import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

export default function withAuthCheck(WrappedComponent) {
  @connect((state) => ({
    app: state.app,
  }))
  class WithAuthCheck extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        checked: false,
      }

      // Do a http request to check the users auth
      axios
        .post('/iapi/auth/check')
        .then((response) => {
          this.setState({
            checked: true,
          })

          this.props.dispatch({
            type: 'SET_AUTH',
            userId: response.data.userId,
            teamId: response.data.teamId,
            jwt:
              response.data.status === 'success' ? response.data.token : null,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
    render() {
      // Don't render the App (wrapped component) unless we have checked in with the server
      if (!this.state.checked || !this.props.app.jwt) {
        return null
      }
      return <WrappedComponent {...this.props} />
    }
  }

  return WithAuthCheck
}
