import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './BrowseCategories.css'
import BriefingNameBlock from 'briefings/components/BriefingNameBlock'

@graphql(gql`
  query {
    categories {
      id
      name
      briefings {
        id
        name
        image
        isFeatured
        url
      }
    }
  }
`)
export default class BrowseCategories extends React.Component {
  constructor(props) {
    super(props)

    let featuredBriefings = []
    let categoriesById = {}

    if (!props.data.loading) {
      const categories = props.data.categories

      featuredBriefings = categories.reduce((featuredBriefings, category) => {
        return [
          ...featuredBriefings,
          ...category.briefings.filter((briefing) => briefing.isFeatured),
        ]
      }, [])

      categoriesById = categories.reduce((categoriesById, category) => {
        categoriesById[category.id] = category
        return categoriesById
      }, {})
    }

    this.state = {
      categoryId: null,
      featuredBriefings,
      categoriesById,
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.data.loading && !nextProps.data.loading) {
      this.setupState(nextProps)
    }
  }
  setupState(props) {
    const categories = props.data.categories

    this.setState({
      featuredBriefings: categories.reduce((featuredBriefings, category) => {
        return [
          ...featuredBriefings,
          ...category.briefings.filter((briefing) => briefing.isFeatured),
        ]
      }, []),
      categoriesById: categories.reduce((categoriesById, category) => {
        categoriesById[category.id] = category
        return categoriesById
      }, {}),
    })
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    const categories = this.props.data.categories

    return (
      <div>
        <div>
          Browse through our categories of briefings. If there's any you'd like
          to follow, you can open up the briefing and subscribe to it.
        </div>

        <div className={styles.options}>
          <div className={styles.label}>Choose a category:</div>
          <div
            className={
              this.state.categoryId === null
                ? styles.activeOption
                : styles.option
            }
            onClick={() => this.setState({ categoryId: null })}
          >
            Featured Briefings
          </div>

          {categories.map((category) => (
            <div
              key={category.id}
              className={
                this.state.categoryId === category.id
                  ? styles.activeOption
                  : styles.option
              }
              onClick={() => this.setState({ categoryId: category.id })}
            >
              {category.name}
            </div>
          ))}
        </div>

        <div className={styles.briefings}>
          {(this.state.categoryId === null
            ? this.state.featuredBriefings
            : this.state.categoriesById[this.state.categoryId].briefings
          ).map((briefing) => (
            <BriefingNameBlock key={briefing.id} briefing={briefing} />
          ))}
        </div>
      </div>
    )
  }
}
