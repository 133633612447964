import React from 'react'
import styles from './Article.css'
import moment from 'moment'
import Sharer from 'articles/components/Sharer'
import uniq from 'lodash/uniqBy'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import articleInfoFragment from 'articles/fragments/articleInfo'
import ArticlePinModal from 'articles/containers/ArticlePinModal'
import { Link } from 'react-router'
import ArticleComments from 'articles/containers/ArticleComments'
import ArticleShare from 'articles/containers/ArticleShare'
import truncate from 'app/library/truncate'
import he from 'he'
import striptags from 'striptags'
import randomSeed from 'random-seed'

// @todo change the article to be a fragment, as used on briefing page too..

@connect((state) => ({
  userId: state.app.userId,
  teamId: state.app.teamId,
  canSaveArticles: state.app.appQueryResult.canSaveArticles,
  canDeleteArticles: state.app.appQueryResult.canDeleteArticles,
}))
@graphql(
  gql`
    mutation ($articleId: ID!) {
      upvoteArticle(articleId: $articleId) {
        ...articleInfo
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'upvoteArticle',
    options: (props) => ({
      variables: {
        articleId: props.article.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation ($articleId: ID!) {
      unUpvoteArticle(articleId: $articleId) {
        ...articleInfo
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'unUpvoteArticle',
    options: (props) => ({
      variables: {
        articleId: props.article.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation ($articleId: ID!) {
      addArticleOpen(articleId: $articleId)
    }
  `,
  {
    name: 'addArticleOpen',
    options: (props) => ({
      variables: {
        articleId: props.article.id,
      },
    }),
  },
)
export default class Article extends React.Component {
  static defaultProps = {
    showActions: true,
    showReadTime: null,
    showCommentsInitially: false,
    actions: [],
    onPinChange: () => {},
  }
  constructor(props) {
    super(props)
    this.state = {
      isShowingAllSharers: false,
      isShowingComments: props.showCommentsInitially,
      showingBriefings: false,
      isSavingArticle: false,
    }
  }
  showAllSharers = () => {
    this.setState({
      isShowingAllSharers: !this.state.isShowingAllSharers,
    })
  }
  showBriefings = () => {
    this.setState({
      showingBriefings: true,
    })
  }
  hideBriefings = () => {
    this.setState({
      showingBriefings: false,
    })
  }
  render() {
    const { article, isMiniView } = this.props

    const uniqueSharers = uniq(article.sharers, (item) => {
      return item.name
    })

    const selfUpvoted =
      article.upvotes &&
      article.upvotes.find((upvote) => upvote.user.id == this.props.userId)
        ? true
        : false
    const selfSaved =
      article.saves &&
      article.saves.find((save) => save.user.id == this.props.userId)
        ? true
        : false

    const anchorAttributes = {
      href: article.url,
      target: '_blank',
      onClick: (e) => {
        this.props.addArticleOpen()
      },
    }
    const titleStyle = isMiniView
      ? [styles.title, styles.miniTitle].join(' ')
      : styles.title
    const titleText = isMiniView
      ? truncate(he.decode(striptags(article.title)), 30)
      : truncate(he.decode(striptags(article.title)), 100)

    // Randomly pick filler image
    const fillerBackgroundImage = `/filler-images/filler${String(
      randomSeed(article.id).intBetween(1, 9),
    ).padStart(2, '0')}.png`

    return (
      <div className={styles.root} data-id={article.id}>
        {article.relevant === false && (
          <div className={styles.filtered}>
            This irrelevant article will be hidden
          </div>
        )}

        <div>
          <a
            {...anchorAttributes}
            className={styles.imageTop}
            style={{
              backgroundImage: `url(${fillerBackgroundImage})`,
            }}
          >
            {article.image !== null && (
              <div
                className={styles.imageTopInner}
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${article.image})`,
                }}
              ></div>
            )}
          </a>

          <div className={styles.text}>
            <div className={styles.metaData}>
              {article.domain && (
                <div className={styles.domain}>
                  {this.props.domainMenu && (
                    <span
                      className={styles.domainMenu}
                      onClick={() => this.props.onDomainMenu()}
                    >
                      <i className="fa fa-times-circle" />
                    </span>
                  )}
                  <span>{article.domain}</span>{' '}
                </div>
              )}
              {article.datePublished !== null && (
                <span>
                  {article.hasPodcast && (
                    <i
                      title="Contains a podcast"
                      style={{
                        marginRight: '7px',
                        marginTop: '2px',
                        color: '#888',
                      }}
                      className="fa fa-podcast"
                    />
                  )}
                  {article.hasVideo && (
                    <i
                      title="Contains a video"
                      style={{
                        marginRight: '7px',
                        marginTop: '2px',
                        color: '#888',
                      }}
                      className="fa fa-video-camera"
                    />
                  )}
                  <span className={styles.time}>
                    {moment(article.datePublished).fromNow()}
                  </span>
                </span>
              )}
            </div>

            <a
              {...anchorAttributes}
              className={titleStyle}
              title={article.title}
            >
              {titleText}
            </a>

            {article.content !== undefined && article.content !== null && (
              <div className={styles.content}>
                {truncate(article.content, isMiniView ? 40 : 120)}
              </div>
            )}

            {(article.sharers ? true : false) && article.sharers.length > 0 && (
              <div>
                <div className={styles.shareTitle}>
                  {(uniqueSharers.length === 1 || uniqueSharers.length > 2) && (
                    <span>
                      Shared by {uniqueSharers[0].name}
                      {uniqueSharers.length > 1 && (
                        <span>
                          {this.state.isShowingAllSharers && (
                            <span>
                              ,{' '}
                              {uniqueSharers
                                .map((s) => s.name)
                                .slice(1, -1)
                                .join(', ')}{' '}
                              and {uniqueSharers[uniqueSharers.length - 1].name}
                            </span>
                          )}
                          {!this.state.isShowingAllSharers && (
                            <span>
                              {' '}
                              and{' '}
                              <span
                                className={styles.sharerName}
                                onClick={this.showAllSharers}
                              >
                                {uniqueSharers.length - 1} others
                              </span>
                            </span>
                          )}
                        </span>
                      )}
                      .
                    </span>
                  )}
                  {uniqueSharers.length === 2 && (
                    <span>
                      Shared by <span>{uniqueSharers[0].name}</span> and{' '}
                      <span>{uniqueSharers[1].name}</span>.
                    </span>
                  )}
                </div>
              </div>
            )}

            {article.briefings !== undefined &&
              article.briefings.length > 0 && (
                <div>
                  {true && (
                    <div>
                      <div className={styles.shareTitle}>
                        {(article.briefings.length === 1 ||
                          article.briefings.length > 2) && (
                          <span>
                            In briefing
                            {article.briefings.length === 1 ? '' : 's'}{' '}
                            <Link
                              className={styles.briefing}
                              to={article.briefings[0].url}
                            >
                              {article.briefings[0].name}
                            </Link>
                            {article.briefings.length > 1 && (
                              <span>
                                {' '}
                                and{' '}
                                <span
                                  className={styles.sharerName}
                                  onClick={this.showBriefings}
                                >
                                  {article.briefings.length - 1} others
                                </span>
                              </span>
                            )}
                            .
                          </span>
                        )}
                        {article.briefings.length === 2 && (
                          <span>
                            In briefings{' '}
                            <Link
                              className={styles.briefing}
                              to={article.briefings[0].url}
                            >
                              {article.briefings[0].name}
                            </Link>{' '}
                            and{' '}
                            <Link
                              className={styles.briefing}
                              to={article.briefings[1].url}
                            >
                              {article.briefings[1].name}
                            </Link>
                            .
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {this.state.showingBriefings && (
                    <div className={styles.sharers}>
                      <div className={styles.hideSharersContainer}>
                        <span
                          className={styles.hideSharers}
                          onClick={this.hideBriefings}
                        >
                          Hide
                        </span>
                      </div>
                      {article.briefings.map((briefing, index) => (
                        <div key={index}>
                          <Link className={styles.briefing} to={briefing.url}>
                            {briefing.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>

        {!this.props.showActions && this.props.showReadTime && (
          <div className={styles.actions}>
            <div></div>
            <div className={styles.actionsLeft}>
              <div>
                {article.readingTime && (
                  <div className={styles.stat}>
                    {Math.ceil(article.readingTime / 60) + ' min read'}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {this.props.showActions && (
          <div className={styles.actions}>
            <div className={styles.actionsLeft}>
              <div
                className={selfUpvoted ? styles.activeAction : styles.action}
                title="Recommend, and see more content like this"
                onClick={() => {
                  if (selfUpvoted) {
                    this.props.unUpvoteArticle()
                  } else {
                    this.props.upvoteArticle()

                    if (this.props.onUpvote) {
                      this.props.onUpvote()
                    }
                    // @todo, remove the upvote?
                  }
                }}
              >
                <i className="fa fa-thumbs-up" />{' '}
                {article.upvotes && article.upvotes.length > 0
                  ? article.upvotes.length
                  : ''}
              </div>
              {this.props.canDeleteArticles && !!this.props.onDownvote && (
                <div
                  className={styles.action}
                  title="You can remove a content item, block a site, and hide more content like this."
                  onClick={() => {
                    this.props.onDownvote()
                  }}
                >
                  <i className="fa fa-thumbs-down" />
                </div>
              )}
              {this.props.canSaveArticles && (
                <div
                  className={selfSaved ? styles.activeAction : styles.action}
                  title="Add this to a saved folder for future reference"
                  onClick={() => {
                    this.setState({
                      isSavingArticle: true,
                    })
                  }}
                >
                  <i className="fa fa-bookmark" />{' '}
                  {article.save && article.saves.length > 0
                    ? article.saves.length
                    : ''}
                </div>
              )}

              {(article.comments ? true : false) &&
                this.props.teamId !== null && (
                  <div
                    className={styles.action}
                    title="Add a comment to show others in your team what you think."
                    onClick={() => {
                      this.setState({
                        isShowingComments: !this.state.isShowingComments,
                      })
                    }}
                  >
                    <i
                      className="fa fa-comment"
                      style={{ marginRight: '3px' }}
                    />{' '}
                    {article.comments.length > 0
                      ? `(${article.comments.length})`
                      : ''}
                  </div>
                )}

              <div
                className={styles.action}
                title="Share content to social platforms"
              >
                <ArticleShare article={article} />
              </div>

              {this.props.actions.map((action, index) => (
                <div key={index} className={styles.action}>
                  {action}
                </div>
              ))}
              {article.readingTime && (
                <div className={styles.stat}>
                  {Math.ceil(article.readingTime / 60) + ' min read'}
                </div>
              )}
            </div>
          </div>
        )}

        {this.props.customBottom && (
          <div className={styles.customBottom}>{this.props.customBottom}</div>
        )}

        {this.state.isShowingComments && (
          <div className={styles.comments}>
            <ArticleComments article={article} />
          </div>
        )}

        {this.state.isSavingArticle && (
          <ArticlePinModal
            id={article.id}
            onRequestClose={() => this.setState({ isSavingArticle: false })}
            onPinChange={this.props.onPinChange}
          />
        )}
      </div>
    )
  }
}
