import React from 'react'
import styles from './LoginPage.css'
import { Link } from 'react-router'
import CenterPage from 'app/components/CenterPage'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

@reduxForm({
  form: 'resetPassword',
})
@graphql(
  gql`
    mutation($email: String!) {
      resetPassword(email: $email)
    }
  `,
  {
    props: ({ mutate }) => ({
      resetPassword: (email) =>
        mutate({
          variables: { email },
        }),
    }),
  },
)
export default class ResetPasswordPage extends React.Component {
  constructor() {
    super()
    this.state = {
      sentTo: null,
    }
  }
  onSubmit = (values) => {
    return this.props
      .resetPassword(values.email)
      .then((response) => {
        this.setState({
          sentTo: values.email,
        })
      })
      .catch((err) => {
        throw new SubmissionError({
          _error: err.graphQLErrors[0].message,
        })
      })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    if (this.state.sentTo !== null) {
      return (
        <CenterPage maxWidth="500px">
          <h5>Email sent</h5>
          <br />
          <p>
            You should shortly receive an email to {this.state.sentTo} which
            will allow you to set a new password. You can close this window.
          </p>
        </CenterPage>
      )
    }

    return (
      <CenterPage maxWidth="500px">
        <h5>Reset your password</h5>
        <br />
        <p>
          Enter your email address and we'll send you a link to set a new
          password.
        </p>
        <form onSubmit={handleSubmit(this.onSubmit)} className="form-inline">
          <Field
            name="email"
            component="input"
            type="email"
            className="form-control mr-1"
            placeholder="Your email address"
          />

          <button className="btn btn-primary">Send email</button>
        </form>

        {error && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{ margin: '10px 0' }}
          >
            {error}
          </div>
        )}

        <p style={{ margin: '20px 0' }}>
          If you don't remember which email address you used to signup, please
          <br /> email us at{' '}
          <a href="email:info@anderspink.com">info@anderspink.com</a> and we'll
          try to help you.
        </p>
      </CenterPage>
    )
  }
}
