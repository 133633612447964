import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import apolloClient from 'apolloClient'
import { browserHistory, Link } from 'react-router'
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form'
import { connect } from 'react-redux'
import moment from 'moment'

import FixedModal from 'app/components/FixedModal'
import AdminTable from 'admin/components/AdminTable'

import AsyncSelect from 'react-select/async'

const ITEMS_PER_PAGE = 50

@graphql(
  gql`
    query ($id: ID!) {
      team(id: $id) {
        id
        name
        useNewBriefingTypes
        teamUsers {
          user {
            id
            firstName
            lastName
            email
            lastActiveDate
          }
          level
        }
        account {
          id
          type
          name
          activeSubscription {
            id
            isStripe
            isTrial
            plans {
              id
              planSet {
                id
                name
                apiType
              }
            }
          }
        }
        apiUser {
          id
          key
          briefingAllowance
          boardAllowance
          createdAt
          cancelledReason
          lastActiveAt
          experimentalCalls
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!, $userId: ID!, $level: String!) {
      adminAddUserToTeam(teamId: $teamId, userId: $userId, level: $level)
    }
  `,
  {
    name: 'adminAddUserToTeam',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!, $userId: ID!) {
      adminRemoveUserFromTeam(teamId: $teamId, userId: $userId)
    }
  `,
  {
    name: 'adminRemoveUserFromTeam',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!, $name: String!, $useNewBriefingTypes: Boolean) {
      adminUpdateTeam(
        teamId: $teamId
        name: $name
        useNewBriefingTypes: $useNewBriefingTypes
      )
    }
  `,
  {
    name: 'adminUpdateTeam',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!, $accountId: ID!) {
      adminMoveTeam(teamId: $teamId, accountId: $accountId)
    }
  `,
  {
    name: 'adminMoveTeam',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!) {
      adminAddApiKeyToTeam(teamId: $teamId)
    }
  `,
  {
    name: 'adminAddApiKeyToTeam',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!) {
      adminDisableApiKey(teamId: $teamId)
    }
  `,
  {
    name: 'adminDisableApiKey',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!) {
      adminEnableApiKey(teamId: $teamId)
    }
  `,
  {
    name: 'adminEnableApiKey',
  },
)
@graphql(
  gql`
    mutation ($teamId: ID!) {
      adminAddExperimentalCalls(teamId: $teamId)
    }
  `,
  {
    name: 'adminAddExperimentalCalls',
  },
)
@connect()
export default class AdminTeamPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      modalType: null,
    }
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    const team = this.props.data.team

    let apiAccess = null
    if (team.account && team.account.activeSubscription) {
      for (let plan of team.account.activeSubscription.plans) {
        if (plan.planSet.apiType !== null) {
          if (apiAccess && plan.planSet.apiType === 'read-only') {
            continue
          }
          apiAccess = plan.planSet.apiType
        }
      }
    }

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>Team</h5>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() =>
              this.setState({ isModalOpen: true, modalType: 'edit' })
            }
          >
            Edit team
          </button>
        </div>
        <div className="form-group row">
          <label className="col-2">ID</label>
          <div className="col-10">{team.id}</div>
        </div>
        <div className="form-group row">
          <label className="col-2">Name</label>
          <div className="col-10">{team.name}</div>
        </div>
        <div className="form-group row">
          <label className="col-2">Users</label>
          <div className="col-10">
            {team.teamUsers.map((teamUser) => (
              <div key={teamUser.user.id} className="row">
                <div className="col-3">
                  <Link
                    className="link"
                    to={`/admin/users/${teamUser.user.id}`}
                  >
                    {teamUser.user.firstName} {teamUser.user.lastName} (
                    {teamUser.level}){' '}
                  </Link>
                  <i
                    className="fa fa-trash"
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                    onClick={() => {
                      let deleteUser = confirm(
                        `Are you sure you want to remove ${teamUser.user.firstName} ${teamUser.user.lastName} from ${team.name}?`,
                      )
                      if (deleteUser) {
                        this.props
                          .adminRemoveUserFromTeam({
                            variables: {
                              teamId: team.id,
                              userId: teamUser.user.id,
                            },
                          })
                          .then(() => {
                            this.props.data.refetch()
                            this.props.dispatch({
                              type: 'ADD_NOTIFICATION',
                              content: `User succesfully removed from team`,
                            })
                          })
                          .catch((err) => {
                            let error = ''
                            if (
                              err.graphQLErrors &&
                              err.graphQLErrors.length > 0
                            ) {
                              error = err.graphQLErrors[0].message
                            } else if (err.networkError) {
                              error = err.networkError.message
                            } else {
                              error = err.message
                            }
                            this.props.dispatch({
                              type: 'ADD_NOTIFICATION',
                              content: error,
                            })
                          })
                      }
                    }}
                  />
                </div>
                <div className="col-6">
                  Last active: {teamUser.user.lastActiveDate} (
                  {moment(teamUser.user.lastActiveDate).fromNow()})
                </div>
              </div>
            ))}
            <div className="mt-2">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() =>
                  this.setState({ isModalOpen: true, modalType: 'user' })
                }
              >
                Add user to team
              </button>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2">Account</label>
          <div className="col-10">
            {team.account == null && (
              <span>None.. whoops this shouldn't be possible!</span>
            )}
            {team.account !== null && (
              <Link className="link" to={`/admin/accounts/${team.account.id}`}>
                {team.account.name} ({team.account.type})
              </Link>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2">Team API key</label>
          <div className="col-10">
            {team.apiUser === null && (
              <div>
                <div>
                  {apiAccess === null && (
                    <div>
                      This teams account isn't allowed API access currently,
                      please change their subscription accordingly.
                    </div>
                  )}
                  {apiAccess !== null && (
                    <div>
                      This team is allowed {apiAccess} access to the API due to
                      their account.
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <button
                    className="btn btn-secondary btn-sm"
                    disabled={apiAccess == null}
                    onClick={() => {
                      if (apiAccess !== null) {
                        this.setState({ isModalOpen: true, modalType: 'api' })
                      }
                    }}
                  >
                    Create key
                  </button>
                </div>
              </div>
            )}
            {team.apiUser !== null && (
              <div>
                <div>
                  {team.apiUser.key} created on{' '}
                  {moment(team.apiUser.createdAt).format('YYYY-MM-DD')}
                </div>
                <div>
                  Last used{' '}
                  {moment(team.apiUser.lastActiveAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}{' '}
                  ({moment(team.apiUser.lastActiveAt).fromNow()})
                </div>
                <div>
                  Skills tagging calls remaining:{' '}
                  {team.apiUser.experimentalCalls}{' '}
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      return this.props
                        .adminAddExperimentalCalls({
                          variables: {
                            teamId: team.id,
                          },
                        })
                        .then(() => {
                          this.props.data.refetch()
                        })
                        .catch((err) => {})
                    }}
                  >
                    Add 50
                  </button>
                </div>
                {team.apiUser.cancelledReason === 0 && (
                  <div>
                    <button
                      className="btn btn-danger btn-sm mt-1"
                      onClick={() => {
                        return this.props
                          .adminDisableApiKey({
                            variables: {
                              teamId: team.id,
                            },
                          })
                          .then(() => {
                            this.props.data.refetch()
                          })
                          .catch((err) => {})
                      }}
                    >
                      Disable this API key
                    </button>
                  </div>
                )}
                {/* {team.apiUser.cancelledReason === 1 && (
                  <div className="alert alert-info">
                    This API key has been disabled
                  </div>
                )} */}

                {team.apiUser.cancelledReason === 1 && (
                  <div>
                    <button
                      className="btn btn-danger btn-sm mt-1"
                      onClick={() => {
                        return this.props
                          .adminEnableApiKey({
                            variables: {
                              teamId: team.id,
                            },
                          })
                          .then(() => {
                            this.props.data.refetch()
                          })
                          .catch((err) => {})
                      }}
                    >
                      Enable this API key
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2">Actions</label>
          <div className="col-10">
            <button
              className="btn btn-sm btn-secondary"
              onClick={() =>
                this.setState({ isModalOpen: true, modalType: 'moveTeam' })
              }
            >
              Move to different account
            </button>
          </div>
        </div>

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          <div style={{ padding: '20px', flex: 1 }}>
            {this.state.modalType === 'api' && (
              <AddKeyForm
                apiAccess={apiAccess}
                onSubmit={(values) => {
                  return this.props
                    .adminAddApiKeyToTeam({
                      variables: {
                        teamId: team.id,
                        ...values,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `API key succesfully added`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}

            {this.state.modalType === 'moveTeam' && (
              <MoveTeamForm
                onSubmit={(values) => {
                  return this.props
                    .adminMoveTeam({
                      variables: {
                        teamId: team.id,
                        accountId: values.accountId.value,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Team succesfully moved`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}

            {this.state.modalType === 'user' && (
              <AddUserForm
                onSubmit={(values) => {
                  return this.props
                    .adminAddUserToTeam({
                      variables: {
                        teamId: team.id,
                        userId: values.user.value,
                        level: values.level,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `User succesfully added`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}

            {this.state.modalType === 'edit' && (
              <EditTeamForm
                initialValues={{
                  name: team.name,
                  useNewBriefingTypes: team.useNewBriefingTypes ? true : false,
                }}
                onSubmit={(values) => {
                  return this.props
                    .adminUpdateTeam({
                      variables: {
                        teamId: team.id,
                        name: values.name,
                        useNewBriefingTypes: values.useNewBriefingTypes,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Team succesfully updated`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}
          </div>
        </FixedModal>
      </div>
    )
  }
}

@reduxForm({
  form: 'addApiKeyToTeam',
  initialValues: {},
})
@connect()
class AddKeyForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <p>
          A random 32 character API key will be generated for this team. It will
          allow <strong>{this.props.apiAccess}</strong> access to the teams
          briefings/boards.
        </p>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Create API key
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'moveTeam',
  initialValues: {},
})
@connect()
class MoveTeamForm extends React.Component {
  async getUsers(input) {
    if (!input) {
      return Promise.resolve([])
    }
    return new Promise((resolve) => {
      try {
        apolloClient()
          .query({
            query: gql`
              query ($query: String!, $types: [String]) {
                adminSearch(query: $query, types: $types) {
                  type
                  id
                  name
                }
              }
            `,
            variables: {
              query: input,
              types: ['account'],
            },
          })
          .then((result) => {
            resolve(
              result.data.adminSearch.map((result) => ({
                label: `${result.name} (${result.type} #${result.id})`,
                value: result.id,
              })),
            )
          })
      } catch (err) {
        console.log(err)
        resolve([])
      }
    })
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <p>Select which account to move this team to</p>

        <p>
          <Field
            name="accountId"
            component={(props) => (
              <AsyncSelect
                valueKey="value"
                labelKey="label"
                value={props.input.value}
                onChange={(selectedOption) => {
                  props.input.onChange(selectedOption)
                }}
                loadOptions={this.getUsers}
              />
            )}
          />
        </p>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Move team
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'addUserToTeam',
  initialValues: {},
})
@connect()
class AddUserForm extends React.Component {
  async getUsers(input) {
    if (!input) {
      return Promise.resolve([])
    }
    return new Promise((resolve) => {
      try {
        apolloClient()
          .query({
            query: gql`
              query ($query: String!, $types: [String]) {
                adminSearch(query: $query, types: $types) {
                  type
                  id
                  name
                }
              }
            `,
            variables: {
              query: input,
              types: ['user'],
            },
          })
          .then((result) => {
            resolve(
              result.data.adminSearch.map((result) => ({
                label: `${result.name} (${result.type} #${result.id})`,
                value: result.id,
              })),
            )
          })
      } catch (err) {
        console.log(err)
        resolve([])
      }
    })
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <div className="form-group">
          <label>User</label>

          <Field
            name="user"
            component={(props) => (
              <AsyncSelect
                valueKey="value"
                labelKey="label"
                value={props.input.value}
                onChange={(selectedOption) => {
                  props.input.onChange(selectedOption)
                }}
                loadOptions={this.getUsers}
              />
            )}
          />
        </div>

        <div className="form-group">
          <label>Level</label>
          <Field name="level" component="select" className="form-control">
            <option>Choose...</option>
            <option key="owner" value="owner">
              Owner
            </option>
            <option key="admin" value="admin">
              Admin
            </option>
            <option key="user" value="user">
              User
            </option>
          </Field>
        </div>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Add user
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'editTeam',
  initialValues: {},
})
@connect()
class EditTeamForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props
    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <div className="form-group">
          <div className="form-group">
            <label>Team name</label>
            <Field
              name="name"
              component="input"
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="useNewBriefingTypes"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Should default to new briefing types?
          </label>
        </div>
        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}
        <div className="mt-2">
          <button
            type="submit"
            className={`btn btn-primary`}
            disabled={submitting}
          >
            Save
          </button>
        </div>
      </form>
    )
  }
}
