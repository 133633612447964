import React from 'react'
import styles from '../Components.module.css'

const Button = ({ title, backgroundColor, margin }) => {
  return (
    <button
      className={styles.button}
      style={{
        backgroundColor: backgroundColor,
        margin: margin || 0,
      }}
    >
      {title}
    </button>
  )
}

export default Button
