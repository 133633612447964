import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import apolloClient from 'apolloClient'
import gql from 'graphql-tag'
import { Link } from 'react-router'
import ArticleListFullPage from 'articles/components/ArticleListFullPage'
import Article from 'articles/containers/Article'
import articleInfoFragment from 'articles/fragments/articleInfo'
import withFollowMutations from 'briefings/containers/withFollowMutations'
import { browserHistory } from 'react-router'
import FixedModal from 'app/components/FixedModal'
import { DropdownMenu } from 'app/components/DropdownMenu'
import styles from './BriefingPage.css'
import ReactTooltip from 'react-tooltip'
import Loader from 'app/components/Loader'
import Loader2 from 'app/components/Loader2'

const DEFAULT_TIME = '3-months'
const ITEMS_PER_PAGE = 50
const ITEMS_PER_PAGE_EMBEDDED = 4
@withFollowMutations
@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
  jwt: state.app.jwt,
}))
@graphql(
  gql`
    mutation ($id: ID!) {
      blockArticle(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      blockArticle: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['briefingQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($briefingId: ID!, $domain: String!) {
      blockBriefingDomain(briefingId: $briefingId, domain: $domain)
    }
  `,
  {
    props: ({ mutate }) => ({
      blockBriefingDomain: (briefingId, domain) =>
        mutate({
          variables: {
            briefingId,
            domain,
          },
          refetchQueries: ['briefingQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($articleId: ID!, $briefingId: ID!) {
      blockBriefingArticle(articleId: $articleId, briefingId: $briefingId)
    }
  `,
  {
    props: ({ mutate }) => ({
      blockBriefingArticle: (articleId, briefingId) =>
        mutate({
          variables: {
            articleId,
            briefingId,
          },
          refetchQueries: ['briefingQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($articleId: ID!, $briefingId: ID!, $tag: String!) {
      tagBriefingArticle(
        articleId: $articleId
        briefingId: $briefingId
        tag: $tag
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      tagBriefingArticle: (articleId, briefingId, tag) =>
        mutate({
          variables: {
            articleId,
            briefingId,
            tag,
          },
          refetchQueries: ['briefingQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    query briefingQuery(
      $id: ID!
      $time: String!
      $limit: Int
      $offset: Int
      $tag: String
    ) {
      briefing(id: $id) {
        id
        name
        url
        type
        description
        image
        isSubscribed
        isSubscribable
        articles(time: $time, limit: $limit, offset: $offset, tag: $tag) {
          ...articleInfo
        }
        userCanEdit
        userCanDelete
        isRefreshing
        isAwaitingRefresh
        linkedBriefingId
        position
        isPublic
        teamId
        team {
          account {
            isDegreedConfigured
          }
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: ({ params, app, location }) => {
      return {
        variables: {
          id: params.id,
          time: params.time || DEFAULT_TIME,
          limit: app.layout.isEmbedded
            ? parseInt(location.query.limit) || ITEMS_PER_PAGE_EMBEDDED
            : parseInt(location.query.limit) || ITEMS_PER_PAGE,
          offset: 0,
          tag: null,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
export default class BriefingPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isArticleActionsModalOpen: false,
      modalArticle: null,
      allArticlesLoaded: false,
      searchOpen: false,
      searchQuery: '',
      searchLoading: false,
      searchArticles: [],
    }
    this.intervalId = null
  }

  componentDidMount() {
    // setTimeout(() => {
    //     // Check...
    //     if (!this.props.data.briefing || this.props.data.briefing.articles.length === 0) {
    //         this.props.data.refetch();
    //     }
    // }, 1000);

    if (this.props.data && this.props.data.briefing) {
      if (
        this.props.data.briefing.isRefreshing ||
        this.props.data.briefing.isAwaitingRefresh
      ) {
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            this.props.data.refetch()
          }, 1000)
        }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.briefing) {
      if (
        nextProps.data.briefing.isRefreshing ||
        nextProps.data.briefing.isAwaitingRefresh
      ) {
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            this.props.data.refetch()
          }, 5000)
        }
      } else if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    }

    //if (!nextProps.data.loading && nextProps.data.briefing && nextProps.data.briefing.isRefreshing) {
    //browserHistory.push(`/briefing-refresh/${nextProps.data.briefing.id}`);
    //}
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.intervalId = null
  }
  componentDidUpdate() {
    ReactTooltip.rebuild()
  }
  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.setState({
        searchOpen: false,
        searchQuery: '',
        searchArticles: [],
      })
    }
  }
  queryArticles(query) {
    this.setState({
      searchQuery: query,
      searchLoading: query.length > 0,
    })

    clearTimeout(this.timeoutId)

    if (query.length > 0) {
      this.timeoutId = setTimeout(() => {
        apolloClient()
          .query({
            query: gql`
              query briefingFilterQuery($id: ID!, $query: String) {
                briefing(id: $id) {
                  id
                  filteredArticles(query: $query) {
                    ...articleInfo
                  }
                }
              }
              ${articleInfoFragment}
            `,
            variables: {
              id: this.props.params.id,
              query: query,
            },
          })
          .then((result) => {
            this.setState({
              searchQuery: query,
              searchLoading: false,
              searchArticles: result.data.briefing.filteredArticles,
            })
          })
      }, 800)
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.briefing) {
      return <div>Loading...</div>
    }

    if (!this.props.data.briefing) {
      return (
        <div>
          Sorry, you are not able to access this briefing or there was an error.
        </div>
      )
    }

    const briefing = this.props.data.briefing
    let articles = briefing.articles

    const time = this.props.params.time || DEFAULT_TIME

    // const degreedSSO =
    //   this.props.appQueryResult && this.props.appQueryResult.degreedSSO

    let canCsvExport = false
    if (
      this.props.appQueryResult &&
      this.props.appQueryResult.user &&
      !!this.props.appQueryResult.user.accounts.find(
        (account) => account.canCsvExport,
      )
    ) {
      canCsvExport = true
    }
    if (
      this.props.appQueryResult &&
      this.props.appQueryResult.team &&
      this.props.appQueryResult.team.account &&
      this.props.appQueryResult.team.account.canCsvExport
    ) {
      canCsvExport = true
    }

    const canSetDegreedSkills =
      briefing.userCanEdit &&
      briefing.team &&
      briefing.team.account &&
      briefing.team.account.isDegreedConfigured

    // Are we subscribed to this briefing?
    let subscribedId = null
    if (briefing.isSubscribable) {
      // See if we have it in our list?
      for (let ourBriefing of this.props.appQueryResult.briefings) {
        if (ourBriefing.linkedBriefingId === briefing.id) {
          subscribedId = ourBriefing.id
        }
      }
    }
    const {
      app: { layout: { isEmbedded } = {} },
      location: { query },
    } = this.props
    const basePath = isEmbedded ? 'briefing-embedded' : 'briefing'

    const articleLimit = isEmbedded
      ? parseInt(query.limit) || ITEMS_PER_PAGE_EMBEDDED
      : parseInt(query.limit) || ITEMS_PER_PAGE

    const buildUrl = (time) =>
      `/${basePath}/${this.props.params.id}/${this.props.params.slug}/${time}${
        query.limit ? `?limit=${query.limit}` : ''
      }`

    return (
      <div>
        {(briefing.isRefreshing || briefing.isAwaitingRefresh) && (
          <div
            className="alert alert-info"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Loader style={{ zoom: 0.7 }} />
            <div style={{ flex: 1 }}>
              This briefing is currently refreshing to bring in the latest
              articles. Please wait as this may take up to a few minutes
            </div>
          </div>
        )}

        <div
          className={styles.top}
          style={isEmbedded ? { flexDirection: 'row' } : {}}
        >
          <div>
            <div
              style={{
                display: 'flex',
                marginBottom: isEmbedded ? '0' : '0.5rem',
                alignItems: 'center',
              }}
            >
              <h5 style={{ marginBottom: 0 }}>{briefing.name}</h5>
              {briefing.isSubscribable && !isEmbedded && (
                <div className={styles.subscribable}>Public briefing</div>
              )}
              {!!briefing.linkedBriefingId && !isEmbedded && (
                <div className={styles.subscribable}>Public briefing</div>
              )}
            </div>
            {(briefing.description ? true : false) && (
              <p>{briefing.description}</p>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '0.8rem',
            }}
          >
            <div
              className="btn-group"
              role="group"
              data-tip="Change your view to see content from different time periods"
            >
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '24-hours' ? 'primary' : 'light'
                }`}
                onClick={() => {
                  browserHistory.push(buildUrl('24-hours'))
                }}
              >
                24 hours
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '3-days' ? 'primary' : 'light'
                }`}
                onClick={() => {
                  browserHistory.push(buildUrl('3-days'))
                }}
              >
                3 days
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '1-week' ? 'primary' : 'light'
                }`}
                onClick={() => {
                  browserHistory.push(buildUrl('1-week'))
                }}
              >
                1 week
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '1-month' ? 'primary' : 'light'
                }`}
                onClick={() => {
                  browserHistory.push(buildUrl('1-month'))
                }}
              >
                1 month
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '3-months' ? 'primary' : 'light'
                }`}
                onClick={() => {
                  browserHistory.push(buildUrl('3-months'))
                }}
              >
                3 months
              </button>
            </div>

            {!isEmbedded ? (
              <>
                <span
                  data-tip="Search for content within a briefing"
                  className={styles.articleSearch}
                  onClick={() => {
                    this.setState({
                      searchOpen: !this.state.searchOpen,
                      searchQuery: '',
                    })

                    if (!this.state.searchOpen) {
                      setTimeout(() => {
                        this.searchRef.focus()
                      }, 50)
                    } else {
                      this.setState({
                        searchArticles: [],
                      })
                      //this.props.data.loadSearchArticles(null);
                    }
                  }}
                >
                  <i
                    className={
                      this.state.searchOpen ? 'fa fa-times' : 'fa fa-search'
                    }
                  />
                </span>
                <div
                  style={{
                    display: this.state.searchOpen ? 'block' : 'none',
                    marginRight: '1rem',
                  }}
                >
                  <input
                    type="text"
                    ref={(ref) => (this.searchRef = ref)}
                    className="form-control mr-1"
                    placeholder="Search for articles"
                    value={this.state.searchQuery}
                    onChange={(e) => {
                      this.queryArticles(e.target.value)
                    }}
                    onKeyDown={this.handleKeyDown}
                  />
                </div>
              </>
            ) : null}

            {briefing.isSubscribable && (
              <div
                style={{
                  marginLeft: '16px',
                }}
              >
                <button
                  className="btn btn-sm btn-primary"
                  onClick={async () => {
                    if (subscribedId !== null) {
                      await this.props.unFollowBriefing(subscribedId)
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Briefing has been unfollowed`,
                      })
                    } else {
                      try {
                        const result = await this.props.followBriefing(
                          briefing.id,
                        )

                        if (
                          result &&
                          result.data &&
                          result.data.followBriefingNew
                        ) {
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `Briefing has been followed`,
                          })
                          browserHistory.push(result.data.followBriefingNew.url)
                        } else {
                          throw new Error('Sory an error occcured')
                        }
                      } catch (err) {
                        let message = null
                        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                          message = err.graphQLErrors[0].message
                        } else if (err.networkError) {
                          message = err.networkError.message
                        } else {
                          message = err.message
                        }
                        this.props.dispatch({
                          type: 'ADD_NOTIFICATION',
                          level: 'danger',
                          content: message,
                        })
                      }
                    }
                  }}
                >
                  {subscribedId !== null ? 'Unfollow' : 'Follow'}
                </button>
              </div>
            )}

            {!!briefing.linkedBriefingId && !isEmbedded && (
              <div
                style={{
                  marginLeft: '16px',
                }}
              >
                <button
                  className="btn btn-sm btn-primary"
                  onClick={async () => {
                    await this.props.unFollowBriefing(briefing.id)
                    browserHistory.push('/home')
                    this.props.dispatch({
                      type: 'ADD_NOTIFICATION',
                      content: `Briefing has been unfollowed`,
                    })
                  }}
                >
                  Unfollow
                </button>
              </div>
            )}

            {(briefing.userCanEdit || briefing.userCanDelete) &&
              !isEmbedded && (
                <DropdownMenu top={true} left={true}>
                  <div
                    className={styles.optionsMenu}
                    data-tip="Edit, copy, move or delete a briefing"
                  >
                    <i className="fa fa-cog" />
                  </div>
                  <div>
                    {briefing.userCanEdit && !!briefing.linkedBriefingId && (
                      <span
                        className={styles.dropdownLink}
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          await this.props.unFollowBriefing(briefing.id)
                          browserHistory.push('/home')
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `Briefing has been unfollowed`,
                          })
                        }}
                      >
                        Unfollow briefing
                      </span>
                    )}

                    {briefing.userCanEdit && !briefing.linkedBriefingId && (
                      <Link
                        className={styles.dropdownLink}
                        to={`/briefing-edit/${briefing.id}`}
                      >
                        Edit briefing
                      </Link>
                    )}

                    {briefing.userCanDelete && !briefing.linkedBriefingId && (
                      <Link
                        className={styles.dropdownLink}
                        to={`/briefing-delete/${briefing.id}`}
                      >
                        Delete briefing
                      </Link>
                    )}
                    {briefing.userCanEdit &&
                      briefing.userCanDelete &&
                      !briefing.linkedBriefingId && (
                        <Link
                          className={styles.dropdownLink}
                          to={`/briefing-move/${briefing.id}`}
                        >
                          Move briefing
                        </Link>
                      )}
                    {briefing.userCanEdit &&
                      briefing.userCanDelete &&
                      !briefing.linkedBriefingId && (
                        <Link
                          className={styles.dropdownLink}
                          to={`/briefing-copy/${briefing.id}`}
                        >
                          Copy briefing
                        </Link>
                      )}
                    {briefing.userCanEdit &&
                      briefing.userCanDelete &&
                      !briefing.linkedBriefingId && (
                        <Link
                          className={styles.dropdownLink}
                          to={`/briefing-training/${briefing.id}`}
                        >
                          Briefing training
                        </Link>
                      )}
                    {briefing.userCanEdit && briefing.userCanDelete && (
                      <Link
                        className={styles.dropdownLink}
                        to={`/briefing-image/${briefing.id}`}
                      >
                        Set briefing image
                      </Link>
                    )}
                    {briefing.userCanEdit && briefing.userCanDelete && (
                      <Link
                        className={styles.dropdownLink}
                        to={`/briefing-article-images/${briefing.id}`}
                      >
                        Set placeholder article images
                      </Link>
                    )}

                    {canCsvExport && (
                      <a
                        className={styles.dropdownLink}
                        href={`/briefing-export/${briefing.id}/${time}/${this.props.jwt}`}
                        target="_blank"
                      >
                        Export briefing as CSV
                      </a>
                    )}

                    {canSetDegreedSkills && (
                      <Link
                        className={styles.dropdownLink}
                        to={`/briefing-degreed-skills/${briefing.id}`}
                      >
                        Set Degreed skills
                      </Link>
                    )}
                  </div>
                </DropdownMenu>
              )}
          </div>
        </div>
        <br />

        {this.state.searchOpen && this.state.searchQuery.length > 0 && (
          <div>
            {this.state.searchLoading && (
              <div>
                <Loader2 text="Searching for articles" />
              </div>
            )}
            {!this.state.searchLoading &&
              this.state.searchQuery.length > 0 &&
              this.state.searchArticles.length === 0 && (
                <div>Sorry, no articles were found</div>
              )}
            {!this.state.searchLoading &&
              this.state.searchQuery.length > 0 &&
              this.state.searchArticles.length > 0 && (
                <div>
                  <ArticleListFullPage>
                    {this.state.searchArticles.map((article, index) => (
                      <Article
                        key={article.id}
                        article={article}
                        showActions={!briefing.isSubscribable && !isEmbedded}
                        onDownvote={() => {
                          this.setState({
                            isArticleActionsModalOpen: true,
                            modalArticle: article,
                          })
                        }}
                        onUpvote={() => {
                          this.props.tagBriefingArticle(
                            article.id,
                            briefing.id,
                            'relevant',
                          )
                        }}
                      />
                    ))}
                  </ArticleListFullPage>
                </div>
              )}
          </div>
        )}

        {!(this.state.searchOpen && this.state.searchQuery.length > 0) && (
          <div>
            {!this.props.data.loading &&
              articles.length === 0 &&
              !briefing.isAwaitingRefresh &&
              !briefing.isRefreshing && (
                <div>Sorry, no articles were found</div>
              )}
            {articles.length > 0 && (
              <div>
                {typeof this.props.location.query.hidden !== 'undefined' && (
                  <div className="alert alert-info" role="alert">
                    Showing articles that are hidden due to being marked
                    irrelevant, or are similar to articles that have been. If
                    any of these are relevant, please select 'Show more like
                    this' from the article dropdown.{' '}
                    <Link to={briefing.url} className="link">
                      Go back to the briefing
                    </Link>
                  </div>
                )}

                <ArticleListFullPage>
                  {articles.map((article, index) => (
                    <Article
                      key={index}
                      article={article}
                      showActions={!briefing.isSubscribable && !isEmbedded}
                      onDownvote={() => {
                        this.setState({
                          isArticleActionsModalOpen: true,
                          modalArticle: article,
                        })
                      }}
                      onUpvote={() => {
                        this.props.tagBriefingArticle(
                          article.id,
                          briefing.id,
                          'relevant',
                        )
                      }}
                      // showDegreedCompletion={isEmbedded && degreedSSO}
                    />
                  ))}
                </ArticleListFullPage>

                {articles.length % articleLimit === 0 && (
                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        const limit =
                          articleLimit +
                          (isEmbedded
                            ? ITEMS_PER_PAGE_EMBEDDED
                            : ITEMS_PER_PAGE)

                        browserHistory.replace(
                          `${window.location.pathname}?limit=${limit}`,
                        )
                      }}
                    >
                      {this.props.data.loading ? 'Loading..' : 'See more'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <FixedModal
          isOpen={this.state.isArticleActionsModalOpen}
          onRequestClose={() =>
            this.setState({ isArticleActionsModalOpen: false })
          }
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() =>
                this.setState({ isArticleActionsModalOpen: false })
              }
            >
              Close
            </button>,
          ]}
        >
          {this.state.modalArticle !== null && (
            <div style={{ padding: '15px' }}>
              <p>
                If the article{' '}
                <strong>"{this.state.modalArticle.title}"</strong> isn't
                relevant you can:{' '}
              </p>

              <div style={{ marginBottom: '1.5em' }}>
                <div>Remove this article from the briefing.</div>
                <div style={{ marginTop: '0.5em' }}>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.props.blockArticle(this.state.modalArticle.id)
                      this.props.blockBriefingArticle(
                        this.state.modalArticle.id,
                        briefing.id,
                      )
                      this.setState({
                        isArticleActionsModalOpen: false,
                      })
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>

              <div style={{ marginBottom: '1.5em' }}>
                <div>
                  Remove this article <strong>and</strong> future articles which
                  are similar. Your briefing will learn as you give it more
                  feedback.
                </div>
                <div style={{ marginTop: '0.5em' }}>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.props.tagBriefingArticle(
                        this.state.modalArticle.id,
                        briefing.id,
                        'irrelevant',
                      )
                      this.setState({
                        isArticleActionsModalOpen: false,
                      })
                    }}
                  >
                    Hide articles like this
                  </button>
                </div>
              </div>

              <div style={{ marginBottom: '1.5em' }}>
                <div>
                  Stop articles from{' '}
                  <strong>{this.state.modalArticle.domain}</strong> from showing
                  in this briefing.
                </div>
                <div style={{ marginTop: '0.5em' }}>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      this.props
                        .blockBriefingDomain(
                          briefing.id,
                          this.state.modalArticle.domain,
                        )
                        .then(() => {
                          //browserHistory.push(`/briefing-refresh/${briefing.id}`);
                          this.props.data.refetch()
                        })
                      this.setState({
                        isArticleActionsModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Domain successfully blocked, refreshing...`,
                      })
                    }}
                  >
                    Block this domain
                  </button>
                </div>
              </div>
            </div>
          )}
        </FixedModal>
      </div>
    )
  }
}
