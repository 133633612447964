import React from 'react'
import { connect } from 'react-redux'
//import styles from './styles.css';
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'

import ContactForm from 'content/containers/ContactForm'

@connect((state) => ({
  app: state.app,
}))
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reason:
        props.location.query && props.location.query.reason
          ? props.location.query.reason
          : null,
    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundPosition: '50% 68%',
            backgroundImage: 'url(/pages/landing/shutterstock_401541778.jpg)',
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Contact us</div>
          </div>
        </div>
        <div className={pageStyles.section}>
          <ContactForm type={this.state.reason} />
        </div>
      </div>
    )
  }
}
