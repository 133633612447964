exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".PersonalHomePage__root___1hhus {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  /* max-width: 1600px; */\n}\n.PersonalHomePage__leftPanel___1HF6f {\n  -webkit-box-flex: 3;\n      -ms-flex: 3;\n          flex: 3;\n  margin-top: 15px;\n  padding: 0 18px 15px 18px;\n  border-right: 1px solid #ddd;\n}\n.PersonalHomePage__rightPanel___CLZEO {\n  -webkit-box-flex: 2;\n      -ms-flex: 2;\n          flex: 2;\n  margin-top: 15px;\n  padding: 0 18px 15px 18px;\n}\n.PersonalHomePage__header___wnOY0 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 18px;\n}\n.PersonalHomePage__title___2YCDf {\n  font-size: 1.5em;\n}\n.PersonalHomePage__link___3Q6un {\n  text-decoration: underline;\n  color: #666;\n  font-size: 0.8em;\n}\n.PersonalHomePage__link___3Q6un:hover {\n  color: #000;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "PersonalHomePage__root___1hhus",
	"leftPanel": "PersonalHomePage__leftPanel___1HF6f",
	"rightPanel": "PersonalHomePage__rightPanel___CLZEO",
	"header": "PersonalHomePage__header___wnOY0",
	"title": "PersonalHomePage__title___2YCDf",
	"link": "PersonalHomePage__link___3Q6un"
};