import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { SubmissionError } from 'redux-form'
import axios from 'axios'

@connect((state) => ({
  organisations: state.app.appQueryResult
    ? state.app.appQueryResult.organisations
    : [],
}))
export default class AllOrganisationsPage extends React.Component {
  render() {
    const { organisations } = this.props

    return (
      <div>
        <h5>Organisations</h5>
        <br />
        <p>
          Organisations represent your company or top-level organisation. They
          can have multiple teams within them, with each team having their own
          users, briefings, and saved folders.
        </p>
        <br />

        <h6 className="mb-2">Your organisations</h6>

        {organisations.length === 0 && (
          <p>You are not a member of any organisations yet.</p>
        )}

        {organisations.length > 0 && (
          <ul className="list-group">
            {organisations.map((organisation) => (
              <li key={organisation.id} className="list-group-item">
                <Link className="link" to={`/organisation/${organisation.id}/`}>
                  {organisation.name}
                </Link>
              </li>
            ))}
          </ul>
        )}

        <div className="card mt-3">
          <div className="card-body">
            <h6 className="card-title">Create new organisation</h6>

            <p>
              You can create a{' '}
              <Link className="link" to="/create-organisation">
                new organisation
              </Link>
              . Organisations can purchase a subscription to create teams.
              Contact us for a free trial or to discuss pricing
            </p>
          </div>
        </div>
      </div>
    )
  }
}
