import React from 'react'
import moment from 'moment'
import Sharer from 'articles/components/Sharer'
import uniq from 'lodash/uniqBy'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import articleInfoFragment from 'articles/fragments/articleInfo'
import ArticlePinModal from 'articles/containers/ArticlePinModal'
import { Link } from 'react-router'
import ArticleComments from 'articles/containers/ArticleComments'
import ArticleShare from 'articles/containers/ArticleShare'
import truncate from 'app/library/truncate'
import he from 'he'
import striptags from 'striptags'
import styles from './ArticleActivity.css'

// @todo change the article to be a fragment, as used on briefing page too..

export default class ArticleActivity extends React.Component {
  static defaultProps = {
    article: null,
  }
  getUsersString(numuniqueUsers) {
    let usersString = ''
    if (numuniqueUsers == 2) {
      usersString = 'and 1 other'
    } else if (numuniqueUsers > 2) {
      usersString = `and ${numuniqueUsers - 1} others`
    }
    return usersString
  }
  renderDate(date) {
    return <span className={styles.date}>{moment(date).fromNow()}</span>
  }
  renderUser(user) {
    return (
      <span className={styles.user}>
        <img className={styles.image} src={user.image} />
      </span>
    )
  }
  render() {
    const article = this.props.article
    let user = null
    let message = null

    if (article.comments.length > 0) {
      const lastComment = article.comments[article.comments.length - 1]
      const usersString = this.getUsersString(
        uniq(article.comments.map((comment) => comment.user.id)).length,
      )
      const date = this.renderDate(lastComment.date)
      user = this.renderUser(lastComment.user)
      message = (
        <span>
          <strong>
            {lastComment.user.firstName} {lastComment.user.lastName}
          </strong>{' '}
          {usersString} commented on{' '}
          <span className={styles.articleTitle}>{article.title}</span>: "
          {lastComment.text}" {date}
        </span>
      )
    } else if (article.upvotes.length > 0) {
      const lastUpvote = article.upvotes[article.upvotes.length - 1]
      const usersString = this.getUsersString(
        uniq(article.upvotes.map((upvote) => upvote.user.id)).length,
      )
      const date = this.renderDate(lastUpvote.date)
      user = this.renderUser(lastUpvote.user)
      message = (
        <span>
          <strong>
            {lastUpvote.user.firstName} {lastUpvote.user.lastName}
          </strong>{' '}
          {usersString} recommended{' '}
          <span className={styles.articleTitle}>{article.title}</span> {date}
        </span>
      )
    } else if (article.saves.length > 0) {
      const lastSave = article.saves[article.saves.length - 1]
      const usersString = this.getUsersString(
        uniq(article.saves.map((save) => save.user.id)).length,
      )
      const date = this.renderDate(lastSave.date)
      user = this.renderUser(lastSave.user)
      message = (
        <span>
          <strong>
            {lastSave.user.firstName} {lastSave.user.lastName}
          </strong>{' '}
          {usersString} pinned{' '}
          <span className={styles.articleTitle}>{article.title}</span> {date}
        </span>
      )
    }

    return (
      <Link to={`/articles/${article.id}`} className={styles.activityLink}>
        <div className={styles.left}>{user}</div>
        <div className={styles.right}>{message}</div>
      </Link>
    )
  }
}
