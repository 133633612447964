import React from 'react'
import { connect } from 'react-redux'
import FriendsArticles from 'friends/containers/FriendsArticles'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import styles from './ApiSettingsPage.css'
import LinkTwitterPanel from 'authentication/containers/LinkTwitterPanel'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const selector = formValueSelector('friendsOverview')

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
@reduxForm({
  form: 'friendsOverview',
})
@graphql(
  gql`
    query apiSettingsQuery {
      session {
        briefings {
          id
          name
          isPublic
        }
        boards {
          id
          name
          isPublic
        }
        api {
          hasAccess
          key
          apiBriefingsAvailable
          apiBoardsAvailable
          publicBriefingsAvailable
          publicBoardsAvailable
          boards {
            id
            name
          }
          briefings {
            id
            name
          }
        }
      }
    }
  `,
  {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      toggleApiBriefing(id: $id) {
        briefings {
          id
          name
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleApiBriefing: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['apiSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      toggleApiBoard(id: $id) {
        boards {
          id
          name
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleApiBoard: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['apiSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      togglePublicBriefing(id: $id) {
        briefings {
          id
          name
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      togglePublicBriefing: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['apiSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      togglePublicBoard(id: $id) {
        boards {
          id
          name
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      togglePublicBoard: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['apiSettingsQuery'],
        }),
    }),
  },
)
export default class ApiSettingsPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.data.loading && !this.props.data.session) {
      return null
    }

    if (!this.props.data.session || !this.props.data.session.api) {
      return null
    }

    const api = this.props.data.session.api

    let canPubliclyExport = false
    let exportLink = null
    if (
      this.props.appQueryResult &&
      this.props.appQueryResult.team &&
      this.props.appQueryResult.team.account &&
      this.props.appQueryResult.team.account.activeSubscriptions
    ) {
      for (let subscription of this.props.appQueryResult.team.account
        .activeSubscriptions) {
        for (let plan of subscription.plans) {
          const planSet = plan.planSet
          if (planSet && planSet.canPubliclyExport) {
            canPubliclyExport = true
            exportLink = this.props.appQueryResult.team.exportLink
          }
        }
      }
    }

    return (
      <div>
        <h4>Export Settings</h4>
        <br />

        {api && api.hasAccess && (
          <div>
            <h5>API settings</h5>

            <p>
              Your private API key is <strong>{api.key}</strong>, please keep
              this safe.
            </p>

            <p>
              For more information on the API please see our{' '}
              <a href="/api-docs" target="_blank" className="link">
                API documentation
              </a>
              .
            </p>

            <p>
              You can choose which briefings and saved boards below are
              accessible via the API. Your plan allows for{' '}
              {api.apiBriefingsAvailable} more briefings and{' '}
              {api.apiBoardsAvailable} more boards to be made available via the
              API.
            </p>

            <h5>
              Select briefings which should be accessible via API (
              {api.apiBriefingsAvailable} remaining)
            </h5>

            <div className={styles.rows}>
              {this.props.data.session.briefings.map((briefing, index) => {
                const selected =
                  api.briefings
                    .map((briefing) => briefing.id)
                    .indexOf(briefing.id) >= 0
                return (
                  <div
                    key={index}
                    className={styles.row}
                    onClick={() => {
                      this.props.toggleApiBriefing(briefing.id)
                    }}
                  >
                    <span>{briefing.name}</span>{' '}
                    <i
                      className={
                        selected ? 'fa fa-check-square-o' : 'fa fa-square-o'
                      }
                    />
                  </div>
                )
              })}
            </div>

            <br />

            <h5>
              Select saved article folders which should be accessible via API (
              {api.apiBoardsAvailable} remaining)
            </h5>

            <div className={styles.rows}>
              {this.props.data.session.boards.map((board, index) => {
                const selected =
                  api.boards.map((board) => board.id).indexOf(board.id) >= 0
                return (
                  <div
                    key={index}
                    className={styles.row}
                    onClick={() => {
                      this.props.toggleApiBoard(board.id)
                    }}
                  >
                    <span>{board.name}</span>{' '}
                    <i
                      className={
                        selected ? 'fa fa-check-square-o' : 'fa fa-square-o'
                      }
                    />
                  </div>
                )
              })}
            </div>

            <br />
            <br />
            <br />
          </div>
        )}

        {canPubliclyExport && (
          <div>
            <h5>Public export settings</h5>

            <p>
              If you are unable to use the API, you can export your briefings
              publicly by using RSS or our JavaScript widget. We recommended you
              use the API.
            </p>

            <p>
              Your plan allows for {api.publicBriefingsAvailable} more briefings
              and {api.publicBoardsAvailable} more boards to be made public.
            </p>

            <h5>
              Select briefings which should be publicly exportable (
              {api.publicBriefingsAvailable} remaining)
            </h5>

            <div className={styles.rows}>
              {this.props.data.session.briefings.map((briefing, index) => {
                const selected = briefing.isPublic
                return (
                  <div
                    key={index}
                    className={styles.row}
                    onClick={() => {
                      this.props.togglePublicBriefing(briefing.id)
                    }}
                  >
                    <span>{briefing.name}</span>{' '}
                    <i
                      className={
                        selected ? 'fa fa-check-square-o' : 'fa fa-square-o'
                      }
                    />
                  </div>
                )
              })}
            </div>
            <br />

            <h5>
              Select saved folders which should be publicly exportable (
              {api.publicBoardsAvailable} remaining)
            </h5>

            <div className={styles.rows}>
              {this.props.data.session.boards.map((board, index) => {
                const selected = board.isPublic
                return (
                  <div
                    key={index}
                    className={styles.row}
                    onClick={() => {
                      this.props.togglePublicBoard(board.id)
                    }}
                  >
                    <span>{board.name}</span>{' '}
                    <i
                      className={
                        selected ? 'fa fa-check-square-o' : 'fa fa-square-o'
                      }
                    />
                  </div>
                )
              })}
            </div>

            {!!exportLink && (
              <div>
                <br />
                <br />
                <br />
                <h5>Embedding all briefings together</h5>
                <p>
                  If you want to iFrame all of this teams briefings in a public
                  site you can use the following URL:
                </p>
                <p>
                  <a href={exportLink} target="_blank">
                    {exportLink}
                  </a>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
