exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Sharer__root___3-Cdl {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.Sharer__left___36_cV {\n  width: 25px;\n  margin-right: 5px;\n}\n.Sharer__image___3uCKM {\n  max-width: 100%;\n}\n.Sharer__right___1AX6E {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.Sharer__time___2JLgK {\n  color: #888;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Sharer__root___3-Cdl",
	"left": "Sharer__left___36_cV",
	"image": "Sharer__image___3uCKM",
	"right": "Sharer__right___1AX6E",
	"time": "Sharer__time___2JLgK"
};