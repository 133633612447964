import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'
import ContactForm from 'content/containers/ContactForm'

import ReactPlayer from 'react-player'

import ReactMarkdown from 'react-markdown'

const partners = [
  {
    id: 'aurion-learning',
    image: `${STATIC_DOMAIN}/pages/landing/partners/aurion.svg`,
    video: `https://player.vimeo.com/video/443016711`,
    name: 'Aurion Learning',
    introText: `
[Aurion Learning](https://aurionlearning.com/) specialise in creating ‘learning that works’, that extends to content and platform solutions that are tailored to achieving the required business impact that each client needs to see.

Watch our interview with Gavin Woods, Client Services Director, who shares more about Aurion’s approach, and the role of content curation via Anders Pink, and there’s more detail below.        
        `,
    text: `
#### What’s your key learning platform? 
We work with a number of different platform solutions including Peoplefluent Learning (NetDimensions), LearnUpon as well as custom tools designed by the Aurion team. 

#### What makes Aurion Different?
We are not tied to any one approach or platform. We can look objectively at the challenge and advise accordingly. Our custom learning portals allow us to combine the right set of learning tools together in a single tailored platform solution. Having the technical know-how to do that and to do it well is rare.

#### How do you work with Anders Pink? 
The main focus of our work with Anders Pink is the integration of content curation into the custom learning portals that we design for clients. Find out more at: https://www.aurionlearning.com/learning-management-systems-lms

#### Services:
Aurion is a full service partner for any organisation. We tend to work on long term multi-project relationships with clients.
Key industries / markets / territories
Healthcare / Public Sector / Global Corporates. Experience of working on global projects centred in the UK, Ireland and USA.

#### Key clients
Health Service Executive, NHS, Jacobs Engineering, IBM, World Rugby,

#### Locations:
Belfast, Dublin, Glasgow, working on projects globally.

#### Pricing (for platform)
Every solution is different - we price based on your requirement. We have options at price points for most budgets and visions.

Find out more at: https://aurionlearning.com/
        `,
    //url: 'https://www.kineo.com',
  },
  {
    id: 'kineo',
    image: `${STATIC_DOMAIN}/pages/landing/partners/kineo.png`,
    video: `https://player.vimeo.com/video/443016342`,
    name: 'Kineo',
    introProductSheet: (
      <a
        className="btn btn-secondary btn-sm"
        href="/documents/AndersPink_Kineo_Product_Sheet_2022.pdf"
        target="_blank"
      >
        Download product sheet
      </a>
    ),
    introText: `Made to measure, designed for impact.

Kineo helps the world’s leading businesses improve performance through learning and technology. We’re proud of our reputation for being flexible and innovative, and of our award-winning work with clients across the world.​

> product sheet link

<br />
<br />

Whatever your business challenge, we will partner with you every step of the way to find the learning solution that fits best – and delivers results.

Here’s Rory Lawson, Account Director to share more:`,
    text: `
#### Location 
UK, USA, Australia, New Zealand, Mexico, Argentina, Sweden, South Africa

#### Product and services summary:

- [Learning and development strategy](https://www.kineo.com/services/learning-and-development-strategy) - We take a strategic approach to delivering solutions to your L&D needs that are in line with your wider business goals. We take a relationship based approach to develop a thorough understanding of your business so we can tailor a solution that works for you.
- [Bespoke e-learning and custom elearning solutions](https://www.kineo.com/services/bespoke-elearning) - Custom-made elearning solutions to cover any learning project. Past clients include charities and multinational corporations across the globe working with every major authoring tool, LMS, sector and industry
- [Learning Platforms](https://www.kineo.com/services/learning-platforms) - Tailor made, scalable learning platforms to suit your business, delivering great results and a first class learning experience
- [Sitepass: Contractor Workforce Management Software](https://www.kineo.com/services/contractor-management) - Efficient software for managing contingent workforces in the gig economy and reducing operational costs and increasing operational efficiency
- [Off the Shelf Learning - Kineo Courses](https://www.kineo.com/services/off-the-shelf-elearning) - Hundreds of SCORM compliant courses ready to use in your existing LMS or via the Kineo Course Learning Portal covering topics including management training, professional development, health and safety and compliance
- [Learning Data Services](https://www.kineo.com/resources/data-your-lms) - From planning your programmes to understanding their impact, we work in partnership with you to deliver the right results in the time you need them across consultancy, learning metrics, data reconciliation, learning measurement, analysis & insights, data transformation, content curation.


#### Key clients 
BP, Barclays, Watts, Levi’s, California Academy of Sciences, IAFC, IPA, Bridgestone, Mission Australia Early Learning, Fravega, Mitchells & Butlers, Burger Fuel, Health Promotion Agency, Pepsico, Credit Suisse, Inland Revenue, Australian Government, Pizza Express, Rolls Royce, Swire, Compass Group, Coats, McDonalds, easyJet, The Scout Association, Samsung, M&S.

#### Key industries / markets / territories:
Territories: UK, USA, Australia, New Zealand, Sweden, Argentina, South Africa
Industries: All sectors

#### How are we different?

We are a learning company. It’s the simple notion that sits at the heart of everything we do. We fundamentally understand the transformative power that education can have on people’s lives and the opportunity that it provides.​

We know how people learn and the best way of achieving results. Results that have impact. Impact that is measurable.

As part of the City & Guilds Group we are able to draw on experience across a range of disciplines and broader services to add value to our products, such as digital credentials.

#### Contact details
Get in touch at [info@kineo.com](mailto:info@kineo.com)<br />
Or contact us via our site: https://www.kineo.com/contact-us


        `,
    url: 'https://www.kineo.com',
  },
  {
    id: 'learning-pool',
    image: `${STATIC_DOMAIN}/pages/landing/partners/learning-pool.png`,
    video: `https://player.vimeo.com/video/443015521`,
    name: 'Learning Pool',
    introText: `Learning Pool is a full-service e-learning provider, offering a range of courses, tools and content creation to over 800 organizations and 2.5 million learners worldwide. With the largest and most flexible catalogue of content on the market and a reliable and robust LMS, Learning Pool delivers a highly customized learning experience, combining their expertise in personalized learning, gamification, and AI-driven performance.

We talked to Jon Bryges, Product Manager at Learning Pool to find out more, and see how they’ve integrated Anders Pink into Stream LXP:`,
    text: `
#### Background/About Us: 

2019 was a landmark year for Learning Pool with highlights including increasing its client base by
30%, the launch of its new LXP, Stream, and the acquisition of the award-winning Oxfordshire-based
innovative learning solutions provider, HT2 Labs which saw staff numbers rise to over 200
colleagues.

We also won a vast array of awards, including Gold for Innovation at the 2019 Learning Technologies
Awards and Gold at the International CSR Awards for our commitment to being a responsible
business to the community. Already in 2020, we have also achieved Gold for exemplar customer
service at the 2020 Stevie Awards and topped both the ‘Top Content Provider for Rapid eLearning’
and ‘Top Content Provider for Mobile Learning’ lists by eLearning Industry.

#### Location: 
Our head office is based in Derry, Northern Ireland but we also have offices in Belfast,
Glasgow, Nottingham, Oxford and Boston (MA).

#### Key Learning Platform / Product: 
[Stream LXP](https://learningpool.com/solutions/learning-experience-platform-stream/).

#### Product Summary: 
Transform user engagement with social and self-directed learning, natural
language deep search and personalised recommendations. Smart, simple and social, Learning Pool’s
Learning Experience Platform (LXP), Stream enables organisations to deliver on their promise of a
true ‘learning culture’ by providing learning experiences that drive the behaviours needed today
whilst building on the skills that needed to succeed tomorrow.

#### Key features: 
One-click interface to give instant access to learning experiences across your systems;
consumer-grade search to help users find the learning they need when they need it; workflow
channel integration delivered as ‘plug and play’; social and gamification tools of engagement; high-
quality data analytics via integrated Learning Record Store and advanced ‘social intelligence’;
integrates with Learning Pool’s content authoring tool, Adapt Builder; available with ‘Future Skills’
content to future-proof your business; ready to launch, Stream is quick to deploy and easy to
integrate into your ecosystem.

#### Key Clients: 
Sky, HSBC, Astra Zeneca, The FA, Vodafone, InterContinental Hotels Group (IHG) and
Villeroy &amp; Boch.

####  Key Industries/Markets/Territories: 
Learning Pool serves hundreds of organisations from 21
different countries across a number of sectors including Finance, Retail, Education, Healthcare,
Public Sector and Transport.

#### Differentiators USP: 
Blending training and talent development in one easy interface; engaging users
directly in the workflow; intelligent impact measurement with the Social Intelligence Dashboard to
analyse the quality of learner contributions and optimise learning content; redefining what ‘value’
looks like as a ‘Potential Challenger’ on the 2020 Fosway-9 grid whilst maintaining ‘Core Leader’
status from the 2019 grid with ‘lowest cost of total ownership’; the only major learning provider that
we know of who provides first-line support to customers on the phone and online with 20 dedicated
support staff and 96% of customers rating support as ‘excellent’.

#### Services Provided (content, platform, support, hosting, consultancy, etc):
Bronze, Silver &amp; Gold:
- Learning Experience Platform, including customer colour scheme and logo, with consumer-
grade UI and standard search
- User-generated content
- Social and gamified learning
- Smart recommendations
- Award-winning telephone and online support
- Learning Record Store (API only - launch xAPI content, out of the box visualisations)

Silver & Gold:
- Learning Record Store Enterprise (full access - create new visualisations and analysis and/or
connect to non-Stream platforms)
- Social Intelligence Dashboard (SID)
- Chatbot (Flo)
- Advanced search (NLP deep search in Adapt/PDF)

Gold only:
- Adapt Builder (5 licenses)
- Future Skills Catalogue
- Waves - Plan, launch, nudge and automate learning effectively by creating campaigns to
personalise learners’ journeys based on their responses and engagement with the content.
- Venue (offline classroom booking etc - when available - Q1 2021)

#### Indicative Pricing: 
Bronze, Silver and Gold plans available. No additional set-up/implementation
fees.

#### Contact Details: 

Website: https://learningpool.com/<br />
Email: [marketing@learningpool.com](mailto:marketing@learningpool.com)<br />
Telephone: 020 7101 9383
        `,
  },
  // {
  //     id: 'fuse',
  //     image: `${STATIC_DOMAIN}/pages/landing/partners/fuse.png`,
  //     name: 'Fuse',
  //     description: 'Fuse are on a mission to change the way the world learns, within their branded environment. From your content strategy to your delivery, we ensure your team develop their skills. Advanced Technology. Great Practice',
  //     integration: 'Anders Pink integrates with Fuse and can be customised and supported by Fuse.',
  //     url: 'https://fuse.fuseuniversal.com',
  // },
  {
    id: 'learn-amp',
    image: `${STATIC_DOMAIN}/pages/landing/partners/learnamp.png`,
    video: `https://player.vimeo.com/video/738666187`,
    name: 'Learn Amp',
    introProductSheet: (
      <a
        className="btn btn-secondary btn-sm"
        href="/documents/AndersPink_Learn_Amp_Product_Sheet_2022.pdf"
        target="_blank"
      >
        Download product sheet
      </a>
    ),
    introText: `Learn Amp is a global leading People Development Platform.​ We offer an all-in-one suite of LMS, LXP, Social Learning and Talent Development tools, providing a 'software with a service'.
 
<br/>

> product sheet link

<br/>
<br/>

Below is a video showcasing Anders Pinks seamless integration with Learn Amp:
`,
    text: `
#### Product summary:

Learn Amp is a leading People Development Platform helping customers to scale and unlock potential through the development and alignment of their employees.​ Learn Amp offers a suite of Learning and Talent Development tools, combined with deep industry expertise to prove ‘software with a service’.​ We provide ROI through improved learning, skills and talent development, which enhances employee experience, increases engagement and improves retention.

#### Solutions we provide:

- Induction & Onboarding
- Learning Pathways
- Self-driven development
- Social Learning
- Knowledge Management
- Content creation and curation
- Culture and comms
- Employee Ecosystem
- Performance Management and Development
- Skill Tracking
- Event management
- Surveys & Polls
- Assessment
- Compliance & Risk Management

#### Key clients (Mid market): 

Metro, NSF, Paragon Bank, Boon Edam, Ten Lifestyle Group, Kinleigh Folkard & Hayward

#### Key clients (SMEs): 

The Drum, Coingaming, Adarma, Free Agent, First Mile, Comparison Technologies 

#### Key industries / markets / territories: 

- SME (100 - 1000 users)
- Mid Market (Up to 10,000 users)
- Key industries: We think we work well with any tech enabled businesses 

#### Differentiators / USP:

- Tie together the key elements of the digital employee experience - Learning, Engagement and Performance 
- An integrated employee hub for your employees which streamlines the digital employee experience
- We balance the needs of the business (LMS) with the needs of the individual (LXP) and our performance provides a place for the employee and the business to align through 1-to-1’s and objective setting
- Far higher user experience for Learners, Managers and Administrators
- We encourage the whole business to be involved, decentralising the platform which increases engagement and spreads the administrative work across the organisation
- Ease of administration: The key balance of easy and enjoyable usability with a breadth, depth and flexibility of tools you would expect in enterprise solutions
- Platform as a service: We recognise what we are good at and we partner with best in breed integration and content partners to deliver an ecosystem our customers need
- Speed of Setup: As well as being a highly intuitive platform we provide in platform training, a knowledge base, templates for our tools for quick set up and expert coaching and project management services to get you launched as fast as possible
- Software with a service

#### Indicative Pricing: 

We charged based on the number of Activated Users, and work in banded pricing​.
 
An Activated User is someone who has accepted their invitation to join the platform​. We don’t count deactivated users​, and they will be treated as archived rather than deleted.
 
Customers select their User Band up front for the year​. Once the customer exceeds the number of licenses in their User Band, they’re charged the cost of the next band pro rata until the end of that contract year​. At the commencement of the next contract year, we would then charge a full 12 months at the new User Band.

#### Contact details

Request a demo - https://learnamp.com/enquiries/new

General Enquiries - [info@learnamp.com](mailto:info@learnamp.com)

Call us - 0845 359 9859 

`,
    url: 'https://learnamp.com',
  },
  {
    id: 'elearning-wmb',
    image: `${STATIC_DOMAIN}/pages/landing/partners/elearningwmb.png`,
    video: `https://player.vimeo.com/video/443016080`,
    name: 'eLearning WMB',
    introText: `
eLearning WMB - makers of the Open eLMS system and services - help organisations to improve the knowledge and skills of their learners both in the corporate and education sectors.

The Open eLMS systems and services adopt a user centred design which makes it possible to deliver powerful learning solutions from Information Management Systems to learning creation without any fuss. We challenge you to find a more comprehensive and user-friendly approach to learning! 

eLearning WMB works with hundreds of the companies from some of the world's largest and some smaller ones too to provide the perfect learning solutions for them.

Watch an overview of Open eLMS here, including how it integrates with Anders Pink. There’s more information on eLearning WMB below 
        `,
    text: `
#### Location:
1 Grove Road, Maidenhead, Berkshire SL6 1LW

#### Key Learning Platform / Product 
Open eLMS 

#### Product summary
    
Open eLMS is the Netflix style LMS, offering an all-in-one solution to meet all of your learning needs, including elearning creation, content management and delivery.
    
This Learning Management System redefines what can be expected from an LMS, allowing you to implement and maintain the most effective learning experience possible by combining LMS, VLE and MIS functionality into one, easy-to-use solution.
    
https://www.e-learningwmb.com/page/open-elms-lms  

#### Key clients 
Amey, Bauer Media, Oetker, Superdrug, Unilever 

#### Key industries / markets / territories
Non specific industries/markets.  Market globally. 

#### Differentiators / USP
    
Any decision to adopt eLearning WMB's Open eLMS products and services is usually attributable to two factors: comprehensiveness of our solutions and ease of use.

#### Comprehensiveness
The Open eLMS suite of services provides everything you will need to produce, run and manage learning. The Open eLMS system provides an unparalleled service covering:

- Learning Management
- Performance Management
- ePortfolios
- Library of Learning
- Online Authoring Software
- Bespoke Development
- Video Conferencing Learning

#### Ease of Use
The Open eLMS suite of services has been designed by qualified ergonomists to be the ultimate in ease of use. Ease of use is characterised by:
- Learner interfaces easy to use like Netflix
- Management interfaces role specific (no need for training - just follow prompts)
- elearning creation more sophisticated than Storyline and Captivate but easier to use than PowerPoint 

#### Services provided (content, platform, support, hosting, consultancy, etc)
- Open eLMS - Data Management: LMS, TMS, e-Portfolio
- Open eLMS Live - a remote learning solution for education and business.
- Open eLMS Bespoke - Courses built from the ground up to meet your needs perfectly.
- Open eLMS Catalogue - Over 200 courses available for immediate purchase
- Open eLMS Creator - Powerful and easy to use authoring - formerly known as 'Jackdaw Cloud'

#### Indicative Pricing:
Prices start at £10 per learner - contact us for an instant quote. 

    
#### Contact details
[info@elearningwmb.com](mailto:info@elearningwmb.com)

1 Grove Road, Maidenhead, Berkshire SL6 1LW  
        `,
  },
  {
    id: 'valamis',
    image: `${STATIC_DOMAIN}/pages/landing/partners/valamis-logo-black-rgb.svg`,
    video: `https://player.vimeo.com/video/443015214`,
    name: 'Valamis',
    introText: `Today’s learning leaders are seeking new ways to increase engagement, retain and mobilise talent and cultivate self-driven career cultures, that impact the bottom line and deliver digital transformation. TWM designs and delivers digital learning and development experiences that address these needs and align employee capabilities to business goals; transforming business and individual performance.

Here’s a video overview of TWM and how they integrate with Anders Pink, and there’s a deeper dive below:`,
    text: `TWM’s learning experience platform boosts engagement, promotes a learning and
development culture, and transforms organisational and individual performance.
Taking a people-first consultative approach, TWM puts achieving an organisation’s strategic
objectives at the heart of its online learning, career and talent management solutions. With a
focus on technology and an emphasis on high user engagement, TWM build and create
platforms and content that delivers value to all stakeholders.

TWM won the prestigious People Development Programme Silver Award for the
development of O2 Campus, in partnership with O2 Telefónica and TWM is proud to be a
‘Top 15 Learning Technology Provider’, recognised by The Learning &amp; Performance
Institute.

Clients span industry sectors and range from small to medium companies to enterprise
organisations across industries. Long term relationships delivering exceptional customer
value is at the heart of TWM.

The partnership with industry career development experts, [Talent & Potential](https://www.talentandpotential.com/about/), has enabled
TWM to enhance its offering by developing the unique next generation CareerBurst platform.
It helps companies promote home-grown talent, reduce turnover costs, build loyalty and
minimise disruption within teams. It also offers world class, curated career support and
provides managers with a robust toolkit to encourage meaningful career conversations.

TWM is dedicated to raising standards of workplace learning for clients and the intuitive
platforms, that have been designed by learning and development and digital experts, help
organisations to build learning cultures, engage their people, drive career ownership and
achieve return on investment.

For advice without obligation, please feel free to [get in touch](mailto:info@theworkingmanager.com) in order to identify that magic
spark that will engage people in learning and proactive career development.

Get in touch and follow us on social media:
- Tel: +44 (0)1285 657978
- Email: [info@theworkingmanager.com](mailto:info@theworkingmanager.com)
- [TWM News & Insights](https://theworkingmanager.com/news-and-views/)
- [TWM LinkedIn](http://www.linkedin.com/company/the-working-manager)
- [TWM Twitter: @workingmanager](https://twitter.com/workingmanager)

#### Location:
The TWM head office is in Cirencester, Gloucestershire but the team is based all over the
UK; partnering with national and international clients, large and small, across industries and
sectors.

#### Key Learning Platform/Product:
[Core Learning Experience Platform](https://theworkingmanager.com/learning-experience-platform/) and [CareerBurst](https://theworkingmanager.com/careerburst/)

#### Key Clients:
TWM partners with O2 Telefónica, Bentley Motors, Polygon and Manufacturing Technology
Centre (MTC) together with many others. Our digital solutions are used across 36 countries
and span different sectors. For further information please go to: [TWM Customer Stories](https://theworkingmanager.com/customer-stories/)

#### The TWM Difference:
Providing online learning platforms since 2004 to companies of all sizes and across sectors
many of the clients TWM partners with have been on the journey with us since the very
beginning and are still working with the TWM team; something we’re really proud of. The
longevity of relationships with key customers, consistently being an outcomes focused
service quality leader and forging strategic partnerships with clients is of utmost importance
to TWM.

TWM’s individuals have a passion for customer service and a tenacity for solving problems.
It is what has helped TWM to win the awards it has and helped clients we work with win
prestigious people development awards.

Our people truly are our differentiator; many of the TWM team are former L&amp;D/HR and
People Development learning leaders and specialists who have a deep knowledge of L&amp;D
and transforming cultures and workforces, creating engaging experiences and mobilising
talent and potential. Working as ‘one team’ with clients TWM helps to ‘future-ready’ people
and ‘future-proof’ organisations. We are where technology meets deep talent expertise.

#### Services Provided (content, platform, support, hosting, consultancy, etc):

TWM learning platforms have all the features you’d expect from an online learning platform
and more and can be accessed at any time and from any device. Blend the ones that you
need to create the solution that is right for you.

Full client customisation is standard allowing you to merge our content with yours. We don’t
believe in add-ons just providing everything you need to deliver a great learning experience
and the tools to evolve it to meet changing needs.
​

![](${STATIC_DOMAIN}/pages/landing/partners/twm_img1.png)


#### TWM’s platform features include:
- Personalised homepages
- Off-the-shelf content
- Content hosting (SCORM, video, audio, files, articles, polls, topics, carousels,
playlists)
- Online and face-to-face event booking and tracking with external/internal trainer
views and tools
- Social collaboration functionality, communities and leaderboards
- Notifications and reminders
- Learning pathways with evidence, target dates and assessor and/or manager sign off
options
- Policy management with download and sign off tracking
- Compliance features including recertification
- Career mobility and career conversations resources and self-assessment tools (e.g.
Think Strengths and Think Motivation)
- Mentoring and coaching tactics

The useful and simple personalised homepage tools have evolved in line with close client
collaboration and industry trends. They allow you to customise your platform so that it can
grow with you as your needs to change, which is why we’ve been working with clients for
such a long time.

Our aim is to make the experience as simple as possible for you to curate, create and
securely host learning experiences that make a difference. We act as an extension to your
team, we listen and develop products that add value to you pre-joiners, employees,
contractors and even partners and clients.

#### Integration and Content:
We have a clever bunch of developers ready to integrate all your learning opportunities from
a single platform and help you leverage your L&amp;D budget to the max. There are various
levels of integration – flexed to specific needs and preferences.

You may not be able to get everything from one place or create your own content but there’s
no need for that to stand in the way of a fabulous learning experience.

We have exercises, ideas and activities, articles, videos and learning bites to get you started
and whenever you’re ready, mix these seamlessly with your content and/or 3 rd party content.
Stream in carousels of content from the web via Anders Pink boards to keep your site up to
date and relevant and deliver the complete package.

#### Indicative Pricing:
TWM works with clients to implement a learning experience platform that delivers against
current requirements and importantly future needs. We will deliver transparent cost models
and tracked activities, linked to key milestones. Unlimited hosting &amp; bandwidth means no
surprise costs for extra usage and all aligned to your project and budget needs.

Following a scoping and proof of concept we integrate systems to help you leverage your
whole L&amp;D budget effectively and as your population expands, we will proactively drive
further savings.

From day one of any partnership TWM works towards doing everything possible to support
individuals and stakeholders whilst bringing further evidence of innovations, cost reductions,
increased capability, new products and services and refreshed interfaces as part of the
solution.

We will provide unique and innovative pricing models to work around any given budgets.

TWM is here to support your digital learning journey, at every step, striving to understand the
challenges you face and deliver engaging user experiences.

#### Contact Details:

Tel: +44 (0)1285 657978<br />
[info@theworkingmanager.com](mailto:info@theworkingmanager.com)<br />
[TWM LinkedIn Site](https://www.linkedin.com/company/551784)<br />

Phil Purver<br />
CEO<br />
+44 (0) 7768 104254<br />
[philip.purver@theworkingmanager.com](mailto:philip.purver@theworkingmanager.com)<br />
https://www.linkedin.com/in/philip-purver-686254/<br />

Melanie Small<br />
Solutions Director<br />
+44 (0) 7543 574614<br />
[melanie.small@theworkingmanager.com](mailto:melanie.small@theworkingmanager.com)<br />
        `,
    url: 'http://www.theworkingmanager.com',
  },
  {
    id: 'talenteam',
    image: `${STATIC_DOMAIN}/pages/landing/partners/talenteam.png`,
    video: `https://player.vimeo.com/video/443014326`,
    name: 'TalenTeam',
    introText: `
As one of the most experienced and qualified Partners of SAP SuccessFactors, TalenTeam helps you unlock the power of this world-class technology, letting you supercharge your Human Capital Management (HCM) potential and profit from a range of valuable business benefits.

Anders Pink powers curation and content recommendation in SAP products via TalenTeam including SAP JAM and Blend LXP.

Here’s TalenTeam Founder Sandeep Nahata to tell you more, and show you how Anders Pink powers curation in their great product set: 
        `,
    text: `Find out more on [SAP JAM and Anders Pink integration](https://www.sapappcenter.com/apps/38027/curated-content-for-sap-jam#!overview).
        <br><br>
        <img src="${STATIC_DOMAIN}/pages/landing/partners/qurate-logo.svg" style="width: 110px">
        Bring [curated content](https://talenteam.com/products/successfactors-apps-and-extensions/qurate) to your learners in SAP JAM and LMS.
        <br><br>
        <img src="${STATIC_DOMAIN}/pages/landing/partners/blend-logo.svg" style="width: 110px">
        Connect your employees to a world class Learning Experience Platform which blends together the best of SuccessFactors Learning, SAP JAM and any External Learning library into [a single destination](https://talenteam.com/products/successfactors-apps-and-extensions/blendlxp).
        `,
    url: 'https://talenteam.com/',
  },
  {
    id: 'degreed',
    image: `${STATIC_DOMAIN}/pages/landing/partners/degreed.svg`,
    video: `https://player.vimeo.com/video/473404624`,
    name: 'Degreed',
    introProductSheet: (
      <a
        className="btn btn-secondary btn-sm"
        href="/documents/AndersPink_Degreed_Product_Sheet_2022.pdf"
        target="_blank"
      >
        Download product sheet
      </a>
    ),
    introText: `
<p>Degreed is a fantastic upskilling platform that helps people continue to learn new skills and strengthen existing skills through formal and informal learning opportunities, including on-the-job learning, articles, videos, podcasts, books and blogs.<p>
    
<p>With our Anders Pink integration for Degreed, you can take Degreed even further:</p>
<ul>
<li class="mb-2">Automatically display trending content from the best of the web, on any topic, from the sources you want, powered by our award-winning curation tool</li>
<li class="mb-2">Connect curated content to your skills within Degreed, so it’s always relevant</li>
<li class="mb-2">Embed curated content on your Degreed homepage, plans or pathways, seamlessly</li>
</ul>

> product sheet link

<br/>
<br/>
<h4>Why integrate Anders Pink with Degreed?</h4>
<br/>
<ul>
  <li class="mb-2"><strong>Save time, stay smart:</strong> Easy for learners to access recent and relevant content on the skills and topics that matter. No need for learners or admins to trawl the web looking for the best 5 articles on remote working this month - that’s all done for you via Anders Pink.</li>
  <li class="mb-2"><strong>Save cost:</strong> All content curated through the Anders Pink Degreed integration is from the public web, and free to access.</li>
  <li class="mb-2"><strong>Take control of curation:</strong> You decide which topics, sources and skills you want to see content from. Make it as specific to your business, skills and learners as you want.</li>
  <li class="mb-2"><strong>Develop critical skills:</strong> All content is tagged to the relevant skills in Degreed. It complements the other content in your Degreed instance, and stays up to date, automatically, every day.</li>
  <li class="mb-2"><strong>Help people learn, faster:</strong> Emerging skills that you need to quickly support? Creating a new feed of curated content in Anders Pink takes minutes, and can immediately display relevant content within Degreed. Skills don’t stand still  - we help you keep pace.</li> 
  <li class="mb-2"><strong>Track progress:</strong> All content viewed via the Anders Pink integration in Degreed can be marked complete, and tracked within Degreed.</li>
</ul>
Here’s a video showing how the integration works:
    `,
    text: `#### Step-by-Step
Here’s how you bring the best of the web into Degreed, with Anders Pink:
  
1\\. **Connect Anders Pink and Degreed.** In your Anders Pink account, you’ll enter a secret code to connect the two apps. Just ask your Degreed contact to set this up, we can then help you enable it in Anders Pink.

2\\. **In Anders Pink, select or create the briefings you want.** A briefing is a content feed from the public web. You choose the topics, keywords, sites and sources you want to see content from - you’re in complete control. Or you can choose from over 150 pre-defined briefings on a range of business topics in 7 languages. 

3\\. **In Anders Pink, tag your briefings with the relevant skills.** Because the apps are connected, you can access your skills list within Anders Pink, this can be automated or we can upload a list of your skills for you to select from. 

4\\. **In Degreed, choose where you want to display briefings:**
- On the home page 
- In a pathway 
- In a plan
    
Or all of the above.

To display content from Anders Pink, you simply need to use a Degreed-specific iframe URL (supplied by Anders Pink), which can be inserted into a Degreed Course, Article or within a Plan using simple markup.

You can change your briefings, and where they’re displayed at any time.

Show a whole briefing or a representative set of articles, or a specific curated list that you’ve made on Anders Pink.

5\\. **That’s it!** Curated briefings will update automatically in Degreed. So there’s always something fresh and relevant for your learners, tightly connected to the skills that matter to you. 
<br/><br/>
#### How Do I Get Started?

Just [get in touch with us](/contact), or let your Degreed contact know you want to connect Anders Pink to Degreed. 

We’ll get you up and running in no time.
<br/><br/>
##### Technical and Security Details

Anders Pink is a Featured Provider within Degreed. The connection with Degreed is powered by their Provider API. 

End users in Degreed do not need an Anders Pink account or access to our platform. Their whole experience is within Degreed. Anders Pink does not store any personal end-user data of Degreed users. 

Just [get in touch](/contact) with any technical questions and we’re happy to help.
    `,
    url: 'https://degreed.com/',
    contactReason: 'Degreed integration inquiry',
  },
  {
    id: 'd2l',
    image: `${STATIC_DOMAIN}/pages/landing/partners/d2l-brightspace.png?1`,
    video: `https://player.vimeo.com/video/719988718`,
    name: 'D2L Brightspace',
    introProductSheet: (
      <a
        className="btn btn-secondary btn-sm"
        href="/documents/AndersPink_D2L_Brightspace_Product_Sheet_2022.pdf"
        target="_blank"
      >
        Download product sheet
      </a>
    ),
    introText: `

[D2L Brightspace](https://www.d2l.com/en-eu/) is a powerful Learning Platform which helps education and skill development whether it be in the classroom or at work. It works on any device, computer/laptop, tablet and your phone, giving learners the opportunity to learn whenever, wherever they want. Brightspace is available for k-12, higher education and corporate customers.

> product sheet link

<br />
<br />

<h4>Anders Pink in Brightspace: Curation made Easy, Automated and Integrated</h4>
    
<p>The Anders Pink Brightspace integration dynamically curates content from the best of the web, on any topic. Get fresh and relevant content every day, directly into Brightspace. Save time, keep your learners up to date.</p>
    
<p>Here’s a video showing how it works:</p>
    `,
    text: `
#### Why curate with Anders Pink in Brightspace? 

1\\. Complete Curation Control

Curate content on any topic, from any public sites and sources across the web. Instructors and Admins can create briefings (content feeds) with a combination of topics, keywords, sites and sources, e.g.

- [Top tips and strategies for sales teams from selected business sites](https://anderspink.com/briefing/80642/hard-skill-sales/3-months)
- [Latest leadership insights on building resilience and managing change](https://anderspink.com/briefing/80328/build-resilience-and-adapt-to-change-in-exceptional-circumstances/3-months)

Make a briefing on anything you want. Choose from your preferred sites and sources, which could be your top 5 preferred sites, or over 5,000 carefully selected business sources. Adjust it any time, in 7 languages. 

Get started fast: Choose from over 150 pre-defined briefings on the most popular business topics for learning, including leadership, sales, HR and compliance.

2\\. Save time and Cost with Automated updates 
  
You want to keep your learners up to date on the latest developments in Brightspace. Every day, millions of articles are published across the web on the topics that are relevant. But nobody has the time to scan them all, share the best links, and keep doing that every day, for all your audiences. 

With Anders Pink, you don’t have to. We’ve made content curation easy, automatic, and fully integrated with Brightspace. Your carefully curated briefings update in Brightspace automatically, refreshing every day.  You can also select specific articles and manually curate content for a specific audience or need. 

3\\. Easy Integration to drive learner engagement in Brightspace

It’s simple to push a live briefing or curated set of articles from Anders Pink to Brightwave. We’ve built an app using the “Insert Stuff’ functionality. All you need is an account on the Anders Pink app, a key to connect the apps, and you’re all set to go. 

- Add trending content to a course to keep it fresh, automatically. Your leadership course can automatically display the top articles posted from trusted business sites today, or over whatever time period you want. 
- Add selected articles to a course as pre or post-assignments 
- Add fresh content into discussion forums to read, discuss and comment on  
<br />
  
#### How Do We Get Started?

Anders Pink is available as an integration in the [Brightspace Appfinder store](https://appfinder.brightspace.com/app-details/?appId=5fce1e7121787601754999c6).

In the meantime: To get started, [get in touch with us](/contact), or ask your D2L/Brightspace contacts to set you up. 
    `,
    url: 'https://www.d2l.com/en-eu/',
  },
  {
    id: 'deer-meadow',
    image: `${STATIC_DOMAIN}/pages/landing/partners/deer-meadow.svg`,
    video: `https://player.vimeo.com/video/473847514`,
    name: 'Deer Meadow Consulting AB',
    introText: `<h4>Background</h4> 
    <p>
    After many years of work with Content Management Systems for IT companies such as CGI, Deer Meadow was formed with a focus on the CMS <a href="https://www.sitevision.se/">SiteVision</a>. SiteVision is a tool used by more than 60% of Sweden's municipalities and many companies in various sectors. Thanks to this, we reach many customers in the network that exists around the CMS.</p>
    <p>
    Among other things, we have built a learning platform for Scania where we have made an integration with Anders Pink. The integration simplifies for editors to be able to easily present different briefings or saved folders seamlessly on their learning platforms all over the world.
    </p>
    <p>
    Here’s a video showing how you can integrate curated and trending content from Anders Pink into SiteVision:
    </p>`,
    text: `
  Here’s a little more about Deer Meadow:
  
  #### Location
  
  Stockholm, Sweden

  #### Key Learning Platform / Product
  
  We have an integration between Anders Pink and [SiteVision](https://www.sitevision.se/)

  #### Product summary

  The integration today is showing briefings and saved folders. We are looking into extending the functionalities. You can find out more at [https://www.deermeadow.se/anderspink](https://www.deermeadow.se/anderspink)

  #### Key industries / markets / territories

  Both public and private sector. We mainly work in Sweden

  #### Contact details
  
  Contact us via our site [https://www.deermeadow.se/contact-us](https://www.deermeadow.se/contact-us)

    `,
    url: 'https://degreed.com/',
  },
  {
    id: 'huler',
    image: `${STATIC_DOMAIN}/pages/landing/partners/huler-logo.png`,
    // video: `https://player.vimeo.com/video/473847514`,
    name: 'Deer Meadow Consulting AB',
    introText: `
<p style="font-size: 1.5rem">Huler: Welcome to your digital home</p>
<p style="font-size: 1.5rem">Your work and personal life, systems and tech, all in one space.</p>
`,
    text: `
🚀 Launching April 2021

Reserve your workspace today: [huler.io](https://huler.io/)

- Putting people at the heart of your business.
- Connect software and communicate with colleagues like never before.
- Increase productivity and reduce the fatigue of digital burnout.
- See what systems, sites and software are used most in your business.
- Improve your employee experience and increase productivity. 
- Provide employees with more timely, meaningful and relevant content.

<br />
<iframe width="560" height="315" src="https://www.youtube.com/embed/IayqNi_xi7c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `,
    url: 'https://huler.io/',
  },
  {
    id: 'learninghubz',
    image: `${STATIC_DOMAIN}/pages/landing/partners/learninghubz.png`,
    // video: `https://player.vimeo.com/video/473847514`,
    name: 'Learninghubz',
    introText: ``,
    text: `
YouTube and MOOC platforms have revolutionized the way people learn, offering billions of individuals the opportunity to learn any skill, anytime, anywhere, and quickly or in-depth, and for free. It’s time for L&D to join this revolution!

Learninghubz curates the very best learning resources available from YouTube and popular MOOCs on +120 skills, saving L&D time and budget from buying expensive “off-the-shelf” course libraries or creating content.
    
But finding, filtering and sharing quality takes time. Time you probably don’t have. You could just pull in some RSS feeds - but then you have no control over what gets displayed.
    
**Full Curation, Complete Control: Anders Pink Plugin for WordPress**
    
Our curation plugin for WordPress makes it easier to find and share great content from anywhere on the web that your audience will love.

- <b>10.000+ videos,</b> from #shots and quick tips, to tutorials and crash courses
- <b>500+ courses and skill playlists</b>
- <b>Add your content,</b> create learning pages, playlists and learning paths
- <b>Content curation on demand</b>

And with Anders Pink integration your teams will also have

- <b>High quality web content on any skill or business topic,</b>  from the sources you want, updated daily

Learninghubz + Anders Pink means

- <b>Modern upskllling:</b> your employees will be able to learn from diversified and updated resources (articles, videos and courses), in one modern learning platform 
- <b>Stay up-to-date:</b> the web is always updated, so will your employees
- <b>Cost-savings:</b> no need to replace you LMS, or buy or content libraries
- <b>Time-saving:</b> instant access to high-quality learning resources, on any topic 
- <b>Learning agility:</b> empower everyone to learn anytime and anywhere

<b>[Visit our website](https://www.learninghubz.com/) to learn more or request a free trial.</b>
`,
    url: 'https://www.learninghubz.com/',
    bottomImage: `${STATIC_DOMAIN}/pages/landing/partners/Learninghubz-bottom.png`,
  },
]

@connect((state) => ({
  app: state.app,
}))
export default class PartnersDetail extends React.Component {
  constructor(props) {
    super()
    const partner = partners.find((partner) => partner.id === props.params.id)
    if (!partner) {
      window.location = '/partners'
    }
    this.state = {
      partner: partner,
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.params.id !== this.state.partner) {
      const partner = partners.find(
        (partner) => partner.id === newProps.params.id,
      )
      if (!partner) {
        window.location = '/partners'
      }
      this.setState({
        partner: partner,
      })
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    const { partner } = this.state

    return (
      <div className={pageStyles.root}>
        <div className={styles.partnerImageContainer}>
          <div className={styles.partnerImage}>
            <img
              src={partner.image}
              style={
                partner.imageHeight ? { maxHeight: partner.imageHeight } : {}
              }
            />
          </div>
        </div>

        {!!partner.introText && (
          <div className={pageStyles.section}>
            <div className={pageStyles.textContainer}>
              <ReactMarkdown
                source={partner.introText}
                escapeHtml={false}
                renderers={{
                  blockquote: (props) => partner.introProductSheet,
                }}
              />
            </div>
          </div>
        )}

        {!!partner.video && (
          <div className={pageStyles.section}>
            <div className={styles.videoContainer}>
              <ReactPlayer controls={true} url={partner.video} />
            </div>
          </div>
        )}

        <div className={pageStyles.section}>
          <div className={pageStyles.textContainer}>
            <ReactMarkdown source={partner.text} escapeHtml={false} />
          </div>
        </div>

        <div style={{ margin: 120 }}>
          <div
            className={styles.partnerImageContainer}
            style={{ backgroundColor: 'transparent' }}
          >
            <div className={styles.partnerImage}>
              <img
                src={partner.bottomImage}
                alt="alt"
                style={{ maxWidth: 600 }}
              />
            </div>
          </div>
        </div>

        {!!partner.contactReason && (
          <div className={pageStyles.section}>
            <ContactForm type={partner.contactReason} />
          </div>
        )}
      </div>
    )
  }
}
