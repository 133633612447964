import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import Loader from 'app/components/Loader'

@graphql(
  gql`
    mutation($token: String!) {
      emailUnsubscribe(token: $token)
    }
  `,
  {
    props: ({ mutate }) => ({
      emailUnsubscribe: (token) =>
        mutate({
          variables: {
            token,
          },
        }),
    }),
  },
)
export default class AccountUnsubscribePage extends React.Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      state: null,
      error: null,
    }
  }
  componentDidMount() {
    return this.props
      .emailUnsubscribe(this.props.params.token)
      .then((response) => {
        this.setState({
          checked: true,
          state: response.data.emailUnsubscribe,
        })
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          this.setState({
            checked: true,
            error: err.graphQLErrors[0].message,
          })
        } else {
          this.setState({
            checked: true,
            error: err.message,
          })
        }
      })
  }
  render() {
    if (!this.state.checked) {
      return (
        <div style={{ maxWidth: '600px' }}>
          <Loader />
        </div>
      )
    }

    if (this.state.error) {
      return (
        <div style={{ maxWidth: '600px' }}>
          <div className="alert alert-danger" role="alert">
            {this.state.error}
          </div>
        </div>
      )
    }

    return (
      <div style={{ maxWidth: '600px' }}>
        <h3>Successfully unsubscribed</h3>
        <br />
        <p>
          Thanks, you've been unsubscribed! If you'd like to resubscribe in
          future you can do so by visiting your account settings.
        </p>
      </div>
    )
  }
}
