import React, { Component } from 'react'
import TwitterLoginButton from 'app/containers/TwitterLoginButton'

export default class LinkTwitterPanel extends Component {
  render() {
    return (
      <div>
        <p>
          We'll be able to show you the content that is getting shared most by
          your network.
        </p>

        <TwitterLoginButton linkAccount={true} />
      </div>
    )
  }
}
