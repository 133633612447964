const klm = `${STATIC_DOMAIN}/pages/new-homepage/Airfrance.png`
const avon = `${STATIC_DOMAIN}/pages/new-homepage/Avon.png`
const capgemini = `${STATIC_DOMAIN}/pages/new-homepage/Capgemini.png`
const sodexo = `${STATIC_DOMAIN}/pages/new-homepage/Sodexo.png`
const scania = `${STATIC_DOMAIN}/pages/new-homepage/Scania.png`

const enterpriseLogos = [
  { id: 1, src: klm, name: 'klm', style: { height: '25px' } },
  { id: 2, src: avon, name: 'avon', style: { height: '25px' } },
  {
    id: 3,
    src: capgemini,
    name: 'capgemini',
    style: { height: '40px', marginTop: '9px' },
  },
  {
    id: 4,
    src: sodexo,
    name: 'sodexo',
    style: { height: '40px', marginBottom: '17px' },
  },
  {
    id: 5,
    src: scania,
    name: 'scania',
    style: { height: '35px' },
  },
]

export default enterpriseLogos
