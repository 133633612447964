import React from 'react'
import { connect } from 'react-redux'
import styles from './PartnersTotara.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'
import Carousel from 'react-elastic-carousel'
import Quote from '../new-homepage/components/Quotes/Quote'

@connect((state) => ({
  app: state.app,
}))
export default class PartnersTotara extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
        darkNav: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
        darkNav: true,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div className={styles.cover}>
          <div className={styles.title}>
            Curate fresh content from the web to power your Totara Talent
            Experience Platform
          </div>

          <div className={styles.overview}>
            <div className={styles.points}>
              <div>{'>'} High quality content on any topic</div>
              <div>
                {'>'} Save time and effort, curate content automatically
              </div>
              <div>{'>'} Upskill and reskill at pace</div>
            </div>
            <div className={styles.logo}>
              <img
                style={{ maxWidth: '200px' }}
                src="/totara/logo-totara-og-image.jpg"
              />
            </div>
          </div>

          <div className={styles.links}>
            <Link to="/contact?reason=Book%20a%20demo">
              <button className={styles.ctaBtn}>Book a consultation</button>
            </Link>
            <a
              href="/totara/AndersPink_Totara_Product_Sheet_2022.pdf?v2"
              target="_blank"
            >
              <button className={styles.downloadBtn}>
               Download product sheet
              </button>
            </a>
          </div>
        </div>
        {/* 
        <div className={styles.logo}>
          <img src={integration.image} />
        </div> */}

        <div className={styles.pinkSection}>
          <div className={styles.innerPinkSection}>
            <div className={styles.videoContainer}>
              <div className={styles.videoLeft}>
                <p>
                  The Totara Talent Experience Platform works best when it's
                  powered with trending, relevant content. That's where Anders
                  Pink comes in. Our pre-configured integrations for Totara
                  Learn and Engage bring the best of the web, on any topic or
                  skill, into the experience. So you can drive continuous
                  learning and insightful conversations, automatically.
                </p>

                <p>
                  What advantages does Anders Pink bring to{' '}
                  <a
                    className="link"
                    target="_blank"
                    href="https://www.totaralearning.com/en/products/anders-pink-content-curation"
                  >
                    Totara Talent Experience Platform?
                  </a>
                </p>

                <p>
                  <strong>Save time</strong>: No need to trawl the web looking
                  for the best learning content for your teams - with Anders
                  Pink, that’s all done for you.
                </p>

                <p>
                  <strong>Reduce costs</strong>: Make use of quality content
                  that already exists. All content curated by Anders Pink is
                  from the web and free to access - you just need a license to
                  Anders Pink.
                </p>

                <p>
                  <strong>Enable your teams</strong> to access the learning they
                  need, exactly when they need it: Flow fresh and up-to-date
                  content into your workspaces linked to your Projects,
                  Departments and Topics or display briefings in Learn blocks.
                  It’s that easy!
                </p>

                <p>
                  <strong>Accelerate skills development</strong>: Enable your
                  teams to build skills more quickly by providing the latest and
                  best content in the flow of work - what they need, when they
                  need it.
                </p>

                <p>
                  <strong>Boost employee engagement</strong>: Your teams can
                  collaborate, share, like and comment on articles within the
                  Discussion area in Engage Workspaces.
                </p>

                <p>
                  <strong>
                    Keep your teams coming back to learn and collaborate
                  </strong>
                  : Content refreshes every day, automatically, so there is
                  always something new to learn. Anders Pink is a scalable, AI
                  powered content curation tool for Totara's TXP, consistently
                  delivering the latest content on fast moving topics right into
                  the flow of work. With the Anders Pink plug-in for both Engage
                  and Learn, delivering the best web content for your learners
                  will never feel hard again.
                </p>
              </div>

              <div className={styles.videoRightFlex}>
                <iframe
                  src="https://player.vimeo.com/video/697459549"
                  width="640"
                  height="339"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.greySection}>
          <div className={styles.innerGreySection}>
            <div className={styles.subsection}>
              <div className={styles.videoContainer}>
                <div className={styles.videoLeft}>
                  <div className={styles.sectionTitle}>
                    AI Powered Content Curation
                  </div>
                  <div className={styles.sectionSubTitle}>
                    Drive Engagement within your Totara Engage Workspaces
                  </div>
                  <p>
                    - Anders Pink provides the latest and most relevant content
                    directly into Totara's TXP every day, keeping learners up to
                    date.
                  </p>
                  <p>
                    - Control the sites and sources content comes from and
                    enable learners to access the latest information.
                  </p>
                  <p>
                    - With tailored briefings related to the topics in the
                    learning library and Workspaces, you can encourage learners
                    to investigate deeper as new information and content is
                    added.
                  </p>
                </div>
                <div className={styles.videoRight}>
                  <div className={styles.image}>
                    <img
                      src="/totara/totara-engage.png"
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.subsection}>
              <div className={styles.videoContainer}>
                <div className={styles.videoLeft}>
                  <div className={styles.image}>
                    <img
                      src="/totara/anderspink.png"
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                </div>
                <div className={styles.videoRight}>
                  <div className={styles.sectionSubTitle}>
                    Let Anders Pink do the heavy lifting on content curation
                  </div>
                  <p>
                    - Automate the process of finding, organizing and delivering
                    the best web content to learners and save educators time and
                    effort.
                  </p>
                  <p>
                    - Remove the need for copy-and-paste by delivering new
                    content from the web directly into your Totara TXP.
                  </p>
                  <p>
                    - Keep courses fresh and relevant with regular content
                    updates as fresh, new content is published to the web.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.subsection}>
              <div className={styles.videoContainer}>
                <div className={styles.videoLeft}>
                  <div className={styles.sectionSubTitle}>
                    Deliver content on fast moving topics into the flow of work
                  </div>
                  <p>
                    - Utilize Anders Pink’s skills-based briefings for
                    reskilling and upskilling using the latest thinking and
                    insights.
                  </p>
                  <p>
                    - As skills, topics and trends emerge and change, it’s easy
                    to keep pace by creating additional briefings on new and
                    relevant content.
                  </p>
                  <p>
                    - Use Anders Pink’s automatic insights to improve learner
                    retention and keep your organization’s learners on top of
                    industry trends and niche sectors.
                  </p>
                </div>
                <div className={styles.videoRight}>
                  <div className={styles.image}>
                    <img
                      src="/totara/totara-learn.png"
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.subsection}>
              <div className={styles.sectionTitle}>How it works</div>

              <div className={styles.howSection}>
                <div className={styles.howStep}>
                  <div>
                    <span className={styles.howStepNumber}>1.</span>
                    <span className={styles.howStepTitle}>Discover</span>
                  </div>
                  <p>
                    We'll work with you to identify how automated curation best
                    fits within your Totara Engage Talent Experience Platform.
                  </p>
                </div>

                <div className={styles.howStep}>
                  <div>
                    <span className={styles.howStepNumber}>2.</span>
                    <span className={styles.howStepTitle}>Curate</span>
                  </div>
                  <p>
                    Get curating instantly with fresh, customized and
                    ready-to-go content feeds on the skills and topics that
                    matter most
                  </p>
                </div>

                <div className={styles.howStep}>
                  <div>
                    <span className={styles.howStepNumber}>3.</span>
                    <span className={styles.howStepTitle}>Launch</span>
                  </div>
                  <p>
                    Save time and empower your learners to upskill and stay
                    smart. Deliver value to your business every day, with
                    automated content curation.
                  </p>
                </div>
              </div>

              <p style={{ textAlign: 'center' }} className="mt-4">
                <Link to="/contact?reason=Book%20a%20demo">
                  <button className={styles.ctaBtn}>Book a consultation</button>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className={styles.greySection}>
          <div className={styles.innerGreySection}>
            <div className={styles.videoContainer}>
              <div className={styles.videoLeft}>
                <img
                  src="/pages/new-homepage/ctaMain.png"
                  style={{ maxWidth: '80%' }}
                />
              </div>
              <div className={styles.videoRight}>
                <div className={styles.pinkSectionTitle}>
                  Anders Pink is packed full of features that enable you to
                  deliver fresh content every day...
                </div>
                <p>
                  - Targeted freemium content curated daily from the web and
                  automatically added to Totara's Talent Experience Platform.
                </p>
                <p>
                  - Seamlessly integrated with Totara Engage Workspaces,
                  automatically adding new content on trends, skills and topics
                  curated from the web.
                </p>
                <p>
                  - Connect Anders Pink briefings (feeds of content) with Totara
                  Engage libraries, to provide target content for members.
                </p>
                <p>
                  - Facilitate social learning and discussion with fresh
                  articles posted daily to the Workspace discussion thread.
                </p>
                <p>
                  - Enable workspace members and learners to search for content
                  within Totara, using the global search feature to find the
                  latest content published on topics that matter to them.
                </p>
                <p>
                  - Add Anders Pink content to playlists within the Totara
                  content library, where learners can comment, share and like
                  articles.
                </p>
                <p>
                  - Automatically tag content from Anders Pink with skills,
                  fuelling the Engage recommendations engine and driving
                  engagement.
                </p>
                <p>
                  - Report on resource engagement with Totara’s powerful
                  built-in reports.
                </p>
                <p className={styles.pinkSectionSubTitle}>
                  Book a consultation today and discover how Anders Pink's
                  content curation tool for the Totara TXP, automates the
                  delivery of curated content from the web.
                </p>
              </div>
            </div>

            <div className="mt-4 mb-4">
              <div className={styles.links} style={{ textAlign: 'center' }}>
                <Link to="/contact?reason=Book%20a%20demo">
                  <button className={styles.ctaBtn}>Book a consultation</button>
                </Link>
                <a
                  href="/totara/AndersPink_Totara_Product_Sheet_2022.pdf?v2"
                  target="_blank"
                >
                  <button className={styles.downloadBtn}>
                    Download product sheet
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className={styles.greySection}>
            <div className={styles.innerGreySection}>
              <Carousel
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 660, itemsToShow: 2 },
                  {
                    width: 1020,
                    itemsToShow: 3,
                    pagination: false,
                    showArrows: false,
                  },
                ]}
              >
                <Quote
                  quoteNum="QuoteCard1"
                  bodyText="Anders Pink has saved time and resources in sourcing content. Time is precious - it is very advantageous to access relevant content in one location"
                  companyName="ENTERPRISE IRELAND"
                />
                <Quote
                  quoteNum="QuoteCard2"
                  bodyText="Anders Pink is crucial for us to offer the latest
            and greatest content on key topics for our business."
                  companyName="SCANIA"
                />

                <Quote
                  quoteNum="QuoteCard3"
                  bodyText={`People do not have time for formal learning, so this informal
                "in-the-flow" of work is just necessary for success. 
                The the more open and connected the learning platform can be, the
                easier it will be to use... Curation tools like Anders Pink - soon
                we will all be talking to these systems.`}
                  companyName="JOSH BERSIN"
                />
              </Carousel>
            </div>
          </div>

          <div style={{ textAlign: 'center', padding: '2rem 0' }}>
            <div
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                marginBottom: '1rem',
              }}
            >
              Integration support
            </div>
            <div className={styles.links}>
              <Link to="/support/totara-learn">
                <button className={styles.ctaBtn}>Totara Learn</button>
              </Link>
              <Link to="/support/totara-engage" style={{ paddingLeft: '1rem' }}>
                <button className={styles.ctaBtn}>Totara Engage</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
