import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import briefingInfoFragment from 'briefings/fragments/briefingInfo'
import EditBriefingForm from 'briefings/create/containers/EditBriefingForm'
import { browserHistory } from 'react-router'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import findGraphQLError from 'app/library/findGraphQLError'

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
@graphql(
  gql`
    query($id: ID!) {
      briefing(id: $id) {
        id
        name
        description
        type
        teamId
        sourceFields {
          sources {
            type
            value
          }
          keywords
          includedWords
          excludedWords
          blockedDomains
          whitelistDomains
        }
        keywordFields {
          keywords
          includedWords
          excludedWords
          domains
          blockedDomains
          useBusinessDomains
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
@graphql(
  gql`
    mutation($id: ID, $name: String!, $teamId: ID) {
      copyBriefing(id: $id, name: $name, teamId: $teamId)
    }
  `,
  {
    name: 'copyBriefing',
    options: {
      refetchQueries: ['appQuery'],
    },
  },
)
export default class CopyBriefingPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.data.loading) {
      return false
    }

    const briefing = this.props.data.briefing
    if (!briefing.type) {
      return (
        <div>
          <span>Sorry, you don't have permission to move this briefing</span>
        </div>
      )
    }

    let teams = this.props.appQueryResult.user.teams || []

    return (
      <div style={{ maxWidth: '400px' }}>
        <h5>
          Copy the briefing "{briefing.name}" to your personal view or any
          teams:
        </h5>
        <br />

        <CopyBriefingForm
          teams={teams}
          initialValues={{
            teamId: briefing.teamId,
            name: briefing.name,
          }}
          onSubmit={(values) => {
            return this.props
              .copyBriefing({
                variables: {
                  id: briefing.id,
                  name: values.name,
                  teamId: values.teamId,
                },
              })
              .then((response) => {
                let teamName =
                  values.teamId == 1
                    ? 'your personal view'
                    : 'team ' +
                      teams.find((team) => team.id == values.teamId).name

                this.props.dispatch({
                  type: 'ADD_NOTIFICATION',
                  content: `The briefing '${values.name}' has been succesfully copied to ${teamName}`,
                })

                browserHistory.goBack()
              })
              .catch((err) => {
                throw new SubmissionError({
                  _error: findGraphQLError(err),
                })
              })
          }}
        />
      </div>
    )
  }
}

@reduxForm({
  form: 'copyBriefing',
})
class CopyBriefingForm extends React.Component {
  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit(this.props.onSubmit)}>
          <div className="form-group">
            <label>Team to copy to:</label>
            <Field name="teamId" component="select" className="form-control">
              <option value={1}>Personal view</option>

              {this.props.teams.map((team) => (
                <option key={team.id} value={team.id}>
                  Team: {team.name}
                </option>
              ))}
            </Field>
          </div>

          <div className="form-group">
            <label>Name for the new briefing:</label>
            <Field
              name="name"
              component="input"
              type="text"
              className="form-control"
            />
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div className="form-group">
            <button className="btn btn-primary btn-sm">Copy briefing</button>
          </div>
        </form>
      </div>
    )
  }
}
