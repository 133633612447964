import React from 'react'

import BrowseCategories from 'discover/containers/BrowseCategories'

export default class BrowsePage extends React.Component {
  render() {
    return (
      <div>
        <BrowseCategories />
      </div>
    )
  }
}
