import React from 'react'
import { connect } from 'react-redux'
import { DropdownMenu } from 'app/components/DropdownMenu'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import articleInfoFragment from 'articles/fragments/articleInfo'
import styles from './NotificationIcon.css'
import moment from 'moment'
import uniq from 'lodash/uniq'
import { Link } from 'react-router'
import { browserHistory } from 'react-router'
import axios from 'axios'

@connect((state) => ({
  lastCheckedNotificationsAt: state.app.appQueryResult.user
    ? state.app.appQueryResult.user.lastCheckedNotificationsAt
    : null,
}))
@graphql(
  gql`
    mutation {
      updateNotificationCheckTime
    }
  `,
  {
    props: ({ mutate }) => ({
      updateNotificationCheckTime: () =>
        mutate({
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
@graphql(gql`
  query {
    session {
      user {
        id
        notifications {
          team {
            id
            name
          }
          article {
            ...articleInfo
          }
        }
      }
      team {
        id
      }
    }
  }
  ${articleInfoFragment}
`)
export default class NotificationIcon extends React.Component {
  render() {
    // How many are after the date?
    let numUnseen = 0

    if (
      !this.props.data.loading &&
      this.props.data.session &&
      this.props.data.session.user.notifications.length > 0
    ) {
      for (let notification of this.props.data.session.user.notifications) {
        let date = null
        let article = notification.article

        if (article.comments.length > 0) {
          date = article.comments[article.comments.length - 1].date
        } else if (article.upvotes.length > 0) {
          date = article.upvotes[article.upvotes.length - 1].date
        } else if (article.saves.length > 0) {
          date = article.saves[article.saves.length - 1].date
        }

        if (
          this.props.lastCheckedNotificationsAt === null ||
          date > this.props.lastCheckedNotificationsAt
        ) {
          numUnseen++
        }
      }
    }

    try {
      return (
        <DropdownMenu
          top={true}
          onOpen={() => this.props.updateNotificationCheckTime()}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.5rem',
              color: '#555',
            }}
          >
            <i className="fa fa-globe" />
            {numUnseen > 0 && (
              <div className={styles.notifictionCount}>
                <span>{numUnseen}</span>
              </div>
            )}
          </div>
          <div>
            {this.props.data.loading && (
              <div className={styles.placeholderText}>Loading...</div>
            )}
            {!this.props.data.loading &&
              this.props.data.session &&
              this.props.data.session.user.notifications.length === 0 && (
                <div className={styles.placeholderText}>
                  No notifications found
                </div>
              )}
            {!this.props.data.loading &&
              this.props.data.session &&
              this.props.data.session.user.notifications.length > 0 && (
                <div className={styles.activities}>
                  {this.props.data.session.user.notifications.map(
                    (notification, index) => (
                      <div key={index} className={styles.activity}>
                        {this.renderArticle(notification)}
                      </div>
                    ),
                  )}
                </div>
              )}
          </div>
        </DropdownMenu>
      )
    } catch (err) {
      console.log(err)
    }
  }
  getUsersString(numuniqueUsers) {
    let usersString = ''
    if (numuniqueUsers == 2) {
      usersString = 'and 1 other'
    } else if (numuniqueUsers > 2) {
      usersString = `and ${numuniqueUsers - 1} others`
    }
    return usersString
  }
  renderDate(date) {
    return <span className={styles.date}>{moment(date).fromNow()}</span>
  }
  renderUser(user) {
    return (
      <span className={styles.user}>
        <img className={styles.image} src={user.image} />
      </span>
    )
  }
  renderArticle(notification) {
    let article = notification.article
    let user = null
    let message = null

    if (article.comments.length > 0) {
      const lastComment = article.comments[article.comments.length - 1]
      const usersString = this.getUsersString(
        uniq(article.comments.map((comment) => comment.user.id)).length,
      )
      const date = this.renderDate(lastComment.date)
      user = this.renderUser(lastComment.user)
      message = (
        <span>
          <strong>
            {lastComment.user.firstName} {lastComment.user.lastName}
          </strong>{' '}
          {usersString} commented on{' '}
          <span className={styles.articleTitle}>{article.title}</span>: "
          {lastComment.text}" {date}
        </span>
      )
    } else if (article.upvotes.length > 0) {
      const lastUpvote = article.upvotes[article.upvotes.length - 1]
      const usersString = this.getUsersString(
        uniq(article.upvotes.map((upvote) => upvote.user.id)).length,
      )
      const date = this.renderDate(lastUpvote.date)
      user = this.renderUser(lastUpvote.user)
      message = (
        <span>
          <strong>
            {lastUpvote.user.firstName} {lastUpvote.user.lastName}
          </strong>{' '}
          {usersString} recommended{' '}
          <span className={styles.articleTitle}>{article.title}</span> {date}
        </span>
      )
    } else if (article.saves.length > 0) {
      const lastSave = article.saves[article.saves.length - 1]
      const usersString = this.getUsersString(
        uniq(article.saves.map((save) => save.user.id)).length,
      )
      const date = this.renderDate(lastSave.date)
      user = this.renderUser(lastSave.user)
      message = (
        <span>
          <strong>
            {lastSave.user.firstName} {lastSave.user.lastName}
          </strong>{' '}
          {usersString} pinned{' '}
          <span className={styles.articleTitle}>{article.title}</span> {date}
        </span>
      )
    }

    return (
      <span
        onClick={() => {
          // Change team if needed...

          if (
            !this.props.data.session.team ||
            this.props.data.session.team.id != notification.team.id
          ) {
            axios
              .get(
                `/iapi/auth/changeTeam?id=${
                  notification.team.id
                }&time=${Date.now()}`,
              )
              .then((response) => {
                if (response.data.status === 'success') {
                  window.location.href = `/articles/${article.id}`
                }
              })
          } else {
            browserHistory.push(`/articles/${article.id}`)
          }
        }}
        className={styles.activityLink}
      >
        <div className={styles.left}>{user}</div>
        <div className={styles.right}>
          {message}

          <div className={styles.team}>From team: {notification.team.name}</div>
        </div>
      </span>
    )
  }
}
