exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Quote\\.module__QuoteCard___2vKgT {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  /* max-width: 330px; */\n  margin: 0 30px;\n}\n\n.Quote\\.module__QuoteCardTop___2Bt9k {\n  margin: 0 auto;\n  width: 100%;\n}\n\n.Quote\\.module__QuoteLine1___3_AgC,\n.Quote\\.module__QuoteLine2___35UI5 {\n  width: 35%;\n  height: 20px;\n  border-bottom: 2px solid var(--ap-pink);\n}\n\n.Quote\\.module__QuoteLine1___3_AgC {\n  float: left;\n  margin-left: 5px;\n}\n\n.Quote\\.module__QuoteLine2___35UI5 {\n  float: right;\n  margin-right: 5px;\n}\n\n.Quote\\.module__QuoteMark___2740l {\n  float: middle;\n  height: 31px;\n  padding: 3px 15px 0px;\n}\n\n.Quote\\.module__QuoteBody___3sgcu {\n  color: #666;\n  font-size: 1.1rem;\n  margin: 20px 0;\n}\n\n.Quote\\.module__QuoteCompany___3uDBj {\n  color: var(--ap-pink);\n}\n\n@media screen and (max-width: 500px) {\n  .Quote\\.module__QuoteCard___2vKgT {\n    margin: 0 20px;\n  }\n}\n\n@media screen and (max-width: 460px) {\n  .Quote\\.module__QuoteLine1___3_AgC,\n  .Quote\\.module__QuoteLine2___35UI5 {\n    width: 30%;\n  }\n\n  .Quote\\.module__QuoteCard___2vKgT {\n    margin: 0 10px;\n  }\n}\n\n@media screen and (max-width: 400px) {\n  .Quote\\.module__QuoteLine1___3_AgC,\n  .Quote\\.module__QuoteLine2___35UI5 {\n    width: 25%;\n  }\n\n  .Quote\\.module__QuoteCard___2vKgT {\n    margin: 0 0px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"QuoteCard": "Quote.module__QuoteCard___2vKgT",
	"QuoteCardTop": "Quote.module__QuoteCardTop___2Bt9k",
	"QuoteLine1": "Quote.module__QuoteLine1___3_AgC",
	"QuoteLine2": "Quote.module__QuoteLine2___35UI5",
	"QuoteMark": "Quote.module__QuoteMark___2740l",
	"QuoteBody": "Quote.module__QuoteBody___3sgcu",
	"QuoteCompany": "Quote.module__QuoteCompany___3uDBj"
};