exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__partners___3HhLu {\n  padding: 1rem;\n}\n.styles__partner___2tvjS {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 1rem 0;\n  border-bottom: 2px solid #e13b66;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n.styles__partner___2tvjS:last-of-type {\n  border-bottom: 0 none;\n}\n.styles__partnerImage___-W1Oi {\n  width: 20%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.styles__partnerImage___-W1Oi img {\n  max-width: 80%;\n  max-height: 60%;\n  -o-object-fit: contain;\n     object-fit: contain;\n}\n.styles__partnerText___Wkgh9 {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  padding: 1rem;\n}\n.styles__partnerLink___2nR6j {\n  width: 15%;\n\n  display: -webkit-box;\n\n  display: -ms-flexbox;\n\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n\n@media screen and (max-width: 512px) {\n  .styles__partnerLink___2nR6j {\n    width: 100%;\n  }\n  .styles__partnerImage___-W1Oi {\n    width: 100%;\n    margin-top: 1rem;\n  }\n  .styles__partnerImage___-W1Oi img {\n    max-width: 50%;\n    max-height: 60px;\n    -o-object-fit: contain;\n       object-fit: contain;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"partners": "styles__partners___3HhLu",
	"partner": "styles__partner___2tvjS",
	"partnerImage": "styles__partnerImage___-W1Oi",
	"partnerText": "styles__partnerText___Wkgh9",
	"partnerLink": "styles__partnerLink___2nR6j"
};