import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './TeamActivityPanel.css'
import panelStyles from './Panel.css'
import articleInfoFragment from 'articles/fragments/articleInfo'
import moment from 'moment'
import uniq from 'lodash/uniq'
import ArticleActivity from 'articles/containers/ArticleActivity'

@graphql(gql`
  query {
    session {
      team {
        id
        name
        recentActivityArticles(limit: 10) {
          ...articleInfo
        }
      }
    }
  }
  ${articleInfoFragment}
`)
export default class TeamActivityPanel extends React.Component {
  constructor() {
    super()
    this.state = {
      retried: false,
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && !nextProps.data.session && !this.state.retried) {
      this.setState({
        retried: true,
      })
      this.props.data.refetch()
    }
  }
  render() {
    let articles = []

    if (
      !this.props.data.loading &&
      this.props.data.session &&
      this.props.data.session.team
    ) {
      articles = this.props.data.session.team.recentActivityArticles.slice(0)
      articles.sort((a, b) => {
        let latestDateA = null
        let latestDateB = null

        if (a.comments.length > 0) {
          latestDateA = a.comments[a.comments.length - 1].date
        } else if (a.upvotes.length > 0) {
          latestDateA = a.upvotes[a.upvotes.length - 1].date
        } else if (a.saves.length > 0) {
          latestDateA = a.saves[a.saves.length - 1].date
        }

        if (b.comments.length > 0) {
          latestDateB = b.comments[b.comments.length - 1].date
        } else if (b.upvotes.length > 0) {
          latestDateB = b.upvotes[b.upvotes.length - 1].date
        } else if (b.saves.length > 0) {
          latestDateB = b.saves[b.saves.length - 1].date
        }

        return latestDateA > latestDateB ? -1 : 1
      })
    }

    return (
      <div>
        {this.props.data.loading && (
          <div className={panelStyles.loading}>Loading...</div>
        )}
        {!this.props.data.loading &&
          this.props.data.session &&
          this.props.data.session.team && (
            <div>
              {articles.length === 0 && (
                <div>Your team has no recent activity.</div>
              )}

              {articles.map((article, index) => (
                <div key={index} className={styles.activity}>
                  <ArticleActivity article={article} />
                </div>
              ))}
            </div>
          )}
      </div>
    )
  }
}
