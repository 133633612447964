import React, { useState } from 'react'
import { Link } from 'react-router'

import ReactMarkdown from 'react-markdown'

import styles from './SupportPage.module.css'
import pageStyles from 'content/pages/styles.css'

const faqs = [
  {
    question: 'Where can I download the plug-ins from?',
    answer: `
We add our plugins to [GitHub](https://github.com/anderspink) 

Totara Learn https://github.com/anderspink/totara-block_anderspink 

Totara Engage https://github.com/anderspink/ap-totara-engage-plugin
    `,
    types: [],
    related: [],
  },
  {
    question: 'What are the names of the Anders Pink plug-ins?',
    answer: `
There are a few different Anders Pink plug-ins. The current plugin names, and a description for each, are:

- **Anders Pink Learn** Manages the Anders Pink block that can be added to Learn pages
- **Anders Pink Engage 1** - Manages Anders Pink resources that can be added to Engage Workspaces
- **Anders Pink Engage 2** - Manages some scheduled tasks for Anders Pink Engage features, and acts as a connector for the 'Anders Pink Engage 1' and 'Container' plugins
- **Container** - Manages the API interface and workspace settings for Anders Pink Engage features
        `,
    types: [],
    related: [],
  },
  {
    question: 'What are the plugin configuration steps?',
    answer: `
    Please see below.
`,
    types: [],
    related: [],
  },
  {
    question: 'What can the different roles do with the plugin?',
    answer: `
- admin - This user can add resources, and add and remove Anders Pink teams from the platform.
- author - This user can add resources, but cannot add and remove Anders Pink teams from the platform.
- learner - This user can do neither of the above, but can view resources added by the other two users.
`,
    types: [],
    related: [],
  },
  {
    question: 'How do I connect an Anders Pink team to Totara?',
    answer: `
Go to Collaborate > Anders Pink Settings > Add New Team

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage1.png)
`,
    types: [],
    related: [],
  },
  {
    question: 'Where do I find my API key in Anders Pink?',
    answer: `
1. Log in to your Anders Pink account > navigate to the desired team using the top left dropdown
2. Go to the “[Export settings](https://anderspink.com/export-settings/)” tab
3. Under the “accessible via API” section tick all briefings/ saved folders you want to add to Workspaces in Engage 
4. Copy the API key and paste into the Anders Pink settings page on Totara

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage2.png)`,
    types: [],
    related: [],
  },
  {
    question: 'How do you add a briefing to an existing Engage workspace?',
    answer: `
Go to Anders Pink Settings > Configure Workspace Briefings > “+ Add New Briefing” 

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage3.png)
`,
    types: [],
    related: [],
  },
  {
    question: 'Can we add more than one briefing to a Workspace?',
    answer: `
Yes you can but we advise adding two or three at most as more content will be posted daily and it may be confusing for Workspace members or they may find it overwhelming. Best practice is to focus on related topics or skills relevant to the workspace to ensure the content is targeted and therefore useful to the members. Get in touch if you like further guidance on this support@anderspink.com 
`,
    types: [],
    related: [],
  },
  {
    question: 'How do I disconnect a briefing from a Workspace?',
    answer: `
Anders Pink Settings > Configure Workspace Briefings > Delete button

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage4.png)
`,
    types: [],
    related: [],
  },
  {
    question:
      'If I delete a briefing from a workspace does it remove the articles previously posted?',
    answer: `
No it does not delete the articles when you delete the briefing connection to the Workspace, it'll just stop posting new articles. You can manually delete articles using the red cross icon in the library or by selecting delete from the .../ellipsis menu in the discussion thread. 

Any articles that have not previously been interacted* with by a user will be deleted from the discussion thread after one week. This does not also delete the article from the library. 

*An interaction on an article by any user is a like, share, comment or it has been added to a playlist.
`,
    types: [],
    related: [],
  },
  {
    question:
      'How many articles/resources are posted to the Workspace Library?',
    answer: `
New resources/articles are added to the Library daily (if new content is available in the Anders Pink briefing) up to a maximum of 5 articles a day. 

We will add up to 50 resources/articles to each Workspace Library. Older resources which have not been interacted with by a user will be deleted as and when the number of resources exceeds 50.
`,
    types: [],
    related: [],
  },
  {
    question:
      'How much content does Anders Pink add to Workspace discussions and libraries?',
    answer: `
**Libraries** 

- Maximum of 5 Anders Pink articles a day will be added. New articles need to be in the Anders Pink briefing for them to appear in your Workspace (see “Why am I not seeing new content daily in my Workspace?”**)**
- Maximum of 50 Anders Pink articles/resources in a Workspace library
- Older articles are deleted when the number of articles exceed 50
- Articles that have been interacted* with by a user will remain in the library indefinitely or until they are manually deleted
- Articles in the library are treated separately to the articles in the discussion thread

**Discussion thread**

- Maximum of 3 new Anders Pink articles a day will be added
- Discussion posts that have not been interacted* with after 1 week will be deleted
- Discussion posts that have been interacted with will remain indefinitely or until they are manually deleted
- Discussion posts are linked to the original article instead of Workspace resources, in case the discussion post is kept, and the Workspace article is deleted

*An interaction on an article by any user is a like, share, comment or it has been added to a playlist.
`,
    types: [],
    related: [],
  },
  {
    question: 'Why am I not seeing new content daily in my Workspace?',
    answer: `
You will only see 5 (or less than 5) articles a day if there are new articles in your briefing. The volume of content in your briefing is dictated by a number of factors:

1. How much content on your topics is available online in the last 3 months - if it's not a hot or trending topic there might not be much available.
2. You've used an allowlist of domains - if you have specified a list of domains the pool of resources will be much smaller therefore you are likely to see less content.
3. If you have been very specific with your briefing context with either topics/keywords and/or training - The narrower the configuration of your briefing/search terms the less content there will be because you have been very targeted with what you want to see therefore there will be less content published that meets your configuration settings and training.
`,
    types: [],
    related: [],
  },
  {
    question: 'How do I add Anders Pink resources/content to a Playlist?  ',
    answer: `
Go to Learn > + playlist 

Add content +

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage5.png)
`,
    types: [],
    related: [],
  },
  {
    question: 'How do I manage topics in Totara?',
    answer: `
You can edit and add topics by going to Site admin > Topic > Manage topics.

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage6.png)
`,
    types: [],
    related: [],
  },
  {
    question:
      'How do I change the #tags on articles/resources from Anders Pink?',
    answer: `
Articles from Anders Pink are automatically tagged with the Anders Pink briefing name when they are imported into Totara. See screenshot #digitaltransformation.

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage7.png)

You can add more tags by clicking “Edit setting” see screenshot above. Click the down arrow under “Assign topics”. Also see “**How do I manage topics in Totara?**” ****for instructions on editing and adding topics within Totara. 

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage8.png)

If you want to change the topic assignment from Anders Pink for future articles simply rename the briefing in Anders Pink by following these steps:

1. Log in to Anders Pink > go to the team and locate the briefing you want to change the name of.
2. Click the Cog icon to see the configuration screen. Scroll down to the bottom and rename the briefing.
3. Click Save. All future articles will use the updated tag.

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage9.png)
`,
    types: [],
    related: [],
  },
  {
    question: 'What reporting is available for Anders Pink resources/articles?',
    answer: `
Please see the Totara help pages 

https://help.totaralearning.com/display/TH15/Engagement+reports
`,
    types: [],
    related: [],
  },
  {
    question: 'Can I change which user the discussions posts come from?',
    answer: `
Yes you can. By default they are posted by the Workspace owner. 

Because accounts require a specific permission to post in any workspace, we have added a role called 'Workspace Discussion Author'. You can assign this role here:

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage10.png)

If 1 user is given this role, automated discussion posts will be made from their account.

If more than 1 user is given this role, the automated discussion post author can be selected here:

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage11.png)

By using this role, you can make automated posts come from a 'generic' account, or any other account you choose. For example:

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage12.png)
`,
    types: [],
    related: [],
  },
  {
    question: 'How to I turn off the discussion notification emails?',
    answer: `
Click the bell icon (top right) > click settings cog > Under the Workspace section select your preferences for email. 

![Totara Engage](${STATIC_DOMAIN}/totara/plugins/engage13.png)
`,
    types: [],
    related: [],
  },
]

const SupportPageEngageLearn = () => {
  const [faqSelected, setFaqSelected] = useState(null)
  const [briefingTypeSelected, setBriefingTypeSelected] = useState('none')
  const [searchTerm, setSearchTerm] = useState('')

  function toggleAccordion(question) {
    if (faqSelected === question) {
      return setFaqSelected(null)
    }
    setFaqSelected(question)
  }

  // const filteredFaqs = faqs.filter((val) => {
  //   if (searchTerm === '' && briefingTypeSelected === 'none') {
  //     return
  //   } else if (searchTerm === '' && val.types.includes(briefingTypeSelected)) {
  //     return val
  //   } else if (
  //     val.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     briefingTypeSelected === 'none'
  //   ) {
  //     return val
  //   } else if (
  //     val.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     val.types.includes(briefingTypeSelected)
  //   ) {
  //     return val
  //   }
  // })
  const filteredFaqs = faqs.filter((faq) => {
    if (searchTerm === '') {
      return faq
    } else if (
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (faq.answer &&
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase()))
    ) {
      return faq
    }
  })

  return (
    <div style={{ textAlign: 'center' }}>
      <div className={styles.greyBg}>
        <h5>How can we help you?</h5>
        <br />
        <div style={{ maxWidth: '700px', margin: '0 auto' }}>
          <input
            className={styles.faqSearch}
            type="text"
            placeholder="Search for a question"
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />
        </div>
        <br />
        {/* <p className={styles.introText}>
        When building a briefing, you can use one of three methods: New, Simple
        or Advanced. To see FAQs related to your briefing type, pick one below
        or simply search for your query.
      </p> */}
        <p className={styles.introText}>
          You can also find further support options at the bottom of the page.
        </p>
      </div>

      <br />

      {/* <h5>Select a briefing type</h5>
      <div className={styles.briefingSelectors}>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('new')}
        >
          New
        </button>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('simple')}
        >
          Simple
        </button>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('advanced')}
        >
          Advanced
        </button>
      </div> */}

      <div className={styles.faqs}>
        {filteredFaqs.map((faq) => (
          <div className={styles.faq} key={faq.question}>
            <div
              className={styles.question}
              onClick={() => toggleAccordion(faq.question)}
            >
              {faq.question}
              <span style={{ color: '#cf0032' }}>
                {faqSelected === faq.question ? '-' : '+'}
              </span>
            </div>
            <div
              className={
                faqSelected === faq.question
                  ? `${styles.answer} ${styles.answerShow}`
                  : styles.answer
              }
            >
              <br />
              <ReactMarkdown source={faq.answer} />
              {faq.videoSrc && (
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0',
                  }}
                >
                  <iframe
                    src={faq.videoSrc}
                    frameBorder="0"
                    allowFullScreen={true}
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe>
                </div>
              )}
              {faq.related && faq.related.length > 0 && (
                <div>
                  <br />
                  You may also find the following useful:
                  <div className={styles.relatedQuestions}>
                    {faq.related.map((question) => (
                      <p
                        key={question}
                        onClick={() => toggleAccordion(question)}
                      >
                        {question}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <br />

      <div className={styles.greyBg}>
        <div className={styles.furtherSupport}>
          <h6 className={styles.furtherSupportHeader}>
            Require further support?
          </h6>
          <div className={styles.supportButtons}>
            <Link to="/api-docs">
              <button className={styles.supportButton}>
                API Documentation
              </button>
            </Link>
            <Link to="/integrations">
              <button className={styles.supportButton}>
                Plugin and Integration Options
              </button>
            </Link>
            <a href="mailto:support@anderspink.com">
              <button className={styles.supportButton}>
                Contact us via support@anderspink.com
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default class SupportPageTotaraEngageWrapper extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          // style={{
          //   backgroundPosition: '50% 68%',
          //   backgroundImage: 'url(/pages/landing/shutterstock_401541778.jpg)',
          // }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Totara Engage Plugin FAQ</div>
          </div>
        </div>

        <SupportPageEngageLearn />
      </div>
    )
  }
}
