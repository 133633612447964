import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import styles from './AppNotifications.css'
import moment from 'moment'

@connect((state) => ({
  notifications: state.app.notifications,
}))
export default class AppNotifications extends React.Component {
  constructor() {
    super()
    this.intervalId = null
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.length > 0 && !this.intervalId) {
      this.intervalId = setInterval(() => {
        const now = moment().unix()
        for (let notification of this.props.notifications) {
          if (notification.expires < now) {
            this.props.dispatch({
              type: 'CLEAR_NOTIFICATION',
              id: notification.id,
            })
          }
        }
      }, 1000)
    } else if (nextProps.notifications.length === 0 && this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.intervalId = null
  }
  render() {
    return (
      <div className={styles.root}>
        {this.props.notifications.map((notification) => (
          <div key={notification.id}>
            <div className={`alert alert-${notification.level}`}>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                onClick={() => {
                  this.props.dispatch({
                    type: 'CLEAR_NOTIFICATION',
                    id: notification.id,
                  })
                }}
              >
                <i className="fa fa-times" />
              </button>
              {notification.content}
            </div>
          </div>
        ))}
      </div>
    )
  }
}
