import React from 'react'

const ContentSelector = ({ value, items, onChangeHandler }) => (
  <select className="form-control" value={value} onChange={onChangeHandler}>
    {items
      .sort((a, b) => (a.isPublic && !b.isPublic ? -1 : 1))
      .map(({ id, name, isPublic }) => (
        <option key={id} value={id} disabled={!isPublic}>
          {name}
          {isPublic ? '' : ' (not public)'}
        </option>
      ))}
  </select>
)

export { ContentSelector }
