import React from 'react'
import moment from 'moment'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { browserHistory, Link } from 'react-router'
import { Form, Container, Row, Col, Table } from 'react-bootstrap'
import flattenStatFields from '../../utils/flattenStatFields'
import TimeRangeFilter from './TimeRangeFilter'
import downloadCSV from '../../utils/downloadCSV'

import { Parser } from 'json2csv'

const selector = formValueSelector('popularBriefingStats')

@compose(
  withProps((props) => ({
    initialValues: {
      timeType: 'last-month',
      timeStart: '',
      timeEnd: '',
    },
  })),
  reduxForm({
    form: 'popularBriefingStats',
  }),
  connect((state, props) => ({
    timeType: selector(state, 'timeType'),
    timeStart: selector(state, 'timeStart'),
    timeEnd: selector(state, 'timeEnd'),
    jwt: state.app.jwt,
  })),
  graphql(
    gql`
      query (
        $id: ID
        $timeType: String
        $timeStart: String
        $timeEnd: String
        $source: String
        $digest: String
        $send: String
      ) {
        team(id: $id) {
          id
          name
          teamStats(
            query: "popular-briefings"
            timeType: $timeType
            timeStart: $timeStart
            timeEnd: $timeEnd
            source: $source
            digest: $digest
            send: $send
          ) {
            fields {
              name
              value
            }
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.id,
            timeType: props.timeType,
            timeStart: props.timeStart,
            timeEnd: props.timeEnd,
            source: props.source,
            digest: props.digest,
            send: props.send,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
export default class PopularBriefings extends React.Component {
  constructor() {
    super()
    this.state = {
      time: null,
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.team) {
      return 'Loading...'
    }
    if (!this.props.data.team) {
      return 'Sorry, something went wrong'
    }

    return (
      <div>
        <div>
          <Row>
            <Col>
              <h5 style={{}}>Popular briefings</h5>
            </Col>
            <Col className="d-flex justify-content-end">
              <a
                href={`/usage-analytics-export/${this.props.id}/${this.props.jwt}?type=popular-briefings&timeType=${this.props.timeType}&timeStart=${this.props.timeStart}&timeEnd=${this.props.timeEnd}`}
                target="_blank"
                style={{ color: 'inherit' }}
              >
                <i className="fa fa-download" style={{ cursor: 'pointer' }} />
              </a>
            </Col>
          </Row>

          <TimeRangeFilter
            timeType={this.props.timeType}
            timeStart={this.props.timeStart}
            timeEnd={this.props.timeEnd}
            onChange={(fields) => {
              for (let [name, value] of Object.entries(fields)) {
                this.props.change(name, value)
              }
            }}
            loading={this.props.data.loading && this.props.data.team}
          />

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Briefing</th>
                <th>Total views</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.team.teamStats.length === 0 && (
                <tr>
                  <td colSpan="2">No data</td>
                </tr>
              )}
              {this.props.data.team.teamStats.length > 0 &&
                this.props.data.team.teamStats
                  .map((stat) => flattenStatFields(stat))
                  .map((stat) => (
                    <tr key={stat.name}>
                      <td>{stat.name}</td>
                      <td>
                        <Link
                          to={`/team-api-stats/${this.props.id}/briefing/${
                            stat.entityId
                          }?timeType=${this.props.timeType}&timeStart=${
                            this.props.timeStart || ''
                          }&timeEnd=${this.props.timeEnd || ''}`}
                        >
                          {stat.value}
                        </Link>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
}
