import React from 'react'
import styles from '../Home.module.css'
import componentsStyles from '../Components.module.css'
import Carousel from 'react-elastic-carousel'
import Testimonial from '.'
import { Link } from 'react-router'
import Button from '../Button'

const TESTIMONIALS = [
  {
    logo: `${STATIC_DOMAIN}/pages/new-landingpage/5app.png`,
    company: '5app',
    content:
      'Adding Anders Pink’s Complete Curation to our solution enables our customers to get true value from all the content they own by automating aggregation, making searching more intelligent and simplifying curation.',
  },
  {
    logo: `${STATIC_DOMAIN}/pages/new-landingpage/videoarts.png`,
    company: 'Video Arts',
    content:
      'The Anders Pink Complete Curation tool is a great way to find, search and tag our library all in one place. We love how the search results can be curated into Learning Pathways and automatically added to our new LXP too – we thoroughly recommend it!',
  },
  {
    logo: `${STATIC_DOMAIN}/pages/new-landingpage/scania.png`,
    company: 'Scania',
    content:
      'Using Anders Pink Web Curation is a key component of our curation strategy at Scania. It’s creating a seamless experience for visitors to the portal together with our own legacy content. Anders Pink is crucial for us to offer the latest and greatest content on key topics for our business.',
  },
  {
    logo: `${STATIC_DOMAIN}/pages/new-landingpage/enterpriseireland.png`,
    company: 'Enterprise Ireland',
    content:
      'With Web Curation, we have added content quickly based on the needs of the relevant programme or trends within a subject area. This has saved time and resources in sourcing content for subjects.',
  },
  {
    logo: `${STATIC_DOMAIN}/pages/new-landingpage/futureworkplace.png`,
    company: 'Future Workplace',
    content:
      'Formal courses are no longer enough to keep employees current on fast changing future of work trends, we need other ways. Anders Pink Web Curation is just such a tool, powered by machine learning to provide trending content on current topics daily.',
  },
]

const Testimonials = ({ withOldHeader }) => {
  let paginationAndArrows = false

  if (TESTIMONIALS.length > 2) {
    paginationAndArrows = true
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 1 },
    { width: 650, itemsToShow: 1 },
    { width: 800, itemsToShow: 2 },
    {
      width: 1000,
      itemsToShow: 2,
      pagination: paginationAndArrows,
      showArrows: paginationAndArrows,
    },
  ]

  return (
    <div className={`${styles.section}`} style={{ paddingBottom: 90 }}>
      <div
        className={`${styles.content}`}
        style={{ textAlign: withOldHeader ? 'center' : 'flex-start' }}
      >
        {withOldHeader ? (
          <header className={styles.oldTitle}>
            {' '}
            What our partners and clients are saying about the Anders Pink
            Product Suite?
          </header>
        ) : (
          <p style={{ display: 'flex' }}>
            <span className={`${styles.line} ${styles.backgroundGreen}`}>
              &nbsp;
            </span>
            <span className={`${styles.title}`}>
              What our partners and clients are saying about the Anders Pink
              <br />
              Product Suite?
            </span>
          </p>
        )}
      </div>
      <Carousel
        className={componentsStyles.carousel}
        breakPoints={breakPoints}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
      >
        {TESTIMONIALS.map((testimonial, index) => (
          <Testimonial key={index} data={testimonial} />
        ))}
      </Carousel>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link to="/contact">
          <Button title="Book a consultation" backgroundColor="#CF0032" />
        </Link>
      </div>
    </div>
  )
}

export default Testimonials
