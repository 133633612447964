import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import articleInfoFragment from 'articles/fragments/articleInfo'

import { renderArticleList } from './renderArticleList'

@graphql(
  gql`
    query briefingQuery(
      $id: ID!
      $time: String!
      $limit: Int
      $offset: Int
      $tag: String
    ) {
      briefing(id: $id) {
        id
        articles(time: $time, limit: $limit, offset: $offset, tag: $tag) {
          ...articleInfo
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.id,
          time: '3-months',
          limit: 500,
          offset: 0,
          tag: null,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
export default class BriefingArticleSelection extends React.Component {
  constructor() {
    super()
    this.state = {
      id: '',
    }
  }

  render() {
    const {
      data: { briefing: { articles = [] } = {} },
      selectedArticles,
      onArticleClick,
    } = this.props

    if (this.props.data.loading) {
      return 'Loading articles..'
    } else {
      return renderArticleList({ articles, selectedArticles, onArticleClick })
    }
  }
}
