import React from 'react'
import { Link } from 'react-router'

import styles from './CTA.module.css'

const CTA = (props) => {
  return (
    <section className={styles.cta}>
      <header className={styles.ctaHeader}>{props.headerText}</header>
      <div className={styles.ctaBody}>
        <div>
          <img className={styles.ctaPic} src={props.img} />
        </div>
        <div className={styles.ctaText}>
          {props.bullets.map((bullet) => (
            <p className={styles.ctaP1} key={bullet.key}>
              <span className={styles.ctaBullet}>&gt; </span>
              {bullet.text}
            </p>
          ))}
          <p className={styles.ctaP2}>{props.p2Text}</p>
          <Link to="/contact?reason=Book%20a%20demo">
            <button className={styles.ctaBtn}>Book a consultation</button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CTA
