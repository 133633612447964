import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form'
import AdminTable from 'admin/components/AdminTable'

const selector = formValueSelector('createPlan')

@graphql(
  gql`
    mutation($plan: PlanInput!) {
      createOrganisationPlan(plan: $plan)
    }
  `,
  {
    name: 'createOrganisationPlan',
    options: {
      refetchQueries: ['appQuery'],
    },
  },
)
@reduxForm({
  form: 'createPlan',
  initialValues: {
    currency: 'usd',
  },
})
@connect((state) => ({
  type: selector(state, 'type'),
  currency: selector(state, 'currency'),
}))
export default class AdminCreatePlanPage extends React.Component {
  onSubmit = (values) => {
    return this.props
      .createOrganisationPlan({
        variables: {
          plan: {
            type: values.type,
            name: values.name,
            description: values.description,
            currency: values.currency,
            priceMonthly: values.priceMonthly,
            priceYearly: values.priceYearly,
            totalNumUsers: parseInt(values.totalNumUsers),
            totalNumBriefings: parseInt(values.totalNumBriefings),
            totalNumBoards: parseInt(values.totalNumBoards),
            personalBriefingAllowance: parseInt(
              values.personalBriefingAllowance,
            ),
            personalBoardAllowance: parseInt(values.personalBoardAllowance),
            apiType: values.apiType,
            totalNumApiBriefings: parseInt(values.totalNumApiBriefings),
            totalNumApiBoards: parseInt(values.totalNumApiBoards),
            canCreateApiKeys: values.canCreateApiKeys,
            totalNumPublicBriefings: parseInt(values.totalNumPublicBriefings),
            totalNumPublicBoards: parseInt(values.totalNumPublicBoards),
            canPubliclyExport: values.canPubliclyExport,
            createOnStripe: values.createOnStripe,
          },
        },
      })
      .then((response) => {
        this.props.reset()
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `New plan succesfully added`,
        })
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    const typeLabels = { app: 'App', api: 'API addon' }

    let typeLabel = typeLabels[this.props.type]
      ? typeLabels[this.props.type]
      : ''

    return (
      <div>
        <h5>Create a plan</h5>
        <p>
          Plans can be for the core app, api, or for extra users. A users
          subscription can made be up of a combination of plans.
        </p>

        <form
          onSubmit={handleSubmit(this.onSubmit)}
          style={{ maxWidth: '600px' }}
        >
          <div className="form-group">
            <label>Type</label>
            <Field name="type" component="select" className="form-control">
              <option>Choose...</option>
              <option value="app">{typeLabels['app']}</option>
              <option value="api">{typeLabels['api']}</option>
            </Field>
          </div>

          {(this.props.type ? true : false) && (
            <div>
              <div className="form-group">
                <label>
                  Name (publicly viewable on billing section and invoices)
                </label>
                <Field
                  name="name"
                  placeholder={`e.g. ${typeLabel} enterprise plan for xxxxxx`}
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Description (internal use, optional)</label>
                <Field
                  name="description"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      name="createOnStripe"
                      component="input"
                      type="checkbox"
                      normalize={(v) => {
                        return !!v ? true : false
                      }}
                      className="form-check-input"
                    />{' '}
                    Can be purchased via Stripe? For most plans with manual
                    invoicing, leave this unchecked.
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>Currency</label>
                <Field
                  name="currency"
                  component="select"
                  className="form-control"
                >
                  <option value="usd">USD (preferred)</option>
                  <option value="gbp">GBP</option>
                  <option value="eur">EUR</option>
                </Field>
              </div>

              <div className="form-group">
                <label>
                  Price monthly ({this.props.currency.toUpperCase()})
                </label>
                <Field
                  name="priceMonthly"
                  placeholder={`e.g. 9.99`}
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  Price yearly ({this.props.currency.toUpperCase()})
                </label>
                <Field
                  name="priceYearly"
                  placeholder={`e.g. 99.99`}
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          )}

          {this.props.type === 'app' && (
            <div>
              <div className="form-group">
                <label>Number of users</label>
                <Field
                  name="totalNumUsers"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Number of briefings</label>
                <Field
                  name="totalNumBriefings"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Number of saved folders</label>
                <Field
                  name="totalNumBoards"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Number of personal briefings each user is allowed</label>
                <Field
                  name="personalBriefingAllowance"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>
                  Number of personal saved folders each user is allowed
                </label>
                <Field
                  name="personalBoardAllowance"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          )}

          {(this.props.type === 'api' || this.props.type === 'app') && (
            <div>
              <div className="form-group">
                <label>API access level</label>
                <Field
                  name="apiType"
                  component="select"
                  className="form-control"
                >
                  <option value="">
                    {this.props.type === 'app' ? 'None' : 'Choose...'}
                  </option>
                  <option value="read-only">Read-only</option>
                  <option value="full">Full (create briefings/boards)</option>
                </Field>
              </div>

              <div className="form-group">
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      name="canCreateApiKeys"
                      component="input"
                      type="checkbox"
                      normalize={(v) => {
                        return !!v ? true : false
                      }}
                      className="form-check-input"
                    />{' '}
                    Allow the account to create their own API keys for teams
                    (not recommmended)
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>Number of briefings exportable via the API</label>
                <Field
                  name="totalNumApiBriefings"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Number of saved folders exportable via the API</label>
                <Field
                  name="totalNumApiBoards"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      name="canPubliclyExport"
                      component="input"
                      type="checkbox"
                      normalize={(v) => {
                        return !!v ? true : false
                      }}
                      className="form-check-input"
                    />{' '}
                    Allow the account to publicly export their
                    briefings/folders?
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>
                  Number of briefings publicly exportable (JS, RSS, iFrame etc)
                </label>
                <Field
                  name="totalNumPublicBriefings"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>
                  Number of saved folders publicly exportable (JS, RSS, iFrame
                  etc)
                </label>
                <Field
                  name="totalNumPublicBoards"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          )}

          {(error ? true : false) && (
            <div className="alert alert-danger">{error}</div>
          )}

          <button
            type="submit"
            className={`btn btn-primary`}
            disabled={submitting}
          >
            Create plan
          </button>
        </form>
      </div>
    )
  }
}
