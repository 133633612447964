export function postRequest(url, data = {}) {
  return {
    type: 'LOAD',
    payload: {
      request: {
        method: 'post',
        url: url,
        data: data,
      },
    },
  }
}
