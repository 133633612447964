import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'

import AdminTable from 'admin/components/AdminTable'

const ITEMS_PER_PAGE = 100

@graphql(
  gql`
    query (
      $query: String
      $sort: String
      $sortOrder: String
      $limit: Int!
      $offset: Int!
    ) {
      users(
        query: $query
        sort: $sort
        sortOrder: $sortOrder
        limit: $limit
        offset: $offset
      ) {
        id
        firstName
        lastName
        email
        teams {
          id
          name
        }
        accounts {
          id
          name
          type
          activeSubscription {
            id
            plans {
              id
              planSet {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          query: props.location.query.query || '',
          sort: props.location.query.sort || 'id',
          sortOrder: props.location.query.sortOrder || 'desc',
          limit: props.location.query.limit || ITEMS_PER_PAGE,
          offset: props.location.query.offset || 0,
        },
      }
    },
    props({ data: { users, fetchMore, ...rest } }) {
      return {
        data: {
          users,
          ...rest,
          loadMore() {
            return fetchMore({
              variables: {
                offset: users.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return Object.assign({}, previousResult, {
                  // Append the new feed results to the old one
                  users: [...previousResult.users, ...fetchMoreResult.users],
                })
              },
            })
          },
        },
      }
    },
  },
)
export default class AdminUsersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reloaded: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.state &&
      nextProps.location.state.refetch &&
      !this.state.reloaded
    ) {
      nextProps.data.refetch()
      this.setState({ reloaded: true })
    }
  }

  render() {
    return (
      <div>
        <AdminTable
          fields={{
            id: {
              label: 'ID',
              render: (id) => (
                <Link to={`/admin/users/${id}`} className="link">
                  {id}
                </Link>
              ),
            },
            firstName: {
              label: 'First Name',
            },
            lastName: {
              label: 'Last Name',
            },
            email: {
              label: 'Email',
            },
            teams: {
              label: 'Teams',
              render: (teams) => {
                return teams.map((team, index) => (
                  <Link
                    key={index}
                    to={`/admin/teams/${team.id}`}
                    className="link link-list"
                  >
                    {team.name}
                  </Link>
                ))
              },
            },
            accounts: {
              label: 'Billing Accounts',
              render: (accounts) => {
                return accounts.map((account, index) => (
                  <div style={{ minWidth: '300px' }}>
                    <Link
                      key={index}
                      to={`/admin/accounts/${account.id}`}
                      className="link"
                    >
                      {account.name} ({account.type})
                    </Link>
                    <div>
                      {account.activeSubscription !== null && (
                        <div>
                          Has{' '}
                          {account.activeSubscription.isTrial ? 'trial' : ''}{' '}
                          subscription to:
                          <ul>
                            {account.activeSubscription.plans
                              .filter((plan) => (plan.planSet ? true : false))
                              .map((plan) => (
                                <li>
                                  <Link
                                    key={plan.id}
                                    to={`/admin/plans/${plan.planSet.id}`}
                                    className="link"
                                  >
                                    {plan.planSet.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              },
            },
          }}
          data={this.props.data.users || []}
          loading={this.props.data.loading}
          onSubmit={(values) => {
            browserHistory.push({
              pathname: '/admin/users',
              query: {
                ...values,
              },
            })
          }}
          loadMore={() => {
            this.props.data.loadMore()
          }}
          initialValues={{
            ...this.props.location.query,
          }}
        />
      </div>
    )
  }
}
