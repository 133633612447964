exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".BrowseCategories__options___3u-0Y {\n  margin: 20px 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n.BrowseCategories__label___4deh0 {\n  padding: 4px 10px 4px 0px;\n  margin: 0 5px 5px 0;\n}\n.BrowseCategories__option___3_kZC {\n  padding: 4px 10px;\n  margin: 0 5px 5px 0;\n  cursor: pointer;\n  border: 1px solid #eee;\n  border-radius: 4px;\n  -webkit-transition: background-color 0.2s linear;\n  transition: background-color 0.2s linear;\n}\n.BrowseCategories__activeOption___3uNov {\n  border: 1px solid #dddddd;\n  background-color: #ececec;\n}\n.BrowseCategories__option___3_kZC:hover {\n  background-color: #f2f2f2;\n}\n.BrowseCategories__activeOption___3uNov:hover {\n  background-color: #ececec;\n}\n.BrowseCategories__briefings___38c6R {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: -10px;\n}\n", ""]);
// Exports
exports.locals = {
	"options": "BrowseCategories__options___3u-0Y",
	"label": "BrowseCategories__label___4deh0",
	"option": "BrowseCategories__option___3_kZC",
	"activeOption": "BrowseCategories__activeOption___3uNov BrowseCategories__option___3_kZC",
	"briefings": "BrowseCategories__briefings___38c6R"
};