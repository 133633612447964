import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { SubmissionError } from 'redux-form'

const DEFAULT_DEGREED_URL = 'eu.betatest.degreed.com'

@graphql(
  gql`
    query accountDegreedQuery($id: ID!) {
      account(id: $id) {
        id
        isDegreedConfigured
        degreedIntegration {
          url
          scope
          clientId
          secret
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.accountId,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
@graphql(
  gql`
    mutation($accountId: ID!, $degreedIntegration: DegreedIntegrationInput!) {
      updateDegreedIntegration(
        accountId: $accountId
        degreedIntegration: $degreedIntegration
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      updateDegreedIntegration: (accountId, degreedIntegration) =>
        mutate({
          variables: { accountId, degreedIntegration },
          refetchQueries: ['accountDegreedQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($accountId: ID!) {
      removeDegreedIntegration(accountId: $accountId)
    }
  `,
  {
    props: ({ mutate }) => ({
      removeDegreedIntegration: (accountId) =>
        mutate({
          variables: { accountId },
          refetchQueries: ['accountDegreedQuery'],
        }),
    }),
  },
)
@connect()
export default class DegreedIntegration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamSettingsSaved: false,
      isModalOpen: false,
      modalType: null,
      modalData: null,
      editing: false,
    }
  }
  render() {
    const {
      accountId,
      data: { loading, account },
    } = this.props
    const { editing } = this.state
    if (loading) {
      return <p>Loading, please wait..</p>
    }

    const integration = account ? account.degreedIntegration : null

    return (
      <div>
        {!account.isDegreedConfigured && !editing && (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => this.setState({ editing: true })}
            >
              Setup integration
            </button>
          </div>
        )}
        {account.isDegreedConfigured && !editing && (
          <div>
            <div className="mb-2">
              <strong>API Url</strong>: {account.degreedIntegration.url}
            </div>
            <div className="mb-2">
              <strong>Client ID</strong>: {account.degreedIntegration.clientId}
            </div>
            <div className="mb-2">
              <strong>Client Secret</strong>:{' '}
              {account.degreedIntegration.secret.replace(/./g, '*')}
            </div>
            <div className="mt-2">
              <button
                className="btn btn-secondary"
                onClick={() => this.setState({ editing: true })}
              >
                Edit
              </button>
            </div>
          </div>
        )}
        {editing && (
          <DegreedIntegrationForm
            initialValues={{
              url:
                integration && integration.url
                  ? integration.url
                  : DEFAULT_DEGREED_URL,
              clientId:
                integration && integration.clientId ? integration.clientId : '',
              secret:
                integration && integration.secret ? integration.secret : '',
            }}
            removable={account.isDegreedConfigured}
            onCancel={() => this.setState({ editing: false })}
            onSubmit={(values) => {
              return this.props
                .updateDegreedIntegration(accountId, {
                  ...values,
                })
                .then(() => {
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Degreed integration succesfully updated`,
                  })
                  this.setState({
                    editing: false,
                  })
                })
                .catch((err) => {
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    level: 'danger',
                    content: `There was an error when updating your Degreed integration`,
                  })
                  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                    throw new SubmissionError({
                      _error: err.graphQLErrors[0].message,
                    })
                  } else if (err.networkError) {
                    throw new SubmissionError({
                      _error: err.networkError.message,
                    })
                  } else {
                    throw new SubmissionError({
                      _error: err.message,
                    })
                  }
                })
            }}
            onRemove={() => {
              return this.props
                .removeDegreedIntegration(accountId)
                .then(() => {
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Degreed integration succesfully removed`,
                  })
                  this.setState({
                    editing: false,
                  })
                })
                .catch((err) => {
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    level: 'danger',
                    content: `There was an error when removing your Degreed integration`,
                  })
                  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                    throw new SubmissionError({
                      _error: err.graphQLErrors[0].message,
                    })
                  } else if (err.networkError) {
                    throw new SubmissionError({
                      _error: err.networkError.message,
                    })
                  } else {
                    throw new SubmissionError({
                      _error: err.message,
                    })
                  }
                })
            }}
          />
        )}
      </div>
    )
  }
}

@reduxForm({
  form: 'degreedIntegration',
})
class DegreedIntegrationForm extends React.Component {
  constructor() {
    super()
  }
  static defaultProps = {
    onRemove: () => {},
    onCancel: () => {},
    onSubmit: () => {},
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting, removable } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>API URL</label>
          <Field
            name="url"
            component="input"
            type="text"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Client ID</label>
          <Field
            name="clientId"
            component="input"
            type="text"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Client Secret</label>
          <Field
            name="secret"
            component="input"
            type="text"
            className="form-control"
            required
          />
        </div>

        {!!error && <div className="alert alert-danger">{error}</div>}

        <button
          className="btn btn-secondary mr-2"
          onClick={() => this.props.onCancel()}
          type="button"
        >
          Cancel
        </button>
        <button className="btn btn-primary" type="submit">
          Save changes
        </button>
        {removable && (
          <button
            className="btn btn-link"
            onClick={() => this.props.onRemove()}
            type="button"
          >
            Remove integration
          </button>
        )}
      </form>
    )
  }
}
