exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UpdateTeamPage__section____wrdN {\n  margin: 20px 0px 40px 0px;\n}\n.UpdateTeamPage__sectionTitle___1QnzG {\n  font-size: 1.2em;\n  margin-bottom: 10px;\n}\n.UpdateTeamPage__rows___1AVrL {\n}\n.UpdateTeamPage__row___285kB:last-of-type {\n  border-bottom: 0 none;\n}\n.UpdateTeamPage__pending___1GJWT {\n  color: #aaa;\n  font-style: italic;\n}\n.UpdateTeamPage__signupLink___2S-Jj {\n  padding: 5px;\n  background-color: #f2f2f2;\n  margin-bottom: 10px;\n}\n.UpdateTeamPage__permissions___MTnDC {\n  padding: 10px;\n  border: 1px solid #eeeeee;\n}\n", ""]);
// Exports
exports.locals = {
	"section": "UpdateTeamPage__section____wrdN",
	"sectionTitle": "UpdateTeamPage__sectionTitle___1QnzG",
	"rows": "UpdateTeamPage__rows___1AVrL",
	"row": "UpdateTeamPage__row___285kB",
	"pending": "UpdateTeamPage__pending___1GJWT",
	"signupLink": "UpdateTeamPage__signupLink___2S-Jj",
	"permissions": "UpdateTeamPage__permissions___MTnDC"
};