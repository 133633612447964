import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

@reduxForm({
  form: 'teamPermissions',
})
export default class TeamPermissionsForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
  }
  render() {
    const { saved, onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Team name</label>
            <Field
              name="name"
              component="input"
              type="text"
              className="form-control"
              style={{ maxWidth: '200px' }}
            />
          </div>

          <p>
            Admins and owners can do anything in the team. Using the below
            settings you can control what normal users (non-admins) can do.
          </p>

          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="areBriefingsLocked"
                component="input"
                type="checkbox"
                className="form-check-input mr-1"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />
              Allow users to create/edit/delete team briefings
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="areBoardsLocked"
                component="input"
                type="checkbox"
                className="form-check-input mr-1"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />
              Allow users to create/delete team saved article folders
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="isSavingLocked"
                component="input"
                type="checkbox"
                className="form-check-input mr-1"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />
              Allow users to save and unsave articles from the folders
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="isBlockingLocked"
                component="input"
                type="checkbox"
                className="form-check-input mr-1"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />
              Allow users to delete individual articles from a briefing (by
              clicking the X in the top right of an article)
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label">
              <Field
                name="canUsersEditDomainLists"
                component="input"
                type="checkbox"
                className="form-check-input mr-1"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />
              Allow users to create/edit/delete Allow/Blocklists for this Team
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={submitting}
          >
            Save permissions
          </button>
        </form>
      </div>
    )
  }
}
