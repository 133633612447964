import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'
import Loader from 'app/components/Loader'
import ReactFileReader from 'briefings/components/ReactFileReader'

@graphql(
  gql`
    query briefingArticleImageQuery($id: ID!) {
      briefing(id: $id) {
        id
        name
        uploadedImages {
          id
          url
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation ($briefingId: ID!, $images: [String!], $types: [String!]) {
      createBriefingArticleImages(
        briefingId: $briefingId
        images: $images
        types: $types
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      createBriefingArticleImages: (briefingId, images, types) =>
        mutate({
          variables: { briefingId, images, types },
        }),
    }),
    options: {
      refetchQueries: [],
    },
  },
)
@graphql(
  gql`
    mutation ($imageId: ID!) {
      deleteBriefingArticleImage(imageId: $imageId)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteBriefingArticleImage: (imageId) =>
        mutate({
          variables: { imageId },
        }),
    }),
    options: {
      refetchQueries: [''],
    },
  },
)
export default class BriefingArticleImagesPage extends React.Component {
  constructor() {
    super()
    this.state = {
      uploading: false,
    }
  }
  render() {
    const briefing = this.props.data.briefing || null

    return (
      <div>
        <h5>Set placeholder images for the articles in your briefing</h5>

        <p>
          These images will be used when an article doesn't have an image. We
          will randomly pick an image from your selection each time the briefing
          gets rendered or returned from the API.
        </p>

        <p>
          Please select PNGs/JPEGs, with a maximum combined size under 2mb per
          upload. You can upload multiple times and there is no upper limit on
          then number of images.
        </p>

        <ReactFileReader
          elementId="upload"
          multipleFiles={true}
          disabled={this.state.uploading}
          base64={true}
          fileTypes={['.png', '.jpg', '.jpeg']}
          handleFiles={(file) => {
            this.setState({
              uploading: true,
            })
            this.props
              .createBriefingArticleImages(
                this.props.data.briefing.id,
                file.base64,
                Array.from(file.fileList).map((file) => file.type),
              )
              .then(() => {
                this.setState({
                  uploading: false,
                })
                this.props.dispatch({
                  type: 'ADD_NOTIFICATION',
                  content: `Image(s) successfully uploaded`,
                })
                this.props.data.refetch()
              })
              .catch((err) => {
                this.setState({
                  uploading: false,
                })
                this.props.dispatch({
                  type: 'ADD_NOTIFICATION',
                  content: `Sorry there was an error: ${err.message}`,
                  level: 'danger',
                })
              })
          }}
        >
          <button className="btn btn-primary">Choose image and upload</button>
        </ReactFileReader>

        <div>{this.state.uploading && <Loader />}</div>

        {briefing &&
          briefing.uploadedImages &&
          briefing.uploadedImages.length > 0 && (
            <div>
              <br />
              <br />
              <h5>Existing images</h5>
              {briefing.uploadedImages.map((image) => (
                <div
                  key={image.id}
                  style={{
                    marginBottom: '15px',
                    marginRight: '15px',
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  <img src={image.url} />
                  <span
                    style={{
                      position: 'absolute',
                      top: '2px',
                      right: '2px',
                      backgroundColor: 'white',
                      padding: '1px 6px',
                      borderRadius: '8px',
                      border: '1px solid #ddd',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.props
                        .deleteBriefingArticleImage(image.id)
                        .then(() => {
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `Image deleted`,
                          })
                          this.props.data.refetch()
                        })
                        .catch((err) => {
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `Sorry there was an error: ${err.message}`,
                            level: 'danger',
                          })
                        })
                    }}
                  >
                    <i className="fa fa-times" />
                  </span>
                </div>
              ))}
            </div>
          )}
      </div>
    )
  }
}
