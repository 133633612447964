const partners = [
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/degreed.svg`,
    name: 'Degreed',
    description:
      'Degreed connects learning and career growth to business opportunities through one single, fluid skill-development experience, so your organisation and your people are always ready to clear the next big obstacle.',
    integration:
      'Anders Pink integrates with Degreed, and can be customised and supported by the Anders Pink team.',
    url: 'https://www.degreed.com',
    moreInfoId: 'degreed',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/d2l-brightspace.png?1`,
    name: 'D2L Brightspace',
    description:
      'D2L Brightspace is a powerful Learning Platform which helps education and skill development whether it be in the classroom or at work. Anders Pink seamlessly integrates with Brightspace, bringing recent and relevant content, on any topic you want, right into Brightspace. Save time and cost, drive engagement and keep your learners up to date',
    integration: null,
    url: 'https://www.d2l.com/en-eu/',
    moreInfoId: 'd2l',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/totara-logo.jpg`,
    name: 'Totara',
    description: `Totara, or otherwise known as Totara Learn, is an open source
      talent and learning management system (LMS) that extends upon
      the core structure of Moodle for corporate, healthcare and
      government environments.`,
    integration: null,
    url: 'https://www.totaralearning.com/products/totara-learn',
    moreInfoUrl: '/partners/totara',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/kineo.png`,
    name: 'Kineo',
    description:
      'Kineo is a global workplace learning company that helps your business improve performance through learning and technology. Kineo has a global network of offices with extensive experience in working with clients to design, develop, host and support learning solutions.',
    integration:
      'Anders Pink integrates with Totara LMS, and can be customised and supported by Kineo.',
    url: 'https://www.kineo.com',
    moreInfoId: 'kineo',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/fuse.png`,
    name: 'Fuse',
    description:
      'Fuse are on a mission to change the way the world learns, within their branded environment. From your content strategy to your delivery, we ensure your team develop their skills. Advanced Technology. Great Practice',
    integration:
      'Anders Pink integrates with Fuse and can be customised and supported by Fuse.',
    url: 'https://fuse.fuseuniversal.com',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/learnamp.png`,
    name: 'Learn Amp',
    description:
      'Learn Amp is the world’s no.1 People Development Platform. We make it easy to develop happy and performing people. Learn Amp combines learning, engagement and performance management tools in one seamless platform driving better business results and higher employee retention. Unlike other learning platforms, Learn Amp is a combined Learning Management System (LMS) and Learning Experience Platform (LXP). In short, we give you the best of both. Learn Amp balances the need for control over compliance with autonomy for employee-led learning. Better yet, our performance tools help you prove the ROI and measure impact.',
    integration:
      'Anders Pink integrates with the Learn Amp Learning Engagement Platform, and can be customised and supported by Learn Amp',
    url: 'https://learnamp.com',
    moreInfoId: 'learn-amp',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/talenteam.png`,
    name: 'TalenTeam',
    description: `As one of the most experienced and qualified Partners of SAP SuccessFactors, TalenTeam helps you unlock the power of this world-class technology, letting you supercharge your Human Capital Management (HCM) potential and profit from a range of valuable business benefits.
      <br /><br />
      Anders Pink powers curation and content recommendation in SAP products via TalenTeam including SAP JAM and Blend LXP.
      `,
    url: 'https://talenteam.com/',
    moreInfoId: 'talenteam',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/gp.jpg`,
    name: 'GP Strategies',
    description:
      'GP Strategies is an award-winning global provider of customised performance improvement programmes, that strategically align with your unique business objectives.',
    integration:
      'GP Strategies can offer Anders Pink as part of a range of its blended solutions and learning programmes and platforms.',
    url: 'https://www.gpstrategies.com',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/valamis-logo-black-rgb.svg`,
    name: 'Valamis',
    description:
      "Valamis is an internationally growing IT company specialized in the digitalization of learning. The company was recently listed in Gartner's 2018 <a href='https://www.valamis.com/company/news/valamis-listed-as-a-representative-vendor-in-gartner-s-2018-market-guide' target='_blank'>Market Guide for Corporate Learning Suites</a> as a Representative Vendor of the product Valamis. <a href='https://www.valamis.com/solutions' target='_blank'>Valamis - Learning Experience Platform</a> helps customers convert the benefits of the digital transformation into a competitive advantage. Valamis' customers are large organizations in both private and public sectors. With its headquarters in Joensuu, Finland, Valamis has a total of ten offices in Finland, the US, Germany, UK, the Netherlands, India and Russia.",
    url: 'http://www.valamis.com',
    // moreInfoId: 'valamis',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/jampan-cornerstone.png`,
    name: 'Jam Pan + Cornerstone',
    description:
      'Jam Pan provides global digital learning talent and solutions on demand, from across the learning marketplace. Jam Pan is a Cornerstone partner. They can bring Anders Pink into your Cornerstone  Learning Platform to provide curated content on any topic. Just chat to David and his team to see it in action.',
    url: 'https://www.jam-pan.com/',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/huler-logo.png`,
    name: 'Huler',
    description:
      'Huler is a connected ecosystem of collaborative Learning, Communication, HR and Talent Spaces delivered in an awesome consumer grade package. Anders Pink integrates seamlessly with the Huler hub to bring curated content from the web mapped to skills directly into Huler Collections, ensuring there is fresh relevant content at the point of need, every day.',
    integration: null,
    url: 'https://huler.io/',
    moreInfoId: 'huler',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/learning-pool.png`,
    name: 'Learning Pool',
    description:
      'Proven e-learning solutions for businesses. Flexible, robust & reliable learning platforms. 98% Customer Satisfaction.',
    integration:
      'Anders Pink integrates with Stream LXP, and can be customised and supported by Learning Pool.',
    url: 'https://www.learningpool.com',
    moreInfoId: 'learning-pool',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/netex.png`,
    name: 'Netex',
    description:
      'Netex develop apps and service-based-solutions to deliver digital learning in companies and academic institutions.',
    integration:
      'Anders Pink is available as an app as part of the Netex Learning Cloud, a modern cloud platform for workplace learning',
    url: 'https://www.netexlearning.com',
  },
  // {
  //     image: `${STATIC_DOMAIN}/pages/landing/partners/lumesse.png`,
  //     name: 'Lumesse',
  //     description: 'Lumesse offers a range of Learning Spaces capable of serving mandatory or elective learning. They also build custom portals and websites to order, for particular audiences or programmes.',
  //     integration: 'Anders Pink integrates with Learning Gateway, and can be customised and supported by Lumesse.',
  //     url: 'https://lumesse.com',
  // },

  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/go1.png`,
    name: 'GO1',
    description:
      "With GO1 you get the learning content that you need, combined with the platform to manage your learners and track their progress. Whether it's personal development, compliance, onboarding or selling courses – GO1 has got you covered!",
    integration:
      'Anders Pink integrates with GO1 Platform and can be customised and supported by GO1.',
    url: 'https://www.go1.com',
  },
  // {
  //   image: `${STATIC_DOMAIN}/pages/landing/partners/raytheon.png`,
  //   name: 'Raytheon',
  //   description: 'Raytheon is a technology and innovation leader specialising in defence, national security, and other government and commercial markets around the world.',
  //   integration: 'Raytheon integrate Anders Pink into their Totara and Drupal solutions.',
  //   url: 'https://www.raytheon.com/uk',
  // },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/clc.png`,
    name: 'Charity Learning Commission',
    description:
      'The Charity Learning Consortium provides affordable eLearning solutions to charities, not for profits and the third sector.',
    integration:
      'Moodle solutions and support from Charity Learning Consortium are powered by Anders Pink',
    url: 'https://charitylearning.org',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/think.svg`,
    name: 'Think',
    description:
      'Think Learning empowers organisations to create safer and better places to work through the implementation of Totara Learn, a fully featured Learning Management System.',
    integration:
      'Anders Pink integrates with Totara LMS, and can be customised and supported by Think Learning.',
    url: 'https://think-learning.com',
  },
  // {
  //   image: `${STATIC_DOMAIN}/pages/landing/partners/unicorn.png`,
  //   name: 'Unicorn',
  //   description: 'Unicorn Training has been creating award-winning LMS, eLearning and compliance solutions for the financial services industry for the last 25 years.',
  //   integration: 'Anders Pink integrates with Unicorn’s minds-i app to provide its users with relevant curated content.',
  //   url: 'https://www.unicorntraining.com',
  // },

  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/tom.png`,
    name: 'Teach on Mars',
    description:
      'Teach on Mars is the finest next-generation learning ecosystem in the (known) universe.<br /><br />We help organisations connect people with the learning and communities that enable them do their jobs - and manage their lives - better.',
    integration:
      'Anders Pink integrates with Teach on Mars mobile learning app',
    url: 'https://www.teachonmars.com/en',
  },

  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/toolwire.png`,
    name: 'Toolwire',
    description:
      'Toolwire’s TILE platform enables companies to skill employees, enhance learning agility, and provide visibility of learning outcomes and resource usage. TILE "hyper engages" modern learners through a range of capabilities including personalized learning paths, video practice and assessment, real-time briefings, gamification, and virtual labs.',
    integration:
      'Anders Pink integrates with Toolwire’s Tile solution, and can be customised and supported by Toolwire.',
    url: 'https://www.toolwire.com',
  },
  // {
  //   image: `${STATIC_DOMAIN}/pages/landing/partners/goodpractice.png`,
  //   name: 'Good Practice',
  //   description: 'Since 2000, GoodPractice has been creating inspiring learning and performance resources for blue-chip organisations across the globe.',
  //   integration: 'Anders Pink integrates with the Good Practice content library.',
  //   url: 'https://www.goodpractice.com',
  // },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/ilearn.svg`,
    name: 'iLearn',
    description:
      'iLearn focuses on transforming job related training, skills development and overall human capital investment. The company provides a full range of services including learning management systems (LMS), off-the-shelf eLearning content (Arabic and English), eLearning consultancy and specialized custom content development.',
    integration:
      'Anders Pink integrates with Totara LMS, and can be customised and supported by iLearn',
    url: 'https://www.ilearnme.com',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/wpt.png`,
    name: 'Workplace Trends',
    description:
      'We organise intelligent, pitch-free conferences and specialise in current thinking and up-coming trends for the Workplace and Higher Education Learning Environments.',
    integration:
      'Workplace Learning offers Anders Pink as part of its wordpress powered solution, and can provide Anders Pink through to its members. ',
    url: 'https://workplacetrends.co',
  },

  // {
  //   image: `${STATIC_DOMAIN}/pages/landing/partners/kallidus.jpg`,
  //   name: 'Kallidus',
  //   description: 'Kallidus is an award-winning provider of learning, performance, 360, recruitment and talent management technologies, bespoke e-learning and consultancy.Kallidus Learn is a modern learning platform designed to make learning simple, fast and effective. Talk to the Kallidus team to see how you can bring fresh content into Kallidus Learn with an Anders Pink integration.',
  //   url: 'https://www.kallidus.com/',
  // },
  // {
  //     image: `${STATIC_DOMAIN}/pages/landing/partners/hownow.png`,
  //     name: 'HowKnow',
  //     description: 'HowNow is an AI-powered knowledge management platform designed to upskill and enable the modern day workforce. HowNow brings together all of your workplace knowledge (shared folders, CRMs, helpdesks etc) as well as expertly curated content including podcasts, books, blogs and workshops, powered by Anders Pink. Then using AI, HowNow recommends relevant knowledge based on role, experience, skill requirements, learning preferences and context.',
  //     url: 'https://gethownow.com/',
  // },

  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/fuse_clg2.png`,
    name: 'FUSE, provided by LogicBay',
    description: `
      LogicBay provides technology-enabled solutions that enable manufacturers to build, scale, and optimize their sales channel. LogicBay’s technology suite includes Partner Relationship Management (PRM), Learning Management System (LMS), and FUSE.
      <br /><br/ />
      FUSE, a member-based digital ecosystem for the manufacturing industry fosters opportunities for members to connect and collaborate effectively, exchange information, gain immediate access to content & courses and activate new and incremental sources of revenue. For additional information, visit: <a href="https://www.growwithfuse.com/" target="_blank">https://www.growwithfuse.com/</a>.
      `,
    url: 'https://www.growwithfuse.com/',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/aurion.svg`,
    name: 'Aurion Learning',
    description: `
      Aurion Learning specialise in creating ‘learning that works’, that extends to content and platform solutions that are tailored to achieving the required business impact that each client needs to see.`,
    url: 'https://aurionlearning.com/',
    moreInfoId: 'aurion-learning',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/elearningwmb.png`,
    name: 'eLearning WMB',
    description: `
      eLearning WMB - makers of the Open eLMS system and services - help organisations to improve the knowledge and skills of their learners both in the corporate and education sectors.`,
    url: 'https://www.e-learningwmb.com/',
    moreInfoId: 'elearning-wmb',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/deer-meadow.svg`,
    name: 'Deer Meadow Consulting AB',
    description: `After many years of work with Content Management Systems for IT companies such as CGI, Deer Meadow was formed with a focus on the CMS SiteVision. SiteVision is a tool used by more than 60% of Sweden's municipalities and many companies in various sectors. <br/><br/>
    Among other things, we have built a learning platform for Scania where we have made an integration with Anders Pink. The integration simplifies for editors to be able to easily present different briefings or saved folders seamlessly on their learning platforms all over the world.`,
    url: 'https://www.deermeadow.se/contact-us',
    moreInfoId: 'deer-meadow',
  },
  {
    image: `${STATIC_DOMAIN}/pages/landing/partners/learninghubz.png`,
    name: 'Learninghubz',
    description: `Learninghubz provides your employees with access to the most engaging and effective learning resources from YouTube and the world's leading MOOCs, all in one easy-to-use and cost-effective platform.<br/><br/>
    With over 10,000+ videos, classes, and courses, all the content is expertly curated and always up-to-date by our content team, saving you time and budget.<br/><br/>
    With Anders Pink,  Learninghubz gives access to the most up-to-date, fresh and relevant articles on the skills, topics and industries that matter to them and to the business.
`,
    url: 'https://www.learninghubz.com',
    moreInfoId: 'learninghubz',
  },
]

export default partners
