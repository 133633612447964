import React from 'react'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Loader from 'app/components/Loader'

const selector = formValueSelector('setupSubscription')

@compose(
  graphql(
    gql`
      query accountQuery($id: ID!) {
        account(id: $id) {
          id
          type
          name
          doesPlansetOverwrite
          forcePlansetInterval
          offeredPlanset {
            id
            name
            currency
            monthlyPlan {
              price
            }
            yearlyPlan {
              price
            }
          }
          customer {
            id
            country
            company
            line1
            line2
            region
            city
            postcode
            vatNumber
            firstName
            lastName
            email
            contactNumber
            paymentDetails {
              last4
              expiryMonth
              expiryYear
              createdAt
            }
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.params.id,
          },
        }
      },
    },
  ),
  withProps((props) => ({
    initialValues: {
      interval: 'year',
    },
  })),
  reduxForm({
    form: 'updateAccountSubscription',
  }),
  connect((state, props) => ({})),
)
export default class UpdateAccountSubscriptionPage extends React.Component {
  constructor() {
    super()
    this.state = {
      successState: null,
    }
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    const account = this.props.data.account

    if (this.state.successState === 'paid') {
      return (
        <div>
          <h5>Payment details successfully updated</h5>
          <br />
          <p>
            Thank you for purchasing the <strong>{planSet.name}</strong> plan.
          </p>
          <p>
            You can cancel at any point by{' '}
            <Link to="/contact" className="link">
              contacting us.
            </Link>
          </p>
          <p>Thanks, we hope you get value out of Anders Pink!</p>
        </div>
      )
    }

    return (
      <div>
        <h5>Update your payment details</h5>
      </div>
    )
  }
}
