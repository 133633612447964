import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'

import AdminTable from 'admin/components/AdminTable'

const ITEMS_PER_PAGE = 200

@graphql(
  gql`
    query(
      $query: String
      $sort: String
      $sortOrder: String
      $limit: Int!
      $offset: Int!
    ) {
      adminPlanSets(
        query: $query
        sort: $sort
        sortOrder: $sortOrder
        limit: $limit
        offset: $offset
      ) {
        id
        type
        name
        description
        personalBriefingAllowance
        personalBoardAllowance
        apiType
        canCreateApiKeys
        totalNumBriefings
        totalNumBoards
        totalNumUsers
        totalNumApiBriefings
        totalNumApiBoards
        totalNumPublicBriefings
        totalNumPublicBoards
        canPubliclyExport
        monthlyPlan {
          id
          price
        }
        yearlyPlan {
          id
          price
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          query: props.location.query.query || '',
          sort: props.location.query.sort || 'id',
          sortOrder: props.location.query.sortOrder || 'desc',
          limit: props.location.query.limit || ITEMS_PER_PAGE,
          offset: props.location.query.offset || 0,
        },
      }
    },
    props({ data: { adminPlanSets, fetchMore, ...rest } }) {
      return {
        data: {
          adminPlanSets,
          ...rest,
          loadMore() {
            return fetchMore({
              variables: {
                offset: adminPlanSets.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return Object.assign({}, previousResult, {
                  // Append the new feed results to the old one
                  adminPlanSets: [
                    ...previousResult.adminPlanSets,
                    ...fetchMoreResult.adminPlanSets,
                  ],
                })
              },
            })
          },
        },
      }
    },
  },
)
export default class AdminPlansPage extends React.Component {
  render() {
    return (
      <div>
        <AdminTable
          fields={{
            id: {
              label: 'ID',
              render: (id) => (
                <Link to={`/admin/plans/${id}`} className="link">
                  {id}
                </Link>
              ),
            },
            type: {
              label: 'Type',
            },
            name: {
              label: 'Name',
            },
            description: {
              label: 'Name',
            },
            monthlyPlan: {
              label: 'Monthly price',
              render: (monthlyPlan) =>
                monthlyPlan ? `$${monthlyPlan.price}` : null,
            },
            yearlyPlan: {
              label: 'Yearly price',
              render: (yearlyPlan) =>
                yearlyPlan ? `$${yearlyPlan.price}` : null,
            },
            totalNumUsers: {
              label: 'Num users',
            },
            totalNumBriefings: {
              label: 'Num briefings',
            },
            totalNumBoards: {
              label: 'Num folders',
            },
            personalBriefingAllowance: {
              label: 'Num personal briefings',
            },
            personalBoardAllowance: {
              label: 'Num personal boards',
            },
            apiType: {
              label: 'API access',
              render: (apiType) => {
                return apiType ? apiType : 'none'
              },
            },
            totalNumApiBriefings: {
              label: 'Num briefings via API',
            },
            totalNumApiBoards: {
              label: 'Num boards via API',
            },

            canPubliclyExport: {
              label: 'Can publicly export',
              render: (canPubliclyExport) => (canPubliclyExport ? 'Yes' : 'No'),
            },
            totalNumPublicBriefings: {
              label: 'Num briefings publicly exportable',
            },
            totalNumPublicBoards: {
              label: 'Num boards publicly exportable',
            },

            canCreateApiKeys: {
              label: 'Can create own API keys',
              render: (canCreateApiKeys) => (canCreateApiKeys ? 'Yes' : 'No'),
            },
          }}
          data={this.props.data.adminPlanSets || []}
          loading={this.props.data.loading}
          onSubmit={(values) => {
            browserHistory.push({
              pathname: '/admin/plans',
              query: {
                ...values,
              },
            })
          }}
          loadMore={() => {
            this.props.data.loadMore()
          }}
          initialValues={{
            ...this.props.location.query,
          }}
        />
      </div>
    )
  }
}
