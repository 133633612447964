import React, { useEffect, useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import uniq from 'lodash/uniq'

const isEmail = (string) => {
  const emailValidation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return emailValidation.test(String(string).toLowerCase())
}

const EmailInput = ({ input, defaultValues, disabled }) => {
  const [lastValue, setLastValue] = useState('')
  const [emails, setEmails] = useState([])

  useEffect(() => {
    setEmails(input.value)
  }, [])

  return (
    <AsyncCreatableSelect
      isMulti
      isDisabled={disabled}
      formatCreateLabel={(inputValue) => `${inputValue}`}
      createOptionPosition="first"
      placeholder="Paste here a list of emails separated by comma"
      value={emails.map((t) => ({
        value: t,
        label: t,
      }))}
      onChange={(selectedOptions) => {
        const emails = selectedOptions.map((o) => o.value)
        setEmails(emails)
        input.onChange(emails)
      }}
      onBlur={(event) => {
        const values = lastValue
          .split(',')
          .map((v) => v.trim().toLowerCase())
          .filter((v) => !!v)

        setLastValue('')
        setEmails(uniq([...emails, ...values]))
      }}
      onCreateOption={(inputValue, a) => {
        let values = inputValue
          .split(',')
          .map((v) => v.trim().toLowerCase())
          .filter((v) => !!v)

        let emailOptions = []
        for (let i = 0; i < values.length; i++) {
          let newEmail = values[i].split(' ')
          emailOptions.push(...newEmail)
        }

        let validEmails = emailOptions.filter((e) => isEmail(e))
        setEmails(uniq([...emails, ...validEmails]))
        input.onChange(uniq([...emails, ...validEmails]))
      }}
      onInputChange={(value) => {
        if (lastValue !== value) {
          setLastValue(value)
        }
      }}
    />
  )
}

export default EmailInput
