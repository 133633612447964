import React from 'react'
import { connect } from 'react-redux'
import pageStyles from 'content/pages/styles.css'
import styles from './styles.css'
import { Link } from 'react-router'
import FlipCard from 'react-flipcard-2'

import Typist from 'react-typist'

import ContactForm from 'content/containers/ContactForm'
import Infographic from 'content/containers/Infographic'

class RestartingTypist extends React.Component {
  state = { typing: true }

  done = () => {
    this.setState({ typing: false }, () => {
      this.timeouts.push(
        setTimeout(
          () => this.setState({ typing: true }),
          this.props.timeout || 1200,
        ),
      )
    })
  }
  componentDidMount() {
    this.timeouts = []
  }
  componentWillUnmount() {
    this.timeouts.forEach(window.clearTimeout)
  }
  render() {
    const { children, timeout, ...props } = this.props
    return this.state.typing ? (
      <Typist {...props} onTypingDone={this.done}>
        {children}
      </Typist>
    ) : (
      <span>&nbsp;</span>
    )
  }
}

@connect((state) => ({
  app: state.app,
}))
export default class Landing extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    const clients = [
      {
        img: `${STATIC_DOMAIN}/pages/landing/scania-logo.png`,
        content: (
          <p>
            "Using Anders Pink is a key component of our curation strategy at
            Scania. It’s creating a seamless experience for visitors to the
            portal together with our own legacy content. Anders Pink is crucial
            for us to offer the the latest and greatest content on key topics
            for our business."
          </p>
        ),
      },
      // {
      //   img: `${STATIC_DOMAIN}/pages/landing/cibc-logo.png`,
      //   content: (
      //     <p>
      //       "Anders Pink has been a phenomenal go-to source for us to gather and
      //       curate engaging articles – which in turn our advisors use to grow
      //       their networks and generate leads. It saves our team a lot of time,
      //       and we can customize what we want to see."
      //     </p>
      //   ),
      //   caseStudyUrl: '/client-success-stories/cibc',
      // },
      {
        img: `${STATIC_DOMAIN}/pages/landing/hitachi-logo.png`,
        content: (
          <p>
            "Curated content is now part of our resources in support of a
            learning topic, embedded directly into our Learning Platform.
            Through this we can encourage a cultural change where learning is
            something you can do for 5-10 minutes a day to help us turn into a
            learning organization."
          </p>
        ),
        caseStudyUrl: '/client-success-stories/hitachi',
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/kineo-logo.png`,
        content: (
          <p>
            "Anders Pink is really useful tool. We use it on the platforms we
            design. Access to constantly updated, well targeted and easy to find
            articles make the LMS a useful destination for the learner,
            encouraging repeat visits to the platform."
          </p>
        ),
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/fuse-logo.png`,
        content: (
          <p>
            "Finding great external content is vital for our clients. Anders
            Pink is fully integrated into Fuse, bringing in the most popular,
            new content that people are loving. Amazing, automatic curation,
            saving time."
          </p>
        ),
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/ei-logo.png`,
        content: (
          <p>
            "We have added content quickly based on the needs of the relevant
            programme or trends. This has saved time and resources in sourcing
            content for subjects. Our clients tell us as time is precious for
            them, it is very advantageous to access relevant sources of content
            in one location."
          </p>
        ),
        caseStudyUrl: '/client-success-stories/enterprise-ireland',
      },
    ]

    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundImage: `url(${STATIC_DOMAIN}/pages/landing/4795118657_7a51dd1c0f_o.jpg)`,
          }}
        >
          <div className={pageStyles.heading}>
            <div className={pageStyles.title}>
              Content Curation for Learning
            </div>
            <div className={pageStyles.dynamicSubtitleContainer}>
              <div className={pageStyles.dynamicSubtitle}>
                <RestartingTypist avgTypingDelay={60} stdTypingDelay={1}>
                  <span>Complete control over your sites and sources</span>
                  <Typist.Backspace count={44} delay={1700} />
                  <span>
                    Easier and automated, with fresh content every day
                  </span>
                  <Typist.Backspace count={50} delay={1700} />
                  <span>
                    Plugged into the tools and platforms you already use
                  </span>
                  <Typist.Backspace count={52} delay={1700} />
                </RestartingTypist>
              </div>
            </div>
          </div>

          <div className={styles.awards}>
            <div className={styles.awardsInner}>
              <img
                src={`${STATIC_DOMAIN}/pages/landing/bronze-award.png`}
                className={styles.award1}
              />
              <img
                src={`${STATIC_DOMAIN}/pages/landing/lpi_awards20_innovation_finalist.png `}
                className={styles.award3}
              />
              <img
                src={`${STATIC_DOMAIN}/pages/landing/tools-for-learning-award.png`}
                className={styles.award2}
              />
            </div>
          </div>
        </div>

        <div className={pageStyles.greySection}>
          <div className={styles.introInner}>
            <div>Save time, stay smart</div>
            <div>Keep your content and platform fresh</div>
            <div>Drive continuous learning in the tools you already use</div>
          </div>
        </div>

        <Infographic />

        <div className={styles.demo}>
          <Link
            to="/contact"
            style={{ position: 'relative' }}
            className="btn btn-lg btn-primary"
          >
            Start a free trial
          </Link>
        </div>

        <div className={pageStyles.greySection}>
          <div className={pageStyles.innerGreySection}>
            <div className={pageStyles.sectionHeading}>
              See Anders Pink in action
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px',
              }}
            >
              <iframe
                src="https://player.vimeo.com/video/385936450"
                width="640"
                height="339"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeading}>
            Helping our clients and partners become content curation heroes:
          </div>

          <div className={pageStyles.clientBlocks}>
            {clients.map((client, i) => {
              return (
                <div key={i} className={pageStyles.clientBlock}>
                  <FlipCard>
                    <div className={pageStyles.clientBlockFront}>
                      <img src={client.img} />
                    </div>
                    <div className={pageStyles.clientBlockBack}>
                      <div>{client.content}</div>
                      {!!client.caseStudyUrl && (
                        <div>
                          <Link
                            to={client.caseStudyUrl}
                            className="btn btn-primary btn-sm"
                          >
                            See case study
                          </Link>
                        </div>
                      )}
                    </div>
                  </FlipCard>
                </div>
              )
            })}
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeading}>
            We are here to make your team content curation heroes.
          </div>

          <div
            className={pageStyles.textContainer}
            style={{
              textAlign: 'center',
              marginBottom: '4rem',
              fontSize: '1.2rem',
            }}
          >
            <p>We'd love to help you.</p>
            <p>
              We'll show you how it works, and set you up with a free trial so
              you can curate content on any topic for yourself and your
              business.
            </p>
          </div>

          <ContactForm />
        </div>
      </div>
    )
  }
}
