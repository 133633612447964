import React, { useState } from 'react'
import { Link } from 'react-router'

import ReactMarkdown from 'react-markdown'

import styles from './SupportPage.module.css'
import pageStyles from 'content/pages/styles.css'

const faqs = [
  {
    question: 'Where can I download the plug-ins from?',
    answer: `
We add our plugins to [GitHub](https://github.com/anderspink) 

Totara Learn https://github.com/anderspink/totara-block_anderspink 

Totara Engage https://github.com/anderspink/ap-totara-engage-plugin
    `,
    types: [],
    related: [],
  },
  {
    question: 'What are the names of the Anders Pink plug-ins?',
    answer: `
There are a few different Anders Pink plug-ins. The current plugin names, and a description for each, are:

- **Anders Pink Learn** Manages the Anders Pink block that can be added to Learn pages
- **Anders Pink Engage 1** - Manages Anders Pink resources that can be added to Engage Workspaces
- **Anders Pink Engage 2** - Manages some scheduled tasks for Anders Pink Engage features, and acts as a connector for the 'Anders Pink Engage 1' and 'Container' plugins
- **Container** - Manages the API interface and workspace settings for Anders Pink Engage features
        `,
    types: [],
    related: [],
  },
  {
    question: 'How do I add an API key to my Anders Pink Learn plug-in?',
    answer: `
1. Ensure the plugin is installed https://github.com/anderspink/totara-block_anderspink 
2. Click > settings cog in Totara
3. Click > Plugins
4. Locate “Anders Pink” block_anderspink click > settings
5. Click > Add new 
6. Add your team name (this can be any name you choose) 
7. Add your Anders Pink API key (Your key is on the “[Export Settings](https://anderspink.com/export-settings/)” in you Anders Pink team)

You can add multiple API keys for multiple teams. Keys can also be managed on this page

![Totara Learn](${STATIC_DOMAIN}/totara/plugins/learn1.png)
`,
    types: [],
    related: [],
  },
  {
    question:
      'How do I add an Audience in Totara? (Totara feature not Anders Pink feature)',
    answer: `
1. Click > settings cog
2. Click > Audiences
3. Click tab > Add new audience 
4. Choose your type (The audience type can be **set** or **dynamic.** Set audiences are a predefined list of users, manually created by the audience creator. The creator can add or remove users but otherwise the list is static. Dynamic audiences are determined by a rule or set of rules, and the users included in the audience will dynamically update to include users who match those rules (and remove users who no longer match). The members of a set audience can be changed at any time, and the rules that define a dynamic audience can also be changed, leading to the audience membership being updated.)
5. Click > Save changes
`,
    types: [],
    related: [],
  },
  {
    question:
      'How do I configure a block and add one or more briefings for an audience?',
    answer: `
1. When on Totara home (as an admin user) click > Manage dashboard
2. Either create a new dashboard or select an existing dashboard from the list 
3. Click > Edit dashboard settings cog to change the audience settings for that dashboard > Click Save changes
4. In the Manage dashboards screen click on the name of the dashboard you wish to configure (on the left) then click > Blocks editing on
5. Click > the plus icon + to add the Anders Pink Learn block
6. Click > The setting cog on the block you just added > Configure Anders Pink Learn block
7. Work through the block configuration settings
8. Select “Override default block title**”** if you’d like to change the block title and Save the changes 
9. Click > Briefings > Add Briefings (tip: Save the block settings before attempting to add the briefings)
10. Choose Briefings OR Saved folders first > select the Anders Pink > briefings/folders
11. Select your Anders Pink team from the dropdown, then your briefing or saved folder
12. Select the briefing time period (This is the time period that you will see the top articles from. Leaving on Auto is recommended as it will select a good time period based on how many new articles are coming in. E.g. if your briefing gets many fresh articles every day, Auto will show articles from the last 24 hours rather than 3 days.) 
13. For audience, if you leave this blank all users will see the briefing/saved folder you add to the dashboard if you select an audience only the user in that audience will see the content displayed in the block. Click > Save 
14. If you select more than one briefing or saved folder, next you can decide if the content should be mixed (Displays articles from each briefing in a combined list) OR separate (Displays each briefing separately, with a heading for each). 
15. There are many other settings for images, articles descriptions, columns etc. Choose your preferences then click > Save changes.

This is an example of a block with the following configuration:

- Added own title
- Only articles with images
- Show previews of content
- Two columns of articles
- Max 6 articles
- Two briefings: 1 French and 1 English with the content mixed

![Totara Learn](${STATIC_DOMAIN}/totara/plugins/learn2.png)
`,
    types: [],
    related: [],
  },
  {
    question: 'Can I add more than one briefing or saved folder to a block?',
    answer: `
Yes you can. When in the block configuration settings. Click the Add briefing button for each briefing or saved folder you wish to add to a block. We recommend adding two or three as a maximum. 

![Totara Learn](${STATIC_DOMAIN}/totara/plugins/learn3.png)
`,
    types: [],
    related: [],
  },
  {
    question:
      'What does “separate” and “mixed” mean in the General block settings?',
    answer: `
**Separate:** Displays each briefing separately, with a heading for each

**Mixed:** Displays articles from each briefing in a combined list
`,
    types: [],
    related: [],
  },
]

const SupportPageTotaraLearn = () => {
  const [faqSelected, setFaqSelected] = useState(null)
  const [briefingTypeSelected, setBriefingTypeSelected] = useState('none')
  const [searchTerm, setSearchTerm] = useState('')

  function toggleAccordion(question) {
    if (faqSelected === question) {
      return setFaqSelected(null)
    }
    setFaqSelected(question)
  }

  // const filteredFaqs = faqs.filter((val) => {
  //   if (searchTerm === '' && briefingTypeSelected === 'none') {
  //     return
  //   } else if (searchTerm === '' && val.types.includes(briefingTypeSelected)) {
  //     return val
  //   } else if (
  //     val.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     briefingTypeSelected === 'none'
  //   ) {
  //     return val
  //   } else if (
  //     val.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     val.types.includes(briefingTypeSelected)
  //   ) {
  //     return val
  //   }
  // })
  const filteredFaqs = faqs.filter((faq) => {
    if (searchTerm === '') {
      return faq
    } else if (
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (faq.answer &&
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase()))
    ) {
      return faq
    }
  })

  return (
    <div style={{ textAlign: 'center' }}>
      <div className={styles.greyBg}>
        <h5>How can we help you?</h5>
        <br />
        <div style={{ maxWidth: '700px', margin: '0 auto' }}>
          <input
            className={styles.faqSearch}
            type="text"
            placeholder="Search for a question"
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />
        </div>
        <br />
        {/* <p className={styles.introText}>
        When building a briefing, you can use one of three methods: New, Simple
        or Advanced. To see FAQs related to your briefing type, pick one below
        or simply search for your query.
      </p> */}
        <p className={styles.introText}>
          You can also find further support options at the bottom of the page.
        </p>
      </div>

      <br />

      {/* <h5>Select a briefing type</h5>
      <div className={styles.briefingSelectors}>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('new')}
        >
          New
        </button>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('simple')}
        >
          Simple
        </button>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('advanced')}
        >
          Advanced
        </button>
      </div> */}

      <div className={styles.faqs}>
        {filteredFaqs.map((faq) => (
          <div className={styles.faq} key={faq.question}>
            <div
              className={styles.question}
              onClick={() => toggleAccordion(faq.question)}
            >
              {faq.question}
              <span style={{ color: '#cf0032' }}>
                {faqSelected === faq.question ? '-' : '+'}
              </span>
            </div>
            <div
              className={
                faqSelected === faq.question
                  ? `${styles.answer} ${styles.answerShow}`
                  : styles.answer
              }
            >
              <br />
              <ReactMarkdown source={faq.answer} />
              {faq.videoSrc && (
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0',
                  }}
                >
                  <iframe
                    src={faq.videoSrc}
                    frameBorder="0"
                    allowFullScreen={true}
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe>
                </div>
              )}
              {faq.related && faq.related.length > 0 && (
                <div>
                  <br />
                  You may also find the following useful:
                  <div className={styles.relatedQuestions}>
                    {faq.related.map((question) => (
                      <p
                        key={question}
                        onClick={() => toggleAccordion(question)}
                      >
                        {question}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <br />

      <div className={styles.greyBg}>
        <div className={styles.furtherSupport}>
          <h6 className={styles.furtherSupportHeader}>
            Require further support?
          </h6>
          <div className={styles.supportButtons}>
            <Link to="/api-docs">
              <button className={styles.supportButton}>
                API Documentation
              </button>
            </Link>
            <Link to="/integrations">
              <button className={styles.supportButton}>
                Plugin and Integration Options
              </button>
            </Link>
            <a href="mailto:support@anderspink.com">
              <button className={styles.supportButton}>
                Contact us via support@anderspink.com
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default class SupportPageTotaraLearnWrapper extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          // style={{
          //   backgroundPosition: '50% 68%',
          //   backgroundImage: 'url(/pages/landing/shutterstock_401541778.jpg)',
          // }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Totara Learn Plugin FAQ</div>
          </div>
        </div>

        <SupportPageTotaraLearn />
      </div>
    )
  }
}
