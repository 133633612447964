import React from 'react'
import styles from './APIDocumentationPage.css'
import { Link } from 'react-router'

export default class APIDocumentationPage extends React.Component {
  render() {
    return (
      <div>
        <h4>API documentation</h4>

        <br />
        <div className="alert alert-info">
          <p>
            This is the documentation for the latest version (v3) of the API,
            released June 2019. The old v2 API can be found{' '}
            <Link to="/api-docs/v2" className="link">
              here
            </Link>
            .
          </p>
          <p>What's new in v3:</p>
          <ul>
            <li>
              Briefing creation is now in line with the latest view on the web
              app, where we have split briefings into simple and advanced. This
              means a different data structure must be passed up when
              creating/editing briefings, please see the below examples for how
              briefings are created.
            </li>
            <li>
              Added an endpoint for previewing a briefing before you make it
            </li>
          </ul>
          <p>
            To migrate from v2 to v3, please use the new URL structure
            https://anderspink.com/api/<strong>v3</strong>/ rather than
            https://anderspink.com/api/<strong>v2</strong>/ and update your code
            to use the new structure for creating briefings.
          </p>
        </div>

        <p>
          For high level information about the API and plans/pricing, please see
          our{' '}
          <Link to="/api-info" className="link">
            API information page
          </Link>
          .
        </p>

        <p>
          To make any requests you'll need an API account. API accounts are tied
          either to your user or one of your teams. Please{' '}
          <Link className="link" to="/contact">
            contact us
          </Link>{' '}
          if you'd like a trial API account.
        </p>

        <p>
          An API account has an API key. Using this key, you can access the
          briefings and boards of the respective user/team.
        </p>

        <p>
          The following endpoints are currently available:
          <ul>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#list-briefings" className={styles.spancode}>
                GET /briefings
              </a>{' '}
              - get a list of all briefings tied to your api account
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-briefing" className={styles.spancode}>
                GET /briefings/:id
              </a>{' '}
              - get back a particular briefing and its articles. If you or your
              team own the briefing you'll see the settings for it (such as
              keywords)
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#create-briefing" className={styles.spancode}>
                POST /briefings
              </a>{' '}
              - create a new briefing, using all the same options available in
              the web app{' '}
              <span className={styles.access}>(for full API users only)</span>
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#update-briefing" className={styles.spancode}>
                PUT /briefings/:id
              </a>{' '}
              - update a particular briefing that is tied to your api account{' '}
              <span className={styles.access}>(for full API users only)</span>
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#delete-briefing" className={styles.spancode}>
                DELETE /briefings/:id
              </a>{' '}
              - delete a particular briefing that is tied to your api account{' '}
              <span className={styles.access}>(for full API users only)</span>
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#preview-briefing" className={styles.spancode}>
                POST /briefing-previews
              </a>{' '}
              - previews a new briefing by supplying the same config as used
              when making a briefing{' '}
              <span className={styles.access}>(for full API users only)</span>
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#create-briefing-training" className={styles.spancode}>
                POST /briefings/:id/trainings
              </a>{' '}
              - trains a briefing by supplying an article id, and whether it's
              relevant or not{' '}
              <span className={styles.access}>(for full API users only)</span>
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#list-boards" className={styles.spancode}>
                GET /boards
              </a>{' '}
              - get a list of all the saved boards tied to your api account
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-board" className={styles.spancode}>
                GET /boards/:id
              </a>{' '}
              - get back a particular saved board and its articles
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-sources" className={styles.spancode}>
                POST /sources/:keyword
              </a>{' '}
              - pass in a keyword (e.g. marketing) and get back a list of
              suggested sources (domains, rss feeds and influencers){' '}
              <span className={styles.access}>(for full API users only)</span>
            </li>
          </ul>
        </p>

        <div className={styles.endpointSection}>
          <h4>Authentication</h4>

          <p>
            You API key must be passed up with every request. The API key should
            should appear in the header field as X-Api-Key. All requests must go
            over https.
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v3/briefings/653`}
          </pre>
        </div>

        <div className={styles.endpointSection}>
          <h4>Response format</h4>

          <p>
            Responses are in JSON format, following the rough spec defined at{' '}
            <a
              target="_blank"
              className="link"
              href="https://labs.omniti.com/labs/jsend"
            >
              https://labs.omniti.com/labs/jsend
            </a>
          </p>

          <p>
            Every request will return a json object with a{' '}
            <code className={styles.spancode}>status</code> field which is set
            to <code className={styles.spancode}>success</code>,{' '}
            <code className={styles.spancode}>fail</code>, or{' '}
            <code className={styles.spancode}>error</code>.
          </p>

          <p>
            On <code className={styles.spancode}>success</code> there will
            always be a <code className={styles.spancode}>data</code> field
            containing any response data. On{' '}
            <code className={styles.spancode}>fail</code> and{' '}
            <code className={styles.spancode}>error</code> there will be a{' '}
            <code className={styles.spancode}>message</code> field giving a
            human readable description of what has gone wrong.
          </p>

          <p>
            The relevant HTTP status codes are used where possible for every
            response.
          </p>
        </div>

        <div className={styles.endpointSection}>
          <h4>Errors</h4>

          <p>
            If something is wrong with the request, the{' '}
            <code className={styles.spancode}>status</code> field will be set to{' '}
            <code className={styles.spancode}>fail</code> and the a relevant{' '}
            <code className={styles.spancode}>message</code> will be given.
          </p>

          <p>
            If something is wrong on the server, the{' '}
            <code className={styles.spancode}>status</code> field will be set to{' '}
            <code className={styles.spancode}>error</code> and a message will be
            given. Please report any issues you come across.
          </p>

          <p>
            For example, if you you supply an incorrect API key you'll receive a
            a 401 status code and the below response:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "fail",
    "message": "Your API key is invalid"
}`}
          </pre>

          <p>
            If the briefing you're requesting cannot be found you'll receive a
            404 status code and the below response:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "fail",
    "message": "This briefing doesn't exist"
}`}
          </pre>

          <p>
            If the server is down you'll receive a 500 status code and a
            response similar to:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "error",
    "message": "Server is down"
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="list-briefings">
          <h4>Endpoint: list all owned/subscribed briefings</h4>

          <p>
            To retrieve the list of briefings tied to your api account you can
            do
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v3/briefings`}
          </pre>

          <p>
            You'll get a response similar to below. Please not that the
            briefings will be split into two arrays under the keys{' '}
            <code className={styles.spancode}>owned_briefings</code> and{' '}
            <code className={styles.spancode}>subscribed_briefings</code>.
          </p>

          <pre className={styles.code} style={{ maxHeight: '500px' }}>
            {`{
    "status": "success",
    "data": {
        "owned_briefings": [
            {
                "id": 1749,
                "name": "Programming",
                "description": null,
                "image": "https://stratechery.com/wp-content/uploads/2017/03/3753191500_c28898135a_o-1024x814.gif",
                "type: "simple",
                "language": "en",
                "simple_fields": {
                    "topics": [
                        "programming"
                    ],
                    "included_words": [],
                    "excluded_words": [],
                    "blocked_domains": [],
                    "domains": [],
                    "categories": []
                }
            },
            {
                "id": 1750,
                "name": "Content marketing",
                "description": null,
                "image": "https://stratechery.com/wp-content/uploads/2017/03/3753191500_c28898135a_o-1024x814.gif",
                "type: "advanced",
                "language": "en",
                "advanced_fields": {
                    "sources": [
                        {
                            "type": "topic",
                            "value": "content marketing"
                        }
                    ],
                    "keywords": [
                    ],
                    "included_words": [
                    ],
                    "excluded_words": [
                    ],
                    "blocked_domains": [
                    ]
                }
            }
        ],
        "subscribed_briefings": [
            {
                "id": 140,
                "name": "SaaS Strategy & Pricing",
                "description": "The latest articles about SaaS strategies and pricing, including all posts from Tom Tunguz and Saastr.",
                "image": null
            },
            {
                "id": 767,
                "name": "Latest News",
                "description": "Latest news from Reuters, BBC, CBS, New York Times, CNN, FT and Washington Post",
                "image": "https://si.wsj.net/public/resources/images/BN-QK128_APPLET_G_20161021180155.jpg"
            }
        ]
    }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="get-briefing">
          <h4>Endpoint: retreive a briefing</h4>

          <p>
            You can retrieve a briefing that you or your team have created, or
            that is public. Briefing ids are numerical currently.
          </p>

          <p>
            To retrieve the public AI & Virtual Reality briefing with a few of
            the top articles in the last 3 days:
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v3/briefings/34?limit=2&offset=0&time=3-days`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "34",
    "name": "AI & Virtual Reality",
    "articles": [
      {
        "id": "719634",
        "title": "How to Build a Neuron: Exploring AI in JavaScript Pt 2 — JavaScript Scene",
        "content": "In this series, we’re discussing a topic that will transform the world we live in over the course of the next 25 years. We’re going to see lots of drones, self driving cars, VR, and AR devices changing...",
        "image": "https://cdn-images-1.medium.com/max/1200/1*p37KRWpihIwr1UJ0gozG9g.jpeg",
        "date_published": "2016-07-02T07:30:40.000Z",
        "url": "https://medium.com/javascript-scene/how-to-build-a-neuron-exploring-ai-in-javascript-pt-2-2f2acb9747ed",
        "author": null,
        "domain": "medium.com",
        "reading_time": null
      },
      {
        "id": "719640",
        "title": "Painting with AIs",
        "content": "A year or two ago, if you’d asked, I would have suggested that yes, eventually, software and robots will take all our jobs. The only jobs that were potentially safe...",
        "image": "https://cdn-images-1.medium.com/max/800/1*_GTttLcm9AgX_SkfQYTXtg.jpeg",
        "date_published": "2016-07-02T07:30:05.000Z",
        "url": "https://medium.com/art-marketing/painting-with-ais-6bcff75d57e7",
        "author": "Phil McCluskey",
        "domain": "medium.com",
        "reading_time": 164
      }
    ]
  }
}`}
          </pre>

          <p>
            All article attributes will be present, but occasionally{' '}
            <code className={styles.spancode}>author</code>,{' '}
            <code className={styles.spancode}>content</code>,{' '}
            <code className={styles.spancode}>reading_time</code> or{' '}
            <code className={styles.spancode}>image</code> will be set to null.
            If an image is present, it will be optimised and served over https.
            Reading time (<code className={styles.spancode}>reading_time</code>)
            is given in seconds and is an estimate based on word count and
            complexity.
          </p>

          <p>
            Available URL parameters are{' '}
            <code className={styles.spancode}>limit</code> (default 10) for
            limiting the total number of returned results, and{' '}
            <code className={styles.spancode}>offset</code> (default 0) for
            choosing where to start.
          </p>
          <p>
            Use <code className={styles.spancode}>time</code> to restrict your
            briefing to a certain time period, e.g.{' '}
            <code className={styles.spancode}>1-week</code> or{' '}
            <code className={styles.spancode}>4-days</code>. By default this
            will be set to <code className={styles.spancode}>auto</code> and we
            will automatically pick a time scale as small as possible which
            still has good, fresh articles in.
          </p>
        </div>

        <div className={styles.endpointSection} id="create-briefing">
          <h4>
            Endpoint: create a briefing{' '}
            <span className={styles.access}>(for full API users only)</span>
          </h4>

          <p>
            You can create a personal or team briefing. The briefing has several
            fields, most of which are optional. You must include a name and at
            least one of the following: keyword, influencer, rss feed, domain.
          </p>

          <p>Here's the fields</p>

          <table className="table">
            <thead>
              <tr>
                <th>Field</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>name</td>
                <td>string</td>
                <td>The name of the briefing</td>
              </tr>
              <tr>
                <td>description</td>
                <td>string</td>
                <td>
                  The description of the briefing, shown on the briefing page to
                  give further info
                </td>
              </tr>
              <tr>
                <td>language</td>
                <td>string (2 characters)</td>
                <td>
                  We use this language to find articles based on the text that
                  appears in their title/body. Languages avalable are: zh
                  (Chinese), nl (Dutch), en (English), fr (French), it
                  (Italian), de (German), pt (Portuguese, es (Spanish).
                </td>
              </tr>
              <tr>
                <td>type</td>
                <td>string</td>
                <td>
                  Set to 'simple' or 'advanced', depending on which type of
                  briefing you want to make. See our web app for the differences
                </td>
              </tr>
              <tr>
                <td>simple_fields</td>
                <td>object</td>
                <td>
                  <p>Must be present when type is set to 'simple'</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>topics</td>
                        <td>array of strings</td>
                        <td>
                          A list of words which we'll use to find matching
                          content
                        </td>
                      </tr>
                      <tr>
                        <td>included_words</td>
                        <td>array of strings</td>
                        <td>
                          These are words that must also appear in the article
                          titles
                        </td>
                      </tr>
                      <tr>
                        <td>excluded_words</td>
                        <td>array of strings</td>
                        <td>
                          These are words that must not appear in the article
                          titles
                        </td>
                      </tr>
                      <tr>
                        <td>domains</td>
                        <td>array of strings</td>
                        <td>
                          Whitelist these domains, therefore only showing
                          content from them
                        </td>
                      </tr>
                      <tr>
                        <td>blocked_domains</td>
                        <td>array of strings</td>
                        <td>Block content that comes from these domains</td>
                      </tr>
                      <tr>
                        <td>categories</td>
                        <td>array of strings</td>
                        <td>
                          Whitelist to these pre-made group of domains. These
                          are language specific. When language is 'en', the
                          categories available are: sales, leadership,
                          hr-and-learning, marketing, technology. When language
                          is 'fr', the options are: business_fr
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>advanced_fields</td>
                <td>object</td>
                <td>
                  <p>Must be present when type is set to 'advanced'</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>sources</td>
                        <td>array of objects</td>
                        <td>
                          An array of source objects. Each object must have two
                          fields: type and value. The type field can be one of:
                          topic, domain, twitterUser, or rssFeed
                        </td>
                      </tr>
                      <tr>
                        <td>keywords</td>
                        <td>array of strings</td>
                        <td>
                          These are words that must appear in the article titles
                        </td>
                      </tr>
                      <tr>
                        <td>included_words</td>
                        <td>array of strings</td>
                        <td>
                          These are words that must also appear in the article
                          titles (these are AND'ed with the keywords)
                        </td>
                      </tr>
                      <tr>
                        <td>excluded_words</td>
                        <td>array of strings</td>
                        <td>
                          These are words that must not appear in the article
                          titles
                        </td>
                      </tr>
                      <tr>
                        <td>blocked_domains</td>
                        <td>array of strings</td>
                        <td>Block content that comes from these domains</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            For example, to make a simple briefing you can do the following
            call:
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X POST \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "AI",
        "type": "simple",
        "simple_fields": {
            "topics": ["business intelligence", "artificial intelligence"],
            "included_words": ["learning"],
            "excluded_words": ["microsoft"],
            "domains": ["forbes.com"],
            "blocked_domains": ["microsoft.com"]
        }
    }' \\
    https://anderspink.com/api/v3/briefings
`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
    "name": "AI"
  }
}`}
          </pre>

          <p>To make an advanced briefing with a variety of sources</p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X POST \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "Big data",
        "type": "advanced",
        "advanced_fields": {
            "sources": [
                {
                    "type": "topic",
                    "value": "big data"
                },
                {
                    "type": "domain",
                    "value": "bbc.co.uk"
                },
                {
                    "type": "twitterUser",
                    "value": "billgates"
                },
                {
                    "type": "rssFeed",
                    "value": "https://www.theguardian.com/world/rss"
                }
            ],
            "keywords": ["ai"],
            "included_words": ["learning"],
            "excluded_words": ["microsoft"],
            "blocked_domains": ["microsoft.com"]
        }
    }' \\
    https://anderspink.com/api/v3/briefings
`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="update-briefing">
          <h4>
            Endpoint: update a briefing{' '}
            <span className={styles.access}>(for full API users only)</span>
          </h4>

          <p>The same fields are available as when creating a briefing.</p>

          <p>
            To change our briefing from one about AI to Big data, we could do
            the following PUT call while specifying the briefing id:
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X PUT \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "Big data",
        "type": "simple",
        "simple_fields": {
            "topics": ["big data"],
            "included_words": ["learning"],
            "excluded_words": ["microsoft"],
            "domains": ["forbes.com"],
            "blocked_domains": []
        }
    }' \\
    https://anderspink.com/api/v3/briefings/1766
`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
    "name": "Big data"
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="delete-briefing">
          <h4>
            Endpoint: delete a briefing{' '}
            <span className={styles.access}>(for full API users only)</span>
          </h4>

          <p>To delete a briefing that your user or team owns, you can do:</p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X DELETE \\
    https://anderspink.com/api/v3/briefings/1766`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="preview-briefing">
          <h4>
            Endpoint: preview a briefing{' '}
            <span className={styles.access}>(for full API users only)</span>
          </h4>
          <p>
            You can preview a briefing before making it by supplying the exact
            same input as used in the create briefing endpoint. You may not
            receive as many articles as you would once the briefing has been
            created as it is a quicker preview.
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X POST \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "AI",
        "type": "simple",
        "simple_fields": {
            "topics": ["business intelligence", "artificial intelligence"],
            "included_words": ["learning"],
            "excluded_words": ["microsoft"],
            "domains": ["forbes.com"],
            "blocked_domains": ["microsoft.com"]
        }
    }' \\
    https://anderspink.com/api/v3/briefing-previews
`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="create-briefing-training">
          <h4>
            Endpoint: train a briefing{' '}
            <span className={styles.access}>(for full API users only)</span>
          </h4>
          <p>
            Train a briefing to know which articles you think are relevant or
            not. Pass up an article id from the briefing and whether it's
            relevant.
          </p>
          <p>
            To train our briefing with article 719634 and 'relevant' set to
            true, we could do the following POST call while specifying the
            briefing id:
          </p>
          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X POST \\
    -H "Content-Type: application/json" \\
    -d '{"articleId": 719634, "relevant": true}' \\
    https://anderspink.com/api/v3/briefings/1766/trainings`}
          </pre>
          <p>You'll get a response similar to below:</p>
          <pre className={styles.code}>
            {`{
  "status": "success",
}`}
          </pre>
          The briefing will re-sort your articles in the background, it may take
          up to a few minutes for this to take effect. The more times you train
          the briefing, the better the results will be.
        </div>

        <div className={styles.endpointSection} id="list-boards">
          <h4>Endpoint: list all saved boards</h4>

          <p>
            To retrieve the list of all saved boards tied to your api account
            you can do
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
https://anderspink.com/api/v3/boards`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
    "status": "success",
    "data": {
        "owned_boards": [
            {
                "id": "143",
                "name": "Favourites",
                "image": "https://media-mediatemple.netdna-ssl.com/wp-content/uploads/2016/03/build-2016-opt.png"
            }
        ]
    }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="get-board">
          <h4>Endpoint: retrieve a saved board</h4>

          <p>To retrieve a single saved board and its articles, you can do:</p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
https://anderspink.com/api/v3/boards/143`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
    "status": "success",
    "data": {
        "id": "143",
        "name": "Favourites",
        "articles": [
            {
                "id": "1133720",
                "title": "3. From Teaching to Diving: Memorizing Facts Is Not Good Enough. — Content Curation Official Guide",
                "content": "Education and teaching are undergoing major transformations as new approaches to learning, where the student is not just a recipient but an active explorer of the new...",
                "url": "https://medium.com/content-curation-official-guide/3-from-teaching-to-diving-memorizing-facts-is-not-good-enough-73dafe6ff8df",
                "image": "http://localhost:3000/proxy-image/normal/https%3A%2F%2Fcdn-images-1.medium.com%2Fmax%2F1200%2F1*ztGKfvY4jE8xa844reDE4A.jpeg",
                "domain": "medium.com",
                "author": null,
                "date_published": "2016-07-25T12:36:32.000Z",
                "reading_time": 65
            }
        ]
    }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="get-sources">
          <h4>
            Endpoint: find sources for a keyword{' '}
            <span className={styles.access}>(for full API users only)</span>
          </h4>

          <p>
            To retrieve the suggested sources for a specific keyword such as
            marketing, you can do:
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X POST \\
    https://anderspink.com/api/v3/sources/marketing`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
	"status": "success",
	"data": {
		"domains": [
			"adweek.com",
			"entrepreneur.com",
			"socialmediaexaminer.com",
			"hbr.org",
			"sagepub.com",
			"adage.com",
			"mckinsey.com",
			"forbes.com",
			"indiatimes.com",
			"researchbib.com",
			"chiefmartec.com",
			"contentmarketinginstitute.com",
			"toprankblog.com",
			"searchenginejournal.com",
			"bufferapp.com",
			"aljazeera.com",
			"techcrunch.com",
			"semrush.com",
			"yourstory.com",
			"wired.com",
            
			[...further results ommited]
		],
		"influencers": [
			{
				"name": "Lee Odden",
				"username": "leeodden",
				"bio": "CEO @TopRank Marketing. Author, Speaker & Consultant on B2B Content & Influencer Marketing, Social Media, PR & SEO. Proud Dad, World Traveler, Foodie, Beardie.",
				"numFollowers": 101332
			},
			{
				"name": "Jonathan Gebauer",
				"username": "jogebauer",
				"bio": "Marketer, Blogger, Influencer? Marketing Blogger at: https://t.co/iT292eIyPJ\nSocial Media Marketing Influencer #54 (Onalytica)",
				"numFollowers": 163804
			},
			{
				"name": "Chad S. White",
				"username": "chadswhite",
				"bio": "Author of 'Email Marketing Rules' and 3,000+ posts on #emailmarketing | Research Director @LitmusApp | prev. @Salesforce & @Responsys | former journalist",
				"numFollowers": 14966
			},
            
			[...further results ommited]
		],
		"rssFeeds": [
			"http://www.psfk.com/feed",
			"http://sethgodin.typepad.com/seths_blog/atom.xml",
			"http://feeds.mashable.com/Mashable",
			"http://www.quicksprout.com/feed/",
			"http://feeds.feedburner.com/OnlineMarketingSEOBlog"
		]
	}
}`}
          </pre>
        </div>

        <h4>Info and help</h4>

        <p>We will email any API users when there are any updates.</p>

        <p>
          If you have any questions or would like an API key please{' '}
          <Link className="link" to="/contact">
            contact us
          </Link>
          .
        </p>
      </div>
    )
  }
}
