import React, { Component } from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './FoundSources.css'
import sourceTypes, { renderSourceString } from 'briefings/config/sourceTypes'
import truncate from 'truncate'

import { FORM_NAMES } from './constants'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state, props) => {
  return {
    sources: selector(state, 'sourceFields.sources'),
  }
})
@graphql(
  gql`
    query($keywords: [String]) {
      suggestedSources(keywords: $keywords) {
        domains
        twitterUsers {
          username
          name
          bio
          numFollowers
        }
        rssFeeds
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          keywords: props.keyword ? [props.keyword] : [],
        },
      }
    },
  },
)
export default class FoundSources extends Component {
  static defaultProps = {
    onAddSource: (source) => {},
    onPreviewSource: (source) => {},
  }
  constructor() {
    super()
    this.state = {
      filter: 'all',
    }
  }
  render() {
    if (this.props.data.loading) {
      return <div>Loading, please wait..</div>
    }

    if (!this.props.data.suggestedSources) {
      return null
    }

    if (
      this.props.keyword !== null &&
      this.props.data.suggestedSources.domains.length === 0
    ) {
      return <div>No sources found</div>
    }

    let { domains, twitterUsers, rssFeeds } = this.props.data.suggestedSources

    const tabs = [
      {
        key: 'all',
        label: `All (${
          domains.length + rssFeeds.length + twitterUsers.length
        })`,
      },
      {
        key: 'domains',
        label: `Domains (${domains.length})`,
      },
      {
        key: 'rssFeeds',
        label: `RSS feeds (${rssFeeds.length})`,
      },
      {
        key: 'twitterUsers',
        label: `Twitter users (${twitterUsers.length})`,
      },
    ]

    return (
      <div>
        <ul className="nav nav-tabs" style={{ marginBottom: '10px' }}>
          {tabs.map((tab, index) => (
            <li key={index} className="nav-item" style={{ cursor: 'pointer' }}>
              <span
                onClick={() => this.setState({ filter: tab.key })}
                className={`nav-link ${
                  this.state.filter === tab.key ? 'active' : ''
                }`}
              >
                {tab.label}
              </span>
            </li>
          ))}
        </ul>

        <div>
          {(this.state.filter === 'all' || this.state.filter === 'domains') && (
            <div>
              {domains.map((domain, index) => (
                <div key={index}>{this.renderSource('domain', domain)}</div>
              ))}
            </div>
          )}
          {(this.state.filter === 'all' ||
            this.state.filter === 'rssFeeds') && (
            <div>
              {rssFeeds.map((rssFeed, index) => (
                <div key={index}>{this.renderSource('rssFeed', rssFeed)}</div>
              ))}
            </div>
          )}

          {(this.state.filter === 'all' ||
            this.state.filter === 'twitterUsers') && (
            <div>
              {twitterUsers.map((twitterUser, index) => (
                <div key={index}>
                  {this.renderSource('twitterUser', twitterUser)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
  renderSource(type, value) {
    let name = value
    let sourceObject = {
      type: type,
      value: value,
    }

    if (type === 'twitterUser') {
      name = `${value.name} (@${value.username})`
      sourceObject.value = value.username
    }

    // See if we've already added this source..
    const existingSource = this.props.sources.find((source) => {
      if (source.type === type) {
        if (source.type === 'twitterUser' && source.value === value.username) {
          return true
        } else if (source.type !== 'twitterUser' && source.value === value) {
          return true
        }
      }

      return false
    })

    return (
      <div className={styles.source}>
        <div style={{ flex: 1 }}>
          <div style={{ fontSize: '0.8em', color: '#888' }}>
            {renderSourceString(type)}
          </div>
          <div style={{ wordBreak: 'break-all', paddingRight: '10px' }}>
            {name}
          </div>
          {type === 'twitterUser' && (
            <div className={styles.sourceTwitterBio}>
              <div>Followers: {value.numFollowers}</div>
              <div>{truncate(value.bio, 80)}</div>
            </div>
          )}
        </div>
        <div>
          {existingSource === undefined && (
            <div>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => this.props.onPreviewSource(sourceObject)}
              >
                Preview source
              </button>{' '}
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.props.onAddSource(sourceObject)}
              >
                Add source
              </button>
            </div>
          )}
          {existingSource !== undefined && (
            <div style={{ fontSize: '0.9em' }}>Source added</div>
          )}
        </div>
      </div>
    )
  }
}
