import React from 'react'
import styles from '../Home.module.css'
import componentsStyles from '../Components.module.css'
import Carousel from 'react-elastic-carousel'
import ProductCard from './Product'

const PRODUCTS = [
  {
    name: 'Complete Curation',
    description:
      'Helping you curate the best content for your teams, mapped to the skills that matter.',
    platformsLink: '/cc/platforms',
    enterpriseLink: '/cc/enterprise',
  },
  {
    name: 'Content to Skills Mapper',
    description:
      'Solving the Content to Skills Mapping challenge automatically.',
    platformsLink: '/content-skills-mapper',
  },
  {
    name: 'Web Curation',
    description:
      'Curate the latest and best of the web to power your learning platform and keep your teams up-to-date on any topic and skills.',
    platformsLink: '/wc/platforms',
    enterpriseLink: '/wc/enterprise',
  },
]

const Products = () => {
  let paginationAndArrows = false

  if (PRODUCTS.length > 5) {
    paginationAndArrows = true
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 650, itemsToShow: 2 },
    { width: 800, itemsToShow: 2 },
    {
      width: 1000,
      itemsToShow: 3,
      pagination: paginationAndArrows,
      showArrows: paginationAndArrows,
    },
  ]

  return (
    <div className={`${styles.section} ${styles.productsContent}`}>
      <div className={`${styles.content}`}>
        <p style={{ display: 'flex' }}>
          <span className={`${styles.line} ${styles.backgroundWhite}`}>
            &nbsp;
          </span>
          <span className={`${styles.title} ${styles.fontWhite}`}>
            Our suite of curation solutions
          </span>
        </p>
      </div>
      {/* Product Cards */}
      <Carousel
        className={componentsStyles.carousel}
        breakPoints={breakPoints}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
      >
        {PRODUCTS.map((product, index) => (
          <ProductCard
            key={index}
            index={index}
            title={product.name}
            description={product.description}
            enterpriseLink={product.enterpriseLink}
            platformsLink={product.platformsLink}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default Products
