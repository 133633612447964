exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "body {\n  overflow-x: hidden;\n}\n\n.PlatformsHomepage\\.module__outerBody___1Bc7- {\n  background-color: #e5e5e5;\n  overflow-x: hidden;\n}\n\n.PlatformsHomepage\\.module__innerBody___3nY4w {\n  background-color: white;\n  margin: 0 auto;\n  /* max-width: 1640px; */\n}\n\n.PlatformsHomepage\\.module__home___3HGg0 {\n  font-family: 'Nunito', Arial, Helvetica, sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  --ap-pink: #cf0032;\n  --ap-green: #006067;\n}\n\n.PlatformsHomepage\\.module__background1___19vsf {\n  /* background-image: url('./header-bg/pink-curve.svg');\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: 100% 68%; */\n  background-color: #d00032;\n}\n.PlatformsHomepage\\.module__background1Inner___3sYrL {\n  background-color: white;\n}\n", ""]);
// Exports
exports.locals = {
	"outerBody": "PlatformsHomepage.module__outerBody___1Bc7-",
	"innerBody": "PlatformsHomepage.module__innerBody___3nY4w",
	"home": "PlatformsHomepage.module__home___3HGg0",
	"background1": "PlatformsHomepage.module__background1___19vsf",
	"background1Inner": "PlatformsHomepage.module__background1Inner___3sYrL"
};