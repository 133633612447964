exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AcceptTeamInvitePage__panels___2CClT {\n  margin-top: 10px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.AcceptTeamInvitePage__left___2-SWa {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  padding-right: 20px;\n  border-right: 1px solid #ddd;\n  margin-right: 20px;\n}\n.AcceptTeamInvitePage__right___-xMKh {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  margin-left: 20px;\n}\n.AcceptTeamInvitePage__loginText___3L45h {\n  margin-top: 20px;\n}\n.AcceptTeamInvitePage__signupText___WFV5Y {\n  margin: 10px 0px;\n}\n", ""]);
// Exports
exports.locals = {
	"panels": "AcceptTeamInvitePage__panels___2CClT",
	"left": "AcceptTeamInvitePage__left___2-SWa",
	"right": "AcceptTeamInvitePage__right___-xMKh",
	"loginText": "AcceptTeamInvitePage__loginText___3L45h",
	"signupText": "AcceptTeamInvitePage__signupText___WFV5Y"
};