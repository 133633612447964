import React from 'react'
import { Link } from 'react-router'

import styles from './Intro.module.css'

const laptop = `${STATIC_DOMAIN}/pages/new-homepage/laptop-no-logo.png`
const platformsHero = `${STATIC_DOMAIN}/pages/new-homepage/platforms-hero.jpg`
const enterpriseHero = `${STATIC_DOMAIN}/pages/new-homepage/enterprise-hero.jpg`
const ContentSkillsHero = `${STATIC_DOMAIN}/pages/new-homepage/contentSkills-hero.jpg`
const completeCurationHero = `${STATIC_DOMAIN}/pages/new-homepage/completeCuration-hero.jpg`

const Intro = (props) => {
  let heroImage, buttonClass

  if (props.homepage === 'main') {
    heroImage = <img src={laptop} alt="laptop" className={styles.introPic} />
    buttonClass = `${styles.introTextButton} ${styles.mainButton}`
  } else if (props.homepage === 'platforms') {
    heroImage = (
      <img
        src={platformsHero}
        alt="platforms"
        className={styles.platformsHero}
      />
    )
    buttonClass = `${styles.introTextButton} ${styles.platformsButton}`
  } else if (props.homepage === 'enterprise') {
    heroImage = (
      <img
        src={enterpriseHero}
        alt="enterprise"
        className={styles.enterpriseHero}
      />
    )
    buttonClass = `${styles.introTextButton} ${styles.enterpriseButton}`
  } else if (props.homepage === 'content-skills') {
    heroImage = (
      <img
        src={ContentSkillsHero}
        alt="content-skills-mapper"
        className={styles.contentSkillsHero}
      />
    )
    buttonClass = `${styles.introTextButton} ${styles.contentSkillsButton}`
  } else if (props.homepage === 'complete-curation') {
    heroImage = (
      <img
        src={completeCurationHero}
        alt="complete-curation"
        className={styles.completeCurationHero}
      />
    )
    buttonClass = `${styles.introTextButton} ${styles.contentSkillsButton}`
  }

  let linkButton = props.link ? props.link : '/contact?reason=Book%20a%20demo'
  let buttonTitle = props.buttonTitle
    ? props.buttonTitle
    : 'Book a consultation'
  let willNavigateInside = props.willNavigateInside
    ? props.willNavigateInside
    : false
  return (
    <section className={styles.intro}>
      <div className={styles.introText}>
        <header
          className={styles.introTextHeader}
          style={{ color: props.headerColor }}
        >
          {props.headerText}
        </header>
        {props.subtitle && (
          <p
            className={styles.introTextBody}
            style={{ color: props.headerColor }}
          >
            {props.subtitle}
          </p>
        )}
        {props.bullet1 && (
          <div
            className={styles.introTextBody}
            style={{ color: props.bulletTextColor }}
          >
            <p>
              <span
                className={styles.bulletPoint}
                style={{ color: props.bulletPointColor }}
              >
                &gt;{' '}
              </span>
              {props.bullet1}
            </p>
            <p>
              <span
                className={styles.bulletPoint}
                style={{ color: props.bulletPointColor }}
              >
                &gt;{' '}
              </span>
              {props.bullet2}
            </p>
            <p>
              <span
                className={styles.bulletPoint}
                style={{ color: props.bulletPointColor }}
              >
                &gt;{' '}
              </span>
              {props.bullet3}
            </p>
            {props.bullet4 && (
              <p>
                <span
                  className={styles.bulletPoint}
                  style={{ color: props.bulletPointColor }}
                >
                  &gt;{' '}
                </span>
                {props.bullet4}
              </p>
            )}
            {props.sub1 && (
              <p>
                <span
                  className={styles.bulletPoint}
                  style={{ color: props.bulletPointColor }}
                >
                  &ndash;{' '}
                </span>
                {props.sub1}
              </p>
            )}
            {props.sub2 && (
              <p>
                <span
                  className={styles.bulletPoint}
                  style={{ color: props.bulletPointColor }}
                >
                  &ndash;{' '}
                </span>
                {props.sub2}
              </p>
            )}
            {props.sub3 && (
              <p>
                <span
                  className={styles.bulletPoint}
                  style={{ color: props.bulletPointColor }}
                >
                  &ndash;{' '}
                </span>
                {props.sub3}
              </p>
            )}
            {props.sub4 && (
              <p>
                <span
                  className={styles.bulletPoint}
                  style={{ color: props.bulletPointColor }}
                >
                  &ndash;{' '}
                </span>
                {props.sub4}
              </p>
            )}
          </div>
        )}
        {willNavigateInside ? (
          <a href={linkButton}>
            <button className={buttonClass}>{buttonTitle}</button>
          </a>
        ) : (
          <>
            <Link to={linkButton}>
              <button className={buttonClass}>{buttonTitle}</button>
            </Link>
            {props.withProductSheet && (
              <button className={buttonClass} style={{ marginLeft: 10 }}>
                <a
                  href="#downloadProductSheet"
                  // href="/documents/AndersPink_Complete_Curation_Product_Sheet.pdf"
                  style={{ color: '#006067' }}
                >
                  Download product sheet
                </a>
              </button>
            )}
          </>
        )}
      </div>

      {heroImage}
    </section>
  )
}

export default Intro
