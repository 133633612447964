import React from 'react'
import { connect } from 'react-redux'
import { isLoggedIn } from 'app/selectors/appSelectors'
import HomePage from 'home/containers/HomePage'
import PersonalHomePage from 'home/containers/PersonalHomePage'
import LandingPage from 'home/containers/LandingPage'

import Landing from 'content/pages/landing/Landing'

@connect((state) => ({
  isLoggedIn: isLoggedIn(state),
  teamId: state.app.teamId,
}))
export default class IndexPage extends React.Component {
  render() {
    if (this.props.isLoggedIn) {
      if (this.props.teamId === null) {
        return <PersonalHomePage {...this.props} />
      } else {
        return <HomePage {...this.props} />
      }
    } else {
      return <LandingPage {...this.props} />
      //return <Landing {...this.props} />
    }
  }
}
