import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { Link } from 'react-router'
import gql from 'graphql-tag'
import FriendsPanel from 'home/containers/FriendsPanel'
import styles from './PersonalHomePage.css'
import LinkTwitterPanel from 'authentication/containers/LinkTwitterPanel'
import BriefingsPanel from 'home/containers/BriefingsPanel'

@graphql(gql`
  query {
    session {
      user {
        id
        isTwitterConnected
      }
    }
  }
`)
export default class PersonalHomePage extends React.Component {
  render() {
    if (
      this.props.data.loading ||
      !this.props.data.session ||
      !this.props.data.session.user
    ) {
      return null
    }

    return (
      <div className={styles.root}>
        <div className={styles.leftPanel}>
          <div className={styles.header}>
            <div className={styles.title}>Top from your briefings</div>
          </div>
          <BriefingsPanel />
        </div>

        {this.props.data.session.user.isTwitterConnected && (
          <div className={styles.rightPanel}>
            <div className={styles.header}>
              <div className={styles.title}>Top from your network</div>
              <Link to="/friends" className={styles.link}>
                See more
              </Link>
            </div>
            <FriendsPanel />
          </div>
        )}
        {!this.props.data.session.user.isTwitterConnected && (
          <div className={styles.rightPanel}>
            <div className={styles.header}>
              <div className={styles.title}>Link your Twitter account</div>
            </div>
            <div>
              <LinkTwitterPanel />
            </div>
          </div>
        )}
      </div>
    )
  }
}
