import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import axios from 'axios'
import AnimateHeight from 'react-animate-height'

import FixedModal from 'app/components/FixedModal'
import NewTeamForm from 'accounts/containers/NewTeamForm'
import EditTeamForm from 'accounts/containers/EditTeamForm'
import DegreedIntegration from 'accounts/containers/DegreedIntegration'
import { D2lPanel } from './D2lPanel'
import SourceFiltersPanel from 'sources-filter/containers/SourceFiltersPanel'

@graphql(
  gql`
    mutation($name: String!, $accountId: ID!) {
      createAccountTeam(name: $name, accountId: $accountId) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createAccountTeam: (name, accountId) =>
        mutate({
          variables: { name, accountId },
        }),
    }),
    options: {
      refetchQueries: ['appQuery', 'accountQuery'],
    },
  },
)
@graphql(
  gql`
    mutation($team: TeamInput!) {
      updateAccountTeam(team: $team) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateAccountTeam: (team) =>
        mutate({
          variables: {
            team,
          },
        }),
    }),
    options: {
      refetchQueries: ['appQuery', 'accountQuery'],
    },
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      deleteTeam(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteTeam: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['appQuery', 'accountQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      copyTeam(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      copyTeam: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['appQuery', 'accountQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID!) {
      upsertD2lIntegration(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      upsertD2lIntegration: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['accountQuery'],
        }),
    }),
  },
)
@connect(({ app }) => ({
  session: app.appQueryResult,
  features: app.features,
  jwt: app.jwt,
}))
export default class AccountPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      modalType: null,
      modalTeam: null,
      showingInvoices: false,
    }
  }
  handleGenerateKeys = ({ onDone } = {}) => {
    const { account } = this.props
    this.setState({ d2lKeysUpserting: true })
    this.props
      .upsertD2lIntegration(account.id)
      .then(() => {
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `New keys have been generated`,
        })
      })
      .catch((err) => {
        let error = err.message
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          error = err.graphQLErrors[0].message
        } else if (err.networkError) {
          error = err.networkError.message
        }
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          level: 'danger',
          content: `Sorry, an error occured: ` + error,
        })
      })
      .finally(() => {
        this.setState({ d2lKeysUpserting: false })
        onDone && onDone()
      })
  }
  render() {
    const { account } = this.props
    const subscriptions = account.activeSubscriptions
    const { d2lIntegration = {} } = account
    let fields = [
      'totalNumBriefings',
      'totalNumBoards',
      'totalNumUsers',
      'totalNumApiBriefings',
      'totalNumApiBoards',
      'totalNumPublicBriefings',
      'totalNumPublicBoards',
    ]
    let totals = {}
    for (let subscription of subscriptions) {
      for (let plan of subscription.plans) {
        if (plan.planSet) {
          const { planSet } = plan
          for (let field of fields) {
            if (planSet[field]) {
              totals[field] = (totals[field] || 0) + planSet[field]
            }
          }
        }
      }
    }

    return (
      <div>
        <h3>
          {account.type === 'business' ? 'Business' : 'Personal'} account:{' '}
          {account.name}
        </h3>
        <br />
        <div className="card mb-2">
          <div className="card-body">
            <h4 className="card-title">
              Your subscription{subscriptions.length === 0 ? '' : 's'}
            </h4>

            {subscriptions.map((subscription) => (
              <div key={subscription.id}>
                {subscription.plans.length === 1 && (
                  <p>
                    You are currently on{' '}
                    {subscription.isTrial ? 'a free trial of' : ''} the{' '}
                    {subscription.plans[0].planSet.name} plan.
                  </p>
                )}
                {subscription.plans.length > 1 && (
                  <div>
                    You are currently on{' '}
                    {subscription.isTrial ? 'a free trial of' : ''} the
                    following plans:
                    <ul>
                      {subscription.plans.map((plan, index) => (
                        <li key={index}>{plan.planSet.name}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {subscription.invoices.length > 0 && (
                  <div>
                    <p
                      className="link"
                      onClick={() =>
                        this.setState({
                          showingInvoices: !this.state.showingInvoices,
                        })
                      }
                    >
                      View recent invoices
                    </p>

                    <AnimateHeight
                      duration={500}
                      height={this.state.showingInvoices ? 'auto' : 0}
                    >
                      <ul>
                        {subscription.invoices
                          .slice(-5)
                          .reverse()
                          .map((invoice) => (
                            <li key={invoice.id} className="mb-1">
                              <span>{invoice.date}</span>
                              <form
                                className="ml-2"
                                method="post"
                                action={`/invoice/${invoice.id}/${this.props.jwt}`}
                                style={{ display: 'inline' }}
                              >
                                <button
                                  className="btn btn-sm btn-secondary"
                                  type="submit"
                                >
                                  Download PDF
                                </button>
                              </form>
                            </li>
                          ))}
                      </ul>
                    </AnimateHeight>
                  </div>
                )}
              </div>
            ))}
            {subscriptions.length === 0 && (
              <p>You don't currently have a subscription</p>
            )}

            {(account.offeredPlanset ? true : false) && (
              <div className="mt-2">
                {account.doesPlansetOverwrite ? 'A new' : 'An additional'}{' '}
                custom subscription ({account.offeredPlanset.name}) is availabe
                for you to purchase.
                <div className="mt-1">
                  <Link
                    to={`/account/${account.id}/setup-custom-subscription`}
                    className="btn btn-sm btn-primary"
                  >
                    Start subscription
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {account.type === 'business' && (
          <div>
            <div className="card mb-2">
              <div className="card-body">
                <h4 className="card-title">Teams</h4>

                <h6 className="card-subtitle mb-2 text-muted">
                  Teams on this account
                </h6>
                {account.teams.length === 0 && (
                  <p>This account currently has no teams.</p>
                )}
                {account.teams.length > 0 && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>

                        <th>
                          Users
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumUsers}
                            </div>
                          )}
                        </th>
                        <th>
                          Briefings
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumBriefings}
                            </div>
                          )}
                        </th>
                        <th>
                          Folders
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumBoards}
                            </div>
                          )}
                        </th>

                        <th>
                          Briefings - API
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumApiBriefings}
                            </div>
                          )}
                        </th>
                        <th>
                          Folders - API
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumApiBoards}
                            </div>
                          )}
                        </th>

                        <th>
                          Briefings - Public
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumPublicBriefings}
                            </div>
                          )}
                        </th>
                        <th>
                          Folders - Public
                          {Object.keys(totals).length > 0 && (
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              Account limit of {totals.totalNumPublicBoards}
                            </div>
                          )}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.teams.map((team) => (
                        <tr key={team.id}>
                          <td>{team.name}</td>
                          <td>
                            {team.teamUsers.length}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumUsers
                                ? `Team is limited to ${team.allowedNumUsers}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td>
                            {team.ownedBriefings.length}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumBriefings
                                ? `Team is limited to ${team.allowedNumBriefings}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td>
                            {team.boards.length}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumBoards
                                ? `Team is limited to ${team.allowedNumBoards}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td>
                            {team.apiUser ? team.apiUser.briefings.length : 0}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumApiBriefings
                                ? `Team is limited to ${team.allowedNumApiBriefings}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td>
                            {team.apiUser ? team.apiUser.boards.length : 0}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumApiBoards
                                ? `Team is limited to ${team.allowedNumApiBoards}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td>
                            {team.apiUser
                              ? team.ownedBriefings.filter((b) => b.isPublic)
                                  .length
                              : 0}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumPublicBriefings
                                ? `Team is limited to ${team.allowedNumPublicBriefings}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td>
                            {team.apiUser
                              ? team.boards.filter((b) => b.isPublic).length
                              : 0}
                            <div style={{ fontSize: '0.8em', color: '#999' }}>
                              {team.allowedNumPublicBoards
                                ? `Team is limited to ${team.allowedNumPublicBoards}`
                                : 'No team limit set'}
                            </div>
                          </td>
                          <td style={{ lineHeight: '2.2rem' }}>
                            <Link
                              to={`/account/${account.id}/team/${team.id}`}
                              className="btn btn-sm btn-secondary"
                            >
                              Update team
                            </Link>{' '}
                            <button
                              onClick={() => {
                                this.setState({
                                  isModalOpen: true,
                                  modalType: 'editTeam',
                                  modalTeam: team,
                                })
                              }}
                              className="btn btn-sm btn-secondary"
                            >
                              Set team limits
                            </button>{' '}
                            <button
                              onClick={() => {
                                axios
                                  .get(
                                    `/iapi/auth/changeTeam?id=${
                                      team.id
                                    }&time=${Date.now()}`,
                                  )
                                  .then((response) => {
                                    if (response.data.status === 'success') {
                                      window.location.href = '/home'
                                    }
                                  })
                              }}
                              className="btn btn-sm btn-secondary"
                            >
                              Change to this team
                            </button>{' '}
                            {account.canCopyTeam && (
                              <span>
                                <button
                                  onClick={() => {
                                    this.setState({
                                      isModalOpen: true,
                                      modalType: 'copyTeam',
                                      modalTeam: team,
                                    })
                                  }}
                                  className="btn btn-sm btn-secondary"
                                >
                                  Copy team
                                </button>{' '}
                              </span>
                            )}
                            <button
                              onClick={() => {
                                this.setState({
                                  isModalOpen: true,
                                  modalType: 'deleteTeam',
                                  modalTeam: team,
                                })
                              }}
                              className="btn btn-sm btn-danger"
                            >
                              Delete team
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                <div>
                  <button
                    onClick={() => {
                      this.setState({
                        isModalOpen: true,
                        modalType: 'createTeam',
                      })
                    }}
                    className={`btn btn-sm btn-primary`}
                  >
                    Create new team
                  </button>
                </div>
              </div>
            </div>

            <div className="card mb-2">
              <div className="card-body">
                <h4 className="card-title">Account owners</h4>

                <h6 className="card-subtitle mb-2 text-muted">
                  Account owners are users which can manage all things on the
                  account.
                </h6>
                {account.users.length > 0 && (
                  <ul>
                    {account.users.map((user) => (
                      <li key={user.id}>
                        {user.firstName} {user.lastName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
        {this.props.features.domainLists ? (
          <div>
            <div className="card mb-2">
              <div className="card-body">
                <SourceFiltersPanel
                  isAccountView={true}
                  accountId={account.id}
                />
              </div>
            </div>
          </div>
        ) : null}
        {account.isDegreedAvailable && (
          <div className="card mb-2">
            <div className="card-body">
              <h4 className="card-title">Integration: Degreed</h4>
              <DegreedIntegration accountId={account.id} />
            </div>
          </div>
        )}

        {account.isD2lAvailable ? (
          <D2lPanel
            keys={d2lIntegration}
            isUpserting={this.state.d2lKeysUpserting}
            generateKeys={this.handleGenerateKeys}
          ></D2lPanel>
        ) : null}

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          <div style={{ padding: '20px' }}>
            {this.state.modalType === 'createTeam' && (
              <NewTeamForm
                onSubmit={(values) => {
                  return this.props
                    .createAccountTeam(values.name, account.id)
                    .then(() => {
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `New team succesfully created`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}
            {this.state.modalType === 'editTeam' && (
              <EditTeamForm
                initialValues={this.state.modalTeam}
                onSubmit={(values) => {
                  return this.props
                    .updateAccountTeam({
                      id: values.id,
                      allowedNumUsers:
                        values.allowedNumUsers === ''
                          ? null
                          : parseInt(values.allowedNumUsers),
                      allowedNumBriefings:
                        values.allowedNumBriefings === ''
                          ? null
                          : parseInt(values.allowedNumBriefings),
                      allowedNumBoards:
                        values.allowedNumBoards === ''
                          ? null
                          : parseInt(values.allowedNumBoards),
                      allowedNumApiBriefings:
                        values.allowedNumApiBriefings === ''
                          ? null
                          : parseInt(values.allowedNumApiBriefings),
                      allowedNumApiBoards:
                        values.allowedNumApiBoards === ''
                          ? null
                          : parseInt(values.allowedNumApiBoards),
                      allowedNumPublicBriefings:
                        values.allowedNumPublicBriefings === ''
                          ? null
                          : parseInt(values.allowedNumPublicBriefings),
                      allowedNumPublicBoards:
                        values.allowedNumPublicBoards === ''
                          ? null
                          : parseInt(values.allowedNumPublicBoards),
                    })
                    .then(() => {
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Team succesfully updated`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}
            {this.state.modalType === 'deleteTeam' && (
              <div>
                Are you sure you want to delete this team and all of its
                briefings and saved folders?
                <p style={{ marginTop: '1.5rem' }}>
                  <button
                    onClick={() => {
                      this.props
                        .deleteTeam(this.state.modalTeam.id)
                        .then(() => {
                          this.setState({
                            isModalOpen: false,
                          })
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `Team deleted`,
                          })
                        })
                        .catch((err) => {
                          let error = err.message
                          if (
                            err.graphQLErrors &&
                            err.graphQLErrors.length > 0
                          ) {
                            error = err.graphQLErrors[0].message
                          } else if (err.networkError) {
                            error = err.networkError.message
                          }
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            level: 'danger',
                            content: `Sorry, an error occured: ` + error,
                          })
                          this.setState({
                            isModalOpen: false,
                          })
                        })
                    }}
                    className="btn btn-sm btn-danger"
                  >
                    Yes, delete this team
                  </button>
                </p>
              </div>
            )}
            {this.state.modalType === 'copyTeam' && (
              <div>
                Are you sure you want to copy this team and its briefings?
                Another team will be created on this account.
                <p style={{ marginTop: '1.5rem' }}>
                  <button
                    onClick={() => {
                      this.props
                        .copyTeam(this.state.modalTeam.id)
                        .then(() => {
                          this.setState({
                            isModalOpen: false,
                          })
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `Team copied`,
                          })
                        })
                        .catch((err) => {
                          let error = err.message
                          if (
                            err.graphQLErrors &&
                            err.graphQLErrors.length > 0
                          ) {
                            error = err.graphQLErrors[0].message
                          } else if (err.networkError) {
                            error = err.networkError.message
                          }
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            level: 'danger',
                            content: `Sorry, an error occured: ` + error,
                          })
                          this.setState({
                            isModalOpen: false,
                          })
                        })
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Yes, copy this team
                  </button>
                </p>
              </div>
            )}
          </div>
        </FixedModal>
      </div>
    )
  }
}
