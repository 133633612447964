import React, { Component } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Button } from 'react-bootstrap'
import apolloClient from 'apolloClient'
import uniq from 'lodash/uniq'

export default class TopicInput extends Component {
  constructor() {
    super()
    this.state = {
      lastValue: '',
    }
  }
  async loadAutoCompletedTopics(inputValue) {
    try {
      inputValue = inputValue.toLowerCase()

      const result = await apolloClient().query({
        query: gql`
          query ($topic: String) {
            autoCompletedTopics(topic: $topic)
          }
        `,
        variables: {
          topic: inputValue,
        },
      })
      return (
        result.data.autoCompletedTopics.map((t) => ({ value: t, label: t })) ||
        []
      )
    } catch (err) {
      console.log(err)
      return []
    }
  }
  render() {
    const { topics, setTopics } = this.props
    return (
      <div>
        <div className="mt-2 mb-2">
          <AsyncCreatableSelect
            isMulti
            formatCreateLabel={(inputValue) => `${inputValue}`}
            createOptionPosition="first"
            value={topics.map((t) => ({
              value: t,
              label: t,
            }))}
            onChange={(selectedOptions) => {
              const topics = selectedOptions.map((o) => o.value)
              setTopics(topics)
            }}
            onInputChange={(value) => {
              if (this.state.lastValue !== value) {
                this.setState({ lastValue: value })
              }
            }}
            onBlur={(event) => {
              const values = this.state.lastValue
                .split(',')
                .map((v) => v.trim().toLowerCase())
                .filter((v) => !!v)

              this.setState({ lastValue: '' })
              setTopics(uniq([...topics, ...values]))
            }}
            onCreateOption={(inputValue, a) => {
              const values = inputValue
                .split(',')
                .map((v) => v.trim().toLowerCase())
                .filter((v) => !!v)
              setTopics(uniq([...topics, ...values]))
            }}
            loadOptions={this.loadAutoCompletedTopics}
            defaultOptions
            placeholder="Enter a search term or select..."
            styles={{
              multiValueLabel: (styles) => ({
                ...styles,
                fontSize: '15px',
                // backgroundColor: '#cf0032',
                color: '#ffffff',
                borderRadius: 0,
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                // backgroundColor: '#cf0032',
                color: '#ffffff',
                borderRadius: 0,
              }),
              multiValue: (styles) => ({
                ...styles,
                backgroundColor: '#cf0032',
                borderRadius: '0.2rem',
              }),
              valueContainer: (styles) => ({
                ...styles,
                padding: '6px 8px',
              }),
            }}
          />
        </div>

        <SuggestedTopics topics={topics} setTopics={setTopics} />
      </div>
    )
  }
}

@graphql(
  gql`
    query ($topics: [String]!) {
      relatedTopics(topics: $topics)
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          topics: props.topics,
        },
      }
    },
  },
)
class SuggestedTopics extends Component {
  render() {
    const { topics, setTopics } = this.props
    if (
      !this.props.data.relatedTopics ||
      this.props.data.relatedTopics.length === 0
    ) {
      return null
    }
    return (
      <div className="mb-2 mt-2">
        <span>Add other related topics: </span>
        {this.props.data.relatedTopics.map((suggestedTopic) => (
          <Button
            key={suggestedTopic}
            variant="secondary"
            size="sm"
            className="mr-1 mb-1"
            onClick={() => {
              const newTopics = [...topics, suggestedTopic]
              setTopics(newTopics)
            }}
          >
            + {suggestedTopic}
          </Button>
        ))}
      </div>
    )
  }
}
