import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Loader from 'app/components/Loader'
import PaymentForm from './PaymentForm'

const selector = formValueSelector('setupSubscription')

@compose(
  withProps((props) => ({
    initialValues: {
      freeTrial: 'yes',
      interval: 'year',
    },
  })),
  reduxForm({
    form: 'setupSubscription',
  }),
  connect((state, props) => ({
    freeTrial: selector(state, 'freeTrial'),
    interval: selector(state, 'interval'),
    appQueryResult: state.app.appQueryResult,
  })),
)
export default class SetupSubscriptionPage extends React.Component {
  constructor() {
    super()
    this.state = {
      successState: null,
    }
  }
  render() {
    let name,
      monthly,
      yearly = null

    if (this.props.params.type === 'pro') {
      name = 'Pro'
      monthly = '$9'
      yearly = '$99'
    } else if (this.props.params.type === 'starter-team') {
      name = 'Starter Team'
      monthly = '$39'
      yearly = '$399'
    } else if (this.props.params.type === 'team') {
      name = 'Team'
      monthly = '$99'
      yearly = '$999'
    }

    let alreadyHadFreeTrial = false

    if (this.props.freeTrial === 'yes') {
    }

    if (this.state.successState === 'trial') {
      return (
        <div>
          <h5>New subscription</h5>
          <br />
          <p>
            Thank you for starting a 14 day free trial on the{' '}
            <strong>{name}</strong> plan.
          </p>

          {this.props.params.type === 'pro' && (
            <p>You can now create more briefings and saved article folders.</p>
          )}
          {this.props.params.type === 'team' && (
            <p>
              You can now <Link to="/create-team">create your team</Link> and
              start inviting other users.
            </p>
          )}
          <p>
            If you would like to continue on this plan after the 14 days, you
            can enter your credit card details and start a subscription.
          </p>

          <p>Thanks, we hope you get value out of Anders Pink!</p>
        </div>
      )
    } else if (this.state.successState === 'paid') {
      return (
        <div>
          <h5>New subscription</h5>
          <br />
          <p>
            Thank you for purchasing the <strong>{name}</strong> plan.
          </p>

          {this.props.params.type === 'pro' && (
            <p>You can now create more briefings and saved article folders.</p>
          )}
          {this.props.params.type === 'team' && (
            <p>
              You can now <Link to="/create-team">create your team</Link> and
              start inviting other users.
            </p>
          )}
          <p>
            You can cancel at any point by{' '}
            <Link to="/contact" className="link">
              contacting us.
            </Link>
          </p>
          <p>Thanks, we hope you get value out of Anders Pink!</p>
        </div>
      )
    }

    return (
      <div>
        <h5>New subscription</h5>

        <p>
          Finish setting up your new <strong>{name}</strong> subscription. Or{' '}
          <Link className="link" to="/subscription">
            go back
          </Link>{' '}
          to pick a different plan.
        </p>

        <div style={{ margin: '30px 0' }}>
          <p>Do you want a free trial or would you like to pay immediately?</p>
          <div
            className="row"
            style={{ margin: '10px -15px', maxWidth: '600px' }}
          >
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-body">
                  <label className="form-check-label">
                    <Field
                      name="freeTrial"
                      component="input"
                      type="radio"
                      value="yes"
                    />{' '}
                    Start 14 day free trial
                  </label>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-body">
                  <label className="form-check-label">
                    <Field
                      name="freeTrial"
                      component="input"
                      type="radio"
                      value="no"
                    />{' '}
                    Pay now
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.props.freeTrial === 'no' && (
          <div style={{ margin: '30px 0' }}>
            <p>Do you want to pay anually or monthly?</p>
            <div
              className="row"
              style={{ margin: '10px -15px', maxWidth: '600px' }}
            >
              <div className="col-lg-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <label className="form-check-label">
                      <Field
                        name="interval"
                        component="input"
                        type="radio"
                        value="year"
                      />{' '}
                      Pay anually ({yearly} billed anually)
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <label className="form-check-label">
                      <Field
                        name="interval"
                        component="input"
                        type="radio"
                        value="month"
                      />{' '}
                      Pay monthly ({monthly} per month)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <PaymentForm
          type={this.props.params.type}
          freeTrial={this.props.freeTrial}
          interval={this.props.interval}
          onSuccess={(state) => this.setState({ successState: state })}
        />
      </div>
    )
  }
}
