import React, { useState, useEffect } from 'react'
import { Link } from 'react-router'
import styles from './Navbar.module.css'

const DropDownItemsMobile = ({ title, items }) => {
  const [dropdownOpen, setDropDownOpen] = useState(false)

  return (
    <>
      <li
        className={`${styles.navItem} dropdown-toggle`}
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ color: 'white' }}
        onClick={() => setDropDownOpen(!dropdownOpen)}
      >
        <a className={styles.navLink}>{title}</a>
      </li>
      {dropdownOpen && (
        <div>
          {items.map((item, key) => (
            <li key={key} className={`dropdown-item`}>
              <Link to={item.link} className={`${styles.navLink}`}>
                {item.name}
              </Link>
            </li>
          ))}
        </div>
      )}
    </>
  )
}

export default DropDownItemsMobile
