import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import EditBriefingForm from 'briefings/create/containers/EditBriefingForm'
import briefingInfoFragment from 'briefings/fragments/briefingInfo'
import { browserHistory } from 'react-router'

@graphql(
  gql`
    mutation ($briefing: BriefingInput!) {
      createBriefing(briefing: $briefing) {
        ...briefingInfo
      }
    }
    ${briefingInfoFragment}
  `,
  {
    name: 'createBriefing',
    options: {
      refetchQueries: ['appQuery'],
    },
  },
)
@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
export default class CreateBriefingPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    // Are we on a team or personal?
    const session = this.props.appQueryResult

    const useNewBriefingTypes = true

    if (session.numAvailableBriefings <= 0) {
      if (session.team) {
        return <div>Sorry, your team can't make any more briefings.</div>
      } else {
        return <div>Sorry, you can't make any more personal briefings.</div>
      }
    }

    let initialValues = {}
    if (this.props.location.query) {
      if (this.props.location.query.type) {
        initialValues.type = this.props.location.query.type
      }

      initialValues.keywordFields = {}

      if (this.props.location.query.keywords) {
        if (typeof this.props.location.query.keywords === 'string') {
          initialValues.keywordFields.keywords = [
            this.props.location.query.keywords,
          ]
        } else {
          initialValues.keywordFields.keywords =
            this.props.location.query.keywords
              .map((keyword) => keyword.trim())
              .filter(Boolean)
        }
      }
      if (this.props.location.query.domains) {
        if (typeof this.props.location.query.domains === 'string') {
          initialValues.keywordFields.domains = [
            this.props.location.query.domains,
          ]
        } else {
          initialValues.keywordFields.domains =
            this.props.location.query.domains
              .map((domain) => domain.trim())
              .filter(Boolean)
        }
      }
    }

    return (
      <div>
        <EditBriefingForm
          crawlerSearchEnabled={useNewBriefingTypes}
          canUseLooseSearch={
            session && session.team && session.team.canUseLooseSearch
          }
          onSubmit={(values) => {
            return this.props
              .createBriefing({
                variables: {
                  briefing: {
                    name: values.name,
                    description: values.description,
                    type: values.type,
                    isPublic: values.isPublic,
                    isFeatured: values.isFeatured,
                    categoryId: values.categoryId,
                    sourceFields: values.sourceFields,
                    keywordFields: values.keywordFields,
                    looseFields: values.looseFields,
                    keywordsV2Fields: values.keywordsV2Fields,
                    sourcesV2Fields: values.sourcesV2Fields,
                    language: values.language,
                  },
                },
              })
              .then((response) => {
                if (
                  response.data.createBriefing &&
                  response.data.createBriefing.id
                ) {
                  //browserHistory.push(`/briefing-refresh/${response.data.createBriefing.id}`);
                  browserHistory.push(response.data.createBriefing.url)
                } else {
                  throw new SubmissionError({
                    _error: 'Sorry, something went wrong',
                  })
                }
              })
              .catch((err) => {
                throw new SubmissionError({
                  _error: 'Sorry, something went wrong',
                })
              })
          }}
          initialValues={initialValues}
        />
      </div>
    )
  }
}
