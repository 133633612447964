import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import franc from 'franc'
import withAuthCheck from 'app/containers/withAuthCheck'
import { isLoggedIn } from 'app/selectors/appSelectors'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './ChromeExtension.css'
import FixedModal from 'app/components/FixedModal'
import { DropdownMenu } from 'app/components/DropdownMenu'
import axios from 'axios'
import NotificationIcon from 'app/containers/NotificationIcon'
import AppQuery from 'app/queries/AppQuery'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { browserHistory } from 'react-router'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import ArticleCommentInput from 'articles/containers/ArticleCommentInput'
import Loader from 'app/components/Loader'

const getAlpha2FromAlpha3 = (alpha3) => {
  alpha3 = alpha3 === 'sco' ? 'eng' : alpha3
  alpha3 = alpha3 === 'nds' ? 'deu' : alpha3

  return mapLanguage3to2[alpha3] || 'en'
}
const getLanguageCode = (text) => getAlpha2FromAlpha3(franc(text))

// Global app styles from bootstrap
//import "bootstrap/scss/bootstrap.scss";

// @todo get this working - currently using CDN
//import "font-awesome/scss/font-awesome.scss";

const languages = [
  {
    label: 'Afar',
    alpha2: 'aa',
  },
  {
    label: 'Abkhazian',
    alpha2: 'ab',
  },
  {
    label: 'Avestan',
    alpha2: 'ae',
  },
  {
    label: 'Afrikaans',
    alpha2: 'af',
  },
  {
    label: 'Akan',
    alpha2: 'ak',
  },
  {
    label: 'Amharic',
    alpha2: 'am',
  },
  {
    label: 'Aragonese',
    alpha2: 'an',
  },
  {
    label: 'Arabic',
    alpha2: 'ar',
  },
  {
    label: 'Assamese',
    alpha2: 'as',
  },
  {
    label: 'Avaric',
    alpha2: 'av',
  },
  {
    label: 'Aymara',
    alpha2: 'ay',
  },
  {
    label: 'Azerbaijani',
    alpha2: 'az',
  },
  {
    label: 'Bashkir',
    alpha2: 'ba',
  },
  {
    label: 'Belarusian',
    alpha2: 'be',
  },
  {
    label: 'Bulgarian',
    alpha2: 'bg',
  },
  {
    label: 'Bihari languages',
    alpha2: 'bh',
  },
  {
    label: 'Bislama',
    alpha2: 'bi',
  },
  {
    label: 'Bambara',
    alpha2: 'bm',
  },
  {
    label: 'Bengali',
    alpha2: 'bn',
  },
  {
    label: 'Tibetan',
    alpha2: 'bo',
  },
  {
    label: 'Breton',
    alpha2: 'br',
  },
  {
    label: 'Bosnian',
    alpha2: 'bs',
  },
  {
    label: 'Catalan; Valencian',
    alpha2: 'ca',
  },
  {
    label: 'Chechen',
    alpha2: 'ce',
  },
  {
    label: 'Chamorro',
    alpha2: 'ch',
  },
  {
    label: 'Corsican',
    alpha2: 'co',
  },
  {
    label: 'Cree',
    alpha2: 'cr',
  },
  {
    label: 'Czech',
    alpha2: 'cs',
  },
  {
    English:
      'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    alpha2: 'cu',
  },
  {
    label: 'Chuvash',
    alpha2: 'cv',
  },
  {
    label: 'Welsh',
    alpha2: 'cy',
  },
  {
    label: 'Danish',
    alpha2: 'da',
  },
  {
    label: 'German',
    alpha2: 'de',
  },
  {
    label: 'Divehi; Dhivehi; Maldivian',
    alpha2: 'dv',
  },
  {
    label: 'Dzongkha',
    alpha2: 'dz',
  },
  {
    label: 'Ewe',
    alpha2: 'ee',
  },
  {
    label: 'Greek, Modern (1453-)',
    alpha2: 'el',
  },
  {
    label: 'English',
    alpha2: 'en',
  },
  {
    label: 'Esperanto',
    alpha2: 'eo',
  },
  {
    label: 'Spanish; Castilian',
    alpha2: 'es',
  },
  {
    label: 'Estonian',
    alpha2: 'et',
  },
  {
    label: 'Basque',
    alpha2: 'eu',
  },
  {
    label: 'Persian',
    alpha2: 'fa',
  },
  {
    label: 'Fulah',
    alpha2: 'ff',
  },
  {
    label: 'Finnish',
    alpha2: 'fi',
  },
  {
    label: 'Fijian',
    alpha2: 'fj',
  },
  {
    label: 'Faroese',
    alpha2: 'fo',
  },
  {
    label: 'French',
    alpha2: 'fr',
  },
  {
    label: 'Western Frisian',
    alpha2: 'fy',
  },
  {
    label: 'Irish',
    alpha2: 'ga',
  },
  {
    label: 'Gaelic; Scottish Gaelic',
    alpha2: 'gd',
  },
  {
    label: 'Galician',
    alpha2: 'gl',
  },
  {
    label: 'Guarani',
    alpha2: 'gn',
  },
  {
    label: 'Gujarati',
    alpha2: 'gu',
  },
  {
    label: 'Manx',
    alpha2: 'gv',
  },
  {
    label: 'Hausa',
    alpha2: 'ha',
  },
  {
    label: 'Hebrew',
    alpha2: 'he',
  },
  {
    label: 'Hindi',
    alpha2: 'hi',
  },
  {
    label: 'Hiri Motu',
    alpha2: 'ho',
  },
  {
    label: 'Croatian',
    alpha2: 'hr',
  },
  {
    label: 'Haitian; Haitian Creole',
    alpha2: 'ht',
  },
  {
    label: 'Hungarian',
    alpha2: 'hu',
  },
  {
    label: 'Armenian',
    alpha2: 'hy',
  },
  {
    label: 'Herero',
    alpha2: 'hz',
  },
  {
    label: 'Interlingua (International Auxiliary Language Association)',
    alpha2: 'ia',
  },
  {
    label: 'Indonesian',
    alpha2: 'id',
  },
  {
    label: 'Interlingue; Occidental',
    alpha2: 'ie',
  },
  {
    label: 'Igbo',
    alpha2: 'ig',
  },
  {
    label: 'Sichuan Yi; Nuosu',
    alpha2: 'ii',
  },
  {
    label: 'Inupiaq',
    alpha2: 'ik',
  },
  {
    label: 'Ido',
    alpha2: 'io',
  },
  {
    label: 'Icelandic',
    alpha2: 'is',
  },
  {
    label: 'Italian',
    alpha2: 'it',
  },
  {
    label: 'Inuktitut',
    alpha2: 'iu',
  },
  {
    label: 'Japanese',
    alpha2: 'ja',
  },
  {
    label: 'Javanese',
    alpha2: 'jv',
  },
  {
    label: 'Georgian',
    alpha2: 'ka',
  },
  {
    label: 'Kongo',
    alpha2: 'kg',
  },
  {
    label: 'Kikuyu; Gikuyu',
    alpha2: 'ki',
  },
  {
    label: 'Kuanyama; Kwanyama',
    alpha2: 'kj',
  },
  {
    label: 'Kazakh',
    alpha2: 'kk',
  },
  {
    label: 'Kalaallisut; Greenlandic',
    alpha2: 'kl',
  },
  {
    label: 'Central Khmer',
    alpha2: 'km',
  },
  {
    label: 'Kannada',
    alpha2: 'kn',
  },
  {
    label: 'Korean',
    alpha2: 'ko',
  },
  {
    label: 'Kanuri',
    alpha2: 'kr',
  },
  {
    label: 'Kashmiri',
    alpha2: 'ks',
  },
  {
    label: 'Kurdish',
    alpha2: 'ku',
  },
  {
    label: 'Komi',
    alpha2: 'kv',
  },
  {
    label: 'Cornish',
    alpha2: 'kw',
  },
  {
    label: 'Kirghiz; Kyrgyz',
    alpha2: 'ky',
  },
  {
    label: 'Latin',
    alpha2: 'la',
  },
  {
    label: 'Luxembourgish; Letzeburgesch',
    alpha2: 'lb',
  },
  {
    label: 'Ganda',
    alpha2: 'lg',
  },
  {
    label: 'Limburgan; Limburger; Limburgish',
    alpha2: 'li',
  },
  {
    label: 'Lingala',
    alpha2: 'ln',
  },
  {
    label: 'Lao',
    alpha2: 'lo',
  },
  {
    label: 'Lithuanian',
    alpha2: 'lt',
  },
  {
    label: 'Luba-Katanga',
    alpha2: 'lu',
  },
  {
    label: 'Latvian',
    alpha2: 'lv',
  },
  {
    label: 'Malagasy',
    alpha2: 'mg',
  },
  {
    label: 'Marshallese',
    alpha2: 'mh',
  },
  {
    label: 'Maori',
    alpha2: 'mi',
  },
  {
    label: 'Macedonian',
    alpha2: 'mk',
  },
  {
    label: 'Malayalam',
    alpha2: 'ml',
  },
  {
    label: 'Mongolian',
    alpha2: 'mn',
  },
  {
    label: 'Marathi',
    alpha2: 'mr',
  },
  {
    label: 'Malay',
    alpha2: 'ms',
  },
  {
    label: 'Maltese',
    alpha2: 'mt',
  },
  {
    label: 'Burmese',
    alpha2: 'my',
  },
  {
    label: 'Nauru',
    alpha2: 'na',
  },
  {
    label: 'Bokmål, Norwegian; Norwegian Bokmål',
    alpha2: 'nb',
  },
  {
    label: 'Ndebele, North; North Ndebele',
    alpha2: 'nd',
  },
  {
    label: 'Nepali',
    alpha2: 'ne',
  },
  {
    label: 'Ndonga',
    alpha2: 'ng',
  },
  {
    label: 'Dutch; Flemish',
    alpha2: 'nl',
  },
  {
    label: 'Norwegian Nynorsk; Nynorsk, Norwegian',
    alpha2: 'nn',
  },
  {
    label: 'Norwegian',
    alpha2: 'no',
  },
  {
    label: 'Ndebele, South; South Ndebele',
    alpha2: 'nr',
  },
  {
    label: 'Navajo; Navaho',
    alpha2: 'nv',
  },
  {
    label: 'Chichewa; Chewa; Nyanja',
    alpha2: 'ny',
  },
  {
    label: 'Occitan (post 1500)',
    alpha2: 'oc',
  },
  {
    label: 'Ojibwa',
    alpha2: 'oj',
  },
  {
    label: 'Oromo',
    alpha2: 'om',
  },
  {
    label: 'Oriya',
    alpha2: 'or',
  },
  {
    label: 'Ossetian; Ossetic',
    alpha2: 'os',
  },
  {
    label: 'Panjabi; Punjabi',
    alpha2: 'pa',
  },
  {
    label: 'Pali',
    alpha2: 'pi',
  },
  {
    label: 'Polish',
    alpha2: 'pl',
  },
  {
    label: 'Pushto; Pashto',
    alpha2: 'ps',
  },
  {
    label: 'Portuguese',
    alpha2: 'pt',
  },
  {
    label: 'Quechua',
    alpha2: 'qu',
  },
  {
    label: 'Romansh',
    alpha2: 'rm',
  },
  {
    label: 'Rundi',
    alpha2: 'rn',
  },
  {
    label: 'Romanian; Moldavian; Moldovan',
    alpha2: 'ro',
  },
  {
    label: 'Russian',
    alpha2: 'ru',
  },
  {
    label: 'Kinyarwanda',
    alpha2: 'rw',
  },
  {
    label: 'Sanskrit',
    alpha2: 'sa',
  },
  {
    label: 'Sardinian',
    alpha2: 'sc',
  },
  {
    label: 'Sindhi',
    alpha2: 'sd',
  },
  {
    label: 'Northern Sami',
    alpha2: 'se',
  },
  {
    label: 'Sango',
    alpha2: 'sg',
  },
  {
    label: 'Sinhala; Sinhalese',
    alpha2: 'si',
  },
  {
    label: 'Slovak',
    alpha2: 'sk',
  },
  {
    label: 'Slovenian',
    alpha2: 'sl',
  },
  {
    label: 'Samoan',
    alpha2: 'sm',
  },
  {
    label: 'Shona',
    alpha2: 'sn',
  },
  {
    label: 'Somali',
    alpha2: 'so',
  },
  {
    label: 'Albanian',
    alpha2: 'sq',
  },
  {
    label: 'Serbian',
    alpha2: 'sr',
  },
  {
    label: 'Swati',
    alpha2: 'ss',
  },
  {
    label: 'Sotho, Southern',
    alpha2: 'st',
  },
  {
    label: 'Sundanese',
    alpha2: 'su',
  },
  {
    label: 'Swedish',
    alpha2: 'sv',
  },
  {
    label: 'Swahili',
    alpha2: 'sw',
  },
  {
    label: 'Tamil',
    alpha2: 'ta',
  },
  {
    label: 'Telugu',
    alpha2: 'te',
  },
  {
    label: 'Tajik',
    alpha2: 'tg',
  },
  {
    label: 'Thai',
    alpha2: 'th',
  },
  {
    label: 'Tigrinya',
    alpha2: 'ti',
  },
  {
    label: 'Turkmen',
    alpha2: 'tk',
  },
  {
    label: 'Tagalog',
    alpha2: 'tl',
  },
  {
    label: 'Tswana',
    alpha2: 'tn',
  },
  {
    label: 'Tonga (Tonga Islands)',
    alpha2: 'to',
  },
  {
    label: 'Turkish',
    alpha2: 'tr',
  },
  {
    label: 'Tsonga',
    alpha2: 'ts',
  },
  {
    label: 'Tatar',
    alpha2: 'tt',
  },
  {
    label: 'Twi',
    alpha2: 'tw',
  },
  {
    label: 'Tahitian',
    alpha2: 'ty',
  },
  {
    label: 'Uighur; Uyghur',
    alpha2: 'ug',
  },
  {
    label: 'Ukrainian',
    alpha2: 'uk',
  },
  {
    label: 'Urdu',
    alpha2: 'ur',
  },
  {
    label: 'Uzbek',
    alpha2: 'uz',
  },
  {
    label: 'Venda',
    alpha2: 've',
  },
  {
    label: 'Vietnamese',
    alpha2: 'vi',
  },
  {
    label: 'Volapük',
    alpha2: 'vo',
  },
  {
    label: 'Walloon',
    alpha2: 'wa',
  },
  {
    label: 'Wolof',
    alpha2: 'wo',
  },
  {
    label: 'Xhosa',
    alpha2: 'xh',
  },
  {
    label: 'Yiddish',
    alpha2: 'yi',
  },
  {
    label: 'Yoruba',
    alpha2: 'yo',
  },
  {
    label: 'Zhuang; Chuang',
    alpha2: 'za',
  },
  {
    label: 'Chinese',
    alpha2: 'zh',
  },
  {
    label: 'Zulu',
    alpha2: 'zu',
  },
]

const mapLanguage3to2 = {
  aar: 'aa',
  abk: 'ab',
  afr: 'af',
  aka: 'ak',
  amh: 'am',
  ara: 'ar',
  arg: 'an',
  asm: 'as',
  ava: 'av',
  ave: 'ae',
  aym: 'ay',
  aze: 'az',
  bak: 'ba',
  bam: 'bm',
  bel: 'be',
  ben: 'bn',
  bis: 'bi',
  bod: 'bo',
  bos: 'bs',
  bre: 'br',
  bul: 'bg',
  cat: 'ca',
  ces: 'cs',
  cha: 'ch',
  che: 'ce',
  chu: 'cu',
  chv: 'cv',
  cor: 'kw',
  cos: 'co',
  cre: 'cr',
  cym: 'cy',
  dan: 'da',
  deu: 'de',
  div: 'dv',
  dzo: 'dz',
  ell: 'el',
  eng: 'en',
  epo: 'eo',
  est: 'et',
  eus: 'eu',
  ewe: 'ee',
  fao: 'fo',
  fas: 'fa',
  fij: 'fj',
  fin: 'fi',
  fra: 'fr',
  fry: 'fy',
  ful: 'ff',
  gla: 'gd',
  gle: 'ga',
  glg: 'gl',
  glv: 'gv',
  grn: 'gn',
  guj: 'gu',
  hat: 'ht',
  hau: 'ha',
  hbs: 'sh',
  heb: 'he',
  her: 'hz',
  hin: 'hi',
  hmo: 'ho',
  hrv: 'hr',
  hun: 'hu',
  hye: 'hy',
  ibo: 'ig',
  ido: 'io',
  iii: 'ii',
  iku: 'iu',
  ile: 'ie',
  ina: 'ia',
  ind: 'id',
  ipk: 'ik',
  isl: 'is',
  ita: 'it',
  jav: 'jv',
  jpn: 'ja',
  kal: 'kl',
  kan: 'kn',
  kas: 'ks',
  kat: 'ka',
  kau: 'kr',
  kaz: 'kk',
  khm: 'km',
  kik: 'ki',
  kin: 'rw',
  kir: 'ky',
  kom: 'kv',
  kon: 'kg',
  kor: 'ko',
  kua: 'kj',
  kur: 'ku',
  lao: 'lo',
  lat: 'la',
  lav: 'lv',
  lim: 'li',
  lin: 'ln',
  lit: 'lt',
  ltz: 'lb',
  lub: 'lu',
  lug: 'lg',
  mah: 'mh',
  mal: 'ml',
  mar: 'mr',
  mkd: 'mk',
  mlg: 'mg',
  mlt: 'mt',
  mon: 'mn',
  mri: 'mi',
  msa: 'ms',
  mya: 'my',
  nau: 'na',
  nav: 'nv',
  nbl: 'nr',
  nde: 'nd',
  ndo: 'ng',
  nep: 'ne',
  nld: 'nl',
  nno: 'nn',
  nob: 'nb',
  nor: 'no',
  nya: 'ny',
  oci: 'oc',
  oji: 'oj',
  ori: 'or',
  orm: 'om',
  oss: 'os',
  pan: 'pa',
  pli: 'pi',
  pol: 'pl',
  por: 'pt',
  pus: 'ps',
  que: 'qu',
  roh: 'rm',
  ron: 'ro',
  run: 'rn',
  rus: 'ru',
  sag: 'sg',
  san: 'sa',
  sin: 'si',
  slk: 'sk',
  slv: 'sl',
  sme: 'se',
  smo: 'sm',
  sna: 'sn',
  snd: 'sd',
  som: 'so',
  sot: 'st',
  spa: 'es',
  sqi: 'sq',
  srd: 'sc',
  srp: 'sr',
  ssw: 'ss',
  sun: 'su',
  swa: 'sw',
  swe: 'sv',
  tah: 'ty',
  tam: 'ta',
  tat: 'tt',
  tel: 'te',
  tgk: 'tg',
  tgl: 'tl',
  tha: 'th',
  tir: 'ti',
  ton: 'to',
  tsn: 'tn',
  tso: 'ts',
  tuk: 'tk',
  tur: 'tr',
  twi: 'tw',
  uig: 'ug',
  ukr: 'uk',
  urd: 'ur',
  uzb: 'uz',
  ven: 've',
  vie: 'vi',
  vol: 'vo',
  wln: 'wa',
  wol: 'wo',
  xho: 'xh',
  yid: 'yi',
  yor: 'yo',
  zha: 'za',
  zho: 'zh',
  zul: 'zu',
}

@withAuthCheck
@connect((state) => ({
  layout: state.app.layout,
  isLoggedIn: isLoggedIn(state),
}))
@reduxForm({
  form: 'addBoard',
  initialValues: {
    name: '',
  },
})
@graphql(
  gql`
    mutation ($name: String!, $teamId: ID) {
      createBoardForTeam(name: $name, teamId: $teamId) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createBoardForTeam: (name, teamId) =>
        mutate({
          variables: { name, teamId },
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation (
      $title: String!
      $url: String!
      $language: String!
      $boardId: ID!
      $upvote: Boolean!
      $comment: CommentInput
    ) {
      saveNewArticle(
        title: $title
        url: $url
        language: $language
        boardId: $boardId
        upvote: $upvote
        comment: $comment
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      saveNewArticle: (title, url, language, boardId, upvote, comment) =>
        mutate({
          variables: { title, url, language, boardId, upvote, comment },
        }),
    }),
  },
)
@graphql(gql`
  query appQuery {
    session {
      user {
        id
        firstName
        lastName
        image
        teams {
          id
          name
          boards {
            id
            name
            url
          }
          teamUsers {
            user {
              id
              firstName
              lastName
              email
            }
            level
          }
        }
        boards {
          id
          name
          url
        }
      }
    }
  }
`)
@reduxForm({
  form: 'extension',
})
export default class ChromeExtension extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: null,
      url: null,
      teamId: '',
      boardId: '',
      addComment: false,
      commentText: '',
      addUpvote: false,
      addMustread: false,
      manuallyChangedTeam: false,
      addingBoard: false,
      success: false,
      error: null,
      boardName: '',
      comment: null,
      language: 'en',
    }
  }
  componentDidMount() {
    window.addEventListener(
      'message',
      (event) => {
        try {
          const info = JSON.parse(event.data)
          if (info && info.title && info.url) {
            this.setState({
              title: info.title,
              url: info.url,
              language: getLanguageCode(info.title),
            })
          }
        } catch (err) {}
        event.source.postMessage('end', event.origin)
      },
      false,
    )
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.data.loading &&
      !nextProps.data.loading &&
      !this.state.boardId
    ) {
      const session = nextProps.data.session

      if (this.props.isLoggedIn && session && session.user) {
        let boards =
          this.state.teamId === ''
            ? nextProps.data.session.user.boards
            : nextProps.data.session.user.teams.find(
                (team) => team.id == this.state.teamId,
              ).boards

        this.setState({
          boardId: boards.length > 0 ? boards[0].id : '',
        })
      }
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.session) {
      return (
        <div style={{ marginTop: '80px' }}>
          <Loader />
          <div
            style={{ textAlign: 'center', marginTop: '10px', color: '#888' }}
          >
            Loading..
          </div>
        </div>
      )
    }

    const session = this.props.data.session
    const isLoggedIn = this.props.isLoggedIn && session && session.user

    if (!isLoggedIn) {
      return (
        <div className={styles.notice}>
          <div>
            <div className={styles.noticeHeader}>You are not logged in</div>
            <div>
              Please open{' '}
              <a href="https://anderspink.com" target="_blank">
                https://anderspink.com
              </a>{' '}
              and login to use this extension
            </div>
          </div>
        </div>
      )
    }

    let teamUsers =
      this.state.teamId === ''
        ? []
        : session.user.teams.find((team) => team.id == this.state.teamId)
            .teamUsers

    let boards =
      this.state.teamId === ''
        ? session.user.boards
        : session.user.teams.find((team) => team.id == this.state.teamId).boards

    return (
      <div className={styles.root}>
        <header className={styles.header}>
          <div>
            <a href="/">
              <img
                className={styles.icon}
                src="https://app.anderspink.com/ap-logo.png"
              />
            </a>
          </div>
          <div>
            {!isLoggedIn && (
              <Link className={styles.headerLink} to="/login">
                Login
              </Link>
            )}
            {isLoggedIn && (
              <div className={styles.headerLink}>
                <DropdownMenu top={true}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={styles.profilePic}
                      src={session.user.image}
                    />{' '}
                    <span>
                      <span className={styles.username}>
                        {session.user.firstName}
                      </span>{' '}
                      <i className="fa fa-caret-down" />
                    </span>
                  </div>
                  <div>
                    <Link
                      to="/logout?redirect=/chrome-extension"
                      className={styles.dropdownLink}
                    >
                      Logout
                    </Link>
                  </div>
                </DropdownMenu>
              </div>
            )}
          </div>
        </header>
        <div className={styles.content}>
          {/* <div className="form-group mb-2">
            <div className={styles.article}>
              <div className={styles.articleTitle}>{this.state.title}</div>
              <div className={styles.articleUrl}>{this.state.url}</div>
            </div>
          </div> */}

          <div className="form-group mb-2">
            <label>1) Confirm the title and language of the article</label>

            <textarea
              placeholder="Article title"
              class="form-control form-control-sm"
              value={this.state.title}
              onChange={(e) => this.setState({ title: e.target.value })}
              rows="2"
            ></textarea>
            <select
              className="form-control form-control-sm"
              value={this.state.language}
              onChange={(e) =>
                this.setState({
                  language: e.target.value,
                })
              }
            >
              {languages.map((language) => (
                <option key={language.alpha2} value={language.alpha2}>
                  {language.label} ({language.alpha2})
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-2">
            <label>2) Select your personal view or a team</label>
            <select
              className="form-control form-control-sm"
              value={this.state.teamId}
              onChange={(e) => {
                let boards =
                  e.target.value === ''
                    ? session.user.boards
                    : session.user.teams.find(
                        (team) => team.id == e.target.value,
                      ).boards

                this.setState({
                  teamId: e.target.value,
                  boardId: boards.length > 0 ? boards[0].id : '',
                  success: false,
                  error: null,
                })
              }}
            >
              <option key="" value="">
                Personal view
              </option>
              {session.user.teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-2">
            <label className={styles.label}>
              <span>3) Select a folder</span>{' '}
              <span
                className={styles.addBoardLink}
                onClick={() =>
                  this.setState({ addingBoard: !this.state.addingBoard })
                }
              >
                Add new folder
              </span>
            </label>

            {this.state.addingBoard && (
              <div className={styles.addBoardContainer}>
                <input
                  type="text"
                  className="form-control form-control-sm mr-1"
                  placeholder="New folder name"
                  value={this.state.boardName}
                  onChange={(e) => this.setState({ boardName: e.target.value })}
                />
                <button
                  type="submit"
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    this.props
                      .createBoardForTeam(
                        this.state.boardName,
                        this.state.teamId,
                      )
                      .then((response) => {
                        this.setState({
                          boardId: response.data.createBoardForTeam.id,
                        })
                      })
                    this.setState({
                      addingBoard: false,
                      boardName: '',
                    })
                  }}
                >
                  Add
                </button>
              </div>
            )}

            <select
              className="form-control form-control-sm"
              value={this.state.boardId}
              onChange={(e) => {
                this.setState({
                  boardId: e.target.value,
                  success: false,
                  error: null,
                })
              }}
            >
              {boards.map((board) => (
                <option key={board.id} value={board.id}>
                  {board.name}
                </option>
              ))}
            </select>
          </div>

          {(this.state.teamId ? true : false) && (
            <div>
              <div className="d-flex justify-content-start mb-1">
                <div className="form-check mr-2">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input mr-1"
                      value={this.state.addUpvote}
                      onChange={(e) =>
                        this.setState({ addUpvote: e.target.checked })
                      }
                    />
                    Recommend this article
                  </label>
                </div>

                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input mr-1"
                      value={this.state.addComment}
                      onChange={(e) =>
                        this.setState({ addComment: e.target.checked })
                      }
                    />
                    Add a comment
                  </label>
                </div>
              </div>

              {this.state.addComment && (
                <div className="mb-2">
                  <ArticleCommentInput
                    ref={(instance) => {
                      this.child = instance
                    }}
                    teamUsers={teamUsers}
                  />
                </div>
              )}
            </div>
          )}

          {this.state.success && (
            <div className="alert alert-success" role="alert">
              Article saved!
            </div>
          )}
          {this.state.error && (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          )}

          <div className="form-group mb-2">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              onClick={() => {
                let comment = this.child ? this.child.getComment() : null
                this.props
                  .saveNewArticle(
                    this.state.title,
                    this.state.url,
                    this.state.language,
                    this.state.boardId,
                    this.state.addUpvote,
                    comment,
                  )
                  .then((response) => {
                    this.setState({
                      success: true,
                      error: null,
                    })

                    clearTimeout(this.timeoutId)
                    this.timeoutId = setTimeout(() => {
                      this.setState({
                        success: false,
                      })
                    }, 3000)
                  })
                  .catch((err) => {
                    this.setState({
                      error: err.message,
                    })
                  })
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }
}
