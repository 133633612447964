import React, { useState, useEffect } from 'react'
import { Link } from 'react-router'
import Button from '../Button'
import styles from '../Home.module.css'
import HomeStyles from '../../MainHomepage.module.css'

const Background = `${STATIC_DOMAIN}/pages/new-landingpage/background.svg`

const Intro = () => {
  const [_document, set_document] = useState(null)
  const [isLessThan870, setIsLessThan870] = useState(false)

  useEffect(() => {
    return set_document(document)
  }, [])

  useEffect(() => {
    if (_document) {
      if (textArray.length) setTimeout(type, newTextDelay + 250)
    }
  }, [_document])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      if (newWidth < 870) {
        setIsLessThan870(true)
      } else {
        setIsLessThan870(false)
      }
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const textArray = ['Discover', 'Curate', 'Tag']
  const typingDelay = 100
  const erasingDelay = 100
  const newTextDelay = 2000 // Delay between current and next text
  let textArrayIndex = 0
  let charIndex = 0

  const type = () => {
    const typedTextSpan = _document.querySelector('.typed-text')
    const cursorSpan = _document.querySelector('.cursor')

    if (charIndex < textArray[textArrayIndex].length) {
      if (!cursorSpan.classList.contains('typing'))
        cursorSpan.classList.add('typing')
      typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex)
      charIndex++
      setTimeout(type, typingDelay)
    } else {
      cursorSpan.classList.remove('typing')
      setTimeout(erase, newTextDelay)
    }
  }

  const erase = () => {
    const typedTextSpan = _document.querySelector('.typed-text')
    const cursorSpan = _document.querySelector('.cursor')

    if (charIndex > 0) {
      if (!cursorSpan.classList.contains('typing'))
        cursorSpan.classList.add('typing')
      typedTextSpan.textContent = textArray[textArrayIndex].substring(
        0,
        charIndex - 1,
      )
      charIndex--
      setTimeout(erase, erasingDelay)
    } else {
      cursorSpan.classList.remove('typing')
      textArrayIndex++
      if (textArrayIndex >= textArray.length) textArrayIndex = 0
      setTimeout(type, typingDelay + 1100)
    }
  }

  return (
    <div className={styles.section}>
      <div className={`${styles.content} ${styles.heroContent}`}>
        <div>
          <span className={styles.header}>
            AI-powered curated content for learning
            <br />
            <br />
            Powerful search for any topic
            <br />
            Plugged into any platform, mapped to the skills that matter.
          </span>

          <p style={{ display: 'flex', marginTop: 60, marginBottom: 60 }}>
            <span className={`${styles.label} typed-text`}></span>
            <span className={`${styles.cursor} cursor`}>&nbsp;</span>
          </p>

          <Link to="/contact">
            <Button title="Book a consultation" backgroundColor="#CF0032" />
          </Link>
        </div>
        <div
          className={HomeStyles.background1}
          style={{
            backgroundImage: isLessThan870 ? 'none' : `url(${Background})`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default Intro
