exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".BriefingPage__optionsMenu___2hJ6u {\n  color: #aaa;\n  text-decoration: underline;\n  font-size: 1.4em;\n\n  -webkit-transition: color 0.1s linear;\n\n  transition: color 0.1s linear;\n  cursor: pointer;\n}\n.BriefingPage__articleSearch___1i4eD {\n  color: #aaa;\n  font-size: 1.4em;\n  margin: 0.3rem 1rem;\n  -webkit-transition: color 0.1s linear;\n  transition: color 0.1s linear;\n  cursor: pointer;\n}\n.BriefingPage__articleSearch___1i4eD:hover,\n.BriefingPage__optionsMenu___2hJ6u:hover {\n  color: inherit;\n}\n.BriefingPage__dropdownLink___1ZHw_ {\n  display: block;\n  padding: 7px 13px;\n  color: inherit;\n}\n.BriefingPage__subscribable___EkLhF {\n  margin-left: 1rem;\n  font-size: 0.6rem;\n  border: 1px solid #eee;\n  padding: 0.1rem 0.3rem;\n  border-radius: 4px;\n  color: #777;\n}\n\n.BriefingPage__top___2XadH {\n  /* display: flex;\n  align-items: center;\n  justify-content: space-between; */\n}\n.BriefingPage__top___2XadH .btn-light {\n  border-color: #e0e0e0;\n}\n\n@media screen and (max-width: 800px) {\n  .BriefingPage__top___2XadH {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"optionsMenu": "BriefingPage__optionsMenu___2hJ6u",
	"articleSearch": "BriefingPage__articleSearch___1i4eD",
	"dropdownLink": "BriefingPage__dropdownLink___1ZHw_",
	"subscribable": "BriefingPage__subscribable___EkLhF",
	"top": "BriefingPage__top___2XadH"
};