import React from 'react'
import { SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import DigestForm from '../components/DigestForm'
import compose from 'recompose/compose'
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import GET_TEAM from '../queries/team'

const CREATE_DIGEST = gql`
  mutation createNewsletter($newsletter: NewsletterInput, $teamId: ID) {
    createNewsletter(newsletter: $newsletter, teamId: $teamId) {
      id
    }
  }
`

const isEmail = (string) => {
  const emailValidation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return emailValidation.test(String(string).toLowerCase())
}

let NewDigestPage = ({
  initialValues,
  valid,
  error,
  createNewsletter,
  dispatch,
  teamData,
}) => {
  if (!teamData.session) {
    return <div>Loading</div>
  }
  const onSubmit = (values) => {
    if (values.emailFromAddress && !isEmail(values.emailFromAddress)) {
      dispatch({
        type: 'ADD_NOTIFICATION',
        content: `The email address is invalid`,
        level: 'danger',
      })
      return
    } else {
      createNewsletter({
        variables: {
          newsletter: {
            newsletterTitle: values.newsletterTitle,
            sendingInterval: values.sendingInterval,
            sendingDay: values.sendingDay,
            articlesFilter: values.articlesFilter,
            withSingleArticlePerSource: values.withSingleArticlePerSource,
            briefingType: values.briefingType,
            briefingIds: values.briefingIds,
            articlesLimit: values.articlesLimit,
            recipientsType: values.recipientsType,
            userIds: values.userIds,
            withAdditionalEmails: values.withAdditionalEmails,
            withBriefingName: values.withBriefingName,
            additionalEmailAddresses: values.additionalEmailAddresses,
            disclaimer: values.disclaimer,
            emailTitle: values.emailTitle,
            digestTime: values.digestTime,
            digestTimeZone: values.digestTimeZone,
            bannerImageUrl: values.bannerImage.image,
            bannerImageWidth: values.bannerImage.width,
            headline: values.headline.replace(/\n\r/g, '<br />'),
            footerLine: values.footerLine.replace(/\n\r/g, '<br />'),
            emailFromName: values.emailFromName,
            emailFromAddress: values.emailFromAddress,
            withArticleDescription: !!values.withArticleDescription,
            withViewInAnderspink: !!values.withViewInAnderspink,
          },
          teamId: 1,
        },
      })
        .then(() => {
          browserHistory.push({
            pathname: '/newsletters',
            state: { refetch: true },
          })
          dispatch({
            type: 'ADD_NOTIFICATION',
            content: `Digest succesfully created`,
          })
        })
        .catch((err) => {
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            throw new SubmissionError({
              _error: err.graphQLErrors[0].message,
            })
          } else if (err.networkError) {
            throw new SubmissionError({
              _error: err.networkError.message,
            })
          } else {
            throw new SubmissionError({
              _error: err.message,
            })
          }
        })
    }
  }
  return (
    <div>
      <h5>Configure new digest</h5>
      <DigestForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        valid={valid}
        error={error}
        briefings={teamData.session.team.ownedBriefings || []}
        users={teamData.session.team.teamUsers || []}
        unsubscribedUsers={[]}
      />
    </div>
  )
}

NewDigestPage = connect(() => ({
  initialValues: {
    newsletterTitle: '',
    sendingInterval: 'weekly',
    sendingDay: '',
    articlesFilter: 'month',
    withSingleArticlePerSource: false,
    digestTime: '08:00',
    digestTimeZone: 'Europe/London',
    briefingType: 'all',
    briefingIds: [],
    articlesLimit: 3,
    recipientsType: 'all',
    userIds: [],
    withAdditionalEmails: false,
    withBriefingName: true,
    additionalEmailAddresses: [],
    disclaimer: false,
    emailTitle: '',
    bannerImage: {},
    headline: '',
    footerLine: '',
    emailFromName: '',
    emailFromAddress: '',
    withArticleDescription: true,
    withViewInAnderspink: true,
  },
}))(NewDigestPage)

NewDigestPage = compose(
  withApollo,
  graphql(CREATE_DIGEST, {
    name: 'createNewsletter',
  }),
  graphql(GET_TEAM, {
    name: 'teamData',
  }),
)(NewDigestPage)

export default NewDigestPage
