import React, { Component, Fragment } from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Button, ProgressBar, Modal } from 'react-bootstrap'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'

import styles from './Training.css'

import FixedModal from 'app/components/FixedModal'
import ToolTip from 'app/components/ToolTip'
import { FORM_NAMES } from '../constants'

export const hasPendingTrainings = (trainings, temporaryTrainings) => {
  const sort = (a, b) => (a.uuid > b.uuid ? 1 : -1)
  return (
    temporaryTrainings &&
    temporaryTrainings.length > 0 &&
    !isEqual(
      [...(temporaryTrainings || [])].sort(sort),
      [...(trainings || [])].sort(sort),
    )
  )
}

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state) => ({
  ...selector(
    state,
    'type',
    'keywordsV2Fields.trainings',
    'sourcesV2Fields.trainings',
    'temporaryTrainings',
    'keywordsV2Fields.domainListAllowIds',
    'keywordsV2Fields.domainListBlockIds',
  ),
}))
export default class Training extends Component {
  constructor(props) {
    super(props)
    this.state = { isModalOpen: false, isWarningModalOpen: false }
  }
  clearTraining = () => {
    this.props.change('temporaryTrainings', [])
    this.props.change(`${this.props.type}Fields.trainings`, [])
    this.setState({ isModalOpen: false })
  }
  componentWillReceiveProps(newProps) {
    const haveTraining =
      this.props[`${this.props.type}Fields`].trainings.length > 0 ||
      this.props.temporaryTrainings.length > 0

    const listsAdded =
      (this.props.keywordsV2Fields.domainListAllowIds.length === 0 &&
        newProps.keywordsV2Fields.domainListAllowIds.length > 0) ||
      (this.props.keywordsV2Fields.domainListBlockIds.length === 0 &&
        newProps.keywordsV2Fields.domainListBlockIds.length > 0)

    if (haveTraining && listsAdded) {
      this.props.onShowTrainingWarning()
    }
  }
  applyTraining = () => {
    const foundPositive = this.props.temporaryTrainings.some(
      (t) => t.label === 'relevant',
    )
    const foundNegative = this.props.temporaryTrainings.some(
      (t) => t.label === 'irrelevant',
    )

    if (!foundPositive || !foundNegative) {
      this.setState({ isWarningModalOpen: true })
    } else {
      this.props.change(
        `${this.props.type}Fields.trainings`,
        this.props.temporaryTrainings,
      )
    }
  }
  render() {
    const requiredTrainingsCount = 30
    const trainings = uniqBy(
      [
        ...(this.props.temporaryTrainings || []),
        ...(this.props[`${this.props.type}Fields`].trainings || []),
      ],
      'uuid',
    )
    const percentageComplete = Math.round(
      (trainings.length / requiredTrainingsCount) * 100,
    )

    const havePendingTrainings = hasPendingTrainings(
      this.props[`${this.props.type}Fields`].trainings,
      this.props.temporaryTrainings,
    )

    return (
      <div>
        <p>
          <span style={{ color: '#666666' }}>
            Train your briefing to bring the most relevant content to the top,
            and filter out irrelevant items.
          </span>{' '}
          <ToolTip>
            <p>
              Use up and down vote arrows on these articles to let us know if
              they're relevant to you.
            </p>

            <p>
              We use this information to ensure the most relevant content is
              brought to the top of your briefing each day. You can refine it
              further any time, and you can leave articles unmarked (neutral).
            </p>

            <p>
              Once you've selected some articles, you must apply the training
              and refresh your preview to see an updated set of articles.
            </p>
          </ToolTip>
        </p>

        {/* <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ isModalOpen: true })}
          >
            Start refining briefing
          </button>
        </div> */}

        {trainings.length === 0 && (
          <p>
            You haven't done any training yet. Get started by using the thumbs
            up/down any articles.
          </p>
        )}
        {trainings.length > 0 && (
          <p>
            You've trained the briefing using {trainings.length} articles. To
            increase the accuracy, train on as many articles as possible.
          </p>
        )}
        {/* <p className="mt-3">
          You have refined the briefing by up/down voting{' '}
          {
            uniqBy(
              [
                ...(this.props.temporaryTrainings || []),
                ...(this.props.keywordsV2Fields.trainings || []),
              ],
              'uuid',
            ).length
          }{' '}
          articles so far.
        </p> */}
        {percentageComplete > 0 && (
          <ProgressBar
            now={Math.min(percentageComplete, 100)}
            label={`${percentageComplete}%`}
          />
        )}

        <div className="mt-2">
          {havePendingTrainings && (
            <Button
              size="sm"
              className="mr-2"
              onClick={() => this.applyTraining()}
            >
              Apply training and refresh preview
            </Button>
          )}

          {trainings.length > 0 && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.setState({ isModalOpen: true })}
            >
              Clear training and start again
            </Button>
          )}
        </div>

        {havePendingTrainings && (
          <div className={styles.banner}>
            <div className={styles.bannerTop}>
              <div>
                You are training your briefing. Once finished, you can{' '}
                <Button size="sm" onClick={() => this.applyTraining()}>
                  Apply training and refresh preview
                </Button>
              </div>
            </div>
            <div className={styles.bannerBottom}>
              Tip: applying your training periodically means your results will
              be refined more quickly inline with your desired context
            </div>
          </div>
        )}

        <Modal
          show={this.state.isWarningModalOpen}
          onHide={() => this.setState({ isWarningModalOpen: false })}
          centered
        >
          <Modal.Body>
            Your training must include up <strong>and</strong> down votes,
            please include at least one of each.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ isWarningModalOpen: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
        >
          <div className={styles.clearTrainingModal}>
            <p className={styles.ctModalText}>
              Are you sure you want to remove all your previous training?
            </p>
            <div className={styles.ctModalBtns}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.clearTraining()}
              >
                Yes
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => this.setState({ isModalOpen: false })}
              >
                No
              </button>
            </div>
          </div>
        </FixedModal>
      </div>
    )
  }
}
