import React from 'react'
import { browserHistory, Link } from 'react-router'

export default class AdminPage extends React.Component {
  render() {
    const pathname = this.props.location.pathname

    return (
      <div>
        <div style={{ marginBottom: '20px' }}>
          <ul className="nav nav-tabs">
            <li className="nav-item ">
              <Link
                to="/admin/users"
                className={`nav-link ${
                  pathname.indexOf('/admin/users') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                Users
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/teams"
                className={`nav-link ${
                  pathname.indexOf('/admin/teams') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                Teams
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/accounts"
                className={`nav-link ${
                  pathname.indexOf('/admin/accounts') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                Accounts
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/create-account"
                className={`nav-link ${
                  pathname.indexOf('/admin/create-account') === 0
                    ? 'active'
                    : ''
                }`}
                href="#"
              >
                Create account
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/apiusers"
                className={`nav-link ${
                  pathname.indexOf('/admin/apiusers') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                API users
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/plans"
                className={`nav-link ${
                  pathname.indexOf('/admin/plans') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                Plans
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/create-plan"
                className={`nav-link ${
                  pathname.indexOf('/admin/create-plan') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                Create plan
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/crawler"
                className={`nav-link ${
                  pathname.indexOf('/admin/crawler') === 0 ? 'active' : ''
                }`}
                href="#"
              >
                Crawler
              </Link>
            </li>
          </ul>
        </div>
        {this.props.children}
      </div>
    )
  }
}
