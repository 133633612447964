import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'

import AdminTable from 'admin/components/AdminTable'

const ITEMS_PER_PAGE = 100

@graphql(
  gql`
    query (
      $query: String
      $sort: String
      $sortOrder: String
      $limit: Int!
      $offset: Int!
    ) {
      teams(
        query: $query
        sort: $sort
        sortOrder: $sortOrder
        limit: $limit
        offset: $offset
      ) {
        id
        name
        teamUsers {
          user {
            id
            firstName
            lastName
            email
          }
          level
        }
        account {
          id
          type
          name
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          query: props.location.query.query || '',
          sort: props.location.query.sort || 'id',
          sortOrder: props.location.query.sortOrder || 'desc',
          limit: props.location.query.limit || ITEMS_PER_PAGE,
          offset: props.location.query.offset || 0,
        },
      }
    },
    props({ data: { teams, fetchMore, ...rest } }) {
      return {
        data: {
          teams,
          ...rest,
          loadMore() {
            return fetchMore({
              variables: {
                offset: teams.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return {
                  ...previousResult,
                  teams: [...previousResult.teams, ...fetchMoreResult.teams],
                }
              },
            })
          },
        },
      }
    },
  },
)
export default class AdminTeamsPage extends React.Component {
  render() {
    return (
      <div>
        <AdminTable
          fields={{
            id: {
              label: 'ID',
              render: (id) => (
                <Link to={`/admin/teams/${id}`} className="link">
                  {id}
                </Link>
              ),
            },
            name: {
              label: 'Name',
            },
            account: {
              label: 'Billing account',
              render: (account) =>
                !account ? (
                  'none'
                ) : (
                  <Link to={`/admin/accounts/${account.id}`} className="link">
                    {account.name} ({account.type})
                  </Link>
                ),
            },
            teamUsers: {
              label: 'Users',
              render: (teamUsers) => {
                return teamUsers.map((teamUser, index) => (
                  <Link
                    key={index}
                    to={`/admin/users/${teamUser.user.id}`}
                    className="link link-list"
                  >
                    {teamUser.user.firstName} {teamUser.user.lastName}
                  </Link>
                ))
              },
            },
            stats: {
              label: 'Stats',
              render: (field, team) => {
                return (
                  <span>
                    <Link to={`/team-api-stats/${team.id}`} className="link">
                      View stats
                    </Link>
                  </span>
                )
              },
            },
          }}
          data={this.props.data.teams || []}
          loading={this.props.data.loading}
          onSubmit={(values) => {
            browserHistory.push({
              pathname: '/admin/teams',
              query: {
                ...values,
              },
            })
          }}
          loadMore={() => {
            this.props.data.loadMore()
          }}
          initialValues={{
            ...this.props.location.query,
          }}
        />
      </div>
    )
  }
}
