import React from 'react'
import moment from 'moment'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'

import { Form, Container, Row, Col, Table, Badge } from 'react-bootstrap'
import { browserHistory, Link } from 'react-router'
import ArticleTable from './stats/ArticleTable'
import flattenStatFields from '../utils/flattenStatFields'
import TimeRangeFilter from './stats/TimeRangeFilter'

const selector = formValueSelector('briefingStats')

@compose(
  graphql(
    gql`
      query (
        $id: ID!
        $timeType: String
        $timeStart: String
        $timeEnd: String
        $entityId: String
        $briefingId: ID!
      ) {
        briefing(id: $briefingId) {
          id
          name
          degreedSkills {
            id
            degreedSkill
          }
        }
        team(id: $id) {
          id
          name
          teamStats(
            query: "briefing"
            timeType: $timeType
            timeStart: $timeStart
            timeEnd: $timeEnd
            entityId: $entityId
          ) {
            fields {
              name
              value
            }
            relatedEntities {
              type
              id
              name
            }
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.params.teamId,
            timeType: props.location.query.timeType,
            timeStart: props.location.query.timeStart,
            timeEnd: props.location.query.timeEnd,
            entityId: props.params.briefingId,
            briefingId: props.params.briefingId,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
export default class TeamApiStatsBriefing extends React.Component {
  constructor() {
    super()
    this.state = {
      integration: null,
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.team) {
      return 'Loading...'
    }
    if (!this.props.data.team || !this.props.data.briefing) {
      return 'Sorry, something went wrong'
    }
    const { team, briefing } = this.props.data

    const teamId = this.props.params.teamId

    return (
      <div>
        <div>
          <Row>
            <Col xs={7}>
              <h5 className="mb-3">
                <Link
                  className="link"
                  to={`/team-api-stats/${this.props.params.teamId}`}
                >
                  Usage Analytics
                </Link>{' '}
                &gt; Briefing Views &gt; {briefing.name}
              </h5>

              {/* <Form className="mb-2">
            <Form.Row className="align-items-center">
              <Col xs="auto">
                <Form.Label>Integration</Form.Label>
              </Col>
              <Col xs="auto">
                <select className="form-control">
                  <option>All</option>
                </select>
              </Col>
            </Form.Row>
          </Form> */}

              <TimeRangeFilter
                {...this.props.location.query}
                onChange={(fields) => {
                  browserHistory.push({
                    pathname: `/team-api-stats/${this.props.params.teamId}/briefing/${this.props.params.briefingId}`,
                    query: {
                      ...this.props.location.query,
                      ...fields,
                    },
                  })
                }}
                loading={this.props.data.loading && this.props.data.team}
              />
            </Col>
            <Col className="d-flex justify-content-end align-items-end">
              {briefing &&
                briefing.degreedSkills &&
                briefing.degreedSkills.length > 0 && (
                  <div className="mb-3">
                    Degreed skills:{' '}
                    {briefing.degreedSkills.map((skill) => (
                      <span key={skill.id}>
                        <Badge variant="secondary">{skill.degreedSkill}</Badge>{' '}
                      </span>
                    ))}
                  </div>
                )}
            </Col>
          </Row>

          <ArticleTable
            stats={this.props.data.team.teamStats.map((stat) =>
              flattenStatFields(stat),
            )}
            teamId={this.props.params.teamId}
            timeType={this.props.location.query.timeType}
            timeStart={this.props.location.query.timeStart}
            timeEnd={this.props.location.query.timeEnd}
          />
        </div>
      </div>
    )
  }
}
