import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'

@connect((state) => ({
  app: state.app,
}))
export default class Integrations extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{ backgroundImage: 'url(/pages/landing/plugin-header.png)' }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Plugins and Integrations</div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            Put Anders Pink inside the platforms and tools you already use
          </div>

          <div className={pageStyles.textContainer}>
            <p>
              You want your learners and your audience to access recent,
              relevant and curated content easily. So do we. They're already
              using tools like Slack, Yammer, SharePoint, and a LMS or Learning
              Platform.
            </p>
            <p>
              Rather than require them to come to another tool, isn't it easier
              to bring the content to them?
            </p>
            <p>
              That’s why we make it very easy to embed content from Anders Pink
              wherever you want.
            </p>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            Integrate AP, your way:
          </div>

          <div className={pageStyles.textContainer}>
            <p>You can use our plugins and integrations in two main ways:</p>

            <p>
              <strong>Simple</strong>
              <br />
              Make your briefings or shared folders in our Anders Pink app,
              display the content in your app or platform for your end users to
              access easily. We have pre-made plugins, HTML embed code and RSS
              feeds to help with this.
            </p>

            <p>
              <strong>Advanced</strong>
              <br />
              Bring Anders Pink functionality directly into your platform.
              Enable your admins and users to create briefings and folders
              completely within your app or platform. Our API and SDK options
              support this.
            </p>

            <p>
              Both ways come with a range of different options, documentation,
              and of course support from us to make it happen.
            </p>
          </div>
        </div>

        <div className={pageStyles.greySection}>
          <div className={styles.contact}>
            <p className={styles.contactTop}>
              Want to talk about the best way to integrate with your platform,
              and add traffic, engagement and value through curation?
            </p>
            <Link
              to="/contact"
              className="btn btn-lg btn-outline-dark"
              style={{ borderWidth: '2px' }}
            >
              Get in touch
            </Link>
            <p className={styles.contactBottom}>
              and we’ll arrange a free Integration trial.
            </p>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            Check out our range of plugins and integrations
          </div>

          <div className={pageStyles.sectionSubHeading}>
            Ready to use plugins for popular platforms:
          </div>

          <div className={pageStyles.blocks}>
            {/* <Link to="/integrations/slack" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <img src={`${STATIC_DOMAIN}/pages/landing/slack-logo.png`} />
              </div>
            </Link> */}
            {/* <Link to="/integrations/wordpress" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <img
                  src={`${STATIC_DOMAIN}/pages/landing/wordpress-logo.png`}
                />
              </div>
            </Link> */}
            <Link to="/integrations/moodle" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <img src={`${STATIC_DOMAIN}/pages/landing/moodle-logo.png`} />
              </div>
            </Link>
            {/* <Link to="/integrations/yammer" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <img src={`${STATIC_DOMAIN}/pages/landing/y-logo.png`} />
              </div>
            </Link> */}
            <Link to="/integrations/sharepoint" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <img
                  src={`${STATIC_DOMAIN}/pages/landing/sharepoint-logo.png`}
                />
              </div>
            </Link>
            <Link to="/partners/totara" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <img src={`${STATIC_DOMAIN}/pages/landing/totara-logo.jpg`} />
              </div>
            </Link>
          </div>

          <p className={pageStyles.blocksBottom}>
            See our{' '}
            <Link className="link" to="/partners">
              partner page
            </Link>{' '}
            for all of their integrations.
          </p>

          <br />
          <br />

          <div className={pageStyles.sectionSubHeading}>
            Custom Integration methods for other platforms:
          </div>

          <div className={pageStyles.blocks}>
            <Link to="/c/javascript-embed-code" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <span>HTML</span>
              </div>
            </Link>
            <Link to="/api-info" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <span>API</span>
              </div>
            </Link>
            <Link to="/c/javascript-sdk" className={pageStyles.block}>
              <div className={pageStyles.blockHighlight}>
                <span>SDK</span>
              </div>
            </Link>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeading}>
            We're adding plugins and integrations all the time.
          </div>

          <div className={pageStyles.sectionSubHeading}>
            What plugin should we build next?
            <p>
              <Link to="/contact" className="btn btn-lg btn-primary mt-3">
                Let us know
              </Link>
            </p>
          </div>
        </div>

        <div className={pageStyles.greySection}>
          <div className={pageStyles.innerGreySection}>
            <div className={pageStyles.sectionHeadingThin}>Partners</div>

            <div className={pageStyles.textContainer}>
              <p>
                Integrate Anders Pink to add curated content and advanced
                functionality to your platform, with specific partner and
                reseller plans to enable you to offer integrations at scale for
                your clients.
              </p>
              <p>
                Join over 25 global Anders Pink Partners: Get in touch to
                discuss your partnership goals.
              </p>
            </div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeading}>Developers</div>

          <div className={pageStyles.sectionSubHeading}>
            Some more technical detail below, and in the links above
          </div>

          <div style={{ overflow: 'scroll' }}>
            <table className={['table-bordered', styles.table].join(' ')}>
              <tbody>
                <tr className={styles.tableBold}>
                  <td></td>
                  <td>
                    <Link to="/api-info" className="link">
                      API
                    </Link>
                  </td>
                  <td>
                    <Link to="/c/javascript-sdk" className="link">
                      JavaScript SDK
                    </Link>
                  </td>
                  <td>Plugins</td>
                  <td>
                    <Link to="/c/javascript-embed-code" className="link">
                      Javascript Widget
                    </Link>
                  </td>
                  <td>RSS feed</td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>Platforms</td>
                  <td>Any website, app or service</td>
                  <td>Any website</td>
                  <td>
                    <Link to="/integrations/moodle" className="link">
                      Moodle
                    </Link>
                    ,{' '}
                    <Link to="/partners/totara" className="link">
                      Totara
                    </Link>
                    ,{' '}
                    <Link to="/integrations/wordpress" className="link">
                      WordPress
                    </Link>
                  </td>
                  <td>Any website</td>
                  <td>Any website, app or service</td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>Notes</td>
                  <td>REST API for use in a server environment</td>
                  <td>
                    Uses JavaScript and requires a small amount of server work
                  </td>
                  <td>Platform specific plugins. Quick to install and use</td>
                  <td>JavaScript, can be embedded in any website</td>
                  <td>
                    RSS is a common format that lots of sites and apps can
                    consume
                  </td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>Needs API key</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>Displays briefings</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>Displays saved folders</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>
                    Style the display of briefings inside your app
                  </td>
                  <td>Yes</td>
                  <td>N/a</td>
                  <td>Yes - via configurable options</td>
                  <td>Yes - via CSS</td>
                  <td>Yes - depending on your RSS client</td>
                </tr>
                <tr>
                  <td className={styles.tableBold}>
                    Create a briefing from within your own platform. No need to
                    login to Anders Pink separately
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.technical}>
          <div className={styles.technicalContent}>
            <p className={styles.technicalTop}>
              Need some technical help to get an integration going? We’re here
              to help.
            </p>
            <p>
              Work directly with us, or we can work with your technology and
              learning partners to make it happen. Get in touch to discuss your
              integration needs, and get a free trial.
            </p>
            <Link to="/contact" className="btn btn-lg btn-primary">
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
