import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import BriefingNameBlockList from 'briefings/components/BriefingNameBlockList'
import BriefingNameBlock from 'briefings/components/BriefingNameBlock'
import styles from './AllBriefingsPage.css'
import { DropdownMenu } from 'app/components/DropdownMenu'

@graphql(
  gql`
    query {
      session {
        briefings {
          id
          name
          image
          url
        }
      }
    }
  `,
  {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  },
)
export default class AllBriefingsPage extends React.Component {
  render() {
    if (this.props.data.loading) {
      return null
    }

    let briefings = this.props.data.session.briefings.slice(0)

    return (
      <div>
        <div className={styles.titleContainer}>
          <h5 className={styles.title}>Your briefings</h5>

          <DropdownMenu top={true} left={true}>
            <div className={styles.optionsMenu} data-tip="Settings">
              <i className="fa fa-cog" />
            </div>
            <div>
              <span
                className={styles.dropdownLink}
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  window.location = '/team/briefings-order'
                }}
              >
                Re-order briefings
              </span>
            </div>
          </DropdownMenu>
        </div>

        {briefings.length === 0 && (
          <div>
            <p>
              You have no briefings yet. Briefings are a powerful way to find
              fresh daily content on a specific subject.
            </p>
            <p>
              <Link className="link" to="/create-briefing">
                Create
              </Link>{' '}
              your own briefing
            </p>
          </div>
        )}

        {briefings.length > 0 && (
          <BriefingNameBlockList>
            {briefings.map((briefing, index) => (
              <div key={index}>
                <BriefingNameBlock briefing={briefing} />
              </div>
            ))}
          </BriefingNameBlockList>
        )}
      </div>
    )
  }
}
