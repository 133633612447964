import React, { useState } from 'react'
import { browserHistory } from 'react-router'
import styles from '../Home.module.css'
import axios from 'axios'

const DownloadPressRelease = (props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [complete, setComplete] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .post(`/cc-newsletter-signup`, {
        firstName,
        lastName,
        email,
        acceptedTerms,
      })
      .then((response) => {
        setComplete(true)
        setFirstName('')
        setLastName('')
        setEmail('')
        setAcceptedTerms(false)
        setError(false)
        setTimeout(() => {
          window.open(
            '/documents/AndersPink_Complete_Curation_Product_Sheet.pdf',
            '_blank',
          )
        }, 1000)
      })
      .catch((err) => {
        setComplete(false)
        setError(true)
      })
  }

  return (
    <div
      className={styles.section}
      style={{ backgroundColor: 'rgb(0, 96, 103)' }}
    >
      <div
        className={styles.content}
        id="downloadProductSheet"
        style={{ background: '#006067', flexDirection: 'column' }}
      >
        <p style={{ fontSize: '1.6rem', fontWeight: '900', color: '#ffffff' }}>
          Download product sheet
        </p>

        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex' }}>
            <input
              type="text"
              placeholder="First name"
              className={styles.input}
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last name"
              className={styles.input}
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            className={styles.input}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="form-check mb-2">
            <label className="form-check-label" style={{ color: 'white' }}>
              <input
                name="terms"
                component="input"
                type="checkbox"
                value={!!acceptedTerms}
                onChange={(e) => setAcceptedTerms(!!e.target.value)}
                required
                // normalize={(v) => {
                //   return !!v ? true : false
                // }}
                className="form-check-input"
              />{' '}
              Receive occasional updates from us including new features,
              articles and communications. You can opt out at any time.
            </label>
          </div>

          {complete && (
            <div className="alert alert-success mt-2 mb-2">
              Thank you! We're opening a new tab with your Product Sheet in.
            </div>
          )}

          {error && (
            <div className="alert alert-danger mt-2 mb-2">
              Sorry an error occured, please try again or contact us.
            </div>
          )}

          <div>
            <input
              type="submit"
              value="Submit"
              className={styles.downloadBtn}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default DownloadPressRelease
