import React from 'react'
import { browserHistory } from 'react-router'
import compose from 'recompose/compose'
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import ListIndividualStatsItem from '../components/ListIndividualStatsItem'

const GET_INDIVIDUAL_STATS_FOR_DIGEST = gql`
  query IndividualStatsForNewsletter($id: ID!) {
    IndividualStatsForNewsletter(id: $id) {
      id
      email
      userId
      sent
      delivered
      opened
      clicked
    }
  }
`

let IndividualStatsDigestPage = (props) => {
  const { IndividualStatsForNewsletter, error, loading, refetch } =
    props.IndividualStatsForNewsletter

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div>
      <h5>Digest Stats for ID: {props.params.id[1]}</h5>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Email</th>
            <th scope="col">Is Team Member</th>
            <th scope="col">Sent Date</th>
            <th scope="col">Delivered Date</th>
            <th scope="col">Opened Date</th>
            <th scope="col">Clicked Date</th>
          </tr>
        </thead>
        <tbody>
          {IndividualStatsForNewsletter.map((item, index) => (
            <ListIndividualStatsItem key={index} item={item} />
          ))}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-sm btn-secondary ml-1"
        onClick={() => browserHistory.goBack()}
      >
        Cancel
      </button>
    </div>
  )
}

IndividualStatsDigestPage = compose(
  withApollo,
  graphql(GET_INDIVIDUAL_STATS_FOR_DIGEST, {
    name: 'IndividualStatsForNewsletter',
    options: ({ params }) => ({
      variables: { id: params.id[1] },
      fetchPolicy: 'cache-and-network',
    }),
  }),
)(IndividualStatsDigestPage)

export default IndividualStatsDigestPage
