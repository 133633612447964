import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './ArticlePage.css'
import Article from 'articles/containers/Article'
import articleInfoFragment from 'articles/fragments/articleInfo'

@graphql(
  gql`
    query ($id: ID!) {
      article(id: $id) {
        ...articleInfo
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
export default class ArticlePage extends React.Component {
  render() {
    if (this.props.data.loading) {
      return null
    }

    const article = this.props.data.article

    return (
      <div className={styles.root}>
        <Article article={article} showCommentsInitially={true} />
      </div>
    )
  }
}
