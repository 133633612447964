import React from 'react'
import styles from './D2L.css'
import Article from 'articles/containers/Article'

// handy for showing images locally
// const replaceUrl = (url) =>
//   url && url.replace("http://localhost:8051/", "http://static.anderspink.com/");

const renderArticleList = ({
  articles = [],
  selectedArticles = [],
  onArticleClick,
}) => (
  <div className={styles.articleList}>
    {articles &&
      articles.map((a) => (
        <div
          className={`${styles.articleTile}  ${
            selectedArticles.map((a) => a.id).includes(a.id)
              ? styles.articleTileSelected
              : ''
          }`}
          onClick={() => onArticleClick(a)}
        >
          <div className={styles.clickBlocker}></div>
          <Article
            key={a.id}
            article={a}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            showActions={false}
          ></Article>
        </div>
      ))}
  </div>
)

export { renderArticleList }
