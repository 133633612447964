import React from 'react'
import styles from './ArticleList.css'
import chunk from 'lodash/chunk'

export default class ArticleList extends React.Component {
  static defaultProps = {
    minusSpace: '240',
  }
  render() {
    let activeStyles = styles

    const childChunks = chunk(this.props.children, 4)

    let className = activeStyles.containerMinus240
    if (this.props.minusSpace === '390') {
      className = activeStyles.containerMinus390
    }

    return (
      <div className={className}>
        {this.props.children.map((child, index) => (
          <div key={index} className={activeStyles.item}>
            {child}
          </div>
        ))}
      </div>
    )
  }
}
