import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Field, reduxForm } from 'redux-form'
import SearchResults from 'search/containers/SearchResults'
import styles from './SearchPage.css'

@connect((state) => ({
  app: state.app,
}))
@reduxForm({
  form: 'search',
})
export default class SearchPage extends React.Component {
  constructor() {
    super()
    this.state = {
      query: '',
    }
  }
  componentDidMount() {
    this.refs.queryInput.getRenderedComponent().focus()
  }
  onSubmit = (values) => {
    this.setState({
      query: values.query,
    })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
      <div>
        <h5 className={styles.title}>Search for public briefings</h5>

        <div className={styles.input}>
          <Field
            withRef
            name="query"
            component="input"
            type="text"
            className="form-control"
            ref="queryInput"
            placeholder="Enter a term.. e.g. marketing, big data, cyling..."
            onKeyUp={() => {
              clearTimeout(this.timeoutId)
              this.timeoutId = setTimeout(() => {
                handleSubmit(this.onSubmit)()
              }, 500)
            }}
          />
        </div>

        <div className={styles.results}>
          <SearchResults query={this.state.query} />
        </div>
      </div>
    )
  }
}
