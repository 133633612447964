import React from 'react'

export default (props) => null
// <Elements stripe={stripePromise}>
//   <ElementsConsumer>
//     {({ stripe, elements }) => (
//       <PaymentForm {...props} stripe={stripe} elements={elements} />
//     )}
//   </ElementsConsumer>
// </Elements>
