import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'
import Loader from 'app/components/Loader'
import ReactFileReader from 'briefings/components/ReactFileReader'
import { Multiselect } from 'multiselect-react-dropdown'

@graphql(
  gql`
    query briefingDegreedSkillsQuery($id: ID!) {
      briefing(id: $id) {
        id
        name
        url
        degreedSkills {
          id
          degreedSkill
        }
        team {
          account {
            id
            degreedSkills {
              id
              degreedSkill
            }
          }
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation($briefingId: ID!, $skills: [ID!]) {
      updateBriefingDegreedSkills(briefingId: $briefingId, skills: $skills)
    }
  `,
  {
    props: ({ mutate }) => ({
      updateBriefingDegreedSkills: (briefingId, skills) =>
        mutate({
          variables: { briefingId, skills },
        }),
    }),
  },
)
export default class BriefingDegreedSkillsPage extends React.Component {
  constructor() {
    super()
    this.state = {
      uploading: false,
      allSkills: [],
      selectedSkills: [],
      filteredSkills: [],
      updateLoading: false,
    }
  }
 
  componentWillReceiveProps(newProps) {
    const mapAndSort = skills=>skills.map(({id, degreedSkill:name}) => ({
      id,
      name,
    })).sort(({name:nameA}, {name:nameB})=>nameA.toLowerCase()-nameB.toLowerCase())
    

    if (!this.props.data.briefing && newProps.data.briefing) {
      this.setState({
        allSkills: mapAndSort(newProps.data.briefing.team.account.degreedSkills),
        selectedSkills: mapAndSort(newProps.data.briefing.degreedSkills),
      })
    }
  }
  onSearch = (query) => {
    query = query ? query.toLowerCase() : ''
   
    if (query.length < 2) {
      this.setState({ filteredSkills: [] })
    } else {
      let filteredSkills = []
      for (let skill of this.state.allSkills) {
        if (skill.name.includes(query)) {
          filteredSkills.push(skill)
        }
      }
      filteredSkills.sort(({name:nameA},{name:nameB})=>{
        const nameALower = nameA.toLowerCase()
        const nameBLower = nameB.toLowerCase()
        const queryFirst = query[0]
        if(nameALower<nameBLower || nameBLower.startsWith(queryFirst)){
          return 1
        }else  if(nameBLower<nameALower || nameALower.startsWith(queryFirst)){
          return -1
        }else{
          return 0
        }
      })
      this.setState({ filteredSkills })
    }
  }
  saveBriefingSkills = () => {
    this.setState({
      updateLoading: true,
    })

    this.props
      .updateBriefingDegreedSkills(
        this.props.data.briefing.id,
        this.state.selectedSkills.map(({id})=>id),
      )
      .then(() => {
        this.setState({
          updateLoading: false,
        })
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `Degreed skills succesfully updated`,
        })
        browserHistory.push({
          pathname: this.props.data.briefing.url,
        })
      })
      .catch((err) => {
        this.setState({
          updateLoading: false,
        })
        console.log(err)
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          level: 'danger',
          content: `Sorry there was an error. Please try again or contact us.`,
        })
      })
  }
  render() {
    const { loading, briefing } = this.props.data
    const {filteredSkills, allSkills } = this.state
    const skillsList = Array.isArray(filteredSkills) && filteredSkills.length > 0 ? filteredSkills : allSkills
    return (
      <div style={{ maxWidth: 600 }}>
        <h5>Set the Degreed skills for this briefing</h5>
        <p>
          We will automatically tag content with these skills when pushing it
          into your Degreed instance
        </p>

        {loading && (
          <div className="mt-2">
            <p className="text-muted">
              Loading your available skills, please wait...
            </p>
            <Loader style={{ margin: '0.5rem', zoom: 0.5 }} />
          </div>
        )}
        {!loading && (
          <div>
            <p>
              Select one or more skills for briefing:{' '}
              <strong>{briefing.name}</strong>
            </p>
            <div className="mt-2 mb-2">
              <Multiselect
                options={skillsList}
                selectedValues={this.state.selectedSkills}
                onSearch={this.onSearch}
                onSelect={(selectedSkills) => this.setState({ selectedSkills })}
                onRemove={(selectedSkills) => this.setState({ selectedSkills })}
                displayValue="name"
                placeholder="Search for skills"
                hidePlaceholder={true}
              />
            </div>

            <div className="mt-4 d-flex justify-content-start align-items-start">
              <button
                className="btn btn-secondary mr-2"
                onClick={() => browserHistory.goBack()}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={this.saveBriefingSkills}
                disabled={this.state.updateLoading}
              >
                Save changes
              </button>
              {this.state.updateLoading && (
                <Loader style={{ margin: '0.5rem', zoom: 0.5 }} />
              )}
            </div>
            <div></div>
          </div>
        )}
      </div>
    )
  }
}
