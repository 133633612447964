import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { browserHistory } from 'react-router'

import ArticleListFullPage from 'articles/components/ArticleListFullPage'
import Article from 'articles/containers/Article'
import articleInfoFragment from 'articles/fragments/articleInfo'
import { DropdownMenu } from 'app/components/DropdownMenu'
import FixedModal from 'app/components/FixedModal'

import styles from './BoardPage.css'

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
  jwt: state.app.jwt,
}))
@graphql(
  gql`
    query ($id: ID!) {
      board(id: $id) {
        id
        name
        isPublic
        description
        image
        articles {
          ...articleInfo
        }
        userCanEdit
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
@graphql(
  gql`
    mutation ($board: BoardInput!) {
      updateBoard(board: $board) {
        id
        name
        isPublic
        description
        image
        articles {
          ...articleInfo
        }
        userCanEdit
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'updateBoard',
  },
)
@graphql(
  gql`
    mutation ($id: ID!) {
      deleteBoard(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteBoard: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($articleId: ID!, $boardId: ID!) {
      unSaveArticle(articleId: $articleId, boardId: $boardId) {
        ...articleInfo
        userSaves {
          boardId
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'unSaveArticle',
  },
)
export default class BoardPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      modalType: null,
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.board) {
      return <div>Loading...</div>
    }

    if (!this.props.data.board) {
      return <div>Sorry, you are not able to access this Folder.</div>
    }

    let canCsvExport = false

    if (
      this.props.appQueryResult &&
      this.props.appQueryResult.user &&
      !!this.props.appQueryResult.user.accounts.find(
        (account) => account.canCsvExport,
      )
    ) {
      canCsvExport = true
    }

    const board = this.props.data.board
    const { app: { layout: { isEmbedded } = {} } = {} } = this.props
    return (
      <div>
        <div className={styles.header}>
          <h5 className={styles.title}>
            {isEmbedded ? '' : 'Saved Folder: '}
            {board.name}
          </h5>
          <div>
            {board.userCanEdit && !isEmbedded ? (
              <DropdownMenu top={true}>
                <div className={styles.optionsMenu}>
                  <i className="fa fa-cog" />
                </div>
                <div>
                  <div
                    className={styles.dropdownLink}
                    onClick={() =>
                      this.setState({ isModalOpen: true, modalType: 'edit' })
                    }
                  >
                    Edit Folder
                  </div>
                  <div
                    className={styles.dropdownLink}
                    onClick={() =>
                      this.setState({ isModalOpen: true, modalType: 'delete' })
                    }
                  >
                    Delete Folder
                  </div>
                  {canCsvExport && (
                    <a
                      className={styles.dropdownLink}
                      href={`/folder-export/${board.id}/${this.props.jwt}`}
                      target="_blank"
                    >
                      Export Folder as CSV
                    </a>
                  )}
                </div>
              </DropdownMenu>
            ) : null}
          </div>
        </div>
        <br />

        {board.articles.length === 0 && (
          <div>You have no articles saved in this Folder yet.</div>
        )}

        <ArticleListFullPage>
          {board.articles.map((article, index) => (
            <Article
              key={index}
              article={article}
              onPinChange={() => {
                this.props.data.refetch()
              }}
              showActions={!isEmbedded}
              actions={[
                <span
                  onClick={() => {
                    return this.props
                      .unSaveArticle({
                        variables: {
                          articleId: article.id,
                          boardId: board.id,
                        },
                      })
                      .then((response) => {
                        // success
                        this.props.dispatch({
                          type: 'ADD_NOTIFICATION',
                          content: `Removed article from Folder`,
                        })
                        this.props.data.refetch()
                      })
                      .catch((err) => {
                        // error
                        this.props.dispatch({
                          type: 'ADD_NOTIFICATION',
                          level: 'danger',
                          content: `Sorry, there was an error removing this article`,
                        })
                        this.props.data.refetch()
                      })
                  }}
                >
                  Remove from Folder
                </span>,
              ]}
            />
          ))}
        </ArticleListFullPage>

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          {this.state.modalType === 'edit' && (
            <div style={{ padding: '15px' }}>
              <EditBoardForm
                initialValues={{
                  name: board.name,
                  isPublic: board.isPublic,
                }}
                onSubmit={(values) => {
                  return this.props
                    .updateBoard({
                      variables: {
                        board: {
                          id: board.id,
                          ...values,
                        },
                      },
                    })
                    .then((response) => {
                      // updated
                      this.setState({ isModalOpen: false })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Succesfully updated Folder`,
                      })
                    })
                    .catch((err) => {
                      throw new SubmissionError({
                        _error: err.graphQLErrors[0].message,
                      })
                    })
                }}
              />
            </div>
          )}
          {this.state.modalType === 'delete' && (
            <div style={{ padding: '15px' }}>
              <DeleteBoardForm
                onSubmit={(values) => {
                  return this.props
                    .deleteBoard(board.id)
                    .then((response) => {
                      // delete
                      browserHistory.push('/saved-articles')
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Succesfully deleted Folder`,
                      })
                    })
                    .catch((err) => {
                      throw new SubmissionError({
                        _error: err.graphQLErrors[0].message,
                      })
                    })
                }}
              />
            </div>
          )}
        </FixedModal>
      </div>
    )
  }
}

@reduxForm({
  form: 'editBoardForm',
})
class EditBoardForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Name of this Saved Folder</label>
          <Field
            name="name"
            component="input"
            type="text"
            className="form-control"
          />
        </div>

        <br />

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <button type="submit" className="btn btn-primary" disabled={submitting}>
          Save
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'deleteBoardForm',
})
class DeleteBoardForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Are you sure you wish to delete this Saved Folder?</p>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <button type="submit" className="btn btn-primary" disabled={submitting}>
          Delete
        </button>
      </form>
    )
  }
}
