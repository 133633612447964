import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'
import Loader from 'app/components/Loader'
import ReactFileReader from 'briefings/components/ReactFileReader'

// customImage

@graphql(
  gql`
    query($id: ID!) {
      briefing(id: $id) {
        id
        name
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation($briefingId: ID!, $image: String!, $type: String!) {
      updateBriefingImage(briefingId: $briefingId, image: $image, type: $type)
    }
  `,
  {
    props: ({ mutate }) => ({
      updateBriefingImage: (briefingId, image, type) =>
        mutate({
          variables: { briefingId, image, type },
        }),
    }),
    options: {
      refetchQueries: [],
    },
  },
)
export default class BriefingImagePage extends React.Component {
  constructor() {
    super()
    this.state = {
      uploading: false,
    }
  }
  render() {
    return (
      <div>
        <h5>Set a custom image for your briefing</h5>

        <p>
          Usually your briefing will display a random image from one of the top
          articles, but here you can manually override it with an uploaded
          image.
        </p>

        <p>Please select a PNG or JPEG, with a size under 2mb.</p>

        <ReactFileReader
          elementId="upload"
          multipleFiles={false}
          disabled={this.state.uploading}
          base64={true}
          fileTypes={['.png', '.jpg', '.jpeg']}
          handleFiles={(file) => {
            this.setState({
              uploading: true,
            })
            this.props
              .updateBriefingImage(
                this.props.data.briefing.id,
                file.base64,
                file.fileList[0].type,
              )
              .then(() => {
                this.setState({
                  uploading: false,
                })
                this.props.dispatch({
                  type: 'ADD_NOTIFICATION',
                  content: `Image successfully uploaded`,
                })
              })
              .catch((err) => {
                this.setState({
                  uploading: false,
                })
                this.props.dispatch({
                  type: 'ADD_NOTIFICATION',
                  content: `Sorry there was an error: ${err.message}`,
                  level: 'danger',
                })
              })
          }}
        >
          <button className="btn btn-primary">Choose image and upload</button>
        </ReactFileReader>

        <div>{this.state.uploading && <Loader />}</div>
      </div>
    )
  }
}
