import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { SubmissionError } from 'redux-form'
import axios from 'axios'

@reduxForm({
  form: 'createTeam',
})
@graphql(
  gql`
    mutation ($name: String) {
      createTeam(name: $name) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createTeam: (name) =>
        mutate({
          variables: { name },
        }),
    }),
  },
)
@connect((state) => ({
  user: state.app.appQueryResult.user,
}))
export default class CreateTeamPage extends React.Component {
  constructor() {
    super()
    this.state = {}
  }
  onSubmit = (values) => {
    return this.props
      .createTeam(values.name)
      .then((response) => {
        axios
          .get(
            `/iapi/auth/changeTeam?id=${
              response.data.createTeam.id
            }&time=${Date.now()}`,
          )
          .then((response) => {
            if (response.data.status === 'success') {
              window.location.href = '/team-settings?new=1'
            }
          })
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    // Can they create a team...

    // No if they've ran out...
    // No if they aren't on the team/legacy plan

    if (
      !this.props.user.subscription ||
      this.props.user.subscription.type === 'free'
    ) {
      return (
        <div>
          <h5>Create new team</h5>
          <br />
          <p>
            Start a{' '}
            <Link className="link" to="/subscription">
              free trial
            </Link>{' '}
            of the <strong>Team</strong> plan to create your team. You can then
            create some team briefings and invite other users.
          </p>
          <p>
            <Link to="/subscription" className="btn btn-primary btn-sm">
              See our team features
            </Link>
          </p>
        </div>
      )
    }

    return (
      <div>
        <h5>Create new team</h5>
        <br />

        <p>
          You will be the team owner and can invite other members after you've
          created the team.
        </p>

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-group">
            <label>Team name</label>
            <Field
              name="name"
              style={{ maxWidth: '300px' }}
              component="input"
              type="text"
              className="form-control"
              placeholder="Team name"
            />
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <button className="btn btn-primary">Create</button>{' '}
          <button
            className="btn btn-secondary"
            onClick={() => {
              window.location.href = '/home'
            }}
          >
            Cancel
          </button>
        </form>
      </div>
    )
  }
}
