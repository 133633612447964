import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Loader from 'app/components/Loader'
import PaymentForm from 'subscription/containers/PaymentForm'

const selector = formValueSelector('setupSubscription')

@compose(
  graphql(
    gql`
      query accountQuery($id: ID!) {
        account(id: $id) {
          id
          type
          name
          doesPlansetOverwrite
          forcePlansetInterval
          offeredPlanset {
            id
            name
            currency
            monthlyPlan {
              price
            }
            yearlyPlan {
              price
            }
          }
          customer {
            id
            country
            company
            line1
            line2
            region
            city
            postcode
            vatNumber
            firstName
            lastName
            email
            contactNumber
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.params.id,
          },
        }
      },
    },
  ),
  withProps((props) => ({
    initialValues: {
      interval: 'year',
    },
  })),
  reduxForm({
    form: 'setupAccountSubscription',
  }),
  connect((state, props) => ({})),
)
export default class SetupCustomAccountSubscriptionPage extends React.Component {
  constructor() {
    super()
    this.state = {
      successState: null,
    }
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    const account = this.props.data.account
    const planSet = account.offeredPlanset
    let interval = account.forcePlansetInterval

    if (this.state.successState === 'paid') {
      return (
        <div>
          <h5>New subscription setup successfully</h5>
          <br />
          <p>
            Thank you for purchasing the <strong>{planSet.name}</strong> plan.
          </p>
          <p>
            You can cancel at any point by{' '}
            <Link to="/contact" className="link">
              contacting us.
            </Link>
          </p>
          <p>Thanks, we hope you get value out of Anders Pink!</p>
        </div>
      )
    }

    return (
      <div>
        <h5>Setup and pay for new subscription</h5>

        <PaymentForm
          type="custom"
          planSet={planSet}
          customPlanSetId={planSet.id}
          doesPlansetOverwrite={account.doesPlansetOverwrite}
          freeTrial="no"
          interval={interval}
          onSuccess={(state) => this.setState({ successState: state })}
          customer={this.props.data.account.customer}
          accountId={account.id}
        />
      </div>
    )
  }
}
