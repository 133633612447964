import React from 'react'
import moment from 'moment'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import palette from 'app/library/palette'
import { Link } from 'react-router'

// don't show an average
// show per person stats...

const selector = formValueSelector('apiStats')

@compose(
  withProps((props) => ({
    initialValues: {
      type: 'day', // day, week, month, custom
      start: moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      end: moment().format('YYYY-MM-DD HH:mm:ss'),
    },
  })),
  reduxForm({
    form: 'apiStats',
  }),
  connect((state, props) => ({
    type: selector(state, 'type'),
    start: selector(state, 'start'),
    end: selector(state, 'end'),
  })),
  graphql(
    gql`
      query($id: ID, $start: String, $end: String) {
        team(id: $id) {
          id
          name
          articleApiOpens(start: $start, end: $end) {
            articleId
            name
            url
            numOpens
            type
            typeId
            typeName
            typeUrl
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.id,
            start: props.start,
            end: props.end,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
export default class ApiStats extends React.Component {
  constructor() {
    super()
    this.state = {
      time: null,
    }
  }
  render() {
    if (!this.props.data.team) {
      return null
    }

    const times = [
      {
        name: '1 day',
        start: moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: '3 days',
        start: moment().subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: '1 week',
        start: moment().subtract(1, 'week').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: '2 weeks',
        start: moment().subtract(2, 'weeks').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: '1 month',
        start: moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: '3 months',
        start: moment().subtract(3, 'months').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
    ]

    return (
      <div>
        <div>
          <h5 style={{ marginTop: 40 }}>Articles tracked through the API</h5>

          <div></div>

          <div className="btn-group">
            {times.map((item) => (
              <button
                key={item.name}
                type="button"
                className={`btn btn-sm btn-${
                  this.state.time === item.name ? 'primary' : 'secondary'
                }`}
                onClick={() => {
                  this.props.change('start', item.start)
                  this.props.change('end', item.end)
                  this.setState({
                    time: item.name,
                  })
                }}
              >
                {item.name}
              </button>
            ))}
          </div>

          <div className="mt-2" style={{ color: '#999' }}>
            Showing results from {moment(this.props.start).format('lll')} to{' '}
            {moment(this.props.end).format('lll')}
          </div>

          <div style={{ margin: '20px 0' }}>
            {this.props.data.team.articleApiOpens.length === 0 && (
              <div>No result found.</div>
            )}

            {this.props.data.team.articleApiOpens.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>Article</th>
                    <th>Source</th>
                    <th>Views</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.data.team.articleApiOpens.map(
                    (articleApiTrack, i) => (
                      <tr key={i}>
                        <td>
                          <div>{articleApiTrack.name}</div>
                          <div>
                            <a href={articleApiTrack.url} target="_blank">
                              {articleApiTrack.url}
                            </a>
                          </div>
                          <div style={{ fontSize: '0.8rem', color: '#AAA' }}>
                            #{articleApiTrack.articleId}
                          </div>
                        </td>
                        <td>
                          {!!articleApiTrack.type && (
                            <div>
                              {articleApiTrack.type === 'briefing'
                                ? 'Briefing'
                                : 'Saved folder'}
                              :{' '}
                              <Link to={articleApiTrack.typeUrl}>
                                {articleApiTrack.typeName}
                              </Link>
                            </div>
                          )}
                        </td>
                        <td>{articleApiTrack.numOpens}</td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }
}
