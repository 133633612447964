import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import articleInfoFragment from 'articles/fragments/articleInfo'
import { renderArticleList } from './renderArticleList'

@graphql(
  gql`
    query($id: ID!) {
      board(id: $id) {
        id
        articles {
          ...articleInfo
        }
        userCanEdit
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
export default class FolderArticleSelection extends React.Component {
  constructor() {
    super()
    this.state = {
      id: '',
    }
  }
  render() {
    if (this.props.data.loading) {
      return 'Loading articles..'
    } else {
      const {
        data: { board: { articles = [] } = {} },
        selectedArticles,
        onArticleClick,
      } = this.props
      return renderArticleList({ articles, selectedArticles, onArticleClick })
    }
  }
}
