exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TeamActivityPanel__articleTitle___3drfu {\n  text-decoration: underline;\n}\n.TeamActivityPanel__date___3PoNj {\n  color: #888;\n  font-size: 0.8em;\n}\n.TeamActivityPanel__activity___YZkFX {\n  border-bottom: 1px solid #ddd;\n}\n.TeamActivityPanel__activity___YZkFX:last-of-type {\n  border-bottom: 0 none;\n}\n.TeamActivityPanel__activityLink___vLK13 {\n  color: inherit;\n  text-decoration: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  cursor: pointer;\n  -webkit-transition: background-color 0.2s linear;\n  transition: background-color 0.2s linear;\n  padding: 10px 0;\n}\n.TeamActivityPanel__activityLink___vLK13:hover {\n  background-color: #f8f8f8;\n  color: inherit;\n  text-decoration: none;\n}\n.TeamActivityPanel__left___1waJ5 {\n  width: 35px;\n  margin-right: 10px;\n  margin-top: 3px;\n}\n.TeamActivityPanel__image___1Y6bx {\n  max-width: 100%;\n  border-radius: 8%;\n}\n.TeamActivityPanel__right___3mttQ {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"articleTitle": "TeamActivityPanel__articleTitle___3drfu",
	"date": "TeamActivityPanel__date___3PoNj",
	"activity": "TeamActivityPanel__activity___YZkFX",
	"activityLink": "TeamActivityPanel__activityLink___vLK13",
	"left": "TeamActivityPanel__left___1waJ5",
	"image": "TeamActivityPanel__image___1Y6bx",
	"right": "TeamActivityPanel__right___3mttQ"
};