import React from 'react'
import { Link } from 'react-router'

import styles from './CaseStudies.module.css'

const CaseStudies = (props) => {
  return (
    <section className={styles.caseStudies}>
      <header className={styles.csHeader}>{props.headerText}</header>
      <div className={styles.articles}>
        {props.studies.map((study) => (
          <Link
            to={study.link}
            key={study.title}
            className={styles.articleLink}
          >
            <article className={styles.article}>
              <img src={study.img} height="220px" />
              <p className={styles.articleCaption}>{study.caption}</p>
              <p className={styles.articleTitle}>{study.title}</p>
              <p className={styles.learnMore}>Learn more</p>
            </article>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default CaseStudies
