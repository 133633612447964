import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, compose, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { Router, Route, browserHistory } from 'react-router'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import createLogger from 'redux-logger'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import promiseMiddleware from 'redux-promise-middleware'
import createRouter from 'router'
import { ApolloProvider } from 'react-apollo'


import apolloClient from 'apolloClient'

import appReducer from 'app/reducers/appReducer'

// For IE 11 downards
require('es6-promise').polyfill()

// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]

      // 5. Let k be 0.
      var k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return k
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return -1.
      return -1
    },
  })
}

const axiosClient = axios.create({
  baseURL: '/iapi',
  responseType: 'json',
})

const reducers = {} // No other reducers yet

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Create store from all reducers
const store = createStore(
  combineReducers({
    ...reducers,
    app: appReducer,
    routing: routerReducer,
    form: formReducer,
  }),
  composeEnhancers(
    applyMiddleware(promiseMiddleware, axiosMiddleware(axiosClient)),
  ),
)

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store)

history.listen(function (location) {
  if (window && window.ga && location.pathname) {
    window.ga('set', 'page', location.pathname)
    window.ga('send', 'pageview', {
      dimension1: store.getState().app.userId === null ? 'guest' : 'user',
    })
  }
})

ReactDOM.render(
  <ApolloProvider client={apolloClient(store)}>
    <Provider store={store}>{createRouter(history)}</Provider>
  </ApolloProvider>,
  document.getElementById('mount'),
)
