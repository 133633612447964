exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NotificationIcon__articleTitle___3KNGx {\n  text-decoration: underline;\n}\n.NotificationIcon__date___2IkX1 {\n  color: #888;\n  font-size: 0.8em;\n}\n.NotificationIcon__activities___38-xr {\n  max-height: 400px;\n  overflow: scroll;\n}\n.NotificationIcon__activity___3stDC {\n  border-bottom: 1px solid #ddd;\n  font-size: 0.9em;\n  width: 315px;\n}\n.NotificationIcon__placeholderText___3Ykc3 {\n  padding: 10px;\n  font-size: 0.9em;\n}\n.NotificationIcon__activity___3stDC:last-of-type {\n  border-bottom: 0 none;\n}\n.NotificationIcon__activityLink___XC1UL {\n  color: inherit;\n  text-decoration: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  cursor: pointer;\n  -webkit-transition: background-color 0.2s linear;\n  transition: background-color 0.2s linear;\n  padding: 10px;\n}\n.NotificationIcon__activityLink___XC1UL:hover {\n  background-color: #f8f8f8;\n  color: inherit;\n  text-decoration: none;\n}\n.NotificationIcon__left___3MXmt {\n  width: 35px;\n  margin-right: 10px;\n  margin-top: 3px;\n}\n.NotificationIcon__image___KUeBa {\n  max-width: 100%;\n  border-radius: 8%;\n}\n.NotificationIcon__right___3__WB {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.NotificationIcon__team___3CAee {\n  color: #888;\n  font-size: 0.8em;\n}\n.NotificationIcon__notifictionCount___3C3ek {\n  width: 14px;\n  height: 14px;\n  background-color: red;\n  border-radius: 50%;\n  font-size: 9px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  color: #fff;\n  top: -2px;\n  right: -6px;\n  position: absolute;\n}\n", ""]);
// Exports
exports.locals = {
	"articleTitle": "NotificationIcon__articleTitle___3KNGx",
	"date": "NotificationIcon__date___2IkX1",
	"activities": "NotificationIcon__activities___38-xr",
	"activity": "NotificationIcon__activity___3stDC",
	"placeholderText": "NotificationIcon__placeholderText___3Ykc3",
	"activityLink": "NotificationIcon__activityLink___XC1UL",
	"left": "NotificationIcon__left___3MXmt",
	"image": "NotificationIcon__image___KUeBa",
	"right": "NotificationIcon__right___3__WB",
	"team": "NotificationIcon__team___3CAee",
	"notifictionCount": "NotificationIcon__notifictionCount___3C3ek"
};