import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

@connect((state) => ({
  app: state.app,
}))
export default class EnsureLoggedOut extends React.Component {
  componentDidMount() {
    if (this.props.app.userId !== null) {
      window.location.href = '/home'
    }
  }
  render() {
    if (this.props.app.userId === null) {
      return this.props.children
    } else {
      return null
    }
  }
}
