exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Comment__root___-72dZ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  position: relative;\n}\n.Comment__left___2DgCy {\n  width: 30px;\n  margin-right: 5px;\n  margin-top: 3px;\n}\n.Comment__image___2MeuF {\n  max-width: 100%;\n  border-radius: 8%;\n}\n.Comment__right___3ZvQF {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.Comment__time___1W6Hm {\n  color: #888;\n  font-size: 0.8em;\n}\n.Comment__menu___l0uNd {\n  position: absolute;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  opacity: 0;\n  background: -webkit-gradient(\n    linear,\n    right top, left top,\n    color-stop(40%, rgba(255, 255, 255, 1)),\n    to(rgba(255, 255, 255, 0))\n  );\n  background: linear-gradient(\n    to left,\n    rgba(255, 255, 255, 1) 40%,\n    rgba(255, 255, 255, 0) 100%\n  );\n  padding-left: 50px;\n  padding-right: 5px;\n  cursor: pointer;\n  color: #888;\n}\n.Comment__menu___l0uNd:hover {\n  opacity: 1;\n}\n\n.Comment__modal___35vVD {\n  padding: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Comment__root___-72dZ",
	"left": "Comment__left___2DgCy",
	"image": "Comment__image___2MeuF",
	"right": "Comment__right___3ZvQF",
	"time": "Comment__time___1W6Hm",
	"menu": "Comment__menu___l0uNd",
	"modal": "Comment__modal___35vVD"
};