import React from 'react'

const sourceTypes = {
  domain: {
    name: 'Site',
    icon: 'globe',
    description:
      'Brings in recent all articles from a given site, e.g. bbc.co.uk',
    label:
      'Enter a site (without the http:// or www.), or subdomains like bbc.com/news',
    placeholder: 'e.g. bbc.co.uk',
  },
  twitterUser: {
    name: 'Twitter User',
    icon: 'twitter',
    description:
      'Brings in all recent articles shared by a particular user on Twitter',
    label: 'Enter a twitter handle (without the @)',
    placeholder: 'e.g. kevinrose, smallbiztrends',
  },
  rssFeed: {
    name: 'RSS Feed',
    icon: 'rss',
    description: 'Brings in all articles from a particular RSS feed',
    label: 'Enter an RSS feed url',
    placeholder: 'e.g. http://feeds.reuters.com/reuters/UKTopNews',
  },
  topic: {
    name: 'Topic',
    icon: 'hashtag',
    description: 'Brings in recent all articles about a given topic',
    label: 'Enter a topic',
    placeholder: 'e.g. content marketing',
  },
}

export function renderSourceString(type) {
  return (
    <span>
      <i className={`fa fa-${sourceTypes[type].icon}`} aria-hidden="true" />{' '}
      {sourceTypes[type].name}
    </span>
  )
}

export default sourceTypes
