import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'

const Image = ({ url, text = null }) => {
  return (
    <div
      style={{
        margin: '30px 0',
        width: '700px',
        maxWidth: '100%',
      }}
    >
      <div
        style={{
          boxShadow: '0px 0px 2px rgba(0,0,0,0.5)',
          padding: '20px',
          backgroundColor: '#FFF',

          marginBottom: '10px',
        }}
      >
        <img src={`/api-images/${url}`} style={{ maxWidth: '100%' }} />
      </div>

      {!!text && (
        <div style={{ fontSize: '0.8em', textAlign: 'center' }}>
          Example of Briefings and Boards in a Learning Platform
        </div>
      )}
    </div>
  )
}

@connect((state) => ({
  app: state.app,
}))
export default class ApiInfo extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundImage: `url(${STATIC_DOMAIN}/pages/landing/plugin-header.png)`,
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Anders Pink Display API</div>
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.section}>
            <div className={pageStyles.sectionHeading}>The Challenge</div>

            <div className={pageStyles.textContainer}>
              <p>
                We all need to stay up to date and continuously learn on the
                topics that matter. Courses alone won't cut it. You need to
                provide fresh and relevant content every day.
              </p>

              <p>Easy to say. Harder to do in practice.</p>

              <p>
                Finding the most relevant content from millions of potential
                sources takes time. Curating, filtering and embedding it in your
                Platform takes manual effort.
              </p>

              <p>
                You don't have time to do it every day, for every topic. But
                it's what your learners want.
              </p>

              <p>Let's make it easier.</p>
            </div>
          </div>

          <div className={styles.section}>
            <div className={pageStyles.sectionHeading}>Our Solution</div>

            <div className={pageStyles.textContainer}>
              <p>
                The Anders Pink API allow you to bring relevant content directly
                into your LMS, Learning Platform or wherever you want to see it.
              </p>

              <p>
                You can use our display API to make briefings on Anders Pink and
                embed them in your platform or app. Or use our Full API or
                Javascript SDK to embed Anders Pink curation functionality
                directly into your app.
              </p>

              <p>
                We use powerful filtering to provide the content you want, from
                any site or source, e.g.
              </p>

              <ul>
                <li>
                  Bring me the latest tips on selling from these 20 sites,
                  Twitter users and RSS feeds
                </li>
                <li>
                  Bring me the latest articles on leading teams from 150
                  business sites
                </li>
                <li>
                  Bring me content on GDPR that also mention HR or Learning
                </li>
              </ul>

              <Image url="briefing1.png" />

              <ul>
                <li>
                  Stay up to date: Updated every few hours with fresh content
                  based on your filters.
                </li>
                <li>
                  Save time: you don't need to keep checking 30 different
                  sources, it's all in one place.{' '}
                </li>
                <li>
                  Stay smart: Our AI learns what you like and intelligently
                  refines your articles. It gets smarter, so do you and your
                  learners.
                </li>
              </ul>

              <Image url="flow1.png" />
            </div>
          </div>

          <div className={styles.section}>
            <div className={pageStyles.sectionHeading}>
              Sample Use Cases for the API
            </div>

            <div className={pageStyles.textContainer}>
              <ul>
                <li>
                  Embed trending content on your Company or sector in your
                  Learning Platform home page
                </li>
                <li>
                  Display relevant content from around the web mapped to your
                  courses and competencies
                </li>
                <li>
                  Display the latest articles from your preferred sites and
                  sources on leadership as part of a leadership area in your LMS
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.section}>
            <div className={pageStyles.sectionHeading}>
              Already Plugged Into Leading Platforms
            </div>

            <div className={pageStyles.textContainer}>
              <p>
                Our API is already integrated with leading platforms and tools,
                and is easy to set up for others:
              </p>
              <br />
              <div className={styles.icons}>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon2.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon3.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon4.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon5.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon6.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon7.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon8.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon9.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon10.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon11.png`} />
                </div>
                <div className={styles.iconImage}>
                  <img src={`/api-images/icon12.png`} />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={pageStyles.sectionHeading}>
              What people are saying
            </div>

            <div className={styles.quotes}>
              <div className={styles.quote}>
                "Anders Pink is really useful tool. We use it on the platforms
                we design. Access to constantly updated, well targeted and easy
                to find articles make the LMS a useful destination for the
                learner, encouraging repeat visits to a platform which might
                otherwise only be used annually to complete a piece of
                compliance training."
                <br />
                <br />
                <br />
                Paul Welch
                <br />
                Solutions Consultant
                <br />
                Kineo
                <img
                  className={styles.profilePic}
                  src={`/api-images/profile1.png`}
                />
              </div>

              <div className={styles.quote}>
                "We're really excited about how we can use Anders pink to curate
                resources in real time for our audience, to support our blended
                content strategy. Either embedded into our eLearning, or as
                resources in a topic on our learning portal, we can bring the
                hot topics to learners, whilst also being able to refine what
                they see, to ensure it's relevant."
                <br />
                <br />
                <br />
                Sam Taylor
                <br />
                Digital Development Manager
                <br />
                Hitachi Rail Europe
                <img
                  className={styles.profilePic}
                  src={`/api-images/profile2.png`}
                />
              </div>

              <div className={styles.quote}>
                "Our members ask more and more about content curation and how it
                could be made easier. To answer this demand and to help with
                continuous learning, we started incorporating Anders Pink. It’s
                absolutely brilliant tool and is now a key part of our blended
                learning solution. Why spend hours searching when Anders Pink
                can do it for you?"
                <br />
                <br />
                <br />
                Martin Baker
                <br />
                CEO
                <br />
                Charity Learning Consortium
                <img
                  className={styles.profilePic}
                  src={`/api-images/profile3.png`}
                />
              </div>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className={styles.section}>
            <div className={styles.users}>
              <div>
                <h4>For L&D Teams:</h4>

                <ul>
                  <li>
                    Intelligently curate content for learning with less effort
                  </li>
                  <li>Save time and cost - no per learner fees for plugins</li>
                  <li>
                    Build learner engagement with a fresh feed of relevant
                    content, right in your platforms
                  </li>
                </ul>
              </div>
              <div>
                <h4>For LMS/Platform Providers:</h4>

                <ul>
                  <li>
                    Easily integrate a powerful curation tool into your platform
                  </li>
                  <li>
                    Add value to your platform and content with intelligent
                    recommendations
                  </li>
                  <li>
                    Grow your business with curation services for your clients
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className={styles.section} style={{ textAlign: 'center' }}>
            <h4>Get In Touch for A Free API Trial!</h4>

            <p>
              Want to try our API? Get in touch for a free trial and more
              information.
            </p>

            <p>
              <Link className="btn btn-primary" to="/contact">
                Contact us
              </Link>
            </p>

            <p>
              Developers:{' '}
              <Link className="link" to="/api-docs">
                API documentation is here
              </Link>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
