import React from 'react'
import { connect } from 'react-redux'

import EBookBanner from './components/EBookBanner/EBookBanner'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import PartnersCarousel from './components/PartnersCarousel/OldPartnersCarousel'
import CTA from './components/CTA/CTA'
import Video from './components/Video/Video'
import PartnersList from './components/PartnersList/PartnersList'
import Testimonials from './components/Testimonials/Testimonials'
import HowItWorks from './components/HowItWorks/HowItWorks'
import CaseStudies from './components/CaseStudies/CaseStudies'
import EBookForm from './components/EBookForm/EBookForm'
import Footer from './components/Footer/Footer'

import enterpriseLogos from './logos/enterpriseLogos'
import platformsLogos from './logos/platformsLogos'

import styles from './EnterpriseHomepage.module.css'

@connect((state) => ({
  app: state.app,
}))
export default class EnterpriseHomepage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.outerBody}>
        <div className={styles.innerBody}>
          <main className={styles.home}>
            <EBookBanner bannerColor="white" externalLink={false} />
            <div className={styles.background1}>
              <Navbar
                backgroundColors="dark"
                pinkOrGreen="green"
                logoColor="white"
              />
              <Intro
                headerText="Curate fresh content to power your learning ecosystem"
                bullet1="High quality content on any topic"
                bullet2="Automate and save time"
                bullet3="Help teams upskill and stay smart"
                headerColor="white"
                bulletPointColor="white"
                bulletTextColor="white"
                homepage="enterprise"
              />
              <div className={styles.background1Inner}>
                <PartnersCarousel
                  headerText="Making our partners and clients heroes"
                  logos={enterpriseLogos}
                />
              </div>
            </div>
            <CTA
              headerText="It shouldn't be so hard to find, organise and deliver the best web content for your learners"
              img={`${STATIC_DOMAIN}/pages/new-homepage/ctaEnterprise.png`}
              bullets={[
                {
                  text: 'Struggling to consistently provide relevant insights and learning content to your teams?',
                  key: 1,
                },
                {
                  text: 'Need fresh content to drive engagement within your learning ecosystem?',
                  key: 2,
                },
              ]}
              p2Text="Anders Pink's AI-powered content curation tool automates the delivery of fresh content from the web, directly into the platforms you're already using."
            />
            <Video bgColor="pink" />
            <PartnersList
              headerText="Partnering with the world's leading learning platforms"
              logos={platformsLogos}
            />
            <Testimonials withOldHeader={true} />
            <HowItWorks
              stepContent={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw4.png`,
                  headerText: '1. Discover',
                  bodyText:
                    "We'll work with you to identify how automated curation best fits with your platform and learning needs.",
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw5.png`,
                  headerText: '2. Curate',
                  bodyText:
                    'Get curating instantly with fresh, customized and ready-to-go content feeds on the skills and topics that matter most.',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw6.png`,
                  headerText: '3. Launch',
                  bodyText:
                    'Save time, effort and budget as you empower your learners to upskill and stay smart. Deliver value to your business every day.',
                },
              ]}
            />
            <CaseStudies
              headerText="Discover how other enterprise clients are automating content curation"
              studies={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-ei-logo.png`,
                  caption: 'Enterprise Ireland case study',
                  title:
                    'Driving engagement with fresh content embedded into learning platform',
                  link: '/client-success-stories/enterprise-ireland',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-scania-logo2.png`,
                  caption: 'Scania case study',
                  title:
                    'Driving engagement by supporting the business through rapid change',
                  link: '/client-success-stories/scania',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/chalhoub-logo.png`,
                  caption: 'Chalhoub case study',
                  title:
                    'Enhancing employee learning opportunities with curated content',
                  link: '/client-success-stories/chalhoub',
                },
              ]}
            />
            <EBookForm />
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}
