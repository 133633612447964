import React from 'react'
import { Link } from 'react-router'

export default class SlackPage extends React.Component {
  render() {
    return (
      <div>
        <h3>
          Get Daily Insights In Your Slack Channels and Teams: Anders Pink Slack
          Integration
        </h3>
        <br />

        <div
          style={{
            margin: '30px 0',
            width: '900px',
            maxWidth: '100%',
          }}
        >
          <div
            style={{
              boxShadow: '0px 0px 12px rgba(0,0,0,0.5)',
              padding: '4px',
              backgroundColor: '#FFF',

              marginBottom: '10px',
            }}
          >
            <img
              src="https://static.anderspink.com/slack/slack1.png"
              style={{ maxWidth: '100%' }}
            />
          </div>
        </div>

        <p>
          <a href="https://slack.com" className="link" target="_blank">
            Slack
          </a>{' '}
          is changing how teams collaborate and work smarter. Over 6 million
          people use it every day to streamline and simplify their workflow.
        </p>

        <p>
          To make it even better, we wanted to make it easy to stay up to date
          in Slack with the latest articles on the trends and topics you and
          your teams care about.
        </p>

        <p>That's what our Anders Pink Slack integration does.</p>

        <br />
        <h5>Back up - What is Anders Pink?</h5>

        <p>
          <a href="/c/product" className="link" target="_blank">
            Anders Pink
          </a>{' '}
          is a content curation tool that makes it easy to stay up to date on
          the topics that you care about. Create briefings (collections of
          articles) on any topic. Filter by keywords, sites, twitter handles and
          RSS feeds. Briefings refresh every few hours, so you're always up to
          date. See more on our{' '}
          <Link to="/about" className="link">
            about page
          </Link>
          .
        </p>

        <br />
        <h5>Why integrate with Slack?</h5>
        <p>
          Get your daily intelligence briefing where you're already doing your
          work - in Slack.
        </p>
        <p>
          With our Slack integration you'll get a daily briefing of the latest
          articles on any topic you want in in any Slack channel, with fresh
          content every day.
        </p>
        <p>Stay smarter as a team in Slack with:</p>
        <ul>
          <li>
            Competitor Intelligence: see the latest articles from your top
            competitors in one place.
          </li>
          <li>
            Client Insights: What's new in your client's business? Track them in
            Slack.
          </li>
          <li>
            Daily tips: get daily{' '}
            <Link
              to="/briefing/2596/sales-latest-thinking-from-top-blogs"
              className="link"
            >
              sales
            </Link>{' '}
            or{' '}
            <Link to="/briefing/2237/leadership" className="link">
              leadership
            </Link>{' '}
            tips - learn something new every day.
          </li>
          <li>
            Trending content: stay on top of any trend:{' '}
            <Link to="/briefing/571/big-data-news" className="link">
              Big Data
            </Link>
            ,{' '}
            <Link to="/briefing/34/ai-and-virtual-reality" className="link">
              AI
            </Link>{' '}
            - whatever you want to track.
          </li>
        </ul>

        <br />

        <h5>I want that. What now? </h5>
        <ol>
          <li>
            <Link to="/contact" className="link">
              Create an account
            </Link>{' '}
            on Anders Pink, or{' '}
            <Link to="/login" className="link">
              login
            </Link>
            .
          </li>
          <li>
            Create a new briefing on any topic, or follow some public ones.{' '}
            <a
              href="https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be"
              className="link"
              target="_blank"
            >
              Here's how
            </a>
            .
          </li>
          <li>
            Go to your{' '}
            <Link to="/profile/slack" className="link">
              {' '}
              Slack Settings Page on Anders Pink
            </Link>{' '}
            and activate the Slack integration.
            <br />
            <div
              style={{
                margin: '15px 5px',
                width: '400px',
                maxWidth: '100%',
              }}
            >
              <div
                style={{
                  boxShadow: '0px 0px 12px rgba(0,0,0,0.5)',
                  padding: '20px',
                  backgroundColor: '#FFF',

                  marginBottom: '10px',
                }}
              >
                <img
                  src="https://static.anderspink.com/slack/slack2.png"
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </div>
          </li>
          <li>
            Choose which briefings you want to post to the channel.
            <div
              style={{
                margin: '15px 5px',
                width: '600px',
                maxWidth: '100%',
              }}
            >
              <div
                style={{
                  boxShadow: '0px 0px 12px rgba(0,0,0,0.5)',
                  padding: '20px',
                  backgroundColor: '#FFF',

                  marginBottom: '10px',
                }}
              >
                <img
                  src="https://static.anderspink.com/slack/slack3.png"
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </div>
          </li>
          <li>
            All done! Now everyone in that channel will get a daily update with
            the top 3 articles from each briefing you selected. Slack (and you)
            just got smarter!
          </li>
        </ol>
        <br />

        <p>
          What do you think? Any questions, ways to improve this beta? Just{' '}
          <Link to="/contact" className="link">
            contact
          </Link>{' '}
          us - we'd love to hear from you.{' '}
        </p>
      </div>
    )
  }
}
