import React from 'react'
import { connect } from 'react-redux'
import { postRequest } from 'app/actions/appActions'

@connect()
export default class LoginPage extends React.Component {
  static defaultProps = {
    linkAccount: false,
  }
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }
  onTwitterLoginPress = () => {
    this.setState({
      loading: true,
    })
    this.props
      .dispatch(postRequest('/auth/twitter-login-url'))
      .then((response) => {
        this.setState({
          loading: false,
        })
        if (response.payload.data.status === 'success') {
          window.location = response.payload.data.url
        } else {
          window.alert(
            'Sorry there was an issue logging you in, please try again',
          )
        }
      })
  }
  render() {
    if (this.props.linkAccount) {
      return (
        <button className="btn btn-primary" onClick={this.onTwitterLoginPress}>
          <i className="fa fa-twitter" aria-hidden="true" />{' '}
          <span>
            {this.state.loading ? 'Logging in...' : `Link your Twitter account`}
          </span>
        </button>
      )
    }

    return (
      <button
        className="btn btn-primary btn-lg"
        onClick={this.onTwitterLoginPress}
      >
        <i className="fa fa-twitter" aria-hidden="true" />{' '}
        <span>
          {this.state.loading ? 'Logging in...' : `Login with Twitter`}
        </span>
      </button>
    )
  }
}
