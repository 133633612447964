import React from 'react'

import styles from './Video.module.css'

const Video = (props) => {
  let sectionClass

  if (props.bgColor === 'green') {
    sectionClass = `${styles.videoBg} ${styles.greenBg}`
  } else if (props.bgColor === 'pink') {
    sectionClass = `${styles.videoBg} ${styles.pinkBg}`
  }

  return (
    <section className={sectionClass}>
      <div className={styles.video}>
        <iframe
          src="https://player.vimeo.com/video/775076007?h=cd9768c1f6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Anders Pink Home Page"
          className={styles.videoPlayer}
        />
        <script src="https://player.vimeo.com/api/player.js" />
      </div>
    </section>
  )
}

export default Video
