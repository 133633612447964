import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

import 'whatwg-fetch'

let apolloClient

export default function (store) {
  if (!apolloClient) {
    const httpLink = createHttpLink({
      uri: '/iapi/graphql',
    })

    const authLink = setContext((_, { headers }) => {
      const token = store.getState().app.jwt
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : null,
        },
      }
    })

    apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      queryDeduplication: false,
      cache: new InMemoryCache({
        dataIdFromObject: (result) => {
          if (result.__typename === 'TeamStatEntity') return null
          if (result.id && result.__typename) {
            return result.__typename + String(result.id)
          }
          // Make sure to return null if this object doesn't have an ID
          return null
        },
      }),
    })
  }
  return apolloClient
}
