import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import BoardNameBlock from 'boards/components/BoardNameBlock'
import BriefingNameBlockList from 'briefings/components/BriefingNameBlockList'
import styles from './AllBoardsPage.css'
import AddBoardForm from 'boards/containers/AddBoardForm'

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
export default class AllBoardsPage extends React.Component {
  render() {
    let boards = this.props.appQueryResult.boards.slice(0)
    boards.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })

    return (
      <div>
        <h5 className={styles.title}>Your Saved Article Folders</h5>

        <AddBoardForm />

        {boards.length > 0 && (
          <BriefingNameBlockList>
            {boards.map((board, index) => (
              <div key={index}>
                <BoardNameBlock board={board} />
              </div>
            ))}
          </BriefingNameBlockList>
        )}

        {boards.length === 0 && <p>You have no Folders at the moment.</p>}
      </div>
    )
  }
}
