import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import briefingInfoFragment from 'briefings/fragments/briefingInfo'
import EditBriefingForm from 'briefings/create/containers/EditBriefingForm'
import { browserHistory } from 'react-router'

@graphql(
  gql`
    query ($id: ID!) {
      briefing(id: $id) {
        id
        name
        userCanEdit
        userCanDelete
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
@graphql(
  gql`
    mutation ($id: ID!) {
      deleteBriefing(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteBriefing: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
export default class DeleteBriefingPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.data.loading) {
      return false
    }

    const briefing = this.props.data.briefing
    if (!briefing.userCanDelete) {
      return (
        <div>
          <span>Sorry, you don't have permission to delete this briefing</span>
        </div>
      )
    }

    return (
      <div>
        <p>Are you sure you want to delete the briefing '{briefing.name}'?</p>
        <p>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.props.deleteBriefing(briefing.id).then(() => {
                browserHistory.push('/briefings')
              })
            }}
          >
            Delete
          </button>{' '}
          <button
            className="btn btn-secondary"
            onClick={() => {
              browserHistory.goBack()
            }}
          >
            Cancel
          </button>
        </p>
      </div>
    )
  }
}
