import React from 'react'
import styles from './LoginPage.css'
import { Link } from 'react-router'
import CenterPage from 'app/components/CenterPage'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { postRequest } from 'app/actions/appActions'
import { browserHistory } from 'react-router'
import NewPasswordInput from 'authentication/containers/NewPasswordInput'

@connect()
@reduxForm({
  form: 'setNewPassword',
})
@graphql(
  gql`
    mutation ($userId: ID!, $token: String!) {
      validatePasswordResetToken(userId: $userId, token: $token)
    }
  `,
  {
    props: ({ mutate }) => ({
      validatePasswordResetToken: (userId, token) =>
        mutate({
          variables: {
            userId,
            token,
          },
        }),
    }),
  },
)
export default class SetNewPasswordPage extends React.Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      validationError: null,
    }
  }
  componentDidMount() {
    this.props
      .validatePasswordResetToken(
        this.props.params.userId,
        this.props.params.token,
      )
      .then((response) => {
        this.setState({
          checked: true,
        })
      })
      .catch((err) => {
        this.setState({
          checked: true,
          validationError: err.graphQLErrors[0].message,
        })
      })
  }
  onSubmit = (values) => {
    return this.props
      .dispatch(
        postRequest('/auth/login-via-password-reset', {
          userId: this.props.params.userId,
          token: this.props.params.token,
          password: values.password,
        }),
      )
      .then((response) => {
        const data = response.payload.data
        if (data.status === 'fail' || data.status === 'error') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })
          window.location.href = '/home'
        }
      })
  }
  render() {
    if (!this.state.checked) {
      return <CenterPage maxWidth="500px">Checking...</CenterPage>
    }

    if (this.state.validationError !== null) {
      return (
        <CenterPage maxWidth="500px">
          <h5>Set new password</h5>
          <br />
          <div
            className="alert alert-danger"
            role="alert"
            style={{ margin: '10px 0' }}
          >
            {this.state.validationError}
          </div>
          <Link className="link" to="/reset-password">
            Continue
          </Link>
        </CenterPage>
      )
    }

    const { error, handleSubmit, submitting } = this.props

    return (
      <CenterPage maxWidth="500px">
        <h5>Set a new password</h5>
        <br />
        <p>
          Choose a new password and we'll log you in. Passwords must be 8+
          characters.
        </p>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="mb-2">
            <NewPasswordInput
              className="form-control mr-1"
              placeholder="Your new password"
            />
          </div>

          <button className="btn btn-primary">Set password</button>
        </form>

        {error && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{ margin: '10px 0' }}
          >
            {error}
          </div>
        )}
      </CenterPage>
    )
  }
}
