import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './FriendsPanel.css'
import panelStyles from './Panel.css'
import Article from 'articles/containers/Article'
import articleInfoFragment from 'articles/fragments/articleInfo'
import { Link } from 'react-router'
import Loader from 'app/components/Loader'

@graphql(
  gql`
    query {
      friendShares(limit: 10, timeLimit: "12-hours", offset: 0) {
        doingInitialLoad
        articles {
          ...articleInfo
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
export default class FriendsPanel extends React.Component {
  componentWillReceiveProps(nextProps) {
    clearTimeout(this.timeoutId)
    if (
      nextProps.data.friendShares &&
      nextProps.data.friendShares.doingInitialLoad
    ) {
      this.timeoutId = setTimeout(() => {
        this.props.data.refetch()
      }, 2000)
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.friendShares) {
      return <div>Loading...</div>
    }

    if (!this.props.data.friendShares) {
      return <div>Nothing to see here yet..</div>
    }

    return (
      <div>
        {this.props.data.friendShares.doingInitialLoad && (
          <div>
            Please wait while we populate your feed from twitter, this may take
            a few minutes initially
            <Loader />
          </div>
        )}
        {!this.props.data.friendShares.doingInitialLoad && (
          <div>
            {this.props.data.friendShares.articles.length === 0 && (
              <div>
                It may take a few minutes for your first articles to start
                appearing, please refresh shortly!
              </div>
            )}
            {this.props.data.friendShares.articles.map((article, index) => (
              <div key={index} className={styles.row}>
                <Article article={article} />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
