import React, { Component } from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { FORM_NAMES } from '../constants'

import Tour from 'reactour'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state, props) => ({
  ...selector(
    state,
    'type',
    'keywordsV2Fields.topics',
    'sourcesV2Fields.sources',
  ),
}))
export default class TourContainer extends Component {
  constructor(props) {
    super()
    this.state = {
      shouldShowTour: props.isCreating,
      isTourOpen: false,
      wasManuallyOpened: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.type === 'keywordsV2' &&
      prevProps.keywordsV2Fields.topics.length === 0 &&
      this.props.keywordsV2Fields.topics.length &&
      this.state.shouldShowTour === true
    ) {
      this.setState({ shouldShowTour: false })
      setTimeout(() => this.setState({ isTourOpen: true }), 750)
    }
  }
  render() {
    const { type, keywordsV2Fields, sourcesV2Fields } = this.props
    const { wasManuallyOpened } = this.state

    const hasTopics = keywordsV2Fields.topics.length > 0
    const hasSources = sourcesV2Fields.sources.length > 0

    const hasStarted =
      (type === 'keywordsV2' && hasTopics) ||
      (type === 'sourcesV2' && hasSources)

    const sourceDomainsCount = sourcesV2Fields.sources.filter(
      (s) => s.type === 'domain',
    ).length

    const filterToSites =
      type === 'sourcesV2' &&
      sourceDomainsCount > 0 &&
      sourcesV2Fields.sources.length > sourceDomainsCount

    const steps = []

    if (type === 'keywordsV2') {
      if (wasManuallyOpened) {
        if (!hasTopics) {
          steps.push({
            selector: '#briefing-type',
            content: (
              <>
                <p>
                  Simple briefings are created by adding your top level topics
                  and then optionally filtering and training. We recommend
                  sticking with this.
                </p>
                <p>
                  Advanced briefings let you start with your sources if you know
                  them, such as certin sites or RSS feeds. You're able to then
                  filter the content and train the briefing.
                </p>
              </>
            ),
          })
        }
        steps.push({
          selector: '#briefing-topics',
          content: (
            <>
              <p>
                Your topics are used to find relevant articles by searching the
                titles and body text.
              </p>
              <p>
                If the articles coming back in your preview are too broad, try
                using more specific search terms.
              </p>
              <p>You may add or remove topics to improve your results.</p>
            </>
          ),
        })
      } else {
        steps.push({
          selector: '#briefing-topics',
          content: (
            <>
              <p>Great, you've entered your first topic!</p>
              <p>
                If the articles coming back in your preview are too broad, try
                using more specific search terms.
              </p>
              <p>You may add or remove topics to improve your results.</p>
            </>
          ),
        })
      }
    }

    if (type === 'sourcesV2') {
      steps.push({
        selector: '#briefing-sources',
        content: (
          <>
            <p>
              Manually pick which sites, RSS feeds and topics to get content
              from.
            </p>
            <p>
              We'll show you everything that we can find in a combined list of
              articles.
            </p>
          </>
        ),
      })
    }

    steps.push({
      selector: '#briefing-preview',
      content: hasStarted ? (
        <>
          <p>
            Your preview shows the type of articles you'll get back once you
            save your briefing.
          </p>
          <p>
            To improve results further, you may click the upvote and downvote
            buttons.
          </p>
        </>
      ) : (
        <>
          <p>
            Your preview will appear here with the type of articles you'll get
            back once you save your briefing.
          </p>
          <p>
            To improve results further, you may click the upvote and downvote
            buttons on any article.
          </p>
        </>
      ),
    })

    if (hasStarted) {
      steps.push({
        selector: '#briefing-training',
        content: (
          <>
            <p>
              After upvoting good articles and downvoting bad ones, you will see
              the progress of your briefing training below.
            </p>
            <p>
              Once you feel you have upvoted and downvoted enough articles (we
              recommend at least 30), refresh your preview to see your new
              articles.
            </p>
            <p>
              We'll push the most relevant articles to the top, and filter out
              the least relevant.
            </p>
          </>
        ),
      })
    }

    if (type === 'keywordsV2' && hasTopics) {
      steps.push({
        selector: '#briefing-sites',
        content: (
          <>
            <p>
              If there are some sites you want to fully remove, or if you'd only
              like results from a limited set of sites, you can manage this
              here.
            </p>
          </>
        ),
      })
    }
    if (type === 'sourcesV2' && hasSources) {
      steps.push({
        selector: '#briefing-keywords',
        content: (
          <>
            <p>
              You can filter your results down by entering keywords that must
              appear somewhere in the article title or body text.
            </p>
          </>
        ),
      })

      if (filterToSites) {
        steps.push({
          selector: '#briefing-filter-to-sites',
          content: (
            <>
              <p>
                As you've got different types of sources, you can filter the RSS
                feed/topics to only show results from the sites that you've
                selected.
              </p>
              <p>This can be useful in some advanced use-cases.</p>
            </>
          ),
        })
      }
    }

    return (
      <>
        <span
          className="link"
          style={{ fontSize: '0.7rem', marginLeft: '0.5rem' }}
          onClick={() =>
            this.setState({ isTourOpen: true, wasManuallyOpened: true })
          }
        >
          Open tour
        </span>

        {this.state.isTourOpen && (
          <Tour
            accentColor="#cf0032"
            steps={steps}
            isOpen={this.state.isTourOpen || false}
            onRequestClose={() =>
              this.setState({ isTourOpen: false, wasManuallyOpened: false })
            }
          />
        )}
      </>
    )
  }
}
