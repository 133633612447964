import gql from 'graphql-tag'

export default gql`
  query appQuery {
    session {
      maintenance
      showRedirectionFlag
      numAvailableBriefings
      numAvailableBoards
      degreedSSO
      user {
        id
        firstName
        lastName
        email
        image
        isTwitterConnected
        isSiteAdmin
        receiveDailyDigest
        receiveNewsletters
        numOwnedTeams
        numOwnedBriefings
        numOwnedBoards
        signupDate
        lastCheckedNotificationsAt
        digestBriefingIds
        timezone
        digestTime
        slackIntegrations {
          id
          channelName
          articleQuantity
          briefings {
            id
            name
          }
        }
        msTeamsIntegrations {
          channelId
          frequency
          time
          timeZone
          articleQuantity
          briefings {
            id
            name
          }
        }
        teams {
          id
          name
        }
        boards {
          id
          name
          url
        }
        customPlanSets {
          id
          name
          monthlyPlan {
            id
            price
          }
          yearlyPlan {
            id
            price
          }
        }
        accounts {
          id
          type
          name
          canCsvExport
          teams {
            id
            name
          }
          users {
            id
            firstName
            lastName
          }
        }
      }
      team {
        id
        name
        exportLink
        canUseLooseSearch
        canUsersEditDomainLists
        useNewBriefingTypes
        trackingEnabled
        account {
          canCsvExport
          useNewBriefingTypes
          activeSubscriptions {
            id
            plans {
              id
              planSet {
                id
                name
                canPubliclyExport
              }
            }
          }
        }
        apiUser {
          id
        }
      }
      isTeamAdmin
      canEditBriefings
      canEditBoards
      canSaveArticles
      canDeleteArticles
      briefings {
        id
        name
        url
        linkedBriefingId
        position
      }
      boards {
        id
        name
        url
      }
      api {
        hasAccess
        key
        boards {
          id
          name
        }
        briefings {
          id
          name
        }
      }
      organisations {
        id
        name
      }
    }
  }
`
