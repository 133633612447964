import gql from 'graphql-tag'

const GET_TEAM = gql`
  query digestTeamQuery {
    session {
      team {
        id
        teamUsers {
          user {
            id
            firstName
            lastName
            email
            lastActiveDate
          }
          level
        }
        ownedBriefings {
          id
          name
        }
      }
      isTeamAdmin
    }
  }
`

export default GET_TEAM
