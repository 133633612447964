import React from 'react'
import styles from './APIDocumentationPage.css'
import { Link } from 'react-router'

export default class APIDocumentationV1Page extends React.Component {
  render() {
    return (
      <div>
        <h4>API documentation</h4>

        <br />
        <div className="alert alert-danger">
          This is the documentation for v1 of the API. Documentation for the
          newest version of the API (v3) is found{' '}
          <Link to="/api-docs" className="link">
            here
          </Link>
          .
        </div>

        <p>
          For high level information about the API and plans/pricing, please see
          our{' '}
          <Link to="/api-info" className="link">
            API information page
          </Link>
          .
        </p>

        <p>
          To make any requests you'll need an API account. API accounts are tied
          either to your user or one of your teams. Please{' '}
          <Link className="link" to="/contact">
            contact us
          </Link>{' '}
          if you'd like a trial API account.
        </p>

        <p>
          An API account has an API key. Using this key, you can access the
          briefings and boards of the respective user/team.
        </p>

        <p>
          The following endpoints are currently available:
          <ul>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#list-briefings" className={styles.spancode}>
                GET /briefings
              </a>{' '}
              - get a list of all briefings tied to your api account
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-briefing" className={styles.spancode}>
                GET /briefings/:id
              </a>{' '}
              - get back a particular briefing and its articles. If you or your
              team own the briefing you'll see the settings for it (such as
              keywords)
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#create-briefing" className={styles.spancode}>
                POST /briefings
              </a>{' '}
              - create a new briefing, using all the same options available in
              the web app
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#update-briefing" className={styles.spancode}>
                PUT /briefings/:id
              </a>{' '}
              - update a particular briefing that is tied to your api account
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#delete-briefing" className={styles.spancode}>
                DELETE /briefings/:id
              </a>{' '}
              - delete a particular briefing that is tied to your api account
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#list-boards" className={styles.spancode}>
                GET /boards
              </a>{' '}
              - get a list of all the saved boards tied to your api account
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-board" className={styles.spancode}>
                GET /boards/:id
              </a>{' '}
              - get back a particular saved board and its articles
            </li>
          </ul>
        </p>

        <div className={styles.endpointSection}>
          <h4>Authentication</h4>

          <p>
            You API key must be passed up with every request. The API key should
            should appear in the header field as X-Api-Key. All requests must go
            over https.
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v1/briefings/653`}
          </pre>
        </div>

        <div className={styles.endpointSection}>
          <h4>Response format</h4>

          <p>
            Responses are in JSON format, following the rough spec defined at{' '}
            <a
              target="_blank"
              className="link"
              href="https://labs.omniti.com/labs/jsend"
            >
              https://labs.omniti.com/labs/jsend
            </a>
          </p>

          <p>
            Every request will return a json object with a{' '}
            <code className={styles.spancode}>status</code> field which is set
            to <code className={styles.spancode}>success</code>,{' '}
            <code className={styles.spancode}>fail</code>, or{' '}
            <code className={styles.spancode}>error</code>.
          </p>

          <p>
            On <code className={styles.spancode}>success</code> there will
            always be a <code className={styles.spancode}>data</code> field
            containing any response data. On{' '}
            <code className={styles.spancode}>fail</code> and{' '}
            <code className={styles.spancode}>error</code> there will be a{' '}
            <code className={styles.spancode}>message</code> field giving a
            human readable description of what has gone wrong.
          </p>

          <p>
            The relevant HTTP status codes are used where possible for every
            response.
          </p>
        </div>

        <div className={styles.endpointSection}>
          <h4>Errors</h4>

          <p>
            If something is wrong with the request, the{' '}
            <code className={styles.spancode}>status</code> field will be set to{' '}
            <code className={styles.spancode}>fail</code> and the a relevant{' '}
            <code className={styles.spancode}>message</code> will be given.
          </p>

          <p>
            If something is wrong on the server, the{' '}
            <code className={styles.spancode}>status</code> field will be set to{' '}
            <code className={styles.spancode}>error</code> and a message will be
            given. Please report any issues you come across.
          </p>

          <p>
            For example, if you you supply an incorrect API key you'll receive a
            a 401 status code and the below response:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "fail",
    "message": "Your API key is invalid"
}`}
          </pre>

          <p>
            If the briefing you're requesting cannot be found you'll receive a
            404 status code and the below response:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "fail",
    "message": "This briefing doesn't exist"
}`}
          </pre>

          <p>
            If the server is down you'll receive a 500 status code and a
            response similar to:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "error",
    "message": "Server is down"
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="list-briefings">
          <h4>Endpoint: list all owned/subscribed briefings</h4>

          <p>
            To retrieve the list of briefings tied to your api account you can
            do
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v1/briefings`}
          </pre>

          <p>
            You'll get a response similar to below. Please not that the
            briefings will be split into two arrays under the keys{' '}
            <code className={styles.spancode}>owned_briefings</code> and{' '}
            <code className={styles.spancode}>subscribed_briefings</code>.
          </p>

          <pre className={styles.code} style={{ maxHeight: '500px' }}>
            {`{
    "status": "success",
    "data": {
        "owned_briefings": [
            {
                "id": 1749,
                "name": "Test",
                "description": null,
                "image": "http://www.gifporn.me/wp-content/uploads/2016/10/never-trust-cats.gif",
                "is_public": false,
                "keywords": [
                    "cats"
                ],
                "included_words": [],
                "excluded_words": [],
                "rss_feeds": [],
                "rss_filter": "keywords",
                "influencers": [],
                "influencer_filter": "keywords",
                "domains": [],
                "domain_filter": "keywords",
                "domain_whitelist": "keywords",
                "blocked_domains": []
            }
        ],
        "subscribed_briefings": [
            {
                "id": 140,
                "name": "SaaS Strategy & Pricing",
                "description": "The latest articles about SaaS strategies and pricing, including all posts from Tom Tunguz and Saastr.",
                "image": null,
                "is_public": true
            },
            {
                "id": 767,
                "name": "Latest News",
                "description": "Latest news from Reuters, BBC, CBS, New York Times, CNN, FT and Washington Post",
                "image": "https://si.wsj.net/public/resources/images/BN-QK128_APPLET_G_20161021180155.jpg",
                "is_public": true
            }
        ]
    }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="get-briefing">
          <h4>Endpoint: retreive a briefing</h4>

          <p>
            You can retrieve a briefing that you or your team have created, or
            that is public. Briefing ids are numerical currently.
          </p>

          <p>
            To retrieve the public AI & Virtual Reality briefing with the newest
            2 articles:
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v1/briefings/34?limit=2&offset=0`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "34",
    "name": "AI & Virtual Reality",
    "articles": [
      {
        "id": "719634",
        "title": "How to Build a Neuron: Exploring AI in JavaScript Pt 2 — JavaScript Scene",
        "image": "https://cdn-images-1.medium.com/max/1200/1*p37KRWpihIwr1UJ0gozG9g.jpeg",
        "date_published": "2016-07-02T07:30:40.000Z",
        "url": "https://medium.com/javascript-scene/how-to-build-a-neuron-exploring-ai-in-javascript-pt-2-2f2acb9747ed",
        "author": null,
        "domain": "medium.com"
      },
      {
        "id": "719640",
        "title": "Painting with AIs",
        "image": "https://cdn-images-1.medium.com/max/800/1*_GTttLcm9AgX_SkfQYTXtg.jpeg",
        "date_published": "2016-07-02T07:30:05.000Z",
        "url": "https://medium.com/art-marketing/painting-with-ais-6bcff75d57e7",
        "author": "Phil McCluskey",
        "domain": "medium.com"
      }
    ]
  }
}`}
          </pre>

          <p>
            All article attributes will be present, but occasionally{' '}
            <code className={styles.spancode}>author</code> or{' '}
            <code className={styles.spancode}>image</code> will be set to null.
            If an image is present, it will be optimised and served over https.
          </p>

          <p>
            Available URL parameters are{' '}
            <code className={styles.spancode}>limit</code> (default 10) for
            limiting the total number of returned results, and{' '}
            <code className={styles.spancode}>offset</code> (default 0) for
            choosing where to start.
          </p>
        </div>

        <div className={styles.endpointSection} id="create-briefing">
          <h4>Endpoint: create a briefing</h4>

          <p>
            You can create a personal or team briefing. The briefing has several
            fields, most of which are optional. You must include a name and at
            least one of the following: keyword, influencer, rss feed, domain.
          </p>

          <p>Here's the fields</p>

          <table className="table">
            <thead>
              <tr>
                <th>Field</th>
                <th>Type</th>
                <th>Description</th>
                <th>Misc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>name</td>
                <td>string</td>
                <td>The name of the briefing</td>
                <td>required</td>
              </tr>
              <tr>
                <td>description</td>
                <td>string</td>
                <td>
                  The description of the briefing, shown on the briefing page to
                  give further info
                </td>
                <td></td>
              </tr>
              <tr>
                <td>keywords</td>
                <td>array of strings</td>
                <td>
                  A list of keywords, such as 'SEO' and 'Content Marketing'
                </td>
                <td>at least one keyword/influencer/domain/feed is required</td>
              </tr>
              <tr>
                <td>included_words</td>
                <td>array of strings</td>
                <td>A list of words that must appear in the article titles</td>
                <td></td>
              </tr>
              <tr>
                <td>excluded_words</td>
                <td>array of strings</td>
                <td>
                  A list of words that must not appear in the article titles
                </td>
                <td></td>
              </tr>
              <tr>
                <td>influencers</td>
                <td>array of strings</td>
                <td>A list of twitter users (without the @ sign)</td>
                <td>at least one keyword/influencer/domain/feed is required</td>
              </tr>
              <tr>
                <td>influencer_filter</td>
                <td>string</td>
                <td>
                  A field to say whether to show all the articles from the
                  influencer, or filter them by the keywords
                </td>
                <td>possible values are 'all' or 'keywords'</td>
              </tr>
              <tr>
                <td>domains</td>
                <td>array of strings</td>
                <td>A list of domains (e.g. bbc.co.uk)</td>
                <td>at least one keyword/influencer/domain/feed is required</td>
              </tr>
              <tr>
                <td>domain_filter</td>
                <td>string</td>
                <td>
                  A field to say whether to show all the articles from the
                  domain, or filter them by the keywords
                </td>
                <td>possible values are 'all' or 'keywords'</td>
              </tr>
              <tr>
                <td>domain_whitelist</td>
                <td>boolean</td>
                <td>
                  A toggle to say whether to only show articles from these
                  domains
                </td>
                <td></td>
              </tr>
              <tr>
                <td>rss_feeds</td>
                <td>array of strings</td>
                <td>
                  A list of rss feeds (e.g.
                  http://feeds.bbci.co.uk/news/rss.xml?edition=uk)
                </td>
                <td>at least one keyword/influencer/domain/feed is required</td>
              </tr>
              <tr>
                <td>rss_filter</td>
                <td>string</td>
                <td>
                  A field to say whether to show all the articles from the rss
                  feed, or filter them by the keywords
                </td>
                <td>possible values are 'all' or 'keywords'</td>
              </tr>
              <tr>
                <td>is_public</td>
                <td>boolean</td>
                <td>
                  A flag to say whether this briefing should be public or not
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <p>
            For example, to make a very simple briefing about cats you can do
            the following call:
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X POST \\
    -H "Content-Type: application/json" \\
    -d '{"name": "Cats","keywords": ["cats"]}' \\
    https://anderspink.com/api/v1/briefings`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
    "name": "Cats"
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="update-briefing">
          <h4>Endpoint: update a briefing</h4>

          <p>The same fields are available as when creating a briefing.</p>

          <p>
            To change our briefing from one about cats to dogs, we could do the
            following PUT call while specifying the briefing id:
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X PUT \\
    -H "Content-Type: application/json" \\
    -d '{"name": "Dogs","keywords": ["dogs"]}' \\
    https://anderspink.com/api/v1/briefings/1766`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
    "name": "Dogs"
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="delete-briefing">
          <h4>Endpoint: delete a briefing</h4>

          <p>To delete a briefing that your user or team owns, you can do:</p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X DELETE \\
    https://anderspink.com/api/v1/briefings/1766`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="list-boards">
          <h4>Endpoint: list all saved boards</h4>

          <p>
            To retrieve the list of all saved boards tied to your api account
            you can do
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
https://anderspink.com/api/v1/boards`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
    "status": "success",
    "data": {
        "owned_boards": [
            {
                "id": "143",
                "name": "Favourites",
                "image": "https://media-mediatemple.netdna-ssl.com/wp-content/uploads/2016/03/build-2016-opt.png"
            }
        ]
    }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="get-board">
          <h4>Endpoint: retrieve a saved board</h4>

          <p>To retrieve a single saved board and its articles, you can do:</p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
https://anderspink.com/api/v1/boards/143`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
    "status": "success",
    "data": {
        "id": "143",
        "name": "Favourites",
        "articles": [
            {
                "id": "1133720",
                "title": "3. From Teaching to Diving: Memorizing Facts Is Not Good Enough. — Content Curation Official Guide",
                "url": "https://medium.com/content-curation-official-guide/3-from-teaching-to-diving-memorizing-facts-is-not-good-enough-73dafe6ff8df",
                "image": "http://localhost:3000/proxy-image/normal/https%3A%2F%2Fcdn-images-1.medium.com%2Fmax%2F1200%2F1*ztGKfvY4jE8xa844reDE4A.jpeg",
                "domain": "medium.com",
                "author": null,
                "date_published": "2016-07-25T12:36:32.000Z"
            }
        ]
    }
}`}
          </pre>
        </div>

        <h4>Info and help</h4>

        <p>We will email any API users when there are any updates.</p>

        <p>
          If you have any questions or would like an API key please{' '}
          <Link className="link" to="/contact">
            contact us
          </Link>
          .
        </p>
      </div>
    )
  }
}
