import React from 'react'
import styles from './APIDocumentationPage.css'
import { Link } from 'react-router'

export default class APIDocumentationPage extends React.Component {
  render() {
    return (
      <div>
        <h4>API documentation</h4>

        <br />
        <div className="alert alert-info">
          <p>
            This is the documentation for the beta version (v4) of the API,
            released March 2022. The old v3 API can be found{' '}
            <Link to="/api-docs" className="link">
              here
            </Link>
            .
          </p>
          <p>What's new in v4:</p>
          <ul>
            <li>Articles now use a string UUID rather than integer ID</li>
            <li>
              The briefing configuration options have been changed to align with
              the latest version of our briefings available in the app. Some
              fields have been removed or renamed. Training has been added.
            </li>
            <li>
              Some endpoints return data in a slightly different structure, such
              as GET /briefings
            </li>
            <li>
              Briefing previews have been split into two endpoints. One endpoint
              is for creating a preview, the other is to check the status of an
              existing preview
            </li>
            <li>
              Rate limiting has been introduced to allow us to provide a
              smoother auto-scaling experience
            </li>
          </ul>
          <p>
            To migrate from v3 to v4, please use the new URL structure
            https://anderspink.com/api/<strong>v4</strong>/ rather than
            https://anderspink.com/api/<strong>v3</strong>/ and update your code
            in the relevant areas.
          </p>
        </div>

        <p>
          For high level information about the API and plans/pricing, please see
          our{' '}
          <Link to="/api-info" className="link">
            API information page
          </Link>
          .
        </p>

        <p>
          To make any requests you'll need an API account. API accounts are tied
          to a team. Please{' '}
          <Link className="link" to="/contact">
            contact us
          </Link>{' '}
          if you'd like a trial API account.
        </p>

        <p>
          An API account has an API key. Using this key, you can access the
          briefings and boards of the respective user/team.
        </p>

        <p>
          The following endpoints are currently available:
          <ul>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#list-briefings" className={styles.spancode}>
                GET /briefings
              </a>{' '}
              - get a list of all briefings on your team
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-briefing" className={styles.spancode}>
                GET /briefings/:id
              </a>{' '}
              - get back a particular briefing and its articles. If you or your
              team own the briefing you'll see the settings for it (such as
              keywords)
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#create-briefing" className={styles.spancode}>
                POST /briefings
              </a>{' '}
              - create a new briefing, using all the same options available in
              the web app
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#update-briefing" className={styles.spancode}>
                PUT /briefings/:id
              </a>{' '}
              - update a particular briefing that is tied to your api account{' '}
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#delete-briefing" className={styles.spancode}>
                DELETE /briefings/:id
              </a>{' '}
              - delete a particular briefing that is tied to your api account{' '}
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#create-briefing-preview" className={styles.spancode}>
                POST /briefing-previews
              </a>{' '}
              - creates a preview of a briefing by supplying the same config as
              used when making a briefing
            </li>
            <li style={{ paddingBottom: '10px' }}>
              <a href="#get-briefing-preview" className={styles.spancode}>
                GET /briefing-previews/:id
              </a>{' '}
              - checks an existing preview to see if it's finished and what the
              latest articles are
            </li>
          </ul>
        </p>

        <div className={styles.endpointSection}>
          <h4>Authentication</h4>

          <p>
            You API key must be passed up with every request. The API key should
            should appear in the header field as X-Api-Key. All requests must go
            over https.
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v4/briefings/653`}
          </pre>
        </div>

        <div className={styles.endpointSection}>
          <h4>Response format</h4>

          <p>
            Responses are in JSON format, following the rough spec defined at{' '}
            <a
              target="_blank"
              className="link"
              href="https://github.com/omniti-labs/jsend"
            >
              https://github.com/omniti-labs/jsend
            </a>
          </p>

          <p>
            Every request will return a json object with a{' '}
            <code className={styles.spancode}>status</code> field which is set
            to <code className={styles.spancode}>success</code>,{' '}
            <code className={styles.spancode}>fail</code>, or{' '}
            <code className={styles.spancode}>error</code>.
          </p>

          <p>
            On <code className={styles.spancode}>success</code> there will
            always be a <code className={styles.spancode}>data</code> field
            containing any response data. On{' '}
            <code className={styles.spancode}>fail</code> and{' '}
            <code className={styles.spancode}>error</code> there will be a{' '}
            <code className={styles.spancode}>message</code> field giving a
            human readable description of what has gone wrong.
          </p>

          <p>
            The relevant HTTP status codes are used where possible for every
            response.
          </p>
        </div>

        <div className={styles.endpointSection}>
          <h4>Errors</h4>

          <p>
            If something is wrong with the request, the{' '}
            <code className={styles.spancode}>status</code> field will be set to{' '}
            <code className={styles.spancode}>fail</code> and the a relevant{' '}
            <code className={styles.spancode}>message</code> will be given.
          </p>

          <p>
            If something is wrong on the server, the{' '}
            <code className={styles.spancode}>status</code> field will be set to{' '}
            <code className={styles.spancode}>error</code> and a message will be
            given. Please report any issues you come across.
          </p>

          <p>
            For example, if you you supply an incorrect API key you'll receive a
            a 401 status code and the below response:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "fail",
    "message": "Your API key is invalid"
}`}
          </pre>

          <p>
            If the briefing you're requesting cannot be found you'll receive a
            404 status code and the below response:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "fail",
    "message": "This briefing doesn't exist"
}`}
          </pre>

          <p>
            If the server is down you'll receive a 500 status code and a
            response similar to:
          </p>

          <pre className={styles.code}>
            {`{
    "status": "error",
    "message": "Server is down"
}`}
          </pre>
        </div>

        <div className={styles.endpointSection}>
          <h4>Rate limiting</h4>

          <p>
            By default your account will have a rate limit of 15 requests within
            a 5 second window. If you exceed this amount we will return a 420
            response code.
          </p>

          <p>
            Three headers are returned on every response which you may choose to
            use to plan your requests to the API:
          </p>
          <p>
            <ul>
              <li>
                X-RateLimit-Remaining: The number of API calls left in the 5
                second timeframe.
              </li>
              <li>
                X-RateLimit-Reset: The epoch time that the rate limit will
                reset.
              </li>
              <li>
                X-RateLimit-Limit: The maximum number of API calls in the 5
                second timeframe.
              </li>
            </ul>
          </p>

          <p>
            Rate limiting allow us to smooth out our infrastructure scaling and
            provide more consistent response times. If you require a higher rate
            limit please{' '}
            <Link className="link" to="/contact">
              contact us
            </Link>{' '}
            to discuss it.
          </p>
        </div>

        <div className={styles.endpointSection} id="list-briefings">
          <h4>Endpoint: list all the team's briefings</h4>

          <p>
            To retrieve the list of briefings tied to your api account you can
            do
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v4/briefings`}
          </pre>

          <p>
            You'll get a response similar to below. Please not that the
            briefings will be split into two arrays under the keys{' '}
            <code className={styles.spancode}>owned_briefings</code> and{' '}
            <code className={styles.spancode}>subscribed_briefings</code>.
          </p>

          <pre className={styles.code} style={{ maxHeight: '500px' }}>
            {`{
    "status": "success",
    "data": [
        {
            "id": 1749,
            "name": "Programming",
            "description": null,
            "image": "https://stratechery.com/wp-content/uploads/2017/03/3753191500_c28898135a_o-1024x814.gif",
            "type: "simple",
            "language": "en"
            
        },
        {
            "id": 1750,
            "name": "Content marketing",
            "description": null,
            "image": "https://stratechery.com/wp-content/uploads/2017/03/3753191500_c28898135a_o-1024x814.gif",
            "type: "advanced",
            "language": "en"
        }
    ]
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="get-briefing">
          <h4>Endpoint: retreive a briefing</h4>

          <p>
            You can retrieve a briefing that you or your team have created, or
            that is public. Briefing ids are numerical currently.
          </p>

          <p>
            To retrieve the public AI & Virtual Reality briefing with a few of
            the top articles in the last 3 days:
          </p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    https://anderspink.com/api/v4/briefings/34?limit=2&offset=0&time=3-days`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "34",
    "name": "AI & Virtual Reality",
    "description": null,
    "is_public": false,
    "last_refreshed_at": "2022-03-31T16:23:39.000Z",
    "type": "simple",
    "language": "en",
    "simple_fields": {
        "topics": [
            "ai",
            "virtual reality"
        ],
        "blocked_domains": [],
        "allowed_domains": [],
        "trainings": []
    },
    "total_num_articles": 2,
    "articles": [
      {
        "id": "719634",
        "title": "How to Build a Neuron: Exploring AI in JavaScript Pt 2 — JavaScript Scene",
        "content": "In this series, we’re discussing a topic that will transform the world we live in over the course of the next 25 years. We’re going to see lots of drones, self driving cars, VR, and AR devices changing...",
        "image": "https://cdn-images-1.medium.com/max/1200/1*p37KRWpihIwr1UJ0gozG9g.jpeg",
        "date_published": "2016-07-02T07:30:40.000Z",
        "url": "https://medium.com/javascript-scene/how-to-build-a-neuron-exploring-ai-in-javascript-pt-2-2f2acb9747ed",
        "author": null,
        "domain": "medium.com",
        "reading_time": null,
        "comments": [],
        "language": "en"
      },
      {
        "id": "719640",
        "title": "Painting with AIs",
        "content": "A year or two ago, if you’d asked, I would have suggested that yes, eventually, software and robots will take all our jobs. The only jobs that were potentially safe...",
        "image": "https://cdn-images-1.medium.com/max/800/1*_GTttLcm9AgX_SkfQYTXtg.jpeg",
        "date_published": "2016-07-02T07:30:05.000Z",
        "url": "https://medium.com/art-marketing/painting-with-ais-6bcff75d57e7",
        "author": "Phil McCluskey",
        "domain": "medium.com",
        "reading_time": 164,
        "comments": [],
        "language": "en"
      }
    ]
  }
}`}
          </pre>

          <p>
            All article attributes will be present, but occasionally{' '}
            <code className={styles.spancode}>author</code>,{' '}
            <code className={styles.spancode}>content</code>,{' '}
            <code className={styles.spancode}>reading_time</code> or{' '}
            <code className={styles.spancode}>image</code> will be set to null.
            If an image is present, it will be optimised and served over https.
            Reading time (<code className={styles.spancode}>reading_time</code>)
            is given in seconds and is an estimate based on word count and
            complexity.
          </p>

          <p>
            Available URL parameters are{' '}
            <code className={styles.spancode}>limit</code> (default 10) for
            limiting the total number of returned results, and{' '}
            <code className={styles.spancode}>offset</code> (default 0) for
            choosing where to start.
          </p>
          <p>
            Use <code className={styles.spancode}>time</code> to restrict your
            briefing to a certain time period, e.g.{' '}
            <code className={styles.spancode}>1-week</code> or{' '}
            <code className={styles.spancode}>4-days</code>. By default this
            will be set to <code className={styles.spancode}>auto</code> and we
            will automatically pick a time scale as small as possible which
            still has good, fresh articles in.
          </p>
        </div>

        <div className={styles.endpointSection} id="create-briefing">
          <h4>Endpoint: create a briefing</h4>

          <p>
            You can create a personal or team briefing. The briefing has several
            fields, most of which are optional. You must include a name and at
            least one of the following: keyword, influencer, rss feed, domain.
          </p>

          <p>Here's the fields</p>

          <table className="table">
            <thead>
              <tr>
                <th>Field</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>name</td>
                <td>string</td>
                <td>The name of the briefing</td>
              </tr>
              <tr>
                <td>description</td>
                <td>string</td>
                <td>
                  The description of the briefing, shown on the briefing page to
                  give further info
                </td>
              </tr>
              <tr>
                <td>language</td>
                <td>string (2 characters)</td>
                <td>
                  We use this language to find articles based on the text that
                  appears in their title/body. Languages avalable are: zh
                  (Chinese), nl (Dutch), en (English), fr (French), it
                  (Italian), de (German), pt (Portuguese, es (Spanish).
                </td>
              </tr>
              <tr>
                <td>type</td>
                <td>string</td>
                <td>
                  Set to 'simple' or 'advanced', depending on which type of
                  briefing you want to make. See our web app for the differences
                </td>
              </tr>
              <tr>
                <td>simple_fields</td>
                <td>object</td>
                <td>
                  <p>Must be present when type is set to 'simple'</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>topics</td>
                        <td>array of strings</td>
                        <td>
                          A list of words which we'll use to find matching
                          content
                        </td>
                      </tr>
                      <tr>
                        <td>training</td>
                        <td>array of objects</td>
                        <td>
                          Each object must have an id and label. Allows you to
                          specify which articles are relevant or irrelevant.
                          This is used to train the briefing and bring in the
                          most relevant content at the top.
                          <br />
                          Article IDs can be found once a preview is created,
                          you can then do further previews with the IDs to
                          refine the briefing.
                          <pre className={styles.code}>
                            {`
[
  {"id": "82c26453-0dc6-48b1-a23f-b93581373ca0", "label": "relevant"},
  {"id": "7744c545-89d1-48a3-a009-539af4bccc2d", "label": "irrelevant"}
]
                            `}
                          </pre>
                        </td>
                      </tr>
                      <tr>
                        <td>allowed_domains</td>
                        <td>array of strings</td>
                        <td>
                          Allow only content from these domains (cannot be used
                          in conjunction with blocked_domains)
                        </td>
                      </tr>
                      <tr>
                        <td>blocked_domains</td>
                        <td>array of strings</td>
                        <td>
                          Block content that comes from these domains (cannot be
                          used in conjunction with allowed_domains)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td>advanced_fields</td>
                <td>object</td>
                <td>
                  <p>Must be present when type is set to 'advanced'</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Type</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>sources</td>
                        <td>array of objects</td>
                        <td>
                          An array of source objects. Each object must have two
                          fields: type and value. The type field can be one of:
                          topic, domain, or rssFeed
                        </td>
                      </tr>
                      <tr>
                        <td>keywords</td>
                        <td>array of strings</td>
                        <td>
                          These are words that must appear in the article titles
                        </td>
                      </tr>
                      <tr>
                        <td>blocked_domains</td>
                        <td>array of strings</td>
                        <td>Block content that comes from these domains</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            For example, to make a simple briefing you can do the following
            call:
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X POST \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "AI",
        "type": "simple",
        "simple_fields": {
            "topics": ["business intelligence", "artificial intelligence"]
            "allowed_domains": ["forbes.com"],
            "trainings": [
              {"id": "82c26453-0dc6-48b1-a23f-b93581373ca0", "label": "relevant"},
              {"id": "7744c545-89d1-48a3-a009-539af4bccc2d", "label": "irrelevant"}
            ]
        }
    }' \\
    https://anderspink.com/api/v4/briefings
`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
    "name": "AI"
  }
}`}
          </pre>

          <p>To make an advanced briefing with a variety of sources</p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X POST \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "Big data",
        "type": "advanced",
        "advanced_fields": {
            "sources": [
                {
                    "type": "topic",
                    "value": "big data"
                },
                {
                    "type": "domain",
                    "value": "bbc.co.uk"
                },
                {
                    "type": "rssFeed",
                    "value": "https://www.theguardian.com/world/rss"
                }
            ],
            "keywords": ["ai"],
            "blocked_domains": ["microsoft.com"]
        }
    }' \\
    https://anderspink.com/api/v4/briefings
`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="update-briefing">
          <h4>Endpoint: update a briefing</h4>

          <p>The same fields are available as when creating a briefing.</p>

          <p>
            To change our briefing from one about AI to Big data, we could do
            the following PUT call while specifying the briefing id:
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X PUT \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "Big data",
        "type": "simple",
        "simple_fields": {
            "topics": ["big data"],
            "blocked_domains": ["forbes.com"]
        }
    }' \\
    https://anderspink.com/api/v4/briefings/1766
`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
    "name": "Big data"
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="delete-briefing">
          <h4>Endpoint: delete a briefing</h4>

          <p>To delete a briefing that your user or team owns, you can do:</p>

          <pre className={styles.code}>
            {`curl -H "X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0" \\
    -X DELETE \\
    https://anderspink.com/api/v4/briefings/1766`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
  "status": "success",
  "data": {
    "id": "1766",
  }
}`}
          </pre>
        </div>

        <div className={styles.endpointSection} id="create-briefing-preview">
          <h4>Endpoint: create a briefing preview</h4>
          <p>
            You can preview a briefing before making it by supplying the exact
            same input as used in the create briefing endpoint. You may not
            receive as many articles as you would once the briefing has been
            created as it is a quicker preview.
          </p>

          <p>
            This endpoint will return immediately with an ID, completion
            percentage and the articles found so far.
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    -X POST \\
    -H 'Content-Type: application/json' \\
    -d '{
        "name": "AI",
        "type": "simple",
        "simple_fields": {
            "topics": ["business intelligence", "artificial intelligence"],
        }
    }' \\
    https://anderspink.com/api/v4/briefing-previews
`}
          </pre>

          <p>You'll get a response similar to below:</p>

          <pre className={styles.code}>
            {`{
    "status": "success",
    "data": {
        "id": "9b9dc715-bdc4-405a-8706-d42a33a6005a",
        "completion_percent": 50,
        "articles": [
            {
                "id": "7489f8df-de48-46c2-bcc0-4f3362b28691",
                "title": "How important is AI and where can it be applied to improve operations?",
                "content": "There are few phrases in the industrial lexicon more widely used but less widely understood than ‘big data’ It would be a mistake though to write the term off as an over-used buzz phrase rather than a concept that can bring tangible value to any enterprise. Understanding and properly leveraging...",
                "url": "https://technative.io/how-important-is-big-data-and-where-can-it-be-applied-to-improve-operations/",
                "image": null,
                "domain": "technative.io",
                "author": "@TechNative",
                "date_published": "2022-02-08T12:14:00.000Z",
                "reading_time": 193
            },
            ...more articles
        ]
    }
}`}
          </pre>

          <p>
            Sometimes you'll immediately receive back a 100% completion,
            othertimes it will be lower and you'll want to re-check the preview
            by passing in the ID to the GET /briefing-previews/:id
          </p>

          <p>
            Previews may take several seconds or even up to a few minutes to
            fully complete.
          </p>
        </div>

        <div className={styles.endpointSection} id="get-briefing-preview">
          <h4>Endpoint: get an existing briefing preview</h4>
          <p>
            Retreive an existing preview to see it's completion percentage and
            what the latest articles are
          </p>

          <p>
            Use the ID returned in the{' '}
            <a href="#create-briefing-preview">Create briefing preview</a> call.
            It is best practice to call this GET endpoint every 5 seconds until
            it returns 100% completion.
          </p>

          <pre className={styles.code}>
            {`curl -H 'X-Api-Key: 4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0' \\
    https://anderspink.com/api/v4/briefing-previews/7489f8df-de48-46c2-bcc0-4f3362b28691
`}
          </pre>

          <p>
            The articles returned will be the full latest set that are available
            for the preview.
          </p>

          <pre className={styles.code}>
            {`{
    "status": "success",
    "data": {
        "id": "9b9dc715-bdc4-405a-8706-d42a33a6005a",
        "completion_percent": 100,
        "articles": [
            {
                "id": "7489f8df-de48-46c2-bcc0-4f3362b28691",
                "title": "How important is AI and where can it be applied to improve operations?",
                "content": "There are few phrases in the industrial lexicon more widely used but less widely understood than ‘big data’ It would be a mistake though to write the term off as an over-used buzz phrase rather than a concept that can bring tangible value to any enterprise. Understanding and properly leveraging...",
                "url": "https://technative.io/how-important-is-big-data-and-where-can-it-be-applied-to-improve-operations/",
                "image": null,
                "domain": "technative.io",
                "author": "@TechNative",
                "date_published": "2022-02-08T12:14:00.000Z",
                "reading_time": 193
            },
            ...more articles
        ]
    }
}`}
          </pre>
        </div>

        <h4>Info and help</h4>

        <p>
          If you have any questions or would like an API key please{' '}
          <Link className="link" to="/contact">
            contact us
          </Link>
          .
        </p>
      </div>
    )
  }
}
