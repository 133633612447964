import React from 'react'
import { Link, browserHistory } from 'react-router'
import FixedModal from 'app/components/FixedModal'

import { connect } from 'react-redux'
import pageStyles from 'content/pages/styles.css'

@connect((state) => ({
  app: state.app,
}))
export default class Pricing extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }
  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundPosition: '50% 100%',
            backgroundImage: 'url(/pages/landing/telescope.jpg)',
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Pricing</div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>Partner Plans</div>

          <div className={pageStyles.textContainer}>
            <p>
              Add a powerful curation tool to your Learning Platform, LMS, LXP
              or app by joining our partner program.
            </p>

            <p>We support you with</p>

            <ul>
              <li>
                Access to our API, SDK and other integration methods to embed
                the Anders Pink tool and content in your platform
              </li>
              <li>Technical, Sales and Marketing Support</li>
              <li>Partner Success Manager</li>
              <li>
                Flexible Pricing models, including OEM level integration for
                unlimited use
              </li>
            </ul>

            <p>
              <a href="/partners" target="blank" className="link">
                Our partners
              </a>{' '}
              include many of the world’s leading learning providers. They’re
              adding value for their clients and growing their businesses by
              embedding Anders Pink in their platforms and solutions.
            </p>

            <p>
              <Link to="/contact" className="link">
                Contact us
              </Link>{' '}
              to discuss your needs and arrange an Enterprise Plan trial.
            </p>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>Enterprise plans</div>

          <div className={pageStyles.textContainer}>
            <p>
              Our enterprise plans enable our clients with 1000+ users to curate
              content on scale and integrate it with their existing platform,
              including their LMS, LXP, Slack, MS Teams and Sharepoint.
            </p>

            <p>
              Enterprise clients can perform their own integrations, or ask
              their LMS / LXP or Learning Providers to do this for them. We may
              already be working with your providers -{' '}
              <a href="/partners" target="blank" className="link">
                check our partner page
              </a>
              .
            </p>

            <p>
              Pricing is based on number of briefings and end-users in your
              client organisation, including an option for unlimited use of
              Anders Pink within your organisation.
            </p>

            <p>
              <Link to="/contact" className="link">
                Contact us
              </Link>{' '}
              to discuss your needs, get a quote, and arrange an Enterprise Plan
              trial.
            </p>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            For Smaller and scaling teams
          </div>

          <div className={pageStyles.textContainer}>
            <p>
              Most of our clients and partners use Anders Pink to integrate with
              other learning tools and platforms at an enterprise level.
            </p>
            <p>
              To use Anders Pink without any integrations as a smaller or
              scaling team,{' '}
              <Link to="/contact" className="link">
                contact us
              </Link>{' '}
              for access and pricing.
            </p>
          </div>
        </div>
      </div>
    )
  }
}
