import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import briefingInfoFragment from 'briefings/fragments/briefingInfo'
import EditBriefingForm from 'briefings/create/containers/EditBriefingForm'
import { browserHistory } from 'react-router'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
@graphql(
  gql`
    query ($id: ID!) {
      briefing(id: $id) {
        id
        name
        description
        type
        teamId
        sourceFields {
          sources {
            type
            value
          }
          keywords
          includedWords
          excludedWords
          blockedDomains
          whitelistDomains
        }
        keywordFields {
          keywords
          includedWords
          excludedWords
          domains
          blockedDomains
          useBusinessDomains
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
@graphql(
  gql`
    mutation ($id: ID, $teamId: ID) {
      moveBriefing(id: $id, teamId: $teamId)
    }
  `,
  {
    name: 'moveBriefing',
    options: {
      refetchQueries: ['appQuery'],
    },
  },
)
export default class MoveBriefingPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.data.loading) {
      return false
    }

    const briefing = this.props.data.briefing
    if (!briefing.type) {
      return (
        <div>
          <span>Sorry, you don't have permission to move this briefing</span>
        </div>
      )
    }

    let teams = this.props.appQueryResult.user.teams || []

    return (
      <div>
        <p>
          Move the briefing "{briefing.name}" between your personal view and any
          teams:
        </p>

        <MoveBriefingForm
          teams={teams}
          initialValues={{
            teamId: briefing.teamId,
          }}
          onSubmit={(values) => {
            let teamName =
              values.teamId == 1
                ? 'your personal view'
                : 'team ' + teams.find((team) => team.id == values.teamId).name

            this.props.moveBriefing({
              variables: {
                id: briefing.id,
                teamId: values.teamId,
              },
            })

            this.props.dispatch({
              type: 'ADD_NOTIFICATION',
              content: `The briefing '${briefing.name}' has been succesfully moved to ${teamName}`,
            })

            browserHistory.push('/home')
          }}
        />
      </div>
    )
  }
}

@connect()
@reduxForm({
  form: 'moveBriefing',
})
class MoveBriefingForm extends React.Component {
  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit(this.props.onSubmit)}>
          <div className="form-group">
            <Field name="teamId" component="select" className="form-control">
              <option value={1}>Personal view</option>

              {this.props.teams.map((team) => (
                <option key={team.id} value={team.id}>
                  Team: {team.name}
                </option>
              ))}
            </Field>
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-sm">Move briefing</button>
          </div>
        </form>
      </div>
    )
  }
}
