import React, { useEffect } from 'react'
import { SubmissionError } from 'redux-form'
import compose from 'recompose/compose'
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import axios from 'axios'

const UNSUBSCRIBE_FROM_DIGEST = gql`
  mutation unsubscribeNewsletter($id: ID, $email: String, $user: ID) {
    unsubscribeNewsletter(id: $id, email: $email, user: $user)
  }
`

let Unsubscribe = ({ location, unsubscribeNewsletter }) => {
  useEffect(() => {
    // Decrypt
    if (location.search) {
      let ciphertext = location.search.slice(1)

      axios
        .post('/hashDecrypt', { text: ciphertext })
        .then(({ data }) => {
          handleUnsubscriptionData(data)
        })
        .catch((e) => console.log('ERR', e))
    }
  }, [location])

  const handleUnsubscriptionData = (data) => {
    unsubscribeNewsletter({
      variables: {
        id: data.newsletterId,
        email: data.email,
        user: data.userId,
      },
    })
      .then(() => console.log('Unsubscribed'))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
    // }
  }

  return (
    <div className="d-flex">
      <div>
        <h5>We have unsubscribed you from this team digest</h5>
      </div>
    </div>
  )
}

Unsubscribe = compose(
  withApollo,
  graphql(UNSUBSCRIBE_FROM_DIGEST, {
    name: 'unsubscribeNewsletter',
    fetchPolicy: 'cache-and-network',
  }),
)(Unsubscribe)

export default Unsubscribe
