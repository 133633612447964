import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router'
import ArticleListFullPage from 'articles/components/ArticleListFullPage'
import Article from 'articles/containers/Article'
import articleInfoFragment from 'articles/fragments/articleInfo'
import withFollowMutations from 'briefings/containers/withFollowMutations'
import { browserHistory } from 'react-router'
import FixedModal from 'app/components/FixedModal'
import { DropdownMenu } from 'app/components/DropdownMenu'
import styles from './BriefingPage.css'

const ITEMS_PER_PAGE = 50

@graphql(
  gql`
    query($limit: Int, $offset: Int) {
      latestNews(limit: $limit, offset: $offset) {
        name
        description
        articles {
          ...articleInfo
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
          time: props.params.time || '1-month',
          limit: ITEMS_PER_PAGE,
          offset: 0,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
    props({ data: { latestNews, fetchMore, ...rest } }) {
      return {
        data: {
          latestNews,
          ...rest,
          loadMoreArticles() {
            return fetchMore({
              variables: {
                offset: latestNews.articles.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return Object.assign({}, previousResult, {
                  // Append the new feed results to the old one
                  latestNews: {
                    ...latestNews,
                    articles: [
                      ...previousResult.latestNews.articles,
                      ...fetchMoreResult.latestNews.articles,
                    ],
                  },
                })
              },
            })
          },
        },
      }
    },
  },
)
export default class LatestNewsPage extends React.Component {
  constructor() {
    super()
  }
  componentDidMount() {
    setTimeout(() => {
      // Check...
      if (
        !this.props.data.latestNews ||
        this.props.data.latestNews.articles.length === 0
      ) {
        this.props.data.refetch()
      }
    }, 1000)
  }
  render() {
    if (this.props.data.loading && !this.props.data.latestNews) {
      return <div>Loading...</div>
    }

    const latestNews = this.props.data.latestNews

    let articles = latestNews.articles

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              marginRight: '20px',
            }}
          >
            <h5>{latestNews.name}</h5>
            {(latestNews.description ? true : false) && (
              <p>{latestNews.description}</p>
            )}
          </div>
        </div>
        <br />

        {!this.props.data.loading && articles.length === 0 && (
          <div>Sorry, no articles were found</div>
        )}
        {articles.length > 0 && (
          <div>
            <ArticleListFullPage>
              {articles.map((article, index) => (
                <Article key={index} article={article} />
              ))}
            </ArticleListFullPage>

            {articles.length % ITEMS_PER_PAGE === 0 && (
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => this.props.data.loadMoreArticles()}
                >
                  {this.props.data.loading ? 'Loading..' : 'See more'}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
