exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ArticleShare__socialContainer___18bOr {\n  padding: 10px;\n}\n.ArticleShare__socialTitle___utkj6 {\n  padding: 7px;\n  font-size: 1.1em;\n}\n.ArticleShare__socialName___2A5Iq {\n  margin-left: 8px;\n}\n.ArticleShare__socialShare___2iyO_ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 7px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  cursor: pointer;\n  color: #666;\n}\n.ArticleShare__socialShare___2iyO_:hover {\n  color: inherit;\n}\n.ArticleShare__socialClose___1kC0p {\n  font-size: 0.9em;\n  color: #aaa;\n  text-decoration: underline;\n  padding: 7px;\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"socialContainer": "ArticleShare__socialContainer___18bOr",
	"socialTitle": "ArticleShare__socialTitle___utkj6",
	"socialName": "ArticleShare__socialName___2A5Iq",
	"socialShare": "ArticleShare__socialShare___2iyO_",
	"socialClose": "ArticleShare__socialClose___1kC0p"
};