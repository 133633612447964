exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FriendsPanel__root___2MVSV {\n  -webkit-box-shadow: 0px 3px 8px 1px #d8d8d8;\n          box-shadow: 0px 3px 8px 1px #d8d8d8;\n}\n.FriendsPanel__header___2I0d7 {\n  padding: 12px;\n  height: 80px;\n  background-color: #444;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n}\n.FriendsPanel__title___3sMPc {\n  color: #fff;\n  font-size: 1.2em;\n}\n.FriendsPanel__icon___3CQ2R {\n  color: #868686;\n  margin-right: 10px;\n}\n.FriendsPanel__loading___39a25 {\n  padding: 12px;\n  min-height: 200px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.FriendsPanel__row___2qMi- {\n  border-bottom: 1px solid #ddd;\n  padding: 10px 0;\n}\n.FriendsPanel__row___2qMi-:last-of-type {\n  border-bottom: 0 none;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "FriendsPanel__root___2MVSV",
	"header": "FriendsPanel__header___2I0d7",
	"title": "FriendsPanel__title___3sMPc",
	"icon": "FriendsPanel__icon___3CQ2R",
	"loading": "FriendsPanel__loading___39a25",
	"row": "FriendsPanel__row___2qMi-"
};