exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".HowItWorks\\.module__howItWorks___1QMtS {\n  margin: 100px 0 150px;\n}\n\n.HowItWorks\\.module__hiwHeader___yV2kj {\n  color: var(--ap-green);\n  font-size: 1.6rem;\n  font-weight: bold;\n  margin: 20px 50px 40px;\n}\n\n.HowItWorks\\.module__hiwCarousel___3SLbP {\n  margin: 0 auto 40px;\n  max-width: 1200px;\n  padding: 0 20px;\n}\n\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-arrow {\n  background-color: rgb(185, 185, 185);\n}\n\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-arrow:hover:not(:disabled),\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-arrow:focus:not(:disabled) {\n  background-color: var(--ap-pink);\n}\n\n.HowItWorks\\.module__hiwCarousel___3SLbP .rec-pagination {\n  padding: -100px;\n}\n\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-dot_active {\n  background-color: var(--ap-pink);\n  -webkit-box-shadow: 0 0 1px 3px var(--ap-pink);\n          box-shadow: 0 0 1px 3px var(--ap-pink);\n}\n\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-dot:hover,\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-dot:active,\n.HowItWorks\\.module__hiwCarousel___3SLbP button.rec-dot:focus {\n  -webkit-box-shadow: 0 0 1px 3px var(--ap-pink);\n          box-shadow: 0 0 1px 3px var(--ap-pink);\n}\n\n.HowItWorks\\.module__hiwBtn___3flLA {\n  background: var(--ap-pink);\n  border: none;\n  border-radius: 5px;\n  color: white;\n  cursor: pointer;\n  font-size: 1rem;\n  padding: 10px;\n  -webkit-transition: 0.2s ease;\n  transition: 0.2s ease;\n}\n\n.HowItWorks\\.module__hiwBtn___3flLA:hover {\n  background-color: #b1012a;\n}\n\n.HowItWorks\\.module__hiwBg___1vu80 {\n  background-color: #f8f4f6;\n  height: 400px;\n  margin-top: 150px;\n  margin-bottom: -520px;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"howItWorks": "HowItWorks.module__howItWorks___1QMtS",
	"hiwHeader": "HowItWorks.module__hiwHeader___yV2kj",
	"hiwCarousel": "HowItWorks.module__hiwCarousel___3SLbP",
	"hiwBtn": "HowItWorks.module__hiwBtn___3flLA",
	"hiwBg": "HowItWorks.module__hiwBg___1vu80"
};