import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory } from 'react-router'
import gql from 'graphql-tag'
import moment from 'moment'
import axios from 'axios'
import randomSeed from 'random-seed'

import styles from './DegreedBriefingPage.css'

import { Container, Row, Col } from 'react-bootstrap'

import Loader from 'app/components/Loader'
import articleInfoFragment from 'articles/fragments/articleInfo'
import withAuthCheck from '../../app/containers/withAuthCheck'

const DEFAULT_TIME = '1-month'

@withAuthCheck
@graphql(
  gql`
    query briefingQuery(
      $id: ID!
      $time: String!
      $limit: Int
      $offset: Int
      $tag: String
    ) {
      briefing(id: $id) {
        id
        name
        url
        description
        image
        articles(time: $time, limit: $limit, offset: $offset, tag: $tag) {
          ...articleInfo
        }
      }
      sessionLite {
        degreedSSO
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: ({ params, app, location }) => {
      return {
        variables: {
          id: params.id,
          time: params.time || DEFAULT_TIME,
          limit: 100,
          offset: 0,
          tag: null,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
export default class DegreedBriefingPage extends React.Component {
  constructor(props) {
    super(props)

    const dataString = localStorage.getItem('degreed_read_articles')
    const articleIds = dataString ? JSON.parse(dataString) : []

    this.state = {
      articleIds,
    }
  }
  isComplete(id) {
    return this.state.articleIds.includes(id)
  }
  markAsComplete(id) {
    const articleIds = [...this.state.articleIds]

    if (!articleIds.includes(id)) {
      articleIds.push(id)

      this.setState({
        articleIds,
      })

      localStorage.setItem('degreed_read_articles', JSON.stringify(articleIds))

      axios
        .post(`/actions/degreed/article-completion/${id}`)
        .then((response) => {
          console.log(response)
        })
    }
  }
  render() {
    console.log(this.props.data)

    if (!this.props.data || !this.props.data.briefing) {
      return (
        <div className={styles.loading}>
          <Loader />
        </div>
      )
    }

    const {
      location: { query },
    } = this.props

    const time = this.props.params.time || DEFAULT_TIME

    const buildUrl = (time) =>
      `/briefing-embedded-degreed/${this.props.params.id}/${
        this.props.params.slug
      }/${time}${query.limit ? `?limit=${query.limit}` : ''}`

    const briefing = this.props.data.briefing

    return (
      <div>
        <Container>
          <Row className="mt-4">
            <Col>
              <h3>{briefing.name}</h3>
              {briefing.description && <p>{briefing.description}</p>}
            </Col>
            <Col>
              <div
                className="d-flex justify-content-end align-items-center"
                style={{ height: '100%' }}
              >
                <div
                  className="btn-group"
                  role="group"
                  data-tip="Change your view to see content from different time periods"
                >
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      time === '24-hours' ? 'primary' : 'secondary'
                    }`}
                    onClick={() => {
                      browserHistory.push(buildUrl('24-hours'))
                    }}
                  >
                    24 hours
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      time === '3-days' ? 'primary' : 'secondary'
                    }`}
                    onClick={() => {
                      browserHistory.push(buildUrl('3-days'))
                    }}
                  >
                    3 days
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      time === '1-week' ? 'primary' : 'secondary'
                    }`}
                    onClick={() => {
                      browserHistory.push(buildUrl('1-week'))
                    }}
                  >
                    1 week
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      time === '1-month' ? 'primary' : 'secondary'
                    }`}
                    onClick={() => {
                      browserHistory.push(buildUrl('1-month'))
                    }}
                  >
                    1 month
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm btn-${
                      time === '3-months' ? 'primary' : 'secondary'
                    }`}
                    onClick={() => {
                      browserHistory.push(buildUrl('3-months'))
                    }}
                  >
                    3 months
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {briefing.articles.length === 0 && (
              <Col>
                <p className="mt-4">
                  Sorry, no articles are available for this time period
                </p>
              </Col>
            )}
            {briefing.articles.map((a) => {
              // Randomly pick filler image
              const fillerBackgroundImage = `/filler-images/filler${String(
                randomSeed(a.id).intBetween(1, 9),
              ).padStart(2, '0')}.png`

              return (
                <Col lg={4} md={6} sm={12} key={a.id}>
                  <div className={styles.article}>
                    <div className={styles.articleInner}>
                      <div
                        className={styles.image}
                        style={{
                          backgroundImage: `url(${fillerBackgroundImage})`,
                        }}
                      >
                        {a.image !== null && (
                          <div
                            className={styles.imageInner}
                            style={{
                              backgroundImage: `url(${a.image})`,
                            }}
                          />
                        )}
                      </div>
                      <div className={styles.text}>
                        <div>
                          <a
                            href={a.url}
                            target="_blank"
                            className={styles.title}
                          >
                            {a.title}
                          </a>
                          <div className={styles.meta}>
                            {a.domain}
                            {a.datePublished ? (
                              <span>
                                {' '}
                                - {moment(a.datePublished).fromNow()}
                              </span>
                            ) : null}
                          </div>

                          <div className={styles.content}>{a.content}</div>
                        </div>
                        {this.props.data.sessionLite.degreedSSO && (
                          <div className={styles.actions}>
                            <span
                              className={
                                this.isComplete(a.id)
                                  ? styles.markIncomplete
                                  : styles.markComplete
                              }
                              onClick={() => this.markAsComplete(a.id)}
                            >
                              <span className={styles.markCompleteInner}>
                                <i className="fa fa-check" />{' '}
                                <span className={styles.markCompleteText}>
                                  {this.isComplete(a.id)
                                    ? 'Completed'
                                    : 'Mark Complete'}
                                </span>
                              </span>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    )
  }
}
