import React from 'react'
import { connect } from 'react-redux'
import EBookBanner from './components/EBookBanner/EBookBanner'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/IntroHomePage'
import PartnersCarousel from './components/PartnersCarousel/PartnersCarousel'
// import CTA from './components/CTA/CTA'
import Video from './components/Video/Video'
// import PartnersList from './components/PartnersList/PartnersList'
import Quotes from './components/Quotes/Quotes'
// import HowItWorks from './components/HowItWorks/HowItWorks'
// import Benefits from './components/Benefits/Benefits'
import Footer from './components/Footer/Footer'

// import enterpriseLogos from './logos/enterpriseLogos'
// import platformsLogos from './logos/platformsLogos'

import styles from './MainHomepage.module.css'
import Testimonials from './components/Testimonials/Testimonials'
import Products from './components/Products'

// const Background = `${STATIC_DOMAIN}/pages/new-landingpage/header-background.svg`

@connect((state) => ({
  app: state.app,
}))
export default class MainHomepage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.outerBody}>
        <div className={styles.innerBody}>
          <main className={styles.home}>
            <EBookBanner bannerColor="green" externalLink={true} />
            {/* <div
              className={styles.background1}
              style={{ backgroundImage: `url(${Background})` }}
            > */}
            <Navbar
              backgroundColors="light"
              pinkOrGreen="pink"
              logoColor="pink"
              background="white"
            />
            <Intro />
            <PartnersCarousel />
            {/* </div> */}

            {/* <CTA
              headerText="It shouldn't be so tough to consistently provide relevant insights and learning content to your teams"
              img={`${STATIC_DOMAIN}/pages/new-homepage/ctaMain.png`}
              bullets={[
                {
                  text: "Would your platform be 'stickier' with integrated content curation from day one?",
                  key: 1,
                },
                {
                  text: 'Need fresh content to drive engagement within your learning ecosystem?',
                  key: 2,
                },
              ]}
              p2Text="Anders Pink's AI-powered content curation tool delivers up-to-date content within your existing learning ecosystem."
            />
            <Video bgColor="green" />
            <PartnersList
              headerText="Partnering with the world's leading learning platforms"
              logos={platformsLogos}
            /> */}
            {/* <Quotes /> */}
            <Video bgColor="pink" />
            <Products />
            <Testimonials withOldHeader={false} />
            {/* <HowItWorks
              stepContent={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw1.png`,
                  headerText: '1. Book a Consultation',
                  bodyText:
                    "We'll work with you to identify how automated curation best fits with your platform and learning needs.",
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw2.png`,
                  headerText: '2. Access Custom Demo',
                  bodyText:
                    'Get curating instantly with fresh, customized and ready-to-go content feeds integrated into your existing learning ecosystem.',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw3.png`,
                  headerText: '3. Discover New Possibilities',
                  bodyText:
                    'Save time, effort and budget as you empower learners to upskill and stay smart. Deliver value to your business every day.',
                },
              ]}
            />
            <Benefits /> */}
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}
