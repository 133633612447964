import React from 'react'
import pageStyles from 'content/pages/styles.css'
import styles from 'content/pages/landing/styles.css'

import { Link } from 'react-router'

export default class Infographic extends React.Component {
  render() {
    return (
      <div className={pageStyles.section}>
        <div className={pageStyles.sectionHeading}>
          How does Anders Pink work?
        </div>

        <div className={styles.overviewDiagram}>
          <img
            src={`${STATIC_DOMAIN}/pages/landing/diagram2.png`}
            className={styles.diagram}
          />

          <div className={styles.textOverlay1}>
            <div className={styles.pipe}>
              <img src={`${STATIC_DOMAIN}/pages/landing/pipe1.png`} />
            </div>
            <div className={styles.pipeText}>
              <div className={styles.overviewTitle}>Discover</div>
              <div className={styles.overviewSubtitle}>
                Get the content you want from the web, in one place
              </div>
              <div className={styles.overviewText}>
                <p>
                  Too much content, too little time? Let's fix that. Anders Pink
                  dynamically aggregates content from the best of the web for
                  you, on any topic. Create custom briefings on the topics you
                  want to track, for example:
                </p>
                <ul>
                  <li>
                    Content on{' '}
                    <a
                      className="link"
                      target="_blank"
                      href="/briefing/2596/sales-latest-thinking-from-top-blogs/1-week"
                    >
                      Sales tips and best practices from your preferred 30 sites
                      and sources
                    </a>
                  </li>
                  <li>
                    Trending content on{' '}
                    <a
                      className="link"
                      target="_blank"
                      href="/briefing/8887/big-data-and-hr/3-months"
                    >
                      {' '}
                      Big Data that also mentions HR, Talent or Recruitment
                    </a>
                  </li>
                  <li>
                    Or choose from over{' '}
                    <a
                      className="link"
                      target="_blank"
                      href="/c/product-briefing-bundles"
                    >
                      150 predefined feeds on the most popular business and
                      learning topics, from carefully selected sites and sources
                    </a>
                  </li>
                </ul>
                <p>
                  Create briefings in a wide and growing{' '}
                  <a
                    className="link"
                    target="_blank"
                    href="/c/product-multi-language"
                  >
                    range of languages
                  </a>
                  .
                </p>
                <p>
                  It updates every few hours: you don’t miss what matters. Less
                  time searching, more time learning.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.textOverlay2}>
            <div className={styles.pipeText}>
              <div className={styles.overviewTitle}>Curate</div>
              <div className={styles.overviewSubtitle}>
                Add your insights, easily
              </div>
              <div className={styles.overviewText}>
                <p>
                  Our AI-powered algorithm learns from your preferences and
                  refines content to make it even more specific. Machines +
                  Humans = better curation.
                </p>

                <ul>
                  <li>
                    Recommend and comment on the best content for your
                    colleagues
                  </li>
                  <li>Share to social, engage your audience. </li>
                  <li>
                    Curate saved collections of top content, build a knowledge
                    base.{' '}
                  </li>
                  <li>
                    Drop content into Anders Pink via our{' '}
                    <a
                      className="link"
                      target="_blank"
                      href="https://chrome.google.com/webstore/detail/anders-pink/laoobdnlmddceccanlpdnfappecaefgl?hl=en-GB"
                    >
                      Chrome extension
                    </a>
                    .
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.pipe}>
              <img src={`${STATIC_DOMAIN}/pages/landing/pipe2.png`} />
            </div>
          </div>

          <div className={styles.textOverlay3}>
            <div className={styles.pipe}>
              <img src={`${STATIC_DOMAIN}/pages/landing/pipe3.png`} />
            </div>
            <div className={styles.pipeText}>
              <div className={styles.overviewTitle}>Integrate</div>
              <div className={styles.overviewSubtitle}>
                Bring fresh content into your apps, tools and platforms, every
                day
              </div>
              <div className={styles.overviewText}>
                <p>
                  Our{' '}
                  <Link to="/integrations" className="link">
                    plugins and integrations
                  </Link>{' '}
                  make it easy to display trending content or curated
                  collections in any Learning Platform, tool or app. You can
                  integrate full curation functionality into your app for your
                  clients, styled your way and completely white labelled.
                </p>

                <ul>
                  <li>
                    Bring fresh content into your Learning Platform or LMS
                  </li>
                  <li>Add fresh content to Slack, WordPress and more</li>
                  <li>Partners: Enhance your offer with powerful curation</li>
                </ul>

                <p>
                  We're plugged into over 25 platforms and tools, and adding new
                  integrations all the time. Bring us your platform, we'll make
                  it happen...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
