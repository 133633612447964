import gql from 'graphql-tag'

import { QUERIES } from './constants'

const domainListsWithItemsQuery = {
  tag: gql`
    query domainListsWithItemsQuery($domainListIds: [ID!]) {
      domainListsWithItems(domainListIds: $domainListIds) {
        id
        listName
        items {
          domain
          id
        }
      }
    }
  `,
  args: {
    options: (props) => {
      const { domainListAllowIds, domainListBlockIds, domainListType } = props
      const domainListIds =
        domainListType === 'allow' ? domainListAllowIds : domainListBlockIds
      return {
        variables: {
          domainListIds,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
    skip: ({ domainListAllowIds, domainListBlockIds, domainListType }) => {
      return domainListType === 'allow'
        ? !domainListAllowIds || domainListAllowIds.length === 0
        : !domainListBlockIds || domainListBlockIds.length === 0
    },
    name: QUERIES.DOMAIN_LISTS_WITH_ITEMS,
  },
}

export { domainListsWithItemsQuery }
