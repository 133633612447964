import React from 'react'
import { Form, Col } from 'react-bootstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import styles from './TimeRangeFilter.css'

const months = []
const dateNow = new Date()
for (let y = new Date().getFullYear(); y > 2016; y--) {
  for (let m = 12; m > 0; m--) {
    if (y === dateNow.getFullYear() && m - 1 > dateNow.getMonth()) {
      continue
    }
    months.push(y + '-' + String(m).padStart(2, '0'))
  }
}

export default class TimeRangeFilter extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.timeType === 'range' &&
      prevProps.timeType !== 'range' //&&
      // !this.props.timeStart &&
      // !this.props.timeEnd
    ) {
      this.props.onChange({
        timeStart: moment().subtract(3, 'days').format('YYYY-MM-DD'),
        timeEnd: moment().format('YYYY-MM-DD'),
      })
    }
  }
  render() {
    let { timeType, timeStart, timeEnd, onChange, loading } = this.props

    if (timeType === 'range') {
      timeStart =
        timeStart && typeof timeStart === 'string'
          ? new Date(timeStart)
          : timeStart
      timeEnd =
        timeEnd && typeof timeEnd === 'string' ? new Date(timeEnd) : timeEnd
    }

    return (
      <Form className="mb-2">
        <Form.Row className="align-items-center">
          <Col xs="auto">
            <div>Time range</div>
          </Col>
          <Col xs="auto">
            <select
              className="form-control"
              value={timeType}
              onChange={(e) => onChange({ timeType: e.target.value })}
            >
              <option value="last-month">
                In the last calendar month (
                {moment().subtract(1, 'months').format('MMM YYYY')})
              </option>
              <option value="all-time">All time</option>
              <option value="month">Month</option>
              <option value="range">Selected date range</option>
            </select>
          </Col>
          {timeType === 'month' && (
            <Col xs="auto">
              <select
                className="form-control"
                value={timeStart}
                onChange={(e) => onChange({ timeStart: e.target.value })}
              >
                <option value="">Select month</option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {moment(month).format('MMMM YYYY')}
                  </option>
                ))}
              </select>
            </Col>
          )}

          {timeType === 'range' && (
            <Col>
              <div className="mb-2 mt-2 d-flex align-items-center">
                {`From `}
                <DatePicker
                  selected={timeStart}
                  onChange={(date) => {
                    onChange({ timeStart: moment(date).format('YYYY-MM-DD') })
                  }}
                  dateFormat="yyyy-MM-dd"
                  selectsStart
                  startDate={timeStart}
                  endDate={timeEnd}
                  className={styles.input}
                />
                {` to `}
                <DatePicker
                  selected={timeEnd}
                  onChange={(date) => {
                    onChange({ timeEnd: moment(date).format('YYYY-MM-DD') })
                  }}
                  dateFormat="yyyy-MM-dd"
                  selectsEnd
                  startDate={timeStart}
                  endDate={timeEnd}
                  minDate={timeStart}
                  className={styles.input}
                />
              </div>
            </Col>
          )}
          {loading && <Col xs="auto">Loading...</Col>}
        </Form.Row>
      </Form>
    )
  }
}
