import gql from 'graphql-tag'

// Save the fragment into a variable
const briefingInfo = gql`
  fragment briefingInfo on Briefing {
    id
    name
    description
    image
    isSubscribed
    isSubscribable
    userCanEdit
    userCanDelete
    url
  }
`
export default briefingInfo
