import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import BriefingsPanel from 'home/containers/BriefingsPanel'
import TeamActivityPanel from 'home/containers/TeamActivityPanel'
import FriendsPanel from 'home/containers/FriendsPanel'
import styles from './HomePage.css'
import { Link } from 'react-router'

@connect((state) => ({
  app: state.app,
}))
export default class HomePage extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.leftPanel}>
          <div className={styles.header}>
            <div className={styles.title}>Top from your team briefings</div>
          </div>
          <BriefingsPanel />
        </div>

        <div className={styles.rightPanel}>
          <div className={styles.header}>
            <div className={styles.title}>Latest team activity</div>
            <Link to="/friends" className={styles.link}>
              See more
            </Link>
          </div>
          <TeamActivityPanel />
        </div>
      </div>
    )
  }
}
