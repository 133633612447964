import React from 'react'
import { Link } from 'react-router'
import componentsStyles from '../Components.module.css'
import Button from '../Button'

const ProductCard = ({
  index,
  title,
  description,
  enterpriseLink,
  platformsLink,
}) => {
  return (
    <div className={componentsStyles.productCard}>
      <div style={{ height: 100 }}>
        <span className={componentsStyles.header}>{title}</span>
      </div>
      <div style={{ height: 100 }}>
        <p className={componentsStyles.content}>{description}</p>
      </div>
      <div style={{ marginTop: 60 }}>
        <Link to={platformsLink}>
          <Button title="For Platforms" backgroundColor="#CF0032" />
        </Link>
        {index !== 1 && (
          <Link to={enterpriseLink}>
            <Button
              title="For Enterprise"
              backgroundColor="#CF0032"
              margin="10px 0px"
            />
          </Link>
        )}
      </div>
    </div>
  )
}

export default ProductCard
