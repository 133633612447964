import React, { Component, Fragment } from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Button } from 'react-bootstrap'
import compose from 'recompose/compose'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { formValueSelector } from 'redux-form'

import ToolTip from 'app/components/ToolTip'
import FixedModal from 'app/components/FixedModal'
import DomainListField from '../../../../sources-filter/containers/DomainListField'
import DomainBuckets from '../DomainBuckets'
import { domainListsWithItemsQuery } from './../queries'
import {
  FIELDS_DOMAIN_LISTS as FIELDS,
  DOMAIN_LIST_TYPES,
  FORM_NAMES,
  QUERIES,
} from './../constants'
import styles from './SourcesFilter.css'

const options = [
  // {
  //   label: (
  //     <>
  //       No filtering - see content from&nbsp;
  //       <span style={{ fontWeight: 'bold' }}>any source</span>
  //     </>
  //   ),
  //   value: DOMAIN_LIST_TYPES.NONE,
  // },
  {
    label: 'Block list',
    description: 'Block just some sites, see content from all others',
    value: DOMAIN_LIST_TYPES.BLOCK,
  },
  {
    label: 'Allow list',
    description: 'Only see content from an allowed set of sites',
    value: DOMAIN_LIST_TYPES.ALLOW,
  },
]

const { Option } = components

const StyledOption = (props) => <Option {...props}>{props.data.label}</Option>

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@compose(
  connect((state, props) => ({
    domainListType: selector(state, FIELDS.DOMAIN_LIST_TYPE),
    domainListAllowIds: selector(state, FIELDS.DOMAIN_LIST_ALLOW_IDS),
    domainListBlockIds: selector(state, FIELDS.DOMAIN_LIST_BLOCK_IDS),
    domainsAllowBriefing: selector(state, FIELDS.DOMAINS_ALLOW_BRIEFING),
    domainsBlockBriefing: selector(state, FIELDS.DOMAINS_BLOCK_BRIEFING),
  })),
  graphql(
    gql`
      query teamDomainListsQuery {
        session {
          team {
            id
            name
            canUsersEditDomainLists
            domainLists {
              id
              listName
              listType
              owner
            }
          }
          isTeamAdmin
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'cache-and-network',
      }),
      name: QUERIES.SESSION,
    },
  ),
  graphql(domainListsWithItemsQuery.tag, domainListsWithItemsQuery.args),
)
export default class SourcesFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      manageSitesVisibile: false,
      domainBucketsVisible: false,
      domainBucketsLoaded: false,
    }
  }
  onTypeChange = ({ value }) => {
    this.props.change(FIELDS.DOMAIN_LIST_TYPE, value)
    this.props.change(FIELDS.DOMAINS_ALLOW_BRIEFING, [])
    this.props.change(FIELDS.DOMAINS_BLOCK_BRIEFING, [])
  }
  filterDomainListItems = ({ domainLists, selectedType }) =>
    domainLists.filter(({ listType }) => listType === selectedType)

  customStyles = {
    option: (styles, { data: { owner } }) => ({
      ...styles,
      fontStyle: owner === 'account' ? 'italic' : 'normal',
    }),
  }
  handleChangeLists = (lists) => {
    const {
      domainListType,
      [QUERIES.SESSION]: {
        session: {
          team: { domainLists },
        },
      },
    } = this.props
    const ids = lists.map(({ value }) => value)
    // const domainsToAdd = domainLists
    //   .filter(({ id }) => ids.includes(id))
    //   .flatMap(({ items }) => items)
    if (domainListType === 'allow') {
      this.props.change(FIELDS.DOMAIN_LIST_ALLOW_IDS, [...ids])
    } else if (domainListType === 'block') {
      this.props.change(FIELDS.DOMAIN_LIST_BLOCK_IDS, [...ids])
    } else {
      throw Error('Unexpected domainListType')
    }
  }
  showDomainBuckets = () => {
    this.setState({ manageSitesVisibile: false, domainBucketsVisible: true })
  }
  hideDomainBuckets = () => {
    this.setState({
      manageSitesVisibile: true,
      domainBucketsVisible: false,
      domainBucketsLoaded: false,
    })
  }
  render() {
    const { loading, session } = this.props[QUERIES.SESSION]
    if (loading || !session) {
      return <div>Loading...</div>
    }
    const { domainListType, domainListAllowIds, domainListBlockIds } =
      this.props
    const selectedDomainListIds =
      domainListType === 'allow' ? domainListAllowIds : domainListBlockIds
    const {
      [QUERIES.SESSION]: {
        session: { team = {}, isTeamAdmin },
      },
    } = this.props
    const { domainLists = [], canUsersEditDomainLists = false } = team || {}
    const isPersonalAccount = !team
    const canManageLists = (team && isTeamAdmin) || canUsersEditDomainLists
    const { domainBucketsLoaded } = this.state

    const allowingSites =
      domainListType === DOMAIN_LIST_TYPES.ALLOW &&
      (this.props.domainsAllowBriefing.length > 0 ||
        this.props.domainListAllowIds.length > 0)
    const blockingSites =
      domainListType === DOMAIN_LIST_TYPES.BLOCK &&
      (this.props.domainsBlockBriefing.length > 0 ||
        this.props.domainListBlockIds.length > 0)

    return (
      <div>
        <p>
          <span style={{ color: '#666666' }}>
            Block or allow only certain sites in your briefing.
          </span>{' '}
          <ToolTip>
            <p>
              Build either an allowed list of sites, or a blocked list of sites.
            </p>

            <p>
              With an allow list, you'll only see content from those sites. You
              can expand upon this list in future to get more content in.
            </p>

            <p>
              With a block list, you'll see content from all sites in our index
              except a specific list that you've chosen to block.
            </p>
          </ToolTip>
        </p>

        {!allowingSites && !blockingSites && (
          <div>You are seeing content from all sites currently.</div>
        )}

        {blockingSites && (
          <div>
            {this.props.domainsBlockBriefing.length > 0 && (
              <div>
                You are currently blocking content from:
                <div>
                  {this.props.domainsBlockBriefing.map((domain) => (
                    <Button
                      className="mr-1 mb-2 mt-2"
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        const newDomains =
                          this.props.domainsBlockBriefing.filter(
                            (d) => d !== domain,
                          )
                        this.props.change(
                          FIELDS.DOMAINS_BLOCK_BRIEFING,
                          newDomains,
                        )
                      }}
                    >
                      {domain} ×
                    </Button>
                  ))}
                </div>
              </div>
            )}
            {this.props.domainListBlockIds.length > 0 && (
              <div>
                <div>
                  {this.props.domainsBlockBriefing.length > 0
                    ? 'And sites appearing in the following lists:'
                    : 'You are currently blocking sites in the following lists:'}
                </div>

                <div>
                  {this.props.domainListBlockIds
                    .map((id) => domainLists.find((d) => d.id === id))
                    .filter((dl) => !!dl)
                    .map((dl) => (
                      <Button
                        className="mr-1 mb-2 mt-2"
                        size="sm"
                        variant="primary"
                        onClick={() => {
                          const newListIds =
                            this.props.domainListBlockIds.filter(
                              (id) => id !== dl.id,
                            )
                          this.props.change(
                            FIELDS.DOMAIN_LIST_BLOCK_IDS,
                            newListIds,
                          )
                        }}
                      >
                        {dl.listName} ×
                      </Button>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}

        {allowingSites && (
          <div>
            {this.props.domainsAllowBriefing.length > 0 && (
              <div>
                You are currently only viewing content from:
                <div>
                  {this.props.domainsAllowBriefing.map((domain) => (
                    <Button
                      className="mr-1 mb-2 mt-2"
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        const newDomains =
                          this.props.domainsAllowBriefing.filter(
                            (d) => d !== domain,
                          )
                        this.props.change(
                          FIELDS.DOMAINS_ALLOW_BRIEFING,
                          newDomains,
                        )
                      }}
                    >
                      {domain} ×
                    </Button>
                  ))}
                </div>
              </div>
            )}
            {this.props.domainListAllowIds.length > 0 && (
              <div>
                <div>
                  {this.props.domainsAllowBriefing.length > 0
                    ? 'And sites appearing in the following lists:'
                    : 'You are currently only viewing content from sites in the following lists:'}
                </div>

                <div>
                  {this.props.domainListAllowIds
                    .map((id) => domainLists.find((d) => d.id === id))
                    .filter((dl) => !!dl)
                    .map((dl) => (
                      <Button
                        className="mr-1 mb-2 mt-2"
                        size="sm"
                        variant="primary"
                        onClick={() => {
                          const newListIds =
                            this.props.domainListAllowIds.filter(
                              (id) => id !== dl.id,
                            )
                          this.props.change(
                            FIELDS.DOMAIN_LIST_ALLOW_IDS,
                            newListIds,
                          )
                        }}
                      >
                        {dl.listName} ×
                      </Button>
                    ))}
                </div>
              </div>
            )}
            <p>
              If you want to see other sites you can add, please go to 'Manage
              sites'.
            </p>
          </div>
        )}

        <div className="mt-2">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => this.setState({ manageSitesVisibile: true })}
          >
            Choose sites to block or allow
          </Button>
        </div>

        <FixedModal
          width="500px"
          isOpen={this.state.manageSitesVisibile}
          onRequestClose={() => this.setState({ manageSitesVisibile: false })}
          buttons={[
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.setState({ manageSitesVisibile: false })}
            >
              Continue and close
            </Button>,
          ]}
        >
          <div style={{ padding: '20px' }}>
            <div
              className="mb-3"
              style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
            >
              Manage sites
            </div>

            <div style={{ marginBottom: '10px' }}>
              <p>
                By default you'll see content from our wide range of selected,
                high-quality sites.
              </p>
              <p>
                You can edit this list and block any sites you don’t want, or
                build your own list of allowed sites.
              </p>
            </div>

            <div className={styles.options}>
              {options.map(({ label, description, value }) => (
                <div
                  key={value}
                  className={[
                    styles.option,
                    domainListType === value ? styles.optionChecked : '',
                  ].join(' ')}
                  onClick={() => {
                    this.props.change(FIELDS.DOMAIN_LIST_TYPE, value)
                    this.props.change(FIELDS.DOMAINS_ALLOW_BRIEFING, [])
                    this.props.change(FIELDS.DOMAINS_BLOCK_BRIEFING, [])
                  }}
                >
                  <div
                    className={[
                      styles.radioButton,
                      domainListType === value ? styles.radioButtonChecked : '',
                    ].join(' ')}
                  />

                  <div className={styles.optionContent}>
                    <div>
                      <strong>{label}</strong>
                    </div>
                    <div>{description}</div>
                  </div>
                </div>
              ))}
            </div>
            {domainListType !== DOMAIN_LIST_TYPES.NONE ? (
              <div>
                <div className="mt-3">
                  {domainListType === DOMAIN_LIST_TYPES.BLOCK && (
                    <p className="mb-2">Blocking sites:</p>
                  )}
                  {domainListType === DOMAIN_LIST_TYPES.ALLOW && (
                    <p className="mb-2">Allowing content from sites:</p>
                  )}

                  {/* <p className="mb-2">

                View some 

                Add the domains that you want to{' '}
                {domainListType === DOMAIN_LIST_TYPES.ALLOW ? 'see' : 'block'}{' '}
                content from, either manually or from a list of those that match
                your keywords
                <ToolTip>
                  {domainListType === DOMAIN_LIST_TYPES.ALLOW ? (
                    <p>
                      By manually adding domains to allow, we will update your
                      preview to only show content from those domains.
                    </p>
                  ) : (
                    <p>
                      By manually adding domains to block, we will update your
                      preview to not show content from those domains.
                    </p>
                  )}
                  <p>
                    You can view the popular domains that have content which
                    match your keywords. From there you can see the type of
                    content that domain produces, and quickly add them to your
                    list if you choose.
                  </p>
                </ToolTip>
              </p> */}

                  <div className={styles.domainSelect}>
                    <div className={styles.domainSelectManual}>
                      <DomainListField
                        formName={FORM_NAMES.EDIT_BRIEFING}
                        field={
                          domainListType === DOMAIN_LIST_TYPES.ALLOW
                            ? FIELDS.DOMAINS_ALLOW_BRIEFING
                            : FIELDS.DOMAINS_BLOCK_BRIEFING
                        }
                        listType={domainListType}
                        placeholder={
                          domainListType === DOMAIN_LIST_TYPES.BLOCK
                            ? 'Type or copy in sites here to block them'
                            : 'Type or copy in sites here to allow them'
                        }
                      />
                    </div>
                  </div>

                  {domainListType === DOMAIN_LIST_TYPES.BLOCK && (
                    <p className="mt-3 mb-1">
                      View sites that may appear in your briefing and optionally
                      block them:
                    </p>
                  )}

                  {domainListType === DOMAIN_LIST_TYPES.ALLOW && (
                    <p className="mt-3 mb-1">
                      View sites that may appear in your briefing and optionally
                      allow them:
                    </p>
                  )}
                  <div className="mt-0">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={this.showDomainBuckets}
                    >
                      View sites
                    </button>
                  </div>

                  {!isPersonalAccount &&
                  Array.isArray(domainLists) &&
                  domainLists.filter(
                    ({ listType }) => listType === domainListType,
                  ).length > 0 ? (
                    <div style={{ marginBottom: '70px' }}>
                      <p className="mt-3 mb-2">
                        And/or use pre-defined{' '}
                        {domainListType === DOMAIN_LIST_TYPES.ALLOW
                          ? 'Allowlists'
                          : 'Blocklists'}{' '}
                        <ToolTip>
                          <p>
                            We will merge the sites in your list with those
                            manually chosen above. To manage your lists please
                            go to your team's{' '}
                            <Link to={`/filter-sources`} target="_blank">
                              list settings
                            </Link>
                            .
                          </p>
                          <p>
                            Allow/block lists contain a list of sites and can be
                            applied to multiple briefings, making it easy to
                            manage which content can be seen or blocked.
                          </p>
                        </ToolTip>
                      </p>
                      {Array.isArray(domainLists) ? (
                        <>
                          {domainLists.length > 0 ? (
                            <CreatableSelect
                              menuPlacement="top"
                              isMulti
                              formatCreateLabel={(inputValue) =>
                                `${inputValue}`
                              }
                              createOptionPosition="first"
                              options={domainLists
                                .filter(
                                  ({ listType }) => listType === domainListType,
                                )
                                .map(({ id, listName, owner }) => ({
                                  value: id,
                                  label: `${listName}`,
                                  owner,
                                }))}
                              isValidNewOption={() => false}
                              onChange={(selectedLists) =>
                                this.handleChangeLists(selectedLists)
                              }
                              value={domainLists
                                .filter(({ id }) =>
                                  selectedDomainListIds.includes(id),
                                )
                                .map(({ id, listName, listType }) => ({
                                  value: id,
                                  label: listName,
                                }))}
                              styles={this.customStyles}
                            />
                          ) : canManageLists ? (
                            <>
                              You currently have no pre-defined lists - go{' '}
                              <Link to={`/filter-sources`}>here</Link> to set
                              one up
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </FixedModal>

        <FixedModal
          styles={
            domainBucketsLoaded
              ? {
                  content: {
                    maxWidth: '70%',
                    minWidth: '500px',
                    width: 'initial',
                  },
                }
              : {}
          }
          isOpen={this.state.domainBucketsVisible}
          onRequestClose={this.hideDomainBuckets}
          buttons={[
            domainBucketsLoaded ? (
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.hideDomainBuckets}
              >
                Continue and close
              </button>
            ) : null,
            !domainBucketsLoaded ? (
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.hideDomainBuckets}
              >
                Cancel
              </button>
            ) : null,
          ]}
        >
          <DomainBuckets
            onLoaded={() => this.setState({ domainBucketsLoaded: true })}
          />
        </FixedModal>
      </div>
    )
  }
}
