import React from 'react'
import styles from './ArticlePinModal.css'
import moment from 'moment'
import uniq from 'lodash/uniqBy'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import articleInfoFragment from 'articles/fragments/articleInfo'
import FixedModal from 'app/components/FixedModal'
import AddBoardForm from 'boards/containers/AddBoardForm'
// @todo change the article to be a fragment, as used on briefing page too..

@connect((state) => ({
  userId: state.app.userId,
  appQueryResult: state.app.appQueryResult,
}))
@graphql(
  gql`
    query($id: ID!) {
      article(id: $id) {
        ...articleInfo
        userSaves {
          boardId
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'articleQuery',
    options: (props) => ({
      variables: {
        id: props.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation($articleId: ID!, $boardId: ID!) {
      saveArticle(articleId: $articleId, boardId: $boardId) {
        ...articleInfo
        userSaves {
          boardId
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'saveArticle',
    options: (props) => ({
      variables: {
        articleId: props.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation($articleId: ID!, $boardId: ID!) {
      unSaveArticle(articleId: $articleId, boardId: $boardId) {
        ...articleInfo
        userSaves {
          boardId
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'unSaveArticle',
    options: (props) => ({
      variables: {
        articleId: props.id,
      },
    }),
  },
)
export default class ArticlePinModal extends React.Component {
  static defaultProps = {
    onRequestClose: () => {},
    onPinChange: () => {},
  }
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      hasPinStateChanged: false,
    }
  }
  onClose = () => {
    this.setState({
      isOpen: false,
    })
    setTimeout(() => {
      this.props.onRequestClose()

      if (this.state.hasPinStateChanged) {
        this.props.onPinChange()
      }
    }, 100)
  }
  render() {
    if (this.props.articleQuery.loading) {
      return false
    }

    let sessionBoards = this.props.appQueryResult.boards.slice(0)
    sessionBoards = sessionBoards.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })
    let userBoards = this.props.appQueryResult.user.boards.slice(0)
    userBoards = userBoards.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })

    return (
      <FixedModal
        isOpen={this.state.isOpen}
        onRequestClose={this.onClose}
        buttons={[
          <button className="btn btn-sm btn-secondary" onClick={this.onClose}>
            Close
          </button>,
        ]}
      >
        <div className={styles.root}>
          Choose folders to save this article to:
          <AddBoardForm
            onAdd={() => {
              this.props.sessionQuery.refetch()
            }}
          />
          <div className={styles.boards}>
            {sessionBoards.map((board) => {
              const saved =
                this.props.articleQuery.article.saves
                  .map((save) => save.boardId)
                  .indexOf(board.id) >= 0
              const onClick = () => {
                if (saved) {
                  this.props
                    .unSaveArticle({
                      variables: {
                        boardId: board.id,
                      },
                    })
                    .then(() => {
                      this.setState({ hasPinStateChanged: true })
                    })
                } else {
                  this.props
                    .saveArticle({
                      variables: {
                        boardId: board.id,
                      },
                    })
                    .then(() => {
                      this.setState({ hasPinStateChanged: true })
                    })
                }
              }
              return (
                <div key={board.id} className={styles.board} onClick={onClick}>
                  <span>{board.name}</span>
                  <span className={styles.check}>
                    <i
                      className={
                        saved ? 'fa fa-check-square-o' : 'fa fa-square-o'
                      }
                    />
                  </span>
                </div>
              )
            })}
          </div>
          {this.props.appQueryResult.team !== null && (
            <div>
              Or choose personal folders to save to:
              <div className={styles.boards}>
                {userBoards.map((board) => {
                  const saved =
                    this.props.articleQuery.article.userSaves
                      .map((save) => save.boardId)
                      .indexOf(board.id) >= 0
                  const onClick = () => {
                    if (saved) {
                      this.props
                        .unSaveArticle({
                          variables: {
                            boardId: board.id,
                          },
                        })
                        .then(() => {
                          this.setState({ hasPinStateChanged: true })
                        })
                    } else {
                      this.props
                        .saveArticle({
                          variables: {
                            boardId: board.id,
                          },
                        })
                        .then(() => {
                          this.setState({ hasPinStateChanged: true })
                        })
                    }
                  }

                  return (
                    <div
                      key={board.id}
                      className={styles.board}
                      onClick={onClick}
                    >
                      <span>{board.name}</span>
                      <span className={styles.check}>
                        <i
                          className={
                            saved ? 'fa fa-check-square-o' : 'fa fa-square-o'
                          }
                        />
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </FixedModal>
    )
  }
}
