import React from 'react'
import { SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { browserHistory } from 'react-router'
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import DigestForm from '../components/DigestForm'
import GET_TEAM from '../queries/team'

const GET_DIGEST = gql`
  query NewslettersById($id: ID!) {
    NewslettersById(id: $id) {
      id
      newsletterTitle
      sendingInterval
      sendingDay
      articlesFilter
      withSingleArticlePerSource
      briefingType
      briefingIds
      articlesLimit
      recipientsType
      userIds
      withAdditionalEmails
      withBriefingName
      withArticleDescription
      withViewInAnderspink
      additionalEmailAddresses
      disclaimer
      emailTitle
      bannerImageUrl
      bannerImageWidth
      teamId
      digestTime
      digestTimeZone
      headline
      footerLine
      emailFromName
      emailFromAddress
      createdAt
      updatedAt
    }
  }
`

const UPDATE_DIGEST = gql`
  mutation updateNewsletter($newsletter: NewsletterInput, $id: ID) {
    updateNewsletter(newsletter: $newsletter, id: $id) {
      id
    }
  }
`

const GET_UNSUBSCRIBED_USERS = gql`
  query UnsubscribedUsersForNewsletter($id: ID!) {
    UnsubscribedUsersForNewsletter(id: $id) {
      newsletterId
      emails
    }
  }
`

const isEmail = (string) => {
  const emailValidation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return emailValidation.test(String(string).toLowerCase())
}

let EditDigestPage = ({
  error,
  valid,
  NewslettersById,
  updateNewsletter,
  UnsubscribedUsersForNewsletter,
  dispatch,
  teamData,
  params,
}) => {
  const NewslettersProps = NewslettersById
  if (!teamData.session) {
    return <div>Loading</div>
  }
  if (NewslettersProps.loading) {
    return <div>Loading...</div>
  }
  if (UnsubscribedUsersForNewsletter.loading) {
    return <div>Loading...</div>
  }
  if (NewslettersProps.error) {
    return <div>{NewslettersProps.error}</div>
  }

  const onSubmit = (values) => {
    if (values.emailFromAddress && !isEmail(values.emailFromAddress)) {
      dispatch({
        type: 'ADD_NOTIFICATION',
        content: `The email address is invalid`,
        level: 'danger',
      })
      return
    } else {
      updateNewsletter({
        variables: {
          newsletter: {
            newsletterTitle: values.newsletterTitle,
            sendingInterval: values.sendingInterval,
            sendingDay: values.sendingDay,
            articlesFilter: values.articlesFilter,
            withSingleArticlePerSource: values.withSingleArticlePerSource,
            briefingType: values.briefingType,
            briefingIds: values.briefingIds,
            articlesLimit: values.articlesLimit,
            recipientsType: values.recipientsType,
            userIds: values.userIds,
            withAdditionalEmails: values.withAdditionalEmails,
            withBriefingName: values.withBriefingName,
            additionalEmailAddresses: values.additionalEmailAddresses,
            disclaimer: values.disclaimer,
            emailTitle: values.emailTitle,
            digestTime: values.digestTime,
            digestTimeZone: values.digestTimeZone,
            bannerImageUrl:
              values.bannerImage && values.bannerImage.image
                ? values.bannerImage.image
                : null,
            bannerImageWidth:
              values.bannerImage && values.bannerImage.width
                ? values.bannerImage.width
                : null,
            headline: values.headline.replace(/\n\r/g, '<br />'),
            footerLine: values.footerLine.replace(/\n\r/g, '<br />'),
            emailFromName: values.emailFromName,
            emailFromAddress: values.emailFromAddress,
            withArticleDescription: !!values.withArticleDescription,
            withViewInAnderspink: !!values.withViewInAnderspink,
          },
          id: params.id,
        },
      })
        .then(() => {
          browserHistory.push({
            pathname: '/newsletters',
            state: { refetch: true },
          })
          dispatch({
            type: 'ADD_NOTIFICATION',
            content: `Digest succesfully edited`,
          })
        })
        .catch((err) => {
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            throw new SubmissionError({
              _error: err.graphQLErrors[0].message,
            })
          } else if (err.networkError) {
            throw new SubmissionError({
              _error: err.networkError.message,
            })
          } else {
            throw new SubmissionError({
              _error: err.message,
            })
          }
        })
    }
  }

  const nl = NewslettersProps.NewslettersById
  const ue = UnsubscribedUsersForNewsletter.UnsubscribedUsersForNewsletter

  let initialValues = {
    newsletterTitle: nl.newsletterTitle,
    sendingInterval: nl.sendingInterval,
    sendingDay: nl.sendingDay,
    articlesFilter: nl.articlesFilter,
    withSingleArticlePerSource: nl.withSingleArticlePerSource,
    briefingType: nl.briefingType,
    briefingIds: nl.briefingIds,
    articlesLimit: nl.articlesLimit,
    recipientsType: nl.recipientsType,
    userIds: nl.userIds,
    unsubscribedUsers: ue.emails,
    withAdditionalEmails: nl.withAdditionalEmails,
    withBriefingName: nl.withBriefingName,
    additionalEmailAddresses: nl.additionalEmailAddresses,
    disclaimer: nl.disclaimer,
    emailTitle: nl.emailTitle,
    bannerImage: {
      image: nl.bannerImageUrl,
      width: nl.bannerImageWidth,
    },
    digestTime: nl.digestTime,
    digestTimeZone: nl.digestTimeZone,
    headline: nl.headline,
    footerLine: nl.footerLine,
    emailFromName: nl.emailFromName,
    emailFromAddress: nl.emailFromAddress,
    withArticleDescription: nl.withArticleDescription,
    withViewInAnderspink: nl.withViewInAnderspink,
  }
  return (
    <div>
      <h5>Edit current digest</h5>
      <DigestForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        valid={valid}
        error={error}
        briefings={teamData.session.team.ownedBriefings || []}
        users={teamData.session.team.teamUsers || []}
        unsubscribedUsers={ue.emails || []}
      />
    </div>
  )
}

EditDigestPage = compose(
  withApollo,
  graphql(GET_DIGEST, {
    name: 'NewslettersById',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(GET_UNSUBSCRIBED_USERS, {
    name: 'UnsubscribedUsersForNewsletter',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(GET_TEAM, {
    name: 'teamData',
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(UPDATE_DIGEST, {
    name: 'updateNewsletter',
  }),
  connect(),
)(EditDigestPage)

export default EditDigestPage
