import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './FriendsArticles.css'
import ArticleListFullPage from 'articles/components/ArticleListFullPage'
import articleInfoFragment from 'articles/fragments/articleInfo'

import Article from 'articles/containers/Article'

const ITEMS_PER_PAGE = 40

@graphql(
  gql`
    query($timeLimit: String!, $keyword: String, $offset: Int, $limit: Int) {
      friendShares(
        timeLimit: $timeLimit
        keyword: $keyword
        offset: $offset
        limit: $limit
      ) {
        id
        articles {
          ...articleInfo
        }
      }
    }
    ${articleInfoFragment}
  `,
  {
    options: (props) => {
      return {
        variables: {
          timeLimit: props.timeLimit ? props.timeLimit : '3-days',
          keyword: props.filter ? props.filter : null,

          offset: 0,
          limit: ITEMS_PER_PAGE,
        },
      }
    },
    props({ data: { loading, friendShares, fetchMore } }) {
      return {
        data: {
          loading,
          friendShares,
          loadMoreArticles() {
            return fetchMore({
              // query: ... (you can specify a different query. FEED_QUERY is used by default)
              variables: {
                offset: friendShares.articles.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return Object.assign({}, previousResult, {
                  // Append the new feed results to the old one
                  friendShares: {
                    articles: [
                      ...previousResult.friendShares.articles,
                      ...fetchMoreResult.friendShares.articles,
                    ],
                  },
                })
              },
            })
          },
        },
      }
    },
  },
)
export default class FriendsArticles extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.data.loading && !this.props.data.friendShares) {
      return <div>Please wait while we fetch your timeline from Twitter...</div>
    }

    if (!this.props.data.friendShares) {
      return <div>Sorry, something went wrong.</div>
    }

    return (
      <div>
        {this.props.data.friendShares.articles.length === 0 && (
          <div>Sorry, no articles were found.</div>
        )}

        {this.props.data.friendShares.articles.length > 0 && (
          <div>
            <ArticleListFullPage>
              {this.props.data.friendShares.articles.map((article, index) => (
                <Article key={article.id} article={article} />
              ))}
            </ArticleListFullPage>

            {this.props.data.friendShares.articles.length % ITEMS_PER_PAGE ===
              0 && (
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => this.props.data.loadMoreArticles()}
                >
                  {this.props.data.loading ? 'Loading..' : 'See more'}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
