import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import Loader from 'app/components/Loader'

const selector = formValueSelector('adminTableForm')

@reduxForm({
  form: 'adminTableForm',
})
@connect((state) => ({
  query: selector(state, 'query'),
}))
export default class AdminTable extends React.Component {
  static defaultProps = {
    onSubmit: (fields) => {},
    loadMore: () => {},
    extractKey: (row) => {
      return row.id
    },
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.query !== this.props.query) {
      // Reset the sort
      this.props.change('sort', '')
      this.props.change('sortOrder', '')
    }
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <div>
        <div style={{ margin: '20px 0' }}>
          <form onSubmit={handleSubmit(onSubmit)} className="form-inline">
            <Field
              name="query"
              component="input"
              type="text"
              className="form-control mr-2"
              placeholder="Search"
            />

            <Field name="sort" component="select" className="form-control mr-2">
              <option key="">Sort by</option>
              {Object.keys(this.props.fields).map((key) => (
                <option key={key} value={key}>
                  {this.props.fields[key].label}
                </option>
              ))}
            </Field>

            <Field
              name="sortOrder"
              component="select"
              className="form-control mr-2"
            >
              <option value="">Sort order</option>
              <option value="asc">Asc</option>
              <option value="desc">Desc</option>
            </Field>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>

            {this.props.loading && <span className="ml-2">Loading..</span>}
          </form>
        </div>
        <table className="table" style={{ width: '100%' }}>
          <thead>
            <tr>
              {Object.keys(this.props.fields).map((key) => (
                <th key={key}>{this.props.fields[key].label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row) => (
              <tr key={this.props.extractKey(row)}>
                {Object.keys(this.props.fields).map((key) => {
                  let value = row[key]

                  if (typeof this.props.fields[key].render !== 'undefined') {
                    value = this.props.fields[key].render(row[key], row)
                  }

                  return <td key={key}>{value}</td>
                })}
              </tr>
            ))}
          </tbody>
        </table>

        <div>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.props.loadMore}
          >
            Load more
          </button>
        </div>
      </div>
    )
  }
}
