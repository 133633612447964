import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'

import ReactMarkdown from 'react-markdown'

const stories = [
  //   {
  //     id: 'cibc',
  //     name: 'Canadian Imperial Bank of Commerce (CIBC)',
  //     title: 'Client engagement through curation',
  //     image: `${STATIC_DOMAIN}/pages/landing/cibc-logo.png`,
  //     challenge: 'Curating to engage audiences on social',
  //     challengeBody: `
  // CIBC provides banking and financial services across Canada. As part of their social outreach to engage prospects and customers, they wanted to source relevant content that would be interesting and helpful to their customers and prospects. While they have their own content, they recognised the importance of sharing content from wider sources to build their authority with prospects.
  //         `,
  //     approachBody: `
  // CIBC curates content from a wide range of sources, taking a regional approach. Anders Pink working with Volterra consulting helped to set up a wide range of briefings and teams for CIBC. They track sources publications in different regions in Canada so that team members in each region get highly specific and relevant local content that’s on topic and sharable with their network.\n
  // \n
  // ![CIBC](${STATIC_DOMAIN}/pages/success-stories/cibc.png "CIBC briefings")
  // \n
  // Each of these briefings updates every few hours with relevant content on banking and financial topics. These act as triggers for team members to share with their prospects and networks, and for their marketing team to share on its social accounts.  Several briefings are set to bring in content in French to support their multilingual markets.
  //         `,
  //     benefitsBody: `
  // Regular feed of content: Tracking over 100 sites and sources manually would be very time consuming for the team. Taking an automated approach to content aggregation means the team has a stream of relevant baseline content from which they can select the most relevant for their audiences.\n
  // \n
  // Building relationships: Rather than just sharing their own content, CIBC’s team can now share content from a wide variety of sources. This builds credibility and trust with their customers and prospects on social networks, bringing valuable and relevant content to their audience.\n
  // \n
  // Responsive service: The team can highlight and comment on relevant content, making it easy for colleagues to identify and quickly share relevant content with their networks, adding their personal insights.\n
  // \n
  // Mark Teofilo, Social Media Consultant at CIBC says:\n
  // \n
  // *"Our social initiative with advisors at CIBC has been a tremendous success thus far – and a large part of this is because of our robust content library. Anders Pink has been a phenomenal go-to source for us to gather and collect engaging articles – which in turn our advisors use to grow their networks and generate leads. Anders Pink continues to save our team a lot of time, and has allowed us to customize our briefings to include what we want to see, each and every day. It has truly been a life saver!"*
  //         `,
  //   },
  {
    id: 'capgemini',
    name: 'Capgemini',
    title:
      'Powering skills at Capgemini with custom curated content in Degreed',
    image: `${STATIC_DOMAIN}/pages/landing/Capgemini-color.png`,
    challenge: 'Staying up to speed takes time...',
    challengeBody: `
Trust is vital for Capgemini. Its teams need to stay up to speed on the topics that matter to their clients, and add value in conversations to build that trust. However, rapid changes across a wide variety of niche industry sectors meant that it was challenging for client-facing teams
to keep up to date on the latest developments which is essential to be competitive in client engagements.\n
Searching for relevant content across the web every day was too time-consuming for Capgemini’s multiple sales and client-facing teams. Being manual content curators for the entire organization wasn’t practical or scalable for the learning team either.
Something needed to change to improve the flow of timely, quality information, and make it easy for team members to access in Degreed.

        `,
    approachBody: `
Curate, integrate, automatically update\n
\n
Capgemini University chose Anders Pink, the leading content curation tool and Degreed partner, to help make content curation a key part of their skills and learning experience alongside a few other trusted partners.\n
\n
We worked together in three stages:\n
\n
**1. Curate with focus, control the topics and sources**\n
Anders Pink worked with Capgemini University to target a set of key skills and topic areas. Together we built a set of custom content feeds, called briefings. Each briefing is custom-built for the niche topics and contexts aligned to the skills that matter to Capgemini, using the Anders Pink content curation tool. These briefings are simple
to configure and adjust for new and emerging topics. The content is curated from a range of carefully selected sources from the public web, so Capgemini University was completely in control of the content.\n
\n
**2. Integrate seamlessly with Degreed - tag with skills, power up Plans and Pathways**\n
Our integration with Degreed made it simple to add curated content to sector-specific Plans and Pathways in Degreed.\n
Capgemini’s skills are automatically displayed in Anders Pink. Each briefing can then be tagged with the relevant skill and displayed natively in Capgemini University’s Degreed.\n
The Capgemini University learning team can create new briefings in Anders Pink and add them to a learning Plan or Pathway, or select specific content items to display and change the skills tags at any time.\n
\n
![Capgemini](${STATIC_DOMAIN}/pages/success-stories/c1.png "Capgemini plan / pathway in Degreed with AP briefing content")
![Capgemini](${STATIC_DOMAIN}/pages/success-stories/c2.png "Capgemini plan / pathway in Degreed with AP briefing content")
\n
**3. Update automatically - less time searching, more focus on learning**\n
\n
Once a briefing on a topic is set up and integrated, it updates automatically every day. This means Capgemini’s employees don’t miss any new developments in their sectors, topics or skills. It’s all delivered into Degreed, every day. No manual searching of 100 different websites for content, no manual copy and pasting of links: Our integration and AI-powered curation tool takes care of it automatically.
`,
    benefitsBody: `
**Focused curated content has driven knowledge and trust**\n
Capgemini’s sector teams continuously develop their knowledge, skills and confidence. They don’t have to spend time searching for relevant, trending content from external sites and sources. It’s right there, fresh every day, in Degreed.\n
\n
**Saving time and effort for the learning team**\n
Capgemini University’s learning team no longer need to source content manually in response to emerging skills and topic needs. Curated content flows directly into Degreed from Anders Pink and updates automatically.\n
\n
**Agile response at a lower cost**\n
Skills and topics will keep changing for Capgemini. But now they have a way of quickly responding. The Capgemini University team can change a briefing or create a new one in seven languages, in a fraction of the time it would take to create custom learning content. And at a fraction of the cost of licensing content libraries.\n
\n
**Powering Search in Degreed**\n
Every inefficient content search costs an organization time. Curated content from Anders Pink in Degreed is tagged with relevant skills, publication date, duration, title and author. This has made content search more powerful and targeted in Degreed and helps Capgemini’s teams to easily find relevant, timely content on demand.
        `,
  },
  {
    id: 'hitachi',
    name: 'Hitachi Rail Europe',
    title:
      'Building a continuous learning culture with embedded curated content',
    image: `${STATIC_DOMAIN}/pages/landing/hitachi-logo.png`,
    challenge: 'Need to Curate, Not Enough Time',
    challengeBody: `
Hitachi Rail Europe is the rolling stock manufacturing division of Hitachi, with global teams supporting rail equipment manufacturing and delivery to rail networks. Hitachi Rail provides a range of elearning and blended programmes on a range of topics to their teams. They recognised the need to go beyond formal learning and provide key audiences with recent and relevant content in the workflow. Their initial step was to ask the L&D team to take on the role of "curators", scanning and sourcing content from around the web on relevant topics to keep their content up to date. However this had some challenges as Sam Taylor, Digital Development Manager pointed out:\n
\n
*"One of the big challenges for digital learning is keeping it fresh and relevant.  We don’t have the time to continuously research and update resources, no matter how much we’d like to.  Many of us don’t have the Google skills to really narrow down search results, let alone the time to do this"*
        `,
    approachBody: `
To help with learning content curation at scale, Hitachi Rail uses a curation tool (Anders Pink) to source and filter relevant content, combined with our API and plugin for their Learning Management System (Totara LMS).  They have created briefings on a range of topics relevant to their audiences, filtered by their preferred sites and sources. These map to key competencies and learning programmes, as well as industry specific content, e.g. tracking mentions of their own company alongside competitors.\n
\n
To make it easy for end users to access this information. Hitachi Rail embed key articles in their Learning Management System in different areas via a simple plug-in, powered by Anders Pink API. This makes it very easy to embed content from either live dynamic Anders Pink briefings or curated folders with hand-picked content: 
![Hitachi](${STATIC_DOMAIN}/pages/success-stories/hitachi.png "Hitachi briefings and folders")
`,
    benefitsBody: `
Sourcing content and saving time:\n
*"Anders Pink can help us source content for us as part of our blend. This is invaluable with a small team building a whole programme of content from scratch. As a team it can give us inspiration and source material for content we want to develop. It can form part of our normal resources in support of a learning topic, embedded directly into our Learning Platform."*\n
Embedding in Learning Platform to drive continuous learning:\n
*"Because of the types of content that can be curated – such as tips or case studies, it can also provide the real life applications that can help colleagues embed learning and provide ideas which will lead to behavioural change. Through this we can encourage a cultural change where learning is something you can do for 5-10 minutes a day to help us turn into a learning organisation."*\n
        `,
  },
  {
    id: 'enterprise-ireland',
    name: 'Enterprise Ireland',
    title:
      'Driving engagement with fresh content embedded into learning platform',
    image: `${STATIC_DOMAIN}/pages/landing/ei-logo.png`,
    aboutBody: `
Enterprise Ireland (EI) is the government organisation responsible for the development and growth of Irish enterprises in world markets. The agency works in partnership with Irish enterprises to accelerate the development of world-class Irish companies to achieve leading positions in global markets.Further details on the organisation and its services are available at https://www.enterprise-ireland.com.
        `,
    challenge: 'Keep learning programmes engaging and relevant',
    challengeBody: `
The Leadership & Scaling (L& S) Department of Enterprise Ireland is tasked by Senior Management with providing accessible and affordable management development programmes for client companies, which can be continually reviewed and updated based on stakeholder feedback and which will be scalable in the longer term.
\n
\n
A core element of the online strategy is to ‘cascade’ the capability development experience to a much broader base of clients. As part of the L&S agenda we have developed an e-learning solution for clients - [eiLearn](https://eilearn.ie/). This is EI’s digital learning platform available in a hop on/hop off model to suit clients learning needs.
\n  
\n
Enterprise Ireland designs and delivers customised leadership and development programmes and eLearning supports to aid clients on their export journey, addressing the learning and development needs. Strategic programmes such as Enter the Eurozone, Leadership 4 Growth, Go Global 4 Growth to flexible short programmes such as Digital Lead Generation, Hybrid Selling and Spotlight on Skills.
\n
\n
Programmes are delivered in a blended format and include a content mix appropriate for the topic and audience, including formal learning resources, videos, coaching, and expert interviews.
        `,
    approachBody: `
Enterprise Ireland wanted to ensure the programmes included recent, relevant and trending content on key topics. This is to help audiences stay informed on the latest developments in these topics, and also to drive continued engagement after the formal elements of programmes are completed.
\n
\n
To achieve this, they have added trending and curated content to each of their key programmes. Enterprise Ireland has areas of growth for businesses known as ‘pillars’. These cover Strategy, Finance, Leadership & People, Sales & Marketing, Innovation and Operational Excellence. The eLearning team liased with the internal specialists of the pillars to create briefings in Anders Pink mapped to each key pillar and filtered based on key topics and sources to ensure quality, on-topic content is presented:
\n
![Enterprise Ireland](${STATIC_DOMAIN}/pages/success-stories/ei1.png "Enterprise Ireland briefing")
\n
To make access simple for end users, briefings are embedded in the relevant section of eiLearn which is publicly accessible and within their learning platform relevant to the subject area. The configuration of AP allows for the team to create general and very detailed briefings which allows users more specific applicable content as they delve into subject areas.
\n
For trending content on fast developing topics from Covid to the Future of Work to Digitalisation, the learning platform displays a live briefing, filtered to the most relevant business sites for their audience, which updates every few hours. This displays a panel beneath their own learning content on the topic as a "recommended reading" panel:
\n
![Enterprise Ireland](${STATIC_DOMAIN}/pages/success-stories/ei2.png "Enterprise Ireland recommended reading panel")
![Enterprise Ireland](${STATIC_DOMAIN}/pages/success-stories/ei3.png "Enterprise Ireland recommended reading panel")
        `,
    benefitsBody: `
**Time saving:** Enterprise Ireland’s team are able to add daily updates carefully chosen from a review across daily news media, live recordings done in conjunction with Enterprise Ireland Business experts.
\n

**Audience engagement:** Liam Ryan, Senior Executuve/eLearning Project Manager believes Anders Pink has added to the Enterprise Ireland learning offering. "Anders Pink has been very well received internally and externally to complement our blended learning programmes. Our clients have informed us that as time is precious for them, it is very advantageous to access relevant sources of content in the one location."\n 

**Ability to respond quickly to audience needs with new briefings:** The platform allows for responsive creation of content to business needs. "We have added content quickly based on the needs of the relevant programme or trends within a subject area. This has saved time and resources in sourcing content for subjects. “From Brexit through Covid and to the Future of Work, we can quickly add relevant curated content for client companies on eiLearn. “
\n

**Ease of integration:** The EI team has designed an eLearning Platform to best serve the needs of their client base. Utilising Open Source technologies, the team has built a solution which is integrated with several Learning Management Systems and applications of each programme providers and Anders Pink has been implemented seamlessly into the overall solution.
\n

See the live [Enterprise Ireland site here](https://eilearn.ie/).

At the bottom of this page is "recommended reading" - An Anders Pink briefing embedded seamlessly into the site, updating every few hours with fresh and relevant content.
        `,
  },
  {
    id: 'acc',
    name: 'ACC',
    title:
      'How Curation Improved their Learning Experience, and Saved Time and Money',
    image: `${STATIC_DOMAIN}/pages/landing/acc-logo.png`,
    aboutBody: `
The [Accident Compensation Corporation (ACC)](https://www.acc.co.nz/) is responsible for administering the no-fault accidental injury compensation scheme in New Zealand. ACC focusses on; injury prevention, rehabilitation and management of the fund that enables the scheme, and provides financial compensation and support to citizens, residents, and temporary visitors who have suffered personal injuries.
 
We spoke to Matt Marsh, Senior People Development Consultant at ACC to learn how they have embraced content curation via Anders Pink to enhance their learning experience. 
        `,
    challenge: 'Delivering to broader learning needs',
    challengeBody: `

Every organisation has more learning needs within it than it can possibly deliver to. The reality of modern learning teams is that we prioritise and triage to help meet those needs as best we can. We were reflecting on this and the concept of ‘curation not content creation’, when the idea of Anders Pink came about.

ACC’s longer-term vision had identified a number of areas where we knew would benefit from learning interventions, but with our transformation programme being the primary focus of our learning design team, we needed a tool that could quickly get content to people that was low effort to build and low maintenance going forward.
        `,
    approachBody: `
##### Project:

From the documents that inform our organisational priorities, we identified key words for potential learning topics. Working with senior leaders these ‘topics’ were ranked as being of High, Medium and Low priority to the business. This whittled ~90 potential topics down to what would become ~25 Anders Pink content pages. The likely business owners for each topic were also identified through this process. We mocked up an MVP draft page (literally just a drawing):

From here we got sign off for a trial of five topic pages, which after socialising grew from a pilot into a project of 25 topic pages due to interest.

![](${STATIC_DOMAIN}/pages/landing/acc1.png)

The business owners helped by writing the introductory blurb for each topic and providing the four static ‘Recommended Reading’ articles. We used them again to help curate the search criteria / filters for the dynamic ‘Further Reading’ articles. After signing off the pages we took them live.

##### Initial launch: Test and refine

We determined the pages would be watched closely for two weeks post go-live given the nature of some of the content (e.g. some topics around ‘Diversity and Inclusion’ were more likely to throw up controversial or misaligned articles), and our want to provide the best and most inclusive experience for our people. We proactively sharpened the search criteria during this time to help produce better results, using the features in Anders Pink to make the searches more specific. 

##### Ongoing: Curation Becomes BAU

After the initial launch period was complete the library of 25+ pages was moved from ‘project’ to BAU. Our curriculum owner role (a role in our team that owns ‘courses’ and resources once they’re live) checks the pages once every three months to confirm the searches are still working appropriately. Business owners are contacted if we need further input in helping tailor any of the results.

#### From Sketch to Reality!

We use a balance of dynamic feeds from Anders Pink and curated articles chosen by ACC specialists from Anders Pink briefings:

- Recommend Reading - Static Anders Pink block with articles chosen by business champions / specialists

![](${STATIC_DOMAIN}/pages/landing/acc2.png)

- Further Reading - Dynamic Anders Pink block with articles pulled using keyword inclusions and exclusions

![](${STATIC_DOMAIN}/pages/landing/acc3.png)
        `,
    benefitsBody: `
**Cost savings**: Using Anders Pink has helped us build a library of ACC prioritised content at a fraction of a regular ‘library subscription’ cost – as you’re paying for the curation tool, not the content.

**Better and broader content**: We were able to provide a broader base of content across a larger set of organisational priorities than we would have otherwise.

**Dynamic updating**: The pages should have at least some new content every time a learner goes to them, keeping our platform fresh.

**Speed of response**: The tool allows us to have quick wins in places we haven’t built out more significant material yet.

**Scalable and replicable**: Once the page template was built, other pages were rolled out by staff completely unrelated to the original project with ease. We went from a handful of topics to 35+ easily.

**Blended Learning**: We now have one more learning tool to use in our problem-solving arsenal.
        `,
  },
  {
    id: 'scania',
    name: 'Scania',
    title: 'Driving engagement by supporting the business through rapid change',
    image: `${STATIC_DOMAIN}/pages/landing/scania-logo2.png`,
    aboutBody: null,
    challenge: 'Supporting the business through rapid change',
    challengeBody: `
[Scania](https://www.scania.com/) is a world-leading provider of transport solutions, operating in over 100 countries. Technology is rapidly changing how the transport sector operates, which means companies like Scania need to stay abreast of the latest developments in areas of strategic importance. The Covid-19 pandemic accelerated this change.

And that’s not all: as well as the increasing speed of change and digital transformation, Scania has to grapple with the explosion of knowledge and information that results from this change. Information dates quickly so there is a need for the organisation to identify the most relevant trends and insights amongst the noise on the web.
  
All this change creates another challenge for the learning team: learning is changing too. It is now a continuous process focused on supporting skills development.
  
As Lars Kjellander, Learning Expertise at Scania Academy, says, “We realised learning has to change and the way we drive learning has to change. It's not only about continuous learning, it's also about continuous reskilling within the job that you have.” 

Anders Pink will continue to play a part in the Scania approach to continuous learning and reskilling. 

Here’s how we are working together:
    
    `,
    approachBody: `
The learning team set up a range of topic specific learning portals that were owned by the business, rather than being managed by the learning team. The learning team set up the portals and then trained the business in how to use them. This meant the business could drive its own learning portals. The portals were built on the company intranet and are integrated with the company's learning management system so that content can feed into the portal from a number of sources.

Soon after the portals were set up, Lars and his team discovered Anders Pink. This enabled Scania to start streamlining its content curation process. "Anders Pink was a tool we could put in the hands of the portal owners to make the curation work much more efficiently."
  
Curators in Scania create briefings in Anders Pink on a range of topics, including: Leadership, Human Resources, E-mobility, Future of transport and more. These enable them to stay up to date on the topics that matter to them and their teams. They then choose the most relevant articles, and save them into folders. These are then displayed, with editorial commentary in Scania's portals. 

What's really powerful about this approach is the combination of automated curation via the Anders Pink tool, and the human curation by Scania's experts, to ensure the most recent and relevant content is brought directly to users via the platform integration. 

End users do not need to log into Anders Pink - the relevant content is seamlessly embedded into their platform.
    

![](${STATIC_DOMAIN}/pages/landing/scania1.jpg)
        `,
    benefitsBody: `
Anders Pink is supporting Scania in their continued plans to build a continuous learning habit in a number of ways. Their automatically updated feeds of content on topics of strategic importance are helping:

- Support the company around digital transformation
- Manage the explosion in knowledge and information
- Support continuous learning and reskilling
  
"Using Anders Pink is a key component of our curation strategy at Scania. It’s creating a seamless experience for visitors to the portal together with our own legacy content. Anders Pink is crucial for us to offer the latest and greatest content on key topics for our business,” says Lars.
  
And by helping make the curation process efficient and easy to do, the learning team has started to shift its focus to support the business more effectively, as Lars explains:  "This approach changes the purpose of the learning team to become an enabler and a support function, not the house of courses."
  
Find out how Anders Pink can help your business and platform with curation and continuous learning: [Get in touch](/contact). 
        `,
  },
  {
    id: 'chalhoub',
    name: 'Chalhoub',
    title: 'Enhancing employee learning opportunities with curated content',
    image: `${STATIC_DOMAIN}/pages/landing/chalhoub-logo.png`,
    aboutBody:
      'The Chalhoub Group, the leading partner for luxury across the Middle East, has always had a strong internal culture of education. The Chalhoub University was created to offer employees access to workshops, coaching, mentoring, and other learning opportunities.',
    challenge: null,
    challengeBody: `
The Chalhoub University team had spent considerable time setting up a digital platform for their university. To source the content for and publish more than 100 educational resources had taken months of painstaking work. With one curated resource requiring around two to three hours of input, the Chalhoub University team could see it would not be practical to dedicate these sorts of resources to updating and maintaining the content going forward.

**The Chalhoub University team needed a system** to help them with the ongoing process of **researching, curating, analysing, redesigning, and summarising content**. This applied to both the university offering and the frontliner focused micro-learning tool.
`,
    approachBody: `
Having researched content curation systems and providers, Marcello Fernandes, Digital Learning Lead of the Chalhoub Group, says they chose Anders Pink because they realised they needed a partner who was:

- **Flexible** in their approach,
- Happy to allow them to **collaborate** in the process rather than providing a one-size-fits-all option,
- Able to offer **API integration** across multiple platforms with completely different technology stacks,
- Offering an easy-to-set-up, **intuitive** system,
- Flexible around the pilot and launch and the ability to use it as **proof of concept**.

***“We’re really happy that we connected with you”***
`,
    processBody: `
Anders Pink helped the Chalhoub University team set up and publish resources alongside existing content on two different platforms, Axonify and Chalhoub University. The platforms work in very different ways and Marcello and his team felt “fantastically supported” during the integration process. In fact, they considered the integration of the content curation tool into Axonify to be **“a real triumph”**. And the client with whom they worked on Axonify was so impressed that they are also looking to use the Anders Pink tool for their own purposes.

The Chalhoub University team now has content curation running alongside existing experiencial programmes in the Chalhoub University in Moodle, and in Axonify for frontliners. They use different briefings for different platforms. Teams even use curated content within their own communications, not just to publish. When curating for their own programmes it offers insight into what’s up to date and fresh.

***The Anders Pink content curation tool is “a great product”***
`,
    impactBody: `
- The **statistics have hit an elevated curve and are continuing to climb** every month.
- Every time the Chalhoub University team introduces the concept of curated content alongside other offerings it’s a **“wow factor”**, helping them develop closer relationships with their internal clients.
- Content curation was previously a role carried out by senior members of the team. Now the entire team can curate as they all take on the role of a leader in the field.

***“Working with AP we can have the entire team curating”***

- The Chalhoub University team have been able to partner with people outside their own team and ask them how they wish to curate for their own space. This means content is now more **driven by the business** than by L&D. 
- Now that the Chalhoub University team has a dedicated content curation tool, they can build on it. It is easier to **build on** something that already exists and refine it than it is to create it.

*** “We’re looking forward to what’s coming”***

Please [get in touch] (/contact) to find out how Anders Pink can help your business with content curation and continuous learning, we would love to hear from you.
`,
  },
]

@connect((state) => ({
  app: state.app,
}))
export default class ClientSuccessStory extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    const story =
      stories.find((story) => story.id === this.props.params.id) || stories[0]

    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundImage: 'url(/pages/landing/shutterstock_306713030.jpg)',
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>{story.name}</div>
            {!!story.title && (
              <div className={pageStyles.subtitle}>{story.title}</div>
            )}
          </div>
        </div>

        <div className={styles.logo}>
          <img src={story.image} />
        </div>

        {!!story.aboutBody && (
          <div className={pageStyles.section}>
            <div className={[pageStyles.textContainer, styles.text].join(' ')}>
              <ReactMarkdown source={story.aboutBody} />
            </div>
          </div>
        )}

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            Challenge: {story.challenge}
          </div>

          <div className={[pageStyles.textContainer, styles.text].join(' ')}>
            <ReactMarkdown source={story.challengeBody} />
          </div>
        </div>

        <div className={pageStyles.greySection}>
          <div className={pageStyles.innerGreySection}>
            <div className={pageStyles.sectionHeadingThin}>Approach</div>
            <div className={[pageStyles.textContainer, styles.text].join(' ')}>
              <ReactMarkdown source={story.approachBody} />
            </div>
          </div>
        </div>

        {story.benefitsBody && (
          <div className={pageStyles.section}>
            <div className={pageStyles.sectionHeadingThin}>Benefits:</div>

            <div className={[pageStyles.textContainer, styles.text].join(' ')}>
              <ReactMarkdown source={story.benefitsBody} />
            </div>
          </div>
        )}

        {story.processBody && (
          <div className={pageStyles.section}>
            <div className={pageStyles.sectionHeadingThin}>Process:</div>

            <div className={[pageStyles.textContainer, styles.text].join(' ')}>
              <ReactMarkdown source={story.processBody} />
            </div>
          </div>
        )}

        {story.impactBody && (
          <div className={pageStyles.greySection}>
            <div className={pageStyles.innerGreySection}>
              <div className={pageStyles.sectionHeadingThin}>Impact</div>
              <div
                className={[pageStyles.textContainer, styles.text].join(' ')}
              >
                <ReactMarkdown source={story.impactBody} />
              </div>
            </div>
          </div>
        )}

        <div className={styles.technical}>
          <div className={styles.technicalContent}>
            <p className={styles.technicalTop}>
              Find out how we can help you power your learning with content
              curation
            </p>
            <Link to="/contact" className="btn btn-lg btn-primary">
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
