import React, { Component } from 'react'
import BrowseCategories from 'discover/containers/BrowseCategories'
export default class DisocverPage extends Component {
  static defaultProps = {}
  render() {
    return (
      <div>
        <h5 style={{ marginBottom: '20px' }}>Browse through our briefings</h5>

        <div className="mt-5">
          <BrowseCategories />
        </div>
      </div>
    )
  }
}
