import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router'

import styles from './EBookForm.module.css'

const ebookTablet = `${STATIC_DOMAIN}/pages/new-homepage/ebook_ipad.png`

const EBookForm = (props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [complete, setComplete] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .post(`/ebook-newsletter-signup`, {
        firstName,
        lastName,
        email,
        acceptedTerms,
      })
      .then((response) => {
        setComplete(true)
        setFirstName('')
        setLastName('')
        setEmail('')
        setAcceptedTerms(false)
        setError(false)
      })
      .catch((err) => {
        setComplete(false)
        setError(true)
      })
  }

  return (
    <section className={styles.eBookForm} id="ebook-form">
      <img src={ebookTablet} className={styles.eBookImg} />
      <div className={styles.eBookText}>
        <p className={styles.preHeader}>Download our free guide:</p>
        <header className={styles.header}>
          Curating Content for Learning: 5 Simple Steps
        </header>
        <p className={styles.mainBody}>
          If you are already curating content for your business, or would like
          to but don't have the time - this eBook is written for you. Inside we
          share 5 steps to make your content curation a success, including:
        </p>
        <p className={styles.boldPoints}>Why it matters.</p>
        <p className={styles.boldPoints}>How to do it right.</p>
        <p className={styles.boldPoints}>Tips, tools & examples.</p>
        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex' }}>
            <input
              type="text"
              placeholder="First name"
              className={styles.fnInput}
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last name"
              className={styles.fnInput}
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            className={styles.emailInput}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="form-check mb-2">
            <label className="form-check-label">
              <input
                name="terms"
                component="input"
                type="checkbox"
                value={!!acceptedTerms}
                onChange={(e) => setAcceptedTerms(!!e.target.value)}
                required
                // normalize={(v) => {
                //   return !!v ? true : false
                // }}
                className="form-check-input"
              />{' '}
              Receive occasional updates from us including new features,
              articles and communications. You can opt out at any time.
            </label>
          </div>

          {complete && (
            <div className="alert alert-success mt-2 mb-2">
              Thank you! You'll shortly receive an email containing your eBook.
            </div>
          )}

          {error && (
            <div className="alert alert-danger mt-2 mb-2">
              Sorry an error occured, please try again or contact us.
            </div>
          )}

          <div>
            <input
              type="submit"
              value="Submit"
              className={styles.downloadBtn}
            />
          </div>
        </form>

        {props.returnLink && (
          <Link to="/" className={styles.returnLink}>
            <i className="fa fa-arrow-left" />
            {'  '}Return to homepage
          </Link>
        )}
      </div>
    </section>
  )
}

export default EBookForm
