import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'
import Loader from 'app/components/Loader'
import ReactFileReader from 'briefings/components/ReactFileReader'

@graphql(
  gql`
    query($id: ID!) {
      briefing(id: $id) {
        id
        name
        url
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation($briefingId: ID!, $csv: String!) {
      updateBriefingTrainingData(briefingId: $briefingId, csv: $csv)
    }
  `,
  {
    props: ({ mutate }) => ({
      updateBriefingTrainingData: (briefingId, csv) =>
        mutate({
          variables: { briefingId, csv },
        }),
    }),
    options: {
      refetchQueries: [],
    },
  },
)
export default class BriefingTrainingPage extends React.Component {
  constructor() {
    super()
    this.state = {
      uploading: false,
    }
  }
  render() {
    return (
      <div>
        <h4 className="mb-2">Briefing Training</h4>

        <h5>Upload manual training data</h5>

        <br />
        <p>
          To train your briefing, you can upload a CSV with two columns, labeled
          Text and Classification. The text should ideally be a paragraph or
          two, but can be as long as 10,000 characters. The classification
          should have the word relevant or irrelevant.
        </p>

        <p>Example CSV:</p>
        <p
          style={{
            border: '1px solid #EEE',
            backgroundColor: ' #FFF',
            padding: '1rem',
          }}
        >
          <code>
            Text,Classification
            <br />
            "Some example text, this shouldn't be longer than 10,000
            characters",relevant
            <br />
            "Some more example text, mark this text as irrelevant to the
            briefing",irrelevant
            <br />
          </code>
        </p>

        <p>
          Your briefing will be trained using the text provided. Upload a new
          CSV to replace the previously uploaded data. This works in parralel
          with any individual articles that are marked as relevant/irrelevant.
          Any text you upload is kept private.
        </p>

        <div style={{ display: 'flex' }}>
          <ReactFileReader
            elementId="upload"
            multipleFiles={false}
            disabled={this.state.uploading}
            base64={true}
            fileTypes={['.csv']}
            handleFiles={(file) => {
              this.setState({
                uploading: true,
              })
              this.props
                .updateBriefingTrainingData(
                  this.props.data.briefing.id,
                  file.base64,
                )
                .then(() => {
                  this.setState({
                    uploading: false,
                  })
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Training data successfully applied`,
                  })
                })
                .catch((err) => {
                  this.setState({
                    uploading: false,
                  })
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Sorry there was an error: ${err.message}`,
                    level: 'danger',
                  })
                })
            }}
          >
            <button className="btn btn-primary">
              Choose CSV file and upload
            </button>
          </ReactFileReader>

          <button
            className="btn btn-secondary ml-2"
            onClick={() => {
              const emptyCSV =
                'data:text/csv;base64,VGV4dCxDbGFzc2lmaWNhdGlvbgo=' // haha...
              this.props
                .updateBriefingTrainingData(
                  this.props.data.briefing.id,
                  emptyCSV,
                )
                .then(() => {
                  this.setState({
                    uploading: false,
                  })
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Training data removed`,
                  })
                })
                .catch((err) => {
                  this.setState({
                    uploading: false,
                  })
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Sorry there was an error: ${err.message}`,
                    level: 'danger',
                  })
                })
            }}
          >
            Delete training data
          </button>
        </div>

        <div>{this.state.uploading && <Loader />}</div>
      </div>
    )
  }
}
