exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DropdownMenu__root___lnBqa {\n  position: relative;\n}\n\n.DropdownMenu__topRoot___3tFdz {\n}\n@media (max-width: 768px) {\n  .DropdownMenu__topRoot___3tFdz {\n    position: static;\n  }\n}\n\n.DropdownMenu__title___MhvxP {\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n.DropdownMenu__items___2M8CS {\n  margin-top: 5px;\n  position: absolute;\n  right: 0;\n  min-width: 250px;\n  background: #ffffff;\n  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);\n          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);\n  /*white-space: nowrap;*/\n  color: #333;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  max-width: 320px;\n  z-index: 1;\n}\n\n.DropdownMenu__leftItems___3Aq9p {\n  right: auto;\n  left: 0;\n}\n.DropdownMenu__item___XCDg3 {\n  margin: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "DropdownMenu__root___lnBqa",
	"topRoot": "DropdownMenu__topRoot___3tFdz DropdownMenu__root___lnBqa",
	"title": "DropdownMenu__title___MhvxP",
	"items": "DropdownMenu__items___2M8CS",
	"leftItems": "DropdownMenu__leftItems___3Aq9p DropdownMenu__items___2M8CS",
	"item": "DropdownMenu__item___XCDg3"
};