import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router'
import styles from './CookiePopup.css'

class CookiePopup extends React.Component {
  constructor(props) {
    super()

    const cookieDismissed = document.cookie
      .split(';')
      .map((cookie) => cookie.split('='))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {},
      ).cookieDismissed

    this.state = {
      cookieDismissed: !!cookieDismissed,
    }
  }
  render() {
    if (this.state.cookieDismissed) return null
    return (
      <div className={styles.root}>
        <div>
          Anders Pink uses cookies to ensure you get the best experience on our
          website{' '}
          <Link className="link" to="/c/cookie-policy">
            More info
          </Link>
        </div>
        <Button
          variant="outline-light"
          onClick={() => {
            let date = new Date()
            date.setDate(date.getDate() + 365)
            document.cookie = `cookieDismissed=true; expires= ${date}`
            this.setState({ cookieDismissed: true })
          }}
        >
          Close
        </Button>
      </div>
    )
  }
}

export default CookiePopup
