exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TeamSettingsPage__section___o-ah8 {\n  margin: 20px 0px 40px 0px;\n}\n.TeamSettingsPage__sectionTitle___COIrY {\n  font-size: 1.2em;\n  margin-bottom: 10px;\n}\n.TeamSettingsPage__rows___20FHC {\n}\n.TeamSettingsPage__row___2zcDZ:last-of-type {\n  border-bottom: 0 none;\n}\n.TeamSettingsPage__pending___8IRhP {\n  color: #aaa;\n  font-style: italic;\n}\n.TeamSettingsPage__signupLink___3ax4B {\n  padding: 5px;\n  background-color: #f2f2f2;\n  margin-bottom: 10px;\n}\n.TeamSettingsPage__permissions___1R0np {\n  padding: 10px;\n  border: 1px solid #eeeeee;\n}\n", ""]);
// Exports
exports.locals = {
	"section": "TeamSettingsPage__section___o-ah8",
	"sectionTitle": "TeamSettingsPage__sectionTitle___COIrY",
	"rows": "TeamSettingsPage__rows___20FHC",
	"row": "TeamSettingsPage__row___2zcDZ",
	"pending": "TeamSettingsPage__pending___8IRhP",
	"signupLink": "TeamSettingsPage__signupLink___3ax4B",
	"permissions": "TeamSettingsPage__permissions___1R0np"
};