import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import withAuthCheck from 'app/containers/withAuthCheck'
import { isLoggedIn } from 'app/selectors/appSelectors'
import { graphql } from 'react-apollo'
import moment from 'moment'
import gql from 'graphql-tag'
import styles from './D2L.css'
import { DropdownMenu } from 'app/components/DropdownMenu'
import Loader from 'app/components/Loader'
import LoginPanel from './LoginPanel'
import BriefingArticleSelection from './BriefingArticleSelection'
import FolderArticleSelection from './FolderArticleSelection'
import { ContentSelector } from './ContentSelector'

const siteRoot =
  location.protocol +
  '//' +
  location.hostname +
  (location.port ? ':' + location.port : '')

const slugify = (text) => text.toLowerCase().split(' ').join('-')

const buildUrl = ({ id, name, path }) => {
  const slug = slugify(name)
  const url = `${siteRoot}/${path}/${id}/${slug}`
  return url
}
const urlForBriefing = (args) =>
  buildUrl({ ...args, path: 'briefing-embedded' })

const urlForBoard = (args) =>
  buildUrl({ ...args, path: 'saved-articles-embedded' })

const getIframeTag = ({ src }) =>
  `<iframe style='border:none; width:100%; height:400px;' src="${src}"/><br></br>`

const getIframeTagBriefing = ({ id, name }) =>
  getIframeTag({ src: urlForBriefing({ id, name }) })

const getIframeTagFolder = ({ id, name }) =>
  getIframeTag({ src: urlForBoard({ id, name }) })

const getReturnUrl = ({
  articleSelection,
  returnUrl,
  briefing,
  folder,
  selectedArticles,
  containerSelection,
}) => {
  let content
  switch (articleSelection) {
    case 'all':
      content =
        containerSelection === 'folder'
          ? getIframeTagFolder(folder)
          : getIframeTagBriefing(briefing)
      break
    case 'single':
      content = `<div style="display:flex; width:100%;">${selectedArticles
        .map(
          ({ url, title, domain, image, date, content }) =>
            `<div style="border:1px solid #ddd; border-radius:3px; padding:17px 20px; margin-bottom:20px;">
              <h4 style="margin-top:0"><a href="${url}">${title}</a></h4>
                <div>
                  <img style="width: 280px; height:auto; float:right; padding-left:20px;" src="${image}"></img>
                  ${
                    domain
                      ? `<p style="font-size: 0.9em; opacity: 0.7;">
                        ${domain && domain} ${
                          date ? `- ${moment(date).fromNow()}` : ''
                        }
                      </p>`
                      : ''
                  }
                ${
                  content !== undefined && content !== null
                    ? `<p>${content}</p>`
                    : ''
                }
               </div>
          </div>`,
        )
        .join('')}<br></br>`
      break
    default:
      throw Error('Unexpected articleSelection')
  }
  return returnUrl + '?content=' + encodeURIComponent(content)
}

@withAuthCheck
@connect((state) => ({
  layout: state.app.layout,
  isLoggedIn: isLoggedIn(state),
}))
@graphql(gql`
  query appQuery {
    session {
      user {
        id
        firstName
        lastName
        image
        teams {
          id
          name
          boards {
            id
            name
            url
            isPublic
          }
          ownedBriefings {
            id
            name
            url
            isPublic
          }
        }
      }
    }
  }
`)
export default class D2LIntegration extends React.Component {
  constructor(props) {
    super(props)
    const { search } = props.location || {}
    const returnUrl = new URLSearchParams(search).get('return_url')
    this.state = {
      teamId: '',
      folder: undefined,
      briefing: undefined,
      containerSelection: 'briefing',
      articleSelection: 'all',
      articleId: '',
      success: false,
      error: null,
      selectedArticles: [],
      returnUrl,
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      data: { loading, session: { user } = {} },
    } = this.props

    if (
      (loading && !nextProps.data.loading) ||
      (!user && nextProps.data.session.user)
    ) {
      const session = nextProps.data.session
      if (this.props.isLoggedIn && session && session.user) {
        const user = session.user

        if (!this.state.teamId && user.teams.length > 0) {
          const teamId = user.teams[0].id
          this.setState({
            teamId: teamId,
          })

          this.setDefault(nextProps, teamId, this.state.containerSelection)
        }
      }
    }
  }
  handleArticleSelection = (e) => {
    this.setState({
      articleSelection: e.target.value,
    })
  }
  handleContainerSelection = (e) => {
    const containerSelection = e.target.value
    this.setState({
      containerSelection,
    })
    this.setDefault(this.props, this.state.teamId, containerSelection)
  }
  setDefault(props, teamId, containerSelection) {
    if (containerSelection === 'briefing') {
      const briefings = this.getBriefings(props, teamId)
      this.setState({
        briefing:
          briefings.length > 0
            ? briefings.find((b) => b.isPublic) || briefings[0]
            : '',
      })
    } else if (containerSelection === 'folder') {
      const folders = this.getFolders(props, teamId)
      this.setState({
        folder: folders.length > 0 ? folders[0] : '',
      })
    }
  }
  getBriefings(props, teamId) {
    const { user } = props.data.session
    const team = user.teams.find((team) => team.id == teamId)
    return team ? team.ownedBriefings : []
  }
  getFolders(props, teamId) {
    const { user } = props.data.session
    const team = user.teams.find((team) => team.id == teamId)
    return team ? team.boards : []
  }
  handleBriefingChange = (e) => {
    const { user } = this.props.data.session
    const team = user.teams.find((team) => team.id == this.state.teamId)
    const briefings = team ? team.ownedBriefings : []
    const briefing = briefings.find((b) => b.id === e.target.value)
    this.setState({
      briefing,
    })
  }
  handleFolderChange = (e) => {
    const { user } = this.props.data.session
    const team = user.teams.find((team) => team.id == this.state.teamId)
    const boards = team ? team.boards : []
    const folder = boards.find((b) => b.id === e.target.value)
    this.setState({
      folder,
    })
  }
  onArticleClick = (article) => {
    const articles = this.state.selectedArticles
    const selected = articles.find((a) => a.id === article.id)

    this.setState({
      selectedArticles: selected ? [] : [article],
    })
  }
  saveSelection = () => {
    const returnUrl = getReturnUrl(this.state)
    document.location = returnUrl
  }

  render() {
    if (this.props.data.loading && !this.props.data.session) {
      return (
        <div style={{ marginTop: '80px' }}>
          <Loader />
          <div
            style={{ textAlign: 'center', marginTop: '10px', color: '#888' }}
          >
            Loading..
          </div>
        </div>
      )
    }
    const onLoginSuccess = () => {
      this.props.data.refetch()
      this.forceUpdate()
    }
    const session = this.props.data.session
    const isLoggedIn = this.props.isLoggedIn && session && session.user
    if (!isLoggedIn) {
      return (
        <div className={styles.root}>
          <div className={styles.center}>
            <LoginPanel
              redirectPath="/d2l-integration"
              onLoginSuccess={onLoginSuccess}
            />
          </div>
        </div>
      )
    }

    const { user } = session
    const team =
      (Array.isArray(user.teams) &&
        user.teams.length > 0 &&
        user.teams.find((team) => team.id == this.state.teamId)) ||
      user.teams[0]
    const boards = team ? team.boards : []
    const briefings = team ? team.ownedBriefings : []
    const { containerSelection } = this.state
    const currentFolder =
      this.state.folder || boards.find((b) => b.isPublic) || boards[0] || {}
    const currentBriefing =
      this.state.briefing ||
      briefings.find((b) => b.isPublic) ||
      briefings[0] ||
      {}
    const sourceIsPublic =
      containerSelection === 'folder'
        ? currentFolder.isPublic
        : currentBriefing.isPublic
    const sourceHasItems =
      containerSelection === 'folder' ? boards.length > 0 : briefings.length > 0
    return (
      <div className={styles.root}>
        <header className={styles.header}>
          <div>
            <a href="/">
              <img
                className={styles.icon}
                src="https://app.anderspink.com/ap-logo.png"
              />
            </a>
          </div>
          <div>
            {!isLoggedIn && (
              <Link className={styles.headerLink} to="/login">
                Login
              </Link>
            )}
            {isLoggedIn && (
              <div className={styles.headerLink}>
                <DropdownMenu top={true}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={styles.profilePic}
                      src={session.user.image}
                    />{' '}
                    <span>
                      <span className={styles.username}>
                        {session.user.firstName}
                      </span>{' '}
                      <i className="fa fa-caret-down" />
                    </span>
                  </div>
                  <div>
                    <Link
                      to="/logout?redirect=/d2l-integration"
                      className={styles.dropdownLink}
                    >
                      Logout
                    </Link>
                  </div>
                </DropdownMenu>
              </div>
            )}
          </div>
        </header>

        {team && (
          <div className={styles.content}>
            <div className="form-group">
              <label>1) Select a team</label>
              <select
                className="form-control"
                value={this.state.teamId}
                onChange={(e) => {
                  const teamId = e.target.value
                  const team = user.teams.find((team) => team.id == teamId)
                  const boards = !team ? session.user.boards : team.boards
                  const briefings = team ? team.ownedBriefings : []
                  const briefing = briefings.find((b) => b.isPublic)
                  this.setState({
                    teamId: teamId,
                    folder: boards.length > 0 ? boards[0] : '',
                    briefing,
                    success: false,
                    error: null,
                  })
                }}
              >
                {session.user.teams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className={styles.label}>
                <span>2) Select a briefing or saved folder</span>
              </label>

              <div className="mt-2 mb-1">
                <label className="mr-2 d-inline-block">
                  <input
                    type="radio"
                    value="briefing"
                    checked={containerSelection === 'briefing'}
                    onChange={this.handleContainerSelection}
                  />{' '}
                  Briefing
                </label>
                <label className="d-inline-block">
                  <input
                    type="radio"
                    value="folder"
                    checked={containerSelection === 'folder'}
                    onChange={this.handleContainerSelection}
                  />{' '}
                  Saved folder
                </label>
              </div>

              {containerSelection === 'folder' && (
                <ContentSelector
                  value={currentFolder.id}
                  onChangeHandler={this.handleFolderChange}
                  items={boards}
                />
              )}

              {containerSelection === 'briefing' && (
                <ContentSelector
                  value={currentBriefing.id}
                  onChangeHandler={this.handleBriefingChange}
                  items={briefings}
                />
              )}
            </div>
            {!sourceHasItems ? (
              <div className="form-group">
                <p>
                  You don't currently have any {containerSelection}s in Anders
                  Pink.
                </p>
              </div>
            ) : null}
            {sourceHasItems && !sourceIsPublic && (
              <div className="form-group">
                <p>Please select a publically-exported {containerSelection}</p>
              </div>
            )}
            {sourceIsPublic && sourceHasItems && (
              <div className="form-group">
                <div className={styles.flex}>
                  <label className={styles.label}>
                    <span>
                      3) Choose how you would like this content to appear
                    </span>
                  </label>
                  <div className={styles.saveButton}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={this.saveSelection}
                      disabled={
                        this.state.articleSelection === 'single' &&
                        this.state.selectedArticles.length === 0
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="mt-1 mb-1">
                  <label className="mr-2 d-inline-block">
                    <input
                      type="radio"
                      value="all"
                      checked={this.state.articleSelection === 'all'}
                      onChange={this.handleArticleSelection}
                    />{' '}
                    Feed from {containerSelection} (dynamic)
                  </label>
                  <label className="d-inline-block">
                    <input
                      type="radio"
                      value="single"
                      checked={this.state.articleSelection === 'single'}
                      onChange={this.handleArticleSelection}
                    />{' '}
                    Single static article (click to select)
                  </label>
                </div>

                {this.state.articleSelection === 'single' &&
                  containerSelection === 'briefing' && (
                    <div>
                      <BriefingArticleSelection
                        id={currentBriefing.id}
                        onArticleClick={this.onArticleClick}
                        selectedArticles={this.state.selectedArticles}
                      />
                    </div>
                  )}
                {this.state.articleSelection === 'single' &&
                  containerSelection === 'folder' && (
                    <div>
                      <FolderArticleSelection
                        id={currentFolder.id}
                        onArticleClick={this.onArticleClick}
                        selectedArticles={this.state.selectedArticles}
                      />
                    </div>
                  )}
                {this.state.articleSelection === 'all' &&
                  containerSelection === 'briefing' && (
                    <div
                      className={styles.previewContainer}
                      dangerouslySetInnerHTML={{
                        __html: getIframeTagBriefing(currentBriefing),
                      }}
                    />
                  )}
                {this.state.articleSelection === 'all' &&
                  containerSelection === 'folder' &&
                  currentFolder.id && (
                    <div
                      className={styles.previewContainer}
                      dangerouslySetInnerHTML={{
                        __html: getIframeTagFolder(currentFolder),
                      }}
                    />
                  )}
              </div>
            )}
            {this.state.success && (
              <div className="alert alert-success" role="alert">
                Article saved!
              </div>
            )}
            {this.state.error && (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}
          </div>
        )}
        {!team && (
          <div className={styles.content}>
            <div className="form-group">
              <p className={styles.message}>
                Sorry, you must be in a Team to use this plug-in. Please contact
                your Anders Pink account administrator.
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }
}
