import React from 'react'
import { Link } from 'react-router'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import CreatableSelect from 'react-select/creatable'
import compose from 'recompose/compose'

@compose(
  graphql(
    gql`
      query teamAccountDomainListsQuery($teamId: ID!, $accountId: ID!) {
        session {
          isTeamAdmin
        }
        teamAccountDomainLists(teamId: $teamId, accountId: $accountId) {
          id
          listName
          listType
        }
        account(id: $accountId) {
          id
          domainLists {
            id
            listName
            listType
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            teamId: props.teamId,
            accountId: props.accountId,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
  graphql(
    gql`
      mutation($teamId: ID!, $accountId: ID!, $domainListIds: [ID!]) {
        updateTeamAccountDomainLists(
          teamId: $teamId
          accountId: $accountId
          domainListIds: $domainListIds
        ) {
          id
          listName
          listType
          items {
            id
            domain
          }
        }
      }
    `,
    {
      props: ({ mutate }) => ({
        updateTeamAccountDomainLists: (teamId, accountId, domainListIds) =>
          mutate({
            variables: { teamId, accountId, domainListIds },
          }),
      }),
    },
  ),
)
export default class TeamAccountDomainLists extends React.Component {
  constructor() {
    super()
    this.state = { teamAccountDomainLists: [] }
  }
  handleChange = (teamId, accountId, items) => {
    const domainListIds = items.map(({ value }) => value)
    this.props
      .updateTeamAccountDomainLists(teamId, accountId, domainListIds)
      .then(() => {
        this.props.data.refetch()
      })
  }
  componentDidUpdate(prevProps) {
    const { data: { loading, teamAccountDomainLists } = {} } = this.props
    const { data: { loading: wasLoading } = {} } = prevProps
    if (!loading && wasLoading) {
      this.setState({ teamAccountDomainLists })
    }
  }
  customStyles = {
    multiValue: (styles, { data: { listType } }) => ({
      display: 'flex',
      marginRight: '5px',
      backgroundColor: listType === 'allow' ? 'lightGreen' : '#ff9c9c',
    }),
    multiValueRemove: (styles, { data: { listType } }) => ({
      ...styles,
      color: '#777',
      backgroundColor: listType === 'allow' ? 'lightGreen' : '#ff9c9c',
      ':hover': { color: '#000' },
    }),
    valueContainer: (styles) => ({
      ...styles,
      margin: 0,
      padding: 0,
      marginLeft: '5px',
    }),
    placeholder: (styles) => ({
      ...styles,
    }),
  }
  render() {
    if (!this.props.data && this.props.data.session.isTeamAdmin) {
      return <div>Sorry, you don't have permission to manage this team.</div>
    }
    const { account: { domainLists = [] } = {} } = this.props.data
    const { teamId, accountId } = this.props
    const { teamAccountDomainLists = [] } = this.state
    const hasAccountDomainLists =
      Array.isArray(domainLists) && domainLists.length > 0

    return (
      <div>
        {hasAccountDomainLists ? (
          <>
            <div>
              Start typing or use the drop down below to apply Account-level
              domain lists to this team. These will be available in all the
              briefings belonging to this Team. They will be visible to briefing
              creators, but not editable by them.
            </div>
            <div className="mt-2 mb-2" style={{ maxWidth: '500px' }}>
              <CreatableSelect
                isMulti
                formatCreateLabel={(inputValue) => `${inputValue}`}
                createOptionPosition="first"
                options={domainLists.map(({ id, listName, listType }) => ({
                  value: id,
                  label: `${listName} (${listType})`,
                }))}
                value={teamAccountDomainLists.map(
                  ({ id, listName, listType }) => ({
                    value: id,
                    label: listName,
                    listType,
                  }),
                )}
                isValidNewOption={() => false}
                onChange={(items) =>
                  this.handleChange(teamId, accountId, items)
                }
                styles={this.customStyles}
              />
            </div>
          </>
        ) : (
          <div>
            You have no Account-level domain lists set up. Visit your{' '}
            <Link to={`/account/${accountId}`} className="link">
              account administration page
            </Link>{' '}
            to create some.
          </div>
        )}
      </div>
    )
  }
}
