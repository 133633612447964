import React from 'react'
import styles from './ArticleListFullPage.css'
import chunk from 'lodash/chunk'

export default class ArticleList extends React.Component {
  static defaultProps = {
    minusSpace: '240',
  }
  render() {
    let activeStyles = styles

    return (
      <div>
        <div className={activeStyles.root}>
          {this.props.children.map((child, index) => (
            <div key={child.key} className={activeStyles.item}>
              <div className={activeStyles.itemInner}>{child}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
