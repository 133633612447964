exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Panel__root___28xea {\n  -webkit-box-shadow: 0px 3px 8px 1px #d8d8d8;\n          box-shadow: 0px 3px 8px 1px #d8d8d8;\n}\n.Panel__header___21Fg9 {\n  padding: 12px;\n  background-color: #444;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.Panel__title___2eDY3 {\n  color: #fff;\n  font-size: 1.1em;\n}\n.Panel__headerLink___1hDqQ {\n  text-decoration: underline;\n  color: #bbb;\n  font-size: 0.9em;\n}\n.Panel__headerLink___1hDqQ:hover {\n  color: #fff;\n}\n.Panel__icon___1uQkn {\n  color: #868686;\n  margin-right: 10px;\n}\n.Panel__loading___3p8uR {\n  padding: 12px;\n  min-height: 200px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Panel__root___28xea",
	"header": "Panel__header___21Fg9",
	"title": "Panel__title___2eDY3",
	"headerLink": "Panel__headerLink___1hDqQ",
	"icon": "Panel__icon___1uQkn",
	"loading": "Panel__loading___3p8uR"
};