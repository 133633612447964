import React from 'react'
import moment from 'moment'

import { Form, Container, Row, Col, Table } from 'react-bootstrap'
import { browserHistory, Link } from 'react-router'

export default ({ teamId, timeType, timeStart, timeEnd, stats }) => {
  if (timeType === 'range' && timeStart) {
    timeStart = moment(timeStart).format('YYYY-MM-DD')
  }
  if (timeType === 'range' && timeEnd) {
    timeEnd = moment(timeEnd).format('YYYY-MM-DD')
  }
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Total views</th>
          <th>Publish date</th>
          <th>Domain</th>
          <th>Word count</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        {stats.length === 0 && (
          <tr>
            <td colSpan="6">No data</td>
          </tr>
        )}
        {stats.length > 0 &&
          stats.map((stat) => (
            <tr key={stat.name}>
              <td title={stat.name}>
                {stat.name.slice(0, 64) + (stat.name.length > 64 ? '...' : '')}
                <br />
                <a target="_blank" title={stat.url} href={stat.url}>
                  {stat.url.slice(0, 64) + (stat.url.length > 64 ? '...' : '')}
                </a>
              </td>
              <td>{stat.value}</td>
              <td>
                {stat.date
                  ? moment(parseInt(stat.date)).format('Do MMM YYYY') +
                    ` (${moment(parseInt(stat.date)).fromNow()})`
                  : ''}
              </td>
              <td>
                <Link
                  to={`/team-api-stats/${teamId}/domain/${
                    stat.domain
                  }?timeType=${timeType}&timeStart=${timeStart || ''}&timeEnd=${
                    timeEnd || ''
                  }`}
                >
                  {stat.domain}
                </Link>
              </td>
              <td>
                {stat.readingTime
                  ? Math.ceil(stat.readingTime / 60) + ' min read'
                  : ''}
              </td>
              <td>
                {stat.relatedEntities &&
                  stat.relatedEntities.map((e, i) => (
                    <div key={`${stat.id}_${e.type}_${e.id}`}>
                      {e.type === 'briefing' && (
                        <Link
                          to={`/team-api-stats/${teamId}/briefing/${
                            e.id
                          }?timeType=${timeType}&timeStart=${
                            timeStart || ''
                          }&timeEnd=${timeEnd || ''}`}
                        >
                          Briefing:{' '}
                          {e.name.slice(0, 32) +
                            (e.name.length > 32 ? '...' : '')}
                        </Link>
                      )}
                      {e.type === 'folder' && (
                        <Link
                          to={`/team-api-stats/${teamId}/folder/${
                            e.id
                          }?timeType=${timeType}&timeStart=${
                            timeStart || ''
                          }&timeEnd=${timeEnd || ''}`}
                        >
                          Folder:{' '}
                          {e.name.slice(0, 32) +
                            (e.name.length > 32 ? '...' : '')}
                        </Link>
                      )}
                    </div>
                  ))}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
