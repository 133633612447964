import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import SignupForm from './SignupForm'
import styles from './EmailSignupPage.css'
import CenterPage from 'app/components/CenterPage'
import TwitterSignupPanel from 'authentication/containers/TwitterSignupPanel'
import TwitterLoginLink from 'app/containers/TwitterLoginLink'
import { Link } from 'react-router'
import { postRequest } from 'app/actions/appActions'
import { SubmissionError } from 'redux-form'
import { browserHistory } from 'react-router'

@connect()
export default class EmailSignupPage extends React.Component {
  constructor(props) {
    super(props)
  }
  onSignupSubmit = (values) => {
    return this.props
      .dispatch(
        postRequest('/auth/email-signup', {
          ...values,
        }),
      )
      .then((response) => {
        const data = response.payload.data

        if (data.status === 'fail') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })

          window.location.href = '/home'
        }
      })
  }
  render() {
    return (
      <CenterPage>
        <div className={styles.panels}>
          <div className={styles.left}>
            <h4>Signup via Twitter</h4>
            <TwitterSignupPanel />
          </div>
          <div className={styles.right}>
            <h4>Create an account</h4>

            <p>
              You'll immediately be sent an email which contains a link to
              confirm your account.
            </p>

            <SignupForm onSubmit={this.onSignupSubmit} />

            <p className={styles.loginText}>
              Already have an account?{' '}
              <TwitterLoginLink>Login via Twitter</TwitterLoginLink> or{' '}
              <Link className="link" to="/login">
                login with your email address
              </Link>
            </p>
          </div>
        </div>
      </CenterPage>
    )
  }
}
