import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'
import ReactMarkdown from 'react-markdown'

import Experts from 'content/containers/Experts'
import Infographic from 'content/containers/Infographic'
import FlipCard from 'react-flipcard-2'
import Bundles from 'content/containers/Bundles'

import Lowlight from 'react-lowlight'
import js from 'highlight.js/lib/languages/javascript'

Lowlight.registerLanguage('js', js)

class CodeBlock extends React.Component {
  render() {
    return (
      <Lowlight
        language={'js'}
        value={this.props.value}
        inline={this.props.inline || false}
      />
    )
  }
}

function RouterLink(props) {
  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href} className="link" target="_blank">
      {props.children}
    </a>
  ) : (
    <Link to={props.href} className="link">
      {props.children}
    </Link>
  )
}

const clients = [
  {
    id: 'scania',
    img: `${STATIC_DOMAIN}/pages/landing/scania-logo.png`,
    content: (
      <p>
        "Using Anders Pink is a key component of our curation strategy at
        Scania. It's creating a seamless experience for visitors to the portal
        together with our own legacy content. Anders Pink is crucial for us to
        offer the the latest and greatest content on key topics for our
        business."
      </p>
    ),
  },
  // {
  //   id: 'cibc',
  //   img: `${STATIC_DOMAIN}/pages/landing/cibc-logo.png`,
  //   content: (
  //     <p>
  //       "Anders Pink has been a phenomenal go-to source for us to gather and
  //       curate engaging articles – which in turn our advisors use to grow their
  //       networks and generate leads. It saves our team a lot of time, and we can
  //       customize what we want to see."
  //     </p>
  //   ),
  //   caseStudyUrl: '/client-success-stories/cibc',
  // },
  {
    id: 'hitachi',
    img: `${STATIC_DOMAIN}/pages/landing/hitachi-logo.png`,
    content: (
      <p>
        "Curated content is now part of our resources in support of a learning
        topic, embedded directly into our Learning Platform. Through this we can
        encourage a cultural change where learning is something you can do for
        5-10 minutes a day to help us turn into a learning organization."
      </p>
    ),
    caseStudyUrl: '/client-success-stories/hitachi',
  },
  {
    id: 'kineo',
    img: `${STATIC_DOMAIN}/pages/landing/kineo-logo.png`,
    content: (
      <p>
        "Anders Pink is really useful tool. We use it on the platforms we
        design. Access to constantly updated, well targeted and easy to find
        articles make the LMS a useful destination for the learner, encouraging
        repeat visits to the platform."
      </p>
    ),
  },
  {
    id: 'fuse',
    img: `${STATIC_DOMAIN}/pages/landing/fuse-logo.png`,
    content: (
      <p>
        "Finding great external content is vital for our clients. Anders Pink is
        fully integrated into Fuse, bringing in the most popular, new content
        that people are loving. Amazing, automatic curation, saving time."
      </p>
    ),
  },
  {
    id: 'ei',
    img: `${STATIC_DOMAIN}/pages/landing/ei-logo.png`,
    content: (
      <p>
        "We have added content quickly based on the needs of the relevant
        programme or trends. This has saved time and resources in sourcing
        content for subjects. Our clients tell us as time is precious for them,
        it is very advantageous to access relevant sources of content in one
        location."
      </p>
    ),
    caseStudyUrl: '/client-success-stories/enterprise-ireland',
  },
  {
    id: 'clc',
    img: `${STATIC_DOMAIN}/pages/landing/charity-learning-logo.png`,
    content: (
      <p>
        "Our members ask more and more about content curation and how it could
        be made easier. To answer this demand and to help with continuous
        learning, we use Anders Pink. It's an absolutely brilliant tool and is
        now a key part of our blended learning solution. Why spend hours
        searching when Anders Pink can do it for you?"
      </p>
    ),
  },
  {
    id: 'mb',
    img: `${STATIC_DOMAIN}/pages/landing/mitchells-and-butler-logo.png`,
    content: (
      <p>
        "Having up to date, relevant articles on specific topics has heightened
        our learner experience, and has allowed us to create truly blended
        learning courses. This has been especially useful during our ‘Refresher
        Training' windows whereby learning content on a specific topic is
        communicated to the entire organisation."
      </p>
    ),
  },
  {
    id: 'learningpool',
    img: `${STATIC_DOMAIN}/pages/landing/learning-pool-logo.png`,
    content: (
      <p>
        "Properly curated, Internet content is an important addition to the
        learning blend. Anders Pink gives us that at the LMS level and embedded
        in courses to create dynamic learning experiences. Simple to work with,
        easy to integrate, and high value outcomes."
      </p>
    ),
  },
]

const pages = [
  {
    id: 'javascript-embed-code',
    title: 'Javascript Embed Code',
    subtitle:
      'If you can place HTML on your site, you can display content from Anders Pink',
    sections: [
      {
        type: 'markdown',
        markdown: `
Here's a really quick and easy way to bring curated content from Anders Pink into your Platform, with no need for an API or any technical integration work beyond pasting some code. 

You can use JavaScript code to display an Anders Pink live briefing or saved folder in your app or platform. 

You can configure the display and drop it anywhere you want. All you need to do is use some simple embed code - same as if you were embedding a YouTube video on a site. 

It also requires your briefing to be set to public mode (which means others could find and view your briefing on Anders Pink, but they will not be able to see any of your personal or team data). For other ways to embed, see our [integrations page](/integrations).

Javascript Embed Steps: 

**Step 1**
Create an account on [Anders Pink](/email-signup). 
    
**Step 2**
Create a new briefing on any topic, or follow some public ones. [Here's how](https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be).
    
**Step 3:**
Enable briefings / saved folders export feature on your account. This enables you to export a briefing or folder to your app or platform.
This is a paid feature. [Contact us](/contact) to set up a free trial and for pricing information. 
    
**Step 4:**
Once Export feature is enabled, go to your Export Settings panel. 
Under "Public export settings", check the briefings or saved folders you want to display in your platform or app:

![Public export settings](${STATIC_DOMAIN}/pages/plugins/js-widget1.png#img-border "Public export settings")

**Step 5:**

Copy the ID of the briefing or saved folder. The ID is a number that appears in the URL in the top of your browser when you're viewing the briefing or folder. 
    
In the following examples it is highlighted:
    
https://anderspink.com/briefing/8520/data-mining 
and for saved folders: https://anderspink.com/saved-articles/15271/brexit

**Step 6**
Use the following snippet of HTML and put it into your site.
For briefings, use the following HTML (with data-type set to briefing) and set the data-id to be your briefing or folder ID from step 5. In this example we will use ID 8520.
    
    <div class="ap-embed" data-type="briefing" data-id="8520" data-limit="20" data-time="auto"></div>
    <script type="text/javascript" src="https://anderspink.com/lib/embed-v1.js"></script>

For saved folders, use the following HTML (with data-type set to folder) and set the data-id to be your own ID from step 5. In this example we will use ID 15271.

    <div class="ap-embed" data-type="folder" data-id="15271" data-limit="20"></div>
    <script type="text/javascript" src="https://anderspink.com/lib/embed-v1.js"></script>

**Step 7:**
You can customise the embedded briefing or folder with a few options:
    
Use \`data-limit="20"\` to set an upper limit to how many articles are shown (in this example, display 20 content items). 
    
Use \`data-hide-imageless="true"\` to only show articles that come with images. This may improve aesthetics but could stop you seeing some articles from sites that do not use images.
    
Use \`data-show-comment="true"\` to show the latest comment posted by your team underneath the article. This allows you to put a comment under articles via the comment function in Anders Pink, and pass this through to your site or platform.
    
For briefings only, \`use data-time="auto"\` to let the briefing automatically choose how far back to show articles from. It will look at how often new content comes in and select a good range of articles. You can set a fixed time frame by using \`data-time="3-days"\` and \`data-time="2-weeks"\` etc.

That's it! You'll now see a briefing or saved folder wherever you've put the code on your site. 

![Example exported briefings](${STATIC_DOMAIN}/pages/plugins/js-widget2.png "Example exported briefings")

Some examples of Embedded Briefings in client apps and platforms 
    
You can use our display as is, or go further and style the generated HTML by using CSS if you want. Get in touch if you want some help and support with styling the output. 

**Go Further with our API options:**

The JS easy embed option is a great way to start curating quickly, with hardly any technical effort. If you can copy and paste, you can do it…

You can take your integration further with our [API integrations](/integrations). The API gives you:

- Tracking data to measure engagement 
- No requirement for briefings in public mode
- No need to use Javascript 
- Enhanced control over styling and display 
- Ability to bring Anders Pink briefing creation functionality into your site (Javascript embed is display only)

Want to get started with our JS easy embed or API options today? [Contact us](/contact) to set up a free trial.
                `,
      },
    ],
  },
  {
    id: 'javascript-sdk',
    title: 'Javascript SDK',
    subtitle:
      'Easily Integrate the full Anders Pink Interface into your LMS, LXP or App',
    sections: [
      {
        type: 'markdown',
        markdown: `
**Give your clients a powerful curation tool, completely white labelled, all inside your product.**

The Anders Pink Javascript SDK allows us to create a white labeled User Interface that gets embedded into your platform or tool. This UI allows you or your client admins or end users to do tasks such as creating or displaying a briefing. 

Your users do not need to log in or use Anders Pink at all when you use the SDK - it's all happening in your platform. 

Every time we add new features or update the UI, we will release a new version of the SDK. You can easily update the version you are using just by changing the script tag. This means you get to keep up to date with any UI or API changes with no developer effort required..

**Integration overview**

To use the SDK you must have a valid Anders Pink API key. 

To ensure the security of the API key, you will need to set up a small proxy which will pass data between the SDK and our API.

**Embedding the SDK**

The first step is to embed our SDK in the place where you want the UI to appear.

You'll need to fill in the onRequest function and put in some way of proxying the payload of JSON data up to your server, and then onto the Anders Pink API. 

In this example, we use jQuery to do an AJAX request to your own server. On success, we pass the response JSON into the supplied callback function.

    <div id="ap"></div>
    <script type="text/javascript" src="https://anderspink.com/dist/sdk-0.3.js"></script>
    <script type="text/javascript">
        if (window.AndersPink) {
            AndersPink.init({
                id: 'ap',
                onRequest: function(data, callback) {
                    $.ajax({
                        type: 'POST',
                        url: '/your-anderspink-proxy-endpoint',
                        data: data,
                        success: function(data) {
                            callback(data);
                        },
                        dataType: 'json',
                        contentType: 'application/json',
                    });
                },
                onBriefingCreate: function(id) {
                    console.log('Current user has created briefing with id', id);
                },
            })
        }
    </script>

**Creating a background proxy**

In the example above, you do an AJAX request to a new URL /your-anderspink-proxy-endpoint. The job of this endpoint is to pass the given JSON to the Anders Pink API and to authenticate your users in the normal way (i.e. can User A create briefings). 

You must also pass your API key in the HTTPS request to the API. 

Some example code in node.js for handling the proxy using express and node-fetch:

    const fetch = require('node-fetch');
    const express = require('express');

    const app = express();
    const httpServer = http.Server(app);

    app.post('/your-anderspink-proxy-endpoint', async function(req, res) {
    try {
        const apiResponse = await fetch('https://anderspink.com/api/v2/proxy', {
            method: 'POST',
            body: JSON.stringify(req.body),
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': '4VfnEb4L7jg1ax6VVEF6u4NoaOnRpxo0',
            },
        }).then(res => res.json());

        return res.json(apiResponse);
    } catch (err) {
        return res.json({
            status: 'error',
            message: err.message,
        })
    }
    });

**How it works**

![JavaScript SDK](${STATIC_DOMAIN}/pages/plugins/js-sdk1.png#img-border "JavaScript SDK")

**Become an Anders Pink Partner**

- Build a powerful curation tool into your offer and extend your functionality with less effort 
- Provide curation services to support your clients 
- Get technical, marketing and sales support as part of our partner programme. 

[Get in touch](/contact) for trial access to the SDK or any of our integration options.  We'd love to help you bring curation into your platform, the way you want to. 
                `,
      },
    ],
  },
  {
    id: 'product',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_212822350.jpg`,
    coverPosition: '50% 20%',
    title: 'Custom Curation App',
    subtitle: '',
    sections: [
      {
        type: 'markdown',
        markdown: `
Anders Pink is an award-winning content curation tool. We built it to make content curation for learning easier, better managed, and integrated into the tools and platforms our clients and partners already use. 
                `,
      },
      {
        type: 'markdown',
        colour: 'grey',
        title: 'See it in action',
        markdown: `
<iframe title="vimeo-player" src="https://player.vimeo.com/video/689257337" width="640" height="341" frameborder="0" allowfullscreen></iframe>
                `,
      },
      {
        type: 'markdown',
        title: "Here's how we help with curation, and how we're different",
        markdown: `
##### 1. Complete control over your curation

You want to be in control when it comes to curation and recommended content. Our app dynamically aggregates content from millions of sources. Users can create briefings (content feeds) with a combination of topics, keywords, sites and sources, e.g.

- Content on Sales tips and best practices from your preferred 30 sites and sources 
- Trending content on Big Data that also mentions HR, Talent or Recruitment

Make a briefing on anything you want. Go as wide or as narrow as you want to, and adjust it any time - [in a wide range of languages](/c/product-multi-language).

Want to get started even faster? We have over 100 pre-defined briefings on the most popular business, HR and management topics. [Check out our briefing bundles](/c/product-briefing-bundles). 

<br />

##### 2. Automated updates - don't browse, let us bring it to you

A key challenge to curation is doing it continuously. New content is published on broad and niche topics every day. Curators don't have time to find new content across a range of subjects daily.

In Anders Pink, content updates every few hours to bring in the most recent and relevant content on users' preferred topics. Users can adjust the time period to see content from the last 24 hours or go back 3 months. 

No more checking multiple sites and sources. We bring the good stuff to you and your learners, every few hours. 

<br />

##### 3. Sensing and sharing - humans + machines working better together...

Our Algorithm aggregates content powerfully, but true curation means humans add value and make sense of content with insights, and share it with the right audience.

In Anders Pink, users can 

- View content (brings you to original source)
- Recommend content to colleagues in private teams 
- Add a comment to signal the relevance of content for colleagues, and collaborate in micro-discussions on content 
- Save collections of the best content as a team 
- Share into apps like Slack and Learning Platforms 
- Share content to social networks to build audience and engagement 
- Train the briefing to bring more of what you want using our AI layer 
- Do all of this on the go in our mobile apps

<br />

##### 4. Plugins and integrations: Get curated content inside your apps and platforms:

Our plugins and integrations make it easy to display live briefings or saved folders in any Learning Platform, tool or app. You can integrate full curation functionality into your app for your clients, styled your way and completely white labelled. 

- Bring fresh content into your Learning Platform to support your learners
- Add fresh content automatically to Slack, WordPress and Social Networks 
- Partners: Add a powerful curation tool to your LMS or LXP to enhance your offer 

We have [integrations for a wide range of learning platforms, tools and apps](/integrations), and we’re adding new integrations all the time. Bring us your platform, we'll make it happen...
                `,
      },
      {
        type: 'infographic',
      },
      {
        type: 'cta',
        value: 'Let us help you be a content curation hero:',
      },
      {
        type: 'clients',
        title: 'What people are saying',
        clients: ['ei', 'cibc', 'hitachi', 'scania', 'clc', 'mb'],
      },
      {
        type: 'clients',
        title: 'Partners',
        clients: ['learningpool', 'fuse', 'kineo'],
      },
      {
        type: 'experts',
        title: 'What industry experts are saying',
      },
    ],
  },
  {
    id: 'about',
    title: 'About us',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_380614540.jpg`,
    coverPosition: '50% 80%',
    sections: [
      {
        type: 'markdown',
        title: 'A new way to learn every day',
        markdown: `
The Anders Pink team has been in Learning technology for a long time. We've designed and built digital learning courses, blended programmes, Learning Management Systems and grown successful businesses including Kineo, now a leader in the global learning market. 

We are passionate about helping learners and their organisations to stay smarter every day. We started Anders Pink because we believed there was a better way to help people to continuously learn: look beyond the formal, unchanging course, and access the most relevant content from millions of site and sources on the web. 

Sure - you can do that on your own. But it isn't easy. Manual curation is a slow, painful, hit-and-miss activity: scouring the web, tracking thousands of sites, wasting time looking at the wrong stuff, copying and pasting into the LMS, or slack channel or onto social networks, falling out of the habit and behind on trending content that matters. Sound familiar? It does to us and it wasn't working. 

So we built Anders Pink for ourselves first and foremost - we wanted to find a better way to stay on top of the content, sites and sources we learned the most from and curate from them, without having to check them all every day. That's what we do for you: Bring in the best of the web, tailored to your teams' needs, updating every day, inside the tools and platforms you already use. We are here to fix that, with fresh, integrated, updating content, every few hours. Powerful algorithms and teams working together to keep everyone smarter every day. 
                `,
      },
      {
        type: 'markdown',
        title: 'Saving time and staying smart',
        markdown: `
Since launching the beta of Anders Pink in late 2017, it's grown to become a fully integrated feature in the world's leading learning platforms, tools and apps.
                `,
      },
      {
        type: 'blocks',
        blocks: [
          {
            markdown: `
#### 1m+
\n
**People** can now access continous learning through Anders Pink
                        `,
          },
          {
            markdown: `
#### 38,000
\n**Topics** being tracked by Anders Pink
`,
          },
          {
            markdown: `
#### 25+ 
\n
**Leading** platforms and partners with Anders Pink plugged in
`,
          },
        ],
      },
      {
        type: 'markdown',
        markdown: `
We're serving over 1 million learners, some of the world's leading organisations, and we're just getting started.

We're delighted to work with leading clients including Spotify, Scania, Hitachi and many more, and we partner with leading learning platform companies to bring curation into their offer. 
See our case studies here. 
                `,
      },
      {
        type: 'employees',
      },
      {
        type: 'markdown',
        markdown: `
We'd love to help you with a new way to stay smart every day. [Get in touch](/contact) and let's make it happen. 
                `,
      },
    ],
  },
  {
    id: 'privacy-policy',
    title: 'Privacy Policy',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_380614540.jpg`,
    coverPosition: '50% 80%',
    sections: [
      {
        type: 'markdown',
        markdown: `
Updated May 2018

We are committed to protecting and respecting your privacy.

This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting AndersPink.com (our site) you are accepting and consenting to the practices described in this policy.

    

INFORMATION WE MAY COLLECT FROM YOU

We may collect and process the following data about you:

    

Information you give us. You may give us information about you by filling in forms on our site or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register to use our site, subscribe to our service, and when you report a problem with our site. The information you give us may include your name, address, e-mail address and phone number, and financial and credit card information (held securely via Stripe our payments processor).

    

Information we collect about you. With regard to each of your visits to our site we may automatically collect the following information:technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page.

    

COOKIES

Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. Please see our cookie policy for more details.

    

GOOGLE ANALYTICS

We use Google Analytics to gain insight into how visitors interact with the site. We use this data to improve and enhance user experience and roll out future changes with new and returning visitors in mind. The information generated by the cookie about your use of our website (including your IP address) will be transmitted to and stored by Google on servers. If you opt out, your experience of the site will not change. However, we will be unable to anonymously analyse your use of the website and make appropriate improvements.
    


HOW WE USE THIS INFORMATION:

We use information held about you in the following ways:

    

Information you give to us

We will use this information:

- to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information and services that you request from us;
- to provide you with information about other services we offer that are similar to those that you have already purchased or enquired about;
- to provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you;
- to notify you about changes to our service;
- to ensure that content from our site is presented in the most effective manner for you and for your computer.
    
Information we collect about you

We will use this information:

- to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
- to improve our site to ensure that content is presented in the most effective manner for you and for your computer;
- to allow you to participate in interactive features of our service, when you choose to do so;
- as part of our efforts to keep our site safe and secure;
- to provide you with a daily digest email containing links to content based on the briefings or topics you have created or followed on Anders Pink. You can opt out of receiving these at any time in your profile settings.
- to occasionally contact you and other users of our site with updates about our products, services and related offers. You can opt out of receiving these at any time in your profile settings.   


SHARING DATA WITH THIRD PARTIES

We do not and never will sell your data to third parties.

We use the following sites to help us provide our services to you and may share your data with these sites strictly for the purpose of providing our services:

Stripe: When you purchase our services via credit card, any credit card information you provide as part of your Payment Information is collected and processed directly by our payment processor Stripe through their Stripe Checkout service. We never receive or store your full credit card information. Stripe commits to complying with the Payment Card Industry Data Security Standard (PCI-DSS) and using industry standard security. Stripe may use your Payment Information in accordance with their own Privacy Policy here: https://stripe.com/us/checkout/legal.

    

WHERE WE STORE YOUR PERSONAL DATA

Data that we collect from you is stored in the EU.

The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA“) depending on third parties' data arrangements.

It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.

Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.

    

YOUR RIGHTS AND OPTIONS

Opt outs:

You have the right to opt out and ask us not to process your personal data for marketing purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data.

These are in your user profile settings in anderspink.com. Please be aware that if you opt-out of certain messages it we may not be able to provide some of our services to you.

You can also exercise the right at any time by contacting us at team@anderspink.com

Cookie Blocking:

You can remove or block certain cookies using the settings in your browser but please be aware that our products and services may cease to function properly if you do so. For more information, please see our cookie policy.

Third Party Links:

Our site may, from time to time, contain links to and from the websites of our partner networks and affiliates. You are under no obligation to follow these links. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.

    

ACCESS TO INFORMATION

Under the Data Protection Act 2018, you have rights as an individual which you can exercise in relation to the information we hold about you.

You can read more about these rights here: https://ico.org.uk/for-the-public/is-my-information-being-handled-correctly/

The DPA gives you the right to access information held about you. Your right of access can be exercised in accordance with the DPA.



CHANGES TO OUR PRIVACY POLICY

We reserve the right to change our Privacy Policy at any time. If we make changes, we will post them and will indicate on this page the policy’s new effective date. If we make material changes to this policy, we will notify you by email or through notice via our product. Please check back frequently to see any updates or changes to our privacy policy.

    

CONTACT

Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to team@anderspink.com

Thank you for trusting us with your data. We take privacy seriously and will ensure it is protected.
                `,
      },
    ],
  },
  {
    id: 'terms-and-conditions',
    title: 'Terms & Conditions',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_380614540.jpg`,
    coverPosition: '50% 80%',
    sections: [
      {
        type: 'markdown',
        markdown: `
Updated May 2018

PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE OR THE Anders Pink APP

www.anderspink.com is a site operated by Anders Pink Limited (“We“). We are registered in England and Wales under company number 9302389 and have our registered office at 44-46 Old Steine, Brighton BN1 1NH.

We are a limited company.

1) TERMS OF SERVICE

1.1 These terms of service (together with the links to other policies and terms referred to in it) tells you the terms of service on which you may make use of our website, www.anderspink.com (our site), whether as a guest, a registered user, or as user of the Anders Pink App. Use of our site includes accessing, browsing, or registering to use our site or the Anders Pink App.

1.2 If you do not agree to these terms of service, you must not use our site or the Anders Pink App.

1.3 If you are a consumer, you have the right to withdraw from your transaction to use the “Paid For” Anders Pink App (the Paid App) without charge and without any reason before using the Paid App. However, you will lose the right to cancel the transaction once you begin using the Paid App. This does not affect your consumer rights if the Paid App is defective.

1.4 By using our site and/or the Anders Pink App, you confirm that you accept these terms of service and that you agree to comply with them. If you do not agree to these terms of service, you must not use our site or the Anders Pink App.

2) OTHER APPLICABLE TERMS

2.1 These terms of service refer to the following additional terms, which also apply to your use of our site and/or the Anders Pink App:

2.1.1 our [Privacy Policy](/c/privacy-policy) and

2.1.2 our [Cookie Policy](/c/cookie-policy)

3) CHANGES TO THESE TERMS

3.1 We may revise these terms of service at any time by amending this page.

3.2 Please check this page from time to time to take notice of any changes we made, as they are binding on you.

4) ACCESSING OUR SITE

4.1 Our site is made available free of charge. Use of the Anders Pink App may be with or without charge subject to the version you choose.

4.2 We do not guarantee that our site will always be available or be uninterrupted. Access to our site is permitted on a temporary basis.

4.3 You are responsible for making all arrangements necessary for you to have access to our site.

4.4 You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of service and other applicable terms and conditions, and that they comply with them.

5) USE OF THE ANDERS PINK APP

5.1 We offer two versions of the Anders Pink App:

5.1.1 the Paid App: Use of this version is subject to you agreeing to pay a subscription fee, and

5.1.2 the Free App: Use of this version is free but it only offers limited functionality

5.2 In consideration of you paying the subscription fee or, if you use the Free App, agreeing to abide by these terms of service, we grant you a non-transferable, non-exclusive licence to use the Anders Pink App subject to these terms (and the other documents referred to in them). We reserve all other rights.

6) ACCEPTABLE USE RESTRICTIONS

You must:

6.1 not use the Anders Pink App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms of service, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the Anders Pink App or our site;

6.2 not infringe our intellectual property rights or those of any third party in relation to your use of the Anders Pink App to the extent that such use is not licensed by these terms of service;

6.3 not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Anders Pink App;

6.4 not use the Anders Pink App in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users;

6.5 not engage in any non-legitimate or excessive use of the Anders Pink App and, by way of illustration only, we consider the following to be indicative of non-legitimate or excessive use: (a) re-selling your subscription; (b) sharing subscriptions between users; or (c) making automated searches or briefings  or unusual search patterns inconsistent with normal, individual subscription use;

6.6 not collect or use data from the Anders Pink App or any Anders Pink API, to provide a direct competitor product to Anders Pink or substitute for Anders Pink;

6.7 not collect or harvest any information or data from our systems or attempt to decipher any transmissions to or from the servers running the Anders Pink App.

7) LIMITED WARRANTY

7.1 We warrant that:

7.1.1 the Anders Pink App will, when properly used and on an operating system for which it was designed, perform substantially in accordance with the functions described on our site; and

7.1.2 that our site correctly describes the operation of the Anders Pink App in all material respects.

7.2 The warranty does not apply if you breach any of these terms of service.

7.3If you are a consumer, this warranty is in addition to your legal rights in relation to software that is faulty or not as described.

8) YOUR ACCOUNT AND PASSWORD

8.1 If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to or share it with anyone else.

8.2 We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of service.

8.3 If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at team@Anders Pink.com

9) INTELLECTUAL PROPERTY RIGHTS

9.1 Intellectual Property of content on our site and in the Anders Pink app remains with the originator of the content. We are the owner or the licensee content that we produce on our website, or for Anders Pink App. Content presented in the app from third parties, including content from users, remains the IP of those third parties. Anders Pink is not responsible for the content presented from third parties, or for the privacy or other policies of sites on which this content resides.

10) NO RELIANCE ON INFORMATION

10.1 The content on our site and the results generated by the Anders Pink App are provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site or the results generated by the Anders Pink App.

10.2 Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.

11) PAID (Pro) APP

11.1 Our Paid (Pro) App is available under a number of subscription plans (each a Subscription Plan). Details of the Subscription Plans will follow during the beta period. The app will be free to use whilst in beta.

11.2 After the beta period, if you sign up to a subscription plan, depending on which Subscription Plan you choose, you agree to subscribe for it for the Trial Period or the Initial Subscription Period. The relevant period starts on the date we accept your offer for a subscription (the Start Date).

11.3 After the Trial Period or the Initial Subscription Plan, depending upon your chosen Subscription Plan, and subject to paragraph 11.4, we will automatically renew your subscription for the Paid App for either the Initial Subscription Period or successive Renewal Periods, as the context requires.

11.4 Your subscription will not renew if:

11.4.1 either you or we notify the other of termination, in writing, at any time before the end of the Trial Period (if applicable), the Initial Subscription Period or any Renewal Period, in which case your subscription shall terminate upon the expiry of the Trial Period, Initial Subscription Period or Renewal Period (as the context requires); or

11.4.2 it is otherwise terminated in accordance with the provisions of these terms of service.

11.5 If you terminate your subscription, you will not be entitled to a refund after billing has occurred for an Initial Subscription Period or any Renewal Period. We will, however, refund Subscription Fees (on a pro rata basis) when we have terminated your subscription before the end of an Initial Subscription Period or, if applicable, a Renewal Period.

11.6 Where the Subscription Plan provides for a Trial Period, we will not charge any Subscription Fees for that period.  

11.7 You must on the Start Date provide us (via our billing partner Stripe) with valid, up-to-date and complete credit or debit card details and any other contact and billing details we may require.

11.8 By providing your credit or debit card details to us, you authorise us to bill your credit or debit card on the first date after the Trial Period and/or the Initial Subscription Period and on the anniversary of each Renewal Period, for the Subscription Fees payable in respect of the Initial Subscription Period or next Renewal Period, as the context requires. We do not hold your credit card details on our files. These are held securely in Stripe. See our privacy policy for more detail.

11.9 All Subscription Fees:

11.9.1 are payable in US Dollars unless otherwise stated; and

11.9.2 are non-cancellable and non-refundable unless you are a consumer;

11.10 We are entitled to increase the Subscription Fees at the start of each Renewal Period upon 90 days’ prior notice to you.

11.11 You may purchase subscriptions (User Subscriptions) for one or more users (each an Authorised User) as shown in the table above. We grant you a non-exclusive, non-transferable right to permit the Authorised Users to use the Paid App during the Trial Period (if applicable), the Initial Subscription Period and any Renewal Period solely for your own business operations.

11.12 In relation to the Authorised Users, you undertake that:

11.12.1 the maximum number of Authorised Users that you authorise to access and use the Paid App shall not exceed the number of User Subscriptions you have purchased under the applicable Subscription Plan;

11.12.2 you will not allow any User Subscription to be used by more than one individual Authorised User unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior Authorised User shall no longer have any right to access or use Paid App;

11.12.3 you shall permit us to audit use of the Paid App and, if any such audit reveals that:

(a) any password has been provided to any individual who is not an Authorised User, then without affecting our other rights, you shall promptly disable such passwords and we shall not issue any new passwords to any such individual; or

(b) you have underpaid Subscription Fees to us, then without affecting our other rights, you shall pay to us an amount equal to such underpayment within ten days of the date of the relevant audit.

LIMITATION OF OUR LIABILITY

12.1 Nothing in these terms of service excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.

12.2 To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or the Anders Pink App, whether express or implied.

12.3 We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:

12.3.1 use of, or inability to use, our site; or

12.3.2 use of or reliance on any content displayed on our site or the results generated by the Anders Pink App.

12.4 If you are a business user, please note that in particular, we will not be liable for:

12.4.1 loss of profits, sales, business, or revenue;

12.4.2 business interruption;  

12.4.3 loss of anticipated savings;

12.4.4 loss of business opportunity, goodwill or reputation; or

12.4.5 any indirect or consequential loss or damage.

12.5 If you are a consumer user, please note that we only provide our site and the Anders Pink App for domestic and private use. You agree not to use our site or the Anders Pink App for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.

12.6 We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or the Anders Pink App.

12.7 We are only responsible for loss or damage you suffer that is a foreseeable result of our breach of these terms of service or our negligence up to the limit specified in the following paragraph, but we are not responsible for any unforeseeable loss or damage. Loss or damage is foreseeable if it is an obvious consequence of our breach or if they were contemplated by you and us at the time we granted you the terms of service.

12.8 Our maximum aggregate liability under or in connection with these terms of service, (including your use of the Anders Pink App) whether in contract, tort (including negligence) or otherwise, shall in all circumstances be limited to the Subscription Fees paid for the Initial Subscription Period or the then current Renewal Period, as the context requires. This does not apply to the types of loss set out in the first paragraph of this section of these terms of service.

13 EVENTS OUTSIDE OUR CONTROL

13.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these terms of service that is caused by any act or event beyond our reasonable control, including failure of public or private telecommunications networks.

VIRUSES

14.1 We do not guarantee that our site will be secure or free from bugs or viruses.

14.2 You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.

14.3 You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.

15 TERMINATION

15.1 We may terminate your subscription to the Free App or Paid App at any time and without notice if you commit a material breach of these terms of service.

15.2 A material breach, for the purpose of the Paid App, includes but is not limited to, any unlawful attempt to copy the App, services or data of Anders Pink and any attempt to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the App or services of Anders Pink.

15.3 On termination for any reason:

15.3.1 all rights granted to you under these terms of service shall cease; and

15.3.2 you must immediately cease all activities authorised by these terms of service, including your use of the Anders Pink App.

16 OTHER IMPORTANT TERMS

16.1 We may transfer our rights and obligations under these terms of service to another organisation, but this will not affect your rights or our obligations under these terms of service.

16.2 You may only transfer your rights or obligations under these terms of service to another person if we agree in writing.

16.3 If we fail to insist that you perform any of your obligations under these terms of service, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.

16.4 Each of the conditions of these terms of service operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.

17 APPLICABLE LAW

17.1 If you are a consumer, please note that these terms of service, their subject matter and its formation, are governed by English law. You and we both agree to that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.

17.2 If you are a business, these terms of service, their subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.

CONTACT US

If you wish to contact us in writing, or if any condition in these terms of service requires you to give us notice in writing, you can send this to us by e-mail to help@Anders Pink.com, or by prepaid post to Anders Pink Limited at 44-46 Old Steine, Brighton BN1 1NH. We will confirm receipt of this by contacting you in writing, normally by e-mail.

If we have to contact you or give you notice in writing, we will do so by e-mail or by pre-paid post to the address you have provided to us.

Thank you for visiting our site and using our service.

Anders Pink Limited
                `,
      },
    ],
  },
  {
    id: 'cookie-policy',
    title: 'Cookie policy',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_380614540.jpg`,
    coverPosition: '50% 80%',
    sections: [
      {
        type: 'markdown',
        markdown: `
Updated May 2018

Our site (anderspink.com) uses cookies to distinguish you from other users of our site. This helps us to provide you with a good experience when you browse our site and also allows us to improve our site.

By continuing to browse the site, you are agreeing to our use of cookies.

A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.

We use the following cookies:

Functionality cookies. These are used to recognise you when you return to our site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your alerts and saved searches).

Targeting cookies. These cookies record your visit to our site, the pages you have visited and the links you have followed. We will use this information to make our site and any advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.

BLOCKING COOKIES: You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.

Except for essential cookies, all cookies will expire after one year.

Anders Pink Limited
                `,
      },
    ],
  },
  {
    id: 'product-multi-language',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_228876649.jpg`,
    coverPosition: '50% 20%',
    title: 'Multi-Language Features',
    subtitle: '',
    sections: [
      {
        type: 'markdown',
        title: 'Multi-language Custom Briefings',
        markdown: `
In Anders Pink, You can create a custom briefing (a feed of content from around the web) in multiple languages:

- English 
- French
- German 
- Spanish 
- Swedish 
- Portuguese
- Italian 

Here’s a video showing how to do it in French:

<iframe width="600" height="375" src="https://www.loom.com/embed/8bc26e20f5e44e9bb76d695b803e32d0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

<br />

<iframe width="600" height="375" src="https://www.loom.com/embed/3a4c7b15cc924db3bc1cd24ace0a89be" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

<br />

It’ll update every few hours with fresh content from your target language. 

So now you can serve multiple global audiences in your organisation with high quality curated content, within your Learning Platforms, Apps and workflow.   
                `,
      },
      {
        type: 'markdown',
        colour: 'grey',
        title: 'Quality sites and sources for each language',
        markdown: `
We’ve carefully selected a range of top quality sites and sources in each language working with experts in each territory. So by default you’ll see business focused content in your target language from a select range of sites. This helps you get to relevant content faster. 

Of course, you’re in control - you can add or remove sites and sources, so if you have your preferred domains, these are easy to add.   
                `,
      },
      {
        type: 'markdown',
        title: 'Multi-Language Curated Bundles',
        markdown: `
You can create a custom briefing on absolutely any topic using our new language features. But we also know that sometimes you just want to pick a topic and get started even faster.

That’s what our Multi-Language Curated Bundles help you to do. 

We’ve created over 150 predefined briefings on the most popular topics across Anders Pink, and in the learning and development space, including:
                
- Leadership and management 
- Sales and marketing 
- HR and L&D topics 
- Technical skills 
- Compliance 
- Business and strategy 
- Personal development
- Living and working well in the “New Normal”
- [Top Ranked in-demand Skills by LinkedIn](https://blog.anderspink.com/2020/06/the-reskilling-emergency-is-real-what-should-ld-do-about-it/) and The World Economic Forum

In each of these bundles there are 20+ briefings. Each is available in English, French, German, Spanish, Italian, Portuguese, and Swedish.

You can use these predefined briefings out of the box and immediately get high quality curated content from selected business sources in each language. Or you can customise them for your particular needs.
                `,
      },
      {
        type: 'cta2',
        value:
          'To trial any of our language features or bundles, just get in touch.',
      },
      {
        type: 'bundles',
        // title: 'What industry experts are saying',
      },
    ],
  },
  {
    id: 'product-briefing-bundles',
    cover: `${STATIC_DOMAIN}/pages/landing/shutterstock_546307051.jpg`,
    coverPosition: '50% 20%',
    title: 'Pre-Defined Briefing Bundles',
    subtitle: '',
    sections: [
      {
        type: 'markdown',
        markdown: `
You can create a briefing in Anders Pink on any topic you want. It’s easy - [here’s how](https://vimeo.com/385936450). 
But sometimes you want to get started even faster on the most popular topics for curation. That’s what our bundles help you to do.              
                `,
      },
      {
        type: 'markdown',
        colour: 'grey',
        title: 'Pre-made briefings on 120+ topics, ready to use',
        markdown: `
We’ve reviewed the most popular topics across our Anders Pink database and created over 120 pre-defined briefings in these top categories:

- Future of Work in-demand Skills
- Personal development
- Business and Strategy
- Technology
- Marketing
- Sales Professionals
- HR, Learning and Development
- Management and Leadership
- Leadership Behaviours
- Hybrid Working
- Wellness, Wellbeing and Mental Health

We’ve created about 15 briefings in each of these categories above. The full catalogue is listed below, and it’s growing all the time.  
                `,
      },
      {
        type: 'markdown',
        title: 'Why use bundled briefings?',
        markdown: `
1. Ready to go – these can be dropped into your Anders Pink account, or via your integration to your LMS or LXP, and you can use them immediately 

2. Quality from the start – we’ve invested the time in refining and quality assuring these briefings and selecting the best sources – so you don’t have to.

3. Customisable – you can use any of these briefings as a baseline and edit it. So for example if you want to use our briefing on High Performing Teams, and edit it so it only includes references to tech teams, or sales teams, or the pharma industry – that’s easy to do. 

4. Support your key skills: Use these to add to skills areas and assessments in your platform. Briefings mapped against these are then preloaded for them, giving each learner a personalised content feed aligned to their interests.

5. Constantly updated: These briefings refresh every few hours, so there’s always fresh content in your Learning Platform on these topics. You can assign them for specific audiences, or let people choose which ones they want to follow – that’s all handled via the integration within your platform.

6. Multi-language: If you’re serving a global audience, these bundles can help you get started fast. Each of the 120 briefings is available in English, French, German, Spanish, Italian, Portuguese and Swedish, with more languages being added all the time. 
                `,
      },
      {
        type: 'cta2',
        value: 'To trial any of our bundles, just get in touch.',
      },
      {
        type: 'bundles',
      },
    ],
  },
]

@connect((state) => ({
  app: state.app,
}))
export default class Page extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    const page =
      pages.find((page) => page.id === this.props.params.id) || pages[0]

    const cover =
      page.cover || `${STATIC_DOMAIN}/pages/landing/plugin-header.png`
    const coverPosition = page.coverPosition || '50% 0%'

    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundImage: `url(${cover})`,
            backgroundPosition: coverPosition,
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>{page.title}</div>
          </div>
        </div>

        {!!page.subtitle && (
          <div className={pageStyles.section}>
            <div className={pageStyles.sectionHeadingThin}>{page.subtitle}</div>
          </div>
        )}

        {page.sections.map((section, i) => (
          <div key={i}>
            {section.type === 'markdown' && (
              <div
                className={
                  section.colour === 'grey'
                    ? pageStyles.greySection
                    : pageStyles.section
                }
              >
                <div
                  className={
                    section.colour === 'grey' ? pageStyles.innerGreySection : ''
                  }
                >
                  {!!section.title && (
                    <div className={pageStyles.sectionHeading}>
                      {section.title}
                    </div>
                  )}
                  <div
                    className={[pageStyles.textContainer, styles.text].join(
                      ' ',
                    )}
                  >
                    <ReactMarkdown
                      source={section.markdown}
                      renderers={{
                        code: CodeBlock,
                        link: RouterLink,
                      }}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </div>
            )}
            {section.type === 'cta' && (
              <div className={styles.cta}>
                <div className={styles.ctaContent}>
                  <p className={styles.ctaTop}>{section.value}</p>
                  <Link
                    to="/contact?reason=Book%20a%20demo"
                    className="btn btn-lg btn-primary"
                  >
                    Book a consultation
                  </Link>
                </div>
              </div>
            )}
            {section.type === 'cta2' && (
              <div className={pageStyles.greySection}>
                <div className={styles.cta2}>
                  <div className={styles.contact}>
                    <p className={styles.contactTop}>{section.value}</p>
                    <Link
                      to="/contact?reason=Request%20briefing%20bundles"
                      className="btn btn-lg btn-outline-dark"
                      style={{ borderWidth: '2px' }}
                    >
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {section.type === 'clients' && (
              <div className={pageStyles.section}>
                {!!section.title && (
                  <div className={pageStyles.sectionHeading}>
                    {section.title}
                  </div>
                )}

                <div className={pageStyles.clientBlocks}>
                  {section.clients.map((clientId, i) => {
                    const client = clients.find(
                      (client) => client.id == clientId,
                    )
                    if (!client) return null

                    return (
                      <div key={i} className={pageStyles.clientBlock}>
                        <FlipCard>
                          <div className={pageStyles.clientBlockFront}>
                            <img src={client.img} />
                          </div>
                          <div className={pageStyles.clientBlockBack}>
                            <div>{client.content}</div>
                            {!!client.caseStudyUrl && (
                              <div>
                                <Link
                                  to={client.caseStudyUrl}
                                  className="btn btn-primary btn-sm"
                                >
                                  See case study
                                </Link>
                              </div>
                            )}
                          </div>
                        </FlipCard>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {section.type === 'experts' && <Experts />}
            {section.type === 'bundles' && <Bundles />}
            {section.type === 'blocks' && (
              <div className={pageStyles.section}>
                <div className={pageStyles.blocks}>
                  {section.blocks.map((block, i) => (
                    <div key={i} className={pageStyles.block}>
                      <div className={pageStyles.blockText}>
                        <ReactMarkdown
                          source={block.markdown}
                          renderers={{
                            code: CodeBlock,
                            link: RouterLink,
                          }}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {section.type === 'employees' && (
              <div className={pageStyles.section}>
                <div className={pageStyles.sectionHeading}>
                  Meet your core team
                </div>
                <div className={pageStyles.persons}>
                  <div className={pageStyles.person}>
                    <div className={pageStyles.personImageLarge}>
                      <img
                        src={`${STATIC_DOMAIN}/pages/about/michelle-hazelton.jpg`}
                      />
                    </div>
                    <div className={pageStyles.personTitle}>
                      <a
                        href="https://www.linkedin.com/in/michellehazelton/"
                        target="_blank"
                        className="link"
                      >
                        Michelle Hazelton
                      </a>
                      <br /> MD
                    </div>
                    <p className={pageStyles.personText}>
                      Background in software product management and implementing
                      learning technologies in global organisations.
                    </p>
                  </div>
                  <div className={pageStyles.person}>
                    <div className={pageStyles.personImageLarge}>
                      <img
                        src={`${STATIC_DOMAIN}/pages/about/stephen-walsh.jpeg`}
                      />
                    </div>
                    <div className={pageStyles.personTitle}>
                      <a
                        href="https://www.linkedin.com/in/stephentwalsh/"
                        target="_blank"
                        className="link"
                      >
                        Stephen Walsh
                      </a>
                      <br /> Co-Founder
                    </div>
                    <p className={pageStyles.personText}>
                      20 years experience in learning, design, marketing, and
                      technology. Co-founded Kineo, BuzzSumo.
                    </p>
                  </div>
                  <div className={pageStyles.person}>
                    <div className={pageStyles.personImageLarge}>
                      <img
                        src={`${STATIC_DOMAIN}/pages/about/steve-rayson.jpeg`}
                      />
                    </div>
                    <div className={pageStyles.personTitle}>
                      <a
                        href="https://www.linkedin.com/in/steverayson/"
                        target="_blank"
                        className="link"
                      >
                        Steve Rayson
                      </a>
                      <br /> Co-Founder
                    </div>
                    <p className={pageStyles.personText}>
                      Founded and sold a number of learning technology
                      companies. Formerly the MD of Kineo for 9 years prior to
                      its sale.
                    </p>
                  </div>
                  <div className={pageStyles.person}>
                    <div className={pageStyles.personImageLarge}>
                      <img
                        src={`${STATIC_DOMAIN}/pages/about/jake-salmon.png`}
                      />
                    </div>
                    <div className={pageStyles.personTitle}>
                      <a
                        href="https://www.linkedin.com/in/jake-salmon-a5025124/"
                        target="_blank"
                        className="link"
                      >
                        Jake Salmon
                      </a>
                      <br /> CTO, Co-Founder
                    </div>
                    <p className={pageStyles.personText}>
                      Experienced programmer with interests covering app
                      development, scalable server tech, AI, and UX design.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {section.type === 'infographic' && <Infographic />}
          </div>
        ))}
      </div>
    )
  }
}
