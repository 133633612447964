import React from 'react'
import { Link } from 'react-router'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import CenterPage from 'app/components/CenterPage'
import { connect } from 'react-redux'
import { postRequest } from 'app/actions/appActions'
import { browserHistory } from 'react-router'
import SignupForm from './SignupForm'
import TwitterSignupPanel from 'authentication/containers/TwitterSignupPanel'
import styles from './AcceptTeamInvitePage.css'
import LoginForm from './LoginForm'
import axios from 'axios'

@connect()
export default class JoinTeamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      error: null,
      teamName: null,
      inviterName: null,
      email: null,
    }
  }
  componentDidMount() {
    this.props
      .dispatch(
        postRequest('/auth/validate-team-public-link-token', {
          teamId: this.props.params.teamId,
          token: this.props.params.token,
        }),
      )
      .then((response) => {
        const data = response.payload.data
        if (data.status === 'success') {
          if (data.action === 'signup') {
            this.setState({
              checked: true,
              teamName: data.teamName,
            })

            // Save the token into local storage incase the user goes off to twitter.
            localStorage.setItem(
              'teamPublicLink',
              JSON.stringify({
                teamId: this.props.params.teamId,
                token: this.props.params.token,
                teamName: data.teamName,
              }),
            )
          } else if (data.action === 'autoJoined') {
            // Fire off a request to change the users team..
            // @todo show success message first?
            axios
              .get(`/iapi/auth/changeTeam?id=${data.teamId}&time=${Date.now()}`)
              .then((response) => {
                if (response.data.status === 'success') {
                  window.location.href = '/home'
                }
              })
          }
        } else {
          this.setState({
            checked: true,
            error: response.payload.data.message,
          })
        }
      })
  }
  onSignupSubmit = (values) => {
    return this.props
      .dispatch(
        postRequest('/auth/email-signup', {
          ...values,
          teamId: this.props.params.teamId,
          teamPublicLinkToken: this.props.params.token,
        }),
      )
      .then((response) => {
        const data = response.payload.data

        if (data.status === 'fail') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })

          window.location.href = '/home'
        }
      })
  }
  onLoginSubmit = (values) => {
    return this.props
      .dispatch(
        postRequest('/auth/login', {
          email: values.email,
          password: values.password,
          teamId: this.props.params.teamId,
          teamPublicLinkToken: this.props.params.token,
        }),
      )
      .then((response) => {
        const data = response.payload.data

        if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })
          window.location.href = '/home'
        } else {
          throw new SubmissionError({ _error: data.message })
        }
      })
  }
  render() {
    if (!this.state.checked) {
      return <CenterPage maxWidth="500px">Checking...</CenterPage>
    }

    if (this.state.error !== null) {
      return (
        <CenterPage maxWidth="500px">
          <div
            className="alert alert-danger"
            role="alert"
            style={{ margin: '10px 0' }}
          >
            {this.state.error}
          </div>
        </CenterPage>
      )
    }

    const { error, handleSubmit, submitting } = this.props

    return (
      <CenterPage>
        <h4>Joining Team</h4>
        <p>
          You've been invited to join team{' '}
          <strong>{this.state.teamName}</strong>. Login with Twitter or manually
          create your account:
        </p>
        <br />

        <div className={styles.panels}>
          <div className={styles.left}>
            <h5>Create an account</h5>
            <TwitterSignupPanel />
            <br />
            <br />
            <h5>Or sign up manually</h5>

            <p>
              You'll immediately be sent an email which contains a link to
              confirm your account.
            </p>

            <SignupForm
              onSubmit={this.onSignupSubmit}
              initialValues={{ email: this.state.email }}
            />
          </div>
          <div className={styles.right}>
            <h5>Existing user</h5>

            <LoginForm onSubmit={this.onLoginSubmit} />

            <p className={styles.signupText}>
              If you've forgotten your password, you can{' '}
              <Link className="link" to="/reset-password">
                reset it here
              </Link>
              .
            </p>
          </div>
        </div>
      </CenterPage>
    )
  }
}
