import React from 'react'
import { connect } from 'react-redux'
import FixedModal from 'app/components/FixedModal'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share'
import styles from './ArticleShare.css'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

@graphql(
  gql`
    mutation($articleId: ID!) {
      addArticleShare(articleId: $articleId)
    }
  `,
  {
    name: 'addArticleShare',
    options: (props) => ({
      variables: {
        articleId: props.article.id,
      },
    }),
  },
)
export default class ArticleShare extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalMounted: false,
      isModalOpen: false,
    }
  }
  closeModal = () => {
    this.setState({
      isModalOpen: false,
    })
    this.timeoutId = setTimeout(() => {
      this.setState({
        isModalMounted: false,
      })
    }, 100)
  }
  shareClick = () => {
    this.props.addArticleShare()
    this.closeModal()
  }
  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }
  render() {
    const article = this.props.article

    return (
      <div>
        <span
          onClick={() => {
            this.setState({
              isModalMounted: true,
              isModalOpen: true,
            })
          }}
        >
          <i className="fa fa-share-alt" />
        </span>

        {this.state.isModalMounted && (
          <FixedModal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.closeModal}
            buttons={[
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.closeModal}
              >
                Close
              </button>,
            ]}
          >
            <div className={styles.socialContainer}>
              <div className={styles.socialTitle}>Share article to:</div>

              <FacebookShareButton
                url={article.url}
                title={`${article.title} via @anderspink`}
              >
                <div className={styles.socialShare} onClick={this.shareClick}>
                  <FacebookIcon size={32} round={true} />{' '}
                  <span className={styles.socialName}>Facebook</span>
                </div>
              </FacebookShareButton>

              <TwitterShareButton
                url={article.url}
                title={`${article.title} via @anderspink`}
              >
                <div className={styles.socialShare} onClick={this.shareClick}>
                  <TwitterIcon size={32} round={true} />{' '}
                  <span className={styles.socialName}>Twitter</span>
                </div>
              </TwitterShareButton>

              <LinkedinShareButton
                url={article.url}
                title={`${article.title} via @anderspink`}
              >
                <div className={styles.socialShare} onClick={this.shareClick}>
                  <LinkedinIcon size={32} round={true} />{' '}
                  <span className={styles.socialName}>LinkedIn</span>
                </div>
              </LinkedinShareButton>

              <div>
                <div
                  className={styles.socialShare}
                  onClick={() => {
                    const windowWidth = Math.min(850, window.screen.availWidth)
                    const windowHeight = 500
                    const centerLeft = parseInt(
                      (window.screen.availWidth - windowWidth) / 2,
                    )
                    const centerTop = parseInt(
                      (window.screen.availHeight - windowHeight) / 2,
                    )

                    window.open(
                      `https://bufferapp.com/add?text=${
                        encodeURIComponent(article.title) + ' via @anderspink'
                      }&url=${encodeURIComponent(article.url)}`,
                      null,
                      `innerWidth=${windowWidth},innerHeight=${windowHeight},top=${centerTop},left=${centerLeft},resizable,scrollbars,status`,
                    )

                    this.shareClick()
                  }}
                >
                  <div
                    style={{
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#EEE',
                    }}
                  >
                    <img style={{ width: '17px' }} src="/buffer.svg" />
                  </div>{' '}
                  <span className={styles.socialName}>Buffer</span>
                </div>
              </div>
            </div>
          </FixedModal>
        )}
      </div>
    )
  }
}
