import React from 'react'
import styles from './LoginPanel.css'
import LoginForm from 'authentication/containers/LoginForm'
import { SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { postRequest } from 'app/actions/appActions'
import { browserHistory } from 'react-router'

@connect()
export default class LoginPanel extends React.Component {
  onLoginSubmit = (values) => {
    return this.props
      .dispatch(
        postRequest('/auth/login', {
          email: values.email,
          password: values.password,
        }),
      )
      .then((response) => {
        const data = response.payload.data

        if (data.status === 'fail') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })

          const onAuthRedirect = window.localStorage.getItem('onAuthRedirect')
          if (onAuthRedirect) {
            window.localStorage.removeItem('onAuthRedirect')
            window.location.href = onAuthRedirect
          } else {
            browserHistory.push(this.props.redirectPath || '/home')
            this.props.onLoginSuccess && this.props.onLoginSuccess()
          }
        }
      })
  }
  render() {
    return (
      <div className={styles.root}>
        <h4 className="mb-2">
          <img className={styles.logo} src="/pages/landing/ap-logo.png" /> Sign
          in to your Anders Pink account
        </h4>

        <LoginForm onSubmit={this.onLoginSubmit} />

        <p className="mt-3 text-muted">
          If you previously signed in with twitter or have forgotten your
          password, please visit{' '}
          <a href="https://anderspink.com/login" target="_blank">
            our web app
          </a>{' '}
          first to log in, then try again here.
        </p>
      </div>
    )
  }
}
