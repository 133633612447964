import React from 'react'

import styles from './Quote.module.css'

const quoteMarks = `${STATIC_DOMAIN}/pages/new-homepage/quote-marks.png`

const Quote = (props) => {
  return (
    <article className={`${styles.QuoteCard} ${styles[props.quoteNum]}`}>
      <div className={styles.QuoteCardTop}>
        <div className={styles.QuoteLine1} />
        <img className={styles.QuoteMark} src={quoteMarks} alt="quote marks" />
        <div className={styles.QuoteLine2} />{' '}
      </div>
      <div className={styles.QuoteBody}>{props.bodyText}</div>
      <div className={styles.QuoteCompany}>{props.companyName}</div>
    </article>
  )
}

export default Quote
