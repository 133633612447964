import React from 'react'
import { connect } from 'react-redux'
import { postRequest } from 'app/actions/appActions'

@connect()
export default class LoginPage extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }
  onTwitterLoginPress = () => {
    this.setState({
      loading: true,
    })
    this.props
      .dispatch(postRequest('/auth/twitter-login-url'))
      .then((response) => {
        this.setState({
          loading: false,
        })
        if (response.payload.data.status === 'success') {
          window.location = response.payload.data.url
        } else {
          window.alert(
            'Sorry there was an issue logging you in, please try again',
          )
        }
      })
  }
  render() {
    return (
      <span
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={this.onTwitterLoginPress}
      >
        {this.props.children}
      </span>
    )
  }
}
