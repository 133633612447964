import React, { Component } from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import styles from 'briefings/create/containers/EditBriefingForm.css'
import { connect } from 'react-redux'

import { FORM_NAMES } from './constants'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state, props) => {
  return {
    [props.newField]: selector(state, props.newField),
    [props.field]: selector(state, props.field),
  }
})
export default class WordList extends Component {
  constructor() {
    super()
    this.state = { error: null }
    this.timeoutId = null
  }
  render() {
    return (
      <div>
        <FieldArray name={this.props.field} component={this.renderWordList} />
        {this.state.error && (
          <div style={{ margin: '10px 0' }} className="alert alert-warning">
            {this.state.error}
          </div>
        )}
      </div>
    )
  }
  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }
  renderWordList = ({ fields, meta: { touched, error } }) => {
    const addFilterWord = () => {
      this.setState({ error: null })
      if (this.props[this.props.newField] === undefined) {
        return
      }

      // Split by commas..
      const words = this.props[this.props.newField]
        .toLowerCase()
        .split(',')
        .map((word) => word.trim())
        .filter((word) => word.length > 0)
      let errored = false

      for (let word of words) {
        if (!this.props[this.props.field].includes(word)) {
          const error = this.props.validation
            ? this.props.validation(word)
            : null
          if (error !== null) {
            errored = true
            this.setState({ error })
            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(() => {
              this.setState({ error: null })
            }, 5000)
          } else {
            if (this.props.map) {
              word = this.props.map(word)
            }
            fields.push(word)
          }
        }
      }
      if (!errored) {
        this.props.change(this.props.newField, '')
      }
    }
    return (
      <div className={styles.filterControls}>
        <div className={styles.filterWordList}>
          {fields.map((field, index) => (
            <Field
              key={index}
              name={field}
              component={this.renderWordComponent}
              onRemove={() => fields.remove(index)}
            />
          ))}
          <Field
            name={this.props.newField}
            component="input"
            type="text"
            className="form-control mr-sm-2 mb-sm-0"
            placeholder=""
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                addFilterWord()
                e.preventDefault()
              }
            }}
          />
        </div>
        <div className={styles.filterWordActions}>
          {fields.length > 0 && (
            <button
              className={styles.filterWordsClear}
              onClick={() => fields.removeAll()}
            >
              clear <i className="fa fa-ban" aria-hidden="true" />
            </button>
          )}
          <button className="btn btn-secondary" onClick={addFilterWord}>
            Add
          </button>
        </div>
      </div>
    )
  }
  renderWordComponent = ({
    input,
    label,
    type,
    onRemove,
    meta: { touched, error },
  }) => {
    return (
      <span className={styles.filterWord}>
        {input.value}{' '}
        <span className={styles.filterWordDelete} onClick={onRemove}>
          <i className="fa fa-times" aria-hidden="true" />
        </span>
      </span>
    )
  }
}
