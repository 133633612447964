import React from 'react'
import { connect } from 'react-redux'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import styles from './TeamSettingsPage.css'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router'

import TeamStats from 'team/containers/TeamStats'

@graphql(
  gql`
    query teamSettingsQuery {
      session {
        team {
          id
        }
        isTeamAdmin
      }
    }
  `,
  {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  },
)
export default class TeamStatsPage extends React.Component {
  render() {
    if (this.props.data.loading) {
      return false
    }

    return (
      <div>
        <TeamStats id={this.props.params.teamId} />
      </div>
    )
  }
}
