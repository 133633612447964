import React from 'react'
import { browserHistory } from 'react-router'
import { Field, formValueSelector } from 'redux-form'
import { reduxForm } from 'redux-form'
import ReactTooltip from 'react-tooltip'
import moment from 'moment-timezone'
import SelectorRequired from './SelectorRequired'
import EmailInput from './EmailInput'
import FileInput from './FileInput'
import { connect } from 'react-redux'
import '../styles/tooltip.scss'

const SENDING_INTERVAL = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'fortnightly', label: 'Fortnightly' },
  { value: 'monthly', label: 'Monthly' },
]

const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

// Monday is 1 in ISO
const DAYS_OF_WEEK = DAYS.map((d, i) => ({
  value: String(i + 1),
  label: d,
}))

const DAYS_OF_MONTH = [
  ...DAYS.map((d, i) => ({
    value: `1-${i + 1}`,
    label: `First ${d} of the month`,
  })),
  ...DAYS.map((d, i) => ({
    value: `2-${i + 1}`,
    label: `Second ${d} of the month`,
  })),
  ...DAYS.map((d, i) => ({
    value: `3-${i + 1}`,
    label: `Third ${d} of the month`,
  })),
]

// { value: 'day', label: '24 hours' },
// { value: 'week', label: 'Last Week' },
const TOP_ARTICLES_RANGE = [
  { value: 'month', label: 'Last Month' },
  { value: 'quarter', label: 'Last 3 Months' },
]

const BRIEFINGS_TYPE = [
  { value: 'all', label: 'All briefings' },
  { value: 'selected', label: 'Selected briefings' },
]
const ARTICLES_LIMIT = [...Array(20).keys()].map((n) => ({
  value: n + 1,
  label: String(n + 1),
}))

const RECIPIENTS_TYPE = [
  { value: 'all', label: 'All team members' },
  { value: 'selected', label: 'Selected team members' },
]

const ucFirst = (string) => {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

let NewsletterForm = ({
  handleSubmit,
  onSubmit,
  valid,
  error,
  briefings,
  users,
  unsubscribedUsers,
  sendingInterval,
  briefingType,
  recipientsType,
  withAdditionalEmails,
  withBriefingName,
  withArticleDescription,
}) => {
  let TIME = Array.from(Array(48).keys()).map((number) => {
    const time =
      String(Math.floor(number / 2)).padStart(2, '0') +
      `:${number % 2 === 0 ? 0 : 3}0`
    return { value: time, label: time }
  })

  const timeZones = moment.tz.names()
  const TIME_ZONES = timeZones.map((timeZone) => ({
    value: timeZone,
    label: timeZone + ' (GMT' + moment.tz(timeZone).format('Z') + ')',
  }))

  const briefingOptions = (briefings || []).map((b) => ({
    value: b.id,
    label: `${b.name} (id: ${b.id})`,
  }))

  const userOptions = (users || []).map((u) => ({
    value: u.user.id,
    label: `${u.user.firstName} ${u.user.lastName} (${u.user.email})`,
  }))

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
      <div className="form-group">
        <label className="font-weight-bold">Digest title</label>
        <div>
          <label className="form-check-label">
            <Field
              name="newsletterTitle"
              className="form-control"
              required
              autoComplete="off"
              component="input"
              type="text"
            />
          </label>
        </div>
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Sending Interval</label>
        <Field
          name="sendingInterval"
          props={{ data: SENDING_INTERVAL }}
          component={SelectorRequired}
          required
        />
      </div>
      {(sendingInterval === 'weekly' || sendingInterval === 'fortnightly') && (
        <div className="form-group">
          <div>
            <label className="font-weight-bold">Day to send</label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="This is the day you’d like the digest to be sent."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
          <Field
            name="sendingDay"
            props={{ data: DAYS_OF_WEEK }}
            component={SelectorRequired}
            required
          />
        </div>
      )}
      {sendingInterval === 'monthly' && (
        <div className="form-group">
          <div>
            <label className="font-weight-bold">Day to send</label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="This is the day you’d like the digest to be sent."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
          <Field
            name="sendingDay"
            props={{ data: DAYS_OF_MONTH }}
            component={SelectorRequired}
            required
          />
        </div>
      )}

      <div className="form-group">
        <div>
          <label className="font-weight-bold">Time</label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="This is the time the digest will be sent  out in your selected time zone."
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <Field
          name="digestTime"
          props={{ data: TIME }}
          component={SelectorRequired}
          required
        />
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Time zone</label>
        <Field
          name="digestTimeZone"
          props={{ data: TIME_ZONES }}
          component={SelectorRequired}
          required
        />
      </div>

      <div className="form-group">
        <div>
          <label className="font-weight-bold">Show top articles from</label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="Show the top articles from the selected time period from your chosen briefings. If no new articles have been added since the last Digest, older articles may be displayed."
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <Field
          name="articlesFilter"
          props={{ data: TOP_ARTICLES_RANGE }}
          component={SelectorRequired}
          required
        />
      </div>

      <div className="form-group">
        <div>
          <label className="font-weight-bold">
            Briefings to select articles from
          </label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="The maximum number briefings that will be included in a Digest is 20."
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <div className="form-check">
          {BRIEFINGS_TYPE.map((item, i) => (
            <div key={i}>
              <label className="form-check-label">
                <Field
                  name="briefingType"
                  className="form-check-input"
                  component="input"
                  type="radio"
                  required
                  value={item.value}
                />{' '}
                {ucFirst(item.label)}
              </label>
            </div>
          ))}
        </div>
      </div>
      {briefingType === 'selected' && (
        <div className="form-group">
          <Field
            name="briefingIds"
            props={{ data: briefingOptions }}
            component={SelectorRequired}
            required
            isMulti
          />
        </div>
      )}
      <div className="form-group">
        <label className="font-weight-bold">
          Number of articles to send per briefing
        </label>
        <Field
          name="articlesLimit"
          props={{ data: ARTICLES_LIMIT }}
          component={SelectorRequired}
          required
        />
      </div>

      <div className="form-group">
        <div className="form-check">
          <div>
            <label className="form-check-label">
              <Field
                className="form-check-input"
                name="withSingleArticlePerSource"
                component="input"
                type="checkbox"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />{' '}
              Send only one article per source (optional)
            </label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="The digest will only show one article per source for each briefing."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Recipients</label>
        <div className="form-check">
          {RECIPIENTS_TYPE.map((item, i) => (
            <div key={i}>
              <label className="form-check-label">
                <Field
                  name="recipientsType"
                  className="form-check-input"
                  component="input"
                  type="radio"
                  required
                  value={item.value}
                />{' '}
                {ucFirst(item.label)}
              </label>
            </div>
          ))}
        </div>
      </div>
      {recipientsType === 'selected' && (
        <div className="form-group">
          <Field
            name="userIds"
            props={{ data: userOptions }}
            isMulti
            component={SelectorRequired}
            required
          />
        </div>
      )}

      {unsubscribedUsers.length > 0 && (
        <div className="form-group">
          <div>
            <label className="font-weight-bold">Unsubscribed users</label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="These are users who unsubscribed from email digest"
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
          <Field
            name="unsubscribedUsers"
            disabled={true}
            component={EmailInput}
          />
        </div>
      )}

      <div className="form-group">
        <div className="form-check">
          <div>
            <label className="form-check-label">
              <Field
                className="form-check-input"
                name="withAdditionalEmails"
                component="input"
                type="checkbox"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />{' '}
              Send to additional email addresses (optional)
            </label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="You can send this digest to people who are not in this team and do not have an Anders Pink account. You can add a list of email addresses - they just need to be separated by a white space or comma."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
        </div>
      </div>

      {withAdditionalEmails && (
        <div className="form-group">
          <Field name="additionalEmailAddresses" component={EmailInput} />
        </div>
      )}

      {withAdditionalEmails && (
        <div className="form-group">
          <div className="form-check">
            <div>
              <label className="form-check-label">
                <Field
                  className="form-check-input"
                  name="disclaimer"
                  component="input"
                  type="checkbox"
                  required
                  normalize={(v) => {
                    return !!v ? true : false
                  }}
                />{' '}
                <span className="font-weight-bold">
                  These people have given me permission to email them
                </span>{' '}
                <br />
                These people will not receive a confirmation email from Anders
                Pink to ask permission, therefore you must have received consent
                to add them to the email list.
              </label>
            </div>
          </div>
        </div>
      )}

      <div className="form-group">
        <div className="form-check">
          <div>
            <label className="form-check-label">
              <Field
                className="form-check-input"
                name="withBriefingName"
                component="input"
                type="checkbox"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />{' '}
              Allow the briefing title to be visible
            </label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="By unticking this box the briefing title's will not appear for each selected briefing within the digest, if you leave the box ticked then a title that reads 'Briefing | [name of briefing]' will appear per section of the digest."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="form-check">
          <div>
            <label className="form-check-label">
              <Field
                className="form-check-input"
                name="withArticleDescription"
                component="input"
                type="checkbox"
                normalize={(v) => {
                  return !!v ? true : false
                }}
              />{' '}
              Allow the article description to be visible.
            </label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="By unticking this box the article description will disappear. If you disable this
              option it will also disable the 'View in Anders Pink' option."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="form-check">
          <div>
            <label className="form-check-label">
              <Field
                className="form-check-input"
                name="withViewInAnderspink"
                component="input"
                type="checkbox"
                normalize={(v) => {
                  return !!v ? true : false
                }}
                // disabled={withArticleDescription ? false : true}
              />{' '}
              Show "View in Anders Pink"
            </label>
            <button
              type="button"
              className="btn"
              data-toggle="tooltip"
              data-placement="top"
              data-tip="By unticking this box the 'View in Anders Pink' will disappear."
            >
              <i className="fa fa-question-circle" />
            </button>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label className="font-weight-bold">Email subject (optional)</label>
        <p>Defaults to 'Anders Pink Digest'</p>
        <div>
          <label className="form-check-label">
            <Field
              name="emailTitle"
              className="form-control"
              autoComplete="off"
              component="input"
              type="text"
            />
          </label>
        </div>
      </div>

      <div className="form-group">
        <label className="font-weight-bold">
          Banner image (optional, max 2mb)
        </label>
        <p>Display at start of the email</p>
        <div>
          <label className="form-check-label">
            <Field
              name="bannerImage"
              type="file"
              props={{ mime: 'image/png, image/jpeg' }}
              component={FileInput}
            />
          </label>
        </div>
      </div>

      <div className="form-group">
        <div>
          <label className="font-weight-bold">Headline Text (optional)</label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="This is displayed at the top of the digest but under the banner image (if a banner image is added)."
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <div>
          <label className="form-check-label">
            <Field
              name="headline"
              className="form-control"
              autoComplete="off"
              component="textarea"
              type="text"
            />
          </label>
        </div>
      </div>

      <div className="form-group">
        <div>
          <label className="font-weight-bold">Email Footer (optional)</label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="Customise the first sentence of the email footer if required. The second sentence by default is ‘You can edit your email preferences or unsubscribe.’"
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <p>
          Defaults to 'You're receiving this because you are subscribed to the
          Anders Pink App.'
        </p>
        <div>
          <label className="form-check-label">
            <Field
              name="footerLine"
              className="form-control"
              autoComplete="off"
              component="textarea"
              type="text"
            />
          </label>
        </div>
      </div>

      <div className="form-group">
        <div>
          <label className="font-weight-bold">Email From Name (optional)</label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="Customise the name the digest will be sent from, i.e. the name of your business/company/team/individual."
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <p>Defaults to 'Anders Pink'</p>
        <div>
          <label className="form-check-label">
            <Field
              name="emailFromName"
              className="form-control"
              autoComplete="off"
              component="input"
              type="text"
            />
          </label>
        </div>
      </div>

      {/* <div className="form-group">
        <div>
          <label className="font-weight-bold">
            Email From Address (optional)
          </label>
          <button
            type="button"
            className="btn"
            data-toggle="tooltip"
            data-placement="top"
            data-tip="Amend the email address the digest will display as being delivered from, i.e. an email address of your business/company/team/individual."
          >
            <i className="fa fa-question-circle" />
          </button>
        </div>
        <p>Defaults to 'info@anderspink.com'</p>
        <div>
          <label className="form-check-label">
            <Field
              name="emailFromAddress"
              className="form-control"
              autoComplete="off"
              component="input"
              type="text"
            />
          </label>
        </div>
      </div> */}

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <button
        type="submit"
        className={`btn btn-sm btn-primary`}
        disabled={!valid}
      >
        Submit
      </button>
      <button
        type="button"
        className="btn btn-sm btn-secondary ml-1"
        onClick={() => browserHistory.goBack()}
      >
        Cancel
      </button>
      <ReactTooltip
        place="top"
        effect="solid"
        multiline={true}
        className="custom-tooltip"
        isCapture={true}
      />
    </form>
  )
}

const selector = formValueSelector('digest')

NewsletterForm = connect((state) => ({
  ...selector(
    state,
    'sendingInterval',
    'briefingType',
    'recipientsType',
    'withAdditionalEmails',
    'withBriefingName',
    'withArticleDescription',
  ),
}))(NewsletterForm)

NewsletterForm = reduxForm({
  form: 'digest',
})(NewsletterForm)

export default NewsletterForm
