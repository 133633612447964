exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".HomePage__root___1aU9D {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.HomePage__leftPanel___7L8JF {\n  -webkit-box-flex: 4;\n      -ms-flex: 4;\n          flex: 4;\n  margin-top: 15px;\n  padding: 0 18px 15px 18px;\n  border-right: 1px solid #ddd;\n}\n.HomePage__rightPanel___zVYqw {\n  -webkit-box-flex: 2;\n      -ms-flex: 2;\n          flex: 2;\n  margin-top: 15px;\n  padding: 0 18px 15px 18px;\n}\n.HomePage__header___78ab5 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 18px;\n}\n.HomePage__title___2_isZ {\n  font-size: 1.5em;\n}\n.HomePage__link___DPxhU {\n  text-decoration: underline;\n  color: #666;\n  font-size: 0.8em;\n}\n.HomePage__link___DPxhU:hover {\n  color: #000;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "HomePage__root___1aU9D",
	"leftPanel": "HomePage__leftPanel___7L8JF",
	"rightPanel": "HomePage__rightPanel___zVYqw",
	"header": "HomePage__header___78ab5",
	"title": "HomePage__title___2_isZ",
	"link": "HomePage__link___DPxhU"
};