exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__text___3aP8A img {\n  max-width: 100%;\n  margin: 1rem 0;\n}\n.styles__logo___1_KOr {\n  text-align: center;\n  padding-top: 2rem;\n  margin-bottom: -2rem;\n}\n.styles__logo___1_KOr img {\n  max-width: 300px;\n  max-height: 170px;\n}\n\n.styles__technical___1hYS3 {\n  background-image: url(/pages/landing/viewfinder-telescope.png);\n  background-position: 50% 75%;\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n\n  height: 270px;\n  position: relative;\n}\n.styles__technical___1hYS3:before {\n  position: absolute;\n  content: '';\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(11, 19, 27, 0.3);\n}\n.styles__technical___1hYS3 button {\n  position: relative;\n}\n.styles__technicalTop___2KIaO {\n  font-size: 1.6rem;\n}\n.styles__technicalContent___3fmay {\n  position: relative;\n  color: #ffffff;\n  max-width: 600px;\n  margin: 0 auto;\n  text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "styles__text___3aP8A",
	"logo": "styles__logo___1_KOr",
	"technical": "styles__technical___1hYS3",
	"technicalTop": "styles__technicalTop___2KIaO",
	"technicalContent": "styles__technicalContent___3fmay"
};