import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import palette from 'app/library/palette'
import { Link } from 'react-router'
import ApiStats from './ApiStats'

// don't show an average
// show per person stats...

const selector = formValueSelector('teamStats')

@compose(
  withProps((props) => ({
    initialValues: {
      time: '1-month',
    },
  })),
  reduxForm({
    form: 'teamStats',
  }),
  connect((state, props) => ({
    time: selector(state, 'time'),
  })),
  graphql(
    gql`
      query($id: ID, $timeInterval: Int, $timePeriod: String) {
        team(id: $id) {
          id
          name
          stats(timeInterval: $timeInterval, timePeriod: $timePeriod) {
            user {
              id
              firstName
              lastName
            }
            views
            shares
            upvotes
            comments
            saves
          }
        }
      }
    `,
    {
      options: (props) => {
        const timeParts = props.time.split('-')
        return {
          variables: {
            id: props.id,
            timeInterval: parseInt(timeParts[0]),
            timePeriod: timeParts[1],
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
export default class TeamStats extends React.Component {
  render() {
    if (this.props.data.loading && !this.props.data.team) {
      return null
    }

    let time = this.props.time
    const team = this.props.data.team
    if (!team) {
      return <div>Sorry, you don't have permission to view this team.</div>
    }

    // If there's less than 5 members,

    const seq = palette('tol-rainbow', team.stats.length)

    const data = [
      { name: 'Viewed' },
      { name: 'Shared' },
      { name: 'Recommended' },
      { name: 'Commented' },
      { name: 'Saved' },
    ]

    let bars = []
    for (let i in this.props.data.team.stats) {
      let userStat = this.props.data.team.stats[i]

      let label = `${userStat.user.firstName} ${userStat.user.lastName}`

      data[0][label] = userStat.views
      data[1][label] = userStat.shares
      data[2][label] = userStat.upvotes
      data[3][label] = userStat.comments
      data[4][label] = userStat.saves

      bars.push(
        <Bar key={label} dataKey={label} stackId="a" fill={`#${seq[i]}`} />,
      )
    }

    return (
      <div>
        <h5>Team Engagement</h5>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px 0',
          }}
        >
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-sm btn-${
                time === '1-week' ? 'primary' : 'secondary'
              }`}
              onClick={() => {
                this.props.change('time', '1-week')
              }}
            >
              This week
            </button>
            <button
              type="button"
              className={`btn btn-sm btn-${
                time === '1-month' ? 'primary' : 'secondary'
              }`}
              onClick={() => {
                this.props.change('time', '1-month')
              }}
            >
              This month
            </button>
            <button
              type="button"
              className={`btn btn-sm btn-${
                time === '3-months' ? 'primary' : 'secondary'
              }`}
              onClick={() => {
                this.props.change('time', '3-months')
              }}
            >
              3 months
            </button>
          </div>
        </div>

        <div style={{ margin: '10px 0' }}>
          <BarChart
            width={800}
            height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {bars}
          </BarChart>
        </div>

        <div style={{ margin: '20px 0' }}>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Viewed</th>
                <th>Shared</th>
                <th>Recommended</th>
                <th>Commented</th>
                <th>Saves</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.team.stats.map((userStat) => (
                <tr key={userStat.user.id}>
                  <td>
                    <Link
                      className="link"
                      to={`/team-stats/${team.id}/user/${userStat.user.id}`}
                    >
                      {userStat.user.firstName} {userStat.user.lastName}
                    </Link>
                  </td>
                  <td>{userStat.views}</td>
                  <td>{userStat.shares}</td>
                  <td>{userStat.upvotes}</td>
                  <td>{userStat.comments}</td>
                  <td>{userStat.saves}</td>
                  <td>
                    {userStat.views +
                      userStat.shares +
                      userStat.upvotes +
                      userStat.comments +
                      userStat.saves}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
