import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { browserHistory } from 'react-router'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import SignupForm from './SignupForm'
import LoginForm from './LoginForm'
import { postRequest } from 'app/actions/appActions'
import styles from './TwitterSignupPage.css'
import { Link } from 'react-router'

const selector = formValueSelector('twitterSignup')

@reduxForm({
  form: 'twitterSignup',
  initialValues: {
    page: 'checkingOAuth',
  },
})
@connect((state) => ({
  page: selector(state, 'page'),
}))
export default class TwitterSignupPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // user form fields..
      error: null,
    }
  }
  componentDidMount() {
    // Do we have a team invite token?...
    const params = {
      oAuthToken: this.props.location.query.oauth_token,
      oAuthVerifier: this.props.location.query.oauth_verifier,
    }
    const teamInviteJson = localStorage.getItem('teamInvite')
    if (teamInviteJson) {
      localStorage.removeItem('teamInvite')
      const teamInvite = JSON.parse(teamInviteJson)
      if (teamInvite && teamInvite.token) {
        params.teamInviteToken = teamInvite.token
      }
    }

    const teamPublicLinkJson = localStorage.getItem('teamPublicLink')
    if (teamPublicLinkJson) {
      localStorage.removeItem('teamPublicLink')
      const teamPublicLink = JSON.parse(teamPublicLinkJson)
      if (teamPublicLink && teamPublicLink.token && teamPublicLink.teamId) {
        params.teamPublicLinkToken = teamPublicLink.token
        params.teamId = teamPublicLink.teamId
      }
    }

    this.props
      .dispatch(postRequest('/auth/twitter-login', params))
      .then((response) => {
        const data = response.payload.data
        if (data.status === 'success') {
          if (data.state === 'redirectToHome') {
            const onAuthRedirect = window.localStorage.getItem('onAuthRedirect')
            if (onAuthRedirect) {
              window.localStorage.removeItem('onAuthRedirect')
              window.location.href = onAuthRedirect
            } else {
              window.location.href = '/home'
            }
          } else if (data.state === 'isLoggedIn') {
            this.props.dispatch({
              type: 'SET_AUTH',
              userId: data.userId,
              teamId: data.teamId,
              jwt: data.token,
            })

            // Redirect to their home page OR the twitter topic selection screen
            const onAuthRedirect = window.localStorage.getItem('onAuthRedirect')
            if (onAuthRedirect) {
              window.localStorage.removeItem('onAuthRedirect')
              window.location.href = onAuthRedirect
            } else {
              window.location.href = '/home'
            }
          } else if (data.state === 'shouldCreateAccount') {
            // we have some values

            // Start a request to load their keywords...

            // Start the loading process..

            // Show the form field..
            this.setState({
              ...data.user,
            })

            this.props.change('page', 'signup')
          }
        } else {
          localStorage.removeItem('teamInvite')
          localStorage.removeItem('teamPublicLink')

          this.setState({
            error: response.payload.data.message,
          })
        }
      })
  }
  onLoginSubmit = (values) => {
    return this.props
      .dispatch({
        type: 'LOAD',
        payload: {
          request: {
            method: 'post',
            url: '/auth/login-link-twitter',
            data: {
              ...values,
            },
          },
        },
      })
      .then((response) => {
        const data = response.payload.data
        if (data.status === 'fail' || data.status === 'error') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })

          // @todo where do we want to send them?
          window.location.href = '/home'
        }
      })
  }
  onSignupSubmit = (values) => {
    const params = values
    const teamInviteJson = localStorage.getItem('teamInvite')
    if (teamInviteJson) {
      const teamInvite = JSON.parse(teamInviteJson)
      if (teamInvite && teamInvite.token) {
        params.teamInviteToken = teamInvite.token
      }
    }

    return this.props
      .dispatch({
        type: 'LOAD',
        payload: {
          request: {
            method: 'post',
            url: '/auth/twitter-signup',
            data: params,
          },
        },
      })
      .then((response) => {
        const data = response.payload.data
        if (data.status === 'fail' || data.status === 'error') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })

          localStorage.removeItem('teamInvite')

          window.location.href = '/home'
        }
      })
  }
  render() {
    if (this.state.error) {
      return (
        <div>
          {this.state.error}
          <br />
          <br />
          <Link to="/" className="btn btn-secondary">
            Continue
          </Link>
        </div>
      )
    }

    if (this.props.page === 'checkingOAuth') {
      return <div>Please wait...</div>
    }

    // Else show the signup form...
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <h2>Welcome {this.state.firstName}!</h2>
          <p>
            Complete your new account below. If you already have an account, you
            can instead sign into this and we'll link your Twitter account.
          </p>
        </div>

        <div className={styles.panels}>
          <div className={styles.left}>
            <h4>Complete your new account</h4>

            <div className={styles.twitter}>
              <img className={styles.image} src={this.state.image} /> @
              {this.state.username}
            </div>

            <SignupForm
              onSubmit={this.onSignupSubmit}
              initialValues={{
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
              }}
            />
          </div>

          <div className={styles.right}>
            <h4>Or log into your existing Anders Pink account</h4>
            <p>
              We'll link your Anders Pink and Twitter account (@
              {this.state.username}) together.
            </p>

            <LoginForm
              onSubmit={this.onLoginSubmit}
              buttonType="secondary"
              initialValues={{
                email: this.state.email,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
