import React from 'react'
import moment from 'moment'

const ListStatsItem = ({ item, viewLink, usageLink }) => {
  return (
    <>
      <tr>
        <th scope="row">{item.id}</th>
        <td>
          {moment(item.createdAt).format('dddd, Do MMMM YYYY, h:mm:ss a')}
        </td>
        <td>{item.sent}</td>
        <td>{item.openRatio}%</td>
        <td>{item.clickRatio}%</td>
        <td>
          <a className="btn btn-sm btn-primary ml-1" href={viewLink}>
            View
          </a>
          <a className="btn btn-sm btn-primary ml-1" href={usageLink}>
            Usage analytics
          </a>
        </td>
      </tr>
    </>
  )
}
export default ListStatsItem
