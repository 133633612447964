import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Field, reduxForm, change, formValueSelector, touch } from 'redux-form'
import TextareaAutosize from 'react-textarea-autosize'

import styles from './SourceFilters.css'

const urlRegex =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/

const validateHasItems = (value) => value.length === 0 && 'List has no items'

@connect()
export default class DomainList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invalidItemsString: '',
      itemsToAddString: '',
      itemsToAddList: [],
      isModalOpen: false,
      isDeleting: false,
    }
  }
  removeDomain = (domainRemoved) => {
    const currentItems = this.props.items
    const updatedItems = currentItems
      .map(({ id, domain }) =>
        domain === domainRemoved
          ? !id
            ? undefined
            : { id, domain: '' }
          : { id, domain },
      )
      .filter(Boolean)
    this.props.handleUpdate(updatedItems)
  }
  //   updateItems = (items) => {
  //     this.props.updateItems(items)
  //     // this.props.change(FORM_NAME, 'items', items)
  //     // this.props.touch(FORM_NAME, ['items'])
  //   }
  formatAddItems = () => {
    const splitItems = this.state.itemsToAddString
      .split(/[\s,]/)
      .map((s) => s.trim().toLowerCase())
      .filter(Boolean)

    const validUrls = splitItems
      .filter((url) => urlRegex.test(url))
      .map((url) =>
        url.startsWith('http') || url.startsWith('//')
          ? new URL(url).host
          : url,
      )

    const invalidUrls = splitItems.filter((url) => !urlRegex.test(url))

    const validUnique = new Set(validUrls)
    const itemsToAddString = [...validUnique].join('\n')

    const invalidUnique = new Set(invalidUrls)
    const invalidItemsString = [...invalidUnique].join('\n')
    return {
      validItemsString: itemsToAddString,
      invalidItemsString: invalidItemsString,
    }
  }
  addItems = () => {
    const { validItemsString, invalidItemsString } = this.formatAddItems()
    const existingItems = this.props.items
    const existingItemDomains = existingItems.map(({ domain }) => domain)
    const newItems = validItemsString
      .split(/[\s,]/)
      .filter((s) => s.length > 0 && !existingItemDomains.includes(s))
      .map((i) => ({ id: null, domain: i }))

    const allItems = [...existingItems, ...newItems]
    this.props.handleUpdate(allItems)
    this.setState({ itemsToAddString: invalidItemsString, invalidItemsString })
  }

  hasItems = (items) =>
    Array.isArray(items) &&
    items.filter(({ domain = '' }) => domain.length > 0).length > 0
  renderItems = (items) => {
    const hasItems = Array.isArray(items) && items.length > 0 // this.hasItems(input.value)
    return (
      <>
        <div
          className={[
            styles.itemsList,
            hasItems ? styles.itemsPadded : '',
          ].join(' ')}
        >
          {hasItems
            ? items
                .filter(({ domain }) => domain.length > 0)
                .map(({ domain }) => (
                  <button
                    type="button"
                    className={[styles.listItem, styles.noStyleBtn].join(' ')}
                    key={domain}
                  >
                    {domain}{' '}
                    <span
                      className={styles.filterWordDelete}
                      onClick={() => this.removeDomain(domain)}
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </span>
                  </button>
                ))
            : null}{' '}
        </div>
        {/* {meta.touched && meta.error && (
          <span className={styles.validationError}>{meta.error}</span>
        )} */}
      </>
    )
  }
  formatItems = (itemsArray = []) => {
    const ret = Array.isArray(itemsArray)
      ? itemsArray.map((item) => item.domain).join('\n')
      : ''
    return ret
  }
  addItemsChanged = (ev) => {
    const itemsToAddString = ev.currentTarget.value
    if (itemsToAddString !== '\n') {
      this.setState({ itemsToAddString })
    }
  }
  handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      this.addItems()
    }
    return false
  }
  copyToClipboard = () => {
    const textToCopy = this.props.items
      .map(({ domain }) => domain)
      .filter(Boolean)
      .join(', ')
    if (navigator.clipboard != undefined) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.props.dispatch &&
            this.props.dispatch({
              type: 'ADD_NOTIFICATION',
              content: `Copied to clipboard`,
            })
        })
        .catch((err) => {
          this.props.dispatch &&
            this.props.dispatch({
              type: 'ADD_NOTIFICATION',
              level: 'danger',
              content: `Failed to copy: ${err}`,
            })
        })
    } else if (window.clipboardData) {
      window.clipboardData.setData('Text', textToCopy)
    }
  }
  clearItems = () => {
    this.props.handleUpdate([])
  }
  render() {
    const { items = [] } = this.props
    const { invalidItemsString = '', isDeleting } = this.state
    const hasInvalidItems = invalidItemsString.length > 0
    const hasClipboard = navigator.clipboard || window.clipboardData
    return (
      <div className="container mx-0 px-0" style={{ position: 'relative' }}>
        <div
          className="d-flex pb-2 justify-content-end"
          style={{
            transform: 'translateY(-70%)',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          {hasClipboard && this.hasItems(items) ? (
            <button
              type="button"
              className={[styles.noStyleBtn, styles.copyToClipboardBtn].join(
                ' ',
              )}
              className={styles.btnItemsControl}
              onClick={this.copyToClipboard}
              title="Copy this list of domains to your clipboard"
            >
              copy <i className="fa fa-copy"></i>
            </button>
          ) : null}
          {this.hasItems(items) ? (
            <button
              className={styles.btnItemsControl}
              onClick={this.clearItems}
            >
              clear <i className="fa fa-ban" aria-hidden="true" />
            </button>
          ) : null}
        </div>
        <div className={styles.itemsContainer}>
          {this.renderItems(items)}
          {/* <Field
            name="items"
            validate={[validateHasItems]}
            component={this.renderItems}
          /> */}
          <div className={styles.textAreaContainer}>
            <TextareaAutosize
              spellCheck={false}
              value={this.state.itemsToAddString}
              onChange={this.addItemsChanged}
              // onBlur={this.addItems}
              onKeyDown={this.handleKeyDown}
              placeholder={
                this.props.placeholder || 'Type or copy/paste domains here'
              }
              rows="3"
              minRows="3"
            />
            <div className={styles.textAreaBtn}>
              <button
                className="btn btn-secondary btn-sm"
                onClick={this.addItems}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {hasInvalidItems ? (
          <span style={{ color: 'red', fontSize: '0.8em' }}>
            Oops! Some domains are not valid
          </span>
        ) : null}
      </div>
    )
  }
}
