import React, { useState, useEffect } from 'react'
import { Link } from 'react-router'
import styles from './Navbar.module.css'

const DropDownItemsDesktop = ({ title, items, colorClasses }) => {
  const [dropdownOpen, setDropDownOpen] = useState(false)

  return (
    <>
      <li className={`${styles.navItem} dropdown`}>
        <a
          className={`${styles.navLink} ${colorClasses.navLinkRight} dropdown-toggle`}
          id="navbarMenuDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() => setDropDownOpen(!dropdownOpen)}
        >
          {title}
        </a>
        <div
          className="dropdown-menu"
          aria-labelledby="navbarDropdown"
          style={{ display: dropdownOpen ? 'block' : 'none' }}
        >
          {items.map((item, key) => (
            <li key={key} className={`dropdown-item`}>
              <Link to={item.link} className={`${styles.navLink}`}>
                {item.name}
              </Link>
            </li>
          ))}
        </div>
      </li>
    </>
  )
}

export default DropDownItemsDesktop
