import React, { Component } from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { isEqual } from 'lodash'
import Article from 'articles/containers/Article'
import Loader2 from 'app/components/Loader2'
import ArticleListFullPage from 'articles/components/ArticleListFullPage'

@graphql(
  gql`
    mutation ($briefing: BriefingInput!) {
      createBriefingPreview(briefing: $briefing) {
        previewId
        articles {
          id
          title
          content
          url
          image
          domain
          datePublished
        }
        completionPercent
      }
    }
  `,
  {
    name: 'createBriefingPreview',
  },
)
export default class PreviewSource extends Component {
  static defaultProps = {
    onAdd: null,
    source: null,
    keywords: [],
  }
  constructor() {
    super()
    this.state = {
      previewId: null,
      completionPercent: null,
      articles: [],
    }
  }
  componentDidMount() {
    this.createPreview()
  }
  async createPreview() {
    try {
      const briefing = {
        type: 'sources',
        language: 'en',
        sourceFields: {
          sources: [
            {
              type: this.props.source.type,
              value: this.props.source.value,
            },
          ],
          keywords: this.props.keywords.filter(Boolean),
        },
      }

      const response = await this.props.createBriefingPreview({
        variables: {
          briefing: briefing,
        },
      })
      const { previewId, completionPercent, articles } =
        response.data.createBriefingPreview

      if (!previewId) {
        throw new Error('Failed to create preview')
      }
      this.setState({
        previewId,
        completionPercent,
        articles,
      })
    } catch (err) {
      console.log(err)
      alert('Failed to load preview, please re-fresh')
    }
  }
  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.source, this.props.source) ||
      !isEqual(prevProps.keywords, this.props.keywords)
    ) {
      this.createPreview()
    }
  }
  render() {
    let infoElement = null

    if (!this.state.previewId || this.state.creatingPreview) {
      infoElement = (
        <div
          className="alert alert-info"
          style={{
            display: 'flex',
            position: 'fixed',
            right: '0',
            left: '599px',
            zIndex: '5',
          }}
        >
          <Loader2 text="Loading preview" />
        </div>
      )
    } else if (
      this.state.completionPercent &&
      this.state.completionPercent < 100
    ) {
      infoElement = (
        <div
          className="alert alert-info"
          style={{
            display: 'flex',
            position: 'fixed',
            right: '0',
            left: '599px',
            zIndex: '5',
          }}
        >
          <Loader2 text={`Loading preview ${this.state.completionPercent}%`} />
        </div>
      )
    } else {
      infoElement = null
    }

    if (this.state.articles.length === 0) {
      return (
        <div>
          {infoElement}

          <div className="mt-2">
            {this.state.completionPercent < 100
              ? 'No articles found yet...'
              : 'No articles found'}
          </div>
        </div>
      )
    }

    let articles = [...this.state.articles]

    articles.sort((a, b) => {
      return a.date > b.date ? -1 : 1
    })

    return (
      <div>
        {infoElement}
        <div
          style={{
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1, marginRight: '5px' }}>
            {this.props.keywords.length === 0 && (
              <span>
                Preview of ALL recent articles from{' '}
                <strong>{this.props.source.value}</strong>. Once added, you can
                filter your sources by keywords in the next step.
              </span>
            )}
            {this.props.keywords.length > 0 && (
              <span>
                Preview of recent articles from{' '}
                <strong>{this.props.source.value}</strong> filtered by keyword
                {this.props.keywords.length > 1 ? 's' : ''}:{' '}
                <strong>{this.props.keywords.join(', ')}</strong>
              </span>
            )}
          </div>
          {this.props.onAdd !== null && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.props.onAdd}
            >
              Add source
            </button>
          )}
        </div>

        <ArticleListFullPage>
          {articles.map((article, index) => (
            <Article key={index} article={article} showActions={false} />
          ))}
        </ArticleListFullPage>
      </div>
    )
  }
}
