import React, { useState } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { isLoggedIn } from 'app/selectors/appSelectors'
import DropDownItemsDesktop from './DropDownItemsDesktop'
import DropDownItemsMobile from './DropDownItemsMobile'

// import DropdownDesktop from './DropdownDesktop'
// import DropdownMobile from './DropdownMobile'

const apLogoPink = `${STATIC_DOMAIN}/pages/new-homepage/ap-logo-pink.png`
const apLogoWhite = `${STATIC_DOMAIN}/pages/new-homepage/ap-logo-white.png`

import styles from './Navbar.module.css'

const PREFIX = process.env.NODE_ENV === 'production' ? 'https' : 'http'
const DOMAIN = process.env.NODE_ENV === 'production' ? 'com' : 'local'

@connect((state) => ({
  isLoggedIn: isLoggedIn(state),
}))
export default class Navbar extends React.Component {
  constructor() {
    super()
    this.state = {
      menuOpen: false,
    }
  }
  render() {
    const handleMenuOpen = () =>
      this.setState({ menuOpen: !this.state.menuOpen })
    const closeMobileMenu = () => this.setState({ menuOpen: false })

    // const onMouseEnterDesktop = () => {
    //   if (window.innerWidth <= 1130) {
    //     setDropdownDesktop(false)
    //   } else {
    //     setDropdownDesktop(true)
    //   }
    // }

    // const onMouseLeaveDesktop = () => {
    //   setDropdownDesktop(false)
    // }

    // const onClickMobile = () => {
    //   if (window.innerWidth > 1130) {
    //     setDropdownMobile(false)
    //   } else {
    //     setDropdownMobile(!dropdownMobile)
    //   }
    // }

    let apLogo
    if (this.props.logoColor === 'white') {
      apLogo = apLogoWhite
    } else if (this.props.logoColor === 'pink') {
      apLogo = apLogoPink
    }

    const colorClasses = {
      navLinkLeft: '',
      navLinkRight: '',
      navBtn1: '',
      navBtn2: '',
      menuIconColor: '',
    }

    if (this.props.backgroundColors === 'dark') {
      colorClasses.navLinkLeft = styles.navLinkWhite
      colorClasses.navLinkRight = styles.navLinkWhite
      if (this.props.pinkOrGreen === 'pink') {
        colorClasses.navBtn1 = styles.navBtnDesktopWhitePink
      } else if (this.props.pinkOrGreen === 'green') {
        colorClasses.navBtn1 = styles.navBtnDesktopWhiteGreen
      }
      colorClasses.navBtn2 = styles.navBtnDesktopWhite
      colorClasses.menuIconColor = 'white'
    } else if (
      this.props.backgroundColors === 'light' &&
      this.props.background === 'white'
    ) {
      colorClasses.navLinkLeft = styles.navLinkGrey
      colorClasses.navLinkRight = styles.navLinkGrey
      colorClasses.navBtn1 = styles.navBtnDesktopPink
      colorClasses.navBtn2 = styles.navBtnDesktopPink
      colorClasses.menuIconColor = this.state.menuOpen ? 'white' : '#cf0032'
    } else if (this.props.backgroundColors === 'light') {
      colorClasses.navLinkLeft = styles.navLinkGrey
      colorClasses.navLinkRight = styles.navLinkGrey
      colorClasses.navBtn1 = styles.navBtnDesktopPink
      colorClasses.navBtn2 = styles.navBtnDesktopPink
      colorClasses.menuIconColor = '#cf0032'
    }

    return (
      <nav
        className={styles.navbar}
        style={{ background: this.props.background ? 'white' : 'transparent' }}
      >
        <div className={styles.navbarLogo}>
          {this.state.menuOpen ? (
            <img
              onClick={closeMobileMenu}
              src={apLogoWhite}
              alt="ap-logo"
              height="80px"
            />
          ) : (
            <Link to="/">
              <img src={apLogo} alt="ap-logo" height="80px" />
            </Link>
          )}
        </div>
        <ul
          className={this.state.menuOpen ? styles.hideNav : styles.navMenuRight}
        >
          <DropDownItemsDesktop
            title="Web Curation"
            items={[
              {
                link: '/wc/enterprise',
                name: 'For Enterprise',
              },
              {
                link: '/wc/platforms',
                name: 'For Platforms',
              },
            ]}
            colorClasses={colorClasses}
          />
          <li className={styles.navItem}>
            <Link
              to="/content-skills-mapper"
              className={`${styles.navLink} ${colorClasses.navLinkRight}`}
            >
              Content to Skills Mapper
            </Link>
          </li>
          <DropDownItemsDesktop
            title="Complete Curation"
            items={[
              {
                link: '/cc/enterprise',
                name: 'For Enterprise',
              },
              {
                link: '/cc/platforms',
                name: 'For Platforms',
              },
            ]}
            colorClasses={colorClasses}
          />
          <li className={styles.navItem}>
            <Link
              to="/client-success-stories"
              className={`${styles.navLink} ${colorClasses.navLinkRight}`}
            >
              Success Stories
            </Link>
          </li>
        </ul>
        <div>
          <Link to="/contact">
            <button
              className={`${styles.navBtnDesktop} ${colorClasses.navBtn1}`}
              style={
                this.props.navBtn1Color
                  ? { color: this.props.navBtn1Color }
                  : {}
              }
            >
              Book a consultation
            </button>
          </Link>
        </div>
        <div>
          {this.props.isLoggedIn ? (
            <Link to="/home">
              <button
                className={`${styles.navBtnDesktop} ${
                  this.props.backgroundColors === 'dark'
                    ? styles.navBtnDesktopTransparent
                    : styles.navBtnDesktopTransparentLightBg
                }`}
              >
                Go to app
              </button>
            </Link>
          ) : (
            <Link
              to={`${PREFIX}://auth.anderspink.${DOMAIN}/login?referer=${encodeURIComponent(
                `${PREFIX}://app.anderspink.${DOMAIN}`,
              )}`}
            >
              <button
                className={`${styles.navBtnDesktop} ${
                  this.props.backgroundColors === 'dark'
                    ? styles.navBtnDesktopTransparent
                    : styles.navBtnDesktopTransparentLightBg
                }`}
              >
                Log in
              </button>
            </Link>
          )}
        </div>
        <div
          className={styles.menuIcon}
          style={{ color: colorClasses.menuIconColor }}
          onClick={handleMenuOpen}
        >
          <i className={this.state.menuOpen ? 'fa fa-times' : 'fa fa-bars'} />
        </div>

        <ul
          className={
            this.state.menuOpen
              ? `${styles.mobileNav} ${styles.active}`
              : styles.mobileNav
          }
        >
          {/* <li className={styles.navItem} onClick={onClickMobile}>
          <div className={styles.navLink}>
            Product <i className="fa fa-angle-down" />
          </div>
        </li>
        {dropdownMobile && (
          <li>
            <DropdownMobile onClick={closeMobileMenu} />
          </li>
        )}
        <li className={styles.navItem}>
          <Link
            to="/partners"
            className={styles.navLink}
            onClick={closeMobileMenu}
          >
            Partners
          </Link>
        </li> */}
          <DropDownItemsMobile
            title="Web Curation"
            items={[
              {
                link: '/wc/enterprise',
                name: 'For Enterprise',
              },
              {
                link: '/wc/platforms',
                name: 'For Platforms',
              },
            ]}
          />
          <li className={styles.navItem}>
            <Link
              to="/content-skills-mapper"
              className={styles.navLink}
              onClick={closeMobileMenu}
            >
              Content to Skills Mapper
            </Link>
          </li>
          <DropDownItemsMobile
            title="Complete Curation"
            items={[
              {
                link: '/cc/enterprise',
                name: 'For Enterprise',
              },
              {
                link: '/cc/platforms',
                name: 'For Platforms',
              },
            ]}
          />
          <li className={styles.navItem}>
            <Link
              to="/client-success-stories"
              className={styles.navLink}
              onClick={closeMobileMenu}
            >
              Success Stories
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={styles.navBtnMobile}
              onClick={closeMobileMenu}
            >
              Book a consultation
            </Link>
          </li>
          <li>
            {this.props.isLoggedIn ? (
              <Link
                to="/home"
                className={styles.navBtnMobile}
                onClick={closeMobileMenu}
              >
                Go to app
              </Link>
            ) : (
              <Link
                to="/login"
                className={styles.navBtnMobile}
                onClick={closeMobileMenu}
              >
                Log in
              </Link>
            )}
          </li>
          {/* <li>
          <Link
            to={isLoggedIn ? '/home' : '/login'}
            className={styles.navBtnMobile}
            onClick={closeMobileMenu}
          >
            {isLoggedIn ? 'Go to app' : 'Log in'}
          </Link>
        </li> */}
        </ul>
      </nav>
    )
  }
}

// const Navbar = (props) => {
//   const [menuOpen, setMenuOpen] = useState(false)
//   // const [dropdownDesktop, setDropdownDesktop] = useState(false)
//   // const [dropdownMobile, setDropdownMobile] = useState(false)

//   const handleMenuOpen = () => setMenuOpen(!menuOpen)
//   const closeMobileMenu = () => setMenuOpen(false)

//   // const onMouseEnterDesktop = () => {
//   //   if (window.innerWidth <= 1130) {
//   //     setDropdownDesktop(false)
//   //   } else {
//   //     setDropdownDesktop(true)
//   //   }
//   // }

//   // const onMouseLeaveDesktop = () => {
//   //   setDropdownDesktop(false)
//   // }

//   // const onClickMobile = () => {
//   //   if (window.innerWidth > 1130) {
//   //     setDropdownMobile(false)
//   //   } else {
//   //     setDropdownMobile(!dropdownMobile)
//   //   }
//   // }

//   let apLogo
//   if (props.logoColor === 'white') {
//     apLogo = apLogoWhite
//   } else if (props.logoColor === 'pink') {
//     apLogo = apLogoPink
//   }

//   const colorClasses = {
//     navLinkLeft: '',
//     navLinkRight: '',
//     navBtn1: '',
//     navBtn2: '',
//     menuIconColor: '',
//   }

//   if (props.backgroundColors === 'mixed') {
//     colorClasses.navLinkLeft = styles.navLinkGrey
//     colorClasses.navLinkRight = styles.navLinkWhite
//     if (props.pinkOrGreen === 'pink') {
//       colorClasses.navBtn1 = styles.navBtnDesktopWhitePink
//     } else if (props.pinkOrGreen === 'green') {
//       colorClasses.navBtn1 = styles.navBtnDesktopWhiteGreen
//     }
//     colorClasses.navBtn2 = styles.navBtnDesktopPink
//     colorClasses.menuIconColor = 'white'
//   } else if (props.backgroundColors === 'dark') {
//     colorClasses.navLinkLeft = styles.navLinkWhite
//     colorClasses.navLinkRight = styles.navLinkWhite
//     if (props.pinkOrGreen === 'pink') {
//       colorClasses.navBtn1 = styles.navBtnDesktopWhitePink
//     } else if (props.pinkOrGreen === 'green') {
//       colorClasses.navBtn1 = styles.navBtnDesktopWhiteGreen
//     }
//     colorClasses.navBtn2 = styles.navBtnDesktopWhite
//     colorClasses.menuIconColor = 'white'
//   } else if (props.backgroundColors === 'light') {
//     colorClasses.navLinkLeft = styles.navLinkGrey
//     colorClasses.navLinkRight = styles.navLinkGrey
//     colorClasses.navBtn1 = styles.navBtnDesktopPink
//     colorClasses.navBtn2 = styles.navBtnDesktopPink
//     colorClasses.menuIconColor = '#cf0032'
//   }

//   return (
//     <nav className={styles.navbar}>
//       <div className={styles.navbarLogo}>
//         {menuOpen ? (
//           <img
//             onClick={closeMobileMenu}
//             src={apLogoWhite}
//             alt="ap-logo"
//             height="80px"
//           />
//         ) : (
//           <Link to="/new-homepage-main">
//             <img src={apLogo} alt="ap-logo" height="80px" />
//           </Link>
//         )}
//       </div>
//       <ul className={menuOpen ? styles.hideNav : styles.navMenuRight}>
//         <li className={styles.navItem}>
//           <Link
//             to="/enterprise"
//             className={`${styles.navLink} ${colorClasses.navLinkRight}`}
//           >
//             For Enterprise
//           </Link>
//         </li>
//         <li className={styles.navItem}>
//           <Link
//             to="/platforms"
//             className={`${styles.navLink} ${colorClasses.navLinkRight}`}
//           >
//             For Platforms
//           </Link>
//         </li>
//         <li className={styles.navItem}>
//           <Link
//             to="/client-success-stories"
//             className={`${styles.navLink} ${colorClasses.navLinkRight}`}
//           >
//             Success Stories
//           </Link>
//         </li>
//       </ul>
//       <div>
//         <Link to="/contact">
//           <button className={`${styles.navBtnDesktop} ${colorClasses.navBtn1}`}>
//             Book a consultation
//           </button>
//         </Link>
//       </div>
//       <div>
//         <Link to="/login">
//           <button
//             className={`${styles.navBtnDesktop} ${styles.navBtnDesktopTransparent}`}
//           >
//             Log in
//           </button>
//         </Link>
//       </div>
//       <div
//         className={styles.menuIcon}
//         style={{ color: colorClasses.menuIconColor }}
//         onClick={handleMenuOpen}
//       >
//         <i className={menuOpen ? 'fa fa-times' : 'fa fa-bars'} />
//       </div>

//       <ul
//         className={
//           menuOpen ? `${styles.mobileNav} ${styles.active}` : styles.mobileNav
//         }
//       >
//         {/* <li className={styles.navItem} onClick={onClickMobile}>
//           <div className={styles.navLink}>
//             Product <i className="fa fa-angle-down" />
//           </div>
//         </li>
//         {dropdownMobile && (
//           <li>
//             <DropdownMobile onClick={closeMobileMenu} />
//           </li>
//         )}
//         <li className={styles.navItem}>
//           <Link
//             to="/partners"
//             className={styles.navLink}
//             onClick={closeMobileMenu}
//           >
//             Partners
//           </Link>
//         </li> */}
//         <li className={styles.navItem}>
//           <Link
//             to="/enterprise"
//             className={styles.navLink}
//             onClick={closeMobileMenu}
//           >
//             For Enterprise
//           </Link>
//         </li>
//         <li className={styles.navItem}>
//           <Link
//             to="/platforms"
//             className={styles.navLink}
//             onClick={closeMobileMenu}
//           >
//             For Platforms
//           </Link>
//         </li>
//         <li className={styles.navItem}>
//           <Link
//             to="/client-success-stories"
//             className={styles.navLink}
//             onClick={closeMobileMenu}
//           >
//             Success Stories
//           </Link>
//         </li>
//         <li>
//           <Link
//             to="/contact"
//             className={styles.navBtnMobile}
//             onClick={closeMobileMenu}
//           >
//             Book a consultation
//           </Link>
//         </li>
//         <li>
//           <Link
//             to="/login"
//             className={styles.navBtnMobile}
//             onClick={closeMobileMenu}
//           >
//             Login
//           </Link>
//         </li>
//         {/* <li>
//           <Link
//             to={isLoggedIn ? '/home' : '/login'}
//             className={styles.navBtnMobile}
//             onClick={closeMobileMenu}
//           >
//             {isLoggedIn ? 'Go to app' : 'Log in'}
//           </Link>
//         </li> */}
//       </ul>
//     </nav>
//   )
// }

// export default Navbar
