exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".EBookForm\\.module__eBookForm___1Wag- {\n  background-color: var(--ap-green);\n  color: white;\n  display: grid;\n  grid-template-columns: 600px 600px;\n  grid-gap: 20px;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.EBookForm\\.module__eBookImg___3tgeP {\n  margin-right: -100px;\n  max-width: 700px;\n}\n\n.EBookForm\\.module__eBookText___RM_f0 {\n  margin: auto 0;\n  max-width: 400px;\n  text-align: left;\n  padding: 20px 0;\n}\n\n.EBookForm\\.module__preHeader___AU6tZ {\n  font-size: 1rem;\n}\n\n.EBookForm\\.module__header___2kbly {\n  font-size: 2rem;\n  margin-bottom: 20px;\n}\n\n.EBookForm\\.module__mainBody___1AAg4 {\n  font-size: 0.9rem;\n}\n\n.EBookForm\\.module__boldPoints___2SEUT {\n  font-size: 1rem;\n  font-weight: bold;\n}\n\n.EBookForm\\.module__fnInput___3XOX5,\n.EBookForm\\.module__emailInput___14aqH {\n  font-size: 1rem;\n  margin: 5px 0;\n  padding: 10px;\n  width: 100%;\n}\n\n.EBookForm\\.module__downloadBtn___14qlz {\n  background-color: var(--ap-green);\n  border: 1px solid white;\n  border-radius: 5px;\n  color: white;\n  cursor: pointer;\n  font-size: 1rem;\n  margin-top: 10px;\n  padding: 10px;\n  -webkit-transition: 0.2s ease;\n  transition: 0.2s ease;\n}\n\n.EBookForm\\.module__downloadBtn___14qlz:hover {\n  background-color: #00484d;\n}\n\n.EBookForm\\.module__returnLink___21PJ7 {\n  color: white;\n  display: block;\n  margin-top: 20px;\n  width: 160px;\n}\n\n.EBookForm\\.module__returnLink___21PJ7:hover {\n  color: rgb(194, 194, 194);\n}\n\n@media screen and (max-width: 1100px) {\n  .EBookForm\\.module__eBookForm___1Wag- {\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n  }\n\n  .EBookForm\\.module__eBookImg___3tgeP {\n    margin: 0 auto -50px;\n  }\n\n  .EBookForm\\.module__eBookText___RM_f0 {\n    margin: 0 auto 50px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"eBookForm": "EBookForm.module__eBookForm___1Wag-",
	"eBookImg": "EBookForm.module__eBookImg___3tgeP",
	"eBookText": "EBookForm.module__eBookText___RM_f0",
	"preHeader": "EBookForm.module__preHeader___AU6tZ",
	"header": "EBookForm.module__header___2kbly",
	"mainBody": "EBookForm.module__mainBody___1AAg4",
	"boldPoints": "EBookForm.module__boldPoints___2SEUT",
	"fnInput": "EBookForm.module__fnInput___3XOX5",
	"emailInput": "EBookForm.module__emailInput___14aqH",
	"downloadBtn": "EBookForm.module__downloadBtn___14qlz",
	"returnLink": "EBookForm.module__returnLink___21PJ7"
};