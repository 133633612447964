import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

const layoutDefaults = {
  hideLeftNav: false,
  minimiseLeftNav: false,
  contentPadding: true,
  hideAllNav: false,
  isEmbedded: false,
}

export default function pageWrapper(WrappedComponent) {
  @connect((state) => ({
    app: state.app,
  }))
  class PageWrapper extends React.Component {
    componentDidMount() {
      // Build a new layout object, using our defaults
      let layout = {}
      for (let field of Object.keys(layoutDefaults)) {
        layout[field] =
          typeof this.props.route[field] === 'undefined'
            ? layoutDefaults[field]
            : this.props.route[field]
      }

      // Update the app layout only if neccisary
      if (!isEqual(this.props.app.layout, layout)) {
        this.props.dispatch({
          type: 'SET_APP_LAYOUT',
          layout,
        })
      }
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  return PageWrapper
}
