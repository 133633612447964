import React from 'react'
import pageStyles from 'content/pages/styles.css'

export default class Experts extends React.Component {
  render() {
    return (
      <div className={pageStyles.section}>
        <div className={pageStyles.sectionHeadingThin}>
          What industry experts are saying
        </div>

        <div className={pageStyles.persons}>
          <div className={pageStyles.person}>
            <div className={pageStyles.personImageLarge}>
              <img src={`${STATIC_DOMAIN}/pages/landing/jane-hart.png`} />
            </div>
            <div className={pageStyles.personTitle}>
              Jane Hart
              <br /> Founder, C4LPT
            </div>
            <p className={pageStyles.personText}>
              "Anders Pink has not only helped organisations see curation as an
              important aspect of continuous learning; it has made it as easy as
              possible.
              <br />
              <br />
              By integrating with other platforms, it has brought learning right
              into the workflow to build a new culture of continuous workplace
              learning."
            </p>
          </div>
          <div className={pageStyles.person}>
            <div className={pageStyles.personImageLarge}>
              <img src={`${STATIC_DOMAIN}/pages/landing/jeanne-meister.png`} />
            </div>
            <div className={pageStyles.personTitle}>
              Jeanne Meister
              <br /> Founder, Future Workplace
            </div>
            <p className={pageStyles.personText}>
              "Formal courses are no longer enough to keep employees current on
              fast changing future of work trends, we need other ways.
              <br />
              <br />
              Anders Pink is just such a tool, powered by machine learning to
              provide trending content on current topics daily."
            </p>
          </div>
          <div className={pageStyles.person}>
            <div className={pageStyles.personImageLarge}>
              <img src={`${STATIC_DOMAIN}/pages/landing/josh-bersin.png`} />
            </div>
            <div className={pageStyles.personTitle}>
              Josh Bersin
              <br /> Founder, Bersin by Deloitte
            </div>
            <p className={pageStyles.personText}>
              "People do not have time for formal learning, so this informal
              "in-the-flow" of work is just necessary for success.
              <br />
              <br />
              The the more open and connected the learning platform can be, the
              easier it will be to use... Curation tools like Anders Pink - soon
              we will all be talking to these systems."
            </p>
          </div>
        </div>
      </div>
    )
  }
}
