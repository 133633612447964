const FIELDS_DOMAIN_LISTS = {
  DOMAIN_LIST_TYPE: 'domainListType',
  DOMAINS_ALLOW_BRIEFING: 'keywordsV2Fields.domainsAllowBriefing',
  DOMAINS_BLOCK_BRIEFING: 'keywordsV2Fields.domainsBlockBriefing',
  DOMAIN_LIST_ALLOW_IDS: 'keywordsV2Fields.domainListAllowIds',
  DOMAIN_LIST_BLOCK_IDS: 'keywordsV2Fields.domainListBlockIds',
}

const DOMAIN_LIST_TYPES = { ALLOW: 'allow', BLOCK: 'block', NONE: 'none' }

const FORM_NAMES = { EDIT_BRIEFING: 'editBriefing' }

const QUERIES = {
  SESSION: 'sessionQuery',
  DOMAIN_LISTS_WITH_ITEMS: 'domainListsWithItemsQuery',
  DOMAIN_BUCKETS: 'domainBuckets',
}

export { FIELDS_DOMAIN_LISTS, DOMAIN_LIST_TYPES, FORM_NAMES, QUERIES }
