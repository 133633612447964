exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "body {\n  overflow-x: hidden;\n}\n\n.ContentSkillsMapperPage\\.module__outerBody___2WCZv {\n  background-color: #e5e5e5;\n  overflow-x: hidden;\n}\n\n.ContentSkillsMapperPage\\.module__innerBody___1YM13 {\n  background-color: white;\n  margin: 0 auto;\n  /* max-width: 1640px; */\n}\n\n.ContentSkillsMapperPage\\.module__home___2E3jy {\n  font-family: 'Nunito', Arial, Helvetica, sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  --ap-pink: #cf0032;\n  --ap-green: #006067;\n}\n\n.ContentSkillsMapperPage\\.module__markdown___1dds3 {\n  font-family: 'Nunito', Arial, Helvetica, sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: left;\n  --ap-pink: #cf0032;\n  --ap-green: #006067;\n}\n\n.ContentSkillsMapperPage\\.module__background1___3f--O {\n  /* background-image: url('./header-bg/green-curve.svg');\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: 100% 70%; */\n  background-color: #025f67;\n}\n\n.ContentSkillsMapperPage\\.module__background1Inner___257sW {\n  background-color: white;\n  padding-bottom: 20px;\n}\n.ContentSkillsMapperPage\\.module__list___3KKAy {\n  padding-left: 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"outerBody": "ContentSkillsMapperPage.module__outerBody___2WCZv",
	"innerBody": "ContentSkillsMapperPage.module__innerBody___1YM13",
	"home": "ContentSkillsMapperPage.module__home___2E3jy",
	"markdown": "ContentSkillsMapperPage.module__markdown___1dds3",
	"background1": "ContentSkillsMapperPage.module__background1___3f--O",
	"background1Inner": "ContentSkillsMapperPage.module__background1Inner___257sW",
	"list": "ContentSkillsMapperPage.module__list___3KKAy"
};