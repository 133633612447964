import React from 'react'
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import Loader from 'app/components/Loader'
import countryCodes from 'country-codes-list'
import ToolTip from 'app/components/ToolTip'
import ReactTooltip from 'react-tooltip'

const reasons = [
  'Book a demo',
  'Request briefing bundles',
  'Need some help',
  'Provide feedback',
  'Partner inquiry',
  'Integrate with learning platform',
  'Other',
]

const jobRoles = [
  'Partnership Manager',
  'Product Manager',
  'Project Manager',
  'Human resources leader',
  'Learning leader',
  'Content specialist',
  'Technology/ Platform Specialist',
  'Other',
]

const products = [
  'Web Curation',
  'Content to Skills Mapper',
  'Complete Curation',
  'All products',
  `I'm not sure`,
]

const hearings = [
  {
    name: 'Recommendation / Referral (please provide details)',
    requirefield: true,
  },
  {
    name: 'Social - LinkedIn',
    requirefield: false,
  },
  {
    name: 'Social - Twitter',
    requirefield: false,
  },
  {
    name: 'Search engine',
    requirefield: false,
  },
  {
    name: 'Blog',
    requirefield: false,
  },
  {
    name: 'Newsletter',
    requirefield: false,
  },
  {
    name: 'Email',
    requirefield: false,
  },
  {
    name: 'Learning Technologies event',
    requirefield: false,
  },
  {
    name: 'Webinar',
    requirefield: false,
  },
  {
    name: 'Other event (please provide details)',
    requirefield: true,
  },
  {
    name: 'Other (please state)',
    requirefield: true,
  },
]

const sizes = [
  'Less than 1,000',
  '1,000 - 5,000',
  '5,000- 7,500',
  '7,500 - 10,000',
  '10,000 - 20,000',
  '20,000 - 50,000',
  '50,000 - 100,000',
  '100,000 - 150,000',
  '150,000+',
]

const selector = formValueSelector('contactForm')
@graphql(
  gql`
    mutation (
      $firstName: String!
      $lastName: String!
      $email: String!
      $prefix: String!
      $phone: String!
      $company: String!
      $jobRole: String!
      $country: String!
      $productRequired: String!
      $hearing: String!
      $hearingOption: String
      $message: String!
      $size: String!
      $receiveNewsletters: Boolean!
    ) {
      sendContactMessageNew(
        firstName: $firstName
        lastName: $lastName
        email: $email
        prefix: $prefix
        phone: $phone
        company: $company
        jobRole: $jobRole
        country: $country
        productRequired: $productRequired
        hearing: $hearing
        hearingOption: $hearingOption
        message: $message
        size: $size
        receiveNewsletters: $receiveNewsletters
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      sendContactMessageNew: (
        firstName,
        lastName,
        email,
        prefix,
        phone,
        company,
        jobRole,
        country,
        productRequired,
        hearing,
        hearingOption,
        message,
        size,
        receiveNewsletters,
      ) =>
        mutate({
          variables: {
            firstName,
            lastName,
            email,
            prefix,
            phone,
            company,
            jobRole,
            country,
            productRequired,
            hearing,
            hearingOption,
            message,
            size,
            receiveNewsletters,
          },
        }),
    }),
  },
)
@connect((state, props) => ({
  selectedType: selector(state, 'type'),
}))
@reduxForm({
  form: 'contactForm',
  destroyOnUnmount: false,
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    prefix: 'United Kingdom +44',
    phone: '',
    company: '',
    jobRole: jobRoles[0],
    country: 'United Kingdom',
    type: 'Book a demo',
    productRequired: products[0],
    hearing: '',
    hearingOption: '',
    message: '',
    size: 'Less than 1,000',
    receiveNewsletters: false,
  },
})
export default class ContactForm extends React.Component {
  constructor() {
    super()
    this.state = {
      sent: false,
      formType: 'full',
      requireField: true,
    }
  }
  componentDidMount() {
    if (this.props.type) {
      this.props.change('type', this.props.type)
    }
    if (this.props.formType) {
      this.setState({ formType: this.props.formType })
    }
  }
  onSubmit = (values) => {
    let prefix = values.prefix.split(' ').map((p) => p.trim())

    let message = values.message
    let productRequired = values.productRequired
    if (this.props.formType === 'completeCuration') {
      message = '[User filled in Complete Curation contact form]'
      productRequired = 'Complete Curation'
    }

    return this.props
      .sendContactMessageNew(
        values.firstName,
        values.lastName,
        values.email,
        prefix[prefix.length - 1],
        values.phone,
        values.company,
        values.jobRole,
        values.country,
        productRequired,
        values.hearing,
        values.hearingOption,
        message,
        values.size,
        values.receiveNewsletters,
      )
      .then((response) => {
        if (response.data.sendContactMessageNew === false) {
          throw new Error(
            'Sorry there was an error, please try again or email us directly at team@anderspink.com',
          )
        }
        this.setState({
          sent: true,
        })
        this.props.reset()
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }
  openModal = () => {
    this.setState({ isModalOpen: true })
  }

  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    if (this.props.type && !reasons.includes(this.props.type)) {
      reasons.push(this.props.type)
    }

    let countries = countryCodes.all()
    countries.sort((a, b) =>
      a.countryNameEn.toLowerCase() < b.countryNameEn.toLowerCase()
        ? -1
        : a.countryNameEn.toLowerCase() > b.countryNameEn.toLowerCase()
        ? 1
        : 0,
    )

    return (
      <div
        style={{
          maxWidth: this.props.width || '600px',
          margin: '0 auto',
          position: 'relative',
          padding: '1rem',
        }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>First Name *</label>
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Last Name *</label>
                <Field
                  name="lastName"
                  component="input"
                  type="text"
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
          {this.state.formType === 'full' && (
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Prefix</label>
                  <Field
                    name="prefix"
                    component="select"
                    type="number"
                    className="form-control"
                  >
                    {countries.map((country) => (
                      <option
                        key={country.countryName}
                        value={`${country.countryNameEn} +${country.countryCallingCode}`}
                      >
                        {country.flag} {country.countryNameEn} +
                        {country.countryCallingCode}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Phone Number</label>
                  <Field
                    name="phone"
                    component="input"
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Company Name *</label>
                <Field
                  name="company"
                  component="input"
                  type="text"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Company Email *</label>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Where did you hear about us?</label>
            <Field
              name="hearing"
              component="select"
              className="form-control"
              onChange={(e) => {
                let option = hearings.find(
                  (item) => item.name === e.target.value,
                )
                this.setState({ requireField: option && option.requirefield })
              }}
            >
              <option value="">Please select...</option>
              {hearings.map((hearing) => (
                <option key={hearing.name} value={hearing.name}>
                  {hearing.name}
                </option>
              ))}
            </Field>
          </div>
          {this.state.requireField && (
            <div className="form-group">
              <label>Please provide details</label>
              <Field
                name="hearingOption"
                component="input"
                type="text"
                className="form-control"
              />
            </div>
          )}
          <div className="form-group">
            <label>Audience / Number of Employees *</label>
            <Field
              name="size"
              component="select"
              className="form-control"
              required
            >
              {sizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Field>
          </div>
          {this.state.formType === 'full' && (
            <div className="form-group">
              <label>Job Role</label>
              <Field name="jobRole" component="select" className="form-control">
                {jobRoles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Field>
            </div>
          )}

          {this.state.formType === 'full' && (
            <div className="form-group">
              <label>Country *</label>
              <Field
                name="country"
                component="select"
                className="form-control"
                required
              >
                {countries.map((country) => (
                  <option
                    key={country.countryName}
                    value={country.countryNameEn}
                  >
                    {country.countryNameEn}
                  </option>
                ))}
              </Field>
            </div>
          )}

          {/* <div className="form-group">
            <label>Reason</label>
            <Field name="type" component="select" className="form-control">
              {reasons.map((reason) => (
                <option key={reason} value={reason}>
                  {reason}
                </option>
              ))}
            </Field>
          </div> */}

          {this.state.formType === 'full' && (
            <div className="form-group">
              <label>Which product you are most interested in?</label>
              <span
                style={{
                  backgroundColor: '#d8d8d8',
                  borderRadius: '50%',
                  width: '14px',
                  height: '14px',
                  fontSize: '10px',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '0.2rem',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  position: 'relative',
                  top: '-3px',
                }}
                data-tip
                data-for="productTip"
              >
                ?
              </span>
              <ReactTooltip id="productTip" place="top" effect="solid">
                Go to the footer on this page for links to view all product
                details in full
              </ReactTooltip>
              <Field
                name="productRequired"
                component="select"
                className="form-control"
              >
                {products.map((product) => (
                  <option key={product} value={product}>
                    {product}
                  </option>
                ))}
              </Field>
            </div>
          )}

          {this.state.formType === 'full' && (
            <div className="form-group">
              <label>Message *</label>
              <Field
                name="message"
                component="textarea"
                style={{ minHeight: '150px' }}
                className="form-control"
                required
              />
            </div>
          )}

          <div className="form-check mb-2">
            <label className="form-check-label">
              <Field
                name="receiveNewsletters"
                component="input"
                type="checkbox"
                normalize={(v) => {
                  return !!v ? true : false
                }}
                className="form-check-input"
              />{' '}
              Receive occasional updates from us including new features,
              articles and communications. You can opt out at any time.
            </label>
          </div>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <button className="btn btn-primary">Send</button>
        </form>

        {(submitting || this.state.sent) && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255,255,255,0.8)',
            }}
          >
            {submitting && (
              <div>
                <Loader />
              </div>
            )}
            {!submitting && (
              <div
                className="alert alert-success"
                style={{ margin: '2rem' }}
                role="alert"
              >
                Thank you for contacting us, we will get back in touch shortly!
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
