import React, { Component, Fragment } from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Button, ProgressBar, Modal } from 'react-bootstrap'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'

import styles from './Training.css'

import FixedModal from 'app/components/FixedModal'
import ToolTip from 'app/components/ToolTip'
import { FORM_NAMES } from '../constants'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state) => ({
  contentTypes: selector(state, 'contentTypes'),
}))
export default class ContentTypes extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const types = ['Articles', 'Videos', 'Podcasts']

    return (
      <div>
        {types.map((type) => (
          <div key={type} className="form-check form-check-inline">
            <input
              id={`contentType${type}`}
              className="form-check-input"
              type="checkbox"
              value={type.toLowerCase()}
              checked={this.props.contentTypes.includes(type.toLowerCase())}
              onChange={() => {
                let selectedTypes = [...this.props.contentTypes]
                const value = type.toLowerCase()

                if (selectedTypes.includes(value)) {
                  selectedTypes = selectedTypes.filter((c) => c !== value)
                } else {
                  selectedTypes.push(value)
                }

                if (
                  selectedTypes.includes('articles') &&
                  !selectedTypes.includes('videos')
                ) {
                  selectedTypes.push('videos')
                }

                this.props.change('contentTypes', selectedTypes)
              }}
            />
            <label className="form-check-label" for={`contentType${type}`}>
              {type}
            </label>
          </div>
        ))}

        <ToolTip>
          <p>
            By default you'll see articles, which may or may not have a video
            embedded in them.
          </p>
          <p>
            If you'd like to only see articles that contain videos, or to only
            see podcasts, you can adjust your preferences here.
          </p>
        </ToolTip>
      </div>
    )
  }
}
