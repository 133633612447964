import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import BriefingNameBlock from 'briefings/components/BriefingNameBlock'
import styles from './SearchPage.css'
import { Link } from 'react-router'

@graphql(
  gql`
    query($query: String) {
      briefings(public: true, query: $query) {
        id
        name
        image
        url
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          query: props.query,
        },
      }
    },
  },
)
export default class SearchResults extends React.Component {
  render() {
    if (this.props.data.loading) {
      return <div>Loading</div>
    }

    if (
      !this.props.data.loading &&
      this.props.query &&
      this.props.query.trim().length > 0 &&
      this.props.data.briefings.length === 0
    ) {
      return (
        <div>
          No briefings found, why not{' '}
          <Link to="/create-briefing" style={{ textDecoration: 'underline' }}>
            create your own
          </Link>{' '}
          on this topic.
        </div>
      )
    }

    return (
      <div>
        <div className={styles.briefings}>
          {this.props.data.briefings.map((briefing) => (
            <BriefingNameBlock key={briefing.id} briefing={briefing} />
          ))}
        </div>
      </div>
    )
  }
}
