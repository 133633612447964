import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

@graphql(
  gql`
    query accountQuery($id: ID!) {
      account(id: $id) {
        id
        type
        name
        canCopyTeam
        isD2lAvailable
        isDegreedAvailable
        activeSubscriptions {
          id
          isTrial
          plans {
            id
            planSet {
              id
              name
              totalNumBriefings
              totalNumBoards
              totalNumUsers
              totalNumApiBriefings
              totalNumApiBoards
              totalNumPublicBriefings
              totalNumPublicBoards
            }
          }
          invoices {
            id
            date
          }
        }
        teams {
          id
          name
          allowedNumBriefings
          allowedNumBoards
          allowedNumUsers
          allowedNumApiBriefings
          allowedNumApiBoards
          allowedNumPublicBriefings
          allowedNumPublicBoards
          teamUsers {
            user {
              id
            }
          }
          ownedBriefings {
            id
            name
            isPublic
          }
          boards {
            id
            name
            isPublic
          }
          apiUser {
            id
            briefings {
              id
              name
            }
            boards {
              id
              name
            }
          }
        }
        users {
          id
          firstName
          lastName
        }
        doesPlansetOverwrite
        forcePlansetInterval
        offeredPlanset {
          id
          name
        }
        d2lIntegration {
          clientId
          secret
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
export default class AccountContainer extends React.Component {
  static childContextTypes = {
    account: PropTypes.object,
  }
  constructor() {
    super()
    this.state = {
      account: null,
    }
  }
  getChildContext() {
    return {
      account: this.props.data.account,
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.data.account) {
      this.setState({
        account: newProps.data.account,
      })
    }
  }
  render() {
    if (!this.state.account) {
      return null
    }
    return (
      <div>
        {React.cloneElement(this.props.children, {
          account: this.state.account,
        })}
      </div>
    )
  }
}
