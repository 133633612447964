exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__text___1yTVg img[src$='#img-border'] {\n  border: 1px solid #eee;\n}\n\n.styles__text___1yTVg img {\n  max-width: 100%;\n}\n\n.styles__cta___1FM3m {\n  background-image: url(/pages/landing/viewfinder-telescope.png);\n  background-position: 50% 75%;\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n\n  height: 270px;\n  position: relative;\n}\n.styles__cta___1FM3m:before {\n  position: absolute;\n  content: '';\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(11, 19, 27, 0.3);\n}\n.styles__cta___1FM3m button {\n  position: relative;\n}\n.styles__ctaTop___1t3Ly {\n  font-size: 1.6rem;\n}\n.styles__ctaContent___2yCEu {\n  position: relative;\n  color: #ffffff;\n  max-width: 600px;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.styles__cta2___3u9LP {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n\n  height: 270px;\n  position: relative;\n}\n.styles__contact___3l3W_ {\n  text-align: center;\n  max-width: 700px;\n  margin: 0 auto;\n  padding: 3rem 0 2rem 0;\n}\n.styles__contactTop___3h9Qi {\n  font-size: 1.4rem;\n}\n.styles__contactBottom___2jXOy {\n  margin-top: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "styles__text___1yTVg",
	"cta": "styles__cta___1FM3m",
	"ctaTop": "styles__ctaTop___1t3Ly",
	"ctaContent": "styles__ctaContent___2yCEu",
	"cta2": "styles__cta2___3u9LP",
	"contact": "styles__contact___3l3W_",
	"contactTop": "styles__contactTop___3h9Qi",
	"contactBottom": "styles__contactBottom___2jXOy"
};