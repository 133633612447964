import React, { Component } from 'react'
import FixedModal from 'app/components/FixedModal'
import styles from './WordSuggestions.css'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { FORM_NAMES } from './constants'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state, props) => ({
  [props.field]: selector(state, props.field),
}))
export default class ExcludeWordSuggestions extends Component {
  static defaultProps = {
    onAdd: (word) => {},
  }
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }
  onAdd = (word) => {
    if (this.props[this.props.field].indexOf(word) < 0) {
      this.props.onAdd(word)
    }
  }
  render() {
    return (
      <div>
        <div
          style={{ fontSize: '0.8em', marginTop: '5px', marginBottom: '20px' }}
        >
          See some{' '}
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.setState({ isOpen: true })
            }}
          >
            useful words to exclude
          </span>
          , such as jobs and events.
        </div>

        <FixedModal
          width="700px"
          isOpen={this.state.isOpen}
          onRequestClose={() => this.setState({ isOpen: false })}
        >
          <div style={{ padding: '20px' }}>
            <h4>Useful words to exclude</h4>
            <p>
              By choosing some words to that must not appear in article titles,
              you can filter out irrelevant content.
            </p>
            <p>
              Some useful words to exclude are:{' '}
              <GroupAdd
                onAdd={this.onAdd}
                words={[
                  'job',
                  'jobs',
                  'position',
                  'event',
                  'workshop',
                  'webinar',
                  'course',
                  'conference',
                ]}
              />
            </p>
          </div>
        </FixedModal>
      </div>
    )
  }
}

class SingleAdd extends React.Component {
  static defaultProps = {
    word: '',
    onAdd: () => {},
  }
  render() {
    const { word } = this.props

    return (
      <span
        className={styles.link}
        onClick={() => {
          this.props.onAdd(word.toLowerCase())
        }}
      >
        {word.toLowerCase()}
      </span>
    )
  }
}

class GroupAdd extends React.Component {
  static defaultProps = {
    words: [],
    onAdd: () => {},
  }
  render() {
    const words = []
    for (let i = 0; i < this.props.words.length; i++) {
      const word = this.props.words[i]
      words.push(
        <span
          key={i}
          className={styles.link}
          onClick={() => {
            this.props.onAdd(word.toLowerCase())
          }}
        >
          {word.toLowerCase()}
        </span>,
      )
      if (i < this.props.words.length - 1) {
        words.push(', ')
      }
    }

    return (
      <div>
        <div>{words}</div>{' '}
        <div style={{ marginTop: '8px' }}>
          <button
            className="btn btn-secondary"
            onClick={() => {
              for (let word of this.props.words) {
                this.props.onAdd(word.toLowerCase())
              }
            }}
          >
            add all
          </button>
        </div>
      </div>
    )
  }
}
