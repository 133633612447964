exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".BriefingNameBlock__root___2BIwg {\n  width: 240px;\n  height: 150px;\n  margin: 10px;\n  background-color: #505050;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: 50%;\n  position: relative;\n  border-radius: 3px;\n}\n.BriefingNameBlock__inner___3taKP {\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: relative;\n  display: block;\n  -webkit-box-shadow: inset 0px 0px 100px 0px black;\n          box-shadow: inset 0px 0px 100px 0px black;\n  border-radius: 3px;\n}\n.BriefingNameBlock__text___a7Nrm {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  color: #fff;\n  right: 0;\n  top: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  text-align: center;\n}\n.BriefingNameBlock__textInner___2NneC {\n  padding: 15px;\n}\n.BriefingNameBlock__name___3bwtq {\n  font-size: 1.3rem;\n  letter-spacing: 0.02em;\n}\n.BriefingNameBlock__list___2Bqub {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: -10px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "BriefingNameBlock__root___2BIwg",
	"inner": "BriefingNameBlock__inner___3taKP",
	"text": "BriefingNameBlock__text___a7Nrm",
	"textInner": "BriefingNameBlock__textInner___2NneC",
	"name": "BriefingNameBlock__name___3bwtq",
	"list": "BriefingNameBlock__list___2Bqub"
};