exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ArticleActivity__articleTitle___HEWGM {\n  text-decoration: underline;\n}\n.ArticleActivity__date___2qfYP {\n  color: #888;\n  font-size: 0.8em;\n}\n.ArticleActivity__activity___2yVyR {\n  border-bottom: 1px solid #ddd;\n}\n.ArticleActivity__activity___2yVyR:last-of-type {\n  border-bottom: 0 none;\n}\n.ArticleActivity__activityLink___2lg28 {\n  color: inherit;\n  text-decoration: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  cursor: pointer;\n  -webkit-transition: background-color 0.2s linear;\n  transition: background-color 0.2s linear;\n  padding: 10px 0;\n}\n.ArticleActivity__activityLink___2lg28:hover {\n  background-color: #f8f8f8;\n  color: inherit;\n  text-decoration: none;\n}\n.ArticleActivity__left___3kQmq {\n  width: 35px;\n  margin-right: 10px;\n  margin-top: 3px;\n}\n.ArticleActivity__image___2qyZy {\n  max-width: 100%;\n  border-radius: 8%;\n}\n.ArticleActivity__right___W1-wo {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"articleTitle": "ArticleActivity__articleTitle___HEWGM",
	"date": "ArticleActivity__date___2qfYP",
	"activity": "ArticleActivity__activity___2yVyR",
	"activityLink": "ArticleActivity__activityLink___2lg28",
	"left": "ArticleActivity__left___3kQmq",
	"image": "ArticleActivity__image___2qyZy",
	"right": "ArticleActivity__right___W1-wo"
};