import React from 'react'
import moment from 'moment'

const defaultState = {
  userId: null, // set to null if not logged in
  teamId: null, // set to null if no team
  jwt: null,
  appQueryResult: null,
  previewBriefingResult: null,

  layout: {
    minimiseLeftNav: false,
    contentPadding: true,
    hideAllNav: false,
  },
  notificationId: 0,
  notifications: [],
  modal: null,
  features: { domainLists: true },
}

export default function appReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        userId: action.userId,
        teamId: action.teamId,
        jwt: action.jwt,
      }

    case 'SET_APP_LAYOUT':
      return {
        ...state,
        layout: {
          ...state.layout,
          ...action.layout,
        },
      }

    case 'SET_APP_QUERY_RESULT':
      return {
        ...state,
        appQueryResult: action.appQueryResult,
      }

    case 'SET_PREVIEW_BRIEFING_RESULT':
      return {
        ...state,
        previewBriefingResult: action.previewBriefingResult,
      }

    case 'SET_APP_MODAL':
      return {
        ...state,
        modal: action.modal,
      }

    case 'ADD_NOTIFICATION':
      let notificationId = state.notificationId + 1

      return {
        ...state,
        notificationId,
        notifications: [
          ...state.notifications,
          {
            id: notificationId,
            level: action.level || 'success',
            content: action.content,
            expires: moment()
              .add(action.ttl | 5, 'seconds')
              .unix(),
          },
        ],
      }

    case 'CLEAR_NOTIFICATION':
      let notifications = [...state.notifications]
      const index = notifications.findIndex(
        (notifications) => notifications.id == action.id,
      )
      if (index > -1) {
        notifications.splice(index, 1)
      }

      return {
        ...state,
        notifications: notifications,
      }

    default:
      return state
  }
}
