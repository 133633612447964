import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

const PREFIX = process.env.NODE_ENV === 'production' ? 'https' : 'http'
const DOMAIN = process.env.NODE_ENV === 'production' ? 'com' : 'local'

@connect((state) => ({
  app: state.app,
}))
export default class EnsureLoggedIn extends React.Component {
  componentDidMount() {
    if (this.props.app.userId === null) {
      window.location.href = `${PREFIX}://auth.anderspink.${DOMAIN}/login?referer=${encodeURIComponent(
        `${PREFIX}://app.anderspink.${DOMAIN}`,
      )}`
    }
  }
  render() {
    if (this.props.app.userId !== null) {
      return this.props.children
    } else {
      return null
    }
  }
}
