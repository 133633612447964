import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'
import FlipCard from 'react-flipcard-2'
import Experts from 'content/containers/Experts'

@connect((state) => ({
  app: state.app,
}))
export default class ClientSuccessStories extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    const clients = [
      {
        img: `${STATIC_DOMAIN}/pages/landing/scania-logo.png`,
        content: (
          <p>
            "Using Anders Pink is a key component of our curation strategy at
            Scania. It’s creating a seamless experience for visitors to the
            portal together with our own legacy content. Anders Pink is crucial
            for us to offer the the latest and greatest content on key topics
            for our business."
          </p>
        ),
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/charity-learning-logo.png`,
        content: (
          <p>
            "Our members ask more and more about content curation and how it
            could be made easier. To answer this demand and to help with
            continuous learning, we use Anders Pink. It’s an absolutely
            brilliant tool and is now a key part of our blended learning
            solution. Why spend hours searching when Anders Pink can do it for
            you?"
          </p>
        ),
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/mitchells-and-butler-logo.png`,
        content: (
          <p>
            "Having up to date, relevant articles on specific topics has
            heightened our learner experience, and has allowed us to create
            truly blended learning courses. This has been especially useful
            during our ‘Refresher Training’ windows whereby learning content on
            a specific topic is communicated to the entire organisation."
          </p>
        ),
      },
    ]
    const partners = [
      {
        img: `${STATIC_DOMAIN}/pages/landing/learning-pool-logo.png`,
        content: (
          <p>
            "Properly curated, Internet content is an important addition to the
            learning blend. Anders Pink gives us that at the LMS level and
            embedded in courses to create dynamic learning experiences. Simple
            to work with, easy to integrate, and high value outcomes."
          </p>
        ),
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/fuse-logo.png`,
        content: (
          <p>
            "Finding great external content is vital for our clients. Anders
            Pink is fully integrated into Fuse, bringing in the most popular,
            new content that people are loving. Amazing, automatic curation,
            saving time."
          </p>
        ),
      },
      {
        img: `${STATIC_DOMAIN}/pages/landing/kineo-logo.png`,
        content: (
          <p>
            "Anders Pink is really useful tool. We use it on the platforms we
            design. Access to constantly updated, well targeted and easy to find
            articles make the LMS a useful destination for the learner,
            encouraging repeat visits to the platform."
          </p>
        ),
      },
    ]

    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundImage: 'url(/pages/landing/shutterstock_306713030.jpg)',
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Client Success Stories</div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.textContainer}>
            <p>
              We're privileged to work with leading brands and partners to make
              them content curation heroes. They're using Anders Pink to curate
              content and get great recommendations from the web, updated
              everyday.
            </p>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            Here are just a few of our heroes, and what they have to say:
          </div>

          <div className={styles.clients}>
            {/* Capgemini */}
            <div className={styles.client}>
              <div className={styles.clientImage}>
                <img
                  src={`${STATIC_DOMAIN}/pages/landing/Capgemini-color.png`}
                />
              </div>

              <p className={styles.clientText}>
                Capgemini is a global leader in partnering with companies to
                transform and manage their business by harnessing the power of
                technology. With over 350,000 employees in more than 50
                countries, a 55-year heritage and deep industry expertise,
                Capgemini is trusted by its clients to address the entire
                breadth of their business needs. Capgemini houses its learning
                and development within Capgemini University, an integrated
                subsiduary of the Group focused solely on the development of
                Capgemini employees.
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/capgemini"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div>
            {/* Chaloub */}
            <div className={styles.client}>
              <div className={styles.clientImage}>
                <img src={`${STATIC_DOMAIN}/pages/landing/chalhoub-logo.png`} />
              </div>
              <p className={styles.clientText}>
                The Chalhoub Group, the leading partner for luxury across the
                Middle East, has always had a strong internal culture of
                education. The Chalhoub University was created to offer
                employees access to workshops, coaching, mentoring, and other
                learning opportunities.
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/chalhoub"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div>
            {/* Hitachi */}
            <div className={styles.client}>
              <div className={styles.clientImage}>
                <img src={`${STATIC_DOMAIN}/pages/landing/hitachi-logo.png`} />
              </div>
              <p className={styles.clientText}>
                Curated content is now part of our resources in support of a
                learning topic, embedded directly into our Learning Platform.
                Through this we can encourage a cultural change where learning
                is something you can do for 5-10 minutes a day to help us turn
                into a learning organization.
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/hitachi"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div>
            {/* <div className={styles.client}>
              <div className={styles.clientImage}>
                <img src={`${STATIC_DOMAIN}/pages/landing/cibc-logo.png`} />
              </div>
              <p className={styles.clientText}>
                Anders Pink has been a phenomenal go-to source for us to gather
                and curate engaging articles – which in turn our advisors use to
                grow their networks and generate leads. It saves our team a lot
                of time, and we can customize what we want to see.
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/cibc"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div> */}
            {/* Enterprise Ireland */}
            <div className={styles.client}>
              <div className={styles.clientImage}>
                <img src={`${STATIC_DOMAIN}/pages/landing/ei-logo.png`} />
              </div>
              <p className={styles.clientText}>
                We have added content quickly based on the needs of the relevant
                programme or trends. This has saved time and resources in
                sourcing content for subjects. Our clients tell us as time is
                precious for them, it is very advantageous to access relevant
                sources of content in one location.
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/enterprise-ireland"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div>
            {/* Scania */}
            <div className={styles.client}>
              <div className={styles.clientImage}>
                <img src={`${STATIC_DOMAIN}/pages/landing/scania-logo2.png`} />
              </div>
              <p className={styles.clientText}>
                Anders Pink is supporting Scania in their continued plans to
                build a continuous learning habit in a number of ways. Their
                automatically updated feeds of content on topics of strategic
                importance are helping:
                <ul>
                  <li>Support the company around digital transformation</li>
                  <li>Manage the explosion in knowledge and information</li>
                  <li>Support continuous learning and reskilling</li>
                </ul>
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/scania"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div>
            {/* Acc */}
            <div className={styles.client}>
              <div className={styles.clientImage}>
                <img src={`${STATIC_DOMAIN}/pages/landing/acc-logo.png`} />
              </div>
              <p className={styles.clientText}>
                Using Anders Pink has helped us build a library of ACC
                prioritised content at a fraction of a regular ‘library
                subscription’ cost – as you’re paying for the curation tool, not
                the content. We were able to provide a broader base of content
                across a larger set of organisational priorities than we would
                have otherwise... We now have one more learning tool to use in
                our problem-solving arsenal.
              </p>
              <div className={styles.clientButton}>
                <Link
                  to="/client-success-stories/acc"
                  className="btn btn-primary"
                >
                  See case study
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={pageStyles.greySection}>
          <div className={pageStyles.innerGreySection}>
            <div className={pageStyles.sectionHeading}>
              See what some of our other heroes have to say...
            </div>
            <br />
            <div className={pageStyles.sectionSubHeading}>Selected clients</div>

            <div className={pageStyles.clientBlocks}>
              {clients.map((client, i) => {
                return (
                  <div key={i} className={pageStyles.clientBlock}>
                    <FlipCard>
                      <div className={pageStyles.clientBlockFront}>
                        <img src={client.img} />
                      </div>
                      <div className={pageStyles.clientBlockBack}>
                        <div>{client.content}</div>
                        {!!client.caseStudyUrl && (
                          <div>
                            <Link
                              to={client.caseStudyUrl}
                              className="btn btn-primary btn-sm"
                            >
                              See case study
                            </Link>
                          </div>
                        )}
                      </div>
                    </FlipCard>
                  </div>
                )
              })}
            </div>

            <div className={pageStyles.sectionSubHeading}>
              Selected partners
            </div>

            <div className={pageStyles.clientBlocks}>
              {partners.map((client, i) => {
                return (
                  <div key={i} className={pageStyles.clientBlock}>
                    <FlipCard>
                      <div className={pageStyles.clientBlockFront}>
                        <img src={client.img} />
                      </div>
                      <div className={pageStyles.clientBlockBack}>
                        <div>{client.content}</div>
                        {!!client.caseStudyUrl && (
                          <div>
                            <Link
                              to={client.caseStudyUrl}
                              className="btn btn-primary btn-sm"
                            >
                              See case study
                            </Link>
                          </div>
                        )}
                      </div>
                    </FlipCard>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <Experts />

        <div className={styles.technical}>
          <div className={styles.technicalContent}>
            <p className={styles.technicalTop}>
              You can make a briefing on anything you want. See how easy it is:
            </p>
            <Link
              to="/contact?reason=Book%20a%20demo"
              className="btn btn-lg btn-primary"
            >
              Book a consultation
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
