import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form'

import Loader2 from 'app/components/Loader2'
import AdminTable from 'admin/components/AdminTable'
import axios from 'axios'

@reduxForm({
  form: 'adminTools',
  initialValues: {
    name: '',
  },
})
@graphql(
  gql`
    mutation ($id: ID!, $text: String) {
      skillsTag(id: $id, text: $text) {
        skill
        score
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      skillsTag: (id, text) =>
        mutate({
          variables: { id, text },
        }),
    }),
  },
)
export default class TeamSkillsTagPage extends React.Component {
  constructor() {
    super()
    this.state = {
      text: '',
      skills: [],
      loading: false,
    }
  }
  skillsTag() {
    this.setState({ loading: true })
    this.props
      .skillsTag(this.props.params.teamId, this.state.text)
      .then((response) => {
        console.log(response)
        this.setState({ skills: response.data.skillsTag, loading: false })
      })
      .catch((err) => {
        let error = err.message
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          error = err.graphQLErrors[0].message
        } else if (err.networkError) {
          error = err.networkError.message
        }
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          level: 'danger',
          content: error,
        })
      })
  }
  clear() {
    this.setState({
      text: '',
      skills: [],
    })
  }
  render() {
    const onSubmit = () => {}

    const { error, handleSubmit, submitting } = this.props

    return (
      <div>
        <h3>Skills tagging demo</h3>

        <div style={{ maxWidth: '700px' }}>
          <p>
            Paste in title/text below from a resource (i.e. article, course).
            Note: we currently only consider the first 500 words when tagging -
            anything further is ignored.
          </p>
          <textarea
            placeholder=""
            class="form-control form-control-sm"
            value={this.state.text}
            onChange={(e) => this.setState({ text: e.target.value })}
            rows="6"
          ></textarea>

          <div
            className="mt-2 mb-2"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.skillsTag()}
              >
                Extract skills
              </button>{' '}
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => this.clear()}
              >
                Clear
              </button>
            </div>
            <div>
              {this.state.text.length > 0
                ? `(${
                    this.state.text
                      .split(' ')
                      .map((w) => w.trim())
                      .filter((w) => !!w).length
                  } words)`
                : null}
            </div>
          </div>

          {this.state.loading && (
            <div className="mt-4">
              <Loader2 text="Loading" />
            </div>
          )}
          {this.state.skills.length > 0 && (
            <div className="mt-4">
              <h5>Most contextually relevant skills:</h5>
              <pre style={{ color: '#e83e8c' }}>
                {this.state.skills.map((s) => (
                  <div key={s.skill}>{`${s.score} - ${s.skill}`}</div>
                ))}
              </pre>
            </div>
          )}
        </div>
      </div>
    )
  }
}
