import React from 'react'
import { connect } from 'react-redux'
import FriendsArticles from 'friends/containers/FriendsArticles'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import styles from './FriendsPage.css'
import LinkTwitterPanel from 'authentication/containers/LinkTwitterPanel'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const selector = formValueSelector('friendsOverview')

@reduxForm({
  form: 'friendsOverview',
  initialValues: {
    filter: '',
  },
})
@graphql(
  gql`
    query {
      session {
        user {
          id
          isTwitterConnected
        }
      }
    }
  `,
  {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  },
)
export default class FriendsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appliedFilter: null,
      timeLimit: '3-hours',
    }
  }
  onFilterSubmit = (values) => {
    this.setState({
      appliedFilter: values.filter,
    })
    this.props.reset()
    return true
  }
  render() {
    if (this.props.data.loading) {
      return null
    }
    if (!this.props.data.session.user.isTwitterConnected) {
      return (
        <div>
          <h3>Link your Twitter account</h3>
          <LinkTwitterPanel />
        </div>
      )
    }

    const time = this.state.timeLimit

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h5
            style={{
              marginRight: '20px',
            }}
          >
            Most shared articles from your network
          </h5>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '5px' }}>In the last: </span>
            <div className="btn-group" role="group">
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '1-hour' ? 'primary' : 'secondary'
                }`}
                onClick={() => {
                  this.setState({
                    timeLimit: '1-hour',
                  })
                }}
              >
                1 hour
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '3-hours' ? 'primary' : 'secondary'
                }`}
                onClick={() => {
                  this.setState({
                    timeLimit: '3-hours',
                  })
                }}
              >
                3 hours
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '12-hours' ? 'primary' : 'secondary'
                }`}
                onClick={() => {
                  this.setState({
                    timeLimit: '12-hours',
                  })
                }}
              >
                12 hours
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-${
                  time === '24-hours' ? 'primary' : 'secondary'
                }`}
                onClick={() => {
                  this.setState({
                    timeLimit: '24-hours',
                  })
                }}
              >
                24 hours
              </button>
            </div>
          </div>
        </div>

        <div className="form-inline" style={{ margin: '10px 0' }}>
          <span>Filter by keyword </span>{' '}
          <Field
            name="filter"
            component="input"
            type="text"
            className="form-control mb-2 mr-2 ml-2"
            placeholder="e.g. big data, cycling.."
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.props.handleSubmit(this.onFilterSubmit)()
                e.preventDefault()
              }
            }}
          />
          <button
            className="btn btn-secondary mb-2"
            onClick={() => {
              this.props.handleSubmit(this.onFilterSubmit)()
            }}
          >
            Apply filter
          </button>
        </div>
        {this.state.appliedFilter !== null && (
          <div className="alert alert-info" style={{ margin: '10px 0' }}>
            Filtering by keyword "{this.state.appliedFilter}"{' '}
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => this.setState({ appliedFilter: null })}
            >
              Clear
            </span>
          </div>
        )}

        <div style={{ marginTop: '30px' }}>
          <FriendsArticles
            filter={this.state.appliedFilter}
            timeLimit={this.state.timeLimit}
          />
        </div>
      </div>
    )
  }
}
