exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Training__banner___2bCUE {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  background-color: #98d4ffeb;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding: 20px;\n  position: fixed;\n  left: 599px;\n  bottom: 0;\n  right: 0;\n  left: 599px;\n  padding: 20px;\n  z-index: 1;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n.Training__bannerTop___3Ggdf {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.Training__bannerBottom___1qcet {\n  text-align: center;\n  font-size: 0.8rem;\n  color: #555;\n  padding-top: 5px;\n}\n\n.Training__clearTrainingModal___1Adzn {\n  display: grid;\n  grid-template-rows: 50px 1fr;\n  padding: 15px;\n}\n\n.Training__ctModalText___1I9Xz {\n  text-align: center;\n  width: 100%;\n}\n\n.Training__ctModalBtns___3ZwaE {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 100%;\n}\n\n.Training__ctModalBtns___3ZwaE button {\n  margin: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"banner": "Training__banner___2bCUE",
	"bannerTop": "Training__bannerTop___3Ggdf",
	"bannerBottom": "Training__bannerBottom___1qcet",
	"clearTrainingModal": "Training__clearTrainingModal___1Adzn",
	"ctModalText": "Training__ctModalText___1I9Xz",
	"ctModalBtns": "Training__ctModalBtns___3ZwaE"
};