import React from 'react'

import styles from './Step.module.css'

const Step = (props) => {
  return (
    <article className={styles.step}>
      <img src={props.img} className={styles.stepImg} />
      <header className={styles.stepTitle}>{props.title}</header>
      <p className={styles.stepBody}>{props.bodyText}</p>
    </article>
  )
}

export default Step
