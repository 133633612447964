import React from 'react'
import moment from 'moment'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from 'recharts'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import palette from 'app/library/palette'
import { Link } from 'react-router'
import flattenStatFields from '../../utils/flattenStatFields'
import { Form, Container, Row, Col, Table } from 'react-bootstrap'

const selector = formValueSelector('monthlyStats')

@compose(
  withProps((props) => ({
    initialValues: {
      timeStart: String(new Date().getFullYear()),
    },
  })),
  reduxForm({
    form: 'monthlyStats',
  }),
  connect((state, props) => ({
    timeStart: selector(state, 'timeStart'),
  })),
  graphql(
    gql`
      query (
        $id: ID
        $timeStart: String
        $source: String
        $digest: String
        $send: String
      ) {
        team(id: $id) {
          id
          name
          teamStats(
            query: "monthly"
            timeStart: $timeStart
            source: $source
            digest: $digest
            send: $send
          ) {
            fields {
              name
              value
            }
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.id,
            timeStart: props.timeStart,
            source: props.source,
            digest: props.digest,
            send: props.send,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
export default class Monthly extends React.Component {
  constructor() {
    super()
    this.state = {
      time: null,
    }
  }
  render() {
    if (this.props.data.loading && !this.props.data.team) {
      return 'Loading...'
    }
    if (!this.props.data.team) {
      return 'Sorry, something went wrong'
    }

    let years = []
    for (let y = new Date().getFullYear(); y > 2016; y--) {
      years.push(String(y))
    }

    return (
      <div>
        <div>
          <h5 style={{}}>Monthly view</h5>

          <Form className="mb-2">
            <Form.Row className="align-items-center">
              <Col xs="auto">
                <Form.Label>Select year</Form.Label>
              </Col>
              <Col xs="auto">
                <select
                  className="form-control"
                  value={this.props.timeStart}
                  onChange={(e) => {
                    this.props.change('timeStart', e.target.value)
                  }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </Col>
              {this.props.data.loading && this.props.data.team && (
                <Col xs="auto">Loading...</Col>
              )}
            </Form.Row>
          </Form>

          <Row noGutters={false} className="mb-3">
            <Col>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Total views</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.data.team.teamStats
                    .map((stat) => flattenStatFields(stat))
                    .map((stat) => (
                      <tr key={stat.name}>
                        <td>{stat.name}</td>
                        <td>
                          {stat.value > 0 ? (
                            <Link
                              to={`/team-api-stats/${this.props.id}/time?timeType=month&timeStart=${stat.entityId}`}
                            >
                              {stat.value}
                            </Link>
                          ) : (
                            stat.value
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
            <Col>
              <LineChart
                width={600}
                height={400}
                data={this.props.data.team.teamStats
                  .map((stat) => flattenStatFields(stat))
                  .map(({ name, value }) => ({
                    name: name.slice(0, 3),
                    value: parseInt(value),
                  }))}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
