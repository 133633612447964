import React from 'react'

import styles from './PartnersListLogo.module.css'

const PartnersListLogo = (props) => {
  return (
    <div className={`${styles.partners1Logo} ${styles[props.name]}`}>
      <img src={props.src} alt={props.name} style={props.style} />
    </div>
  )
}

export default PartnersListLogo
