import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change,
} from 'redux-form'
import { Form } from 'react-bootstrap'

import Article from './../../../articles/containers/Article'
import ArticleListFullPage from './../../../articles/components/ArticleListFullPage'
import Loader from './../../../app/components/Loader'
import ToolTip from 'app/components/ToolTip'

import {
  DOMAIN_LIST_TYPES,
  FIELDS_DOMAIN_LISTS as FIELDS,
  FORM_NAMES,
  QUERIES,
} from './constants'
import { domainListsWithItemsQuery } from './queries'
import styles from './DomainBuckets.css'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@compose(
  connect(
    (state, props) => ({
      briefing: selector(
        state,
        'type',
        'keywordFields',
        'sourceFields',
        'looseFields',
        'keywordsV2Fields',
        'language',
        'influencerFilter',
        'domainFilter',
        'rssFilter',
      ),
      domainListType: selector(state, 'domainListType'),
      domainListAllowIds: selector(state, FIELDS.DOMAIN_LIST_ALLOW_IDS),
      domainListBlockIds: selector(state, FIELDS.DOMAIN_LIST_BLOCK_IDS),
    }),
    { change },
  ),
  graphql(
    gql`
      query domainBucketsQuery($briefing: BriefingInput!) {
        briefingPreviewDomainBuckets(briefing: $briefing) {
          domain
          articles {
            id
            title
            content
            url
            image
            domain
            datePublished
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            briefing: {
              ...props.briefing,
              keywordsV2Fields: {
                ...props.briefing.keywordsV2Fields,
                domainsAllowBriefing: [],
                domainsBlockBriefing: [],
                domainListAllowIds: [],
                domainListBlockIds: [],
              },
            },
          },
          fetchPolicy: 'cache-and-network',
        }
      },
      name: QUERIES.DOMAIN_BUCKETS,
    },
  ),
  graphql(domainListsWithItemsQuery.tag, domainListsWithItemsQuery.args),
)
export default class DomainBuckets extends Component {
  constructor(props) {
    super(props)

    let showArticles = localStorage.getItem('domainBucketsShowArticles')
    showArticles = showArticles === null || showArticles === '1'

    this.state = {
      activePreview: '',
      showArticles,
      domainArticlesVisible: {},
    }
  }
  componentDidUpdate(prevProps) {
    const { loading: wasLoading } = prevProps[QUERIES.DOMAIN_BUCKETS]
    const { loading: isLoading } = this.props[QUERIES.DOMAIN_BUCKETS]
    if (wasLoading && !isLoading) {
      this.props.onLoaded()
    }
  }
  toggleDomain = (domain) => {
    const {
      briefing: {
        keywordsV2Fields: { domainsAllowBriefing, domainsBlockBriefing },
      },
    } = this.props
    const isAllow = this.props.domainListType === DOMAIN_LIST_TYPES.ALLOW
    const currentDomains = isAllow ? domainsAllowBriefing : domainsBlockBriefing

    const updatedDomains = currentDomains.includes(domain)
      ? currentDomains.filter((d) => d !== domain)
      : [...currentDomains, domain]
    const field = isAllow
      ? FIELDS.DOMAINS_ALLOW_BRIEFING
      : FIELDS.DOMAINS_BLOCK_BRIEFING
    this.props.change(FORM_NAMES.EDIT_BRIEFING, field, updatedDomains)
  }
  domainInBriefingList = (domain) => {
    const {
      briefing: {
        keywordsV2Fields: {
          domainsAllowBriefing = [],
          domainsBlockBriefing = [],
        },
      },
    } = this.props
    const selectedDomains =
      this.props.domainListType === 'allow'
        ? domainsAllowBriefing
        : domainsBlockBriefing
    return selectedDomains.includes(domain)
  }
  domainToListNameMap = () => {
    const { domainListsWithItems = [], loading } =
      this.props[QUERIES.DOMAIN_LISTS_WITH_ITEMS] || {}
    return domainListsWithItems.reduce((acc, { listName, items }) => {
      for (const { domain } of items) {
        const entry = acc.get(domain) || []
        acc.set(domain, [...entry, listName])
      }
      return acc
    }, new Map())
  }
  articlesForDomain = () => {
    const { domainListsWithItems = [], loading } =
      this.props[QUERIES.DOMAIN_LISTS_WITH_ITEMS] || {}
  }
  domainListNamesForDomain = (domain) => {
    if (loading !== false) {
      return
    }

    return this.domainToListNameMap().get(domain)
  }
  showPreview = (domain) => {
    this.setState((state) => ({
      ...state,
      activePreview: domain,
    }))
  }
  hidePreview = (domain) => {
    this.setState((state) => ({
      ...state,
      activePreview: '',
    }))
  }
  domainIsInASelectedList = (domain) => !!this.domainToListNameMap().get(domain)
  domainSelected = (domain) => {
    return (
      this.domainInBriefingList(domain) || this.domainIsInASelectedList(domain)
    )
  }
  render() {
    const { loading: loadingDomainBuckets, briefingPreviewDomainBuckets = [] } =
      this.props[QUERIES.DOMAIN_BUCKETS]

    if (loadingDomainBuckets) {
      return (
        <div className={styles.loading}>
          Loading sites...
          <Loader />
        </div>
      )
    }

    const unSelectedDomainBuckets = briefingPreviewDomainBuckets.filter(
      ({ domain }) => !this.domainSelected(domain),
    )
    const selectedDomainBuckets = briefingPreviewDomainBuckets.filter(
      ({ domain }) => this.domainSelected(domain),
    )
    const previewDomain = this.state.activePreview || ''
    const previewBucket = briefingPreviewDomainBuckets.find(
      ({ domain }) => domain === previewDomain,
    )

    const isBlock = this.props.domainListType === DOMAIN_LIST_TYPES.BLOCK

    const previewArticles = previewBucket ? previewBucket.articles : []
    const { domainListType } = this.props

    return (
      <>
        <div className={styles.domainBucketsContainer}>
          <div className={styles.domainBucketsTop}>
            <div className={styles.domainBucketsTitle}>
              {isBlock && <span>Sites that you may wish to block</span>}
              {!isBlock && <span>Sites that you may wish to allow</span>}
            </div>
            <div>
              <Form.Check
                id="showSampleArticles"
                type="switch"
                label="Show sample articles"
                checked={this.state.showArticles}
                onChange={(e) => {
                  localStorage.setItem(
                    'domainBucketsShowArticles',
                    this.state.showArticles ? '0' : '1',
                  )
                  this.setState({
                    showArticles: !this.state.showArticles,
                    domainArticlesVisible: {},
                  })
                }}
              />
            </div>
          </div>

          <div className={styles.domainBucketsBottom}>
            {briefingPreviewDomainBuckets.map(({ domain, articles }) => (
              <div
                key={domain}
                className={`${styles.domainItem} ${
                  this.domainSelected(domain) ? styles.domainItemSelected : ''
                }`}
              >
                <div className={styles.bucketLabel}>
                  <span className={styles.bucketDomain}>{domain}</span>
                  <div>
                    {!this.state.showArticles && (
                      <span
                        className={styles.viewArticles}
                        onClick={() => {
                          const domainArticlesVisible = {
                            ...this.state.domainArticlesVisible,
                          }
                          if (domainArticlesVisible[domain]) {
                            delete domainArticlesVisible[domain]
                          } else {
                            domainArticlesVisible[domain] = true
                          }
                          this.setState({
                            domainArticlesVisible,
                          })
                        }}
                      >
                        View articles{' '}
                        {this.state.domainArticlesVisible[domain] ? (
                          <i className="fa fa-chevron-down" />
                        ) : (
                          <i className="fa fa-chevron-right" />
                        )}
                      </span>
                    )}
                    {!this.domainIsInASelectedList(domain) && (
                      <button
                        className={`btn btn-sm ${
                          this.domainSelected(domain)
                            ? 'btn-secondary'
                            : 'btn-primary'
                        }`}
                        onClick={() => this.toggleDomain(domain)}
                      >
                        {this.domainSelected(domain)
                          ? isBlock
                            ? 'Unblock this site'
                            : 'Unallow this site'
                          : isBlock
                          ? 'Block this site'
                          : 'Allow this site'}
                      </button>
                    )}
                    {this.domainIsInASelectedList(domain) && (
                      <span>(in a selected list)</span>
                    )}
                  </div>
                </div>
                {(this.state.showArticles ||
                  this.state.domainArticlesVisible[domain]) && (
                  <div>
                    {articles.map((article) => (
                      <div key={article.id} className={styles.article}>
                        <a
                          href={article.url}
                          target="_blank"
                          className={`link ${styles.articleTitle}`}
                        >
                          {article.title}
                        </a>

                        <div className={styles.articleLower}>
                          <span className={styles.articleDate}>
                            {moment(article.datePublished).fromNow()}
                          </span>{' '}
                          <span className={styles.articleContent}>
                            {article.content}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}
