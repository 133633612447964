import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import pageStyles from 'content/pages/styles.css'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import EBookBanner from './components/EBookBanner/EBookBanner'
import Footer from './components/Footer/Footer'
import Button from './components/Button'
import HowItWorks from './components/HowItWorks/HowItWorks'
import Video from './components/Video/Video'
import DownloadPressRelease from './components/DownloadPressRelease'
import styles from './CompleteCurationPage.module.css'
import Testimonials from './components/Testimonials/Testimonials'

@connect((state) => ({
  app: state.app,
}))
export default class PlatformsCompleteCurationPage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.outerBody}>
        <div className={styles.innerBody}>
          <main className={styles.home}>
            <EBookBanner bannerColor="white" externalLink={false} />
            <div className={styles.background1}>
              <Navbar
                backgroundColors="dark"
                pinkOrGreen="pink"
                logoColor="white"
              />
              <Intro
                headerText="Helping your clients discover and curate the best content for their teams, mapped to the skills that matter"
                bullet1="Supercharge your learning platform with advanced search"
                bullet2="Map content to skills, automatically"
                bullet3="Enable your clients to:"
                sub1="Make better sense of their learning content"
                sub2="Curate the best learning content from all content libraries"
                sub3="Create great learning pathways at scale"
                headerColor="white"
                bulletPointColor="white"
                bulletTextColor="white"
                homepage="complete-curation"
                withProductSheet={true}
              />
              <div className={styles.background1Inner}></div>
            </div>

            <div className={pageStyles.section}>
              <div className={pageStyles.sectionHeadingThin}>
                What is Complete Curation?
              </div>
              <div className={pageStyles.textContainer}>
                <p>
                  Complete Curation is our AI powered solution that enables
                  powerful search, curation and tagging of content from any
                  source: internal libraries, licensed content, or the web. It’s
                  available as an app or integrated into your platform via API.
                </p>
              </div>

              <div className={pageStyles.sectionHeadingThin}>
                What problem does it solve?
              </div>
              <div className={pageStyles.textContainer}>
                <p>
                  We all need to continuously develop to stay relevant and drive
                  business performance. L&D teams are tasked with finding the
                  right content aligned to skills and areas of interest, and
                  organize them in learning platforms as plans or pathways.
                  <br />
                  <br />
                  But let’s face it: there’s a lot of learning content out
                  there. And it’s not very searchable - or organized. One of the
                  biggest L&D challenges is how to organize the masses of
                  learning content they have, tag it with the right skills, and
                  then find the right content to upskill and reskill their
                  teams. And then keep their learning platforms updated with the
                  most relevant content.
                  <br />
                  <br />
                  Doing that manually takes time and effort. Our Complete
                  Curation app does it automatically at scale. So your clients
                  get a better return on their content investment, and get
                  access to the content that best meets their needs, from
                  anywhere. And you get a world-class search engine embedded
                  into your learning platform.
                </p>
              </div>

              <div className={pageStyles.sectionHeadingThin}>
                Why plug Complete Curation into your platform?
              </div>
              <div className={pageStyles.textContainer}>
                <p>
                  - Boost your platform’s search capability, making it easy for
                  your clients to find the content they need
                </p>
                <p>
                  - Map content to skills at speed, at pace - any skills
                  framework can be used, so if your clients use different skills
                  frameworks… no problem
                </p>
                <p>
                  - Power your platform with world-class search, enabling your
                  clients to search and combine all of their learning content,
                  from internal resources, multiple libraries and platforms, and
                  freemium web content, in one place
                </p>
                <p>
                  - Enable your clients to create learning pathways at speed,
                  curate the best content their organization owns in multiple
                  content libraries and platforms, and add it quickly to your
                  LMS or LXP, tagged with skills
                </p>
                <p>
                  - Generate additional revenue - whitelabel the Complete
                  Curation app in your platform, add value for your clients
                </p>
                <div style={{ textAlign: 'center' }}>
                  <Link to="/contact?reason=Book%20a%20demo">
                    <Button
                      title="Book a consultation"
                      backgroundColor="#CF0032"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <Testimonials withOldHeader={true} />
            <div style={{ textAlign: 'center' }}>
              <HowItWorks
                stepContent={[
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw1.png`,
                    headerText: '1. Connect',
                    bodyText:
                      'Work with your clients to enable them to share the data in their current content libraries and platforms (e.g. SharePoint, learning platforms, video platforms) to the Complete Curation app. Or connect your client’s libraries via an API or connector (coming soon). Map the data in Complete Curation and within minutes see all content in one place, tagged with skills.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw2.png`,
                    headerText: '2. Search',
                    bodyText:
                      'Using advanced NLP techniques, Complete Curation understands the context of any search (skill, question or phrase) so your clients can rest assured that the most relevant learning assets are surfaced which best match their search term.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw3.png`,
                    headerText: '3. Curate',
                    bodyText:
                      'From the search results, choose learning assets to add to a “Curation”. Your clients can refine their search based on duration, media type, publish date, provider, language and level. Or drill down on a specific content item to find other closely related content from content libraries or from the web.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw7.png`,
                    headerText: '4. Collaborate',
                    bodyText:
                      'Optionally, invite subject matter experts to review and refine Curations. They can also add comments for the curator.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw8.png`,
                    headerText: '5. Go live',
                    bodyText:
                      'Your client then adds “Curations” to lists or pathways in your learning platform, tagged with relevant skills. New learning pathways built within minutes.',
                  },
                ]}
              />
            </div>
            <Video bgColor="pink" />
            <div className={pageStyles.section}>
              <div className={pageStyles.sectionHeadingThin}>
                More features and integrations coming soon!
              </div>
            </div>
            <DownloadPressRelease />
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}
