import React from 'react'
import { Link } from 'react-router'
import Carousel from 'react-elastic-carousel'

import Step from './Step'
import styles from './HowItWorks.module.css'

// const breakPoints = [
//   { width: 1, itemsToShow: 1 },
//   { width: 660, itemsToShow: 2 },
//   { width: 1020, itemsToShow: 3, pagination: false, showArrows: false },
// ]

const HowItWorks = (props) => {
  let paginationAndArrows = false

  if (props.stepContent.length > 3) {
    paginationAndArrows = true
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 650, itemsToShow: 2 },
    { width: 800, itemsToShow: 2 },
    {
      width: 1000,
      itemsToShow: 3,
      pagination: paginationAndArrows,
      showArrows: paginationAndArrows,
    },
  ]

  return (
    <section className={styles.howItWorks}>
      <header className={styles.hiwHeader}>How it works</header>
      <div className={styles.hiwBg} />
      <Carousel className={styles.hiwCarousel} breakPoints={breakPoints}>
        {props.stepContent.map((step) => (
          <Step
            key={step.headerText}
            img={step.img}
            title={step.headerText}
            bodyText={step.bodyText}
          />
        ))}
      </Carousel>
      <Link to="/contact?reason=Book%20a%20demo">
        <button className={styles.hiwBtn}>Book a consultation</button>
      </Link>
    </section>
  )
}

export default HowItWorks
