import React from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import EBookBanner from './components/EBookBanner/EBookBanner'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import PartnersCarousel from './components/PartnersCarousel/OldPartnersCarousel'
import CTA from './components/CTA/CTA'
import Video from './components/Video/Video'
import PartnersList from './components/PartnersList/PartnersList'
import Quotes from './components/Quotes/Quotes'
import HowItWorks from './components/HowItWorks/HowItWorks'
import CaseStudies from './components/CaseStudies/CaseStudies'
import EBookForm from './components/EBookForm/EBookForm'
import Footer from './components/Footer/Footer'

import enterpriseLogos from './logos/enterpriseLogos'
import platformsLogos from './logos/platformsLogos'
import pageStyles from '../styles.css'
import styles from './ContentSkillsMapperPage.module.css'
import Testimonials from './components/Testimonials/Testimonials'

const contents = [
  {
    id: 'what-is-the-content-to-skills-mapper',
    title: 'What is the Content to Skills Mapper?',
    description:
      'The Content to Skills Mapper is a state of the art, NLP tool that provides a highly accurate and fully automated solution for tagging any asset, with skills.',
  },
  {
    id: 'what-problem-does-the-content-to-skills-mapper-solve',
    title: 'What problem does the Content to Skills Mapper solve?',
    description: `Organizations have lots of content - potentially tens if not hundreds of thousands of content items. These might be courses, eBooks, videos, podcasts or articles. And all of these content items aim to help upskill and reskill their people.\n
\n
Except that the content is disorganized. It’s not tagged with the right skills. It doesn’t map to the organization’s skills taxonomy. So they can’t find the best content they have to help upskill and reskill their people. So they buy more content to plug content “gaps”.\n
\n
In fact, there’s so much content, it’s overwhelming and well, pretty redundant. Many organizations spend hours working on spreadsheets to try to keep a handle on their content libraries. We decided there must be a better way.\n
\n
So we built the Content to Skills Mapper.`,
  },
  {
    id: 'the-conent-to-skills-mapper-enables-organizations',
    title: 'The Content to Skills Mapper enables organizations to:',
    description: `- Tag all content in any format with skills, at scale and pace\n
\n
- Save hours of time - Content to Skills Mapper is fully automated - no human input or verification required\n
\n
- Map content to any skills taxonomy or framework\n
\n
- Easily and automatically update content skills tags when frameworks change\n
\n
- Tag everything - Empower everyone to develop the skills that matter\n
\n
- Quickly find content to upskill and reskill everyone\n
\n
- Identify skills gaps across different content types\n
\n
- Make better content buying decisions\n`,
  },
  {
    id: 'what-is-in-it-for-your-platform',
    title: 'What’s in it for your platform?',
    description: `Other than making your clients’ lives easier?\n
\n
- Using the Content to Skills Mapper API you can tag all content automatically as it’s added to customer libraries, including off the shelf content libraries\n
\n
- Enable search by skills in your platform\n
\n
- Enhance Search in your platform with adjacent skills or topics\n
\n
- Fuel your recommendation engine with consistent skills tagging`,
  },
  {
    id: 'why-is-the-content-to-skills-mapper-the-best',
    title:
      'Why is the Content to Skills Mapper the best solution on the market?',
    description: `**Fast**: Fully automated - no human intervention required\n
\n
**Accurate**: Using multiple NLP techniques, skills tagging is highly accurate\n
\n
**Easy to integrate**: Fully featured API for whitelabelling in platforms\n
\n
**Flexible**: Content can be tagged using any skills framework or ontology automatically
`,
  },
]

@connect((state) => ({
  app: state.app,
}))
export default class ConentSkillsMapperPage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.outerBody}>
        <div className={styles.innerBody}>
          <main className={styles.home}>
            <EBookBanner bannerColor="white" externalLink={false} />
            <div className={styles.background1}>
              <Navbar
                backgroundColors="dark"
                pinkOrGreen="green"
                logoColor="white"
              />
              <Intro
                headerText="Solving the Content to Skills Mapping challenge automatically"
                subtitle="What skills does your content really cover? Our new product answers that, instantly"
                // bullet1="What skills does your content really cover?"
                // bullet2="Our new product answers that, instantly"
                // bullet3="Help teams upskill and stay smart"
                headerColor="white"
                bulletPointColor="white"
                bulletTextColor="white"
                homepage="content-skills"
              />
              <div className={styles.background1Inner}>
                {/* <PartnersCarousel
                  headerText="Making our partners and clients heroes"
                  logos={enterpriseLogos}
                /> */}
              </div>
            </div>

            <div className={styles.markdown}>
              <div className={pageStyles.section}>
                <div className={pageStyles.sectionHeadingThin}>
                  What is the Content to Skills Mapper?
                </div>
                <div
                  className={[pageStyles.textContainer, styles.text].join(' ')}
                >
                  <p>
                    Organizations are not short on content. They have thousands
                    of learning assets: courses, eBooks, videos, podcasts,
                    articles.{' '}
                  </p>

                  <p>
                    But what does that content actually cover? How well does it
                    map to the skills that matter to the organization and its
                    people? Where are the gaps? What is not relevant?{' '}
                  </p>

                  <p>
                    There’s a content to skills problem. We’re seeing it across
                    L&D. Content is not tagged with the right skills. It doesn’t
                    map to the organization’s skills taxonomy. Manual analysis
                    of every content item, assessing its quality and relevance,
                    deciding what to keep, cut, or buy: This is time-consuming
                    and not scalable. Many organizations spend thousands of
                    hours working on spreadsheets to try to keep a handle on
                    their content and aligning it to skills.{' '}
                  </p>

                  <p>There’s got to be a better way. And now there is:</p>

                  <p>
                    <strong>Anders Pink Content to Skills Mapper</strong>
                  </p>
                </div>
              </div>

              <div className={pageStyles.section}>
                <div className={pageStyles.sectionHeadingThin}>
                  The Content to Skills Mapper enables organizations to:
                </div>
                <div
                  className={[pageStyles.textContainer, styles.text].join(' ')}
                >
                  <ul className={styles.list}>
                    <li>
                      Tag all content in any format with skills, automatically
                    </li>

                    <li>
                      Save time and effort: Content to Skills Mapper is fully
                      automated, analyzing thousands of content items against
                      skills in seconds. No manual analysis needed{' '}
                    </li>

                    <li>
                      Map content to any skills taxonomy or framework – your
                      own, or an industry standard
                    </li>

                    <li>
                      Easily and automatically update content skills tags when
                      frameworks change
                    </li>

                    <li>
                      Tag everything: Empower everyone to develop the skills
                      that matter
                    </li>

                    <li>
                      Quickly find content to upskill and reskill everyone
                    </li>

                    <li>Identify skills gaps across different content types</li>
                    <li>
                      Make better content buying decisions, improving the value
                      of your learning content{' '}
                    </li>
                  </ul>
                </div>
              </div>

              <div className={pageStyles.section}>
                <div className={pageStyles.sectionHeadingThin}>
                  Power your Learning Platform with Skills to Content Mapping:
                </div>
                <div
                  className={[pageStyles.textContainer, styles.text].join(' ')}
                >
                  <ul className={styles.list}>
                    <li>
                      Using the Content to Skills Mapper API you can tag all
                      content automatically as it’s added to your client’s
                      instance - so it’s easy for them and you to see how
                      content maps to skills{' '}
                    </li>

                    <li>Enable search by skills in your platform</li>

                    <li>
                      Enhance Search in your platform with adjacent skills or
                      topics
                    </li>

                    <li>
                      Power your recommendation engine with consistent skills
                      tagging
                    </li>

                    <li>
                      Fully integrated and white-labelled in any Learning
                      Platform.{' '}
                    </li>
                  </ul>
                </div>
              </div>

              <div className={pageStyles.section}>
                <div className={pageStyles.sectionHeadingThin}>
                  Get More from Your Content with our Skills to Content Mapper:
                </div>
                <div
                  className={[pageStyles.textContainer, styles.text].join(' ')}
                >
                  <ul className={styles.list}>
                    <li>
                      Save time and effort: Fully automated - no human
                      intervention required
                    </li>

                    <li>
                      Accurate: Using multiple NLP techniques, skills tagging is
                      highly accurate{' '}
                    </li>

                    <li>
                      Easy to integrate: Fully featured API for whitelabelling
                      in platforms
                    </li>

                    <li>
                      Flexible: Content can be tagged using any skills framework
                      or ontology automatically
                    </li>
                  </ul>

                  <p>Get in touch for a demo today. </p>
                </div>
              </div>

              {/* {contents.map((item, index) => {
                return (
                  <div key={index} className={pageStyles.section}>
                    <div className={pageStyles.sectionHeadingThin}>
                      {item.title}
                    </div>

                    <div
                      className={[pageStyles.textContainer, styles.text].join(
                        ' ',
                      )}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          p: ({ node, ...props }) => (
                            <p style={{ fontSize: '20px' }} {...props} />
                          ),
                        }}
                        children={item.description}
                      />
                    </div>
                  </div>
                )
              })} */}
            </div>
            <HowItWorks
              stepContent={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw4.png`,
                  headerText: '1. Upload',
                  bodyText:
                    'Use our API to pass us text from any source (article, course, eBook, video, podcast, etc). This could be the title, description, snippet of body content or the learning objectives',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw5.png`,
                  headerText: '2. Instant skills mapping',
                  bodyText:
                    'Using state of the art machine learning, the Content to Skills Mapper instantly returns the top skills, as well as adjacent skills, for all content analyzed',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw6.png`,
                  headerText: '3. Power your platform',
                  bodyText:
                    'Store skills mapped to content in your learning platform, making search easy and focused',
                },
              ]}
            />
            <Video bgColor="pink" />
            <PartnersList
              headerText="Partnering with the world's leading learning platforms"
              logos={platformsLogos}
            />
            {/* <Quotes headerText="What our partners and clients are saying about the Anders Pink Product Suite?" /> */}
            <PartnersCarousel
              headerText="Making our partners and clients heroes"
              logos={enterpriseLogos}
            />
            <CaseStudies
              headerText="Discover how other enterprise clients are automating content curation with Anders Pink Web Curation"
              studies={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-ei-logo.png`,
                  caption: 'Enterprise Ireland case study',
                  title:
                    'Driving engagement with fresh content embedded into learning platform',
                  link: '/client-success-stories/enterprise-ireland',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-scania-logo2.png`,
                  caption: 'Scania case study',
                  title:
                    'Driving engagement by supporting the business through rapid change',
                  link: '/client-success-stories/scania',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/chalhoub-logo.png`,
                  caption: 'Chalhoub case study',
                  title:
                    'Enhancing employee learning opportunities with curated content',
                  link: '/client-success-stories/chalhoub',
                },
              ]}
            />
            <EBookForm />
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}
