import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'

import AdminTable from 'admin/components/AdminTable'

const ITEMS_PER_PAGE = 50

@graphql(
  gql`
    query ($id: ID!) {
      user(id: $id) {
        id
        firstName
        lastName
        email
        teams {
          id
          name
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation ($userId: ID!) {
      adminRemoveUserFromUsers(userId: $userId)
    }
  `,
  {
    name: 'adminRemoveUserFromUsers',
  },
)
@connect((state) => ({
  query: state,
}))
export default class AdminUserPage extends React.Component {
  render() {
    if (this.props.data.loading) {
      return null
    }
    const admin = this.props.query.app.userId
    const user = this.props.data.user

    const handleAlertDialog = () => {
      return this.props
        .adminRemoveUserFromUsers({
          variables: {
            userId: user.id,
          },
        })
        .then(() => {
          browserHistory.push({
            pathname: '/admin/users',
            state: { refetch: true },
          })
          this.props.dispatch({
            type: 'ADD_NOTIFICATION',
            content: `User succesfully deleted`,
          })
        })
        .catch((err) => {
          let error = ''
          if (err.graphQLErrors && err.graphQLErrors.length > 0) {
            error = err.graphQLErrors[0].message
          } else if (err.networkError) {
            error = err.networkError.message
          } else {
            error = err.message
          }
          this.props.dispatch({
            type: 'ADD_NOTIFICATION',
            content: error,
          })
        })
    }

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>User</h5>
          {admin !== user.id && (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                if (confirm('Do you really want to delete this user?')) {
                  handleAlertDialog()
                }
              }}
            >
              Delete user
            </button>
          )}
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">ID</label>
          <div className="col-10">{user.id}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">First Name</label>
          <div className="col-10">{user.firstName}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Last Name</label>
          <div className="col-10">{user.lastName}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Email</label>
          <div className="col-10">{user.email}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Teams</label>
          <div className="col-10">
            {user.teams.map((team) => (
              <div key={team.id}>
                <Link to={`/admin/teams/${team.id}`}>{team.name}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
