exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AllBriefingsPage__title___FwN7V {\n  margin-bottom: 20px;\n}\n.AllBriefingsPage__titleContainer___1-e60 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.AllBriefingsPage__publicBriefings___2LYSt {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n.AllBriefingsPage__publicBriefing___2ktSt {\n  margin: 0 20px 20px 0;\n  width: 400px;\n}\n.AllBriefingsPage__optionsMenu___1u0KP {\n  color: #aaa;\n  text-decoration: underline;\n  font-size: 1.4em;\n\n  -webkit-transition: color 0.1s linear;\n\n  transition: color 0.1s linear;\n  cursor: pointer;\n  margin-left: 10px;\n}\n.AllBriefingsPage__optionsMenu___1u0KP:hover {\n  color: inherit;\n}\n.AllBriefingsPage__dropdownLink___1aFv5 {\n  display: block;\n  padding: 7px 13px;\n  color: inherit;\n}\n", ""]);
// Exports
exports.locals = {
	"title": "AllBriefingsPage__title___FwN7V",
	"titleContainer": "AllBriefingsPage__titleContainer___1-e60",
	"publicBriefings": "AllBriefingsPage__publicBriefings___2LYSt",
	"publicBriefing": "AllBriefingsPage__publicBriefing___2ktSt",
	"optionsMenu": "AllBriefingsPage__optionsMenu___1u0KP",
	"dropdownLink": "AllBriefingsPage__dropdownLink___1aFv5"
};