import React from 'react'
import styles from './ArticleComments.css'
import moment from 'moment'
import { MentionsInput, Mention } from 'react-mentions'
import articleInfoFragment from 'articles/fragments/articleInfo'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import withTeamUsers from 'articles/containers/withTeamUsers'
import Comment from 'articles/components/Comment'
import { connect } from 'react-redux'

const defaultMentionStyle = {
  backgroundColor: '#ffc6da',
}
const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 'normal',
  },

  input: {
    margin: 0,
  },

  control: {
    boxShadow: '0px 0px 1px 0px rgb(119, 119, 119)',
    borderRadius: '4px',
  },

  highlighter: {
    padding: 9,
  },

  input: {
    padding: 9,
    minHeight: 28,
    outline: 0,
    border: 0,
    fontFamily: 'inherit',
    fontSize: '0.9em',
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 10,
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#ffc6da',
      },
    },
  },
}

@withTeamUsers
@graphql(
  gql`
    mutation($articleId: ID!, $comment: CommentInput) {
      addCommentToArticle(articleId: $articleId, comment: $comment) {
        ...articleInfo
      }
    }
    ${articleInfoFragment}
  `,
  {
    name: 'addCommentToArticle',
    options: (props) => ({
      variables: {
        articleId: props.article.id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation($articleId: ID!, $commentId: ID) {
      deleteComment(articleId: $articleId, commentId: $commentId) {
        ...articleInfo
      }
    }
    ${articleInfoFragment}
  `,
  {
    props: ({ mutate }) => ({
      deleteComment: (articleId, commentId) =>
        mutate({
          variables: { articleId, commentId },
        }),
    }),
  },
)
@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
export default class ArticleComments extends React.Component {
  constructor() {
    super()
    this.state = {
      value: '',
      valuePlainText: '',
      mentions: [],
    }
  }
  handleChange = (event, newValue, newPlainTextValue, mentions) => {
    this.setState({
      value: newValue,
      valuePlainText: newPlainTextValue,
      mentions: mentions,
    })
  }
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.comments}>
          {this.props.article.comments.map((comment, index) => (
            <div key={index} className={styles.comment}>
              <Comment
                comment={comment}
                onDelete={() => {
                  this.props.deleteComment(this.props.article.id, comment.id)
                  this.props.dispatch({
                    type: 'ADD_NOTIFICATION',
                    content: `Comment deleted`,
                  })
                }}
                canDelete={
                  this.props.appQueryResult &&
                  this.props.appQueryResult.user &&
                  this.props.appQueryResult.user.id === comment.user.id
                }
              />
            </div>
          ))}
        </div>

        <MentionsInput
          value={this.state.value}
          onChange={this.handleChange}
          placeholder="Add a comment"
          style={defaultStyle}
          onKeyPress={(e) => {
            if (e.key == 'Enter') {
              e.preventDefault()
              this.props.addCommentToArticle({
                variables: {
                  comment: {
                    text: this.state.value,
                    plainText: this.state.valuePlainText,
                    mentions: this.state.mentions.map((mention) => ({
                      userId: mention.id,
                      index: mention.index,
                      plainTextIndex: mention.plainTextIndex,
                      display: mention.display,
                    })),
                  },
                },
              })
              this.setState({
                value: '',
              })
            }
          }}
        >
          <Mention
            trigger="@"
            data={this.props.teamUsers.map((teamUser) => ({
              id: teamUser.user.id,
              display: `${teamUser.user.firstName} ${teamUser.user.lastName}`,
            }))}
            style={defaultMentionStyle}
          />
        </MentionsInput>
      </div>
    )
  }
}
