import React, { Component } from 'react'
import zxcvbn from 'zxcvbn'
import { Field, reduxForm } from 'redux-form'

export default class NewPasswordInput extends Component {
  state = {
    password: '',
  }
  render() {
    const {
      name = 'password',
      className = 'form-control',
      placeholder = 'Password',
    } = this.props

    const scores = {
      0: { color: '#c42023', label: 'Password: Too short' },
      1: { color: '#c42023', label: 'Password strength: Very weak' },
      2: { color: '#c42023', label: 'Password strength: Weak' },
      3: { color: '#f6ac1c', label: 'Password strength: Fair' },
      4: { color: '#a1bc72', label: 'Password strength: Good' },
      5: { color: '#19a418', label: 'Password strength: Excellent' },
    }

    let score = null

    if (this.state.password.length > 0) {
      score =
        this.state.password.length < 8
          ? scores[0]
          : scores[zxcvbn(this.state.password).score + 1]
    }

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <Field
          name={name}
          component="input"
          type="password"
          className={className}
          placeholder={placeholder}
          onChange={(e) => {
            this.setState({
              password: e.currentTarget.value.trim(),
            })
          }}
        />
        {!!score && (
          <span
            style={{
              position: 'absolute',
              right: '10px',
              top: 0,
              bottom: 0,
              alignItems: 'center',
              display: 'flex',
              fontSize: '0.8rem',
              color: score.color,
            }}
          >
            {score.label}
          </span>
        )}
      </div>
    )
  }
}
