import React from 'react'
import { Field, reduxForm, SubmissionError, isDirty } from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import Select from 'react-select'
import FixedModal from 'app/components/FixedModal'

import Loader from 'app/components/Loader'

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
  jwt: state.app.jwt,
}))
@graphql(
  gql`
    mutation($user: UserInput!) {
      updateUser(user: $user) {
        id
        firstName
        lastName
        email
        receiveDailyDigest
        receiveNewsletters
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUser: (user) =>
        mutate({
          variables: {
            user,
          },
          /*refetchQueries: [
                'appQuery'
            ]*/
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID, $briefingIds: [ID], $articleQuantity: Int) {
      updateSlackIntegrationBriefings(
        id: $id
        briefingIds: $briefingIds
        articleQuantity: $articleQuantity
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      updateSlackIntegrationBriefings: (id, briefingIds, articleQuantity) =>
        mutate({
          variables: {
            id,
            briefingIds,
            articleQuantity: articleQuantity || 0,
          },
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation($id: ID) {
      sendSlackIntegrationTest(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      sendSlackIntegrationTest: (id) =>
        mutate({
          variables: {
            id,
          },
        }),
    }),
  },
)
export default class ProfilePage extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      saving: false,
    }
  }
  render() {
    if (!this.props.appQueryResult || !this.props.appQueryResult.user) {
      return null
    }

    const slackIntegrations = this.props.appQueryResult.user.slackIntegrations
    const slackRedirectUrl = window.location.origin + '/slack-oauth'

    return (
      <div>
        <h3>Integrate with Slack (Free in beta)</h3>

        <p>
          Receive a daily digest of your briefings into any Slack channel.{' '}
          <a
            className="link"
            href="http://blog.anderspink.com/2017/12/how-to-use-slack-to-support-learning-in-your-workflow/"
          >
            Read more
          </a>{' '}
          about why you should integrate Anders Pink with Slack.
        </p>

        {slackIntegrations.length > 0 && (
          <div style={{ marginTop: '2rem' }}>
            <SlackAppForm
              slackIntegrations={slackIntegrations}
              userId={this.props.appQueryResult.user.id}
              teams={this.props.appQueryResult.user.teams}
              sendSlackIntegrationTest={this.props.sendSlackIntegrationTest}
              dispatch={this.props.dispatch}
              saving={this.state.saving}
              onSubmit={(values) => {
                this.setState({
                  saving: true,
                })

                return Promise.all(
                  slackIntegrations.map((slackIntegration) => {
                    const briefingIds = values[slackIntegration.id]
                      .split(',')
                      .filter((id) => !!id)
                      .map((id) => parseInt(id))
                    const articleQuantity =
                      values[slackIntegration.id + '_articleQuantity']
                    return this.props.updateSlackIntegrationBriefings(
                      slackIntegration.id,
                      briefingIds,
                      parseInt(articleQuantity),
                    )
                  }),
                )
                  .then(() => {
                    this.setState({
                      saving: false,
                      isModalOpen: true,
                    })
                  })
                  .catch((err) => {
                    this.setState({
                      saving: false,
                    })
                    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                      throw new SubmissionError({
                        _error: err.graphQLErrors[0].message,
                      })
                    } else {
                      throw new SubmissionError({
                        _error: err.message,
                      })
                    }
                  })
              }}
              initialValues={{
                ...slackIntegrations.reduce((carry, slack) => {
                  carry[slack.id] = slack.briefings
                    .map((briefing) => briefing.id)
                    .join(',')
                  carry[slack.id + '_articleQuantity'] = slack.articleQuantity
                  return carry
                }, {}),
              }}
            />
            <br />
            <br />
          </div>
        )}

        <br />
        <h5>Get started</h5>
        <p>
          Setup a new integration below and choose{' '}
          {slackIntegrations.length > 0 ? 'another' : 'a'} channel for us to
          post briefings to:
        </p>
        <a
          href={`https://slack.com/oauth/authorize?client_id=19056480530.277798771237&scope=incoming-webhook&state=${
            this.props.jwt
          }&redirect_uri=${encodeURIComponent(slackRedirectUrl)}`}
        >
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
        <br />
        <br />
        <br />
        <p>
          Need a daily digest of competitor intelligence, tips or trends on any
          topic right in your Slack?{' '}
          <Link to="/create-briefing" className="link">
            Create a briefing
          </Link>
          .
        </p>

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          <div style={{ padding: '20px' }}>
            <h3>Great, your changes have been saved!</h3>
            <p>Would you like to send the digest now?</p>

            <div style={{ display: 'flex', margin: '2rem 0' }}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  this.setState({ isModalOpen: false })

                  return Promise.all(
                    slackIntegrations.map((slackIntegration) => {
                      return this.props.sendSlackIntegrationTest(
                        slackIntegration.id,
                      )
                    }),
                  )
                    .then(() => {
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Successfully sent manual digest`,
                      })
                    })
                    .catch((err) => {
                      let msg = null
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        msg = err.graphQLErrors[0].message
                      } else {
                        msg = err.message
                      }
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: msg,
                      })
                    })
                }}
              >
                Yes, send it now
              </button>
              <button
                className="btn btn-sm btn-secondary"
                style={{ marginLeft: '5px' }}
                onClick={() => this.setState({ isModalOpen: false })}
              >
                No, don't send it now
              </button>
            </div>

            <p>
              Either way we will send your digest through tomorrow at 8am GMT,
              and every weekday after that!
            </p>
          </div>
        </FixedModal>
      </div>
    )
  }
}

@graphql(
  gql`
    query briefings($forUserId: ID!) {
      briefings(forUserId: $forUserId) {
        id
        name
        teamId
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          forUserId: props.userId,
        },
      }
    },
  },
)
@reduxForm({
  form: 'slackApp',
})
class SlackAppForm extends React.Component {
  render() {
    const {
      onSubmit,
      error,
      handleSubmit,
      submitting,
      teams,
      data,
      dirty,
      slackIntegrations,
    } = this.props

    if (data.loading) {
      return <div>Loading..</div>
    }

    const teamsById = teams.reduce((carry, team) => {
      carry[team.id] = team
      return carry
    }, {})

    const briefings = data.briefings.slice(0).sort((a, b) => {
      if (a.name.toLowerCase() === b.name.toLowerCase()) {
        return 0
      }
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    })

    const briefingOptions = briefings.map((briefing) => {
      let teamName = 'Public' // If its a public briefing from another team
      if (briefing.teamId == 1) {
        teamName = 'Personal'
      } else if (teamsById[briefing.teamId] !== undefined) {
        teamName = teamsById[briefing.teamId].name
      }
      return {
        value: briefing.id,
        label: `${briefing.name} (${teamName})`,
      }
    })

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5>Your existing integrations</h5>

        <p>
          We will send a daily digest each day (at 8am GMT) to the following
          channels, taking the top articles from your chosen briefings.
        </p>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Channel</th>
              <th scope="col">Briefings</th>
              <th scope="col">Max no. articles per briefing</th>
            </tr>
          </thead>
          <tbody>
            {slackIntegrations.map((slackIntegration) => (
              <tr key={slackIntegration.id}>
                <td>{slackIntegration.channelName}</td>
                <td style={{ width: '100%' }}>
                  <Field
                    name={slackIntegration.id}
                    component={(props) => (
                      <Select
                        simpleValue={true}
                        multi={true}
                        removeSelected={false}
                        value={props.input.value}
                        onChange={(selectedOption) => {
                          props.input.onChange(selectedOption)
                        }}
                        options={briefingOptions}
                      />
                    )}
                  />
                </td>
                <td>
                  <Field
                    name={slackIntegration.id + '_articleQuantity'}
                    component="input"
                    type="number"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {error && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{ margin: '0.5rem 0' }}
          >
            {error}
          </div>
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ margin: '1.1rem 0' }}>
            <button className="btn btn-primary">Save</button>
          </div>
          {this.props.saving && (
            <div>
              <Loader />
            </div>
          )}
        </div>
      </form>
    )
  }
}
