import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

@reduxForm({
  form: 'accountCreateTeam',
  initialValues: {
    name: '',
  },
})
export default class NewTeamForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
  }
  constructor() {
    super()
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Team name</label>
          <Field
            name="name"
            component="input"
            type="text"
            className="form-control"
          />
        </div>

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <button type="submit" className="btn btn-primary" disabled={submitting}>
          Create
        </button>
      </form>
    )
  }
}
