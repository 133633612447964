import React from 'react'
import styles from './Comment.css'
import moment from 'moment'
import FixedModal from 'app/components/FixedModal'

export default class Comment extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalMounted: false,
      isMenuOpen: false,
    }
    this.timeoutId = null
  }
  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }
  onOpen = () => {
    this.setState({
      isModalMounted: true,
      isMenuOpen: true,
    })
  }
  onClose = () => {
    this.setState({
      isMenuOpen: false,
    })
    this.timeoutId = setTimeout(() => {
      this.timeoutId = null
      this.setState({
        isModalMounted: false,
      })
    }, 300)
  }
  onDelete = () => {
    this.onClose()
    this.props.onDelete()
  }
  render() {
    const { comment, canDelete } = this.props
    const commentHTML = comment.text.replace(
      /\[(.*)\]\((.*)\)/,
      '<a href="$2">$1</a>',
    )

    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <img className={styles.image} src={comment.user.image} />
        </div>
        <div className={styles.right}>
          <div>
            <strong>{`${comment.user.firstName} ${comment.user.lastName}`}</strong>{' '}
            <span dangerouslySetInnerHTML={{ __html: commentHTML }} />
          </div>
          <div className={styles.time}>{moment(comment.date).fromNow()}</div>
        </div>

        {canDelete && (
          <div className={styles.menu} onClick={this.onOpen}>
            <i className="fa fa-ellipsis-h" />
          </div>
        )}

        {this.state.isModalMounted && (
          <FixedModal
            isOpen={this.state.isMenuOpen}
            onRequestClose={this.onClose}
            buttons={[
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.onClose}
              >
                Close
              </button>,
            ]}
          >
            <div className={styles.modal}>
              Delete this comment
              <button
                className="btn btn-sm btn-danger ml-2"
                onClick={this.onDelete}
              >
                Delete
              </button>
            </div>
          </FixedModal>
        )}
      </div>
    )
  }
}
