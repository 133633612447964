import React from 'react'
import {
  Router,
  Route,
  Redirect,
  IndexRoute,
  browserHistory,
} from 'react-router'

import App from './app/containers/App'
import ChromeExtension from './chrome/containers/ChromeExtension'
import D2LIntegration from './d2l-integration/D2L'
import pageWrapper from './app/containers/pageWrapper'
// import LoginPage from './authentication/containers/LoginPage'
import LogoutPage from './authentication/containers/LogoutPage'
import TwitterSignupPage from './authentication/containers/TwitterSignupPage'
import EmailSignupPage from './authentication/containers/EmailSignupPage'
import ResetPasswordPage from './authentication/containers/ResetPasswordPage'
import SetNewPasswordPage from './authentication/containers/SetNewPasswordPage'
import AcceptTeamInvitePage from './authentication/containers/AcceptTeamInvitePage'
import JoinTeamPage from './authentication/containers/JoinTeamPage'
import ProfilePage from './user/containers/ProfilePage'
import ChangePasswordPage from './user/containers/ChangePasswordPage'
import ProfileSlackPage from './user/containers/ProfileSlackPage'
import ProfileMSTeamsPage from './user/containers/ProfileMSTeamsPage'
import AccountUnsubscribePage from './user/containers/AccountUnsubscribePage'

import MSTeamsDemoPage from './home/containers/MSTeamsDemoPage'
import BriefingOrder from './home/containers/BriefingOrder'

import FriendsPage from './friends/containers/FriendsPage'
import IndexPage from './home/containers/IndexPage'
import CreateBriefingPage from './briefings/containers/CreateBriefingPage'
import NotFoundPage from './home/containers/NotFoundPage'
import DiscoverPage from './discover/containers/DiscoverPage'
import BrowsePage from './discover/containers/BrowsePage'
import AllBriefingsPage from './briefings/containers/AllBriefingsPage'
import BriefingPage from './briefings/containers/BriefingPage'
import LatestNewsPage from './briefings/containers/LatestNewsPage'
import BoardPage from './boards/containers/BoardPage'
import AllBoardsPage from './boards/containers/AllBoardsPage'
import ArticlePage from './articles/containers/ArticlePage'
import EditBriefingPage from './briefings/containers/EditBriefingPage'
import CopyBriefingPage from './briefings/containers/CopyBriefingPage'
import MoveBriefingPage from './briefings/containers/MoveBriefingPage'
import DeleteBriefingPage from './briefings/containers/DeleteBriefingPage'
import BriefingRefreshPage from './briefings/containers/BriefingRefreshPage'
import BriefingTrainingPage from './briefings/containers/BriefingTrainingPage'
import BriefingImagePage from './briefings/containers/BriefingImagePage'
import BriefingArticleImagesPage from './briefings/containers/BriefingArticleImagesPage'
import BriefingDegreedSkillsPage from './briefings/containers/BriefingDegreedSkillsPage'
import DegreedBriefingPage from './briefings/containers/DegreedBriefingPage'

import APIDocumentationPage from './content/containers/APIDocumentationPage'
import APIDocumentationV1Page from './content/containers/APIDocumentationV1Page'
import APIDocumentationV2Page from './content/containers/APIDocumentationV2Page'
import APIDocumentationV4Page from './content/containers/APIDocumentationV4Page'
import SlackPage from './content/containers/SlackPage'
import SearchPage from './search/containers/SearchPage'
import EnsureLoggedIn from './app/containers/EnsureLoggedIn'
import EnsureLoggedOut from './app/containers/EnsureLoggedOut'
import ApiSettingsPage from './api/containers/ApiSettingsPage'
import TeamSettingsPage from './team/containers/TeamSettingsPage'
import TeamSkillsTagPage from './team/containers/TeamSkillsTagPage'
import CreateTeamPage from './team/containers/CreateTeamPage'
import TeamStatsPage from './team/containers/TeamStatsPage'
import TeamUserStatsPage from './team/containers/TeamUserStatsPage'

import TeamApiStats from './team/containers/TeamApiStats'
import TeamApiStatsBriefing from './team/containers/TeamApiStatsBriefing'
import TeamApiStatsFolder from './team/containers/TeamApiStatsFolder'
import TeamApiStatsTime from './team/containers/TeamApiStatsTime'
import TeamApiStatsDomain from './team/containers/TeamApiStatsDomain'

import CreateOrganisationPage from './organisation/containers/CreateOrganisationPage'
import AllOrganisationsPage from './organisation/containers/AllOrganisationsPage'

import SubscriptionPage from './subscription/containers/SubscriptionPage'
import SetupSubscriptionPage from './subscription/containers/SetupSubscriptionPage'
import SetupCustomSubscriptionPage from './subscription/containers/SetupCustomSubscriptionPage'

import AccountContainer from './accounts/containers/AccountContainer'
import AccountPage from './accounts/containers/AccountPage'
import UpdateTeamPage from './accounts/containers/UpdateTeamPage'

import SetupCustomAccountSubscriptionPage from './accounts/containers/SetupCustomAccountSubscriptionPage'

import AdminPage from './admin/containers/AdminPage'
import AdminUsersPage from './admin/containers/AdminUsersPage'
import AdminUserPage from './admin/containers/AdminUserPage'
import AdminTeamsPage from './admin/containers/AdminTeamsPage'
import AdminTeamPage from './admin/containers/AdminTeamPage'
import AdminApiUsersPage from './admin/containers/AdminApiUsersPage'
import AdminCreatePlanPage from './admin/containers/AdminCreatePlanPage'
import AdminCrawlerPage from './admin/containers/AdminCrawlerPage'
import AdminPlansPage from './admin/containers/AdminPlansPage'
import AdminPlanPage from './admin/containers/AdminPlanPage'
import AdminAccountsPage from './admin/containers/AdminAccountsPage'
import AdminAccountPage from './admin/containers/AdminAccountPage'
import AdminCreateAccountPage from './admin/containers/AdminCreateAccountPage'
import AdminToolsPage from './admin/containers/AdminToolsPage'

import Landing from 'content/pages/landing/Landing'
import NewHomepage from 'content/pages/new-homepage/MainHomepage'
import PlatformsHomepage from 'content/pages/new-homepage/PlatformsHomepage'
import EnterpriseHomepage from 'content/pages/new-homepage/EnterpriseHomepage'
import ContentSkillsMapperPage from 'content/pages/new-homepage/ContentSkillsMapperPage'
import PlatformsCompleteCurationPage from 'content/pages/new-homepage/PlatformsCompleteCurationPage'
import EnterpriseCompleteCurationPage from 'content/pages/new-homepage/EnterpriseCompleteCurationPage'
import EBookFormPage from 'content/pages/ebook-form/EBookFormPage'
import Integrations from 'content/pages/integrations/Integrations'
import Integration from 'content/pages/integrations-sharepoint/IntegrationsSharepoint'
import Partners from 'content/pages/partners/Partners'
import PartnersDetail from 'content/pages/partners-detail/PartnersDetail'
import PartnersTotara from 'content/pages/partners/PartnersTotara'
import ClientSuccessStories from 'content/pages/client-stories/ClientSuccessStories'
import ClientSuccessStory from 'content/pages/client-story/ClientSuccessStory'
import Contact from 'content/pages/contact/Contact'
import ApiInfo from 'content/pages/api-info/ApiInfo'
import ContentPage from 'content/pages/page/Page'
import Pricing from 'content/pages/pricing/Pricing'
import SupportPage from 'content/containers/SupportPage'
import UpdateAccountSubscriptionPage from './accounts/containers/UpdateAccountSubscriptionPage'

import SourceFiltersPanel from './sources-filter/containers/SourceFiltersPanel'

import ListDigestPage from './digest/containers/ListDigestPage'
import NewDigestPage from './digest/containers/NewDigestPage'
import EditDigestPage from './digest/containers/EditDigestPage'
import StatsDigestPage from './digest/containers/StatsDigestPage'
import IndividualStatsDigestPage from './digest/containers/IndividualStatsDigestPage'

import Unsubscribe from './unsubscribe'
import SupportPageTotaraLearnWrapper from './content/containers/SupportPageTotaraLearn'
import SupportPageTotaraEngageWrapper from './content/containers/SupportPageTotaraEngage'

export default function (history) {
  return (
    <Router
      history={history}
      onUpdate={function () {
        let { action } = this.state.location

        if (action === 'PUSH') {
          window.scrollTo(0, 0)

          if (window.ga) {
            window.ga('set', 'page', this.state.location.pathname)
            window.ga('send', 'pageview')
          }
        }
      }}
    >
      <Route path="/chrome-extension" component={ChromeExtension} />
      <Route
        path="/d2l-integration"
        isEmbedded={true}
        component={pageWrapper(D2LIntegration)}
      />
      <Route
        path="/briefing-embedded-degreed/:id/:slug(/:time)"
        component={DegreedBriefingPage}
      />
      <Route path="/download-ebook" component={pageWrapper(EBookFormPage)} />
      <Route path="/" component={App}>
        {/* <IndexRoute component={pageWrapper(NewHomepage)} /> */}
        {/* <Route
          path="/wc/platforms"
          component={pageWrapper(PlatformsHomepage)}
        />
        <Route
          path="/wc/enterprise"
          component={pageWrapper(EnterpriseHomepage)}
        />
        <Route
          path="/content-skills-mapper"
          component={pageWrapper(ContentSkillsMapperPage)}
        />
        <Route
          path="/cc/platforms"
          component={pageWrapper(PlatformsCompleteCurationPage)}
        />
        <Route
          path="/cc/enterprise"
          component={pageWrapper(EnterpriseCompleteCurationPage)}
        />
        <Route path="/unsubscribe" component={pageWrapper(Unsubscribe)} />

        <Route path="/integrations" component={pageWrapper(Integrations)} />
        <Route path="/integrations/:id" component={pageWrapper(Integration)} />
        <Route path="/partners" component={pageWrapper(Partners)} />
        <Route
          path="/partners/totara"
          component={pageWrapper(PartnersTotara)}
        />
        <Route path="/partners/:id" component={pageWrapper(PartnersDetail)} />
        <Route
          path="/client-success-stories"
          component={pageWrapper(ClientSuccessStories)}
        />
        <Route
          path="/client-success-stories/:id"
          component={pageWrapper(ClientSuccessStory)}
        />
        <Route path="/contact" component={pageWrapper(Contact)} />
        <Route path="/api-info" component={pageWrapper(ApiInfo)} /> */}
        <Route path="/c/:id" component={pageWrapper(ContentPage)} />
        {/* <Route path="/pricing" component={pageWrapper(Pricing)} /> */}
        {/* <Route path="/support" component={pageWrapper(SupportPage)} /> */}
        {/* <Route
          path="/support/totara-learn"
          component={pageWrapper(SupportPageTotaraLearnWrapper)}
        />
        <Route
          path="/support/totara-engage"
          component={pageWrapper(SupportPageTotaraEngageWrapper)}
        /> */}

        <Route
          path="briefing/:id/:slug(/:time)"
          component={pageWrapper(BriefingPage)}
        />
        <Route
          path="briefing-embedded/:id/:slug(/:time)"
          component={pageWrapper(BriefingPage)}
          isEmbedded={true}
          hideAllNav={true}
        />
        <Route
          path="saved-articles/:id/:slug"
          component={pageWrapper(BoardPage)}
        />
        <Route
          path="saved-articles-embedded/:id/:slug"
          component={pageWrapper(BoardPage)}
          isEmbedded={true}
          hideAllNav={true}
        />
        <Route path="latest-news" component={pageWrapper(LatestNewsPage)} />
        <Route
          path="invite/:token"
          component={pageWrapper(AcceptTeamInvitePage)}
        />
        <Route
          path="join-team/:teamId/:token"
          component={pageWrapper(JoinTeamPage)}
        />
        <Route
          path="twitter-signup"
          component={pageWrapper(TwitterSignupPage)}
        />

        <Route
          path="twitter-signup"
          component={pageWrapper(TwitterSignupPage)}
        />

        <Route
          path="embed/team/:id/:secret(/:time)"
          component={pageWrapper(MSTeamsDemoPage)}
          hideAllNav={true}
        />

        <Route
          path="team/briefings-order"
          component={pageWrapper(BriefingOrder)}
        />

        <Route path="api-docs" component={pageWrapper(APIDocumentationPage)} />
        <Route
          path="api-docs/v1"
          component={pageWrapper(APIDocumentationV1Page)}
        />
        <Route
          path="api-docs/v2"
          component={pageWrapper(APIDocumentationV2Page)}
        />
        <Route
          path="api-docs/v4"
          component={pageWrapper(APIDocumentationV4Page)}
        />
        <Redirect from="complete-curation" to="/cc/enterprise" />
        <Redirect from="features" to="/c/product" />
        <Redirect from="about" to="/c/about" />
        <Redirect from="js-widget" to="/c/javascript-embed-code" />
        <Redirect from="moodle-plugin" to="/integrations/moodle" />
        <Redirect from="wordpress-plugin" to="/integrations/wordpress" />

        <Redirect from="privacy-policy" to="/c/privacy-policy" />
        <Redirect from="terms-and-conditions" to="/c/terms-and-conditions" />

        <Route path="articles/:id" component={pageWrapper(ArticlePage)} />
        <Route path="article/:id" component={pageWrapper(ArticlePage)} />
        <Route
          path="account/unsubscribe/:token"
          component={pageWrapper(AccountUnsubscribePage)}
        />
        <Route path="slack" component={pageWrapper(SlackPage)} />

        <Route
          path="ms-teams-configuration"
          hideAllNav={true}
          component={pageWrapper(ProfileMSTeamsPage)}
        />

        {/* <Route component={EnsureLoggedOut}>
          <Route path="login" component={pageWrapper(LoginPage)} />
          <Route path="email-signup" component={pageWrapper(EmailSignupPage)} />
          <Route
            path="reset-password"
            component={pageWrapper(ResetPasswordPage)}
          />
          <Route
            path="set-new-password/:userId/:token"
            component={pageWrapper(SetNewPasswordPage)}
          />
        </Route> */}

        <Route component={EnsureLoggedIn}>
          <IndexRoute
            component={pageWrapper(IndexPage)}
            contentPadding={false}
          />
          <Route
            path="/home"
            component={pageWrapper(IndexPage)}
            contentPadding={false}
          />

          <Route path="logout" component={pageWrapper(LogoutPage)} />

          <Route path="friends" component={pageWrapper(FriendsPage)} />
          <Route
            path="filter-sources"
            component={pageWrapper(SourceFiltersPanel)}
          />
          <Route
            path="create-briefing"
            component={pageWrapper(CreateBriefingPage)}
            minimiseLeftNav={true}
          />
          <Route
            path="discover"
            component={pageWrapper(DiscoverPage)}
            minimiseLeftNav={true}
          />
          <Route path="browse" component={pageWrapper(BrowsePage)} />
          <Route
            path="briefing-edit/:id"
            component={pageWrapper(EditBriefingPage)}
            minimiseLeftNav={true}
          />
          <Route
            path="briefing-copy/:id"
            component={pageWrapper(CopyBriefingPage)}
          />
          <Route
            path="briefing-move/:id"
            component={pageWrapper(MoveBriefingPage)}
          />
          <Route
            path="briefing-delete/:id"
            component={pageWrapper(DeleteBriefingPage)}
          />
          <Route
            path="briefing-refresh/:id"
            component={pageWrapper(BriefingRefreshPage)}
          />
          <Route
            path="briefing-training/:id"
            component={pageWrapper(BriefingTrainingPage)}
          />
          <Route
            path="briefing-image/:id"
            component={pageWrapper(BriefingImagePage)}
          />
          <Route
            path="briefing-article-images/:id"
            component={pageWrapper(BriefingArticleImagesPage)}
          />
          <Route
            path="briefing-degreed-skills/:id"
            component={pageWrapper(BriefingDegreedSkillsPage)}
          />
          <Route path="briefings" component={pageWrapper(AllBriefingsPage)} />
          <Route path="saved-articles" component={pageWrapper(AllBoardsPage)} />
          <Route path="search" component={pageWrapper(SearchPage)} />
          <Route
            path="export-settings"
            component={pageWrapper(ApiSettingsPage)}
          />
          <Route
            path="team-settings"
            component={pageWrapper(TeamSettingsPage)}
          />
          <Route
            path="skills-tagging-demo/:teamId"
            component={pageWrapper(TeamSkillsTagPage)}
          />
          <Route
            path="team-stats/:teamId/user/:userId"
            component={pageWrapper(TeamUserStatsPage)}
          />
          <Route
            path="team-stats/:teamId"
            component={pageWrapper(TeamStatsPage)}
          />
          <Route
            path="team-api-stats/:teamId"
            component={pageWrapper(TeamApiStats)}
          />
          <Route
            path="team-api-stats/:teamId/briefing/:briefingId"
            component={pageWrapper(TeamApiStatsBriefing)}
          />
          <Route
            path="team-api-stats/:teamId/folder/:folderId"
            component={pageWrapper(TeamApiStatsFolder)}
          />
          <Route
            path="team-api-stats/:teamId/time"
            component={pageWrapper(TeamApiStatsTime)}
          />
          <Route
            path="team-api-stats/:teamId/domain/:domain"
            component={pageWrapper(TeamApiStatsDomain)}
          />

          <Route path="create-team" component={pageWrapper(CreateTeamPage)} />
          <Route path="profile" component={pageWrapper(ProfilePage)} />
          <Route
            path="profile/change-password"
            component={pageWrapper(ChangePasswordPage)}
          />
          <Route
            path="profile/slack"
            component={pageWrapper(ProfileSlackPage)}
          />
          <Route
            path="subscription"
            component={pageWrapper(SubscriptionPage)}
          />
          <Route
            path="setup-subscription/:type"
            component={pageWrapper(SetupSubscriptionPage)}
          />
          <Route
            path="setup-custom-subscription/:id"
            component={pageWrapper(SetupCustomSubscriptionPage)}
          />
          <Route
            path="create-organisation"
            component={pageWrapper(CreateOrganisationPage)}
          />
          <Route
            path="organisations"
            component={pageWrapper(AllOrganisationsPage)}
          />

          <Route
            path="account/:id"
            component={pageWrapper(AccountContainer)}
            minimiseLeftNav={true}
          >
            <IndexRoute component={AccountPage} />
            <Route
              path="setup-custom-subscription"
              component={SetupCustomAccountSubscriptionPage}
            />
            <Route
              path="update-subscription"
              component={UpdateAccountSubscriptionPage}
            />
            <Route path="team/:teamId" component={UpdateTeamPage} />
          </Route>

          <Route path="admin/" component={pageWrapper(AdminPage)}>
            <Route path="users" component={AdminUsersPage} />
            <Route path="users/:id" component={AdminUserPage} />
            <Route path="teams" component={AdminTeamsPage} />
            <Route path="teams/:id" component={AdminTeamPage} />
            <Route path="apiusers" component={AdminApiUsersPage} />
            <Route path="create-plan" component={AdminCreatePlanPage} />
            <Route path="crawler" component={AdminCrawlerPage} />
            <Route path="plans" component={AdminPlansPage} />
            <Route path="plans/:id" component={AdminPlanPage} />
            <Route path="accounts" component={AdminAccountsPage} />
            <Route path="accounts/:id" component={AdminAccountPage} />
            <Route path="create-account" component={AdminCreateAccountPage} />
            <Route path="copy-tools" component={AdminToolsPage} />
          </Route>
          <Route path="newsletters" component={pageWrapper(ListDigestPage)} />
          <Route
            path="newsletters/create"
            component={pageWrapper(NewDigestPage)}
          />
          <Route
            path="newsletters/:id"
            component={pageWrapper(EditDigestPage)}
          />
          <Route
            path="newsletters/:id/stats"
            component={pageWrapper(StatsDigestPage)}
          />
          <Route
            path="newsletters/:id/stats/:id"
            component={pageWrapper(IndividualStatsDigestPage)}
          />
        </Route>

        <Route path="*" component={pageWrapper(NotFoundPage)} />
      </Route>
    </Router>
  )
}
