exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CTA\\.module__cta___1XFyS {\n  margin: 130px auto;\n  max-width: 1200px;\n}\n\n.CTA\\.module__ctaHeader___3jhrI {\n  color: var(--ap-green);\n  font-size: 1.6rem;\n  font-weight: bold;\n  margin: 0 auto 40px;\n  max-width: 700px;\n}\n\n.CTA\\.module__ctaBody___1AYOE {\n  display: grid;\n  grid-template-columns: minmax(20px, 1fr) minmax(20px, 1fr);\n  grid-gap: 20px;\n}\n\n.CTA\\.module__ctaPic___1kgBR {\n  max-height: 350px;\n  max-width: 100%;\n}\n\n.CTA\\.module__ctaText___3c9qc {\n  margin: auto 100px auto 0;\n  text-align: left;\n}\n\n.CTA\\.module__ctaBullet___HkbPf {\n  color: var(--ap-pink);\n  font-weight: bold;\n}\n\n.CTA\\.module__ctaP1___3-GR7 {\n  color: #666;\n  font-size: 1.1rem;\n}\n\n.CTA\\.module__ctaP2___3USB3 {\n  color: var(--ap-green);\n  font-size: 1.1rem;\n  font-weight: bold;\n  margin-top: 40px;\n}\n\n.CTA\\.module__ctaBtn___1Rhre {\n  background: var(--ap-pink);\n  border: none;\n  border-radius: 5px;\n  color: white;\n  cursor: pointer;\n  font-size: 1rem;\n  padding: 10px;\n  -webkit-transition: 0.2s ease;\n  transition: 0.2s ease;\n}\n\n.CTA\\.module__ctaBtn___1Rhre:hover {\n  background-color: #b1012a;\n}\n\n@media screen and (max-width: 820px) {\n  .CTA\\.module__ctaBody___1AYOE {\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n  }\n\n  .CTA\\.module__ctaPic___1kgBR {\n    max-height: 300px;\n  }\n\n  .CTA\\.module__ctaText___3c9qc {\n    margin: 0 30px;\n    text-align: center;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"cta": "CTA.module__cta___1XFyS",
	"ctaHeader": "CTA.module__ctaHeader___3jhrI",
	"ctaBody": "CTA.module__ctaBody___1AYOE",
	"ctaPic": "CTA.module__ctaPic___1kgBR",
	"ctaText": "CTA.module__ctaText___3c9qc",
	"ctaBullet": "CTA.module__ctaBullet___HkbPf",
	"ctaP1": "CTA.module__ctaP1___3-GR7",
	"ctaP2": "CTA.module__ctaP2___3USB3",
	"ctaBtn": "CTA.module__ctaBtn___1Rhre"
};