import React from 'react'
import ReactModal from 'react-modal'
import styles from './FixedModal.css'

/*
const styles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(255, 255, 255, 0.75)'
    },
    content : {
        position                   : 'absolute',
        top                        : '40px',
        left                       : '40px',
        right                      : '40px',
        bottom                     : '40px',
        border                     : '0 none',
        background                 : '#fff',
        overflow                   : 'auto',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '4px',
        outline                    : 'none',
        padding                    : '0px'
    }
};
*/

export default class FixedModal extends React.Component {
  static defaultProps = {
    title: '',
    contentLabel: 'Modal',
    isOpen: false,
    onRequestClose: () => {},
    onSave: () => {},
    primaryButtonText: 'Save changes',
    closeButtonText: 'Close',
    centerStyleMaxWidth: 'auto',
    buttons: [],

    size: 'dynamic',
    width: '400px',
    styles: {},
  }
  render() {
    let modalStyles = {
      overlay: {
        zIndex: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '45px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        ...this.props.styles.overlay,
      },
      content: {
        maxHeight: '96%',
        maxWidth: '96%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        width: this.props.size === 'full' ? '96%' : this.props.width,
        height: this.props.size === 'full' ? '96%' : 'auto',

        // Cancel out the below..
        //position: 'static',
        right: 'initial',
        bottom: 'initial',
        border: '0 none',
        background: 'transparent',
        overflow: 'hidden',
        borderRadius: '0 none',
        outline: 'none',
        padding: '0px',
        ...this.props.styles.content,
      },
    }

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia

    if (isIE || isEdge) {
      modalStyles.content.height = this.props.size === 'full' ? '96%' : '1px'
      modalStyles.content.minHeight = '400px'
    }

    /*
        let modalStyles = {
            overlay: {
                zIndex: 3,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '45px',
                position          : 'fixed',
                top               : 0,
                left              : 0,
                right             : 0,
                bottom            : 0,
                overflow          : 'hidden',
            },
        };
        
            modalStyles.overlay = {
                ...modalStyles.overlay,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            };
            modalStyles.content = {
                width: this.props.width,
                maxHeight: '100%',
                display: 'flex',
                
                // Cancel out the below..
                position: 'static',
                top: 'initial',
                left: 'initial',
                right: 'initial',
                bottom: 'initial',
                border: '0 none',
                background: 'transparent',
                overflow: 'hidden',
                borderRadius: '0 none',
                outline: 'none', 
                padding: '0px',
            }
    
        if (!this.props.center) {
            modalStyles.content.height = '100%';
            modalStyles.content.width = '100%';
        } else {
            
                modalStyles.content.height = '75%';
                modalStyles.content.width = '75%';
                modalStyles.content.maxWidth = '400px';
                
        }*/

    return (
      <ReactModal
        style={modalStyles}
        contentLabel={this.props.contentLabel}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        appElement={document.getElementById('mount')}
      >
        <div className="modal-content" style={{ display: 'flex', flex: '1' }}>
          <div className={styles.modal}>
            {this.props.center && (
              <div className={styles.modalTopCenter}>{this.props.children}</div>
            )}
            {!this.props.center && (
              <div className={styles.modalTop}>{this.props.children}</div>
            )}

            {this.props.buttons.length > 0 && (
              <div className={styles.modalBottom}>
                {this.props.buttons.map((button, i) => (
                  <span className={styles.button} key={i}>
                    {button}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    )
  }
}
