import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import FixedModal from '../../app/components/FixedModal'

const KeyField = ({ label, className, ...rest }) => (
  <div className={className}>
    <label>{label}</label>
    <div>
      <input placeholder={label} className="form-control" {...rest} />
    </div>
  </div>
)

class D2lPanel extends Component {
  constructor() {
    super()
    this.state = { isModalOpen: false, hasNewKeys: false }
  }
  closeModal = () => {
    this.setState({ isModalOpen: false })
  }
  openModal = () => {
    this.setState({ isModalOpen: true })
  }
  handleGenerateKeys = () =>
    this.props.generateKeys({
      onDone: () => {
        this.closeModal()
        this.setState({ hasNewKeys: true })
      },
    })
  render() {
    const { keys, isUpserting } = this.props
    const { secret, clientId } = keys !== null ? keys : {}
    const hasKeys = secret && clientId
    const { hasNewKeys } = this.state
    const showKeysVisible = hasKeys && hasNewKeys
    const showKeysHidden = hasKeys && !hasNewKeys
    return (
      <>
        <div className="card mb-2">
          <div className="card-body">
            <h4 className="card-title">Integration: Brightspace (D2L)</h4>
            <p>Generate access keys for integrating content from Anders Pink</p>
            {!hasKeys ? (
              <div>
                {' '}
                <Button
                  onClick={this.handleGenerateKeys}
                  type="submit"
                  className="btn btn-primary"
                >
                  Create new keys
                </Button>
              </div>
            ) : null}
            {showKeysVisible ? (
              <>
                <Container className="m-0 p-0">
                  <Row>
                    <KeyField
                      name="clientId"
                      type="text"
                      className="col-md-6 mb-3"
                      label="LTI Key"
                      value={clientId}
                    />
                    <KeyField
                      name="secret"
                      type="text"
                      label="LTI Secret"
                      className="col-md-6"
                      value={secret}
                    />
                  </Row>
                  <Row className="mb-3">
                    <p className="col">
                      <strong>Note:</strong> Your LTI Secret key will only be
                      available now, although you can generate a new one
                      whenever you need. Please copy/paste both keys now and
                      keep them somewhere safe.
                    </p>
                  </Row>
                </Container>
                <Button
                  onClick={() => {
                    this.setState({ hasNewKeys: false })
                  }}
                  type="submit"
                  className="btn btn-primary"
                >
                  Done
                </Button>
              </>
            ) : null}

            {showKeysHidden ? (
              <>
                <div className="mb-3">
                  <div className="mb-2">
                    <strong>LTI Key</strong>: {clientId}
                  </div>
                  <div className="mb-2">
                    <strong>LTI Secret</strong>: {secret.replace(/./g, '*')}
                  </div>
                </div>
                <Button
                  onClick={this.openModal}
                  type="submit"
                  className="btn btn-primary"
                >
                  Create new keys
                </Button>
              </>
            ) : null}
          </div>
        </div>
        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          buttons={[
            <button
              className="btn btn-sm btn-primary"
              onClick={this.handleGenerateKeys}
              disabled={isUpserting}
            >
              Ok
            </button>,
            <button
              className="btn btn-sm btn-secondary"
              onClick={this.closeModal}
              disabled={isUpserting}
            >
              Cancel
            </button>,
          ]}
        >
          <div style={{ padding: '20px', minHeight: '100px' }}>
            <div>Are you sure you want to reset your access keys?</div>
          </div>
        </FixedModal>
      </>
    )
  }
}

export { D2lPanel }
