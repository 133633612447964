exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "body {\n  overflow-x: hidden;\n}\n\n.EnterpriseHomepage\\.module__outerBody___HJExq {\n  background-color: #e5e5e5;\n  overflow-x: hidden;\n}\n\n.EnterpriseHomepage\\.module__innerBody___c3SHV {\n  background-color: white;\n  margin: 0 auto;\n  /* max-width: 1640px; */\n}\n\n.EnterpriseHomepage\\.module__home___364yX {\n  font-family: 'Nunito', Arial, Helvetica, sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: center;\n  --ap-pink: #cf0032;\n  --ap-green: #006067;\n}\n\n.EnterpriseHomepage\\.module__background1___1hl0l {\n  /* background-image: url('./header-bg/green-curve.svg');\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: 100% 70%; */\n  background-color: #025f67;\n}\n.EnterpriseHomepage\\.module__background1Inner___359h- {\n  background-color: white;\n}\n", ""]);
// Exports
exports.locals = {
	"outerBody": "EnterpriseHomepage.module__outerBody___HJExq",
	"innerBody": "EnterpriseHomepage.module__innerBody___c3SHV",
	"home": "EnterpriseHomepage.module__home___364yX",
	"background1": "EnterpriseHomepage.module__background1___1hl0l",
	"background1Inner": "EnterpriseHomepage.module__background1Inner___359h-"
};