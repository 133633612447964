import React from 'react'
import apolloClient from 'apolloClient'
import gql from 'graphql-tag'

let loading = false
let loaded = false
let teamUsers = []
let callbacks = []

const onLoad = (callback) => {
  if (loaded) {
    // Call it immediately..
    setTimeout(callback, 1)
  } else {
    // Add to the list
    callbacks.push(callback)
  }

  if (!loading && !loaded) {
    // Start loading..
    loading = true

    apolloClient()
      .query({
        query: gql`
          query {
            session {
              team {
                id
                teamUsers {
                  user {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        teamUsers = result.data.session.team
          ? result.data.session.team.teamUsers
          : []
        loaded = true
        loading = false

        for (let callback of callbacks) {
          callback()
        }
      })
  }
}

export default function withTeamUsers(WrappedComponent) {
  class WithTeamUsers extends React.Component {
    constructor() {
      super()
      this.state = {
        teamUsers: [],
      }
      onLoad(() => {
        this.setState({
          teamUsers: teamUsers,
        })
      })
    }
    render() {
      return (
        <WrappedComponent {...this.props} teamUsers={this.state.teamUsers} />
      )
    }
  }

  return WithTeamUsers
}
