import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'
import moment from 'moment'
import AdminTable from 'admin/components/AdminTable'

const ITEMS_PER_PAGE = 200

@graphql(
  gql`
    query(
      $query: String
      $sort: String
      $sortOrder: String
      $limit: Int!
      $offset: Int!
    ) {
      apiUsers(
        query: $query
        sort: $sort
        sortOrder: $sortOrder
        limit: $limit
        offset: $offset
      ) {
        id
        apiKey
        briefingAllowance
        boardAllowance
        lastActiveAt
        isActive
        createdAt
        expiresAt
        user {
          id
          firstName
          lastName
        }
        team {
          id
          name
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          query: props.location.query.query || '',
          sort: props.location.query.sort || 'id',
          sortOrder: props.location.query.sortOrder || 'desc',
          limit: props.location.query.limit || ITEMS_PER_PAGE,
          offset: props.location.query.offset || 0,
        },
      }
    },
    props({ data: { apiUsers, fetchMore, ...rest } }) {
      return {
        data: {
          apiUsers,
          ...rest,
          loadMore() {
            return fetchMore({
              variables: {
                offset: apiUsers.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return Object.assign({}, previousResult, {
                  // Append the new feed results to the old one
                  users: [
                    ...previousResult.apiUsers,
                    ...fetchMoreResult.apiUsers,
                  ],
                })
              },
            })
          },
        },
      }
    },
  },
)
export default class AdminApiUsersPage extends React.Component {
  render() {
    return (
      <div>
        <AdminTable
          fields={{
            id: {
              label: 'ID',
            },
            apiKey: {
              label: 'Key',
            },
            briefingAllowance: {
              label: 'Num briefings',
            },
            boardAllowance: {
              label: 'Num boards',
            },
            isActive: {
              label: 'Is active',
              render: (isActive) => (isActive ? 'true' : 'false'),
            },
            createdAt: {
              label: 'Created',
            },
            expiresAt: {
              label: 'Expires',
            },
            lastActiveAt: {
              label: 'Last active',
              render: (lastActiveAt) => {
                if (!lastActiveAt) {
                  return null
                }
                return (
                  <span>
                    {lastActiveAt} ({moment(lastActiveAt).fromNow()})
                  </span>
                )
              },
            },
            user: {
              label: 'User',
              render: (user) => {
                if (!user) {
                  return null
                }
                return (
                  <span>
                    <Link to={`/admin/users/${user.id}`} className="link">
                      {user.firstName} {user.lastName}
                    </Link>
                  </span>
                )
              },
            },
            team: {
              label: 'Team',
              render: (team) => {
                if (!team) {
                  return null
                }
                return (
                  <span>
                    <Link to={`/admin/teams/${team.id}`} className="link">
                      {team.name}
                    </Link>
                  </span>
                )
              },
            },
          }}
          data={this.props.data.apiUsers || []}
          loading={this.props.data.loading}
          onSubmit={(values) => {
            browserHistory.push({
              pathname: '/admin/apiusers',
              query: {
                ...values,
              },
            })
          }}
          loadMore={() => {
            this.props.data.loadMore()
          }}
          initialValues={{
            ...this.props.location.query,
          }}
        />
      </div>
    )
  }
}
