import React, { Component } from 'react'
import styles from './Loader.css'

const CenterContainer = (props) => {
  return (
    <div className={styles.root} style={props.style || {}}>
      <div className={styles.circle + ' ' + styles.circle1}></div>
      <div className={styles.circle + ' ' + styles.circle2}></div>
      <div className={styles.circle + ' ' + styles.circle3}></div>
      <div className={styles.circle + ' ' + styles.circle4}></div>
      <div className={styles.circle + ' ' + styles.circle5}></div>
      <div className={styles.circle + ' ' + styles.circle6}></div>
      <div className={styles.circle + ' ' + styles.circle7}></div>
      <div className={styles.circle + ' ' + styles.circle8}></div>
    </div>
  )
}

export default CenterContainer
