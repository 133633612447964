exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "body {\n  overflow-x: hidden;\n}\n\n.CompleteCurationPage\\.module__outerBody___12NCF {\n  background-color: #e5e5e5;\n  overflow-x: hidden;\n}\n\n.CompleteCurationPage\\.module__innerBody___12R9Y {\n  background-color: white;\n  margin: 0 auto;\n  /* max-width: 1640px; */\n}\n\n.CompleteCurationPage\\.module__home___CyhBX {\n  font-family: 'Nunito', Arial, Helvetica, sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  /* text-align: center; */\n  --ap-pink: #cf0032;\n  --ap-green: #006067;\n}\n\n.CompleteCurationPage\\.module__markdown___2l8wz {\n  font-family: 'Nunito', Arial, Helvetica, sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  text-align: left;\n  --ap-pink: #cf0032;\n  --ap-green: #006067;\n}\n\n.CompleteCurationPage\\.module__background1___1se_S {\n  /* background-image: url('./header-bg/green-curve.svg');\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: 100% 70%; */\n  background-color: #006067;\n}\n\n.CompleteCurationPage\\.module__background1Inner___2aC5B {\n  background-color: white;\n  padding-bottom: 20px;\n}", ""]);
// Exports
exports.locals = {
	"outerBody": "CompleteCurationPage.module__outerBody___12NCF",
	"innerBody": "CompleteCurationPage.module__innerBody___12R9Y",
	"home": "CompleteCurationPage.module__home___CyhBX",
	"markdown": "CompleteCurationPage.module__markdown___2l8wz",
	"background1": "CompleteCurationPage.module__background1___1se_S",
	"background1Inner": "CompleteCurationPage.module__background1Inner___2aC5B"
};