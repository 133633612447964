import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import apolloClient from 'apolloClient'
import { browserHistory, Link } from 'react-router'
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form'
import { connect } from 'react-redux'

import FixedModal from 'app/components/FixedModal'
import AdminTable from 'admin/components/AdminTable'

import AsyncSelect from 'react-select/async'

const ITEMS_PER_PAGE = 50

@graphql(
  gql`
    query ($id: ID!) {
      account: adminAccount(id: $id) {
        id
        name
        canRssExport
        canCsvExport
        isDegreedAvailable
        isD2lAvailable
        useNewBriefingTypes
        type
        publicSignupLink
        ccEnabled
        articlesEnabled
        aiCurationsEnabled
        mfaEmailEnabled
        activeSubscriptions {
          id
          isStripe
          isTrial
          plans {
            id
            planSet {
              id
              name
            }
          }
        }
        teams {
          id
          name
        }
        users {
          id
          firstName
          lastName
          email
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation ($subscription: ManualSubscriptionInput!) {
      createAdminSubscription(subscription: $subscription)
    }
  `,
  {
    name: 'createAdminSubscription',
  },
)
@graphql(
  gql`
    mutation ($accountId: ID!, $name: String!) {
      createAdminTeam(accountId: $accountId, name: $name)
    }
  `,
  {
    name: 'createAdminTeam',
  },
)
@graphql(
  gql`
    mutation ($accountId: ID!, $userId: ID!) {
      adminAddUserToAccount(accountId: $accountId, userId: $userId)
    }
  `,
  {
    name: 'adminAddUserToAccount',
  },
)
@graphql(
  gql`
    mutation ($accountId: ID!, $userId: ID!) {
      adminRemoveUserFromAccount(accountId: $accountId, userId: $userId)
    }
  `,
  {
    name: 'adminRemoveUserFromAccount',
  },
)
@graphql(
  gql`
    mutation (
      $id: ID!
      $name: String
      $canRssExport: Boolean
      $canCsvExport: Boolean
      $isDegreedAvailable: Boolean
      $isD2lAvailable: Boolean
      $useNewBriefingTypes: Boolean
      $ccEnabled: Boolean
      $articlesEnabled: Boolean
      $aiCurationsEnabled: Boolean
      $mfaEmailEnabled: Boolean
    ) {
      adminUpdateAccount(
        id: $id
        name: $name
        canRssExport: $canRssExport
        canCsvExport: $canCsvExport
        isDegreedAvailable: $isDegreedAvailable
        isD2lAvailable: $isD2lAvailable
        useNewBriefingTypes: $useNewBriefingTypes
        ccEnabled: $ccEnabled
        articlesEnabled: $articlesEnabled
        aiCurationsEnabled: $aiCurationsEnabled
        mfaEmailEnabled: $mfaEmailEnabled
      )
    }
  `,
  {
    name: 'adminUpdateAccount',
  },
)
@connect()
export default class AdminAccountPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      modalType: null,
    }
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    const account = this.props.data.account

    let hasStripeSubscription = false
    if (account.activeSubscriptions) {
      for (let subscription of account.activeSubscriptions) {
        if (subscription.isStripe) {
          hasStripeSubscription = true
        }
      }
    }

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>Account</h5>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() =>
              this.setState({ isModalOpen: true, modalType: 'editAccount' })
            }
          >
            Edit account
          </button>
        </div>
        <div className="form-group row">
          <label className="col-2 ">ID</label>
          <div className="col-10">{account.id}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Type</label>
          <div className="col-10">{account.type}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Name</label>
          <div className="col-10">{account.name}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Can export briefings as RSS feeds</label>
          <div className="col-10">{account.canRssExport ? 'Yes' : 'No'}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Can export briefings as a CSV</label>
          <div className="col-10">{account.canCsvExport ? 'Yes' : 'No'}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Subscription</label>
          <div className="col-10">
            {account.activeSubscriptions.length > 0 && (
              <div>
                <div>
                  This account has{' '}
                  <strong>{account.activeSubscriptions.length}</strong>{' '}
                  subscription(s).
                </div>
                <br />
                {account.activeSubscriptions.map((subscription) => (
                  <div>
                    Has {subscription.isTrial ? 'trial' : ''}{' '}
                    {subscription.isStripe ? 'Stripe' : ''} subscription to:
                    <ul>
                      {subscription.plans
                        .filter((plan) => (plan.planSet ? true : false))
                        .map((plan) => (
                          <li key={plan.id}>
                            <Link
                              to={`/admin/plans/${plan.planSet.id}`}
                              className="link"
                            >
                              {plan.planSet.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                    {!subscription.isStripe &&
                      !hasStripeSubscription &&
                      account.activeSubscriptions.length === 1 && (
                        <div>
                          You can cancel this subscription and start a new one:
                          <button
                            className="btn btn-sm btn-secondary ml-2"
                            onClick={() =>
                              this.setState({
                                isModalOpen: true,
                                modalType: 'subscription',
                              })
                            }
                          >
                            Create new subscription
                          </button>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            )}
            {account.activeSubscriptions.length === 0 && (
              <div>
                You can create a subscription:
                <button
                  className="btn btn-sm btn-secondary ml-2"
                  onClick={() =>
                    this.setState({
                      isModalOpen: true,
                      modalType: 'subscription',
                    })
                  }
                >
                  Create new subscription
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Teams</label>
          <div className="col-10">
            {account.teams.map((team) => (
              <div key={team.id}>
                <Link to={`/admin/teams/${team.id}`} className="link">
                  {team.name}
                </Link>
              </div>
            ))}
            {account.teams.length === 0 && (
              <div>This account has no teams currently</div>
            )}

            <div className="mt-2">
              <button
                onClick={() => {
                  this.setState({
                    isModalOpen: true,
                    modalType: 'team',
                  })
                }}
                className={`btn btn-sm btn-secondary`}
              >
                Create new team
              </button>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Users (owners)</label>
          <div className="col-10">
            {account.users.map((user) => (
              <div key={user.id}>
                <Link to={`/admin/users/${user.id}`} className="link">
                  {user.firstName} {user.lastName} ({user.email})
                </Link>
                <i
                  className="fa fa-trash"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => {
                    let deleteUser = confirm(
                      `Are you sure you want to remove ${user.firstName} ${user.lastName} from ${account.name}?`,
                    )
                    if (deleteUser) {
                      this.props
                        .adminRemoveUserFromAccount({
                          variables: {
                            accountId: account.id,
                            userId: user.id,
                          },
                        })
                        .then(() => {
                          this.props.data.refetch()
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: `User succesfully removed from account`,
                          })
                        })
                        .catch((err) => {
                          let error = ''
                          if (
                            err.graphQLErrors &&
                            err.graphQLErrors.length > 0
                          ) {
                            error = err.graphQLErrors[0].message
                          } else if (err.networkError) {
                            error = err.networkError.message
                          } else {
                            error = err.message
                          }
                          this.props.dispatch({
                            type: 'ADD_NOTIFICATION',
                            content: error,
                          })
                        })
                    }
                  }}
                />
              </div>
            ))}

            <div className="mt-2">
              <button
                onClick={() => {
                  this.setState({
                    isModalOpen: true,
                    modalType: 'user',
                  })
                }}
                className={`btn btn-sm btn-secondary`}
              >
                Add user
              </button>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Public signup link</label>
          <div className="col-10">
            {account.publicSignupLink ? (
              <a href={account.publicSignupLink}>{account.publicSignupLink}</a>
            ) : null}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Complete Curation enabled</label>
          <div className="col-10">{account.ccEnabled ? 'Yes' : 'No'}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">
            Articles enabled in Complete Curation
          </label>
          <div className="col-10">{account.articlesEnabled ? 'Yes' : 'No'}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">
            AI curations enabled in Complete Curation
          </label>
          <div className="col-10">
            {account.aiCurationsEnabled ? 'Yes' : 'No'}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 ">Email multi-factor auth enabled</label>
          <div className="col-10">{account.mfaEmailEnabled ? 'Yes' : 'No'}</div>
        </div>
        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          <div style={{ padding: '20px' }}>
            {this.state.modalType === 'user' && (
              <AddUserForm
                onSubmit={(values) => {
                  return this.props
                    .adminAddUserToAccount({
                      variables: {
                        accountId: account.id,
                        userId: values.user.value,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Added new account owner`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}

            {this.state.modalType === 'team' && (
              <CreateTeamForm
                onSubmit={(values) => {
                  return this.props
                    .createAdminTeam({
                      variables: {
                        accountId: account.id,
                        ...values,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `New team succesfully created`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}

            {this.state.modalType === 'editAccount' && (
              <EditAccountForm
                initialValues={{
                  name: account.name,
                  canRssExport: account.canRssExport ? true : false,
                  canCsvExport: account.canCsvExport ? true : false,
                  isDegreedAvailable: account.isDegreedAvailable ? true : false,
                  isD2lAvailable: account.isD2lAvailable ? true : false,
                  useNewBriefingTypes: account.useNewBriefingTypes
                    ? true
                    : false,
                  ccEnabled: account.ccEnabled ? true : false,
                  articlesEnabled: account.articlesEnabled ? true : false,
                  aiCurationsEnabled: account.aiCurationsEnabled ? true : false,
                  mfaEmailEnabled: account.mfaEmailEnabled ? true : false,
                }}
                onSubmit={(values) => {
                  return this.props
                    .adminUpdateAccount({
                      variables: {
                        id: account.id,
                        ...values,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Account succesfully updated`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}

            {this.state.modalType === 'subscription' && (
              <CreateSubscriptionForm
                onSubmit={(values) => {
                  return this.props
                    .createAdminSubscription({
                      variables: {
                        subscription: {
                          accountId: account.id,
                          ...values,
                        },
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `New subscription succesfully created`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}
          </div>
        </FixedModal>
      </div>
    )
  }
}

const selector = formValueSelector('createSubscription')

@graphql(gql`
  query {
    planSets {
      id
      type
      name
      monthlyPlan {
        id
      }
      yearlyPlan {
        id
      }
    }
  }
`)
@reduxForm({
  form: 'createSubscription',
  initialValues: {
    interval: 'yearly',
    isTrial: false,
  },
})
@connect((state) => ({
  isTrial: selector(state, 'isTrial'),
}))
class CreateSubscriptionForm extends React.Component {
  render() {
    if (this.props.data.loading) {
      return null
    }

    const { onSubmit, error, handleSubmit, submitting } = this.props

    const typeLabels = { core: 'Core', api: 'API', extra_users: 'Extra users' }

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <div className="form-group">
          <label>Main plan</label>
          <Field
            name="appPlanSetId"
            component="select"
            className="form-control"
          >
            <option value="">Choose..</option>
            {this.props.data.planSets
              .filter((planSet) => planSet.type === 'app')
              .map((planSet) => (
                <option key={planSet.id} value={planSet.id}>
                  {planSet.name}
                </option>
              ))}
          </Field>
        </div>

        <div className="form-group">
          <label>API add-on (optional)</label>
          <Field
            name="apiPlanSetId"
            component="select"
            className="form-control"
          >
            <option value="">Choose..</option>
            {this.props.data.planSets
              .filter((planSet) => planSet.type === 'api')
              .map((planSet) => (
                <option key={planSet.id} value={planSet.id}>
                  {planSet.name}
                </option>
              ))}
          </Field>
        </div>

        <div className="form-group">
          <label>Extra team users add-on (optional)</label>
          <Field
            name="extraUserPlanSetId"
            component="select"
            className="form-control"
          >
            <option value="">Choose..</option>
            {this.props.data.planSets
              .filter((planSet) => planSet.type === 'extra_users')
              .map((planSet) => (
                <option key={planSet.id} value={planSet.id}>
                  {planSet.name}
                </option>
              ))}
          </Field>
        </div>

        <div className="form-group">
          <label>Billing cycle</label>
          <Field name="interval" component="select" className="form-control">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </Field>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="isTrial"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Is a trial subscription (i.e. not being paid for yet)
          </label>
        </div>

        {this.props.isTrial && (
          <div className="form-group">
            <label>Length of trial (in days)</label>
            <Field
              name="trialLength"
              component="input"
              type="text"
              className="form-control"
              placeholder="e.g. 14"
            />
          </div>
        )}

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Create subscription
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'createTeam',
  initialValues: {},
})
@connect()
class CreateTeamForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <div className="form-group">
          <label>Team name</label>
          <Field
            name="name"
            component="input"
            type="text"
            className="form-control"
          />
        </div>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Create team
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'addUserToTeam',
  initialValues: {},
})
@connect()
class AddUserForm extends React.Component {
  async getUsers(input) {
    if (!input) {
      return Promise.resolve([])
    }
    return new Promise((resolve) => {
      try {
        apolloClient()
          .query({
            query: gql`
              query ($query: String!, $types: [String]) {
                adminSearch(query: $query, types: $types) {
                  type
                  id
                  name
                }
              }
            `,
            variables: {
              query: input,
              types: ['user'],
            },
          })
          .then((result) => {
            resolve(
              result.data.adminSearch.map((result) => ({
                label: `${result.name} (${result.type} #${result.id})`,
                value: result.id,
              })),
            )
          })
      } catch (err) {
        console.log(err)
        resolve([])
      }
    })
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <p>
          Adding a user to this account will allow them to alter billing
          information and create teams. Note: this is different from a user in a
          team.
        </p>

        <div className="form-group">
          <label>User</label>

          <Field
            name="user"
            component={(props) => (
              <AsyncSelect
                valueKey="value"
                labelKey="label"
                value={props.input.value}
                onChange={(selectedOption) => {
                  props.input.onChange(selectedOption)
                }}
                loadOptions={this.getUsers}
              />
            )}
          />
        </div>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Add user
        </button>
      </form>
    )
  }
}

@reduxForm({
  form: 'editAccountForm',
  initialValues: {},
})
@connect()
class EditAccountForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props
    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <p>Update account</p>

        <div className="form-group">
          <label>Name</label>
          <Field
            name="name"
            component="input"
            type="text"
            className="form-control"
          />
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="canRssExport"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Can the user export briefings via RSS?
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="canCsvExport"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Can the user export briefings as a CSV?
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="isDegreedAvailable"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Is Degreed integration enabled?
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="isD2lAvailable"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Is D2l integration enabled?
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="useNewBriefingTypes"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Should default to new briefing types?
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="ccEnabled"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            CC enabled?
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="articlesEnabled"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            CC public articles enabled?
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="aiCurationsEnabled"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            CC AI curations enabled?
          </label>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="mfaEmailEnabled"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            MFA enabled?
          </label>
        </div>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Update
        </button>
      </form>
    )
  }
}
