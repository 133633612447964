import gql from 'graphql-tag'

// Save the fragment into a variable
const articleInfo = gql`
  fragment articleInfo on Article {
    id
    title
    content
    url
    image
    datePublished
    domain
    readingTime
    hasVideo
    hasPodcast
    upvotes {
      date
      user {
        id
        firstName
        lastName
        image
      }
    }
    saves {
      date
      boardId
      user {
        id
        firstName
        lastName
        image
      }
    }
    comments {
      id
      date
      text
      user {
        id
        firstName
        lastName
        image
      }
    }
    sharers {
      username
      name
      image
      postedAt
    }
  }
`
export default articleInfo
