import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

@reduxForm({
  form: 'teamUserInvite',
  initialValues: {
    level: 'user',
  },
})
export default class TeamUserInviteForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="form-inline">
          <Field
            name="email"
            style={{ width: '250px' }}
            component="input"
            type="text"
            className="form-control mr-2"
            placeholder="Email address"
          />

          <Field name="level" component="select" className="form-control mr-2">
            <option value="user">Normal user</option>
            <option value="admin">Admin</option>
          </Field>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={submitting}
          >
            Invite
          </button>
        </form>

        {error && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{ marginTop: '10px' }}
          >
            {error}
          </div>
        )}
      </div>
    )
  }
}
