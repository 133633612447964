import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import briefingInfoFragment from 'briefings/fragments/briefingInfo'

export default function withFollowMutations(WrappedComponent) {
  @graphql(
    gql`
      mutation($id: ID!) {
        followBriefingNew(id: $id) {
          ...briefingInfo
        }
      }
      ${briefingInfoFragment}
    `,
    {
      props: ({ mutate }) => ({
        followBriefing: (id) =>
          mutate({
            variables: { id },
            refetchQueries: ['appQuery'],
          }),
      }),
    },
  )
  @graphql(
    gql`
      mutation($id: ID!) {
        unFollowBriefingNew(id: $id)
      }
    `,
    {
      props: ({ mutate }) => ({
        unFollowBriefing: (id) =>
          mutate({
            variables: { id },
            refetchQueries: ['appQuery'],
          }),
      }),
    },
  )
  class WithFollowMutations extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return WithFollowMutations
}
