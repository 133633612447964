import React, { Component } from 'react'
import TwitterLoginButton from 'app/containers/TwitterLoginButton'

export default class TwitterSignupPanel extends Component {
  render() {
    return (
      <div>
        <p>Login with Twitter to save time.</p>

        <TwitterLoginButton />
      </div>
    )
  }
}
