exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FixedModal__closeButton___3pBHF {\n  position: absolute;\n  cursor: pointer;\n  top: -8px;\n  right: -8px;\n  background-color: #fff;\n  border: 1px solid #dedede;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-transition: background-color 0.25s linear;\n  transition: background-color 0.25s linear;\n}\n.FixedModal__closeButton___3pBHF:hover {\n  background-color: #f1f1f1;\n}\n\n.FixedModal__modal___23dCg {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  min-height: 0;\n}\n.FixedModal__modalTop___pbt3b {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  overflow: auto;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.FixedModal__modalTopCenter___2Hvr6 {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  overflow: scroll;\n}\n.FixedModal__modalBottom___2-fDK {\n  text-align: right;\n  border-top: 1px solid #ddd;\n  padding: 5px;\n}\n.FixedModal__button___3ftFr {\n  padding-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"closeButton": "FixedModal__closeButton___3pBHF",
	"modal": "FixedModal__modal___23dCg",
	"modalTop": "FixedModal__modalTop___pbt3b",
	"modalTopCenter": "FixedModal__modalTopCenter___2Hvr6",
	"modalBottom": "FixedModal__modalBottom___2-fDK",
	"button": "FixedModal__button___3ftFr"
};