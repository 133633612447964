import React from 'react'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import styles from './TeamSettingsPage.css'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import articleInfoFragment from 'articles/fragments/articleInfo'
import ArticleActivity from 'articles/containers/ArticleActivity'

const selector = formValueSelector('teamStats')

@compose(
  withProps((props) => ({
    initialValues: {
      time: '1-month',
    },
  })),
  reduxForm({
    form: 'teamStats',
  }),
  connect((state, props) => ({
    time: selector(state, 'time'),
  })),
  graphql(
    gql`
      query($id: ID, $userId: ID) {
        team(id: $id) {
          id
          name
          recentActivityArticles(limit: 20, userId: $userId) {
            ...articleInfo
          }
        }
      }
      ${articleInfoFragment}
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.params.teamId,
            userId: props.params.userId,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
export default class TeamUserStatsPage extends React.Component {
  render() {
    if (this.props.data.loading && !this.props.data.team) {
      return null
    }

    const team = this.props.data.team

    let articles = team.recentActivityArticles.slice(0)
    articles.sort((a, b) => {
      let latestDateA = null
      let latestDateB = null

      if (a.comments.length > 0) {
        latestDateA = a.comments[a.comments.length - 1].date
      } else if (a.upvotes.length > 0) {
        latestDateA = a.upvotes[a.upvotes.length - 1].date
      } else if (a.saves.length > 0) {
        latestDateA = a.saves[a.saves.length - 1].date
      }

      if (b.comments.length > 0) {
        latestDateB = b.comments[b.comments.length - 1].date
      } else if (b.upvotes.length > 0) {
        latestDateB = b.upvotes[b.upvotes.length - 1].date
      } else if (b.saves.length > 0) {
        latestDateB = b.saves[b.saves.length - 1].date
      }

      return latestDateA > latestDateB ? -1 : 1
    })

    return (
      <div>
        <h5>User history in team {team.name}</h5>

        {articles.length === 0 && <p>Nothing found recently for this user.</p>}

        {articles.map((article, index) => (
          <div key={index}>
            <ArticleActivity article={article} />
          </div>
        ))}
      </div>
    )
  }
}
