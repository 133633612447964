import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'

import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form'
import { connect } from 'react-redux'
import FixedModal from 'app/components/FixedModal'

@graphql(
  gql`
    query($id: ID!) {
      adminPlanSet(id: $id) {
        id
        type
        name
        description
        personalBriefingAllowance
        personalBoardAllowance
        apiType
        canCreateApiKeys
        totalNumBriefings
        totalNumBoards
        totalNumUsers
        totalNumApiBriefings
        totalNumApiBoards
        totalNumPublicBriefings
        totalNumPublicBoards
        canPubliclyExport
        monthlyPlan {
          id
          price
        }
        yearlyPlan {
          id
          price
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation(
      $id: ID!
      $canPubliclyExport: Boolean
      $canCreateApiKeys: Boolean
    ) {
      adminUpdatePlan(
        id: $id
        canPubliclyExport: $canPubliclyExport
        canCreateApiKeys: $canCreateApiKeys
      )
    }
  `,
  {
    name: 'adminUpdatePlan',
  },
)
@connect()
export default class AdminPlanPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
      modalType: null,
    }
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    const adminPlanSet = this.props.data.adminPlanSet

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>Plan</h5>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() =>
              this.setState({ isModalOpen: true, modalType: 'editPlan' })
            }
          >
            Edit plan
          </button>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">ID</label>
          <div className="col-10">{adminPlanSet.id}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Type</label>
          <div className="col-10">{adminPlanSet.type}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Name</label>
          <div className="col-10">{adminPlanSet.name}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Description</label>
          <div className="col-10">{adminPlanSet.description}</div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">Monthly price</label>
          <div className="col-10">${adminPlanSet.monthlyPlan.price}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Yearly price</label>
          <div className="col-10">${adminPlanSet.yearlyPlan.price}</div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">Total Num Users</label>
          <div className="col-10">{adminPlanSet.totalNumUsers}</div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">Total Num Briefings</label>
          <div className="col-10">{adminPlanSet.totalNumBriefings}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Total Num Boards</label>
          <div className="col-10">{adminPlanSet.totalNumBoards}</div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">Num personal briefings</label>
          <div className="col-10">{adminPlanSet.personalBriefingAllowance}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">Num personal boards</label>
          <div className="col-10">{adminPlanSet.personalBoardAllowance}</div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">API access</label>
          <div className="col-10">
            {adminPlanSet.apiType ? adminPlanSet.apiType : 'none'}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">
            Can create own API keys
          </label>
          <div className="col-10">
            {adminPlanSet.canCreateApiKeys ? 'Yes' : 'No'}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">
            Num briefings exportable via API
          </label>
          <div className="col-10">{adminPlanSet.totalNumApiBriefings}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">
            Num folders exportable via API
          </label>
          <div className="col-10">{adminPlanSet.totalNumApiBoards}</div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">
            Can publicly export briefings/folders
          </label>
          <div className="col-10">
            {adminPlanSet.canPubliclyExport ? 'Yes' : 'No'}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">
            Num briefings publicly exportable
          </label>
          <div className="col-10">{adminPlanSet.totalNumApiBriefings}</div>
        </div>
        <div className="form-group row">
          <label className="col-2 col-form-label">
            Num saved folders publicly API
          </label>
          <div className="col-10">{adminPlanSet.totalNumApiBoards}</div>
        </div>

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          <div style={{ padding: '20px' }}>
            {this.state.modalType === 'editPlan' && (
              <EditPlanForm
                initialValues={{
                  canCreateApiKeys: adminPlanSet.canCreateApiKeys
                    ? true
                    : false,
                  canPubliclyExport: adminPlanSet.canPubliclyExport
                    ? true
                    : false,
                }}
                onSubmit={(values) => {
                  console.log('submitted', values)

                  return this.props
                    .adminUpdatePlan({
                      variables: {
                        id: adminPlanSet.id,
                        ...values,
                      },
                    })
                    .then(() => {
                      this.props.data.refetch()
                      this.setState({
                        isModalOpen: false,
                      })
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Plan succesfully updated`,
                      })
                    })
                    .catch((err) => {
                      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                        throw new SubmissionError({
                          _error: err.graphQLErrors[0].message,
                        })
                      } else if (err.networkError) {
                        throw new SubmissionError({
                          _error: err.networkError.message,
                        })
                      } else {
                        throw new SubmissionError({
                          _error: err.message,
                        })
                      }
                    })
                }}
              />
            )}
          </div>
        </FixedModal>
      </div>
    )
  }
}

@reduxForm({
  form: 'editPlanForm',
})
@connect()
class EditPlanForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <h6>Update plan</h6>

        <div className="form-check mb-2">
          <label className="form-check-label">
            <Field
              name="canCreateApiKeys"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Can the account create their own API keys?
          </label>
        </div>

        <div className="form-check mb-2">
          <label className="form-check-label">
            <Field
              name="canPubliclyExport"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Can the account publicly export their briefings/folders?
          </label>
        </div>

        {(error ? true : false) && (
          <div className="alert alert-danger">{error}</div>
        )}

        <button
          type="submit"
          className={`btn btn-primary`}
          disabled={submitting}
        >
          Update
        </button>
      </form>
    )
  }
}
