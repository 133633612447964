exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TwitterSignupPage__root___1uO8J {\n  max-width: 900px;\n  margin: 0 auto;\n}\n.TwitterSignupPage__header___3cvDp {\n  margin-bottom: 30px;\n}\n\n.TwitterSignupPage__panels___1t-v3 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.TwitterSignupPage__left___2S6hF {\n  -webkit-box-flex: 3;\n      -ms-flex: 3;\n          flex: 3;\n}\n.TwitterSignupPage__right___rqN80 {\n  -webkit-box-flex: 2;\n      -ms-flex: 2;\n          flex: 2;\n  padding-left: 40px;\n  border-left: 1px solid #ddd;\n  margin-left: 40px;\n}\n.TwitterSignupPage__twitter___3KMO- {\n  margin-bottom: 13px;\n  padding: 10px;\n  background-color: #f8f8f8;\n  border: 1px solid #eee;\n}\n.TwitterSignupPage__image___3Qp0_ {\n  width: 30px;\n  height: 30px;\n  margin-right: 5px;\n  border-radius: 8%;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "TwitterSignupPage__root___1uO8J",
	"header": "TwitterSignupPage__header___3cvDp",
	"panels": "TwitterSignupPage__panels___1t-v3",
	"left": "TwitterSignupPage__left___2S6hF",
	"right": "TwitterSignupPage__right___rqN80",
	"twitter": "TwitterSignupPage__twitter___3KMO-",
	"image": "TwitterSignupPage__image___3Qp0_"
};