import React from 'react'
import styles from '../Components.module.css'

const Testimonials = ({ data }) => {
  return (
    <div style={{ justifyContent: 'center', padding: 20 }}>
      <img
        src={`${STATIC_DOMAIN}/pages/new-landingpage/quotes-up.png`}
        alt="quotes"
        style={{
          zIndex: 1,
          marginLeft: 15,
          width: 39,
          height: 31,
        }}
      />
      <div className={styles.testimonialCard}>
        <img
          src={data.logo}
          alt="logo"
          style={{
            objectFit: 'cover',
            // borderRadius: '100px',
            width: 150,
          }}
        />
        <div
          className={styles.testimonialContent}
          style={{ padding: '0px 20px 0px 20px' }}
        >
          <span style={{ fontSize: 24 }}>{data.company}</span>

          <p style={{ color: '#5E5E5E' }}>{data.content}</p>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
