'use strict'

module.exports = function humaniseList(list, options) {
  if (!Array.isArray(list)) {
    throw new TypeError('humanise-list expected an array')
  }

  options = options || {}
  options.conjunction = options.conjunction || 'and'

  if (options.oxfordComma === undefined) {
    options.oxfordComma = true
  }

  var listLength = list.length

  if (listLength === 1) {
    return list[0]
  }

  if (options.skipConjunction) {
    return list.join(', ')
  }

  var humanisedList = ''
  for (var i = 0; i < listLength; i++) {
    if (i === listLength - 1) {
      if (options.oxfordComma && listLength > 2) {
        humanisedList += ','
      }

      humanisedList += ' ' + options.conjunction + ' '
    } else if (i !== 0) {
      humanisedList += ', '
    }

    humanisedList += list[i]
  }

  return humanisedList
}
