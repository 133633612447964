import React from 'react'
import moment from 'moment'

const ListIndividualStatsItem = ({ item }) => {
  return (
    <>
      <tr>
        <th scope="row">{item.id}</th>
        <td>{item.email}</td>
        <td>{item.userId ? 'Yes' : 'No'}</td>
        <td>
          {item.sent
            ? moment(item.sent).format('dddd, Do MMMM YYYY, h:mm:ss a')
            : 'N/A'}
        </td>
        <td>
          {item.delivered
            ? moment(item.delivered).format('dddd, Do MMMM YYYY, h:mm:ss a')
            : 'N/A'}
        </td>
        <td>
          {item.opened
            ? moment(item.opened).format('dddd, Do MMMM YYYY, h:mm:ss a')
            : 'N/A'}
        </td>
        <td>
          {item.clicked
            ? moment(item.clicked).format('dddd, Do MMMM YYYY, h:mm:ss a')
            : 'N/A'}
        </td>
      </tr>
    </>
  )
}
export default ListIndividualStatsItem
