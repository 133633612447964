import React from 'react'
import { connect } from 'react-redux'

import EBookForm from '../new-homepage/components/EBookForm/EBookForm'

import styles from './EBookFormPage.module.css'

@connect((state) => ({
  app: state.app,
}))
export default class PlatformsHomepage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.main}>
        <EBookForm returnLink={true} />
      </div>
    )
  }
}
