import React from 'react'
import { Field, reduxForm, SubmissionError, isDirty } from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import gql from 'graphql-tag'

import moment from 'moment-timezone'
import FixedModal from 'app/components/FixedModal'

@reduxForm({
  form: 'account',
})
class ProfileForm extends React.Component {
  constructor() {
    super()
    this.state = {
      selectionOpen: false,
      openTeams: {},
    }
  }
  onClose = () => {
    this.setState({
      selectionOpen: false,
    })
  }
  render() {
    const timeZones = moment.tz.names()
    const { onSubmit, error, handleSubmit, submitting } = this.props

    const teams = this.props.teams.filter(
      (team) => team.ownedBriefings.length > 0,
    )

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>First name</label>
          <Field
            name="firstName"
            component="input"
            type="text"
            className="form-control"
            placeholder="First name"
          />
        </div>
        <div className="form-group">
          <label>Last name</label>
          <Field
            name="lastName"
            component="input"
            type="text"
            className="form-control"
            placeholder="Last name"
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <Field
            name="email"
            component="input"
            type="email"
            className="form-control"
            placeholder="Email"
          />
        </div>
        <div className="form-group">
          <label>Timezone</label>
          <Field name="timezone" component="select" className="form-control">
            {timeZones.map((timeZone) => (
              <option key={timeZone} value={timeZone}>
                {timeZone + ' (GMT' + moment.tz(timeZone).format('Z') + ')'}
              </option>
            ))}
          </Field>
        </div>
        <div className="form-check mb-2">
          <label className="form-check-label">
            <Field
              name="receiveDailyDigest"
              component="input"
              type="checkbox"
              className="form-check-input"
              normalize={(v) => {
                return !!v ? true : false
              }}
            />{' '}
            Receive daily digest email. This will send you links to articles
            based on the briefings you create or follow each day.{' '}
          </label>
          <span
            className="link mt-1"
            onClick={() =>
              this.setState({ selectionOpen: !this.state.selectionOpen })
            }
          >
            Configure your daily digest
          </span>

          {this.state.selectionOpen && (
            <div
              style={{
                padding: '1rem',
                border: '1px solid #e1e1e1',
                margin: '1rem 0',
              }}
            >
              <div
                style={{
                  paddingBottom: '0.5rem',
                }}
              >
                Select which briefings you want email updates from
              </div>

              <div
                style={{
                  borderLeft: '3px solid #eaeaea',
                  paddingLeft: '10px',
                }}
              >
                {teams.map((team) => (
                  <div key={team.id}>
                    <div
                      style={{
                        padding: '0.5rem 0',
                        cursor: 'pointer',
                        userSelect: 'none',
                      }}
                      onClick={() => {
                        const openTeams = this.state.openTeams
                        if (!this.state.openTeams[team.id]) {
                          openTeams[team.id] = true
                        } else {
                          delete openTeams[team.id]
                        }
                        this.setState({ openTeams })
                      }}
                    >
                      Team: {team.name}{' '}
                      <i
                        className={
                          !this.state.openTeams[team.id]
                            ? 'fa fa-angle-right'
                            : 'fa fa-angle-down'
                        }
                      />
                    </div>

                    {!!this.state.openTeams[team.id] && (
                      <div style={{ paddingBottom: '1rem' }}>
                        {team.ownedBriefings.map((briefing) => (
                          <div key={briefing.id}>
                            <div className="form-check">
                              <label className="form-check-label">
                                <Field
                                  name={`briefings_${briefing.id}`}
                                  component="input"
                                  type="checkbox"
                                  className="form-check-input"
                                />{' '}
                                {briefing.name}{' '}
                                {!!briefing.description ? (
                                  <span>
                                    -{' '}
                                    <span style={{ color: '#666' }}>
                                      {briefing.description}
                                    </span>
                                  </span>
                                ) : null}
                              </label>
                              {'  '}
                              <a
                                href={briefing.url}
                                className="link"
                                target="_blank"
                              >
                                View
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="form-group mt-3">
                <label>Choose a time for your digest</label>
                <Field
                  name="digestTime"
                  component="select"
                  className="form-control"
                >
                  {Array.from(Array(48).keys()).map((number) => {
                    const time =
                      String(Math.floor(number / 2)).padStart(2, '0') +
                      `:${number % 2 === 0 ? 0 : 3}0`
                    return (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    )
                  })}
                </Field>
              </div>
            </div>
          )}
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <Field
              name="receiveNewsletters"
              component="input"
              type="checkbox"
              className="form-check-input"
              normalize={(v) => {
                return !!v ? true : false
              }}
            />{' '}
            Receive occasional updates from us on our products, services and
            offers.
          </label>
        </div>
        <br />
        <p style={{ color: '#AAAAAA' }}>
          We will never sell your data to third parties. For more information,
          see our{' '}
          <a target="_blank" href="/privacy-policy">
            privacy policy
          </a>
          .
        </p>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <button className="btn btn-primary">Update</button>{' '}
        <button
          className="btn btn-secondary"
          onClick={() => {
            window.location.href = '/home'
          }}
        >
          Cancel
        </button>
      </form>
    )
  }
}

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
  jwt: state.app.jwt,
}))
@graphql(
  gql`
    mutation ($user: UserInput!) {
      updateUser(user: $user) {
        id
        firstName
        lastName
        email
        receiveDailyDigest
        receiveNewsletters
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUser: (user) =>
        mutate({
          variables: {
            user,
          },
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
@graphql(gql`
  query {
    csrf(type: "updateUser")
    session {
      user {
        id
        teams {
          id
          name
          ownedBriefings {
            id
            name
            description
            url
          }
        }
      }
    }
  }
`)
export default class ProfilePage extends React.Component {
  constructor() {
    super()
    this.state = {
      updated: false,
    }
  }
  onSubmit = (values) => {
    const ids = []
    for (let field in values) {
      if (field.indexOf('briefings_') === 0 && values[field] === true) {
        const parts = field.split('_')
        ids.push(parts[1])
      }
    }

    return this.props
      .updateUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        receiveDailyDigest: values.receiveDailyDigest,
        receiveNewsletters: values.receiveNewsletters,
        timezone: values.timezone,
        digestTime: values.digestTime,

        briefingIds: ids,
        csrfToken: this.props.data.csrf,
      })
      .then((response) => {
        this.setState({
          updated: true,
        })
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }
  render() {
    if (this.props.data.loading) {
      return null
    }

    // let briefing
    const teams = this.props.data.session.user.teams

    return (
      <div>
        <h3>Profile</h3>

        <div style={{ maxWidth: '600px' }}>
          <ProfileForm
            onSubmit={this.onSubmit}
            initialValues={{
              firstName: this.props.appQueryResult.user.firstName,
              lastName: this.props.appQueryResult.user.lastName,
              email: this.props.appQueryResult.user.email,
              receiveDailyDigest:
                this.props.appQueryResult.user.receiveDailyDigest,
              timezone: this.props.appQueryResult.user.timezone,
              digestTime: this.props.appQueryResult.user.digestTime,
              receiveNewsletters:
                this.props.appQueryResult.user.receiveNewsletters,
              ...this.props.appQueryResult.user.digestBriefingIds.reduce(
                (c, id) => {
                  c['briefings_' + id] = true
                  return c
                },
                {},
              ),
            }}
            teams={teams}
          />

          {this.state.updated && (
            <div
              style={{ marginTop: '10px' }}
              className="alert alert-success"
              role="alert"
            >
              Acccount updated
            </div>
          )}
        </div>

        <h5 className="mt-5">Change your password</h5>
        <p>
          To change your password, please{' '}
          <Link to="/profile/change-password">use this form</Link>.
        </p>

        <h5 className="mt-5">Delete your account</h5>
        <p>
          If you wish to delete your account or change any other information,
          please <Link to="/contact">contact us</Link>.
        </p>
      </div>
    )
  }
}
