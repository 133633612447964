import React, { useState } from 'react'
import { Link } from 'react-router'

import styles from './EBookBanner.module.css'

const ebookCookie = document.cookie
  .split(';')
  .map((cookie) => cookie.split('='))
  .reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key.trim()]: decodeURIComponent(value),
    }),
    {},
  ).ebookDismissed

const EBookBanner = (props) => {
  const [ebookDismissed, setEbookDismissed] = useState(ebookCookie)

  if (ebookDismissed) {
    return <></>
  }

  let bannerClass, btnClass

  if (props.bannerColor === 'green') {
    bannerClass = styles.bannerGreen
    btnClass = styles.downloadBtnGreen
  } else if (props.bannerColor === 'pink') {
    bannerClass = styles.bannerPink
    btnClass = styles.downloadBtnPink
  } else if (props.bannerColor === 'white') {
    bannerClass = styles.bannerWhite
    btnClass = styles.downloadBtnWhite
  }

  return (
    <div className={bannerClass}>
      Free eBook: Curating Content for Learning: 5 Simple Steps
      {props.externalLink ? (
        <Link to="/download-ebook">
          <button className={btnClass}>Download now</button>
        </Link>
      ) : (
        <a href="#ebook-form">
          <button className={btnClass}>Download now</button>
        </a>
      )}
      <i
        className="fa fa-close"
        style={{ marginLeft: '5px', cursor: 'pointer' }}
        onClick={() => {
          let date = new Date()
          date.setDate(date.getDate() + 3)
          document.cookie = `ebookDismissed=true; expires= ${date}`
          setEbookDismissed(true)
        }}
      />
    </div>
  )
}

export default EBookBanner
