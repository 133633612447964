import React, { useState, useEffect, useRef } from 'react'
import apolloClient from 'apolloClient'
import gql from 'graphql-tag'
import styles from './MSTeamsDemoPage.css'

import ArticleListFullPage from 'articles/components/ArticleList'
import Article from 'articles/containers/Article'
import articleInfoFragment from 'articles/fragments/articleInfo'
import Loader2 from 'app/components/Loader2'

const defaultTime = '1-month'
let ITEMS_PER_PAGE = 20

const TeamsEmbedPage = (props) => {
  const [time, setTime] = useState(defaultTime)

  useEffect(() => {
    if (props.params.time) setTime(props.params.time)
  }, [props])

  return (
    <TeamsEmbedInner
      {...props}
      time={time}
      onTimeChange={(time) => setTime(time)}
    />
  )
}

export default TeamsEmbedPage

const TeamsEmbedInner = (props) => {
  const [offset, setOffset] = useState(ITEMS_PER_PAGE)
  const [loading, setLoading] = useState(true)
  const [publicTeam, setPublicTeam] = useState(null)
  const [activeBriefingId, setActiveBriefingId] = useState(null)
  const [articles, setArticles] = useState([])
  const [moreAvailable, setMoreAvailable] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchArticles, setSearchArticles] = useState([])

  const searchRef = useRef(null)

  useEffect(() => {
    const time = props.time
    loadArticles(time, 0)
  }, [props, activeBriefingId])

  const loadArticles = (time, offset) => {
    setOffset(offset)
    apolloClient()
      .query({
        query: gql`
          query (
            $id: ID!
            $secret: String!
            $time: String!
            $limit: Int
            $offset: Int
          ) {
            publicTeam(id: $id, secret: $secret) {
              id
              name
              briefings {
                id
                name
                image
                articles(
                  time: $time
                  limit: $limit
                  offset: $offset
                  tag: "tracked"
                ) {
                  ...articleInfo
                }
              }
            }
          }
          ${articleInfoFragment}
        `,
        variables: {
          id: props.params.id,
          secret: props.params.secret,
          time: time,
          limit: ITEMS_PER_PAGE,
          offset: offset,
        },
      })
      .then((result) => {
        setLoading(result.data.loading)
        setPublicTeam(result.data.publicTeam)

        const newActiveBriefing = activeBriefingId
          ? result.data.publicTeam.briefings.find(
              (b) => b.id === activeBriefingId,
            )
          : result.data.publicTeam.briefings[0]

        if (!activeBriefingId) {
          // First load.. simple
          setActiveBriefingId(newActiveBriefing.id)
          setArticles(newActiveBriefing.articles)
        } else {
          if (offset > 0) {
            // trying to load more
            setArticles([...articles, ...newActiveBriefing.articles])
          } else {
            setActiveBriefingId(newActiveBriefing.id)
            setArticles(newActiveBriefing.articles)
          }
        }

        setMoreAvailable(newActiveBriefing.articles.length === ITEMS_PER_PAGE)
      })
  }

  const queryArticles = (query) => {
    setSearchQuery(query)
    setSearchLoading(query.length > 0)

    if (query.length > 0) {
      apolloClient()
        .query({
          query: gql`
            query briefingFilterQuery($id: ID!, $query: String) {
              briefing(id: $id) {
                id
                filteredArticles(query: $query) {
                  ...articleInfo
                }
              }
            }
            ${articleInfoFragment}
          `,
          variables: {
            id: activeBriefingId,
            query: query,
          },
        })
        .then((result) => {
          setSearchQuery(query)
          setSearchLoading(false)
          setSearchArticles(result.data.briefing.filteredArticles)
        })
    }
  }

  const handleSearchClick = () => {
    setSearchOpen((prevState) => !prevState)
    setSearchQuery('')

    if (!searchOpen) {
      setTimeout(() => {
        searchRef.current.focus()
      }, 50)
    } else {
      setSearchArticles([])
    }
  }

  let overrideColor = null
  if (props.location.query) {
    if (props.location.query.primaryColour) {
      overrideColor = props.location.query.primaryColour
    } else if (props.location.query.primaryColor) {
      overrideColor = props.location.query.primaryColor
    }
  }

  if (loading && !publicTeam) {
    return <span>Loading...</span>
  }

  if (!publicTeam) {
    return (
      <span>
        Sorry, something went wrong when trying to display this team. Please
        contact us at team@anderspink.com.
      </span>
    )
  }

  if (!publicTeam.briefings || publicTeam.briefings.length === 0) {
    return <span>Error: none of your briefings are set to be public</span>
  }

  const briefings = publicTeam.briefings

  const time = props.time

  const timeOptions = [
    { value: '24-hours', label: '24 hours' },
    { value: '3-days', label: '3 days' },
    { value: '1-week', label: '1 week' },
    { value: '1-month', label: '1 month' },
    { value: '3-months', label: '3 months' },
  ]

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        {briefings.map((briefing) => (
          <div
            key={briefing.id}
            className={`${styles.briefingCover} ${
              activeBriefingId === briefing.id
                ? styles.briefingActive
                : styles.briefing
            }`}
            style={{
              backgroundImage: `url(${briefing.image})`,
              borderColor: overrideColor ? '#' + overrideColor : undefined,
            }}
            onClick={() => {
              setActiveBriefingId(briefing.id)
            }}
          >
            {/* <Link to={briefing.url} className={styles.inner}> */}
            <div className={styles.inner}>
              <div className={styles.text}>
                <div className={styles.textInner}>
                  <div className={styles.name}>{briefing.name}</div>
                </div>
              </div>
            </div>
            {/* </Link> */}
          </div>
        ))}
      </div>

      {/* <div
            key={briefing.id}
            onClick={() => {
              setActiveBriefingId(briefing.id)
            }}
            className={`${styles.briefing} ${styles.briefingCover} ${
              activeBriefingId === briefing.id ? styles.briefingActive : ''
            }`}
            style={
              !!overrideColor && activeBriefingId === briefing.id
                ? {
                    backgroundColor: `#${overrideColor}`,
                    backgroundImage: `url(${briefing.image})`,
                  }
                : { backgroundImage: `url(${briefing.image})` }
            }
          >
            {briefing.name}
          </div> */}

      <div className={styles.right}>
        <div className={styles.topBar}>
          <span
            data-tip="Search for content within a briefing"
            className={styles.articleSearch}
            style={{
              alignSelf: 'center',
              marginRight: !searchOpen ? '1rem' : '0rem',
            }}
            onClick={handleSearchClick}
          >
            <i className={searchOpen ? 'fa fa-times' : 'fa fa-search'} />
          </span>
          <div
            style={{
              display: searchOpen ? 'block' : 'none',
              marginRight: '1rem',
              marginLeft: '1rem',
              alignSelf: 'center',
            }}
          >
            <input
              type="text"
              ref={searchRef}
              className="form-control mr-1"
              placeholder="Search for articles"
              value={searchQuery}
              onChange={(e) => {
                queryArticles(e.target.value)
              }}
            />
          </div>
          <div
            className="btn-group"
            role="group"
            data-tip="Change your view to see content from different time periods"
          >
            {timeOptions.map((timeOption) => (
              <button
                key={timeOption.value}
                type="button"
                className={`btn btn-sm btn-${
                  time === timeOption.value ? 'primary' : 'secondary'
                }`}
                onClick={() => {
                  props.onTimeChange(timeOption.value)
                }}
                style={
                  !!overrideColor && time === timeOption.value
                    ? {
                        backgroundColor: `#${overrideColor}`,
                        borderColor: `#${overrideColor}`,
                        boxShadow: 'none',
                      }
                    : {}
                }
              >
                {timeOption.label}
              </button>
            ))}
          </div>
        </div>

        {!searchOpen && articles && articles.length === 0 && (
          <div>Sorry, no articles were found</div>
        )}

        {searchOpen && searchQuery.length > 0 && (
          <div>
            {searchLoading && (
              <div>
                <Loader2 text="Searching for articles" />
              </div>
            )}
            {!searchLoading &&
              searchQuery.length > 0 &&
              searchArticles.length === 0 && (
                <div>Sorry, no articles were found</div>
              )}
            {!searchLoading &&
              searchQuery.length > 0 &&
              searchArticles.length > 0 && (
                <div>
                  <ArticleListFullPage>
                    {searchArticles.map((article, index) => (
                      <Article
                        key={article.id}
                        article={article}
                        showActions={false}
                        showReadTime={true}
                      />
                    ))}
                  </ArticleListFullPage>
                </div>
              )}
          </div>
        )}

        {!(searchOpen && searchQuery.length > 0) &&
          articles &&
          articles.length > 0 && (
            <>
              <ArticleListFullPage>
                {articles.map((article, index) => (
                  <Article
                    key={index}
                    article={article}
                    showActions={false}
                    showReadTime={true}
                  />
                ))}
              </ArticleListFullPage>
              {moreAvailable && (
                <div style={{ marginTop: 20 }}>
                  <button
                    className="btn btn-primary"
                    onClick={() => loadArticles(time, offset + ITEMS_PER_PAGE)}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
      </div>
    </div>
  )
}
