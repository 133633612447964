import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import briefingInfoFragment from 'briefings/fragments/briefingInfo'
import EditBriefingForm from 'briefings/create/containers/EditBriefingForm'
import { browserHistory } from 'react-router'

@graphql(
  gql`
    query ($id: ID!) {
      briefing(id: $id) {
        id
        name
        url
        description
        isPublic
        isFeatured
        categoryId
        type
        contentTypes
        linkedBriefingId
        position
        sourceFields {
          sources {
            type
            value
          }
          keywords
          includedWords
          excludedWords
          blockedDomains
          whitelistDomains
          trainings {
            uuid
            label
          }
        }
        keywordFields {
          keywords
          includedWords
          excludedWords
          domains
          blockedDomains
          useBusinessDomains
          categories
          trainings {
            uuid
            label
          }
        }
        looseFields {
          query
          excludedWords
          domains
          blockedDomains
          categories
        }
        keywordsV2Fields {
          topics
          excludedWords
          filterVideo
          categories
          domainsAllowBriefing
          domainsBlockBriefing
          domainListAllowIds
          domainListBlockIds
          trainings {
            uuid
            label
          }
        }
        sourcesV2Fields {
          sources {
            type
            value
          }
          keywords
          blockedDomains
          whitelistDomains
          trainings {
            uuid
            label
          }
        }
        language
        influencerFilter
        domainFilter
        rssFilter
        createdVia
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
)
@graphql(
  gql`
    mutation ($briefing: BriefingInput!) {
      updateBriefing(briefing: $briefing) {
        ...briefingInfo
      }
    }
    ${briefingInfoFragment}
  `,
  {
    name: 'updateBriefing',
  },
)
@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
}))
export default class EditBriefingPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.data.loading) {
      return false
    }

    const briefing = this.props.data.briefing
    if (!briefing.type) {
      return (
        <div>
          <span>Sorry, you don't have permission to edit this briefing.</span>
        </div>
      )
    }

    if (!!briefing.linkedBriefingId) {
      return (
        <div>
          <span>
            Sorry, this briefing is linked to a public briefing so it cannot be
            edited.
          </span>
        </div>
      )
    }

    const session = this.props.appQueryResult

    const useNewBriefingTypes = true

    let keywordFields = {}
    let sourceFields = {}
    let looseFields = {}
    let keywordsV2Fields = {}
    let sourcesV2Fields = {}
    let temporaryTrainings = []

    if (briefing.type === 'keywords') {
      let { __typename, ...otherKeywordFields } = briefing.keywordFields
      keywordFields = otherKeywordFields
      keywordFields.trainings = (keywordFields.trainings || []).map((t) => {
        const { __typename, ...otherFields } = t
        return otherFields
      })
    } else if (briefing.type === 'sources') {
      let { __typename, ...otherSourceFields } = briefing.sourceFields
      sourceFields = otherSourceFields
      sourceFields.sources = sourceFields.sources.map((source) => {
        let { __typename, ...otherFields } = source
        return otherFields
      })
      sourceFields.trainings = (sourceFields.trainings || []).map((t) => {
        const { __typename, ...otherFields } = t
        return otherFields
      })
    } else if (briefing.type === 'loose') {
      let { __typename, ...otherLooseFields } = briefing.looseFields
      looseFields = otherLooseFields
    } else if (briefing.type === 'keywordsV2') {
      let { __typename, ...otherKeywordsV2Fields } = briefing.keywordsV2Fields
      keywordsV2Fields = otherKeywordsV2Fields

      keywordsV2Fields.trainings = keywordsV2Fields.trainings.map((t) => {
        const { __typename, ...otherFields } = t
        return otherFields
      })
      temporaryTrainings = keywordsV2Fields.trainings.map((t) => {
        const { __typename, ...otherFields } = t
        return otherFields
      })
    } else if (briefing.type === 'sourcesV2') {
      let { __typename, ...otherSourceFields } = briefing.sourcesV2Fields
      sourcesV2Fields = otherSourceFields
      sourcesV2Fields.sources = sourcesV2Fields.sources.map((source) => {
        let { __typename, ...otherFields } = source
        return otherFields
      })
      sourcesV2Fields.trainings = (sourcesV2Fields.trainings || []).map((t) => {
        const { __typename, ...otherFields } = t
        return otherFields
      })
      temporaryTrainings = sourcesV2Fields.trainings.map((t) => {
        const { __typename, ...otherFields } = t
        return otherFields
      })
    }

    let initialValues = {
      name: briefing.name,
      description: briefing.description,
      isPublic: briefing.isPublic,
      isFeatured: briefing.isFeatured,
      categoryId: briefing.categoryId,
      type: briefing.type,
      contentTypes: briefing.contentTypes,
      keywordFields: keywordFields,
      sourceFields: sourceFields,
      looseFields: looseFields,
      keywordsV2Fields: keywordsV2Fields,
      sourcesV2Fields: sourcesV2Fields,
      language: briefing.language,
      influencerFilter: briefing.influencerFilter,
      domainFilter: briefing.domainFilter,
      rssFilter: briefing.rssFilter,
      temporaryTrainings,
    }

    if (briefing.type === 'loose') {
      initialValues.tempQuery = looseFields.query
    }
    if (briefing.type === 'keywordsV2') {
      const {
        domainsAllowBriefing = [],
        domainsBlockBriefing = [],
        domainListAllowIds = [],
        domainListBlockIds = [],
      } = keywordsV2Fields
      const hasAllowLists =
        domainsAllowBriefing.length > 0 || domainListAllowIds.length > 0
      const hasBlockLists =
        domainsBlockBriefing.length > 0 || domainListBlockIds.length > 0
      if (hasAllowLists && hasBlockLists) {
        throw Error('Briefing has both AllowLists and BlockLists set')
      }
      initialValues.domainListType = hasAllowLists ? 'allow' : 'block'
    }

    return (
      <div>
        <EditBriefingForm
          crawlerSearchEnabled={
            useNewBriefingTypes ||
            briefing.type === 'keywordsV2' ||
            briefing.type === 'sourcesV2'
          }
          canUseLooseSearch={
            session && session.team && session.team.canUseLooseSearch
          }
          creating={false}
          onSubmit={(values) => {
            return this.props
              .updateBriefing({
                variables: {
                  briefing: {
                    id: briefing.id,
                    name: values.name,
                    description: values.description,
                    isPublic: values.isPublic,
                    isFeatured: values.isFeatured,
                    categoryId: values.categoryId,
                    type: values.type,
                    contentTypes: values.contentTypes,
                    sourceFields: values.sourceFields,
                    keywordFields: values.keywordFields,
                    looseFields: values.looseFields,
                    keywordsV2Fields: values.keywordsV2Fields,
                    sourcesV2Fields: values.sourcesV2Fields,
                    language: values.language,
                    influencerFilter: values.influencerFilter || false,
                    domainFilter: values.domainFilter || false,
                    rssFilter: values.rssFilter || false,
                  },
                },
              })
              .then((response) => {
                if (
                  response.data.updateBriefing &&
                  response.data.updateBriefing.id
                ) {
                  browserHistory.push(briefing.url)
                } else {
                  console.log('briefing update error', response)
                  throw new SubmissionError({
                    _error: 'Sorry, something went wrong',
                  })
                }
              })
          }}
          initialValues={initialValues}
          viaApi={briefing.createdVia === 'api'}
        />
      </div>
    )
  }
}
