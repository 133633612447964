import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'
import Loader from 'app/components/Loader'

@graphql(
  gql`
    query($id: ID!) {
      briefing(id: $id) {
        id
        name
        url
        isAwaitingRefresh
        isRefreshing
        lastRefreshDate
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.params.id,
        },
      }
    },
  },
)
export default class BriefingRefreshPage extends React.Component {
  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.props.data.refetch()
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data.briefing &&
      nextProps.data.briefing.lastRefreshDate !== null &&
      !nextProps.data.briefing.isRefreshing &&
      !nextProps.data.briefing.isAwaitingRefresh
    ) {
      // redirect...
      //browserHistory.push(`/briefing/${nextProps.data.briefing.id}`);
      window.location.href = nextProps.data.briefing.url
    }
  }
  render() {
    return (
      <div
        className="alert alert-info"
        role="alert"
        style={{
          maxWidth: '400px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div>
          This briefing is currently refreshing to bring in the latest articles.
          Please wait as this may take up to a few minutes...
        </div>

        <div style={{ marginTop: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }
}
