import React from 'react'
import {
  reset,
  stopSubmit,
  Field,
  reduxForm,
  SubmissionError,
  isDirty,
} from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import NewPasswordInput from 'authentication/containers/NewPasswordInput'

@reduxForm({
  form: 'changePassword',
})
class ChangePasswordForm extends React.Component {
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Current password</label>
          <Field
            name="password"
            component="input"
            type="password"
            className="form-control"
            placeholder=""
          />
        </div>
        <div className="form-group">
          <label>New password</label>
          <NewPasswordInput name="newPassword" placeholder="" />
          <span className="form-text text-muted">
            Passwords must be at least 8 characters long, and be as unguessable
            as possible.
          </span>
        </div>
        <div className="form-group">
          <label>Repeat new password</label>
          <NewPasswordInput name="newPassword2" placeholder="" />
        </div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <button className="btn btn-primary">Update</button>{' '}
        <button
          className="btn btn-secondary"
          onClick={() => {
            browserHistory.push('/profile')
          }}
        >
          Cancel
        </button>
      </form>
    )
  }
}

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
  jwt: state.app.jwt,
}))
@graphql(
  gql`
    mutation(
      $password: String!
      $newPassword: String!
      $newPassword2: String!
      $csrfToken: String!
    ) {
      updateUserPassword(
        password: $password
        newPassword: $newPassword
        newPassword2: $newPassword2
        csrfToken: $csrfToken
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUserPassword: (values) =>
        mutate({
          variables: {
            ...values,
          },
        }),
    }),
  },
)
@graphql(gql`
  query {
    csrf(type: "updateUserPassword")
  }
`)
export default class ChangePasswordPage extends React.Component {
  constructor() {
    super()
    this.state = {
      updated: false,
    }
  }
  onSubmit = (values) => {
    this.props.dispatch(stopSubmit('changePassword', {}))

    return this.props
      .updateUserPassword({
        ...values,
        csrfToken: this.props.data.csrf,
      })
      .then((response) => {
        browserHistory.push('/profile')
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `Your password has been successfully updated`,
        })
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }
  render() {
    return (
      <div>
        <h3>Change password</h3>

        <div style={{ maxWidth: '600px' }}>
          <ChangePasswordForm
            onSubmit={this.onSubmit}
            initialValues={{
              password: '',
              newPassword: '',
              newPassword2: '',
            }}
          />

          {this.state.updated && (
            <div
              style={{ marginTop: '10px' }}
              className="alert alert-success"
              role="alert"
            >
              Acccount updated
            </div>
          )}
        </div>
      </div>
    )
  }
}
