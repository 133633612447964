import React, { Component, Fragment } from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Badge, Button, Modal, ProgressBar } from 'react-bootstrap'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'

import styles from '../EditBriefingForm.css'
import ToolTip from 'app/components/ToolTip'
import WordList from 'briefings/create/containers/WordList'
import Loader2 from 'app/components/Loader2'
import AddSourceModal from 'briefings/create/containers/AddSourceModal'
import sourceTypes, { renderSourceString } from 'briefings/config/sourceTypes'
import humaniseList from 'app/library/humaniseList'

import styles2 from './CrawlerBriefingForm.css'

import TopicInput from './TopicInput'
import SourcesFilter from './SourcesFilter'
import Training from './Training'
import { FORM_NAMES, DOMAIN_LIST_TYPES } from './../constants'
import ContentTypes from './ContentTypes'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

const languages = [
  {
    code: 'en',
    name: 'English',
    cats: [
      {
        label: 'General business (top 6k business, tech, news and more)',
        value: 'business',
      },
      { label: 'Sales', value: 'sales' },
      { label: 'Leadership', value: 'leadership' },
      { label: 'HR and Learning', value: 'hr-and-learning' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Technology', value: 'technology' },
    ],
  },
  { code: 'fr', name: 'French', cats: [] },
  { code: 'it', name: 'Italian', cats: [] },
  { code: 'de', name: 'German', cats: [] },
  { code: 'pt', name: 'Portuguese', cats: [] },
  { code: 'es', name: 'Spanish', cats: [] },
  { code: 'sv', name: 'Swedish', cats: [] },
  { code: 'ar', name: 'Arabic', cats: [] },
]

@connect((state, props) => ({
  ...selector(
    state,
    'type',
    'keywordsV2Fields.topics',
    'keywordsV2Fields.training',
    'sourcesV2Fields.sources',
    'sourcesV2Fields.training',
    'language',
  ),
}))
export default class CrawlerBriefingForm extends Component {
  constructor() {
    super()
    this.state = {
      isSelectingLanguage: false,
      lastTrainingApplied: [],
      isAddSourceModalOpen: false,
      showTrainingWarning: false,
    }
  }
  render() {
    const lang = languages.find((lang) => lang.code === this.props.language)
    const { type } = this.props

    const renderTopicWarning = (message) => (
      <div style={{ color: 'red', fontWeight: 'bold' }}>{message}</div>
    )
    const topicCount = this.props.keywordsV2Fields.topics.length
    const sourceCount = this.props.sourcesV2Fields.sources.length
    const sourceDomains = this.props.sourcesV2Fields.sources.filter(
      (s) => s.type === 'domain',
    )
    const sourceRSSCount = this.props.sourcesV2Fields.sources.filter(
      (s) => s.type === 'rssFeed',
    ).length
    const sourceTopicCount = this.props.sourcesV2Fields.sources.filter(
      (s) => s.type === 'topic',
    ).length

    return (
      <div style={{ padding: '20px' }}>
        <div id="briefing-type" className="mb-5">
          <div className={styles2.heading}>Briefing type</div>

          <div>
            <div className={styles.options}>
              <div
                className={[
                  styles.option,
                  type === 'keywordsV2' ? styles.optionChecked : '',
                ].join(' ')}
                onClick={() => {
                  this.props.change('type', 'keywordsV2')

                  // reset training
                  this.props.change('temporaryTrainings', [
                    ...(this.props.keywordsV2Fields.training || []),
                  ])
                }}
              >
                <div
                  className={[
                    styles.radioButton,
                    type === 'keywordsV2' ? styles.radioButtonChecked : '',
                  ].join(' ')}
                />

                <div className={styles.optionContent}>
                  <div>Simple</div>
                  <div className={styles.optionDesc}>
                    Choose your topics and find articles, then filter down
                    further
                  </div>
                </div>
              </div>

              <div
                className={[
                  styles.option,
                  type === 'sourcesV2' ? styles.optionChecked : '',
                ].join(' ')}
                onClick={() => {
                  this.props.change('type', 'sourcesV2')

                  // reset training
                  this.props.change('temporaryTrainings', [
                    ...(this.props.sourcesV2Fields.training || []),
                  ])
                }}
              >
                <div
                  className={[
                    styles.radioButton,
                    type === 'sourcesV2' ? styles.radioButtonChecked : '',
                  ].join(' ')}
                />

                <div className={styles.optionContent}>
                  <div>Advanced</div>
                  <div className={styles.optionDesc}>
                    Get all articles from the sources you select (sites, RSS
                    feeds, topics etc), then filter down
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {type === 'keywordsV2' && (
          <div id="briefing-topics" className="mb-5">
            <div className={styles2.heading}>Topics</div>
            <div>
              <div className="mb-3">
                <span>
                  Enter the topic you’re interested in, e.g. ‘leadership’ or
                  ‘machine learning’, or choose from our list of popular topics.
                </span>{' '}
                <ToolTip>
                  <p>
                    A topic can be any word or phrase. For example "Fintech
                    trends" or "AI".
                  </p>
                  <p>
                    For very broad topics such as AI you can add an additional
                    term to curate content on a specific topic within AI e.g.
                    "AI in HR" or AI Recruitment".
                  </p>

                  <p>
                    If you want to curate content that combines multiple topics,
                    e.g. leadership communication, we recommend that you add the
                    topics as one topic e.g. "leadership communication".
                  </p>

                  <p>
                    You can also add synonyms and alternative phrases, e.g.
                    "communication skills for leaders" and "leadership
                    communication skills"
                  </p>

                  <p>
                    Tip: We recommend you stick to one key subject area to keep
                    your briefing focused.
                  </p>
                </ToolTip>
              </div>
              <div className={styles2.topicsCointainer}>
                {this.props.keywordsV2Fields.topics.length > 0 && (
                  <p
                    className={styles2.copyTopics}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        this.props.keywordsV2Fields.topics.join(', '),
                      )
                      this.props.dispatch({
                        type: 'ADD_NOTIFICATION',
                        content: `Topics copied to clipboard`,
                      })
                    }}
                  >
                    Copy topics
                  </p>
                )}
                <TopicInput
                  topics={this.props.keywordsV2Fields.topics}
                  setTopics={(topics) =>
                    topics.length <= 7
                      ? this.props.change('keywordsV2Fields.topics', topics)
                      : null
                  }
                />
              </div>
              {topicCount > 5
                ? topicCount < 7
                  ? renderTopicWarning('We recommend 5 topics or fewer')
                  : renderTopicWarning('Topic limit reached')
                : undefined}
              <div className={styles2.languageContainer}>
                <label>
                  Show articles written in language:{' '}
                  {!this.state.isSelectingLanguage ? (
                    <span
                      className={styles.language}
                      onClick={() =>
                        this.setState({ isSelectingLanguage: true })
                      }
                    >
                      {lang.name}
                    </span>
                  ) : null}
                </label>
                <ToolTip>
                  <p>
                    Change languages to see content published from quality sites
                    in your target language.
                  </p>
                  <p>
                    Tip: You can enter words from any language in the keyword
                    box, and any site from any country/TLD in the sites step.
                    Keep your briefing to one language to keep it focused.
                  </p>
                </ToolTip>
                {this.state.isSelectingLanguage && (
                  <Field
                    name="language"
                    component="select"
                    className="form-control"
                    onChange={() => {
                      this.setState({ isSelectingLanguage: false })
                    }}
                  >
                    {languages.map((lang) => (
                      <option key={lang.code} value={lang.code}>
                        {lang.name}
                      </option>
                    ))}
                  </Field>
                )}
              </div>
            </div>
          </div>
        )}

        {type === 'sourcesV2' && (
          <div id="briefing-sources" className="mb-5">
            <div className={styles2.heading}>Sources</div>

            <div>
              {this.props.sourcesV2Fields.sources.length === 0 && (
                <div className={styles.sourcesEmpty2}>
                  No sources added yet. Add a site, RSS feed or topic.
                </div>
              )}

              {this.props.sourcesV2Fields.sources.map((source, index) => (
                <div key={index} className={styles.source}>
                  <div className={styles.sourceLeft}>
                    <div className={styles.sourceType}>
                      {renderSourceString(source.type)}
                    </div>
                    <div className={styles.sourceValue} title={source.value}>
                      {source.value}
                    </div>
                  </div>
                  <div
                    className={styles.sourceDelete}
                    onClick={() =>
                      this.props.array.remove('sourcesV2Fields.sources', index)
                    }
                  >
                    <i className="fa fa-times" aria-hidden="true" />
                  </div>
                </div>
              ))}

              <button
                className="btn btn-block btn-secondary"
                onClick={() => this.setState({ isAddSourceModalOpen: true })}
              >
                Add source
              </button>
            </div>
          </div>
        )}

        {this.props.isSiteAdmin && type === 'keywordsV2' && topicCount > 0 && (
          <div id="briefing-training" className="mb-5">
            <div className={styles2.heading}>Content types</div>
            <ContentTypes change={this.props.change} />
          </div>
        )}

        {((type === 'keywordsV2' && topicCount > 0) ||
          (type === 'sourcesV2' && sourceCount > 0)) && (
          <div id="briefing-training" className="mb-5">
            <div className={styles2.heading}>
              Training <span className={styles2.faded}>(optional)</span>
            </div>
            <Training
              change={this.props.change}
              onShowTrainingWarning={() => {
                this.setState({ showTrainingWarning: true })
              }}
            />
          </div>
        )}

        {type === 'keywordsV2' && topicCount > 0 && (
          <div id="briefing-sites" className="mb-5">
            <div className={styles2.heading}>
              Sites <span className={styles2.faded}>(optional)</span>
            </div>
            <SourcesFilter change={this.props.change} />
          </div>
        )}

        {type === 'sourcesV2' && sourceCount > 0 && (
          <div id="briefing-keywords" className="mb-5">
            <div className={styles2.heading}>
              Keywords <span className={styles2.faded}>(optional)</span>
            </div>
            <p>
              Filter articles by some keywords that must appear in the title or
              body of the article
            </p>
            <WordList
              field="sourcesV2Fields.keywords"
              newField="keywordsNew"
              change={this.props.change}
            />
          </div>
        )}

        {type === 'sourcesV2' &&
          sourceDomains.length > 0 &&
          sourceCount > sourceDomains.length && (
            <div id="briefing-filter-to-sites" className="mb-5">
              <div className={styles2.heading}>
                Filter sources <span className={styles2.faded}>(optional)</span>
              </div>
              <p>
                Results from your{' '}
                {sourceRSSCount === 0
                  ? 'topics'
                  : sourceTopicCount > 0
                  ? 'topics/RSS feeds'
                  : 'RSS feeds'}{' '}
                can be filtered to just the sites you've added.
              </p>
              <p>
                <label>
                  <Field
                    name="sourcesV2Fields.whitelistDomains"
                    component="input"
                    type="checkbox"
                    normalize={(v) => {
                      return !!v ? true : false
                    }}
                  />{' '}
                  Only show content from the specificed source sites (
                  {humaniseList(
                    sourceDomains.slice(0, 3).map((source) => source.value),
                  )}
                  {sourceDomains.length > 3 ? '...' : ''})
                </label>
              </p>
            </div>
          )}

        {((type === 'keywordsV2' && topicCount > 0) ||
          (type === 'sourcesV2' && sourceCount > 0)) &&
          !this.props.creating && (
            <div id="briefing-name" className="mb-5">
              <div className={styles2.heading}>Name</div>
              <div className="form-group">
                <label>Briefing name</label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  Description <span className={styles2.faded}>(optional)</span>
                </label>
                <Field
                  name="description"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          )}

        <AddSourceModal
          isOpen={this.state.isAddSourceModalOpen}
          onRequestClose={() => this.setState({ isAddSourceModalOpen: false })}
          change={this.props.change}
          array={this.props.array}
          onAddSource={(source) => {
            this.props.array.push('sourcesV2Fields.sources', source)
          }}
          hideTwitter={true}
        />

        <Modal
          show={this.state.showTrainingWarning}
          onHide={() => this.setState({ showTrainingWarning: false })}
          centered
        >
          <Modal.Body>
            You have added an allow/block list to a briefing which has had some
            training. You may wish to clear your training and start the training
            again for best results.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showTrainingWarning: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
