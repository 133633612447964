import React from 'react'
import styles from './Sharer.css'
import moment from 'moment'

export default class Sharer extends React.Component {
  render() {
    const { sharer } = this.props
    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <img className={styles.image} src={sharer.image} />
        </div>
        <div className={styles.right}>
          <div>
            <strong>{sharer.name}</strong> {sharer.text}
          </div>
          <div className={styles.time}>{moment(sharer.postedAt).fromNow()}</div>
        </div>
      </div>
    )
  }
}
