import React, { Component } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import styles from './EditBriefingForm.css'
import ToolTip from 'app/components/ToolTip'
import FixedModal from 'app/components/FixedModal'
import { connect } from 'react-redux'
import PreviewBriefing from 'briefings/create/containers/PreviewBriefing'
import merge from 'lodash/merge'
import TourContainer from './types/TourContainer'

import { Form } from 'react-bootstrap'

import CrawlerBriefingForm from './types/CrawlerBriefingForm'
import OldBriefingTypes from './OldBriefingTypes'

import { FORM_NAMES } from './constants'
import { hasPendingTrainings } from './types/Training'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state, props) => ({
  // Merge our incoming intial values with the defauls
  initialValues: merge(
    {
      type: props.crawlerSearchEnabled ? 'keywordsV2' : 'keywords', // keywords or sources
      name: '',
      isPublic: false,
      categoryId: null,
      isFeatured: false,
      category: null,
      contentTypes: ['articles', 'videos'],
      keywordFields: {
        keywords: [],
        domains: [],
        includedWords: [],
        excludedWords: [],
        blockedDomains: [],
        useBusinessDomains: true,
        categories: [],
      },
      sourceFields: {
        sources: [],
        keywords: [],
        includedWords: [],
        excludedWords: [],
        blockedDomains: [],
        whitelistDomains: false,
      },
      looseFields: {
        query: '',
        domains: [],
        excludedWords: [],
        blockedDomains: [],
      },
      keywordsV2Fields: {
        topics: [],
        filterVideo: false,
        excludedWords: [],
        categories: [],
        trainings: [],
        domainsAllowBriefing: [],
        domainsBlockBriefing: [],
        domainListAllowIds: [],
        domainListBlockIds: [],
      },
      sourcesV2Fields: {
        sources: [],
        keywords: [],
        trainings: [],
        blockedDomains: [],
        whitelistDomains: false, // @todo do we want to offer this still?
      },
      language: 'en',
      influencerFilter: false,
      domainFilter: false,
      rssFilter: false,
      temporaryTrainings: [],
      domainListType: 'block',
    },
    props.initialValues,
  ),
}))
@reduxForm({
  form: FORM_NAMES.EDIT_BRIEFING,
})
@connect((state, props) => ({
  isSiteAdmin: state.app.appQueryResult.user.isSiteAdmin,
  ...selector(
    state,
    'type',
    'keywordsV2Fields.trainings',
    'sourcesV2Fields.trainings',
    'temporaryTrainings',
  ),
  domainListType: selector(state, 'domainListType'),
}))
export default class EditBriefingForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
    blockedArticles: [],
    creating: true,
    viaApi: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      isSaveModalOpen: false,
      isSaveTrainingModalOpen: false,
      superMode:
        props.type === 'keywordsV2' ||
        props.type === 'sourcesV2' ||
        (props.creating && props.crawlerSearchEnabled),
    }
    if (props.creating && props.crawlerSearchEnabled) {
      props.change('type', 'keywordsV2')
    }
  }

  handleSave = (onSaveCb) => {
    if (this.props.creating) {
      this.setState({
        isSaveModalOpen: true,
      })
    } else {
      onSaveCb && onSaveCb()
    }
  }
  applyTemporaryTrainingAndSave = (onSaveCb) => {
    debugger
    this.props.change(
      'keywordsV2Fields.trainings',
      this.props.temporaryTrainings,
    )
    this.setState({ isSaveTrainingModalOpen: false })
    setTimeout(() => this.handleSave(onSaveCb), 1)
  }
  clearTemporaryTrainingAndSave = (onSaveCb) => {
    this.props.change('temporaryTrainings', [])
    this.setState({ isSaveTrainingModalOpen: false })
    this.handleSave(onSaveCb)
  }

  render() {
    const {
      onSubmit,
      error,
      handleSubmit,
      crawlerSearchEnabled = false,
    } = this.props

    const havePendingTrainings = ['keywordsV2', 'sourcesV2'].includes(
      this.props.type,
    )
      ? hasPendingTrainings(
          this.props.type === 'keywordsV2'
            ? this.props.keywordsV2Fields.trainings
            : this.props.sourcesV2Fields.trainings,
          this.props.temporaryTrainings,
        )
      : false

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.form}>
            <div className={styles.top}>
              <div className={styles.title}>
                <span className="mr-1">
                  {this.props.creating ? 'Create briefing' : 'Update briefing'}
                </span>
                {this.props.creating &&
                  !['keywordsV2', 'sourcesV2'].includes(this.props.type) && (
                    <ToolTip>
                      A briefing is a collection of articles from the web,
                      filtered by your preferred topics, contexts and sites,
                      updating every day with fresh content. We’ll take you
                      through 4 quick steps to create one. You can change it any
                      time.
                    </ToolTip>
                  )}
                {['keywordsV2', 'sourcesV2'].includes(this.props.type) && (
                  <TourContainer isCreating={this.props.creating} />
                )}
              </div>

              <div className="d-flex align-items-center">
                {crawlerSearchEnabled && (
                  <div className="mr-4">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="New briefing types"
                      checked={this.state.superMode}
                      onChange={(e) => {
                        this.setState({ superMode: e.target.checked })

                        if (e.target.checked) {
                          this.props.change('type', 'keywordsV2')
                        } else {
                          this.props.change('type', 'keywords')
                        }
                      }}
                    />
                  </div>
                )}

                <button
                  id="briefing-save"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    if (havePendingTrainings) {
                      this.setState({ isSaveTrainingModalOpen: true })
                    } else {
                      this.handleSave(handleSubmit(onSubmit))
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>

            {(error ? true : false) && (
              <div className={styles.oldBriefingTypes}>
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}
            {['keywordsV2', 'sourcesV2'].includes(this.props.type) && (
              <CrawlerBriefingForm
                creating={this.props.creating}
                change={this.props.change}
                array={this.props.array}
                isSiteAdmin={this.props.isSiteAdmin}
              />
            )}

            {['keywords', 'sources', 'loose'].includes(this.props.type) && (
              <OldBriefingTypes
                creating={this.props.creating}
                change={this.props.change}
                array={this.props.array}
              />
            )}
          </div>

          <div id="briefing-preview" className={styles.preview}>
            <PreviewBriefing change={this.props.change} />
          </div>
        </div>
        <FixedModal
          width="500px"
          isOpen={this.state.isSaveTrainingModalOpen}
          onRequestClose={() =>
            this.setState({ isSaveTrainingModalOpen: false })
          }
        >
          <div style={{ padding: '20px' }}>
            <div
              style={{
                fontSize: '1.4em',
                marginBottom: '20px',
              }}
            >
              Save training?
            </div>
            <div>
              You have unsaved changes to the training of this briefing. Would
              you like to save it before continuing?
            </div>
            <div className="mt-4">
              <button
                className="btn btn-primary btn-sm mr-3"
                onClick={() =>
                  this.applyTemporaryTrainingAndSave(handleSubmit(onSubmit))
                }
              >
                Keep changes
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() =>
                  this.clearTemporaryTrainingAndSave(handleSubmit(onSubmit))
                }
              >
                Discard changes
              </button>
            </div>
          </div>
        </FixedModal>
        <FixedModal
          width="500px"
          isOpen={this.state.isSaveModalOpen}
          onRequestClose={() => this.setState({ isSaveModalOpen: false })}
        >
          <div style={{ padding: '20px' }}>
            <div
              style={{
                fontSize: '1.4em',
                marginBottom: '20px',
              }}
            >
              Finish your briefing off by choosing a name
            </div>
            <div className="form-group">
              <label>
                <strong>Briefing name</strong>
              </label>
              <Field
                name="name"
                component="input"
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>
                <strong>Description (optional)</strong>
              </label>
              <Field
                name="description"
                component="input"
                type="text"
                className="form-control"
              />
            </div>

            {(error ? true : false) && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleSubmit(onSubmit)()
              }}
            >
              Save
            </button>
          </div>
        </FixedModal>
      </div>
    )
  }
}
