import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'

import ReactMarkdown from 'react-markdown'

const integrations = [
  {
    id: 'yammer',
    image: `${STATIC_DOMAIN}/pages/landing/y-logo.png`,
    name: 'Yammer',
    title: 'Get daily insights in your Yammer channels and teams',
    topContent: `
Yammer is a great tool to help teams collaborate and stay up to date. You can make it even better by bringing in fresh content every day on the topics you care about, with our Yammer Integration for Anders Pink.
        `,
    bottomContent: `
Here's how it works:

1. Create an account on [Anders Pink](/contact), or [login](/login).
2. Create a new briefing on any topic, or follow some public ones. Briefings are collections of content from around the web, filtered by your preferred topics and sources. E.g. "Bring me content on AI, but only if it mentions HR Or Talent Management, and only from these 10 sites." [Here’s how](https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be).
3. Enable RSS feeds on your Anders Pink account. This turns any briefing or saved folder in your account into an RSS feed, which enables you to put it in a wide range of sites and sources.  This is a paid feature - [get in touch](/contact) for pricing, which is based on audience size and number of briefings. 
4. Use the [Zapier](https://zapier.com/apps/yammer/integrations/rss/2917/post-rss-feed-updates-to-yammer) integration to embed and update RSS feeds in Yammer. 
5. Drop the RSS feed into the Zapier integration and connect it to your Yammer account.

That’s it! You’ll start getting updates in your chosen Yammer channel. You and your team can stay up to date on any topic, from any sources - you’re in complete control. And once it’s in Yammer you can comment, share, interact with it like any other message:

![AndersPink and Yammer](${STATIC_DOMAIN}/pages/plugins/yammer1.png "AndersPink and Yammer")

Want to try out RSS feeds from Anders Pink in Yammer? Get in touch for a free trial. 

Want to put content from Anders Pink into your other workflow tools, like your LMS, Learning Platform, Slack, SharePoint - or anywhere else? See all our other [integration options](/integrations) here - or [get in touch](/contact) and we’ll make it happen... 
        `,
  },
  {
    id: 'sharepoint',
    image: `${STATIC_DOMAIN}/pages/landing/sharepoint-logo.png`,
    name: 'Sharepoint',
    title:
      'Bring recent and relevant content on any topic, directly into your SharePoint',
    topContent: `
Microsoft SharePoint is all about collaboration, keeping teams connected and up to speed. Here’s a way you can make it even better:

1. What if you could bring in the most recent and relevant content on the topics your teams care about from around the web - filtered whatever way you want?
2. What if you could put that content anywhere in SharePoint?
3. What if it that content was updated every few hours with the latest insights, and anyone can access it - seamlessly?

That's what our SharePoint integration brings you.

Curation just got a lot easier with the Anders Pink SharePoint integration.
        `,
    bottomContent: `
Here’s how it works:

![AndersPink and Sharepoint](${STATIC_DOMAIN}/pages/plugins/sharepoint_option1.png "AndersPink and Sharepoint Option 1")

![AndersPink and Sharepoint](${STATIC_DOMAIN}/pages/plugins/sharepoint_option2.png "AndersPink and Sharepoint Option 2")

1. Create an account on [Anders Pink](/contact), or [login](/login).
2. Create a new briefing on any topic, or follow some public ones. Briefings are collections of content from around the web, filtered by your preferred topics and sources. E.g. "Bring me content on AI, but only if it mentions HR Or Talent Management, and only from these 10 sites." [Here’s how](https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be).
3. Get in touch for our integration code (Javascript embed code). This is a paid feature.
4. Use the simple code to embed a live briefing anywhere on your SharePoint site.
5. That’s it. Display your briefings in SharePoint, keep your teams up to speed on any topic. So for example:
    - Display trending leadership content from the top 20 sites for your leadership teams
    - Bring trending content about your competitors into a feed for your sales teams
    - Share updates on your home page about your industry, to keep everyone up to date

Embed great content, seamlessly in SharePoint. Just [get in touch](/contact) and we can set you up with a free integration trial.
        `,
  },
  {
    id: 'slack',
    image: `${STATIC_DOMAIN}/pages/landing/slack-logo.png`,
    name: 'Slack',
    title: 'Get daily insights in your Slack channels and teams',
    topContent: `
Slack is changing how teams collaborate and work smarter. Over 6 million people use it every day to streamline and simplify their workflow. 

We think Slack is also a great place for learning and collaboration. Here’s why we think Slack is a stealth learning tool.
    
To make it even better, we wanted to make it easy to stay up to date in Slack with the latest articles on the trends and topics you and your teams care about.
That's what our Anders Pink Slack integration does.


![AndersPink and Slack](${STATIC_DOMAIN}/pages/plugins/slack1.png "AndersPink and Slack")
        `,
    bottomContent: `
Why integrate with Slack?

Get your daily intelligence briefing where you're already doing your work - in Slack. With our Slack integration you'll get a daily briefing of the latest articles on any topic you want in in any Slack channel, with fresh content every day.
    
Stay smarter as a team in Slack with:

- Competitor Intelligence: see the latest articles from your top competitors in one place.
- Client Insights: What's new in your client's business? Track them in Slack.
- Daily tips: get daily sales or leadership tips - learn something new every day.
- Trending content: stay on top of any trend: Big Data, AI - whatever you want to track.

I want that. What now?

1. Create an account on [Anders Pink](/contact), or [login](/login).
2. Create a new briefing on any topic, or follow some public ones. Briefings are collections of content from around the web, filtered by your preferred topics and sources. E.g. "Bring me content on AI, but only if it mentions HR Or Talent Management, and only from these 10 sites." [Here’s how](https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be).
3. Go to your Slack Settings Page on Anders Pink and activate the Slack integration (paid feature).
4. On Slack, decide which channel you want to send Anders Pink briefings to: 
![AndersPink and Slack](${STATIC_DOMAIN}/pages/plugins/slack2.png "AndersPink and Slack")
5. On Anders Pink/Add AP to Slack - Choose which briefings to send to which Slack channel. You can define how much content you want to come into the channel every morning. 
![AndersPink and Slack](${STATIC_DOMAIN}/pages/plugins/slack3.png "AndersPink and Slack")

All done! Now everyone in that channel will get a daily update with the top articles from each briefing you selected. Slack (and you) just got smarter!

What do you think? Any questions, ways to improve this beta? 
    
Just [contact us](/contact) - we'd love to hear from you.
        `,
  },
  {
    id: 'moodle',
    image: `${STATIC_DOMAIN}/pages/landing/moodle-logo.png`,
    name: 'Moodle',
    title:
      'Bring continuous learning into your LMS with fresh and relevant content from Anders Pink',
    topContent: `
You want your learners to stay up to date. Formal learning courses and programmes are vital, but they go out of date quickly. New content is produced every day. How do you bring it into your [Moodle](https://moodle.org/) LMS or VLE or to support continuous learning?

You and your learners could check Google, news feeds, LinkedIn, Twitter lists, industry sites and blogs, and share the best links. But this takes a lot of time and effort. And if you’re not doing it every day, you’re missing the latest, most relevant content.
        `,
    bottomContent: `
Here how it works: 

1. Create an account on [Anders Pink](/contact), or [login](/login).
2. Create a new briefing on any topic, or follow some public ones. Briefings are collections of content from around the web, filtered by your preferred topics and sources. E.g. "Bring me content on AI, but only if it mentions HR Or Talent Management, and only from these 10 sites." [Here’s how](https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be).
3. Plug it into Moodle
    - Download the [Moodle plugin](https://github.com/anderspink/moodle-block_anderspink/releases/tag/v3.5)
    - You’ll need an API key to connect it to your Anders Pink account. 
    - [Contact us](/contact) for a free API trial key for Moodle. 
4. Drop curated content onto any page 

Once the plugin is up and running, you can create an Anders Pink Block on any page in your Moodle instance, and display a live briefing, or saved folder. For example 

- Display a trending news feed about your company, industry or competitors on your home page 
- Display curated content about leadership as part of your leadership blended programme
- Provide daily sales tips and sector insights for your sales team.
- Provide a steady stream of relevant videos and short articles - microlearning without having to make anything. 

Relevant updated content in your LMS, every few hours. Easy!

[Get in touch](/contact) to find out more about our Plugin for Moodle, and any other platform you want to integrate with.
        `,
  },
  {
    id: 'learninghubz',
    image: `${STATIC_DOMAIN}/pages/landing/learninghubz.png`,
    name: 'Learninghubz',
    title: '',
    topContent: `
YouTube and MOOC platforms have revolutionized the way people learn, offering billions of individuals the opportunity to learn any skill, anytime, anywhere, and quickly or in-depth, and for free. It’s time for L&D to join this revolution!

Learninghubz curates the very best learning resources available from YouTube and popular MOOCs on +120 skills, saving L&D time and budget from buying expensive “off-the-shelf” course libraries or creating content.
    
But finding, filtering and sharing quality takes time. Time you probably don’t have. You could just pull in some RSS feeds - but then you have no control over what gets displayed.
    
**Full Curation, Complete Control: Anders Pink Plugin for WordPress**
    
Our curation plugin for WordPress makes it easier to find and share great content from anywhere on the web that your audience will love.

- 10.000+ videos, from #shots and quick tips, to tutorials and crash courses
- 500+  courses and skill playlists
- Add your content, create learning pages, playlists and learning paths
- Content curation on demand

And with Anders Pink integration your teams will also have

- High quality web content on any skill or business topic,  from the sources you want, updated daily

Learninghubz + Anders Pink means

- Modern upskllling: your employees will be able to learn from diversified and updated resources (articles, videos and courses), in one modern learning platform 
- Stay up-to-date:: the web is always updated, so will your employees
- Cost-savings: no need to replace you LMS, or buy or content libraries
- Time-saving: instant access to high-quality learning resources, on any topic 
- Learning agility: empower everyone to learn anytime and anywhere

[Visit our website](https://www.learninghubz.com/) to learn more or request a free trial.
`,
    bottomContent: ``,
  },
  {
    id: 'wordpress',
    image: `${STATIC_DOMAIN}/pages/landing/wordpress-logo.png`,
    name: 'Wordpress',
    title:
      'Fresh, relevant content from around the web, from any site or source you want: Curation for WordPress just got easier',
    topContent: `
You want to keep your audience engaged and returning regularly to your WordPress site for updates.

One way of doing that is to regularly curate and share high quality, recent and relevant content on the topics your audience cares about.
    
But finding, filtering and sharing quality takes time. Time you probably don’t have. You could just pull in some RSS feeds - but then you have no control over what gets displayed.
    
**Full Curation, Complete Control: Anders Pink Plugin for WordPress**
    
Our curation plugin for WordPress makes it easier to find and share great content from anywhere on the web that your audience will love.

- Set the rules for the content you want to curate: Choose specific sites, twitter influencers and RSS feeds. You control the sources.
- Filter them by the keywords: pull through content on any topic, for example “digital banking trends” or “Facebook Marketing” - whatever fits with your audience and content themes. You have complete control over what’s displayed - much more powerful than an RSS feed on its own.
- Use the plugin to embed recent trending content alongside your blog posts, or on any page in your WordPress site.
- Display recent content automatically, or hand-pick the articles you want to display.

How one Client, Enterprise Ireland, keeps its audience up to date with a Briefing embedded into their WordPress site. 

![Enterprise Ireland](${STATIC_DOMAIN}/pages/plugins/ei.png "Enterprise Ireland WordPress integration")

See the full [case study](/client-success-stories/enterprise-ireland) here. 
    
Get the [WordPress plugin here](https://api.github.com/repos/anderspink/ap_wordpress_plugin/zipball). The WordPress Plugin needs an API key to function. 

[Contact us](/contact) to set up a free API trial.

Power your site with curated content, keep your audience engaged. 
    
Want to build Anders Pink into more places - such as your Learning Platform, LMS, Slack, Yammer or SharePoint? We have a wide range of [integration options](/integrations) for all of these and more.
        `,
    bottomContent: ``,
  },
  {
    id: 'totara',
    image: `${STATIC_DOMAIN}/pages/landing/totara-logo.jpg`,
    name: 'Totara',
    title:
      'Bring continuous learning into your LMS with fresh and relevant content from Anders Pink.',
    topContent: `
You want your learners to stay up to date. Formal learning courses and programmes are vital, but they go out of date quickly. New content is produced every day. How do you bring it into your [Totara Learn LMS](https://www.totaralms.com/solutions/totara-learn) to support continuous learning?

You and your learners could check Google, news feeds, LinkedIn, Twitter lists, industry sites and blogs, and share the best links. But this takes a lot of time and effort. And if you’re not doing it every day, you’re missing the latest, most relevant content.

This is where our Totara Plugin helps.
        `,
    bottomContent: `
Here how it works: 

1. Create an account on [Anders Pink](/contact), or [login](/login).
2. Create a new briefing on any topic, or follow some public ones. Briefings are collections of content from around the web, filtered by your preferred topics and sources. E.g. "Bring me content on AI, but only if it mentions HR Or Talent Management, and only from these 10 sites." [Here’s how](https://www.loom.com/share/3a4c7b15cc924db3bc1cd24ace0a89be).
3. Plug it into Totara 
    - Install the [Totara plugin](https://github.com/anderspink/moodle-block_anderspink/releases/tag/v3.5) for Anders Pink 
    - You’ll need an API key to connect it to your Anders Pink account. 
    - [Contact us](/contact) for a free API trial key for Totara. 
4. Drop curated content onto any page 

Once the plugin is up and running, you can create an Anders Pink Block on any page in your Totara instance, and display a live briefing, or saved folder. For example 

- Display a trending news feed about your company, industry or competitors on your home page 
- Display curated content about leadership as part of your leadership blended programme
- Provide daily sales tips and sector insights for your sales team.
- Provide a steady stream of relevant videos and short articles - microlearning without having to make anything. 

Relevant updated content in your Totara LMS, every few hours. Tracked and integrated. Easy!

<iframe style="max-width: 100%; width: 1280px; height: 400px" frameborder="0" scrolling="no" src="https://screencast-o-matic.com/embed?sc=cqeoIh0BkW&v=5&ff=1" allowfullscreen="true"></iframe>

[Get in touch](/contact) to find out more about our Plugin for Totara, and any other platform you want to integrate with.

Already working with a Totara Partner? They can do this all for you. Get in touch and we can support them with the plugin and API information.
        `,
  },
  {
    id: '',
    image: `${STATIC_DOMAIN}/pages/landing/sharepoint-logo.png`,
    name: '',
    title: '',
    topContent: ``,
    bottomContent: ``,
  },
]

function RouterLink(props) {
  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href} className="link" target="_blank">
      {props.children}
    </a>
  ) : (
    <Link to={props.href} className="link">
      {props.children}
    </Link>
  )
}

@connect((state) => ({
  app: state.app,
}))
export default class IntegrationsSharepoint extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    const integration =
      integrations.find((story) => story.id === this.props.params.id) ||
      integrations[0]

    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundImage: `url(${STATIC_DOMAIN}/pages/landing/plugin-header.png)`,
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Plugins: {integration.name}</div>
          </div>
        </div>

        <div className={styles.logo}>
          <img src={integration.image} />
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            {integration.name} integration for Anders Pink
          </div>

          <div className={pageStyles.sectionSubHeading}>
            {integration.title}
          </div>

          <div className={[pageStyles.textContainer, styles.text].join(' ')}>
            <ReactMarkdown
              source={integration.topContent}
              renderers={{ link: RouterLink }}
            />
          </div>
        </div>

        {!!integration.bottomContent && (
          <div className={pageStyles.greySection}>
            <div className={pageStyles.innerGreySection}>
              <div className={pageStyles.sectionHeading}>
                Back up - What is Anders Pink?
              </div>

              <div className={pageStyles.textContainer}>
                <p>
                  <Link href="/c/product" className="link">
                    Anders Pink
                  </Link>{' '}
                  is a content curation tool that makes it easy to stay up to
                  date on the topics that you care about. Create briefings
                  (collections of articles) on any topic. Filter by keywords,
                  sites, twitter handles and RSS feeds. Briefings refresh every
                  few hours, so you're always up to date.
                </p>
              </div>
            </div>
          </div>
        )}

        {!!integration.bottomContent && (
          <div className={pageStyles.section}>
            <div className={[pageStyles.textContainer, styles.text].join(' ')}>
              <ReactMarkdown
                source={integration.bottomContent}
                renderers={{ link: RouterLink }}
                escapeHtml={false}
              />
            </div>
          </div>
        )}

        <div className={styles.technical}>
          <div className={styles.technicalContent}>
            <p className={styles.technicalTop}>
              Just get in touch and we can set you up with a free integration
              trial.
            </p>
            <button type="submit" className="btn btn-lg btn-primary">
              Get in touch
            </button>
          </div>
        </div>
      </div>
    )
  }
}
