import React from 'react'
import { Link } from 'react-router'
import styles from '../Home.module.css'
import CookiePopup from 'app/containers/CookiePopup'

const apLogoWhite = `${STATIC_DOMAIN}/pages/new-homepage/ap-logo-white.png`
const tw = `${STATIC_DOMAIN}/pages/new-homepage/Twitter Icon.png`
const li = `${STATIC_DOMAIN}/pages/new-homepage/LinkedIn.png`
const award1 = `${STATIC_DOMAIN}/pages/new-homepage/2020_iil_award.png`
const award2 = `${STATIC_DOMAIN}/pages/new-homepage/NQA_ISO27001_CMYK.jpg`

const Footer = () => {
  return (
    <div className={`${styles.backgroundRed}`} style={{ textAlign: 'left' }}>
      <div className={`${styles.content}`}>
        <div className={`${styles.footerContent}`}>
          <div className={styles.footerColumnOne}>
            <Link to="/" style={{ alignSelf: 'flex-start', marginBottom: 20 }}>
              <img
                src={apLogoWhite}
                alt="logo"
                style={{ alignSelf: 'flex-start', width: 50, height: 50 }}
              />
            </Link>
            <p style={{ color: '#ffffff' }}>© Anders Pink 2022</p>
          </div>
          <div>
            <Link
              to="/client-success-stories"
              className={styles.footerNavLinks}
            >
              <p style={{ color: '#ffffff' }}>Success Stories</p>
            </Link>
            <Link to="/c/about" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>About</p>
            </Link>
            <a
              href="https://blog.anderspink.com"
              target="_blank"
              className={styles.footerNavLinks}
            >
              <p style={{ color: '#ffffff' }}>Blog</p>
            </a>
            <Link to="/c/product" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Custom curation app</p>
            </Link>
            <Link
              to="/c/product-briefing-bundles"
              className={styles.footerNavLinks}
            >
              <p style={{ color: '#ffffff' }}>Pre-defined bundles</p>
            </Link>
            <Link
              to="/c/product-multi-language"
              className={styles.footerNavLinks}
            >
              <p style={{ color: '#ffffff' }}>Multi-language features</p>
            </Link>
            <Link to="/integrations" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>APIs and integrations</p>
            </Link>
            <Link to="/partners" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Partner showcase</p>
            </Link>
          </div>
          <div>
            <Link to="/cc/platforms" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Complete Curation - Platforms</p>
            </Link>
            <Link to="/cc/enterprise" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Complete Curation - Enterprise</p>
            </Link>
            <Link to="/wc/platforms" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Web Curation - Platforms</p>
            </Link>
            <Link to="/wc/enterprise" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Web Curation - Enterprise</p>
            </Link>
            <Link to="/content-skills-mapper" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Content to Skills Mapper</p>
            </Link>
          </div>
          <div>
            <Link to="/contact" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Contact</p>
            </Link>
            <Link to="/support" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Support</p>
            </Link>
            <Link to="/c/privacy-policy" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Privacy policy</p>
            </Link>
            <Link to="/c/cookie-policy" className={styles.footerNavLinks}>
              <p style={{ color: '#ffffff' }}>Cookie policy</p>
            </Link>
            <Link
              to="/c/terms-and-conditions"
              className={styles.footerNavLinks}
            >
              <p style={{ color: '#ffffff' }}>Terms & conditions</p>
            </Link>
            <p style={{ color: '#ffffff', fontSize: 20 }}>Follow Us</p>
            <a
              href="https://twitter.com/anderspink"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="twiiter"
                src={tw}
                style={{
                  width: 32,
                  height: 32,
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/anders-pink/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="facebook"
                src={li}
                style={{
                  width: 32,
                  height: 32,
                  marginLeft: 10,
                }}
              />
            </a>
          </div>
          <div className={styles.footerColumnFour}>
            <img
              alt="award"
              src={award1}
              style={{
                width: 150,
                height: 150,
              }}
            />
            <img
              alt="award"
              src={award2}
              style={{
                width: 100,
                height: 150,
                marginTop: 20,
              }}
            />
          </div>
        </div>
      </div>
      <CookiePopup />
    </div>
  )
}

export default Footer
