import React from 'react'
import styles from './DropdownMenu.css'

export class DropdownItem extends React.Component {
  render() {
    const { children, ...otherProps } = this.props
    return (
      <div {...otherProps} className={styles.item}>
        {children}
      </div>
    )
  }
}

export class DropdownMenu extends React.Component {
  static defaultProps = {
    text: '',
    textIcon: '',
    left: false,
    onOpen: () => {},
    top: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }
  onWindowClick = () => {
    this.setState({
      open: false,
    })
  }
  componentDidMount() {
    window.addEventListener('click', this.onWindowClick)
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick)
  }

  render() {
    return (
      <div className={this.props.top ? styles.topRoot : styles.root}>
        <div
          className={styles.title}
          onClick={(e) => {
            if (this.state.open) {
              e.stopPropagation()
            }
            setTimeout(() => {
              if (!this.state.open) {
                this.props.onOpen()
              }
              this.setState({
                open: !this.state.open,
              })
            }, 1)
          }}
        >
          {this.props.children[0]}
        </div>

        {this.state.open && (
          <div
            className={this.props.left ? styles.leftItems : styles.items}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {React.Children.map(this.props.children[1], (child) => (
              <div onClick={() => this.setState({ open: false })}>{child}</div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
