import React, { useState, useEffect } from 'react'
import '../styles/fileInput.scss'

const MAX_SIZE = 2000000

const FileInput = ({ input, type, meta, mime, defaultValue }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [highlight, setHighlight] = useState(false)

  useEffect(() => {
    if (input.value && input.value.image) {
      setSelectedImage(input.value.image)
      return
    }
  }, [])

  const handleHighlight = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setHighlight(true)
  }
  const handleUnhighlight = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setHighlight(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    let dt = e.dataTransfer
    let files = dt.files
    let size = files[0].size

    if (size > MAX_SIZE) {
      alert(
        `The file size exceed the maximum of ${MAX_SIZE / 1000000}mb allowed`,
      )
    } else {
      loadFile(files[0])
    }

    setHighlight(false)
  }

  const handleChange = (e) => {
    e.preventDefault()
    e.stopPropagation()

    let size = e.target.files[0].size

    if (size > MAX_SIZE) {
      alert(
        `The file size exceed the maximum of ${MAX_SIZE / 1000000}mb allowed`,
      )
    } else {
      loadFile(e.target.files[0])
    }
  }

  const loadFile = (file) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setSelectedImage(reader.result)
      var image = new Image()
      image.src = reader.result
      image.onload = () => {
        let bannerImageWidth =
          image.height <= 200
            ? image.width
            : Math.round(image.width * (200 / image.height))
        input.onChange({
          image: image.src,
          width: bannerImageWidth.toString(),
        })
      }
    })
    reader.readAsDataURL(file)
  }

  const handleOnClear = (e) => {
    e.preventDefault()
    setSelectedImage(null)
    input.onChange({ image: null, width: null })
  }

  return (
    <>
      {selectedImage ? (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary position-absolute d-flex align-self-center opacity-50"
            onClick={handleOnClear}
          >
            <i className="fa fa-trash" />
          </button>
          <img alt="preview" width={'250px'} src={selectedImage} />
        </div>
      ) : (
        <div
          className="inner-container"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className={
              highlight
                ? 'draggable-container highlight'
                : 'draggable-container'
            }
            onDragEnter={handleHighlight}
            onDragOver={handleHighlight}
            onDragLeave={handleUnhighlight}
            onDrop={handleDrop}
          >
            <input
              name={input.name}
              type={type}
              accept={mime}
              onChange={handleChange}
            />
            <div className="files-preview-container"></div>
            <div className="helper-text">Drag and Drop Image Here</div>
          </div>
        </div>
      )}
    </>
  )
}

export default FileInput
