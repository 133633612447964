import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'

import { connect } from 'react-redux'
import AdminTable from 'admin/components/AdminTable'

import axios from 'axios'
import moment from 'moment'

import FixedModal from 'app/components/FixedModal'

@connect()
export default class AdminCrawlerPage extends React.Component {
  constructor() {
    super()
    this.state = {
      domains: [],
      queue: {},
      isModalOpen: false,
      articles: [],
    }
  }
  componentDidMount() {
    this.intervalId = setInterval(this.fetchDomains, 20000)
    this.intervalId2 = setInterval(this.fetchQueue, 5000)
    this.fetchDomains()
    this.fetchQueue()
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
    clearInterval(this.intervalId2)
  }
  fetchDomains = async () => {
    const response = await axios('/crawler/es/domains')
    this.setState({
      domains: response.data.items,
    })
  }
  fetchQueue = async () => {
    const response = await axios('/crawler/queue')
    this.setState({
      queue: response.data.domains,
    })
  }
  viewArticles = async (domain) => {
    this.setState({
      isModalOpen: true,
      articles: [],
    })
    const response = await axios(
      '/crawler/es/articles/' + domain + '?from=0&size=1000',
    )
    this.setState({
      articles: response.data.items,
    })
  }
  onClose = () => this.setState({ isModalOpen: false })

  clearCache = async () => {
    const response = await axios('/crawler/clear-cache/' + domain)
    console.log('clearCache', response)
  }

  render() {
    return (
      <div>
        <table className="table">
          <tr>
            <th>Domain</th>
            <th>Num crawled pages</th>
            <th>Last page crawled at</th>
            <th>Urls in crawl queue</th>
            <th></th>
          </tr>
          {this.state.domains.map((domain) => (
            <tr key={domain.key}>
              <td>{domain.key}</td>
              <td>{domain.doc_count}</td>
              <td>{domain.last_crawled_at.value_as_string}</td>
              <td>{this.state.queue[domain.key] || 0}</td>
              <td>
                <button
                  className="btn btn-secondary btn-small"
                  onClick={() => this.viewArticles(domain.key)}
                >
                  View crawled pages
                </button>{' '}
                <button
                  className="btn btn-secondary btn-small"
                  onClick={() => this.clearCache(domain.key)}
                >
                  Clear 'recently-crawled' cache
                </button>
              </td>
            </tr>
          ))}
        </table>

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.onClose}
          buttons={[
            <button className="btn btn-sm btn-secondary" onClick={this.onClose}>
              Close
            </button>,
          ]}
          width="900px"
        >
          <div style={{ padding: '20px' }}>
            {this.state.articles.map((article) => (
              <div key={article._id} style={{ marginBottom: '10px' }}>
                <div style={{ fontWeight: 'bold' }}>
                  {article._source.title}
                </div>
                <div>
                  <a href={article._source.url}>{article._source.url}</a>
                </div>

                <div
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    color: '#444',
                  }}
                >
                  {article._source.date_published ? (
                    <span>Published: {article._source.date_published} - </span>
                  ) : null}
                  <span>Word count: {article._source.word_count} - </span>
                  <span>Score: {article._source.score}</span>
                </div>

                {article._source.content && (
                  <div style={{ fontSize: '0.8rem', color: '#444' }}>
                    {article._source.content.slice(0, 300)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </FixedModal>
      </div>
    )
  }
}
