import React from 'react'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import withAuthCheck from './withAuthCheck'
import { isLoggedIn } from '../selectors/appSelectors'
import ReactTooltip from 'react-tooltip'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './App.css'
import newStyles from './App2.css'
import FixedModal from 'app/components/FixedModal'
import { DropdownMenu } from 'app/components/DropdownMenu'
import axios from 'axios'
import NotificationIcon from 'app/containers/NotificationIcon'
import AppQuery from 'app/queries/AppQuery'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import AppNotifications from './AppNotifications'

import './bootstrap.scss'
import Footer from '../../content/pages/new-homepage/components/Footer/Footer'
import Navbar from '../../content/pages/new-homepage/components/Navbar/Navbar'
// require('font-awesome-sass-loader')

const PREFIX = process.env.NODE_ENV === 'production' ? 'https' : 'http'
const DOMAIN = process.env.NODE_ENV === 'production' ? 'com' : 'local'

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      scrollPosition:
        document.body.scrollTop || document.documentElement.scrollTop,
      menuOpen: false,
      isMouseInside: false,
      isMouseInside2: false,
      dropdownOpen: false,
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onMobileMenuItemClick = () => {
    this.setState({
      menuOpen: false,
    })
  }

  onScroll = () => {
    const scrollPosition =
      document.body.scrollTop || document.documentElement.scrollTop
    this.setState({
      scrollPosition,
    })
  }

  mouseEnter = () => {
    this.setState({ isMouseInside: true })
  }
  mouseLeave = () => {
    this.setState({ isMouseInside: false })
  }
  mouseEnter2 = () => {
    this.setState({ isMouseInside2: true })
  }
  mouseLeave2 = () => {
    this.setState({ isMouseInside2: false })
  }

  render() {
    const isLoggedIn = this.props.isLoggedIn
    return (
      <div
        className={[
          newStyles.header,
          this.props.darkNav || this.state.scrollPosition > 0
            ? newStyles.headerFixed
            : null,
        ].join(' ')}
      >
        {!this.props.darkNav && (
          <Navbar
            backgroundColors={this.state.scrollPosition > 0 ? 'light' : 'dark'}
            pinkOrGreen="pink"
            logoColor={this.state.scrollPosition > 0 ? 'pink' : 'white'}
            navBtn1Color={this.state.scrollPosition > 0 ? undefined : 'black'}
          />
        )}
        {this.props.darkNav && (
          <Navbar
            backgroundColors={'light'}
            pinkOrGreen="pink"
            logoColor={'pink'}
            navBtn1Color={undefined}
          />
        )}
      </div>
    )
  }
}

@withAuthCheck
@connect((state) => ({
  layout: state.app.layout,
  features: state.app.features,
  isLoggedIn: isLoggedIn(state),
}))
@graphql(AppQuery)
export default class App extends React.Component {
  constructor(props) {
    super(props)
    let hideMenus =
      props.location.query && props.location.query.iframe ? true : false

    if (props.location.pathname.indexOf('/embed/team/') === 0) {
      hideMenus = true
    }

    this.state = {
      briefingsExpanded: false,
      boardsExpanded: false,
      isChoosingTeam: false,
      isSearchingTeam: false,
      teamResults: [],
      accountResults: [],
      hideMenus,
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.data.loading &&
      !isEqual(nextProps.data.session, this.props.data.session)
    ) {
      this.props.dispatch({
        type: 'SET_APP_QUERY_RESULT',
        appQueryResult: nextProps.data.session,
      })
    }
  }
  componentDidMount() {
    if (window && window.ga) {
      window.ga('send', 'pageview', {
        dimension1: this.props.isLoggedIn ? 'user' : 'guest',
      })
    }
  }

  render() {
    if (this.props.data.loading && !this.props.data.session) {
      return null
    }

    const session = this.props.data.session
    const isLoggedIn =
      this.props.isLoggedIn && session && session.user ? true : false

    let showRedirection = false
    if (
      this.props.data &&
      this.props.data.session &&
      this.props.data.session.showRedirectionFlag
    ) {
      showRedirection = true
    }

    if (
      this.props.data &&
      this.props.data.session &&
      this.props.data.session.maintenance
    ) {
      const viableMaintenanceRouters = [
        '^/$',
        '^/integrations',
        '^/partners',
        '^/client-success-stories',
        '^/contact',
        '^/api-info',
        '^/api-docs',
        '^/pricing',
        '^/c/',
      ]
      let allowed = false
      for (let route of viableMaintenanceRouters) {
        if (new RegExp(route).test(this.props.location.pathname)) {
          allowed = true
        }
      }
      if (!allowed) {
        return (
          <div className={styles.maintenanceContainer}>
            <img
              className="App2__logo___fbU56"
              src="/pages/landing/ap-logo-white.png"
            ></img>

            <div className={styles.maintenanceText}>
              <div className={styles.maintenanceTitle}>
                Anders Pink - server maintenance is under way.
              </div>
              <p>
                The web app is in maintenance mode, we are expecting this to be
                complete by 02:00 GMT+1.
              </p>
              <p>
                Our APIs are in read-only mode for the duration of the
                maintenance. Any API requests that attempt to modify resources
                will return a "503 Service Unavailable" error.
              </p>
              <p>
                If you need any immediate assistance please contact
                team@anderspink.com
              </p>
              <p>
                <a href="/" className="link">
                  Go to the home page
                </a>
              </p>
            </div>
          </div>
        )
      }
    }
    if (this.props.layout.completePage) {
      return this.props.children
    }

    if (this.props.layout.newLayout) {
      return (
        <div className={newStyles.app}>
          <Header
            isLoggedIn={isLoggedIn}
            darkNav={!!this.props.layout.darkNav}
          />
          {this.props.children}
          <Footer />
        </div>
      )
    }

    let user = isLoggedIn ? session.user : null

    // Should they see the pricing? yes if not paying....
    let shouldSeePricing = false
    let accountLinks = []
    let accountList = this.state.isSearchingAccount
      ? this.state.accountResults
      : accountLinks
    if (isLoggedIn) {
      if (
        user.teams.length == 0 &&
        user.accounts.length === 1 &&
        !user.accounts[0].isPaying &&
        !user.accounts[0].isTrial
      ) {
        shouldSeePricing = true
      }

      // If the user only has a personal account...
      if (user.accounts.length === 1 && user.accounts[0].type === 'personal') {
        accountLinks.push(
          <a
            key="personal"
            href={`/account/${user.accounts[0].id}`}
            className={styles.dropdownLink}
          >
            Account
          </a>,
        )
      } else {
        // Loop over all accounts..
        for (let account of user.accounts) {
          accountLinks.push(
            <a
              key={account.id}
              href={`/account/${account.id}`}
              className={styles.dropdownLink}
            >
              Account: {account.type === 'personal' ? 'Personal' : account.name}
            </a>,
          )
        }
      }
    }

    const currentPath = this.props.location.pathname
    const layout = this.props.layout

    const isLeftHandNavVisible = isLoggedIn && !layout.hideLeftNav
    const isLeftHandNavMinimised = layout.minimiseLeftNav

    const showLeftNavText = !layout.minimiseLeftNav

    let contentClass = isLeftHandNavMinimised
      ? styles.contentWithMinmisedLeftNav
      : styles.content
    if (!isLeftHandNavVisible) {
      contentClass = styles.contentWithNoLeftNav
    }

    if (this.state.hideMenus || layout.hideAllNav) {
      contentClass = styles.contentWithNoNavs
    }

    let canPubliclyExport = false
    if (
      session &&
      session.team &&
      session.team.account &&
      session.team.account.activeSubscriptions
    ) {
      for (let subscription of session.team.account.activeSubscriptions) {
        for (let plan of subscription.plans) {
          const planSet = plan.planSet
          if (planSet && planSet.canPubliclyExport) {
            canPubliclyExport = true
          }
        }
      }
    }

    const hasApiAccess =
      session && session.team && session.team.apiUser && session.team.apiUser.id

    let seenTeamIds = []
    let allTeamList = []
    let teamList = this.state.isSearchingTeam
      ? this.state.teamResults
      : allTeamList
    if (isLoggedIn && session && session.user) {
      for (let team of session.user.teams) {
        if (!seenTeamIds.includes(team.id)) {
          allTeamList.push(team)
          seenTeamIds.push(team.id)
        }
      }
      for (let account of session.user.accounts) {
        for (let team of account.teams) {
          if (!seenTeamIds.includes(team.id)) {
            allTeamList.push(team)
            seenTeamIds.push(team.id)
          }
        }
      }
    }

    const searchTeam = (term) => {
      if (term.length > 0) {
        this.setState({ isSearchingTeam: true })
        let results = allTeamList.filter((team) =>
          team.name.toLowerCase().includes(term),
        )
        this.setState({ teamResults: results })
      } else {
        this.setState({ isSearchingTeam: false })
      }
    }

    const searchAccount = (term) => {
      if (term.length > 0) {
        this.setState({ isSearchingAccount: true })
        let results = accountLinks.filter((account) =>
          account.props.children[1].toLowerCase().includes(term),
        )

        this.setState({ accountResults: results })
      } else {
        this.setState({ isSearchingAccount: false })
      }
    }

    const MAX_RESULTS = 10
    const { team, isTeamAdmin } = session || {}
    const userHasTeams = user && user.teams && user.teams.length > 0
    const canUsersEditDomainLists = team ? team.canUsersEditDomainLists : false
    const isNotPersonal = !!team
    const canManageDomainLists =
      this.props.features.domainLists &&
      isNotPersonal &&
      userHasTeams &&
      (canUsersEditDomainLists || isTeamAdmin)

    const willShowTeamResults =
      this.state.isSearchingTeam && this.state.teamResults.length > 0
        ? true
        : !this.state.isSearchingTeam && allTeamList.length > 0
        ? true
        : false
    const willShowAccountResults =
      this.state.isSearchingAccount && this.state.accountResults.length > 0
        ? true
        : !this.state.isSearchingAccount && accountLinks.length > 0
        ? true
        : false
    return (
      <div className={styles.root}>
        {!!showRedirection && (
          <div
            style={{
              backgroundColor: 'red',
              height: '50px',
              color: 'white',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ padding: '3px', margin: '0' }}>
              Please use the new app at{' '}
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://web-curation.anderspink.com"
                target="_blank"
              >
                https://web-curation.anderspink.com
              </a>{' '}
              to update your briefings and folders. This app has now been
              deprecated and changes to briefings will no longer be saved.{' '}
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://anderspink.com/article/anderspink-refresh"
                target="_blank"
              >
                See more info.
              </a>
            </p>
          </div>
        )}

        <header
          className={styles.header}
          style={
            this.state.hideMenus || layout.hideAllNav
              ? { display: 'none', ...(showRedirection ? { top: '50px' } : {}) }
              : { ...(showRedirection ? { top: '50px' } : {}) }
          }
        >
          <div>
            <a href={`${PREFIX}://anderspink.${DOMAIN}`}>
              <img className={styles.icon} src="/ap-logo.png" />
            </a>

            <nav></nav>
          </div>
          <div className={styles.headerLinks}>
            <Link
              className={styles.headerLink}
              href={`${PREFIX}://anderspink.${DOMAIN}/support`}
            >
              Support
            </Link>
            <Link
              className={styles.headerLink}
              href={`${PREFIX}://anderspink.${DOMAIN}`}
            >
              Go to site
            </Link>

            {isLoggedIn && (
              <span className={styles.headerLink}>
                <NotificationIcon />
              </span>
            )}

            {isLoggedIn && (
              <div className={styles.headerLink}>
                <DropdownMenu top={true}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '1.5rem',
                      color: '#555',
                    }}
                  >
                    <i className="fa fa-question-circle" />
                  </div>
                  <div>
                    <Link to="/contact" className={styles.dropdownLink}>
                      Contact us
                    </Link>
                    <Link to="/support" className={styles.dropdownLink}>
                      Support
                    </Link>
                    <Link className={styles.dropdownLink} to="/integrations">
                      Integrations / Plugins
                    </Link>
                    <Link className={styles.dropdownLink} to="/api-info">
                      API
                    </Link>
                    <Link to="/about" className={styles.dropdownLink}>
                      About
                    </Link>
                  </div>
                </DropdownMenu>
              </div>
            )}
            {isLoggedIn && (
              <div className={styles.headerLink}>
                <DropdownMenu top={true}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={styles.profilePic}
                      src={session.user.image}
                    />{' '}
                    <span>
                      <span className={styles.username}>
                        {session.user.firstName}
                      </span>{' '}
                      <i className="fa fa-caret-down" />
                    </span>
                  </div>
                  <div style={{ maxHeight: '400px', overflowX: ' scroll' }}>
                    {accountLinks.length > MAX_RESULTS && (
                      <input
                        className="form-control mr-2 search-account"
                        type="search"
                        placeholder="Search an account..."
                        onChange={(e) => searchAccount(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    <Link to="/profile" className={styles.dropdownLink}>
                      Profile
                    </Link>
                    {willShowAccountResults && accountList}

                    {session.user.isSiteAdmin && (
                      <Link to="/admin/users" className={styles.dropdownLink}>
                        Admin
                      </Link>
                    )}
                    <Link to="/logout" className={styles.dropdownLink}>
                      Logout
                    </Link>
                  </div>
                </DropdownMenu>
              </div>
            )}
            {!isLoggedIn && (
              <Link
                className={styles.headerLink}
                to={`${PREFIX}://auth.anderspink.${DOMAIN}/login?referer=${encodeURIComponent(
                  `${PREFIX}://app.anderspink.${DOMAIN}`,
                )}`}
              >
                Login
              </Link>
            )}
          </div>
        </header>

        <div className={styles.body}>
          {isLeftHandNavVisible &&
            !(this.state.hideMenus || layout.hideAllNav) && (
              <div
                className={
                  isLeftHandNavMinimised
                    ? styles.leftNavMinimised
                    : styles.leftNav
                }
                style={{ ...(showRedirection ? { top: '100px' } : {}) }}
              >
                {!isLeftHandNavMinimised && userHasTeams && (
                  <div
                    data-tip="Switch from personal view to teams"
                    className={styles.teamSwitch}
                    onClick={() => this.setState({ isChoosingTeam: true })}
                  >
                    <span>{session.team ? session.team.name : 'Personal'}</span>{' '}
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                  </div>
                )}

                <div className={styles.leftNavSection}>
                  <Link
                    data-tip="See a combined feed of all your briefings"
                    to="/home"
                    className={styles.leftNavSectionTitle}
                    style={currentPath === '/home' ? { color: '#f2105a' } : {}}
                  >
                    <span className={styles.leftNavIcon}>
                      <i className="fa-regular fa-house" aria-hidden="true" />
                    </span>
                    {showLeftNavText && <span>Home</span>}
                  </Link>
                </div>

                <div className={styles.leftNavSection}>
                  <Link
                    data-tip="View your and your team's briefings or create new<br />
                    ones. Briefings are content feeds that update every<br />
                    few hours based on your topics and sources."
                    to="/briefings"
                    className={styles.leftNavSectionTitle}
                    style={
                      currentPath === '/briefings' ? { color: '#f2105a' } : {}
                    }
                  >
                    <span className={styles.leftNavIcon}>
                      <i className="fa fa-folder" aria-hidden="true" />
                    </span>
                    {showLeftNavText && <span>Briefings</span>}
                  </Link>
                  {showLeftNavText && (
                    <div className={styles.leftNavSectionItems}>
                      {session.briefings.length > 0 && (
                        <div>
                          {session.briefings
                            .slice(
                              0,
                              this.state.briefingsExpanded
                                ? session.briefings.length
                                : 3,
                            )
                            .map((briefing, index) => (
                              <div
                                key={index}
                                className={styles.leftNavSectionItem}
                              >
                                <Link to={briefing.url}>{briefing.name}</Link>
                              </div>
                            ))}
                        </div>
                      )}
                      {session.briefings.length > 3 && (
                        <div className={styles.leftNavSectionItem}>
                          <span
                            className={styles.briefingsExpand}
                            onClick={() =>
                              this.setState({
                                briefingsExpanded:
                                  !this.state.briefingsExpanded,
                              })
                            }
                          >
                            {this.state.briefingsExpanded
                              ? 'See less'
                              : 'See more'}
                          </span>{' '}
                          {session.canEditBriefings && (
                            <Link
                              to="/create-briefing"
                              className={styles.createBriefing}
                            >
                              Create briefing
                            </Link>
                          )}
                        </div>
                      )}
                      {session.briefings.length <= 3 && (
                        <div className={styles.leftNavSectionItem}>
                          {session.canEditBriefings && (
                            <Link
                              to="/create-briefing"
                              className={styles.createBriefing}
                            >
                              Create briefing
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className={styles.leftNavSection}>
                  <Link
                    data-tip="Save content to folders for future reference"
                    to="/saved-articles/"
                    className={styles.leftNavSectionTitle}
                    style={
                      currentPath === '/saved-articles'
                        ? { color: '#f2105a' }
                        : {}
                    }
                  >
                    <span className={styles.leftNavIcon}>
                      <i className="fa fa-bookmark" aria-hidden="true" />
                    </span>
                    {showLeftNavText && <span>Saved Articles</span>}
                  </Link>
                </div>

                {session.isTeamAdmin && (canPubliclyExport || hasApiAccess) && (
                  <div
                    data-tip="Display briefings or folders in another tool or site"
                    className={styles.leftNavSection}
                  >
                    <Link
                      to="/export-settings/"
                      className={styles.leftNavSectionTitle}
                      style={
                        currentPath === '/export-settings'
                          ? { color: '#f2105a' }
                          : {}
                      }
                    >
                      <span className={styles.leftNavIcon}>
                        <i className="fa fa-cogs" aria-hidden="true" />
                      </span>
                      {showLeftNavText && <span>Export Settings</span>}
                    </Link>
                  </div>
                )}

                {session.isTeamAdmin && (
                  <div
                    data-tip="Track API usage"
                    className={styles.leftNavSection}
                  >
                    <div
                      onClick={() =>
                        browserHistory.push({
                          pathname: `/team-api-stats/${session.team.id}`,
                        })
                      }
                      className={styles.leftNavSectionTitle}
                      style={
                        currentPath === '/team-api-stats'
                          ? { color: '#f2105a', cursor: 'pointer' }
                          : { cursor: 'pointer' }
                      }
                    >
                      <span className={styles.leftNavIcon}>
                        <i className="fa fa-bar-chart" aria-hidden="true" />
                      </span>
                      {showLeftNavText && <span>Usage Analytics</span>}
                    </div>
                  </div>
                )}

                {session.isTeamAdmin && session.team !== null && (
                  <div
                    data-tip="Track views and engagement from within the app"
                    className={styles.leftNavSection}
                  >
                    <Link
                      to={`/team-stats/${session.team.id}`}
                      className={styles.leftNavSectionTitle}
                      style={
                        currentPath === '/team-stats'
                          ? { color: '#f2105a' }
                          : {}
                      }
                    >
                      <span className={styles.leftNavIcon}>
                        <i className="fa fa-pie-chart" aria-hidden="true" />
                      </span>
                      {showLeftNavText && <span>Team Engagement</span>}
                    </Link>
                  </div>
                )}

                {session.team !== null && session.isTeamAdmin && (
                  <div
                    data-tip="Add or remove team members and manage permissions"
                    className={styles.leftNavSection}
                  >
                    <Link
                      to="/team-settings/"
                      className={styles.leftNavSectionTitle}
                      style={
                        currentPath === '/team-settings'
                          ? { color: '#f2105a' }
                          : {}
                      }
                    >
                      <span className={styles.leftNavIcon}>
                        <i className="fa fa-cogs" aria-hidden="true" />
                      </span>
                      {showLeftNavText && <span>Team Settings</span>}
                    </Link>
                  </div>
                )}

                <div className={styles.leftNavSection}>
                  <Link
                    data-tip="See content from your Twitter network"
                    to="/friends"
                    className={styles.leftNavSectionTitle}
                    style={
                      currentPath === '/friends' ? { color: '#f2105a' } : {}
                    }
                  >
                    <span className={styles.leftNavIcon}>
                      <i className="fa fa-globe" aria-hidden="true" />
                    </span>
                    {showLeftNavText && <span>Your Network</span>}
                  </Link>
                </div>

                {canManageDomainLists ? (
                  <div className={styles.leftNavSection}>
                    <Link
                      data-tip="Create and edit allowlists and blocklists for your team"
                      to="/filter-sources"
                      className={styles.leftNavSectionTitle}
                      style={
                        currentPath === '/filter-sources'
                          ? { color: '#f2105a' }
                          : {}
                      }
                    >
                      <span className={styles.leftNavIcon}>
                        <i className="fa fa-filter" aria-hidden="true" />
                      </span>
                      {showLeftNavText && <span>Allow/Blocklists</span>}
                    </Link>
                  </div>
                ) : null}

                {session && session.team ? (
                  <div className={styles.leftNavSection}>
                    <Link
                      data-tip="Create and edit digests for your team"
                      to="/newsletters"
                      className={styles.leftNavSectionTitle}
                      style={
                        currentPath === '/newsletters'
                          ? { color: '#f2105a' }
                          : {}
                      }
                    >
                      <span className={styles.leftNavIcon}>
                        <i className="fa fa-envelope" aria-hidden="true" />
                      </span>
                      {showLeftNavText && <span>Team Digests</span>}
                    </Link>
                  </div>
                ) : null}

                {/* {showLeftNavText && (
                  <div style={{ margin: '20px' }}>
                    <a
                      href="https://itunes.apple.com/us/app/anders-pink-discover-learn-curate-collaborate/id1161439166"
                      target="_blank"
                      style={{ display: 'block', marginBottom: '5px' }}
                    >
                      <img style={{ maxWidth: '60%' }} src="/app-store.png" />
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.apmobileapp"
                      target="_blank"
                      style={{ display: 'block' }}
                    >
                      <img
                        style={{ maxWidth: '60%' }}
                        src="/google-store.png"
                      />
                    </a>
                  </div>
                )} */}
              </div>
            )}
          <div
            className={
              contentClass +
              ' ' +
              (layout.contentPadding ? '' : styles.noPadding)
            }
            style={{ ...(showRedirection ? { marginTop: '100px' } : {}) }}
          >
            {this.props.children}
          </div>
        </div>

        {isLoggedIn && (
          <FixedModal
            isOpen={this.state.isChoosingTeam}
            onRequestClose={() => this.setState({ isChoosingTeam: false })}
            buttons={[
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => this.setState({ isChoosingTeam: false })}
              >
                Close
              </button>,
            ]}
          >
            <div style={{ padding: '20px' }}>
              <div style={{ marginBottom: '20px' }}>
                Change to a team or to your personal view:
              </div>

              {/* Search team */}
              {allTeamList.length > MAX_RESULTS && (
                <input
                  className="form-control mr-2"
                  type="search"
                  placeholder="Search a team..."
                  onChange={(e) => searchTeam(e.target.value)}
                />
              )}
              <br />
              <div className={styles.teamSelects}>
                <div
                  className={styles.teamSelect}
                  onClick={() => {
                    axios
                      .get(`/iapi/auth/changeTeam?time=${Date.now()}`)
                      .then((response) => {
                        if (response.data.status === 'success') {
                          window.location.href = '/home'
                        }
                      })
                  }}
                >
                  Personal view{' '}
                  <span className={styles.teamIcon}>
                    <i className="fa fa-user" aria-hidden="true" />
                  </span>
                </div>

                {willShowTeamResults &&
                  teamList.map((team) => (
                    <div
                      key={team.id}
                      className={styles.teamSelect}
                      onClick={() => {
                        axios
                          .get(
                            `/iapi/auth/changeTeam?id=${
                              team.id
                            }&time=${Date.now()}`,
                          )
                          .then((response) => {
                            if (response.data.status === 'success') {
                              window.location.href = '/home'
                            }
                          })
                      }}
                    >
                      {team.name}{' '}
                      <span className={styles.teamIcon}>
                        <i className="fa fa-users" aria-hidden="true" />
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </FixedModal>
        )}

        <AppNotifications />
        <ReactTooltip delayShow={750} multiline={true} />
        <AppModal />
      </div>
    )
  }
}

@connect((state) => ({
  modal: state.app.modal,
}))
class AppModal extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }
  componentDidUpdate() {
    const isOpen = !!this.props.modal
    if (this.state.isOpen !== isOpen) {
      this.setState({
        isOpen,
      })
    }
  }
  render() {
    if (!this.state.isOpen) return null
    return (
      <FixedModal
        isOpen={true}
        onRequestClose={() => {
          this.props.dispatch({
            type: 'SET_APP_MODAL',
            modal: null,
          })
        }}
        buttons={[
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              this.props.dispatch({
                type: 'SET_APP_MODAL',
                modal: null,
              })
            }}
          >
            Close
          </button>,
        ]}
      >
        <div style={{ padding: '20px' }}>{this.props.modal}</div>
      </FixedModal>
    )
  }
}
