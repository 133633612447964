import React, { Component } from 'react'
import FixedModal from 'app/components/FixedModal'
import styles from './WordSuggestions.css'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { FORM_NAMES } from './constants'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

@connect((state, props) => ({
  [props.field]: selector(state, props.field),
}))
export default class IncludeWordSuggestions extends Component {
  static defaultProps = {
    onAdd: (word) => {},
  }
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }
  onAdd = (word) => {
    if (this.props[this.props.field].indexOf(word) < 0) {
      this.props.onAdd(word)
    }
  }
  render() {
    return (
      <div>
        <div
          style={{ fontSize: '0.8em', marginTop: '5px', marginBottom: '20px' }}
        >
          See some{' '}
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.setState({ isOpen: true })
            }}
          >
            useful words to include
          </span>
          , such as tips and trends.
        </div>

        <FixedModal
          width="700px"
          isOpen={this.state.isOpen}
          onRequestClose={() => this.setState({ isOpen: false })}
        >
          <div style={{ padding: '20px' }}>
            <h4>Useful words to add</h4>
            <p>
              By choosing some words that must be in the article titles, you can
              make your briefing more focused.
            </p>
            <p>
              Some useful words to add are{' '}
              <SingleAdd onAdd={this.onAdd} word={'tips'} /> and{' '}
              <SingleAdd onAdd={this.onAdd} word={'trends'} />
            </p>
            <p>
              Some useful groups of words to add are listed below. You can add
              all of the words in a group, or individual ones.
            </p>

            <div className={styles.wordGroup}>
              <div className={styles.wordGroupTitle}>Practical content</div>
              <GroupAdd
                onAdd={this.onAdd}
                words={[
                  'Tips',
                  'How to',
                  'Ways to',
                  'Guide',
                  'Step-by-step',
                  'Mistakes',
                  'Advice',
                  'Habits',
                  'Best practice',
                  'Techniques',
                  'Case studies',
                  'Successful',
                  'Examples',
                  'Steps',
                  'Insights',
                  'Lessons',
                ]}
              />
            </div>

            <div className={styles.wordGroup}>
              <div className={styles.wordGroupTitle}>Future trends</div>
              <GroupAdd
                onAdd={this.onAdd}
                words={[
                  'Trends',
                  'Trend',
                  'Forecast',
                  'Forecasts',
                  'Future of',
                  'Predictions',
                  'rise of',
                  'revolution',
                  'will change',
                ]}
              />
            </div>

            <div className={styles.wordGroup}>
              <div className={styles.wordGroupTitle}>Latest news</div>
              <GroupAdd
                onAdd={this.onAdd}
                words={['News', 'Analysis', 'update', 'Announcement', 'Latest']}
              />
            </div>

            <div className={styles.wordGroup}>
              <div className={styles.wordGroupTitle}>Financial news</div>
              <GroupAdd
                onAdd={this.onAdd}
                words={[
                  'Stock',
                  'Announcement',
                  'Stock price',
                  'Share price',
                  'Market',
                  'Investment',
                  'Acquisition',
                  'Funding',
                  'Merger',
                  'Acquires',
                  'acquisition',
                  'VC',
                  'Future of',
                  'Equity',
                  'Performance',
                  'Prediction',
                  'Analysts',
                ]}
              />
            </div>
          </div>
        </FixedModal>
      </div>
    )
  }
}

class SingleAdd extends React.Component {
  static defaultProps = {
    word: '',
    onAdd: () => {},
  }
  render() {
    const { word } = this.props

    return (
      <span
        className={styles.link}
        onClick={() => {
          this.props.onAdd(word.toLowerCase())
        }}
      >
        {word.toLowerCase()}
      </span>
    )
  }
}

class GroupAdd extends React.Component {
  static defaultProps = {
    words: [],
    onAdd: () => {},
  }
  render() {
    const words = []
    for (let i = 0; i < this.props.words.length; i++) {
      const word = this.props.words[i]
      words.push(
        <span
          key={i}
          className={styles.link}
          onClick={() => {
            this.props.onAdd(word.toLowerCase())
          }}
        >
          {word.toLowerCase()}
        </span>,
      )
      if (i < this.props.words.length - 1) {
        words.push(', ')
      }
    }

    return (
      <div>
        <div>{words}</div>{' '}
        <div style={{ marginTop: '8px' }}>
          <button
            className="btn btn-secondary"
            onClick={() => {
              for (let word of this.props.words) {
                this.props.onAdd(word.toLowerCase())
              }
            }}
          >
            add all
          </button>
        </div>
      </div>
    )
  }
}
