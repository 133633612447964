import React, { Component } from 'react'

export default class Loader2 extends Component {
  constructor() {
    super()
    this.state = {
      dots: 1,
    }
  }
  componentDidMount() {
    this.intervalId = setInterval(() => {
      let dots = this.state.dots + 1
      if (dots > 3) dots = 0
      this.setState({ dots: dots })
    }, 300)
  }
  componentWillUnmount() {
    clearInterval(this.intervalId)
  }
  render() {
    return (
      <span>
        {this.props.text || 'Loading'}
        {'.'.repeat(this.state.dots)}
      </span>
    )
  }
}
