import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Loader from 'app/components/Loader'
import PaymentForm from './PaymentForm'

const selector = formValueSelector('setupSubscription')

@compose(
  withProps((props) => ({
    initialValues: {
      interval: 'year',
    },
  })),
  reduxForm({
    form: 'setupSubscription',
  }),
  connect((state, props) => ({
    freeTrial: selector(state, 'freeTrial'),
    interval: selector(state, 'interval'),
    customPlanSets: state.app.appQueryResult.user.customPlanSets,
  })),
)
export default class SetupCustomSubscriptionPage extends React.Component {
  constructor() {
    super()
    this.state = {
      successState: null,
    }
  }
  render() {
    let customPlanSet = this.props.customPlanSets.find(
      (customPlanSet) => customPlanSet.id === this.props.params.id,
    )

    if (!customPlanSet) {
      return (
        <div>
          Sorry, this plan couldn't be found, or you don't have permission.
        </div>
      )
    }

    const yearly = '$' + customPlanSet.yearlyPlan.price
    const monthly = '$' + customPlanSet.monthlyPlan.price

    if (this.state.successState === 'paid') {
      return (
        <div>
          <h5>New subscription</h5>
          <br />
          <p>
            Thank you for purchasing the <strong>{customPlanSet.name}</strong>{' '}
            plan.
          </p>
          <p>
            You can cancel at any point by{' '}
            <Link to="/contact" className="link">
              contacting us.
            </Link>
          </p>
          <p>Thanks, we hope you get value out of Anders Pink!</p>
        </div>
      )
    }

    return (
      <div>
        <h5>New subscription</h5>

        <p>
          Finish setting up your new <strong>{customPlanSet.name}</strong>{' '}
          subscription. Or{' '}
          <Link className="link" to="/subscription">
            go back
          </Link>{' '}
          to pick a different plan.
        </p>

        <div style={{ margin: '30px 0' }}>
          <p>Do you want to pay anually or monthly?</p>
          <div
            className="row"
            style={{ margin: '10px -15px', maxWidth: '600px' }}
          >
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-body">
                  <label className="form-check-label">
                    <Field
                      name="interval"
                      component="input"
                      type="radio"
                      value="year"
                    />{' '}
                    Pay anually ({yearly} billed anually)
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-body">
                  <label className="form-check-label">
                    <Field
                      name="interval"
                      component="input"
                      type="radio"
                      value="month"
                    />{' '}
                    Pay monthly ({monthly} per month)
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PaymentForm
          type="custom"
          customPlanSetId={customPlanSet.id}
          freeTrial="no"
          interval={this.props.interval}
          onSuccess={(state) => this.setState({ successState: state })}
        />
      </div>
    )
  }
}
