import React from 'react'
import styles from './BriefingNameBlock.css'
import { Link } from 'react-router'

export default class BriefingNameBlock extends React.Component {
  render() {
    const briefing = this.props.briefing

    return (
      <div
        className={styles.root}
        style={{
          backgroundImage: `url(${briefing.image})`,
        }}
      >
        <Link to={briefing.url} className={styles.inner}>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <div className={styles.name}>{briefing.name}</div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
