import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change,
  touch,
} from 'redux-form'

import DomainList from './DomainList'

@connect(
  (state, props) => {
    return {
      [props.field]: formValueSelector(props.formName)(state, props.field),
    }
  },
  { change, touch },
)
export default class DomainListField extends Component {
  handleUpdate = (items) => {
    const domains = items.map(({ domain }) => domain)
    const fieldName = this.props.field
    this.props.change(this.props.formName, fieldName, domains)
    this.props.touch(this.props.formName, [fieldName])
  }
  render() {
    const field = this.props.field
    const items = this.props[field].map((domain) => ({ domain }))
    return (
      <DomainList
        items={items}
        handleUpdate={this.handleUpdate}
        placeholder={this.props.placeholder}
      ></DomainList>
    )
  }
}
