import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styles from './BriefingsPanel.css'
import panelStyles from './Panel.css'
import Article from 'articles/containers/Article'
import ArticleListFullPage from 'articles/components/ArticleListFullPage'

@graphql(gql`
  query {
    home {
      articles(limit: 10) {
        id
        url
        title
        image
        domain
        content
        readingTime
        datePublished
        sharers {
          username
          name
          image
          postedAt
        }
        briefings {
          id
          name
          url
        }
        upvotes {
          date
          user {
            id
            firstName
          }
        }
        saves {
          date
          boardId
          user {
            id
          }
        }
        comments {
          date
          text
          user {
            id
            firstName
            lastName
            image
          }
        }
      }
    }
  }
`)
export default class BriefingsPanel extends React.Component {
  render() {
    return (
      <div>
        {this.props.data.loading && <div>Loading...</div>}
        {!this.props.data.loading && !this.props.data.home && (
          <div>Sorry there was an error, please refresh and try again</div>
        )}
        {!this.props.data.loading && (
          <div>
            {this.props.data.home.articles.length === 0 && (
              <div>
                You have no articles to show. You may want to{' '}
                <Link className="link" to="/create-briefing">
                  create
                </Link>{' '}
                some briefings.
              </div>
            )}
            <ArticleListFullPage>
              {this.props.data.home.articles.map((article, index) => (
                <Article key={index} article={article} />
              ))}
            </ArticleListFullPage>
          </div>
        )}
      </div>
    )
  }
}
