import React from 'react'
import Carousel from 'react-elastic-carousel'

import styles from './PartnersCarousel.module.css'

const PartnersCarousel = (props) => {
  let paginationAndArrows = false

  if (props.logos.length > 5) {
    paginationAndArrows = true
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 650, itemsToShow: 3 },
    { width: 800, itemsToShow: 4 },
    {
      width: 1000,
      itemsToShow: 5,
      pagination: paginationAndArrows,
      showArrows: paginationAndArrows,
    },
  ]

  return (
    <section
      className={styles.partners2}
      style={{
        background: props.bgColor,
      }}
    >
      <header className={styles.partners2Text}>{props.headerText}</header>
      <Carousel
        className={styles.partners2Carousel}
        breakPoints={breakPoints}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
      >
        {props.logos.map((logo) => (
          <img
            src={logo.src}
            alt={logo.name}
            key={logo.id}
            style={logo.style}
          />
        ))}
      </Carousel>
    </section>
  )
}

export default PartnersCarousel
