import React, { Component } from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { isEqual } from 'lodash'
import Loader2 from 'app/components/Loader2'

import { Button } from 'react-bootstrap'

import styles from './PreviewBriefing.css'
import ArticleListFullPage from 'articles/components/ArticleListFullPage'
import Article from 'articles/containers/Article'
import FixedModal from 'app/components/FixedModal'

const makeCancelable = (promise) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
    )
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error),
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    },
  }
}

import {
  FORM_NAMES,
  DOMAIN_LIST_TYPES,
  FIELDS_DOMAIN_LISTS as FIELDS,
} from './constants'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

// note: use testCategoryScore for testing single categories
// (also need to mod crawler SearchRouter createPreview method to enable it)

@connect((state, props) => ({
  briefing: selector(
    state,
    'type',
    'contentTypes',
    'keywordFields',
    'sourceFields',
    'looseFields',
    'keywordsV2Fields',
    'sourcesV2Fields',
    'language',
    'influencerFilter',
    'domainFilter',
    'rssFilter',
  ),
  temporaryTrainings: selector(state, 'temporaryTrainings'),
  domainListType: selector(state, 'domainListType'),
}))
@graphql(
  gql`
    mutation ($briefing: BriefingInput!) {
      createBriefingPreview(briefing: $briefing) {
        previewId
        articles {
          id
          title
          content
          url
          image
          domain
          datePublished
          relevant
          hasVideo
          hasPodcast
        }
        completionPercent
        categories {
          name
          value
        }
      }
    }
  `,
  {
    name: 'createBriefingPreview',
  },
)
export default class PreviewBriefing extends Component {
  constructor() {
    super()
    this.state = {
      hideInfo: false,

      previewId: null,
      completionPercent: null,
      articles: [],
      creatingPreview: false,
      selectedDomain: null,
    }
  }
  componentDidMount() {
    this.createPreview(this.props.briefing)
  }
  componentWillUnmount() {
    if (this.previewPromise) {
      this.previewPromise.cancel()
      this.previewPromise = null
    }
  }
  async createPreview(briefing) {
    try {
      // Do we have enough data to create a preview?
      let canCreatePreview =
        (briefing.type === 'keywords' &&
          briefing.keywordFields.keywords.length > 0) ||
        (briefing.type === 'sources' &&
          briefing.sourceFields.sources.length > 0) ||
        (briefing.type === 'loose' && briefing.looseFields.query.length > 0) ||
        (briefing.type === 'keywordsV2' &&
          briefing.keywordsV2Fields.topics.length > 0) ||
        (briefing.type === 'sourcesV2' &&
          briefing.sourcesV2Fields.sources.length > 0)

      if (!briefing.contentTypes || briefing.contentTypes.length === 0) {
        canCreatePreview = false
      }

      if (!canCreatePreview) {
        return
      }
      this.setState({ creatingPreview: true })

      if (this.previewPromise) {
        this.previewPromise.cancel()
        this.previewPromise = null
      }

      const {
        keywordsV2Fields: {
          domainListAllowIds,
          domainListBlockIds,
          ...restKeywordsV2Fields
        },
        ...restBriefing
      } = briefing

      const crawlerDomainListIds =
        this.props.domainListType === 'allow'
          ? { domainListAllowIds, domainListBlockIds: [] }
          : { domainListAllowIds: [], domainListBlockIds }

      this.previewPromise = makeCancelable(
        this.props.createBriefingPreview({
          variables: {
            briefing: {
              ...restBriefing,
              keywordsV2Fields: {
                ...restKeywordsV2Fields,
                ...crawlerDomainListIds,
              },
            },
          },
        }),
      )

      const response = await this.previewPromise.promise
      this.previewPromise = null

      const { previewId, completionPercent, articles, categories } =
        response.data.createBriefingPreview

      this.setState({ creatingPreview: false })

      if (!previewId) {
        throw new Error('Failed to create preview')
      }
      this.setState({
        previewId,
        completionPercent,
        articles,
      })
    } catch (err) {
      if (!err.isCanceled) {
        console.log(err)
        alert('Failed to load preview, please re-fresh')
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { briefing, domainListType } = this.props
    const {
      keywordsV2Fields: {
        domainListAllowIds,
        domainListBlockIds,
        domainsAllowBriefing,
        domainsBlockBriefing,
      },
    } = briefing

    const briefingChanged = !isEqual(prevProps.briefing, briefing)
    const domainListTypeChanged =
      domainListType !== prevProps.domainListType &&
      prevProps.domainListType !== DOMAIN_LIST_TYPES.NONE
    const hasDomainListEntries =
      domainListBlockIds.length > 0 ||
      domainListAllowIds.length > 0 ||
      domainsAllowBriefing.length > 0 ||
      domainsBlockBriefing.length > 0

    if (briefingChanged || (domainListTypeChanged && hasDomainListEntries)) {
      this.createPreview(this.props.briefing)
    }
  }
  setSelectedDomain(domain) {
    this.setState({ selectedDomain: domain })
  }
  render() {
    const { briefing } = this.props
    let canCreatePreview =
      (briefing.type === 'keywords' &&
        briefing.keywordFields.keywords.length > 0) ||
      (briefing.type === 'sources' &&
        briefing.sourceFields.sources.length > 0) ||
      (briefing.type === 'loose' && briefing.looseFields.query.length > 0) ||
      (briefing.type === 'keywordsV2' &&
        briefing.keywordsV2Fields.topics.length > 0) ||
      (briefing.type === 'sourcesV2' &&
        briefing.sourcesV2Fields.sources.length > 0)
    if (!briefing.contentTypes || briefing.contentTypes.length === 0) {
      canCreatePreview = false
    }
    if (!canCreatePreview) {
      return null
    }

    let infoElement = null

    if (!this.state.previewId || this.state.creatingPreview) {
      infoElement = (
        <div className={styles.banner}>
          <Loader2 text="Loading preview" />
        </div>
      )
    } else if (
      this.state.completionPercent &&
      this.state.completionPercent < 100
    ) {
      infoElement = (
        <div className={styles.banner}>
          <Loader2 text={`Loading preview ${this.state.completionPercent}%`} />
        </div>
      )
    } else {
      infoElement = null
    }

    const { domainListType } = this.props
    const {
      keywordsV2Fields: {
        domainListAllowIds,
        domainListBlockIds,
        domainsAllowBriefing,
        domainsBlockBriefing,
      },
    } = briefing

    // Can block...

    const canBlock =
      briefing.type === 'keywordsV2' &&
      !(
        domainListType === DOMAIN_LIST_TYPES.ALLOW &&
        (domainsAllowBriefing.length > 0 || domainListAllowIds.length > 0)
      )

    return (
      <div className={styles.preview}>
        {infoElement}
        {this.state.articles.length > 0 && (
          <div className={styles.articles}>
            <ArticleListFullPage minusSpace="390">
              {this.state.articles.map((article, index) => (
                <WrappedArticle
                  key={article.id}
                  article={article}
                  training={this.props.temporaryTrainings.find(
                    (t) => t.uuid === article.id,
                  )}
                  briefingType={this.props.briefing.type}
                  onTrainingChange={(training) => {
                    const trainings = [...this.props.temporaryTrainings].filter(
                      (t) => t.uuid !== article.id,
                    )
                    if (training !== null) {
                      trainings.push({
                        uuid: article.id,
                        label: training,
                      })
                    }
                    this.props.change('temporaryTrainings', trainings)
                  }}
                  canBlock={canBlock}
                  onDomainMenu={() => this.setSelectedDomain(article.domain)}
                />
              ))}
            </ArticleListFullPage>
          </div>
        )}
        {this.state.articles.length === 0 && (
          <div className={styles.articles}>
            {this.state.completionPercent < 100
              ? 'No articles found yet...'
              : 'No articles found'}
          </div>
        )}

        <FixedModal
          width="500px"
          isOpen={!!this.state.selectedDomain}
          onRequestClose={() => this.setState({ selectedDomain: null })}
          buttons={[
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                if (canBlock) {
                  const newDomains = [
                    ...this.props.briefing.keywordsV2Fields
                      .domainsBlockBriefing,
                  ]
                  if (!newDomains.includes(this.state.selectedDomain)) {
                    newDomains.push(this.state.selectedDomain)
                    this.props.change(FIELDS.DOMAINS_BLOCK_BRIEFING, newDomains)
                    // If not blocking.. do so now
                    this.props.change(
                      FIELDS.DOMAIN_LIST_TYPE,
                      DOMAIN_LIST_TYPES.BLOCK,
                    )
                  }
                }

                this.setState({ selectedDomain: null })
              }}
            >
              Block domain
            </Button>,
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.setState({ selectedDomain: null })}
            >
              Close
            </Button>,
          ]}
        >
          <div style={{ padding: '20px' }}>
            <p>
              Do you want to block this domain?{' '}
              <span style={{ fontWeight: 'bold' }}>
                {this.state.selectedDomain}
              </span>
            </p>
            <p>You can undo this at anytime if wanted.</p>
          </div>
        </FixedModal>
      </div>
    )
  }
}

class WrappedArticle extends Component {
  shouldComponentUpdate(nextProps) {
    const hasChanged =
      !isEqual(this.props.article, nextProps.article) ||
      !isEqual(this.props.training, nextProps.training) ||
      this.props.briefingType !== nextProps.briefingType

    return hasChanged
  }
  render() {
    const alreadyRelevant =
      this.props.training && this.props.training.label === 'relevant'
    const alreadyIrrelevant =
      this.props.training && this.props.training.label === 'irrelevant'

    return (
      <Article
        article={this.props.article}
        showActions={false}
        domainMenu={this.props.canBlock}
        onDomainMenu={this.props.onDomainMenu}
        customBottom={
          ['keywordsV2', 'sourcesV2'].includes(this.props.briefingType) ? (
            <div style={{ marginTop: '0.3rem' }}>
              <Button
                size="sm"
                variant={alreadyRelevant ? 'primary' : 'secondary'}
                className="mr-2"
                onClick={() => {
                  this.props.onTrainingChange(
                    alreadyRelevant ? null : 'relevant',
                  )
                }}
              >
                <i className="fa fa-chevron-up " />
              </Button>
              <Button
                size="sm"
                variant={alreadyIrrelevant ? 'primary' : 'secondary'}
                onClick={() => {
                  this.props.onTrainingChange(
                    alreadyIrrelevant ? null : 'irrelevant',
                  )
                }}
              >
                <i className="fa fa-chevron-down" />
              </Button>
            </div>
          ) : null
        }
      />
    )
  }
}
