import React from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

const PREFIX = process.env.NODE_ENV === 'production' ? 'https' : 'http'
const DOMAIN = process.env.NODE_ENV === 'production' ? 'com' : 'local'

@connect()
export default class LogoutPage extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.props
      .dispatch({
        type: 'LOAD',
        payload: {
          request: {
            method: 'post',
            url: '/auth/logout',
          },
        },
      })
      .then((response) => {
        const data = response.payload.data
        if (data.status === 'fail') {
          throw new SubmissionError({ _error: data.message })
        } else if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: null,
            teamId: null,
            jwt: data.token,
          })

          if (this.props.location.query.redirect) {
            browserHistory.push(this.props.location.query.redirect)
          } else {
            window.location.href = `${PREFIX}://auth.anderspink.${DOMAIN}`
          }
        }
      })
  }
  render() {
    return (
      <div>
        <p>Logging out...</p>
      </div>
    )
  }
}
