import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

class LoginForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
    buttonType: 'primary',
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Email address</label>
          <Field
            name="email"
            component="input"
            type="email"
            className="form-control"
            placeholder="Email address"
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <Field
            name="password"
            component="input"
            type="password"
            className="form-control"
            placeholder="Password"
          />
        </div>

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <button
          type="submit"
          className={`btn btn-${this.props.buttonType}`}
          disabled={submitting}
        >
          Login
        </button>
      </form>
    )
  }
}

export default reduxForm({
  form: 'login',
})(LoginForm)
