import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import pageStyles from 'content/pages/styles.css'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import EBookBanner from './components/EBookBanner/EBookBanner'
import Footer from './components/Footer/Footer'
import Button from './components/Button'
import HowItWorks from './components/HowItWorks/HowItWorks'
import Video from './components/Video/Video'
import styles from './CompleteCurationPage.module.css'
import DownloadPressRelease from './components/DownloadPressRelease'
import Testimonials from './components/Testimonials/Testimonials'

@connect((state) => ({
  app: state.app,
}))
export default class EnterpriseCompleteCurationPage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.outerBody}>
        <div className={styles.innerBody}>
          <main className={styles.home}>
            <EBookBanner bannerColor="white" externalLink={false} />
            <div className={styles.background1}>
              <Navbar
                backgroundColors="dark"
                pinkOrGreen="green"
                logoColor="white"
              />
              <Intro
                headerText="Helping you discover and curate the best content for your teams, mapped to the skills that matter"
                bullet1="Make sense of your learning content with advanced search"
                bullet2="Map content to skills, automatically"
                bullet3="Curate the best learning content from all content libraries"
                bullet4="Create the best learning pathways, fast"
                headerColor="white"
                bulletPointColor="white"
                bulletTextColor="white"
                homepage="complete-curation"
                withProductSheet={true}
              />
              <div className={styles.background1Inner}></div>
            </div>

            <div className={pageStyles.section}>
              <div className={pageStyles.sectionHeadingThin}>
                What is Complete Curation?
              </div>
              <div className={pageStyles.textContainer}>
                <p>
                  Complete Curation is an AI powered app that enables powerful
                  search, curation and tagging of content from any source:
                  internal libraries, your licensed content, or the web, at
                  speed and scale. Helping you to get a better return on your
                  content investment, and get access to the content that best
                  meets your needs, from anywhere.
                </p>
              </div>

              <div className={pageStyles.sectionHeadingThin}>
                Who needs Complete Curation?
              </div>
              <div className={pageStyles.textContainer}>
                <p>
                  Imagine having all of your content mapped to the skills your
                  organization needs.
                  <br />
                  <br />
                  Imagine being able to easily and quickly search all of your
                  learning content across all of your content libraries to find
                  and curate the best content for your learners, including
                  freemium content from the web.
                  <br />
                  <br />
                  Imagine being able to create learning pathways at speed,
                  curating the best content your organization owns in multiple
                  content libraries, and adding it quickly to your LMS or LXP,
                  tagged with skills.
                  <br />
                  <br />
                  Now you can, with Anders Pink Complete Curation
                </p>
              </div>

              <div className={pageStyles.sectionHeadingThin}>
                Complete Curation enables organizations to:
              </div>
              <div className={pageStyles.textContainer}>
                <p>
                  <b>Get organized:</b> automatically index and map all of your
                  learning content to the skills your organization needs, at
                  scale, at pace.
                </p>
                <p>
                  <b>Adopt a skills-first approach:</b> with content mapped to
                  skills, you’ll be able to quickly identify where content to
                  skills gaps lie, ensuring you can plug gaps quickly to upskill
                  and reskill your workforce.
                </p>
                <p>
                  <b>Save time:</b> easily and efficiently search all of your
                  learning content across all of your content libraries to find
                  the best content for your learners. No need to search
                  libraries individually or keep a log in spreadsheets. Complete
                  Curation saves 80% of time vs manual curation, meaning you can
                  curate content for a wider volume of skills, fast.
                </p>
                <p>
                  <b>Build the best learning solutions:</b> With advanced, NLP
                  techniques built into Complete Curation, finding and curating
                  content has never been easier. Simply search for content using
                  a skill, question or phrase to surface the best, most relevant
                  content within your learning libraries and from the web. Then
                  build meaningful, targeted learning pathways, mapped to the
                  skills your organization needs.
                </p>
                <p>
                  <b>Respond rapidly:</b> Create learning pathways at speed,
                  curating the best content your organization owns and adding it
                  quickly to your LMS or LXP, enabling you to respond rapidly to
                  learning requests from your teams and departments.
                </p>
                <p>
                  <b>Reduce cost:</b> Make better content buying decisions by
                  identifying content overlap and content gaps, as well as
                  retiring content that isn’t used.
                </p>
                <p>
                  <b>Get recommendations:</b> With all of your content in one
                  place, you’ll be able to get recommendations of similar
                  content so that you can curate the best learning pathways for
                  your teams.
                </p>
                <div style={{ textAlign: 'center' }}>
                  <Link to="/contact?reason=Book%20a%20demo">
                    <Button
                      title="Book a consultation"
                      backgroundColor="#CF0032"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <Testimonials withOldHeader={true} />
            <div style={{ textAlign: 'center' }}>
              <HowItWorks
                stepContent={[
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw1.png`,
                    headerText: '1. Connect',
                    bodyText:
                      'Share the data in your current content libraries (we’ll help you with this) to the Complete Curation app or connect your libraries via an API or connector (coming soon). Map the data in Complete Curation and within minutes see all of your content in one place, tagged with skills.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw2.png`,
                    headerText: '2. Search',
                    bodyText:
                      'Using advanced NLP techniques, Complete Curation understands the context of any search (skill, question or phrase) so you can rest assured that the most relevant learning assets are surfaced which best match your search term. ',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw3.png`,
                    headerText: '3. Curate',
                    bodyText:
                      'From your search results, choose learning assets to add to a “Curation”. You can refine your search based on duration, media type, publish date, provider, language and level. Or drill down on a specific content item to find other closely related content you own or from the web.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw7.png`,
                    headerText: '4. Collaborate',
                    bodyText:
                      'Optionally, invite subject matter experts to review and refine Curations. They can also add comments for the curator.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw8.png`,
                    headerText: '5. Go live',
                    bodyText:
                      'Add your “Curations” to lists or pathways in your learning platform, tagged with relevant skills. New learning pathways built within minutes.',
                  },
                  {
                    img: `${STATIC_DOMAIN}/pages/new-homepage/hiw9.png`,
                    headerText: 'Sit back and…',
                    bodyText:
                      'With all the time you will have saved, sit back and relax / have a coffee / build more Curations.',
                  },
                ]}
              />
            </div>
            <Video bgColor="pink" />
            <div className={pageStyles.section}>
              <div className={pageStyles.sectionHeadingThin}>
                More features and integrations coming soon!
              </div>
            </div>
            <DownloadPressRelease />
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}
