import React from 'react'
import { Field, reduxForm, SubmissionError, isDirty, reset } from 'redux-form'
import { graphql } from 'react-apollo'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import Select from 'react-select'
import { postRequest } from 'app/actions/appActions'
import FixedModal from 'app/components/FixedModal'
import * as microsoftTeams from '@microsoft/teams-js'
import LoginForm from 'authentication/containers/LoginForm'

import moment from 'moment-timezone'

@connect((state) => ({
  appQueryResult: state.app.appQueryResult,
  jwt: state.app.jwt,
}))
@graphql(
  gql`
    query briefings($forUserId: ID!) {
      briefings(forUserId: $forUserId) {
        id
        name
        teamId
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          forUserId: props.appQueryResult.user
            ? props.appQueryResult.user.id
            : null,
        },
      }
    },
  },
)
@graphql(
  gql`
    mutation ($msTeamsIntegration: MSTeamsIntegrationInput) {
      updateMSTeamsIntegration(msTeamsIntegration: $msTeamsIntegration)
    }
  `,
  {
    props: ({ mutate }) => ({
      updateMSTeamsIntegration: (msTeamsIntegration) =>
        mutate({
          variables: {
            msTeamsIntegration,
          },
        }),
    }),
  },
)
export default class ProfileMSTeamsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      context: null,
      webhookUrl: null,
      briefingIds: [],
      articleQuantity: '3',
      frequency: 'daily',
      time: '08:00',
      timeZone: 'Europe/London',
      valid: false,
      checked: false,
    }
  }
  onLoginSubmit = (values) => {
    return this.props
      .dispatch(
        postRequest('/auth/login', {
          email: values.email,
          password: values.password,
        }),
      )
      .then((response) => {
        const data = response.payload.data

        if (data.status === 'success') {
          this.props.dispatch({
            type: 'SET_AUTH',
            userId: data.userId,
            teamId: data.teamId,
            jwt: data.token,
          })
          window.location.href = '/ms-teams-configuration'
        } else {
          throw new SubmissionError({ _error: data.message })
        }
      })
  }
  componentDidUpdate(prevProps, prevState) {
    const valid = this.state.briefingIds.length > 0

    if (!this.state.valid && valid) {
      this.setState({ valid: true })
      microsoftTeams.settings.setValidityState(true)
    } else if (this.state.valid && !valid) {
      this.setState({ valid: false })
      microsoftTeams.settings.setValidityState(false)
    }

    if (
      !this.state.checked &&
      this.state.context &&
      this.props.appQueryResult.user
    ) {
      for (let msTeamsIntegration of this.props.appQueryResult.user
        .msTeamsIntegrations) {
        if (msTeamsIntegration.channelId === this.state.context.channelId) {
          this.setState({
            briefingIds: msTeamsIntegration.briefings.map((b) =>
              parseInt(b.id),
            ),
            articleQuantity: msTeamsIntegration.articleQuantity,
            frequency: msTeamsIntegration.frequency,
            time: msTeamsIntegration.time,
            timeZone: msTeamsIntegration.timeZone,
          })
        }
      }

      //this.state.context.channelId

      this.setState({
        checked: true,
      })
    }
  }
  componentDidMount() {
    // @todo Do a request to load from server
    // if it exists, we will update the local state
    // WAIT until this check is done

    microsoftTeams.initialize()

    microsoftTeams.getContext((context) => {
      this.setState({ context })
    })

    microsoftTeams.settings.registerOnRemoveHandler((removeEvent) => {
      removeEvent.notifySuccess()
    })

    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.settings.setSettings({
        // entityId: "anderspink",
        contentUrl: 'https://bb30ed5a.eu.ngrok.io/ms-teams-configuration',
        // configName: "anderspinkConfig",
        // suggestedDisplayName: "Anders Pink"
      })

      microsoftTeams.settings.getSettings((data) => {
        this.setState({
          webhookUrl: data.webhookUrl,
        })

        const submitData = {
          webhookUrl: data.webhookUrl,
          context: JSON.stringify(this.state.context),
          //context: '',
          briefingIds: this.state.briefingIds,
          articleQuantity: parseInt(this.state.articleQuantity),
          frequency: this.state.frequency,
          time: this.state.time,
          timeZone: this.state.timeZone,
          channelId: this.state.context.channelId,
          channelName: this.state.context.channelName,
          teamName: this.state.context.teamName,
        }

        // @todo save it using the mutation....

        // saveEvent.notifySuccess();

        this.props
          .updateMSTeamsIntegration(submitData)
          .then((r) => {
            saveEvent.notifySuccess()
          })
          .catch((err) => {
            console.log('err', err)

            saveEvent.notifySuccess()
          })
      })
    })
  }
  render() {
    const timeZones = moment.tz.names()

    if (
      !this.state.context ||
      !this.props.appQueryResult ||
      this.props.data.loading
    ) {
      return <div style={{ margin: '20px -30px' }}>Loading...</div>
    }

    // if (!this.props.appQueryResult || this.props.data.loading) {
    //     return <div>Loading (DEV)...</div>;
    // }

    if (!this.props.appQueryResult.user) {
      return (
        <div style={{ margin: '20px -30px' }}>
          <p>
            To continue, please log in to your existing Anders Pink account. If
            you don't have an account, please visit{' '}
            <a href="https://anderspink.com/" target="_blank">
              https://anderspink.com/
            </a>{' '}
            in your browser and sign up first.
          </p>

          <LoginForm onSubmit={this.onLoginSubmit} />

          <p style={{ color: '#999', marginTop: '2rem' }}>
            Signing in to your Anders Pink account is required to use this
            connector. We will pull in fresh articles from the Briefings on your
            account into this Teams channel every day.
          </p>
        </div>
      )
    }

    const { context } = this.state
    const { user } = this.props.appQueryResult

    const teamsById = user.teams.reduce((carry, team) => {
      carry[team.id] = team
      return carry
    }, {})

    const briefings = this.props.data.briefings.slice(0).sort((a, b) => {
      if (a.name.toLowerCase() === b.name.toLowerCase()) {
        return 0
      }
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    })

    const briefingOptions = briefings.map((briefing) => {
      let teamName = 'Public' // If its a public briefing from another team
      if (briefing.teamId == 1) {
        teamName = 'Personal'
      } else if (teamsById[briefing.teamId] !== undefined) {
        teamName = teamsById[briefing.teamId].name
      }
      return {
        value: briefing.id,
        label: `${briefing.name} (${teamName})`,
      }
    })

    return (
      <div style={{ margin: '20px -30px' }}>
        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
          Manage connector for "{context.channelName}" channel in "
          {context.teamName}" team
        </div>

        <p>
          We will post the top new articles from your briefings into this
          channel every day.
        </p>

        <div className="form-group">
          <label>1. Select some of your briefings</label>
          <Select
            simpleValue={true}
            multi={true}
            removeSelected={false}
            value={this.state.briefingIds}
            onChange={(selectedOption) => {
              this.setState({
                briefingIds: selectedOption.split(',').map((i) => parseInt(i)),
              })
            }}
            options={briefingOptions}
          />
        </div>
        <br />

        <div className="form-group">
          <label>
            2. Choose the maximum number of articles to send each day
          </label>
          <select
            className="form-control"
            value={this.state.articleQuantity}
            onChange={(e) => {
              this.setState({ articleQuantity: e.target.value })
            }}
          >
            {Array.from(Array(20).keys()).map((number) => {
              number++
              return (
                <option key={number} value={number}>
                  {number}
                </option>
              )
            })}
          </select>
        </div>
        <br />

        <div className="form-group">
          <label>3. Finally, pick what time of the day to send</label>

          <div style={{ display: 'flex' }}>
            <select
              className="form-control mr-2"
              style={{ maxWidth: '150px' }}
              value={this.state.time}
              onChange={(e) => {
                this.setState({ time: e.target.value })
              }}
            >
              {Array.from(Array(48).keys()).map((number) => {
                const time =
                  String(Math.floor(number / 2)).padStart(2, '0') +
                  `:${number % 2 === 0 ? 0 : 3}0`
                return (
                  <option key={time} value={time}>
                    {time}
                  </option>
                )
              })}
            </select>

            <select
              className="form-control"
              value={this.state.timeZone}
              onChange={(e) => {
                this.setState({ timeZone: e.target.value })
              }}
            >
              {timeZones.map((timeZone) => (
                <option key={timeZone} value={timeZone}>
                  {timeZone + ' (GMT' + moment.tz(timeZone).format('Z') + ')'}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )
  }
}
