exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__contact___2WZkW {\n  text-align: center;\n  max-width: 700px;\n  margin: 0 auto;\n  padding: 3rem 0 2rem 0;\n}\n.styles__contactTop___2jHyx {\n  font-size: 1.4rem;\n}\n.styles__contactBottom___Ty3py {\n  margin-top: 1rem;\n}\n\n.styles__table___CkapR {\n  margin: 1rem;\n}\n.styles__table___CkapR td {\n  padding: 1rem;\n  text-align: center;\n}\n.styles__tableBold___2zQJ1,\n.styles__tableBold___2zQJ1 td {\n  font-weight: bold;\n}\n\n.styles__technical___KhQuH {\n  background-image: url(/pages/landing/viewfinder-telescope.png);\n  background-position: 50% 78%;\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n\n  min-height: 270px;\n  position: relative;\n}\n.styles__technical___KhQuH:before {\n  position: absolute;\n  content: '';\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(11, 19, 27, 0.3);\n}\n.styles__technical___KhQuH button {\n  position: relative;\n}\n.styles__technicalTop___3WM6N {\n  font-size: 1.6rem;\n}\n.styles__technicalContent___J5vKp {\n  position: relative;\n  color: #ffffff;\n  max-width: 600px;\n  margin: 0 auto;\n  text-align: center;\n  padding: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"contact": "styles__contact___2WZkW",
	"contactTop": "styles__contactTop___2jHyx",
	"contactBottom": "styles__contactBottom___Ty3py",
	"table": "styles__table___CkapR",
	"tableBold": "styles__tableBold___2zQJ1",
	"technical": "styles__technical___KhQuH",
	"technicalTop": "styles__technicalTop___3WM6N",
	"technicalContent": "styles__technicalContent___J5vKp"
};