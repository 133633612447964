import React from 'react'

import PartnersListLogo from './PartnersListLogo'
import styles from './PartnersList.module.css'

const PartnersList = (props) => {
  return (
    <section className={styles.partners1}>
      <header className={styles.partners1Text}>{props.headerText}</header>
      <div className={styles.partners1Logos}>
        {props.logos.map((logo) => (
          <PartnersListLogo
            key={logo.id}
            name={logo.name}
            src={logo.src}
            style={logo.style}
          />
        ))}
      </div>
    </section>
  )
}

export default PartnersList
