import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form'

import AdminTable from 'admin/components/AdminTable'

@reduxForm({
  form: 'adminTools',
  initialValues: {
    name: '',
  },
})
export default class AdminToolsPage extends React.Component {
  render() {
    const onSubmit = () => {}

    const { error, handleSubmit, submitting } = this.props

    return (
      <div>
        <h3>Admin tools</h3>

        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a className="nav-link active" href="#">
              Briefing copy
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Team copy
            </a>
          </li>
        </ul>

        <div style={{ padding: '2rem 0' }}>
          <div>
            <strong>Select briefings (0 selected)</strong>
          </div>
          <p>
            Search for briefings by their name, the team they’re in. Select as
            many briefings as wanted
          </p>

          <input type="text" nam />

          <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
            <div className="form-group">
              <label>Team or briefing name</label>
              <Field
                name="name"
                component="input"
                type="text"
                className="form-control"
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}
