import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { SubmissionError } from 'redux-form'
import axios from 'axios'
import { browserHistory } from 'react-router'

@reduxForm({
  form: 'createOrganisation',
})
@graphql(
  gql`
    mutation($name: String) {
      createOrganisation(name: $name) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createOrganisation: (name) =>
        mutate({
          variables: { name },
        }),
    }),
    options: {
      refetchQueries: ['appQuery'],
    },
  },
)
@connect((state) => ({
  user: state.app.appQueryResult.user,
}))
export default class CreateOrganisationPage extends React.Component {
  onSubmit = (values) => {
    return this.props
      .createOrganisation(values.name)
      .then((response) => {
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `Succesfully created new organisation`,
        })

        browserHistory.push(
          `/organisation/${response.data.createOrganisation.id}/`,
        )
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
      <div>
        <h5>Create new organisation</h5>
        <br />

        <p>
          Create an organisation to represent your company. You can create
          multiple teams within your organisation, each with their own members,
          briefings, and saved folders.
        </p>

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="form-group">
            <label>Name</label>
            <Field
              name="name"
              style={{ maxWidth: '300px' }}
              component="input"
              type="text"
              className="form-control"
              placeholder="e.g. company or group name"
            />
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <button className="btn btn-primary">Create</button>{' '}
          <button
            className="btn btn-secondary"
            onClick={() => {
              browserHistory.push('/home')
            }}
          >
            Cancel
          </button>
        </form>
      </div>
    )
  }
}
