exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__option___3de3m {\n  text-align: center;\n  padding: 10px 0;\n  border: 1px solid #ccc;\n  margin-bottom: 10px;\n}\n\n.styles__icons___YVLlN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: -1rem;\n}\n\n.styles__iconImage___1fT9r {\n  max-width: 100%;\n  margin: 1rem;\n  width: 140px;\n}\n\n.styles__iconImage___1fT9r img {\n  max-height: 45px;\n  max-width: 100%;\n}\n\n.styles__quotes___m6QQG {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin: -1rem;\n}\n.styles__quote___3MXmV {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  margin: 2rem 1rem;\n}\n\n.styles__section___3BBqY {\n  margin: 3rem auto;\n}\n\n.styles__header___9lIv7 {\n  background-color: #333333;\n  background-image: -webkit-gradient(\n    linear,\n    right top, left bottom,\n    from(#333333),\n    color-stop(#3b3b3b),\n    color-stop(#444444),\n    color-stop(#4c4c4c),\n    to(#555555)\n  );\n  background-image: linear-gradient(\n    to left bottom,\n    #333333,\n    #3b3b3b,\n    #444444,\n    #4c4c4c,\n    #555555\n  );\n  color: #ffffff;\n  text-align: center;\n  padding: 5rem 0;\n  margin-bottom: 2rem;\n}\n\n.styles__container___Xz3E9 {\n  max-width: 750px;\n  margin: 0 auto;\n}\n\n.styles__profilePic___1TB6p {\n  max-width: 80px;\n  border-radius: 50%;\n  display: block;\n  margin-top: 1rem;\n  border: #f8f8f8 1px solid;\n}\n\n.styles__users___2nSqI {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.styles__users___2nSqI > div {\n  width: 45%;\n}\n", ""]);
// Exports
exports.locals = {
	"option": "styles__option___3de3m",
	"icons": "styles__icons___YVLlN",
	"iconImage": "styles__iconImage___1fT9r",
	"quotes": "styles__quotes___m6QQG",
	"quote": "styles__quote___3MXmV",
	"section": "styles__section___3BBqY",
	"header": "styles__header___9lIv7",
	"container": "styles__container___Xz3E9",
	"profilePic": "styles__profilePic___1TB6p",
	"users": "styles__users___2nSqI"
};