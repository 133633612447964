import React from 'react'
import { browserHistory } from 'react-router'
import compose from 'recompose/compose'
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { SubmissionError } from 'redux-form'
import { connect } from 'react-redux'

import ListStatsItem from '../components/ListStatsItem'

const GET_STATS_FOR_DIGEST = gql`
  query StatsForNewsletter($id: ID!) {
    StatsForNewsletter(id: $id) {
      id
      createdAt
      sent
      openRatio
      clickRatio
    }
    NewslettersById(id: $id) {
      id
      teamId
    }
  }
`

const EXPORT_DIGEST = gql`
  mutation exportSingleNewsletter($id: ID) {
    exportSingleNewsletter(id: $id)
  }
`

let StatsDigestPage = (props) => {
  const { StatsForNewsletter, NewslettersById, error, loading, refetch } =
    props.StatsForNewsletter

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  const handleOnExport = (id) => {
    props
      .exportSingleNewsletter({
        variables: {
          id: id,
        },
      })
      .then(({ data }) => open(data.exportSingleNewsletter, '_blank'))
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }

  return (
    <div>
      <h5>Digest Stats for ID: {props.params.id}</h5>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Sent Date</th>
            <th scope="col">Total Sent</th>
            <th scope="col">Open Rate</th>
            <th scope="col">Click-to-open Rate</th>
            <th scope="col">More Info</th>
          </tr>
        </thead>
        <tbody>
          {StatsForNewsletter.map((item, index) => (
            <ListStatsItem
              key={index}
              item={item}
              viewLink={`/newsletters/${props.params.id}/stats/${item.id}`}
              usageLink={`/team-api-stats/${NewslettersById.teamId}?digest=${props.params.id}&send=${item.id}&source=digests`}
            />
          ))}
        </tbody>
      </table>

      {StatsForNewsletter.length > 0 && (
        <a
          href={`/team-digest-export/${props.params.id}/${props.jwt}`}
          target="_blank"
          className="btn btn-sm btn-primary ml-1"
        >
          Export CSV
        </a>
      )}

      <button
        type="button"
        className="btn btn-sm btn-secondary ml-1"
        onClick={() => browserHistory.goBack()}
      >
        Cancel
      </button>
    </div>
  )
}

StatsDigestPage = compose(
  withApollo,
  graphql(GET_STATS_FOR_DIGEST, {
    name: 'StatsForNewsletter',
    options: ({ params }) => ({
      variables: { id: params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(EXPORT_DIGEST, {
    name: 'exportSingleNewsletter',
  }),
  connect((state) => ({
    jwt: state.app.jwt,
  })),
)(StatsDigestPage)

export default StatsDigestPage
