import React, { Component } from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import FixedModal from 'app/components/FixedModal'
import sourceTypes, { renderSourceString } from 'briefings/config/sourceTypes'
import PreviewSource from 'briefings/create/containers/PreviewSource'
import FoundSources from 'briefings/create/containers/FoundSources'
import validator from 'validator'

import styles from './EditBriefingForm.css'
export default class AddSourceModal extends Component {
  static defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
    change: () => {},
    onAddSource: (source) => {},
    hideTwitter: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      findingSourcesValue: null,
      previewedSource: null,
      addedSource: false,
      sourceError: null,

      selectedAddSourceType: 'domain',
      sourceValue: '',
      sourceSearchValue: '',
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      // Wipe the form and state..
      this.setState({
        sourceSearchValue: '',
        sourceValue: '',
        findingSourcesValue: null,
        previewedSource: null,
      })
    }
  }
  doPreview() {
    if (
      this.state.selectedAddSourceType === 'domain' &&
      !validator.isURL(this.state.sourceValue, {
        protocols: [],
        require_protocol: false,
        allow_underscores: true,
      })
    ) {
      this.setState({
        sourceError:
          'Please enter a valid domain (without the http or www), e.g. forbes.com',
      })
      return
    }
    if (
      this.state.selectedAddSourceType === 'twitterUser' &&
      !validator.matches(this.state.sourceValue, /^(\w){1,15}$/)
    ) {
      this.setState({
        sourceError:
          'Please enter a valid twitter handle, without the @, e.g. nytimes',
      })
      return
    }
    if (
      this.state.selectedAddSourceType === 'rssFeed' &&
      !validator.isURL(this.state.sourceValue, {
        protocols: ['http', 'https'],
        require_protocol: true,
        allow_underscores: true,
      })
    ) {
      this.setState({
        sourceError:
          'Please enter a valid RSS feed url, e.g. http://feeds.bbci.co.uk/news/uk/rss.xml?edition=uk',
      })
      return
    }

    this.setState({
      sourceError: null,
      findingSourcesValue: null,
      previewedSource: {
        type: this.state.selectedAddSourceType,
        value: this.state.sourceValue,
      },
    })
  }
  render() {
    return (
      <FixedModal
        size="full"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        buttons={[
          <button
            className="btn btn-sm btn-secondary"
            onClick={this.props.onRequestClose}
          >
            Close and continue
          </button>,
        ]}
      >
        <div className={styles.modal}>
          <div className={styles.modalLeft}>
            <div className={styles.modalGroup}>
              <h4>Add source</h4>
              <div className="form-group">
                <label>What type of source do you want to add?</label>
                <div>
                  {Object.keys(sourceTypes)
                    .filter(
                      (s) => s !== 'twitterUser' || !this.props.hideTwitter,
                    )
                    .map((sourceType, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          this.setState({
                            selectedAddSourceType: sourceType,
                          })

                          this.setState({
                            sourceError: null,
                          })
                        }}
                        className={
                          this.state.selectedAddSourceType === sourceType
                            ? styles.activeNewSourceType
                            : styles.newSourceType
                        }
                      >
                        {renderSourceString(sourceType)}
                      </div>
                    ))}
                </div>
                <p className={styles.sourceDescription}>
                  {sourceTypes[this.state.selectedAddSourceType].description}
                </p>

                <div className="form-group" style={{ marginTop: '30px' }}>
                  <label>
                    {sourceTypes[this.state.selectedAddSourceType].label}
                  </label>

                  <div className="form-inline">
                    <input
                      type="text"
                      value={this.state.sourceValue}
                      onChange={(e) =>
                        this.setState({ sourceValue: e.target.value })
                      }
                      style={{ width: '300px' }}
                      className="form-control mb-2 mr-sm-2 mb-sm-0"
                      placeholder={
                        sourceTypes[this.state.selectedAddSourceType]
                          .placeholder
                      }
                      onKeyPress={(e) => {
                        if (e.key == 'Enter') {
                          this.doPreview()
                        }
                      }}
                    />
                    <button
                      className="btn btn-secondary mb-2 mr-sm-2 mb-sm-0"
                      onClick={() => {
                        this.doPreview()
                      }}
                    >
                      Preview source
                    </button>{' '}
                    <button
                      className="btn btn-primary mb-2 mr-sm-2 mb-sm-0"
                      onClick={() => {
                        if (
                          this.state.selectedAddSourceType === 'domain' &&
                          !validator.isURL(this.state.sourceValue, {
                            protocols: [],
                            require_protocol: false,
                            allow_underscores: true,
                          })
                        ) {
                          this.setState({
                            sourceError:
                              'Please enter a valid domain (without the http or www), e.g. forbes.com',
                          })
                          return
                        }
                        if (
                          this.state.selectedAddSourceType === 'twitterUser' &&
                          !validator.matches(
                            this.state.sourceValue,
                            /^(\w){1,15}$/,
                          )
                        ) {
                          this.setState({
                            sourceError:
                              'Please enter a valid twitter handle, without the @, e.g. nytimes',
                          })
                          return
                        }
                        if (
                          this.state.selectedAddSourceType === 'rssFeed' &&
                          !validator.isURL(this.state.sourceValue, {
                            protocols: ['http', 'https'],
                            require_protocol: true,
                            allow_underscores: true,
                          })
                        ) {
                          this.setState({
                            sourceError:
                              'Please enter a valid RSS feed url, e.g. http://feeds.bbci.co.uk/news/uk/rss.xml?edition=uk',
                          })
                          return
                        }

                        this.props.onAddSource({
                          type: this.state.selectedAddSourceType,
                          value: this.state.sourceValue,
                        })

                        this.setState({
                          sourceValue: '',
                          sourceError: null,
                          addedSource: true,
                        })
                        setTimeout(() => {
                          this.setState({
                            addedSource: false,
                          })
                        }, 3000)

                        //this.props.onRequestClose();
                      }}
                    >
                      Add source
                    </button>
                  </div>
                  {this.state.addedSource && (
                    <div
                      style={{ marginTop: '10px' }}
                      className="alert alert-success"
                    >
                      Added source to briefing. When you're finished adding
                      sources, close this window to finish your briefing set up
                      and filter sources by keywords if you want.
                    </div>
                  )}

                  {this.state.sourceError !== null && (
                    <div
                      style={{ marginTop: '10px' }}
                      className="alert alert-warning"
                    >
                      {this.state.sourceError}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.modalGroup}>
              <h4>See suggested sources</h4>
              <div className="form-group">
                <label>
                  Enter a topic and we'll suggest some relevant sources
                </label>

                <div className="form-inline">
                  <input
                    type="text"
                    value={this.state.sourceSearchValue}
                    onChange={(e) =>
                      this.setState({ sourceSearchValue: e.target.value })
                    }
                    style={{ width: '300px' }}
                    className="form-control mb-2 mr-sm-2 mb-sm-0"
                    placeholder="e.g. big data, healthcare, cycling..."
                    onKeyPress={(e) => {
                      if (e.key == 'Enter') {
                        this.setState({
                          findingSourcesValue: this.state.sourceSearchValue,
                          previewedSource: null,
                        })
                      }
                    }}
                  />

                  <button
                    className="btn btn-secondary mb-2 mr-sm-2 mb-sm-0"
                    onClick={() => {
                      this.setState({
                        findingSourcesValue: this.state.sourceSearchValue,
                        previewedSource: null,
                      })
                    }}
                  >
                    Find sources
                  </button>
                </div>
              </div>

              {this.state.findingSourcesValue !== null && (
                <FoundSources
                  keyword={this.state.findingSourcesValue}
                  onAddSource={(source) => {
                    this.props.onAddSource(source)
                  }}
                  onPreviewSource={(source) => {
                    this.setState({
                      previewedSource: source,
                    })
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.modalRight}>
            {this.state.previewedSource !== null && (
              <PreviewSource
                source={this.state.previewedSource}
                keywords={[this.state.findingSourcesValue].filter(Boolean)}
              />
            )}
          </div>
        </div>
      </FixedModal>
    )
  }
}
