import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import compose from 'recompose/compose'

import SourceFiltersEdit from './SourceFiltersEdit'
import styles from './SourceFilters.css'

const urlRegex =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/

const QUERIES = {
  SESSION: 'session_query',
  ACCOUNT_DOMAIN_LISTS: 'account_domain_lists_query',
}
@compose(
  graphql(
    gql`
      query teamDomainListsQuery {
        session {
          team {
            id
            name
            canUsersEditDomainLists
            domainLists {
              id
              listName
              listType
              owner
            }
          }
          isTeamAdmin
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'cache-and-network',
      }),
      name: QUERIES.SESSION,
    },
  ),
  graphql(
    gql`
      query accountDomainListsQuery($accountId: ID!) {
        account(id: $accountId) {
          id
          domainLists {
            id
            listName
            listType
          }
        }
      }
    `,
    {
      options: (props) => ({
        variables: {
          accountId: props.accountId,
        },
        fetchPolicy: 'cache-and-network',
      }),
      skip: (props) => {
        return !props.accountId
      },
      name: QUERIES.ACCOUNT_DOMAIN_LISTS,
    },
  ),
  graphql(
    gql`
      mutation ($domainList: DomainListInput!) {
        updateDomainList(domainList: $domainList) {
          id
          listName
          listType
          items {
            id
            domain
          }
        }
      }
    `,
    {
      props: ({ mutate }) => ({
        updateDomainList: (domainList) =>
          mutate({
            variables: { domainList },
          }),
      }),
    },
  ),
  graphql(
    gql`
      mutation ($domainList: DomainListInput!) {
        createTeamDomainList(domainList: $domainList)
      }
    `,
    {
      props: ({ mutate }) => ({
        createTeamDomainList: (domainList, teamId) =>
          mutate({
            variables: { domainList, teamId },
          }),
      }),
    },
  ),
  graphql(
    gql`
      mutation ($domainList: DomainListInput!, $accountId: ID!) {
        createAccountDomainList(domainList: $domainList, accountId: $accountId)
      }
    `,
    {
      props: ({ mutate }) => ({
        createAccountDomainList: (domainList, accountId) =>
          mutate({
            variables: { domainList, accountId },
          }),
      }),
    },
  ),
  graphql(
    gql`
      mutation ($id: ID!) {
        deleteTeamDomainList(id: $id)
      }
    `,
    {
      props: ({ mutate }) => ({
        deleteTeamDomainList: (id) =>
          mutate({
            variables: { id },
            refetchQueries: ['appQuery'],
          }),
      }),
    },
  ),
  graphql(
    gql`
      mutation ($id: ID!, $accountId: ID) {
        deleteAccountDomainList(id: $id, accountId: $accountId)
      }
    `,
    {
      props: ({ mutate }) => ({
        deleteAccountDomainList: (id, accountId) =>
          mutate({
            variables: { id, accountId },
            refetchQueries: ['appQuery'],
          }),
      }),
    },
  ),
  connect(),
)
export default class SourcesFiltersPage extends React.Component {
  constructor() {
    super()
    this.state = { selectedId: null }
  }
  onListChange = ({ currentTarget: { value } }) => {
    const selectedId = value && value !== 'null' ? value : null
    this.setState({ selectedId })
  }
  getDomainLists = () => {
    if (this.props.isAccountView) {
      const { account: { domainLists = [] } = {} } =
        this.props[QUERIES.ACCOUNT_DOMAIN_LISTS]
      return domainLists
    } else {
      const {
        session: { team: { domainLists: teamDomainListsAll = [] } = {} } = {},
      } = this.props[QUERIES.SESSION]
      const domainLists = teamDomainListsAll.filter(
        ({ owner }) => owner === 'team',
      )
      return domainLists
    }
  }
  componentDidUpdate() {
    const domainLists = this.getDomainLists()
    const { forceSelectId } = this.state
    if (
      forceSelectId === null ||
      (forceSelectId && domainLists.some(({ id }) => forceSelectId === id))
    ) {
      this.setState({ forceSelectId: undefined, selectedId: forceSelectId })
    }
  }
  getCreateParams = (isAccountView) => {
    const createFunction = isAccountView
      ? this.props.createAccountDomainList
      : this.props.createTeamDomainList
    const { accountId } = this.props
    const key = isAccountView
      ? 'createAccountDomainList'
      : 'createTeamDomainList'
    return isAccountView
      ? { createFunction, accountId, key }
      : { createFunction, key }
  }
  getQueryName = () =>
    this.props.isAccountView ? QUERIES.ACCOUNT_DOMAIN_LISTS : QUERIES.SESSION
  createList = (domainlist) => {
    const { createFunction, accountId, key } = this.getCreateParams(
      this.props.isAccountView,
    )
    return createFunction(domainlist, accountId).then(
      ({ data: { [key]: result } }) => {
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `New list created`,
        })
        this.setState({ forceSelectId: result })
        this.props[this.getQueryName()].refetch()
      },
    )
  }
  deleteList = (id, onDone) => {
    const accountId = this.props.accountId || null
    const deleteFunc = this.props.isAccountView
      ? 'deleteAccountDomainList'
      : 'deleteTeamDomainList'
    return this.props[deleteFunc](id, accountId)
      .then(() => {
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `List deleted`,
        })
        this.setState({ forceSelectId: null })
      })
      .catch((err) => {
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          level: 'danger',
          content: `Something went wrong! ${err.message}`,
        })
      })
      .finally(() => {
        onDone && onDone()
        this.props[this.getQueryName()].refetch()
      })
  }
  updateList = (domainList) => {
    return this.props.updateDomainList(domainList).then(
      ({
        data: {
          updateDomainList: { id },
        },
      }) => {
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `List updated`,
        })
        this.props[this.getQueryName()].refetch()
      },
    )
  }
  render() {
    const {
      error,
      handleSubmit,
      submitting,
      isAccountView,
      [QUERIES.SESSION]: { session = {} },
    } = this.props

    const domainLists = this.getDomainLists()
    const { selectedId } = this.state

    let canManageDomainLists = isAccountView
    if (!isAccountView) {
      const {
        team: { canUsersEditDomainLists = false } = {},
        isTeamAdmin = false,
      } = session
      canManageDomainLists = isTeamAdmin || canUsersEditDomainLists
    }
    return !canManageDomainLists ? (
      <div>You do not have permission to view this page.</div>
    ) : (
      <div>
        <h5>
          Create or Edit Allow/Blocklists for this{' '}
          {isAccountView ? 'Account' : 'Team'}
        </h5>
        <br />

        <div className={styles.sourceFiltersEditContainer}>
          <div className="form-group">
            <div>
              <label>Create a new list or select one to edit:</label>
              <select
                name="listName"
                style={{ maxWidth: '300px' }}
                component="select"
                className="form-control"
                onChange={this.onListChange}
                value={selectedId}
              >
                <option value="null">Create new</option>
                {domainLists.map(({ id, listName, listType }) => (
                  <option key={id} value={id}>
                    {listName} ({listType === 'allow' ? 'allow' : 'block'})
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <SourceFiltersEdit
              key={selectedId}
              selectedId={selectedId}
              domainLists={domainLists}
              createList={this.createList}
              updateList={this.updateList}
              deleteList={this.deleteList}
            />
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
      </div>
    )
  }
}
