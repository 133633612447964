import React, { Component } from 'react'
import { connect } from 'react-redux'

@connect()
export default class ToolTip extends Component {
  render() {
    return (
      <span
        style={{
          backgroundColor: '#d8d8d8',
          borderRadius: '50%',
          width: '14px',
          height: '14px',
          fontSize: '10px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '0.2rem',
          cursor: 'pointer',
          fontWeight: 'bold',
          position: 'relative',
          top: '-3px',
        }}
        onClick={() => {
          this.props.onClick
            ? this.props.onClick()
            : this.props.dispatch({
                type: 'SET_APP_MODAL',
                modal: this.props.children,
              })
        }}
      >
        ?
      </span>
    )
  }
}
