import React from 'react'
import Carousel from 'react-elastic-carousel'
import styles from '../Home.module.css'
import componentStyle from '../Components.module.css'

const CLIENTS = [
  {
    name: '5app',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/5app.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'Video Arts ',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/videoarts.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'd2l',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/d2l.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'degreed',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/degreed.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'docebo',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/docebo.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'fuse',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/fuse.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'learnamp',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/learnamp.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'blend',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/blend.png`,
    styles: { width: 235, height: 192 },
  },
  {
    name: 'enterpriseireland',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/enterpriseireland.png`,
    styles: { width: 227, height: 200 },
  },
  {
    name: 'futureworkplace',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/futureworkplace.png`,
    styles: { width: 227, height: 200 },
  },
  {
    name: 'airfrance',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/airfrance.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'capgemini',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/capgemini.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'avon',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/avon.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'sodexo',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/sodexo.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'scania',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/scania.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'chalhoub',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/chalhoub.png`,
    styles: { width: 227, height: 192 },
  },
  {
    name: 'hitachi',
    url: `${STATIC_DOMAIN}/pages/new-landingpage/hitachi.png`,
    styles: { width: 227, height: 192 },
  },
]

const PartnersCarousel = () => {
  let paginationAndArrows = false

  if (CLIENTS.length > 5) {
    paginationAndArrows = true
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 650, itemsToShow: 3 },
    { width: 800, itemsToShow: 3 },
    {
      width: 1000,
      itemsToShow: 4,
      pagination: paginationAndArrows,
      showArrows: paginationAndArrows,
    },
  ]

  return (
    <div>
      <div className={styles.content}>
        <p style={{ display: 'flex' }}>
          <span className={`${styles.line} ${styles.backgroundGreen}`}>
            &nbsp;
          </span>
          <span className={styles.title}>
            Partnering with the world’s
            <br />
            leading learning platforms and enterprises
          </span>
        </p>
      </div>
      <Carousel
        className={componentStyle.carousel}
        breakPoints={breakPoints}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
      >
        {CLIENTS.map((client, index) => (
          <img
            key={index}
            src={client.url}
            alt={client.name}
            style={client.styles}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default PartnersCarousel
