import React, { useState } from 'react'
import { Link } from 'react-router'

import ReactMarkdown from 'react-markdown'

import styles from './SupportPage.module.css'
import pageStyles from 'content/pages/styles.css'

const faqs = [
  {
    question: 'What is a briefing?',
    answer: `A briefing is a feed of content from the web based on the settings defined by you, the curator, or a system, using our API, on topics, sites and sources you specify to curate content from.`,
    types: ['new'],
    related: [
      'How to create a briefing?',
      'What is a topic?',
      'How to train a briefing?',
      'How to save and edit a briefing?',
    ],
  },
  {
    question: 'What is a topic?',
    answer: `
  A topic is the theme of the briefing you are creating. For example, you are curating content on leadership or big data etc. the topics are how we first filter content from the web.
  \n
  A topic can be any word or phrase. You can add multiple topics and we’ll suggest related terms.
  Tip: We recommend combining your topics into one search query, like this “AI in HR” or “leadership communication” if you want content that mentions multiple topics in each article. Otherwise, you can add them separately.
    `,
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to create a briefing?',
      'How to train a briefing?',
      'How to save and edit a briefing?',
    ],
  },
  {
    question: 'How to login and navigate to a team?',
    answer: `
  Once you have created an account and logged in you will need to change from your person view to your team view by selecting the dropdown on top left that will say 'Personal'. By selecting this dropdown you will then see a list of all the teams you are included in. Select which team you which to view from the list.
    `,
    videoSrc: 'https://www.loom.com/embed/a2dd4fed06494bb6aff869813519223e',
    types: ['new'],
    related: [
      'How to access all of the briefings in your team?',
      'What is an account/account view?',
      'How to access your account view?',
      'How to create a briefing?',
    ],
  },
  {
    question:
      'How to convert your current briefings to the new simple briefing type?',
    answer: `
  Once you have navigated to the briefing you wish to convert you'll then want to select the cog icon and then 'edit briefing'.
  \n
  You will then see the toggle next to the option for 'new briefing type', by selecting this toggle you'll convert this briefing from the old briefing type to the new briefing type.
  \n
  IMPORTANT INFORMATION - When you convert an old briefing to a new briefing type the topics and training will not transfer over, this option of converting the briefing is simply to keep the briefing ID and any integration intact. To ensure any links or integrations/plugins don't break.
    `,
    videoSrc: 'https://www.loom.com/embed/6823ecf1a9dd4f10a3a86637674b332c',
    types: ['new'],
    related: [
      'How to create a briefing?',
      'What is a topic?',
      'How to train a briefing?',
      'How to save and edit a briefing?',
    ],
  },
  {
    question: 'What is an account/account view?',
    answer: `
  The account view is where you can see all the teams that exist within your account.
  \n
  You could use this page to create new teams, update existing ones, or delete them altogether.
  \n
  Please note that not all users have access to this page—only those with admin rights can view this screen.
  `,
    videoSrc: 'https://www.loom.com/embed/b4e6203f8f174266a6d3e63f0ff3029a',
    types: ['new'],
    related: [
      'How to access your account view?',
      'How to create a briefing?',
      'How to add users to my Anders Pink team?',
      'What are the export settings and how do I use them?',
    ],
  },
  {
    question: 'How to create a briefing?',
    answer: `
  Once you have navigated to the team you wish to create the briefings within (see 'How to login and navigate to a team?') you will want to select the 'Create Briefing' button within the left hand navigation menu.
  \n
  Once you selected the 'Create Briefing' option you'll then want to start by inputting your topic to get started. Read 'How to train a briefing' for the next steps.
  `,
    videoSrc: 'https://www.loom.com/embed/3a4c7b15cc924db3bc1cd24ace0a89be',
    types: ['new'],
    related: [
      'What is a briefing?',
      'What is a topic?',
      'How to train a briefing?',
    ],
  },
  {
    question: 'How to train a briefing?',
    answer: `
  Training a briefing is really important as it ensures the that the AI that sits behind the briefing understands the context, categories and audience for which you have built the briefing.
  \n
  You will initially train your briefing whilst in the create briefing stage by using the up and down vote arrows on each article. Upvoting any articles that are most relevant and down voting any that aren't as relevant.
  \n
  To do any additional training after you've saved the briefing you will need to select the cog icons, then select 'edit briefing'. Use the upvote and downvote arrows as used initially.
  `,
    videoSrc: 'https://www.loom.com/embed/165efa2dc2dd4d8d9db0dd7a03cc556b',
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to save and edit a briefing?',
      'How to access all of the briefings in your team?',
    ],
  },
  {
    question: 'How to save and edit a briefing?',
    answer: `
  Briefings can easily be edited at any time. This allows topics can be added or removed, and training can be updated to reflect the focus of the briefing.
  \n
  To make edits, select the briefing you would lie to change. Navigate to the cog icon and click to access the drop-down menu. Select Edit Briefing. This loads the update briefing screen. Make any amendments and click save.
    `,
    videoSrc: 'https://www.loom.com/embed/4cd437a9b7524730b69fe2e35d477323',
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to train a briefing?',
      'How to access all of the briefings in your team?',
    ],
  },
  {
    question: 'How to access all of the briefings in your team?',
    answer: `
  You can easily switch between teams you are a member of by using the drop-down menu on the top left of the screen.
  \n
  The briefings list will now show all of the available briefings within that team. Click "see more" to expand the list.
  \n
  You can also create a new briefing by clicking on "Create new briefing"
    `,
    videoSrc: 'https://www.loom.com/embed/4b6cb98d69774132a516e7bd3cf32268',
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to add users to my Anders Pink team?',
      'What is an account/account view?',
      'How to train a briefing?',
    ],
  },
  {
    question: 'How to access your account view?',
    answer: `
  When you first log in, select the account that you want to work on. In order to do this, go to the top right corner of the screen and click on the drop-down with your name on it.
  \n
  This will open a list of all of your accounts. Clicking on any one of them will take you to that individual account's view.
  \n
  On this screen, you can update teams, set limits, change your team, or delete it. You can also create new teams within your account by clicking "Create new team"
    `,
    videoSrc: 'https://www.loom.com/embed/dd5f4a1fa6cf4ad1b07aa33d616b235a',
    types: ['new'],
    related: [
      'What is an account/account view?',
      'How to add users to my Anders Pink team?',
      'How to make my briefings and saved folders publicly available or exportable via the API?',
    ],
  },
  {
    question: 'How to add users to my Anders Pink team?',
    answer: `
  There are two methods to access the team update screen:
  \n
  1. Clicking the drop-down box at the top right of the screen and selecting the account you wish to edit. Navigate to the team you wish to alter.
  \n
  Select "Update your team" to bring up the list of current team members.
  \n
  2. Switch teams using the view on the top left. Select "Team settings" in the left control panel.
  \n
  Once you are in the update team area:
  \n
  Enter the new team members' email address in the box and chose the user level (Normal/admin). If they are new to Anders Pink this will send an invite with instructions for them to sign in. Existing users are added automatically.
    `,
    videoSrc: 'https://www.loom.com/embed/c264303cf97b46da99ad42d51c5a8972',
    types: ['new'],
    related: [
      'How to login and navigate to a team?',
      'How to access all of the briefings in your team?',
      'How to access and interpret the usage analytics?',
    ],
  },
  {
    question: 'How do I use the manage sites feature?',
    answer: `
  At a briefing level, the Manage sites feature enables you to add allow and block lists to your briefing, ensuring that you control where content does and doesn't come from.
  \n
  In addition to this, you can also view sites that you may like to add to an allow or block list you have already applied to the briefing. The sites shown are sites which have published content related to the topic on which you are curating content.
  \n
  Alternatively, you can build a new allow or block list using the sites listed.
  `,
    videoSrc: 'https://www.loom.com/embed/cbc449a23ae24dbab7b5033f9df1a0ea',
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to create an allow and block list of sites for a briefing?',
      'How to create an allow and block list of sites for a team level?',
      'How to create an allow and block list at an account level?',
    ],
  },
  {
    question: 'How to create an allow and block list of sites for a briefing?',
    answer: `
  Creating an allow list and applying it to a briefing means that content will be surfaced only from the list of websites that you have "allowed". This provides an element of control to your briefing.
  \n
  Creating a block list and applying it to a briefing means that content will not be surfaced from the list of websites you have added to the block list.
  \n
  Watch the video to see how to create allow and block lists.
  `,
    videoSrc: 'https://www.loom.com/embed/223df8b3f283449a82666130571ba1f7',
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to train a briefing?',
      'How to create an allow and block list of sites for a team level?',
      'How to create an allow and block list at an account level?',
    ],
  },
  {
    question:
      'How to create an allow and block list of sites for a team level?',
    answer: `
  Creating an allow list and applying it to a briefing means that content will be surfaced only from the list of websites that you have "allowed". This provides an element of control to your briefing.
  \n
  Creating a block list and applying it to a briefing means that content will not be surfaced from the list of websites you have added to the block list.
  \n
  Creating allow and block lists at a team level means that the lists can be applied to all briefings created within that team, saving time creating new lists for each briefing.
  \n
  Watch the video to see how to create allow and block lists at a team level.
  `,
    videoSrc: 'https://www.loom.com/embed/19e572395eff4e5cb52271842c0d1ac8',
    types: ['new'],
    related: [
      'What is a briefing?',
      'How to train a briefing?',
      'How to create an allow and block list of sites for a briefing?',
      'How to create an allow and block list at an account level?',
    ],
  },
  {
    question: 'How to create an allow and block list at an account level?',
    answer: `
Creating an allow list and applying it to a briefing means that content will be surfaced only from the list of websites that you have "allowed". This provides an element of control to your briefing.
\n
Creating a block list and applying it to a briefing means that content will not be surfaced from the list of websites you have added to the block list.
\n
Creating allow and block lists at an account level means that the list is available to all teams within the account. So you don't have to create separate block and allow lists for each team and each briefing - a big time saver!
\n
Watch the video to see how to create allow and block lists at an account level.
`,
    videoSrc: 'https://www.loom.com/embed/20d69f22a9a347c8b7e62330d58aff9f',
    types: ['new'],
    related: [
      'How to create an allow and block list of sites for a briefing?',
      'How to create an allow and block list of sites for a team level?',
      'What is an account/account view?',
    ],
  },
  {
    question: 'How to use pre-made allow and block lists in a briefing?',
    answer: `
Creating an allow list and applying it to a briefing means that content will be surfaced only from the list of websites that you have "allowed". This provides an element of control to your briefing.
\n
Creating a block list and applying it to a briefing means that content will not be surfaced from the list of websites you have added to the block list.
\n
If an allow or block list has been pre-made at a team level, you can apply the allow or block list to a briefing. Watch this video to see how.
`,
    videoSrc: 'https://www.loom.com/embed/16772c44b83b4b16b177405219455516',
    types: ['new'],
    related: [
      'How to create a briefing?',
      'How to train a briefing?',
      'How to create an allow and block list of sites for a briefing?',
      'How to create an allow and block list at an account level?',
    ],
  },
  {
    question: 'How to create a daily digest email?',
    answer: `
A daily digest email is an email summary of the latest and freshest articles from within your selected briefings.
\n
To activate your daily digest email, navigate to the top right dropdown menu that will state your name, then select the 'profile' option from the list. Check the box next to the 'Receive daily digest email.' option, and then click 'Configure your daily digest'. Select which briefings you wish to be included within your daily digest email, select what time you'd like this to arrive in your inbox each day and then click update.
`,
    videoSrc: 'https://www.loom.com/embed/4d7d9f3880c34842b3ca5433e7cf4260',
    types: ['new'],
    related: [
      'How to deactivate a daily digest?',
      'How to create a weekly team digest?',
      'How to deactivate a weekly team digest?',
    ],
  },
  {
    question: 'How to deactivate a daily digest email?',
    answer: `
To deactivate your daily digest email, navigate to the top right dropdown menu that will state your name, then select the 'profile' option from the list. Uncheck the box next to the 'Receive daily digest email.' option then click update.
`,
    videoSrc: 'https://www.loom.com/embed/f95ef20ee8784028bf4551023312eb92',
    types: ['new'],
    related: [
      'How to create a daily digest?',
      'How to create a weekly team digest?',
      'How to deactivate a weekly team digest?',
    ],
  },
  {
    question: 'How to create a team digest?',
    answer: `
The Team Digest functionality can be located in your designated team, then navigating to the left hand control menu. Once you have navigated to the Team Digest menu you'll then have the option to 'Create new digest', fill in all of the setting to your desired functionally and then click 'submit'. This digest will then send on the day and time specified, you can also send yours a test email by selecting the 'Send Test' button on the 'Manage digests' screen once you've submitted your digest.
\n
From the 'Manage digests' screen you can also edit, delete and view the stats for each digest that's set up.
`,
    videoSrc: 'https://www.loom.com/embed/e925b0948e8240cfa3963c66bf7b3468',
    types: ['new'],
    related: [
      'How to deactivate a team digest?',
      'How to create a daily digest?',
      'How to deactivate a daily digest?',
    ],
  },
  {
    question: 'How to deactivate a team digest?',
    answer: `
To delete a team digest and stop it from sending, you will want to navigate to the correct team. Select the 'Team Digest' function from the left hand control menu, once selected you'll be able to see a complete list of Digest's set up from this team. Select 'Delete' next to the digest you wish to delete.
\n
If you wish to keep the digest but stop it sending to any participants you can do this by editing the briefing and removing all participants. You will need to have at least one participant, we suggest leaving yourself as the only participant in this instance.
`,
    types: ['new'],
    related: [
      'How to create a team digest?',
      'How to create a daily digest?',
      'How to deactivate a daily digest?',
    ],
  },
  {
    question: 'What are the export settings and how do I use them?',
    answer: `
This short video shows you how to access the export settings, enabling you to connect or export your briefings to your learning platform.
`,
    videoSrc: 'https://www.loom.com/embed/6589864a2b7b4ce98b6d22aac9d633ed',
    types: ['new'],
    related: [
      'What is a briefing?',
      'What is an account/account view?',
      'How to access all of the briefings in your team?',
    ],
  },
  {
    question:
      'How to make my briefings and saved folders publicly available or exportable via the API?',
    answer: `
The export settings are where you will find the API key to connect your briefings to your learning platform or collaboration tool.
\n
Within the export settings, you'll also be able to make your briefings publicly exportable. This is useful if you cannot use the API and will instead iFrame or use our JavaScript widget to export your briefings.
\n
This short video shows you how to export your briefings to your learning platform or collaboration tool.
`,
    videoSrc: 'https://www.loom.com/embed/49d068522b4140b89afb0e2d86804d12',
    types: ['new'],
    related: [
      'What is a briefing?',
      'What is an account/account view?',
      'How to access all of the briefings in your team?',
    ],
  },
  {
    question: 'My briefing is not available/showing in the plugin or API?',
    answer: `
You need to ensure you have ticked the briefing you’d like to expose to plugins and or the API within the Export Settings in the Anders Pink app.
`,
    types: ['new'],
    related: [
      'What are the export settings and how do I use them?',
      'How to make my briefings and saved folders publicly available or exportable via the API?',
      'How to access and interpret the usage analytics?',
      'How to use the Anders Pink Chrome extension?',
    ],
  },
  {
    question: 'How to access and interpret the usage analytics?',
    answer: `
The Usage Analytics can be found on the left hand side panel. Within the usage analytics you can view most popular articles, briefings and saved folders. This will give you an insight into the topics and skills your learners are learning about and which articles are peaking their interest. This will help you to build additional briefings tailored for your learners and curate collections of articles into saved folders.
\n
Within usage analytics you can define the time period you wish to focus on.
\n
Remember, we don't collect any individual user data so we don't know who has clicked on which article or briefing. We only collect data on article, briefing and saved folder clicks.
`,
    videoSrc: 'https://www.loom.com/embed/e59cd45851284853af0634dbf15e25aa',
    types: ['new'],
    related: [
      'What are saved folders and how do they work?',
      'How to use the Anders Pink Chrome extension?',
      'What are the export settings and how do I use them?',
      'How to make my briefings and saved folders publicly available or exportable via the API?',
    ],
  },
  {
    question: 'What are saved folders and how do they work?',
    answer: `
Saved Folders enable you to add a human layer of curation to your briefings. Using Saved Folders, you can add specific, handpicked articles from your briefings. These might be "must-read" articles for your learners or you might simply want to focus your learners' attention on a few key articles.
\n
Saved Folders are also useful if you prefer to have more control over the content that is coming through into your plans, pathways, units or modules as it ensures that only articles you have handpicked will appear in your learning platform.
\n
You will need to ensure that the Export Settings are accurately set up for your Saved Folders to connect to your learning platform or collaboration tool.
`,
    videoSrc: 'https://www.loom.com/embed/1f92ef719f6f49d88fd1d88998ead54c',
    types: ['new'],
    related: [
      'How to use the Anders Pink Chrome extension?',
      'What are the export settings and how do I use them?',
      'How to make my briefings and saved folders publicly available or exportable via the API?',
      'How to access and interpret the usage analytics?',
    ],
  },
  {
    question: 'How to use the Anders Pink Chrome extension?',
    answer: `
  Find the Anders Pink Chrome extension in the Chrome webstore by searching for "Anders Pink." Once you have downloaded it, it will appear within the extensions area of your taskbar.
  \n
  To add an article to Anders Pink, navigate to the Chrome extension while on the page.
  \n
  A pop up will appear. Confirm the title and select the team you wish to save the article to. You can then select a folder within the team. Select the boxes to recommend the article or add a comment as needed.
  \n
  When you return to the selected team in Anders Pink, the article will be saved under "Saved Articles" in the left menu
    `,
    videoSrc: 'https://www.loom.com/embed/d074d66c48054240ac2dc5ebcbc69fc1',
    types: ['new'],
    related: [
      'What are saved folders and how do they work?',
      'What are the export settings and how do I use them?',
      'How to make my briefings and saved folders publicly available or exportable via the API?',
      'How to access and interpret the usage analytics?',
    ],
  },
  {
    question: 'How to create briefings in different languages?',
    answer: `
Creating a briefing in another language the same as creating a briefing in English You simply need to know how to change the language to ensure that the content coming through into your briefing is the language you require.
\n
This short video shows you how to change the language so you can start creating your foreign language briefings.
`,
    videoSrc: 'https://www.loom.com/embed/8bc26e20f5e44e9bb76d695b803e32d0',
    types: ['new'],
    related: [
      'How to create a briefing?',
      'How to train a briefing?',
      'How to create an allow and block list of sites for a briefing?',
      'How to create an allow and block list of sites for a team level?',
    ],
  },
]

const SupportPage = () => {
  const [faqSelected, setFaqSelected] = useState(null)
  const [briefingTypeSelected, setBriefingTypeSelected] = useState('none')
  const [searchTerm, setSearchTerm] = useState('')

  function toggleAccordion(question) {
    if (faqSelected === question) {
      return setFaqSelected(null)
    }
    setFaqSelected(question)
  }

  // const filteredFaqs = faqs.filter((val) => {
  //   if (searchTerm === '' && briefingTypeSelected === 'none') {
  //     return
  //   } else if (searchTerm === '' && val.types.includes(briefingTypeSelected)) {
  //     return val
  //   } else if (
  //     val.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     briefingTypeSelected === 'none'
  //   ) {
  //     return val
  //   } else if (
  //     val.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     val.types.includes(briefingTypeSelected)
  //   ) {
  //     return val
  //   }
  // })
  const filteredFaqs = faqs.filter((faq) => {
    if (searchTerm === '') {
      return faq
    } else if (
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (faq.answer &&
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase()))
    ) {
      return faq
    }
  })

  return (
    <div style={{ textAlign: 'center' }}>
      <div className={styles.greyBg}>
        <h5>How can we help you?</h5>
        <br />
        <div style={{ maxWidth: '700px', margin: '0 auto' }}>
          <input
            className={styles.faqSearch}
            type="text"
            placeholder="Search for a question"
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
          />
        </div>
        <br />
        {/* <p className={styles.introText}>
        When building a briefing, you can use one of three methods: New, Simple
        or Advanced. To see FAQs related to your briefing type, pick one below
        or simply search for your query.
      </p> */}
        <p className={styles.introText}>
          You can also find further support options at the bottom of the page.
        </p>
      </div>

      <br />

      {/* <h5>Select a briefing type</h5>
      <div className={styles.briefingSelectors}>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('new')}
        >
          New
        </button>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('simple')}
        >
          Simple
        </button>
        <button
          className={styles.briefingSelector}
          onClick={() => setBriefingTypeSelected('advanced')}
        >
          Advanced
        </button>
      </div> */}

      <div className={styles.faqs}>
        {filteredFaqs.map((faq) => (
          <div className={styles.faq} key={faq.question}>
            <div
              className={styles.question}
              onClick={() => toggleAccordion(faq.question)}
            >
              {faq.question}
              <span style={{ color: '#cf0032' }}>
                {faqSelected === faq.question ? '-' : '+'}
              </span>
            </div>
            <div
              className={
                faqSelected === faq.question
                  ? `${styles.answer} ${styles.answerShow}`
                  : styles.answer
              }
            >
              <br />
              <ReactMarkdown source={faq.answer} />
              {faq.videoSrc && (
                <div
                  style={{
                    position: 'relative',
                    paddingBottom: '62.5%',
                    height: '0',
                  }}
                >
                  <iframe
                    src={faq.videoSrc}
                    frameBorder="0"
                    allowFullScreen={true}
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe>
                </div>
              )}
              <br />
              You may also find the following useful:
              <div className={styles.relatedQuestions}>
                {faq.related &&
                  faq.related.map((question) => (
                    <p key={question} onClick={() => toggleAccordion(question)}>
                      {question}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <br />

      <div className={styles.greyBg}>
        <div className={styles.furtherSupport}>
          <h6 className={styles.furtherSupportHeader}>
            Require further support?
          </h6>
          <div className={styles.supportButtons}>
            <Link to="/api-docs">
              <button className={styles.supportButton}>
                API Documentation
              </button>
            </Link>
            <Link to="/integrations">
              <button className={styles.supportButton}>
                Plugin and Integration Options
              </button>
            </Link>
            <a href="mailto:support@anderspink.com">
              <button className={styles.supportButton}>
                Contact us via support@anderspink.com
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.greyBg}>
        <div className={styles.furtherSupport}>
          <h6 className={styles.furtherSupportHeader}>Integration support</h6>
          <div className={styles.supportButtons}>
            <Link to="/support/totara-learn">
              <button className={styles.supportButton}>Totara Learn</button>
            </Link>
            <Link to="/support/totara-engage">
              <button className={styles.supportButton}>Totara Engage</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default class SupportPageWrapper extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          // style={{
          //   backgroundPosition: '50% 68%',
          //   backgroundImage: 'url(/pages/landing/shutterstock_401541778.jpg)',
          // }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Anders Pink Help</div>
          </div>
        </div>

        <SupportPage />
      </div>
    )
  }
}
