import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import apolloClient from 'apolloClient'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form'
import AdminTable from 'admin/components/AdminTable'

import AsyncSelect from 'react-select/async'

const selector = formValueSelector('createPlan')

@graphql(
  gql`
    mutation($type: String!, $name: String!, $userIds: [ID!]) {
      createAdminAccount(type: $type, name: $name, userIds: $userIds)
    }
  `,
  {
    name: 'createAdminAccount',
  },
)
@reduxForm({
  form: 'createPlan',
  initialValues: {
    type: 'business',
  },
})
@connect((state) => ({
  type: selector(state, 'type'),
}))
export default class AdminCreateAccountPage extends React.Component {
  onSubmit = (values) => {
    return this.props
      .createAdminAccount({
        variables: {
          type: values.type,
          name: values.name,
          userIds: values.users.map((user) => user.id.value),
        },
      })
      .then((response) => {
        this.props.reset()
        this.props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `New account succesfully created`,
        })
      })
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          throw new SubmissionError({
            _error: err.graphQLErrors[0].message,
          })
        } else if (err.networkError) {
          throw new SubmissionError({
            _error: err.networkError.message,
          })
        } else {
          throw new SubmissionError({
            _error: err.message,
          })
        }
      })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    const typeLabels = {
      app: 'App',
      api: 'API addon',
      extra_users: 'Extra users addon',
    }

    let typeLabel = typeLabels[this.props.type]
      ? typeLabels[this.props.type]
      : ''

    return (
      <div>
        <h5>Create an account</h5>

        <form
          onSubmit={handleSubmit(this.onSubmit)}
          style={{ maxWidth: '600px' }}
        >
          <div className="form-group">
            <label>Type</label>
            <Field name="type" component="select" className="form-control">
              <option value="personal">Personal</option>
              <option value="business">Business</option>
            </Field>
          </div>

          <div className="form-group">
            <label>Name (e.g. company name)</label>
            <Field
              name="name"
              component="input"
              type="text"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Owners of the account (for billing etc)</label>
            <FieldArray name="users" component={this.renderUsers} />
          </div>

          {(error ? true : false) && (
            <div className="alert alert-danger">{error}</div>
          )}

          <button
            type="submit"
            className={`btn btn-primary`}
            disabled={submitting}
          >
            Create account
          </button>
        </form>
      </div>
    )
  }
  async getUsers(input) {
    if (!input) {
      return Promise.resolve([])
    }
    return new Promise((resolve) => {
      try {
        apolloClient()
          .query({
            query: gql`
              query($query: String!, $types: [String]) {
                adminSearch(query: $query, types: $types) {
                  type
                  id
                  name
                }
              }
            `,
            variables: {
              query: input,
              types: ['user'],
            },
          })
          .then((result) => {
            resolve(
              result.data.adminSearch.map((result) => ({
                label: `${result.name} (${result.type} #${result.id})`,
                value: result.id,
              })),
            )
          })
      } catch (err) {
        console.log(err)
        resolve([])
      }
    })
  }
  renderUsers = ({ fields, meta: { error, submitFailed } }) => {
    return (
      <div style={{ margin: '1rem 0' }}>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => fields.push({})}
        >
          Add user
        </button>

        {submitFailed && error && <span>{error}</span>}

        {fields.map((user, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <div style={{ flex: '1' }}>
              <Field
                name={`${user}.id`}
                component={(props) => (
                  <AsyncSelect
                    valueKey="value"
                    labelKey="label"
                    value={props.input.value}
                    onChange={(selectedOption) => {
                      props.input.onChange(selectedOption)
                    }}
                    loadOptions={this.getUsers}
                  />
                )}
              />
            </div>

            <button
              type="button"
              title="Remove user"
              className="btn btn-secondary btn-sm"
              onClick={() => fields.remove(index)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    )
  }
}
