import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import PaymentForm from './PaymentForm'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'

const selector = formValueSelector('setupSubscription')

@compose(
  withProps((props) => ({
    initialValues: {
      interval: 'year',
    },
  })),
  reduxForm({
    form: 'setupSubscription',
  }),
  connect((state) => ({
    interval: selector(state, 'interval'),
    appQueryResult: state.app.appQueryResult,
  })),
)
export default class SubscriptionPage extends React.Component {
  constructor() {
    super()
    this.state = {
      seeOtherPlans: false,
      successState: null,
    }
  }
  render() {
    // If they're seeing this page they are on the free subscription (Free or legacy beta)..

    const customPlanSets = this.props.appQueryResult.user.customPlanSets

    // Get their current plan....
    let accounts = this.props.appQueryResult.user.accounts
    let account = accounts.length > 0 ? accounts[0] : null
    //let appPlan = account.plans.find(plan => plan.type === 'app');

    if (this.props.appQueryResult.user.teams.length > 0) {
      return (
        <div>
          <p>
            You are a member of a team, please see your account section to
            manage your business subscriptions.
          </p>
        </div>
      )
    }

    let showPlans = false

    return (
      <div>
        {showPlans && (
          <div>
            <h4 style={{ margin: '1rem 0' }}>
              For Enterprises and Partners: Enterprise Plans
            </h4>

            <p>
              For enterprises and partners using Anders Pink on scale, our
              Enterprise plans enable you to embed Anders Pink curated content
              and functionality seamlessly into your enterprise platforms, sites
              and apps.
            </p>

            <p>Drive traffic and engagement in:</p>
            <ul>
              <li>LMS/Learning Platforms</li>
              <li>WordPress</li>
              <li>SharePoint</li>
              <li>Yammer</li>
              <li>Elearning Modules</li>
              <li>...and many more</li>
            </ul>

            <p>
              We have a range of integration methods including{' '}
              <Link to="/api-info" className="link">
                API
              </Link>
              , RSS, full SDK and Javascript code.
            </p>

            <br />
            <h5>Enterprise Plan Pricing:</h5>

            <p>
              <Link to="/contact" className="link">
                Contact us
              </Link>{' '}
              for a quote. Enterprise plans include access to our app on scale,
              and a range of integration options. Enterprise Integration pricing
              is based on number of briefings, end-users and integrations.
            </p>

            <p>
              <Link to="/contact" className="link">
                Contact us
              </Link>{' '}
              to discuss your needs and arrange an Enterprise Plan trial.
            </p>

            <br />
            <h5>Become an Anders Pink Partner</h5>

            <p>
              Join over 20 leading Digital Learning providers and become an
              Anders Pink Partner.
            </p>

            <p>
              Get specific packages and integrations to help you enhance your
              platforms and apps, integrate Anders Pink into your app and
              platform seamlessly, support your clients, and grow your business.
            </p>

            <p>
              <Link to="/contact" className="link">
                Contact us
              </Link>{' '}
              to discuss the Anders Pink partner program.
            </p>

            <br />
            <br />
            <h4 style={{ margin: '1rem 0' }}>
              For Individuals and Small Teams using our App
            </h4>
            <p>
              To use our app without any Integrations, you can choose one of our
              app-only plans. You can upgrade to an Enterprise plan any time.
            </p>

            <div
              className="row"
              style={{
                margin: '20px -15px',
                maxWidth: '900px',
                display: 'flex',
                alignItems: 'stretch',
              }}
            >
              <div className="col-xl-3 col-md-6 col-12 mb-4">
                <div className="card" style={{ height: '100%' }}>
                  <div style={{ padding: '1.25rem 1.25rem 0 1.25rem' }}>
                    <h4 className="card-title">Free</h4>
                    <p>&nbsp;</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">1 user</li>
                    <li className="list-group-item">
                      1 briefings
                      <br />1 saved article folder
                    </li>
                    <li className="list-group-item">Free</li>
                  </ul>
                  <div
                    className="card-body"
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <div>This is your current plan.</div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-4">
                <div className="card" style={{ height: '100%' }}>
                  <div style={{ padding: '1.25rem 1.25rem 0 1.25rem' }}>
                    <h4 className="card-title">Pro</h4>
                    <p>For smart individuals</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">1 user</li>
                    <li className="list-group-item">
                      10 briefings
                      <br />
                      5 saved article folder
                      <br />
                      Slack integration
                    </li>
                    <li className="list-group-item">
                      <span>
                        <span style={{ fontSize: '1.6em' }}>$9</span> per month
                        <br />
                        <span style={{ fontSize: '1.6em' }}>$99</span> billed
                        annually (save $9)
                      </span>
                    </li>
                  </ul>
                  <div
                    className="card-body"
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <div>
                      <Link
                        className="btn btn-primary btn-sm"
                        to="/setup-subscription/pro"
                      >
                        Start your free 14 day trial
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-4">
                <div className="card" style={{ height: '100%' }}>
                  <div style={{ padding: '1.25rem 1.25rem 0 1.25rem' }}>
                    <h4 className="card-title">Starter Team</h4>
                    <p>Ideal for smaller teams</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Up to 5 users, 1 private team
                    </li>
                    <li className="list-group-item">
                      25 briefings
                      <br />
                      5 saved articles folders
                      <br />
                      Slack integration
                      <br />
                      Share and comment privately with your team
                      <br />
                      Team and per user stats
                      <br />
                      Webinar Training Session
                      <br />
                    </li>
                    <li className="list-group-item">
                      <span>
                        <span style={{ fontSize: '1.6em' }}>$39</span> per month
                        <br />
                        <span style={{ fontSize: '1.6em' }}>$399</span> billed
                        annually (save $69)
                        <br />
                        Additional users $5 per month
                      </span>
                    </li>
                  </ul>
                  <div
                    className="card-body"
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <div>
                      <Link
                        className="btn btn-primary btn-sm"
                        to="/setup-subscription/starter-team"
                      >
                        Start your free 14 day trial
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-12 mb-4">
                <div className="card" style={{ height: '100%' }}>
                  <div style={{ padding: '1.25rem 1.25rem 0 1.25rem' }}>
                    <h4 className="card-title">Pro Team</h4>
                    <p>Ideal for Mid-sized Teams</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Up to 20 users, 1 private team
                    </li>
                    <li className="list-group-item">
                      50 briefings and folders
                      <br />
                      Slack integration
                      <br />
                      Share and comment privately with your team
                      <br />
                      Team and per user stats
                      <br />
                      Webinar Training Session
                      <br />
                    </li>
                    <li className="list-group-item">
                      <span>
                        <span style={{ fontSize: '1.6em' }}>$99</span> per month
                        <br />
                        <span style={{ fontSize: '1.6em' }}>$999</span> billed
                        annually (save $189)
                        <br />
                        Additional users $5 per month
                      </span>
                    </li>
                  </ul>
                  <div
                    className="card-body"
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <div>
                      <Link
                        className="btn btn-primary btn-sm"
                        to="/setup-subscription/team"
                      >
                        Start your free 14 day trial
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p>
              <Link to="/contact" className="link">
                Contact us
              </Link>{' '}
              to discuss your needs and arrange an integration trial.
            </p>
            <p>
              Start with a Free trial - no credit card needed. You can change
              your plan any time.
            </p>
            <p>
              All prices exclude local VAT where applicable. E.g. non-businesses
              based in the EU.
            </p>
          </div>
        )}
      </div>
    )
  }
}
