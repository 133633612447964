import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'

let isLoading = false
let isClearable = true
let isSearchable = true

const Selector = ({ data, isMulti, input, required }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(isMulti ? [] : null)

  let selectRef = useRef().current

  useEffect(() => {
    if (isMulti) {
      const foundOptions = data.filter((o) => input.value.includes(o.value))
      setValue(foundOptions)
    } else {
      const foundOption = data.find((o) => o.value === input.value)
      if (foundOption) {
        setValue(foundOption)
      }
    }
  }, [])

  const handleChange = (event) => {
    if (!isMulti) {
      setIsOpen(false)
    }
    setValue(event)
    if (isMulti) {
      let values = event.map((item) => item.value)
      input.onChange(values)
    } else {
      input.onChange(event.value)
    }
  }

  return (
    <>
      <Select
        ref={(r) => (selectRef = r)}
        className="selector"
        classNamePrefix="select"
        isMulti={isMulti}
        menuIsOpen={isOpen}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={input.name}
        options={data}
        value={value}
        onChange={handleChange}
      />
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0,
            position: 'absolute',
          }}
          value={isMulti ? (input.value || []).join(',') : input.value}
          onChange={() => {}}
          onFocus={() => selectRef.focus()}
          required={required}
        />
      )}
    </>
  )
}

export default Selector
