import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.css'
import pageStyles from 'content/pages/styles.css'
import { Link } from 'react-router'
import ContactForm from 'content/containers/ContactForm'

import partners from './partners-data'

@connect((state) => ({
  app: state.app,
}))
export default class Partners extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        newLayout: false,
      },
    })
  }

  render() {
    return (
      <div className={pageStyles.root}>
        <div
          className={pageStyles.cover}
          style={{
            backgroundPosition: '50% 85%',
            backgroundImage: 'url(/pages/landing/shutterstock_228793369.jpg)',
          }}
        >
          <div className={pageStyles.headingShort}>
            <div className={pageStyles.title}>Partner showcase</div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.textContainer}>
            <p>
              Anders Pink is plugged into leading partners and platforms to make
              it easier to embed content curation in your LMS, Learning
              Platform, or whatever tool you want.
            </p>
            <p>
              If you're already working with one of our partners - it's really
              easy. Contact any of our partners to learn more about how to get
              Anders Pink as part of your solution.
            </p>

            <div style={{ textAlign: 'center' }}>
              <Link
                to="/contact?reason=Partner%20inquiry"
                className="btn btn-lg btn-primary"
              >
                Become a partner
              </Link>
            </div>
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            Our Partners and Platforms:
          </div>

          <div className={styles.partners}>
            {partners.map((partner, i) => (
              <div className={styles.partner} key={i}>
                <div className={styles.partnerImage}>
                  <img src={partner.image} />
                </div>
                <div className={styles.partnerText}>
                  <div
                    style={{
                      fontSize: '1.1rem',
                      paddingBottom: '0.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {partner.name}
                  </div>
                  {!!partner.description && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: partner.description,
                      }}
                    />
                  )}
                  {!!partner.integration && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: partner.integration,
                      }}
                    />
                  )}
                </div>
                <div className={styles.partnerLink}>
                  {!!partner.moreInfoId && (
                    <Link
                      className="btn btn-primary mb-2"
                      to={`/partners/${partner.moreInfoId}`}
                    >
                      Showcase
                    </Link>
                  )}
                  {!!partner.moreInfoUrl && (
                    <Link
                      className="btn btn-primary mb-2"
                      to={partner.moreInfoUrl}
                    >
                      Showcase
                    </Link>
                  )}
                  <a
                    target="_blank"
                    className="btn btn-secondary"
                    href={partner.url}
                  >
                    Contact
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={pageStyles.section}>
          <div className={pageStyles.sectionHeadingThin}>
            We also integrate with:
          </div>

          <div className={styles.partners}>
            <div className={styles.partner}>
              <div className={styles.partnerImage}>
                <img src="/pages/landing/moodle-logo.png" />
              </div>
              <div className={styles.partnerText}>
                <div
                  style={{
                    fontSize: '1.1rem',
                    paddingBottom: '0.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  Moodle
                </div>
                <p>
                  Moodle is a learning platform designed to provide educators,
                  administrators and learners with a single robust, secure and
                  integrated system to create personalised learning environments
                </p>
              </div>
              <div className={styles.partnerLink}>
                <Link to="/integrations/moodle" className="btn btn-primary">
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={pageStyles.greySection}>
          <div className={pageStyles.innerGreySection}>
            <div className={pageStyles.sectionHeadingThin}>
              Become an Anders Pink Partner:
            </div>

            <div className={pageStyles.textContainer}>
              <p>
                Join over 25 leading learning and platform providers and become
                an Anders Pink partner.
              </p>
              <ul>
                <li>
                  Add value to your platform and content: Get access to our API
                  and integrations to easily embed Anders Pink curated content
                  and/or powerful curation features directly into your platforms
                  and apps
                </li>
                <li>
                  Add value to your clients: your clients want curated content
                  for continuous learning - easily add it with our platform{' '}
                </li>
                <li>
                  Completely white labelled - you can customise, brand and add
                  services{' '}
                </li>
                <li>Preferential partner pricing with a range of models </li>
                <li>
                  Technical Support from our team to help with integration{' '}
                </li>
                <li>
                  Marketing support - we'll help you educate your clients in the
                  value of curation for learning and develop a co-marketing plan
                  with you{' '}
                </li>
                <li>
                  Sales support - we'll support your sales team to help you win{' '}
                </li>
              </ul>
              <p>
                Contact us to arrange a partner overview session by filling in
                the form below.
              </p>
            </div>

            <br />
            <ContactForm type="partner" width="720px" />
          </div>
        </div>
      </div>
    )
  }
}
