exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ArticleListFullPage__root___3pASA {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  /* align-items: stretch; */\n  margin: -15px;\n}\n.ArticleListFullPage__container___2_R0o {\n}\n.ArticleListFullPage__item___1m_e1 {\n  width: 300px;\n  padding: 15px 15px 0 15px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: stretch;\n      -ms-flex-align: stretch;\n          align-items: stretch;\n  margin-bottom: 16px;\n}\n.ArticleListFullPage__itemInner___3dMjG {\n  width: 100%;\n  /* border-bottom: 1px solid #e1e1e1; */\n  padding-bottom: 15px;\n  margin-bottom: -8px;\n}\n.ArticleListFullPage__noBorder___3oUK7 {\n  border: none;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ArticleListFullPage__root___3pASA",
	"container": "ArticleListFullPage__container___2_R0o",
	"item": "ArticleListFullPage__item___1m_e1",
	"itemInner": "ArticleListFullPage__itemInner___3dMjG",
	"noBorder": "ArticleListFullPage__noBorder___3oUK7"
};