import React from 'react'
import pageStyles from 'content/pages/styles.css'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const bundles = {
  en: {
    'Bundle: Future of Work in-demand Skills': [
      [
        'Soft Skill: Emotional Intelligence',
        '/briefing/98536/soft-skill-emotional-intelligence/3-months',
      ],
      'Soft Skill: Adaptability',
      'Soft Skill: Collaboration',
      'Soft Skill: Creativity',
      'Hard Skill: Blockchain',
      'Hard Skill: Cloud Computing',
      'Hard Skill: Analytical Reasoning',
      'Hard Skill: Artificial Intelligence',
      'Hard Skill: UX Design',
      'Hard Skill: Digital Marketing',
      [
        'Hard Skill: Design Thinking',
        '/briefing/98547/hard-skill-design-thinking/3-months',
      ],
      'Hard Skill: Affiliate Marketing',
      'Hard Skill: Video Production',
      'Hard Skill: Sales',
    ],
    'Bundle: Personal development': [
      'Time Management',
      ['Work-Life Balance', '/briefing/98554/work-life-balance/3-months'],
      'Virtual and remote working',
      [
        'Presentation and public speaking skills',
        '/briefing/98556/presentation-and-public-speaking-skills/3-months',
      ],
      'Resilience',
      'Emotional intelligence and EQ',
      'Decision making',
      'Developing your career',
      'Continuous and Lifelong learning',
      'Communication and interpersonal skills',
      'Creative thinking',
      'Productivity',
      'Diversity and cultural intelligence',
      'Adapting to change',
      'Building your personal brand',
    ],
    'Bundle: Business and Strategy': [
      'Business Strategy: Trends and insights',
      'Innovation, Creativity and Design Thinking',
      'Thought Leadership',
      'Startups and Entrepreneurs - trending content',
      'Product and project management',
      ['Growth Hacking', '/briefing/98574/growth-hacking/3-months'],
      'The Future of Work',
      'Digital Transformation and Disruption',
      'Gig Economy',
      [
        'Sustainable Business Practices',
        '/briefing/98661/sustainable-business-practices/3-months',
      ],
      'Becoming a Net Zero Business',
    ],
    'Bundle: Technology': [
      'Technology News and Trends',
      'AI: Latest trends and insights',
      'API Development and News',
      'Virtual Reality (VR)',
      'Machine Learning Trends',
      'User Experience Design: Tips and Trends',
      'Augmented Reality (AR)',
      'Internet of Things (IOT)',
      'SaaS Trends',
      'App Development',
      'Big Data and analytics',
      'Cloud Computing',
      ['Gaming: Trends', '/briefing/98607/gaming-trends/3-months'],
      'Product Management',
      ['DevOps', '/briefing/98615/devops/3-months'],
      'Blockchain',
    ],
    'Bundle: Marketing': [
      'Marketing: Overview',
      ['SEO and SEM', '/briefing/98585/seo-and-sem/3-months'],
      'Influencer Marketing',
      'Blogging',
      'Content Marketing: Trends and tips',
      'Public Relations/ PR',
      'Paid Search, PPC and AdWords',
      'B2B Marketing',
      'Data-Driven Marketing: Metrics, Analytics and ROI',
      [
        'Social Media Marketing: Trends and tips',
        '/briefing/98633/social-media-marketing-trends-and-tips/3-months',
      ],
      'Buyer and Customer Personas',
      'Marketing planning',
    ],
    'Bundle: Sales Professionals': [
      'Building sales relationships',
      'Active listening',
      'Influencing skills',
      'Effective negotiation',
      'Maintaining customer value',
      'Trending content for Sales Professionals',
      ['Social Selling', '/briefing/98622/social-selling/3-months'],
      'Sales planning',
      'Objection Prevention and handling',
      'Sales Presentations and Demos',
      'Pitching',
      'Pricing Strategies',
      'Account Management, Client Service, Customer Experience',
      'Lead generation and prospecting',
      [
        'Customer service mentality',
        '/briefing/98569/customer-service-mentality/3-months',
      ],
    ],
    'Bundle: HR, Learning and Development': [
      'Learning Technology: Trends and Insights',
      [
        'HR: Trends and insights',
        '/briefing/98600/hr-trends-and-insights/3-months',
      ],
      'Recruitment, Hiring and Talent Acquisition',
      'HR, Learning & Development',
      'LMS, LXP and Learning Platforms',
      'Gamification Trends and insights',
      'EdTech',
      'HR and People Strategy',
      'Talent Management',
      'Performance Management',
      'Continuous and Lifelong learning',
      'Learning design - trends and tips',
      ['Employee Engagement', '/briefing/98636/employee-engagement/3-months'],
      'AI, Bots, Big Data and Automation in HR',
      'Company Culture',
      'The Future of Work Trends',
      'Diversity and inclusion',
    ],

    'Bundle: Management and Leadership': [
      'Leading and Managing Teams',
      [
        'Agile, Remote and Virtual Teams',
        '/briefing/98694/agile-remote-and-virtual-teams/3-months',
      ],
      'High Performing Teams',
      'Coaching and Mentoring',
      'Strategic Thinking',
      'Emotional intelligence and EQ for leaders',
      'Negotiation and influencing',
      'Business Acumen',
      ['Employee Engagement', '/briefing/98703/employee-engagement/3-months'],
      'Talent Management',
      'Performance Management',
      'Business Strategy',
      'Supply Chain Management',
      'Leading and managing change',
      'Developing a Growth Mindset',
      'CSR News and Trends for Leaders',
      'Net Zero Business for Leaders',
    ],
    'Bundle: Leadership Behaviours': [
      'Leadership Behaviors: Emotional Intelligence',
      'Leadership Behaviors: Growth Mindset',
      'Leadership Behaviors: Communication',
      [
        'Leadership Behaviors: Resilience',
        '/briefing/98654/leadership-behaviors-resilience/3-months',
      ],
      'Leadership Behaviors: Decision Making',
      'Leadership Behaviors: Creativity and Innovation',
      'Leadership Behaviors: Strategic Thinking and Problem Solving',
      [
        'Leadership Behaviors: Giving and Receiving Feedback',
        '/briefing/98665/leadership-behaviors-giving-and-receiving-feedback/3-months',
      ],
      'Leadership Behaviors: Effective Leadership',
      'Leadership Behaviors: Managing Change',
      'Leadership Behaviors: Accountability',
      'Leadership Behaviors: Relationship Building',
      'Leadership Behaviors: Conflict Management',
      'Leadership Behaviors: Developing Others',
      'Leadership Behaviors: Motivation',
      'Leadership Behaviors: Negotiating',
      'Leadership Behaviors: Risk Taking',
      'Leadership Behaviors: Trust and Integrity',
      'Leadership Behaviors: Diversity and Inclusion',
      'Articles for Leaders From Top Sources',
    ],
    'Bundle: Hybrid Working': [
      [
        'Employee Expectations Post-Pandemic',
        '/briefing/98672/employee-expectations-post-pandemic/3-months',
      ],
      'Best Practices for Working Remotely or With a Hybrid Model',
      'Managerial Best Practices While Working Remotely or With a Hybrid Model',
      'Hybrid Working & Post-Pandemic Articles for HR Professionals',
      'Employee Motivation and Engagement for Virtual & Hybrid Teams',
      'Upskilling, Reskilling and skills for the Future',
    ],
    'Bundle: Wellness, Wellbeing and Mental Health': [
      ['Well Being at Work', '/briefing/98627/well-being-at-work/3-months'],
      'Personal wellbeing',
      'Taking care of your mental health',
      'Stress Management',
    ],
  },
  de: {
    'Bundle: Business and Strategy (DE)': [
      'Geschäftsstrategie: Trends und Erkenntnisse',
      'Innovation, Kreativität und Design Thinking',
      'Thought-Leadership',
      'Start-ups und Unternehmer: trendige Inhalte',
      [
        'Produkt- und Projektmanagement',
        '/briefing/82417/produkt-und-projektmanagement/3-months',
      ],
      'Growth Hacking',
      'Die Zukunft der Arbeit',
      'Supply-Chain-Management',
      'Digitale Transformation und Umwälzung',
      'Gig Economy',
    ],
    'Bundle: Curated Content on Working and Living Well in the New Normal (DE)':
      [
        'Psychische Gesundheit und Wohlergehen am Arbeitsplatz',
        'Mitarbeitermotivation und -einbindung für Remote Teams',
        'Agile, virtuelle und Remote Teams',
        [
          'Die Zukunft der Arbeit',
          '/briefing/83905/die-zukunft-der-arbeit/3-months',
        ],
        'Psychische Gesundheit in Zeiten von Corona',
        'Tipps für das Homeoffice',
        'Tipps für das Homeschooling',
        'Resilienz und Veränderungen am Arbeitsplatz',
        'Work-Life-Balance',
        'Zeitmanagement',
        'Change-Management: Best Practice und Prozesse',
        'Tipps für effektive Webkonferenzen',
      ],
    'Bundle: Future of Work in-demand Skills (DE)': [
      'Soziale Kompetenz: Emotionale Intelligenz',
      'Soziale Kompetenz: Überzeugungskraft',
      'Soziale Kompetenz: Anpassungsfähigkeit',
      'Soziale Kompetenz: Zusammenarbeit',
      'Soziale Kompetenz: Kreativität',
      [
        'Fachliche Kompetenz: Blockchain',
        '/briefing/82367/fachliche-kompetenz-blockchain/3-months',
      ],
      'Fachliche Kompetenz: Cloud-Computing',
      'Fachliche Kompetenz: Analytisches Denken',
      'Fachliche Kompetenz: Künstliche Intelligenz',
      'Fachliche Kompetenz: User Experience Design',
      'Fachliche Kompetenz: Digitales Marketing',
      'Fachliche Kompetenz: Design Thinking',
      'Fachliche Kompetenz: Digitales Projektmanagement',
      'Fachliche Kompetenz: Affiliate-Marketing',
      'Fachliche Kompetenz: Wissenschaftliches Rechnen',
      'Fachliche Kompetenz: Videoproduktion',
      'Fachliche Kompetenz: Vertrieb',
    ],
    'Bundle: HR, Learning and Development (DE)': [
      [
        'Lerntechnologien: Trends und Erkenntnisse',
        '/briefing/82483/lerntechnologien-trends-und-erkenntnisse/3-months',
      ],
      'Humanressourcen: Trends und Erkenntnisse',
      'Personalbeschaffung, Personaleinstellung und Talentgewinnung',
      'Humanressourcen, Aus- und Weiterbildung',
      'LMS, LXP und Lernplattformen',
      'Gamification: Trends und Erkenntnisse',
      'Bildungstechnologien',
      'HR- und Personalstrategie',
      'Talentmanagement',
      'Performance-Management',
      'Wohlergehen am Arbeitsplatz',
      'Kontinuierliches und lebenslanges Lernen',
      'Lerndesign: Trends und Tipps',
      'Mitarbeitereinbindung',
      'KI, Bots, Big Data und Automation im Bereich Humanressourcen',
      'Unternehmenskultur',
    ],
    'Bundle: Leadership and Management (DE)': [
      'Teams leiten und managen',
      'Agile, virtuelle und Remote Teams',
      'Leistungsfähige Teams',
      'Coaching und Mentoring',
      'Strategisches Denken',
      'Emotionale Intelligenz für Führungskräfte',
      'Feedback geben und erhalten',
      'Kreativität und Innovation für Führungskräfte',
      'Verhandeln und beeinflussen',
      'Geschäftssinn',
      'Mitarbeitereinbindung',
      'Talentmanagement',
      'Performance-Management',
      'Geschäftsstrategie',
      'Supply-Chain-Management',
      ['Change-Management', '/briefing/82514/change-management/3-months'],
      'Führung und Entscheidungsfindung',
      'Führung und Resilienz',
      'Führung und Kommunikation',
      'Digitale Umwälzung',
      'Growth Mindset',
    ],
    'Bundle: Personal development (DE)': [
      ['Zeitmanagement', '/briefing/82396/zeitmanagement/3-months'],
      'Work-Life-Balance',
      'Arbeiten im Homeoffice',
      'Präsentationen und öffentliches Reden',
      'Resilienz',
      'Emotionale Intelligenz und EQ',
      'Entscheidungsfindung',
      'Karriereentwicklung',
      'Kontinuierliches und lebenslanges Lernen',
      'Kommunikation und zwischenmenschliche Fähigkeiten',
      'Kreatives Denken',
      'Problemlösung und kritisches Denken',
      'Servicementalität',
      'Produktivität',
      'Vielfalt und kulturelle Intelligenz',
      'Technologietrends',
      'Anpassung an Veränderungen',
    ],
    'Bundle: Sales and Marketing (DE)': [
      ['Marketing: Überblick', '/briefing/82439/marketing-uberblick/3-months'],
      'SEO und SEM',
      'Influencer Marketing',
      'Bloggen',
      'Content-Marketing: Trends und Tipps',
      'Public Relations/ Öffentlichkeitsarbeit',
      'Verkaufspräsentationen und -demonstrationen',
      'Verkaufsgespräche',
      'Richtig verhandeln',
      'Preispolitik',
      'Account-Management, Kundenservice, Kundenerfahrung',
      'Pay-per-Click und AdWords',
      'Social Selling',
      'B2B-Marketing',
      'Datengesteuertes Marketing: Metriken, Analytik und ROI',
      'Leadgenerierung und Verkaufsaussichten',
      'Einwandvorbeugung und -behandlung',
      'Social Media Marketing: Trends und Tipps',
      'Das Persona-Konzept',
      'Absatzplanung',
      'Marketingplanung',
      'Lieferantenmanagement',
    ],
    'Bundle: Technology (DE)': [
      [
        'Technologie: News und Trends',
        '/briefing/82423/technologie-news-und-trends/3-months',
      ],
      'KI: Trends und Erkenntnisse',
      'API: Entwicklung und News',
      'Virtuelle Realität (VR)',
      'Maschinelles Lernen: Trends',
      'User Experience Design: Tipps und Trends',
      'Erweiterte Realität (Augmented Reality)',
      'Internet der Dinge (IdD)',
      'SaaS: Trends',
      'App-Entwicklung',
      'Big Data und Analytik',
      'Cloud-Computing',
      'Gaming: Trends',
      'Produktmanagement',
      'DevOps',
      'Blockchain',
    ],
  },
  es: {
    'Bundle: Business and Strategy (ES)': [
      [
        'Estrategia empresarial: tendencias y actualidad',
        '/briefing/80561/estrategia-empresarial-tendencias-y-actualidad/3-months',
      ],
      'Innovación, creatividad y pensamiento de diseño',
      'Liderazgo intelectual',
      'Startups y emprendedores: tendencias',
      'Gestión de productos y proyectos',
      'Hackeo de crecimiento',
      'El futuro del trabajo',
      'Gestión de la cadena de suministro',
      'Transformación e innovación digital',
      'Economía de los pequeños encargos',
    ],
    'Bundle: Curated Content on Working and Living Well in the New Normal (ES)':
      [
        [
          'Salud mental y bienestar en el trabajo',
          '/briefing/80381/salud-mental-y-bienestar-en-el-trabajo/3-months',
        ],
        'Motivación y compromiso de los miembros de equipos virtuales',
        'Equipos ágiles, remotos y virtuales',
        'El futuro del trabajo',
        'Proteger la salud mental durante la pandemia de la Covid-19',
        'Consejos para trabajar desde casa',
        'Consejos para educar a tus hijos en casa',
        'Desarrollar resiliencia y adaptarse al cambio en circunstancias excepcionales',
        'El equilibrio entre la vida personal y profesional',
        'Gestión del tiempo',
        'Mejores prácticas y procesos para la gestión del cambio',
        'Consejos para mantener reuniones virtuales eficaces',
      ],
    'Bundle: Future of Work in-demand Skills (ES)': [
      [
        'Habilidad básica: inteligencia emocional',
        '/briefing/80625/habilidad-basica-inteligencia-emocional/3-months',
      ],
      'Habilidad básica: persuasión',
      'Habilidad básica: adaptabilidad',
      'Habilidad básica: colaboración',
      'Habilidad básica: creatividad',
      'Habilidad técnica: cadena de bloques',
      'Habilidad técnica: informática en la nube',
      'Habilidad técnica: razonamiento analítico',
      'Habilidad técnica: inteligencia artificial',
      'Habilidad técnica: diseño de experiencias del usuario',
      'Habilidad técnica: marketing digital',
      'Habilidad técnica: pensamiento de diseño',
      'Habilidad técnica: gestión de proyectos digitales',
      'Habilidad técnica: marketing de afiliación',
      'Habilidad técnica: informática científica',
      'Habilidad técnica: producción de vídeo',
      'Habilidad técnica: ventas',
    ],
    'Bundle: HR, Learning and Development (ES)': [
      'Aprendizaje electrónico: tendencias y actualidad',
      [
        'RR. HH.: tendencias y actualidad',
        '/briefing/80454/rr-hh-tendencias-y-actualidad/3-months',
      ],
      'Contratación y adquisición de personal',
      'RR. HH., aprendizaje y desarrollo',
      'LMS, LXP y plataformas de aprendizaje',
      'Gamificación: tendencias y actualidad',
      'Tecnología educativa',
      'Recursos humanos y estrategia de personal',
      'Gestión del personal',
      'Gestión del rendimiento',
      'Bienestar en el trabajo',
      'Aprendizaje continuo y permanente',
      'Diseño del aprendizaje: tendencias y consejos',
      'Compromiso de los empleados',
      'IA, bots, big data y automatización en RR. HH.',
      'Cultura empresarial',
    ],
    'Bundle: Leadership and Management (ES)': [
      [
        'Dirigir y gestionar equipos',
        '/briefing/80394/dirigir-y-gestionar-equipos/3-months',
      ],
      'Equipos ágiles, remotos y virtuales',
      'Equipos de alto rendimiento',
      'Coaching y mentoring',
      'Pensamiento estratégico',
      'Inteligencia emocional para líderes',
      'Dar y recibir feedback',
      'Creatividad e innovación para líderes',
      'Negociar e influenciar',
      'Visión para los negocios',
      'Compromiso de los empleados',
      'Gestión del personal',
      'Gestión del rendimiento',
      'Estrategia empresarial',
      'Gestión de la cadena de suministro',
      'Dirigir y gestionar el cambio',
      'Liderazgo y toma de decisiones',
      'Liderazgo y resiliencia',
      'Liderazgo y comunicación',
      'Innovación digital',
      'Desarrollar una mentalidad de crecimiento',
    ],
    'Bundle: Personal development (ES)': [
      ['Gestión del tiempo', '/briefing/80590/gestion-del-tiempo/3-months'],
      'El equilibrio entre la vida personal y profesional',
      'Teletrabajo',
      'Presentar y hablar en público',
      'Resiliencia',
      'Inteligencia emocional',
      'Toma de decisiones',
      'Desarrollar una carrera profesional',
      'Aprendizaje continuo y permanente',
      'Habilidades interpersonales y de comunicación',
      'Pensamiento creativo',
      'Resolución de problemas y pensamiento crítico',
      'Cultura de servicio al cliente',
      'Productividad',
      'Diversidad e inteligencia cultural',
      'Tecnología: tendencias',
      'Adaptarse al cambio',
    ],
    'Bundle: Sales and Marketing (ES)': [
      [
        'Marketing: actualidad y mejores prácticas',
        '/briefing/80493/marketing-actualidad-y-mejores-practicas/3-months',
      ],
      'SEO y SEM',
      'Marketing de influencia',
      'Blogs: consejos e información',
      'Marketing de contenidos: Tendencias y consejos',
      'Relaciones públicas',
      'Presentaciones y demostraciones de ventas',
      'Discursos de ventas',
      'Negociación eficaz',
      'Estrategias de precios',
      'Gestión de cuentas, servicio al cliente y experiencia del cliente',
      'Búsqueda pagada, pago por clic y AdWords',
      'Venta social',
      'Marketing B2B',
      'Marketing basado en datos: métricas, análisis y ROI',
      'Generación y prospección de clientes',
      'Prevención y gestión de objeciones',
      'Marketing en las redes sociales: tendencias y consejos',
      'Personas del comprador y el cliente',
      'Planificación de ventas',
      'Planificación de marketing',
      'Gestión de relaciones con proveedores',
    ],
    'Bundle: Technology (ES)': [
      [
        'Tecnología: novedades y tendencias',
        '/briefing/80535/tecnologia-novedades-y-tendencias/3-months',
      ],
      'IA: últimas tendencias y actualidad',
      'API: desarrollo y novedades',
      'Realidad virtual (RV)',
      'Aprendizaje automático: tendencias',
      'Diseño de experiencias de usuario: consejos y tendencias',
      'Realidad aumentada (RA)',
      'Internet de las cosas (IOT)',
      'SaaS: tendencias',
      'Desarrollo de aplicaciones',
      'Big data y análisis',
      'Informática en la nube',
      'Juegos: tendencias',
      'Gestión de productos',
      'Operaciones de desarrollo',
      'Cadena de bloques',
    ],
  },
  fr: {
    'Bundle: Business and Strategy (FR)': [
      "Stratégie d'entreprise - Actualités et analyses",
      [
        'Innovation, créativité et design thinking',
        '/briefing/80552/innovation-creativite-et-design-thinking/3-months',
      ],
      'Leadership intellectuel',
      'Startups et entrepreneurs - Actualités',
      'Gestion de projet et de produit',
      'Growth Hacking / Piratage de la croissance',
      "L'avenir du travail",
      'Gestion de la chaîne logistique',
      'Révolution numérique et technologie de rupture',
      'Économie à la tâche / Gig Economy',
    ],
    'Bundle: Curated Content on Working and Living Well in the New Normal (FR)':
      [
        'Motivation et engagement des collaborateurs dans les équipes à distance',
        'Équipes virtuelles, à distance et agiles',
        ["L'avenir du travail", '/briefing/80371/lavenir-du-travail/3-months'],
        "Gérer votre santé mentale durant l'épidémie de Covid-19",
        'Conseils pour travailler à domicile',
        "Conseils pour faire l'école à la maison",
        "Développer sa résilience et s'adapter dans les circonstances exceptionnelles",
        'Équilibre vie professionnelle-vie privée',
        'Gestion du temps',
        'Processus et meilleures pratiques de gestion du changement',
        'Conseils pour des réunions virtuelles efficaces',
        'Santé mentale et bien-être au travail',
      ],
    'Bundle: Future of Work in-demand Skills (FR)': [
      [
        'Savoir-être/Soft skills - Intelligence émotionnelle',
        '/briefing/80607/savoir-etresoft-skills-intelligence-emotionnelle/3-months',
      ],
      "Savoir-être/Soft skills - L'art de la persuasion",
      'Savoir-être/Soft skills - Adaptabilité',
      'Savoir-être/Soft Skill - Collaboration',
      'Savoir-être/Soft Skills - Créativité',
      'Compétences/Hard Skills - Blockchain',
      'Compétences/Hard Skills - Cloud Computing',
      'Compétences/Hard Skills - Raisonnement analytique',
      'Compétences/Hard Skills - Intelligence artificielle',
      'Compétences/Hard Skills : Design UX',
      'Compétences/Hard Skills - Marketing digital',
      'Compétences/Hard Skills - Design Thinking',
      'Compétences/Hard Skills - Gestion de projet digital',
      "Compétences/Hard Skills - Marketing d'affiliation",
      'Compétences/Hard Skills - Sciences numériques',
      'Compétences/Hard Skills - Production audiovisuelle',
      "Compétences/Hard Skills - L'art de la vente",
    ],
    'Bundle: HR, Learning and Development (FR)': [
      'E-learning - Dernières tendances et analyses',
      [
        'RH - Actualités et analyses',
        '/briefing/80438/rh-actualites-et-analyses/3-months',
      ],
      'Recrutement, embauche et acquisition des talents',
      'RH, L&D',
      "LMS, LXP et plateformes d'apprentissage",
      'Gamification/Ludification - Actualités et analyses',
      'EdTech',
      'Ressources humaines et People Strategy',
      'Gestion des talents',
      'Gestion de la performance',
      'Bien-être au travail',
      'Formation continue et apprentissage tout au long de la vie',
      'Design pédagogique - Actualités et conseils',
      'Engagement des employés',
      'IA, bots, Big Data et automatisation RH',
      "Culture d'entreprise",
    ],
    'Bundle: Leadership and Management (FR)': [
      'Manager et diriger une équipe',
      [
        'Équipes virtuelles, à distance et agiles',
        '/briefing/80396/equipes-virtuelles-a-distance-et-agiles/3-months',
      ],
      'Équipes haute performance',
      'Coaching et mentorat',
      'Réflexion stratégique',
      'Intelligence émotionnelle et QE pour les dirigeants',
      'Donner et recevoir du feedback',
      'Innovation et créativité pour les dirigeants',
      'Négocier et influencer',
      'Économie et commerce',
      'Engagement des collaborateurs',
      'Gestion des talents',
      'Gestion de la performance',
      "Stratégie d'entreprise",
      'Gestion de la chaîne logistique',
      'Piloter et gérer le changement',
      'Leaders et prise de décision',
      'Résilience des dirigeants',
      'Communication managériale',
      'Innovation de rupture',
      "Développer un état d'esprit de croissance",
    ],
    'Bundle: Personal development (FR)': [
      'Gestion du temps',
      'Équilibre vie professionnelle-vie privée',
      ['Télétravail', '/briefing/80574/teletravail/3-months'],
      'Présentations et prise de parole',
      'Résilience',
      'Intelligence émotionnelle et QE',
      'Prise de décision',
      'Développement de carrière',
      'Formation continue et apprentissage tout au long de la vie',
      'Communication et relations interpersonnelles',
      'Pensée créatrice',
      'Résolution de problèmes et esprit critique',
      'Culture du service client',
      'Productivité',
      'Diversité et intelligence culturelle',
      'Technologie - Nouveautés',
      "S'adapter au changement",
    ],
    'Bundle: Sales and Marketing (FR)': [
      [
        'Marketing - Actualités et meilleures pratiques',
        '/briefing/80471/marketing-actualites-et-meilleures-pratiques/3-months',
      ],
      'SEO et SEM',
      "Marketing d'influence",
      'Blogs - Conseils et astuces',
      'Marketing de contenu - Actualités et conseils',
      'Relations publiques',
      'Présentations et démos',
      'Pitchs et argumentaires',
      'Négociation efficace',
      'Politique de prix',
      'Gestion de compte, service et expérience client',
      'Référencement payant, PPC, AdWords',
      'Social selling, vente sociale',
      'Marketing B2B',
      'Marketing basé sur les données - Métriques, analyses, ROI',
      'Génération de leads et prospection',
      'Éviter les objections et y répondre',
      'Marketing des réseaux sociaux - Actualités et conseils',
      'Personas',
      'Planification des ventes',
      'Planification marketing',
      'Gestion de la relation fournisseurs',
    ],
    'Bundle: Technology (FR)': [
      [
        'Technologie - Actualités et nouveautés',
        '/briefing/80515/technologie-actualites-et-nouveautes/3-months',
      ],
      'IA - Dernières tendances et analyses',
      'API - Développement et actualités',
      'Réalité virtuelle',
      'Machine Learning - Nouveautés',
      'Design UX - Conseils et nouveautés',
      'Réalité augmentée',
      'Internet des objets',
      'SaaS - Nouveautés',
      "Création d'application",
      'Big Data et analytics',
      'Cloud Computing',
      'Gaming - Nouveautés',
      'Gestion de produit',
      'DevOps',
      'Blockchain',
    ],
  },
  it: {
    'Bundle: Business and Strategy (IT)': [
      'Strategia di business: tendenze e approfondimenti',
      'Innovazione, creatività e Design Thinking',
      [
        'Leadership di pensiero',
        '/briefing/82577/leadership-di-pensiero/3-months',
      ],
      'Imprenditorialità e startup: contenuti e tendenze del momento',
      'Project management: gestione di prodotti e progetti',
      'Growth Hacking',
      'Il futuro del lavoro',
      'Supply Chain Management',
      'Digital Transformation e Digital Disruption',
      'Gig Economy',
    ],
    'Bundle: Curated Content on Working and Living Well in the New Normal (IT)':
      [
        'Salute mentale e benessere sul luogo di lavoro',
        'Motivare e coinvolgere i dipendenti in Smart Working',
        'Team agili, remoti e virtuali',
        'Il futuro del lavoro',
        'Gestione della salute mentale durante la pandemia da coronavirus (COVID-19)',
        [
          'Smart Working: consigli per lavorare bene da casa',
          '/briefing/82665/smart-working-consigli-per-lavorare-bene-da-casa/3-months',
        ],
        'Homeschooling e Smart Working: consigli e suggerimenti',
        'Rafforzare la resilienza e adattarsi al cambiamento in circostanze eccezionali',
        'Work-Life balance',
        'Gestione del tempo',
        'Change Management: best practice, processi, consigli',
        'Consigli per organizzare meeting virtuali efficaci e produttivi',
      ],
    'Bundle: Future of Work in-demand Skills (IT)': [
      'Competenze trasversali/soft skill: intelligenza emotiva',
      'Competenze trasversali/soft skill: capacità di persuasione',
      'Competenze trasversali/soft skill: adattabilità al cambiamento',
      'Competenze trasversali/soft skill: capacità di lavorare in team',
      'Competenze trasversali/soft skill: creatività',
      'Competenze tecniche/hard skill: tecnologia blockchain',
      [
        'Competenze tecniche/hard skill: Cloud Computing',
        '/briefing/82547/competenze-tecnichehard-skill-cloud-computing/3-months ',
      ],
      'Competenze tecniche/hard skill: pensiero analitico',
      'Competenze tecniche/hard skill: Intelligenza Artificiale',
      'Competenze tecniche/hard skill: UX Design',
      'Competenze tecniche/hard skill: Digital Marketing',
      'Competenze tecniche/hard skill: Design Thinking',
      'Competenze tecniche/hard skill: Project Management digitale',
      'Competenze tecniche/hard skill: Affiliate Marketing',
      'Competenze tecniche/hard skill: calcolo ad alte prestazioni',
      'Competenze tecniche/hard skill: produzione di video',
      'Competenze tecniche/hard skill: abilità di vendita',
    ],
    'Bundle: HR, Learning and Development (IT)': [
      "Tecnologie per l'e-learning: approfondimenti e nuove tendenze",
      [
        'Risorse Umane (HR): tendenze e approfondimenti',
        '/briefing/82624/risorse-umane-hr-tendenze-e-approfondimenti/3-months',
      ],
      'Reclutamento del personale, assunzione e Talent Acquisition',
      'HR, apprendimento e crescita del personale',
      'LMS, LXP e piattaforme di apprendimento',
      'Gamification e formazione del personale: le ultime tendenze e gli approfondimenti più aggiornati',
      "Education Technology: tecnologie digitali per l'istruzione",
      'Strategia Risorse Umane e gestione del personale',
      'Talent Management',
      'Performance Management',
      'Benessere lavorativo',
      'Formazione continua e permanente',
      'Learning Design',
      'Coinvolgimento e motivazione del personale',
      'Intelligenza Artificiale (AI), Bot, Big Data, Automazione e Risorse Umane',
      "Company Culture: l'importanza della cultura aziendale",
    ],
    'Bundle: Leadership and Management (IT)': [
      [
        'Guidare e gestire team',
        '/briefing/82639/guidare-e-gestire-team/3-months',
      ],
      'Team agili, team remoti e team virtuali',
      'Team di lavoro ad alte prestazioni',
      'Coaching e Mentoring',
      'Strategic Thinking - Pensiero Strategico',
      'Intelligenza emotiva ed EQ per leader',
      'Dare e ricevere feedback',
      'Creatività e innovazione per leader',
      'Negoziazione e persuasione',
      'Fiuto per il business e senso degli affari',
      'Motivazione e coinvolgimento del personale',
      'Talent Management',
      'Performance Management',
      'Business Strategy',
      'Supply Chain Management',
      'Guidare e gestire il cambiamento',
      'Leadership e capacità decisionali',
      'Leadership e resilienza',
      'Leadership e comunicazione',
      'Digital Disruption',
      'Growth Mindset: sviluppare una mentalità di crescita',
    ],
    'Bundle: Personal development (IT)': [
      'Gestione del tempo',
      'Equilibrio lavoro-vita privata',
      'Telelavoro e team virtuali',
      'Abilità nel realizzare presentazioni e parlare in pubblico',
      ['Resilienza', '/briefing/82562/resilienza/3-months'],
      'Intelligenza emotiva ed EQ',
      'Decision Making',
      'Sviluppo della carriera',
      'Formazione continua e apprendimento permanente',
      'Capacità comunicative e relazionali',
      'Pensiero creativo',
      'Pensiero critico e problem solving',
      'Mentalità orientata al Customer Service',
      'Produttività',
      'Diversità e intelligenza culturale',
      'Nuove tendenze nel campo della tecnologia',
      'Adattarsi al cambiamento',
    ],
    'Bundle: Sales and Marketing (IT)': [
      [
        'Marketing: una panoramica',
        '/briefing/82601/marketing-una-panoramica/3-months',
      ],
      'SEO e SEM',
      'Influencer Marketing',
      'Blogging',
      'Marketing dei contenuti: consigli e tendenze',
      'Pubbliche Relazioni/PR',
      'Presentazioni efficaci',
      'Pitching',
      'Abilità negoziali',
      'Strategie di prezzo',
      'Gestione clienti, Servizio clienti, Customer Experience',
      'Paid Search, Pay Per Click e AdWords',
      'Social Selling',
      'Marketing B2B',
      'Data Driven Marketing: metriche, analisi, ROI',
      'Lead generation e prospezione delle vendite',
      'Gestire le obiezioni',
      'Social Media Marketing: tendenze e suggerimenti',
      'Buyer Persona e Customer Persona',
      'Sales Planning',
      'Marketing Planning: strutturare un piano di marketing',
      'Supplier Relationship Management',
    ],
    'Bundle: Technology (IT)': [
      [
        'Novità e tendenze nel campo della tecnologia',
        '/briefing/82585/novita-e-tendenze-nel-campo-della-tecnologia/3-months',
      ],
      'Intelligenza Artificiale: nuove tendenze e approfondimenti',
      'API: sviluppo e novità',
      'Virtual Reality (VR)',
      'Machine Learning: tendenze e approfondimenti',
      'User Experience Design: consigli e tendenze',
      'Augmented Reality (AR)',
      'Internet of Things (IOT)',
      'Software as a Service (SaaS): tendenze del settore',
      'Sviluppo di applicazioni',
      'Big Data Analytics',
      'Il Cloud Computing',
      'Gaming: tendenze e ultime novità',
      'Gestione di prodotti e progetti',
      'DevOps',
      'Blockchain',
    ],
  },
  pt: {
    'Bundle: Business and Strategy (PT)': [
      [
        'Estratégia empresarial: tendências e perspetivas',
        '/briefing/81699/estrategia-empresarial-tendencias-e-perspetivas/3-months',
      ],
      'Inovação, criatividade e Design Thinking',
      'Liderança de pensamento',
      'Startups e empreendedores - tendências',
      'Gestão de produtos e projetos',
      'Hacking de crescimento',
      'O futuro do trabalho',
      'Gestão da cadeia de abastecimento',
      'Transformação e disrupção digital',
      'Economia GIG',
    ],
    'Bundle: Curated Content on Working and Living Well in the New Normal (PT)':
      [
        [
          'Saúde mental e bem-estar em casa',
          '/briefing/81604/saude-mental-e-bem-estar-em-casa/3-months',
        ],
        'Motivação e envolvimento de colaboradores em equipas virtuais',
        'Equipas ágeis, remotas e virtuais',
        'O futuro do trabalho',
        'Gestão da saúde mental durante a Covid-19',
        'Principais dicas para teletrabalho',
        'Dicas para ensino doméstico durante o teletrabalho',
        'Desenvolver resiliência e adaptar-se à mudança em circunstâncias excecionais',
        'Equilíbrio entre vida pessoal e profissional',
        'Gestão do tempo',
        'Mudança de boas práticas e processos de gestão',
        'Dicas para reuniões virtuais eficazes e colaborativas',
      ],
    'Bundle: Future of Work in-demand Skills (PT)': [
      [
        'Soft Skill: Inteligência emocional',
        '/briefing/81726/soft-skill-inteligencia-emocional/3-months',
      ],
      'Soft Skill: Persuasão',
      'Soft Skill: Adaptabilidade',
      'Soft Skill: Colaboração',
      'Soft Skill: Criatividade',
      'Hard Skill: Blockchain',
      'Hard Skill: Computação em nuvem',
      'Hard Skill: Raciocínio analítico',
      'Hard Skill: Inteligência artificial',
      'Hard Skill: Design de experiência do utilizador',
      'Hard Skill: Marketing digital',
      'Hard Skill: Design Thinking',
      'Hard Skill: Gestão de projetos digitais',
      'Hard Skill: Marketing de afiliados',
      'Hard Skill: Computação científica',
      'Hard Skill: Produção de vídeo',
      'Hard Skill: Vendas',
    ],
    'Bundle: HR, Learning and Development (PT)': [
      [
        'Últimas tendências e perspetivas sobre tecnologias de aprendizagem',
        '/briefing/81644/ultimas-tendencias-e-perspetivas-sobre-tecnologias-de-aprendizagem/3-months',
      ],
      'RH: tendências e perspetivas',
      'Recrutamento, contratação e aquisição de talentos',
      'RH, Formação e Desenvolvimento',
      'Plataformas de LMS, LXP e aprendizagem',
      'Tendências e perspetivas de gamificação',
      'EdTech',
      'Estratégia de RH e pessoas',
      'Gestão de talentos',
      'Gestão de desempenho',
      'Bem-estar no trabalho',
      'Formação contínua e permanente',
      'Design instrucional - dicas e tendências',
      'Envolvimento dos funcionários',
      'IA, Bots, Big Data e Automação em RH',
      'Cultura da empresa',
    ],
    'Bundle: Leadership and Management (PT)': [
      [
        'Liderar e gerir equipas',
        '/briefing/81617/liderar-e-gerir-equipas/3-months',
      ],
      'Equipas ágeis, remotas e virtuais',
      'Equipas de alto desempenho',
      'Coaching e mentoring',
      'Pensamento estratégico',
      'Inteligência emocional e QE para líderes',
      'Dar e receber feedback',
      'Criatividade e inovação para líderes',
      'Negociação e influência',
      'Perspicácia nos negócios',
      'Envolvimento dos colaboradores',
      'Gestão de talentos',
      'Gestão do desempenho',
      'Estratégia empresarial',
      'Gestão da cadeia de abastecimento',
      'Liderar e gerir mudanças',
      'Liderança e tomada de decisões',
      'Liderança e resiliência',
      'Liderança e comunicação',
      'Disrupção digital',
      'Desenvolver uma mentalidade de crescimento',
    ],
    'Bundle: Personal development (PT)': [
      'Gestão de tempo',
      'Equilíbrio entre vida pessoal e profissional',
      [
        'Trabalho virtual e remoto',
        '/briefing/81711/trabalho-virtual-e-remoto/3-months',
      ],
      'Técnicas de apresentação e oratória',
      'Resiliência',
      'Inteligência emocional e QE',
      'Tomada de decisões',
      'Desenvolvimento da carreira',
      'Formação contínua e permanente',
      'Competências comunicacionais e interpessoais',
      'Pensamento criativo',
      'Resolução de problemas e pensamento crítico',
      'Mentalidade de serviço ao cliente',
      'Produtividade',
      'Diversidade e inteligência cultural',
      'Tendências tecnológicas',
      'Adaptação à mudança',
    ],
    'Bundle: Sales and Marketing (PT)': [
      [
        'Marketing: visão geral',
        '/briefing/81660/marketing-visao-geral/3-months',
      ],
      'SEO e SEM',
      'Marketing de influência',
      'Blogues',
      'Marketing de conteúdo: dicas e tendências',
      'Relações Públicas/RP',
      'Apresentações e demonstrações de vendas',
      'Pitching',
      'Negociação efetiva',
      'Estratégias de preços',
      'Gestão de contas, atendimento ao cliente, experiência do cliente',
      'Pesquisas pagas, PPC e AdWords',
      'Vendas sociais',
      'Marketing B2B',
      'Marketing orientado por dados: métricas, análises e ROI',
      'Geração e prospeção de leads',
      'Prevenção e tratamento de objeções',
      'Marketing de redes sociais: dicas e tendências',
      'Personas de compradores e clientes',
      'Planeamento de vendas',
      'Planeamento de marketing',
      'Gestão de relações com fornecedores',
    ],
    'Bundle: Technology (PT)': [
      [
        'Novidades e tendências tecnológicas',
        '/briefing/81683/novidades-e-tendencias-tecnologicas/3-months',
      ],
      'IA: últimas tendências e perspetivas',
      'Desenvolvimento e novidades sobre API',
      'Realidade Virtual (VR)',
      'Tendências de aprendizagem automática',
      'Design de experiência do utilizador: dicas e tendências',
      'Realidade Aumentada (RA)',
      'Internet das coisas (IOT)',
      'Tendências de SaaS',
      'Desenvolvimento de aplicações',
      'Big Data e análise',
      'Computação em nuvem',
      'Gaming: tendências',
      'Gestão de produtos',
      'DevOps',
      'Blockchain',
    ],
  },
  sv: {
    'Bundle: Business and Strategy (SV)': [
      'Affärsstrategi: Trender och insikter',
      [
        'Innovation, kreativitet och designtänk',
        '/briefing/82707/innovation-kreativitet-och-designtank/3-months',
      ],
      'Tankeledarskap',
      'Startups och entreprenörer – trendande innehåll',
      'Produkt- och projektledning',
      'Growth hacking',
      'Framtiden för arbete',
      'Supply chain management',
      'Digital transformation och disruption',
      'Gigekonomi',
    ],
    'Bundle: Curated Content on Working and Living Well in the New Normal (SV)':
      [
        [
          'Mental hälsa och välmående på arbetsplatsen',
          '/briefing/82791/mental-halsa-och-valmaende-pa-arbetsplatsen/3-months',
        ],
        'Personalens motivation och engagemang vid distansarbete',
        'Agila team, virtuella team och distansteam',
        'Framtidens arbete',
        'Ta hand om din mentala hälsa under COVID-19',
        'Bästa tipsen om att jobba hemma',
        'Tips om hemundervisning när du både du och barnen jobbar hemma',
        'Hantera förändringar i kristid',
        'Balans mellan arbete och fritid',
        'Tidsplanering',
        'Förändringshantering – bästa praxis och processer',
        'Tips för effektiva virtuella möten',
      ],
    'Bundle: Future of Work in-demand Skills (SV)': [
      'Mjuka kompetenser: Emotionell intelligens',
      'Mjuka kompetenser: Att lyckas med att övertyga',
      [
        'Mjuka kompetenser: Anpassningsbarhet',
        '/briefing/82674/mjuka-kompetenser-anpassningsbarhet/3-months',
      ],
      'Mjuka kompetenser: Samarbete',
      'Mjuka kompetenser: Kreativitet',
      'Hårda kompetenser: Blockchain',
      'Hårda kompetenser: Molntjänster',
      'Hårda kompetenser: Analytiskt resonemang',
      'Hårda kompetenser: Artificiell intelligens',
      'Hårda kompetenser: UX-design',
      'Hårda kompetenser: Digital marknadsföring',
      'Hårda kompetenser: Design thinking',
      'Hårda kompetenser: Digital projektledning',
      'Hårda kompetenser: Affiliatemarknadsföring',
      'Hårda kompetenser: Scientific Computing',
      'Hårda kompetenser: Videoproduktion',
      'Hårda kompetenser: Försäljning',
    ],
    'Bundle: HR, Learning and Development (SV)': [
      [
        'Senaste nytt om teknologier för inlärning',
        '/briefing/82754/senaste-nytt-om-teknologier-for-inlarning/3-months',
      ],
      'HR: trender och insikter',
      'Rekrytera, anställa och värva talang',
      'HR, Learning and development',
      'Lärplattformar',
      'Gamification – trender och insikter',
      'EdTech – nya tekniker för lärande',
      'HR: People strategy',
      'Hantera och leda talang',
      'Performance management',
      'Må bra på jobbet',
      'Livslångt lärande',
      'Design för lärande – trender och tips',
      'Personalengagemang',
      'AI, big data och automatisering inom HR',
    ],
    'Bundle: Leadership and Management (SV)': [
      ['Att leda team', '/briefing/82770/att-leda-team/3-months'],
      'Agila team, virtuella team och distansteam',
      'Högpresterande team',
      'Coachning och mentorskap',
      'Strategiskt tänkande',
      'Emotionell intelligens och EQ för ledare',
      'Att ge och få feedback',
      'Kreativitet och innovation för ledare',
      'Att förhandla och påverka',
      'Business sense',
      'Personalengagemang',
      'Hantera och leda talang',
      'Performance management',
      'Affärstrategier',
      'Supply chain management',
      'Leda och hantera förändring',
      'Att leda och fatta beslut',
      'Leaderskap och resiliens',
      'Leaderskap och kommunikation',
      'Digital disruption',
      'Att utveckla ett dynamiskt tänkesätt',
    ],
    'Bundle: Personal development (SV)': [
      'Tidsplanering',
      'Balans mellan arbete och fritid',
      ['Arbeta på distans', '/briefing/82691/arbeta-pa-distans/3-months'],
      'Att hålla presentationer och att tala inför andra',
      'Resiliens',
      'Emotionell intelligens och EQ',
      'Beslutsfattande',
      'Karriärutveckling',
      'Livslångt lärande',
      'Kommunikation och mellanmänsklig kommunikation',
      'Kreativt tänkande',
      'Problemlösning och kritiskt tänkande',
      'Kundservice',
      'Produktivitet',
      'Mångfald och kulturell intelligens',
      'Teknologitrender',
      'Anpassning till förändring',
    ],
    'Bundle: Sales and Marketing (SV)': [
      [
        'Marknadsföring: översikt',
        '/briefing/82732/marknadsforing-oversikt/3-months',
      ],
      'SEO och SEM',
      'Influencers och marknadsföring',
      'Bloggande',
      'Content marketing: trender och tips',
      'Public Relations/ PR',
      'Säljpresentationer',
      'Att pitcha',
      'Effektiva förhandlingar',
      'Strategier för prissättning',
      'Account management, kundservice, kundupplevelse',
      'Betald sökning, pay per click och AdWords',
      'Social selling',
      'B2B-marknadsföring',
      'Datadriven marknadsföring: mäta, analysera och ta fram ROI',
      'Generera leads och prospects',
      'Förebygga och hantera kundinvändningar',
      'Marknadsföring i sociala medier: trender och tips',
      'Olika kundtyper',
      'Säljplanering',
      'Marknadsföringsplanering',
      'Leverantörsrelationer',
    ],
    'Bundle: Technology (SV)': [
      [
        'Teknologi – nyheter och trender',
        '/briefing/82716/teknologi-nyheter-och-trender/3-months',
      ],
      'AI: senaste trenderna och insikterna',
      'API-utveckling och nyheter',
      'Virtual Reality (VR)',
      'Trender inom maskininlärning',
      'UX-design: tips och trender',
      'Augmented Reality (AR)',
      'Internet of Things eller sakernas internet',
      'Trender inom SaaS',
      'Apputveckling',
      'Big Data och analyser',
      'Molnet',
      'Spel: trender',
      'Produktledning',
      'DevOps',
      'Blockchain',
    ],
  },
}

const languages = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  pt: 'Portugese',
  sv: 'Swedish',
}

export default class Experts extends React.Component {
  constructor() {
    super()
    this.state = {
      lang: 'en',
    }
  }
  render() {
    return (
      <div className={pageStyles.section}>
        <div className={pageStyles.sectionHeadingThin}>
          Our briefing bundles
        </div>

        <div className={pageStyles.textContainer}>
          <p>
            Click on the link in each bundle category to view a sample briefing
            within Anders Pink.
          </p>
          <div className="d-flex flex-row-reverse">
            <select
              className="mb-2"
              value={this.state.lang}
              onChange={(e) => this.setState({ lang: e.target.value })}
            >
              {Object.entries(languages).map(([lang, label]) => (
                <option value={lang} key={lang}>
                  {label} ({lang})
                </option>
              ))}
            </select>
          </div>

          <Accordion defaultActiveKey="0">
            {Object.keys(bundles[this.state.lang]).map((bundleName, i) => (
              <Card key={bundleName}>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={String(i)}
                  >
                    {bundleName}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={String(i)}>
                  <Card.Body>
                    <ul>
                      {bundles[this.state.lang][bundleName].map(
                        (briefing, i) => (
                          <li>
                            {Array.isArray(briefing) ? (
                              <a
                                href={briefing[1]}
                                target="_blank"
                                className="link"
                              >
                                {briefing[0]}
                              </a>
                            ) : (
                              briefing
                            )}
                          </li>
                        ),
                      )}
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </div>
      </div>
    )
  }
}
