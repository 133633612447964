import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import apolloClient from 'apolloClient'
import gql from 'graphql-tag'

const BriefingOrder = () => {
  const [briefings, setBriefings] = useState([])
  const [briefingsUpdated, setBriefingsUpdated] = useState(false)

  const session = useSelector(({ app }) => app.appQueryResult)

  useEffect(() => {
    if (
      !briefingsUpdated &&
      briefings.length === 0 &&
      session.briefings.length > 0
    ) {
      // Make an initial order
      let briefings = session.briefings

      setBriefings([...briefings])
    } else {
      setBriefingsUpdated(false)
    }
  }, [briefingsUpdated, session.briefings, briefings])

  const handlePositionChange = async (id, action) => {
    const newBriefings = [...briefings]
    const index = briefings.findIndex((b) => b.id === id)

    if (action == 'moveUp') {
      // move it up by one
      if (index > 0) {
        // swap with one above it
        let temp = newBriefings[index]
        newBriefings[index] = newBriefings[index - 1]
        newBriefings[index - 1] = temp
      }
    } else {
      // move it down by one
      if (index < newBriefings.length - 1) {
        // swap with one below it
        let temp = newBriefings[index]
        newBriefings[index] = newBriefings[index + 1]
        newBriefings[index + 1] = temp
      }
    }
    setBriefings(newBriefings)
    setBriefingsUpdated(true)
  }

  const saveUpdatedPositions = () => {
    let ids = briefings.map((briefing) => parseInt(briefing.id))

    apolloClient()
      .mutate({
        mutation: gql`
          mutation updateBriefingPosition($briefings: [Int]!) {
            updateBriefingPosition(briefings: $briefings) {
              id
              position
            }
          }
        `,
        variables: {
          briefings: ids,
        },
      })
      .then((response) => {
        console.log(response)
        // handle response

        window.location = '/briefings'
      })
      .catch((error) => {
        console.log(error)
        // handle error
      })
  }

  return (
    <div
      style={{
        maxWidth: 720,
      }}
    >
      <h4>Order Briefings</h4>
      <br />
      {briefings.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>{item.name}</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 30,
                height: 30,
                backgroundColor: '#f4f4f4',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: 10,
              }}
              onClick={() => handlePositionChange(item.id, 'moveUp')}
            >
              <i className="fa fa-arrow-up" style={{ color: '#444444' }} />
            </div>
            <div
              style={{
                display: 'flex',
                width: 30,
                height: 30,
                backgroundColor: '#f4f4f4',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handlePositionChange(item.id, 'moveDown')}
            >
              <i className="fa fa-arrow-down" style={{ color: '#444444' }} />
            </div>
          </div>
        </div>
      ))}

      <div style={{ marginTop: 20 }}>
        <button
          className="btn btn-primary"
          onClick={() => saveUpdatedPositions()}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default BriefingOrder
