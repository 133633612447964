exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".BoardNameBlock__root___38JMF {\n  width: 240px;\n  height: 150px;\n  margin: 10px;\n  background-color: #505050;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: 50%;\n  position: relative;\n  border-radius: 3px;\n}\n.BoardNameBlock__inner___3CTET {\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: relative;\n  display: block;\n  -webkit-box-shadow: inset 0px 0px 100px 0px black;\n          box-shadow: inset 0px 0px 100px 0px black;\n  border-radius: 3px;\n}\n.BoardNameBlock__text___1SAeK {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  color: #fff;\n  right: 0;\n  top: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  text-align: center;\n}\n.BoardNameBlock__textInner___2eakb {\n  padding: 15px;\n}\n.BoardNameBlock__name___3_rdK {\n  font-size: 1.3rem;\n  letter-spacing: 0.02em;\n}\n.BoardNameBlock__list___lISop {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: -10px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "BoardNameBlock__root___38JMF",
	"inner": "BoardNameBlock__inner___3CTET",
	"text": "BoardNameBlock__text___1SAeK",
	"textInner": "BoardNameBlock__textInner___2eakb",
	"name": "BoardNameBlock__name___3_rdK",
	"list": "BoardNameBlock__list___lISop"
};