import React, { Component } from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'

import styles from './EditBriefingForm.css'
import ToolTip from 'app/components/ToolTip'
import FixedModal from 'app/components/FixedModal'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import PreviewBriefing from 'briefings/create/containers/PreviewBriefing'
import WordList from 'briefings/create/containers/WordList'
import FoundSources from 'briefings/create/containers/FoundSources'
import sourceTypes, { renderSourceString } from 'briefings/config/sourceTypes'
import PreviewSource from 'briefings/create/containers/PreviewSource'
import AddSourceModal from 'briefings/create/containers/AddSourceModal'
import IncludeWordSuggestions from 'briefings/create/containers/IncludeWordSuggestions'
import ExcludeWordSuggestions from 'briefings/create/containers/ExcludeWordSuggestions'
import humaniseList from 'app/library/humaniseList'
import merge from 'lodash/merge'
import validator from 'validator'
import CheckboxGroup from 'app/components/CheckboxGroup'
import { Link } from 'react-router'
import isEqual from 'lodash/isEqual'
import TourContainer from './types/TourContainer'

import { Form } from 'react-bootstrap'

import CrawlerBriefingForm from './types/CrawlerBriefingForm'
import DomainBuckets from './DomainBuckets'

import { FORM_NAMES } from './constants'
import { hasPendingTrainings } from './types/Training'

const selector = formValueSelector(FORM_NAMES.EDIT_BRIEFING)

const languages = [
  // {code: 'zh', name:'Chinese', cats: [],},
  // {code: 'nl', name:'Dutch', cats: [],},
  {
    code: 'en',
    name: 'English',
    cats: [
      {
        label: 'General business (top 6k business, tech, news and more)',
        value: 'business',
      },
      { label: 'Sales', value: 'sales' },
      { label: 'Leadership', value: 'leadership' },
      { label: 'HR and Learning', value: 'hr-and-learning' },
      { label: 'Marketing', value: 'marketing' },
      { label: 'Technology', value: 'technology' },
    ],
  },
  { code: 'fr', name: 'French', cats: [] },
  { code: 'it', name: 'Italian', cats: [] },
  { code: 'de', name: 'German', cats: [] },
  { code: 'pt', name: 'Portuguese', cats: [] },
  { code: 'es', name: 'Spanish', cats: [] },
  { code: 'sv', name: 'Swedish', cats: [] },
  { code: 'ar', name: 'Arabic', cats: [] },
  { code: 'zh', name: 'Chinese (beta)', cats: [] },
  // {code: 'pl', name:'Polish', cats: [],},
  // {code: 'hu', name:'Hungarian', cats: [],},
  // {code: 'ru', name:'Russian', cats: [],},
  // {code: 'ro', name:'Romanian', cats: [],},
  // {code: 'uk', name:'Ukaranian', cats: [],},
  // {code: 'sk', name:'Slovak', cats: [],},
  // {code: 'vi', name:'Vietnamese', cats: [],},
  // {code: 'id', name:'Indonesian', cats: [],},
]

const contentCategories = [
  {
    label: 'Arts',
    value: 'arts',
  },
  {
    label: 'Construction',
    value: 'construction',
  },
  {
    label: 'Design',
    value: 'design',
  },
  {
    label: 'Drinks, Food & Tobacco',
    value: 'drinks_food_and_tobacco',
  },
  {
    label: 'Environment',
    value: 'environment',
  },
  {
    label: 'Finance & Accounting',
    value: 'finance_and_accounting',
  },
  {
    label: 'Healthcare & Social',
    value: 'healthcare_and_social',
  },
  {
    label: 'Hospitality',
    value: 'hospitality',
  },
  {
    label: 'HR & Learning',
    value: 'hr_and_learning',
  },
  {
    label: 'IT',
    value: 'it',
  },
  {
    label: 'Languages',
    value: 'languages',
  },
  {
    label: 'Leadership & Business',
    value: 'leadership_and_business',
  },
  {
    label: 'Legal',
    value: 'legal',
  },
  {
    label: 'Manufacturing & Repair',
    value: 'manufacturing_and_repair',
  },
  {
    label: 'Marketing',
    value: 'marketing',
  },
  {
    label: 'Military',
    value: 'military',
  },
  {
    label: 'Sales',
    value: 'sales',
  },
  {
    label: 'Science',
    value: 'science',
  },
  {
    label: 'Sport',
    value: 'sport',
  },
  {
    label: 'Teaching',
    value: 'teaching',
  },
  {
    label: 'Transport & Logistics',
    value: 'transport_and_logistics',
  },
  {
    label: 'Veterinary & Farming',
    value: 'veterinary_and_farming',
  },
]

@connect((state, props) => ({
  isSiteAdmin: state.app.appQueryResult.user.isSiteAdmin,
  ...selector(
    state,
    'type',
    'keywordFields.keywords',
    'keywordFields.domains',
    'keywordFields.useBusinessDomains',
    'keywordFields.categories',
    'looseFields.query',
    'looseFields.domains',
    'sourceFields.sources',
    'language',
  ),
  domainListType: selector(state, 'domainListType'),
}))
@graphql(gql`
  query {
    categories {
      id
      name
    }
  }
`)
export default class EditBriefingForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
    blockedArticles: [],
    creating: true,
    viaApi: false,
  }
  constructor(props) {
    super(props)
    this.state = {
      isAddSourceModalOpen: false,
      isSaveModalOpen: false,
      isSaveTrainingModalOpen: false,
      isAdvancedOpen: false,
      findingSourcesValue: null,
      previewedSource: null,
      isSelectingLanguage: false,
      superMode:
        props.type === 'keywordsV2' ||
        (props.creating && props.crawlerSearchEnabled),
    }
    if (props.creating && props.crawlerSearchEnabled) {
      props.change('type', 'keywordsV2')
    }
  }
  componentDidMount() {
    this.handleCategories()
  }
  handleCategories() {
    if (this.props.type === 'keywords') {
      const lang = languages.find((lang) => lang.code === this.props.language)

      // If it doesn't have business set, and we have use business on, disable..
      const hasBusiness = lang.cats.find((c) => c.value === 'business')
      if (!hasBusiness && this.props.keywordFields.useBusinessDomains) {
        this.props.change('keywordFields.useBusinessDomains', false)
      }

      // Set categories to be a filtered list
      let categories = this.props.keywordFields.categories.filter((c) => {
        return lang.cats.find((cat) => cat.value === c)
      })
      if (!isEqual(categories, this.props.keywordFields.categories)) {
        this.props.change('keywordFields.categories', categories)
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      !prevProps.keywordFields.useBusinessDomains &&
      !!this.props.keywordFields.useBusinessDomains &&
      this.props.keywordFields.categories.length > 0
    ) {
      // Blank the categories
      this.props.change('keywordFields.categories', [])
    }
    if (
      prevProps.keywordFields.categories.length === 0 &&
      this.props.keywordFields.categories.length >= 1 &&
      !!this.props.keywordFields.useBusinessDomains
    ) {
      // Blank the useBusinessDomains
      this.props.change('keywordFields.useBusinessDomains', false)
    }

    setTimeout(() => {
      this.handleCategories()
    }, 200)
  }
  render() {
    const sourceDomains = this.props.sourceFields.sources.filter(
      (source) => source.type === 'domain',
    )

    const lang = languages.find((lang) => lang.code === this.props.language)
    const looseEnabled = this.props.canUseLooseSearch
    return (
      <div>
        <div className={styles.oldBriefingTypes}>
          {!this.props.creating && (
            <div className={styles.editInfo}>
              <div className="form-group">
                <label>
                  <strong>Briefing name</strong>
                </label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  <strong>Description</strong>
                </label>
                <Field
                  name="description"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          )}

          {!this.state.superMode && (
            <div className="form-group">
              <label>
                <strong>Step 1: Select your briefing type</strong>
              </label>

              <div className={styles.options}>
                <div
                  className={[
                    styles.option,
                    this.props.type === 'keywords' ? styles.optionChecked : '',
                  ].join(' ')}
                  onClick={() => {
                    this.props.change('type', 'keywords')
                  }}
                >
                  <div
                    className={[
                      styles.radioButton,
                      this.props.type === 'keywords'
                        ? styles.radioButtonChecked
                        : '',
                    ].join(' ')}
                  />

                  <div className={styles.optionContent}>
                    <div>Simple</div>
                    <div className={styles.optionDesc}>
                      Get only articles that match your topics, then filter down
                      further
                    </div>
                  </div>
                </div>

                <div
                  className={[
                    styles.option,
                    this.props.type === 'sources' ? styles.optionChecked : '',
                  ].join(' ')}
                  onClick={() => {
                    this.props.change('type', 'sources')
                  }}
                >
                  <div
                    className={[
                      styles.radioButton,
                      this.props.type === 'sources'
                        ? styles.radioButtonChecked
                        : '',
                    ].join(' ')}
                  />

                  <div className={styles.optionContent}>
                    <div>Advanced</div>
                    <div className={styles.optionDesc}>
                      Get all articles from the sources you select (domains, RSS
                      feeds, topics etc), then filter down
                    </div>
                  </div>
                </div>

                {looseEnabled && (
                  <div
                    className={[
                      styles.option,
                      this.props.type === 'loose' ? styles.optionChecked : '',
                    ].join(' ')}
                    onClick={() => {
                      this.props.change('type', 'loose')
                    }}
                    style={{ flex: 0.7 }}
                  >
                    <div
                      className={[
                        styles.radioButton,
                        this.props.type === 'loose'
                          ? styles.radioButtonChecked
                          : '',
                      ].join(' ')}
                    />

                    <div className={styles.optionContent}>
                      <div>Loose</div>
                      <div className={styles.optionDesc}>
                        (beta) Find results based on a single query
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {this.props.type === 'loose' && (
            <div>
              <div className={styles.stepContainer}>
                <div className={styles.stepTitle}>
                  Step 2: Enter your query{' '}
                  <ToolTip>
                    Enter any term here to start your briefing. Keep your
                    briefing focused by using just one or two related terms,
                    e.g. machine learning.
                  </ToolTip>
                </div>
                <div style={{ fontSize: '0.8em' }}>
                  Enter your search query, e.g. "machine learning"
                </div>
                <div>
                  <Field
                    name="tempQuery"
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => {
                      clearTimeout(this.looseQueryTimeoutId)
                      this.looseQueryTimeoutId = setTimeout(() => {
                        this.props.change(
                          'looseFields.query',
                          e.currentTarget.value,
                        )
                      }, 2000)
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.props.change(
                          'looseFields.query',
                          e.currentTarget.value,
                        )
                        clearTimeout(this.looseQueryTimeoutId)
                      }
                    }}
                    onBlur={(e) => {
                      this.props.change(
                        'looseFields.query',
                        e.currentTarget.value,
                      )
                      clearTimeout(this.looseQueryTimeoutId)
                    }}
                  />
                </div>

                <div className={styles.languageContainer}>
                  <label>
                    Show articles written in language:{' '}
                    {!this.state.isSelectingLanguage ? (
                      <span
                        className={styles.language}
                        onClick={() =>
                          this.setState({ isSelectingLanguage: true })
                        }
                      >
                        {lang.name}
                      </span>
                    ) : null}
                  </label>
                  <ToolTip>
                    We'll find content published in your chosen language.
                    <br />
                    <br />
                    <strong>Tip</strong>: You can enter any language terms in
                    the topic and other fields, and any TLD in the sites filter.
                    Keep your briefing to one language to keep it focused.
                  </ToolTip>
                  {this.state.isSelectingLanguage && (
                    <Field
                      name="language"
                      component="select"
                      className="form-control"
                      onChange={() => {
                        this.setState({ isSelectingLanguage: false })
                      }}
                    >
                      {languages.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                          {lang.name}
                        </option>
                      ))}
                    </Field>
                  )}
                </div>

                <div className={styles.stepContainer}>
                  <div className={styles.stepTitle}>Choose any categories</div>
                  <div style={{ fontSize: '0.8em' }}>
                    <CheckboxGroup
                      name="looseFields.categories"
                      options={contentCategories}
                    />
                  </div>
                </div>
              </div>

              {this.props.looseFields.query.length > 0 && (
                <div className={styles.stepContainer}>
                  <div className={styles.stepTitle}>
                    Step 3: Further filter by words (optional)
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Article titles must <strong>not</strong> contain any of
                      the following words:
                      <ToolTip>
                        Any content item containing these words in the title
                        won’t appear. E.g. jobs, courses, webinars. Press enter
                        after each word.
                        <br />
                        <br />
                        <strong>Tip</strong>: Use our content type lists (see:
                        "useful words to exclude" below) to get suggestions.
                      </ToolTip>
                    </div>
                    <WordList
                      field="looseFields.excludedWords"
                      newField="excludedWordsNew"
                      change={this.props.change}
                    />
                    <ExcludeWordSuggestions
                      field="looseFields.excludedWords"
                      onAdd={(word) => {
                        this.props.array.push('looseFields.excludedWords', word)
                      }}
                    />
                  </div>
                </div>
              )}

              {this.props.looseFields.query.length > 0 && (
                <div>
                  <div className={styles.stepContainer}>
                    <div className={styles.stepTitle}>
                      Step 4: Limit to certain sites (optional)
                      <ToolTip>
                        Enter any URL to bring in content from the domain. Use
                        our pre-made lists to get started, then add your own
                        preferred sites.
                        <br />
                        <br />
                        <strong>Tip</strong>: want to add Twitter or RSS? Use
                        the advanced briefing option.
                      </ToolTip>
                    </div>
                    <div style={{ fontSize: '0.8em' }}>
                      You can see content from around the web, or narrow your
                      briefing to specific sites.
                      <br />
                      <br />
                      Enter the sites you only want to see content from (e.g.
                      forbes.com, bbc.com/science)
                    </div>
                    <div>
                      <WordList
                        field="looseFields.domains"
                        newField="domainsNew"
                        change={this.props.change}
                        validation={(entry) => {
                          if (
                            !validator.isURL(entry, {
                              protocols: [],
                              require_protocol: false,
                              allow_underscores: true,
                            })
                          ) {
                            return 'Please enter a valid domain (without the http or www), e.g. forbes.com'
                          }
                          return null
                        }}
                        map={(entry) => entry.replace(/^(www\.)/, '')}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.props.looseFields.query.length > 0 && (
                <div className={styles.stepContainer}>
                  <div className={styles.stepTitle}>
                    Step 5: Block domains (optional)
                    <ToolTip>
                      Sites you add here won’t appear in your briefing.
                      <br />
                      <br />
                      <strong>Tip</strong>: You may want to block paywall sites
                      such as The Wall Street Journal (wsj.com) and Financial
                      Times (ft.com)
                    </ToolTip>
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Block articles that come from specific domains, such as
                      job.com
                    </div>
                    <WordList
                      field="looseFields.blockedDomains"
                      newField="blockedDomainsNew"
                      change={this.props.change}
                      validation={(entry) => {
                        if (
                          !validator.isURL(entry, {
                            protocols: [],
                            require_protocol: false,
                            allow_underscores: true,
                          })
                        ) {
                          return 'Please enter a valid domain (without the http or www), e.g. forbes.com'
                        }
                        return null
                      }}
                      map={(entry) => entry.replace(/^(www\.)/, '')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {this.props.type === 'keywords' && (
            <div>
              <div className={styles.stepContainer}>
                <div className={styles.stepTitle}>
                  Step 2: Add your topics{' '}
                  <ToolTip>
                    Enter any term here to start your briefing. Keep your
                    briefing focused by using just one or two related terms,
                    e.g. machine learning, AI. Press enter after each one.
                    <br />
                    <br />
                    <strong>Tip</strong>: If we find a pre-made briefing that
                    matches your topic, we'll show you in the preview panel.
                  </ToolTip>
                </div>
                <div style={{ fontSize: '0.8em' }}>
                  Enter the topics that you're interested in, e.g. big data,
                  healthcare, cycling...
                </div>
                <div>
                  <WordList
                    field="keywordFields.keywords"
                    newField="keywordsNew"
                    change={this.props.change}
                  />
                </div>

                <div className={styles.languageContainer}>
                  <label>
                    Show articles written in language:{' '}
                    {!this.state.isSelectingLanguage ? (
                      <span
                        className={styles.language}
                        onClick={() =>
                          this.setState({ isSelectingLanguage: true })
                        }
                      >
                        {lang.name}
                      </span>
                    ) : null}
                  </label>
                  <ToolTip>
                    We'll find content published in your chosen language.
                    <br />
                    <br />
                    <strong>Tip</strong>: You can enter any language terms in
                    the topic and other fields, and any TLD in the sites filter.
                    Keep your briefing to one language to keep it focused.
                  </ToolTip>
                  {this.state.isSelectingLanguage && (
                    <Field
                      name="language"
                      component="select"
                      className="form-control"
                      onChange={() => {
                        this.setState({ isSelectingLanguage: false })
                      }}
                    >
                      {languages.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                          {lang.name}
                        </option>
                      ))}
                    </Field>
                  )}
                </div>
              </div>

              {this.props.keywordFields.keywords.length > 0 && (
                <div className={styles.stepContainer}>
                  <div className={styles.stepTitle}>
                    Step 3: Further filter by words (optional)
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Article titles also must contain one or more of the
                      following words:
                      <ToolTip>
                        Only content that has one of these terms AND one of your
                        topics in the title will appear. Press enter after each
                        word.
                        <br />
                        <br />
                        <strong>Tip</strong>: Use our content type lists (see:
                        "useful words to include" below) to get suggestions.
                      </ToolTip>
                    </div>
                    <WordList
                      field="keywordFields.includedWords"
                      newField="includedWordsNew"
                      change={this.props.change}
                    />
                    <IncludeWordSuggestions
                      field="keywordFields.includedWords"
                      onAdd={(word) => {
                        this.props.array.push(
                          'keywordFields.includedWords',
                          word,
                        )
                      }}
                    />
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Article titles must <strong>not</strong> contain any of
                      the following words:
                      <ToolTip>
                        Any content item containing these words in the title
                        won’t appear. E.g. jobs, courses, webinars. Press enter
                        after each word.
                        <br />
                        <br />
                        <strong>Tip</strong>: Use our content type lists (see:
                        "useful words to exclude" below) to get suggestions.
                      </ToolTip>
                    </div>
                    <WordList
                      field="keywordFields.excludedWords"
                      newField="excludedWordsNew"
                      change={this.props.change}
                    />
                    <ExcludeWordSuggestions
                      field="keywordFields.excludedWords"
                      onAdd={(word) => {
                        this.props.array.push(
                          'keywordFields.excludedWords',
                          word,
                        )
                      }}
                    />
                  </div>
                </div>
              )}

              {this.props.keywordFields.keywords.length > 0 && (
                <div>
                  <div className={styles.stepContainer}>
                    <div className={styles.stepTitle}>
                      Step 4: Limit to certain sites (optional)
                      <ToolTip>
                        Enter any URL to bring in content from the domain. Use
                        our pre-made lists to get started, then add your own
                        preferred sites.
                        <br />
                        <br />
                        <strong>Tip</strong>: want to add Twitter or RSS? Use
                        the advanced briefing option.
                      </ToolTip>
                    </div>
                    <div style={{ fontSize: '0.8em' }}>
                      You can see content from around the web, or narrow your
                      briefing to specific sites.
                      <br />
                      <br />
                      Enter the sites you only want to see content from (e.g.
                      forbes.com, bbc.com/science)
                    </div>
                    <div>
                      <WordList
                        field="keywordFields.domains"
                        newField="domainsNew"
                        change={this.props.change}
                        validation={(entry) => {
                          if (
                            !validator.isURL(entry, {
                              protocols: [],
                              require_protocol: false,
                              allow_underscores: true,
                            })
                          ) {
                            return 'Please enter a valid domain (without the http or www), e.g. forbes.com'
                          }
                          return null
                        }}
                        map={(entry) => entry.replace(/^(www\.)/, '')}
                      />
                    </div>

                    {lang.cats.length > 0 && (
                      <div>
                        <div
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            fontSize: '0.8em',
                          }}
                        >
                          And/Or see content only from our curated lists. You
                          can combine these with any sites you’ve chosen above.
                        </div>

                        {lang.cats.find((c) => c.value === 'business') && (
                          <div
                            style={{
                              marginTop: '0px',
                              fontSize: '0.8em',
                            }}
                          >
                            <div>
                              <label>
                                <Field
                                  name="keywordFields.useBusinessDomains"
                                  component="input"
                                  type="checkbox"
                                  normalize={(v) => {
                                    return !!v ? true : false
                                  }}
                                />
                                {' ' +
                                  lang.cats.find((c) => c.value === 'business')
                                    .label}
                              </label>
                            </div>
                          </div>
                        )}

                        {lang.cats.filter((c) => c.value !== 'business')
                          .length > 0 && (
                          <div style={{ fontSize: '0.8em' }}>
                            <CheckboxGroup
                              name="keywordFields.categories"
                              options={lang.cats.filter(
                                (c) => c.value !== 'business',
                              )}
                            />
                          </div>
                        )}

                        <div
                          style={{
                            marginTop: '10px',
                            fontSize: '0.8em',
                            color: '#888',
                          }}
                        >
                          Want to suggest an additional list or site?{' '}
                          <Link className="link" target="_blank" to="/contact">
                            Let us know
                          </Link>
                          .
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.props.keywordFields.keywords.length > 0 &&
                (this.props.keywordFields.domains.length === 0 ||
                  this.props.keywordFields.useBusinessDomains ||
                  (this.props.keywordFields.categories &&
                    this.props.keywordFields.categories.length >= 1)) && (
                  <div className={styles.stepContainer}>
                    <div className={styles.stepTitle}>
                      Step 5: Block domains (optional)
                      <ToolTip>
                        Sites you add here won’t appear in your briefing.
                        <br />
                        <br />
                        <strong>Tip</strong>: You may want to block paywall
                        sites such as The Wall Street Journal (wsj.com) and
                        Financial Times (ft.com)
                      </ToolTip>
                    </div>

                    <div className={styles.filterWordSection}>
                      <div className={styles.filterText}>
                        Block articles that come from specific domains, such as
                        job.com
                      </div>
                      <WordList
                        field="keywordFields.blockedDomains"
                        newField="blockedDomainsNew"
                        change={this.props.change}
                        validation={(entry) => {
                          if (
                            !validator.isURL(entry, {
                              protocols: [],
                              require_protocol: false,
                              allow_underscores: true,
                            })
                          ) {
                            return 'Please enter a valid domain (without the http or www), e.g. forbes.com'
                          }
                          return null
                        }}
                        map={(entry) => entry.replace(/^(www\.)/, '')}
                      />
                    </div>
                  </div>
                )}
            </div>
          )}

          {this.props.type === 'sources' && (
            <div>
              <div className="form-group">
                <label>
                  <strong>Step 2: Add your Sources</strong>
                </label>

                {this.props.sourceFields.sources.length === 0 && (
                  <div className={styles.sourcesEmpty}>
                    No sources added yet. Add a domain, RSS feed or Twitter
                    user.
                  </div>
                )}

                {this.props.sourceFields.sources.map((source, index) => (
                  <div key={index} className={styles.source}>
                    <div className={styles.sourceLeft}>
                      <div className={styles.sourceType}>
                        {renderSourceString(source.type)}
                      </div>
                      <div className={styles.sourceValue} title={source.value}>
                        {source.value}
                      </div>
                    </div>
                    <div
                      className={styles.sourceDelete}
                      onClick={() =>
                        this.props.array.remove('sourceFields.sources', index)
                      }
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </div>
                  </div>
                ))}

                <button
                  className="btn btn-block btn-secondary"
                  onClick={() => this.setState({ isAddSourceModalOpen: true })}
                >
                  Add source
                </button>

                <div className={styles.languageContainer}>
                  <label>
                    Show articles written in language:{' '}
                    {!this.state.isSelectingLanguage ? (
                      <span
                        className={styles.language}
                        onClick={() =>
                          this.setState({ isSelectingLanguage: true })
                        }
                      >
                        {
                          languages.find(
                            (lang) => lang.code === this.props.language,
                          ).name
                        }
                      </span>
                    ) : null}
                  </label>
                  {this.state.isSelectingLanguage && (
                    <Field
                      name="language"
                      component="select"
                      className="form-control"
                      onChange={() => {
                        this.setState({ isSelectingLanguage: false })
                      }}
                    >
                      {languages.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                          {lang.name}
                        </option>
                      ))}
                    </Field>
                  )}
                </div>

                {sourceDomains.length > 0 &&
                  this.props.sourceFields.sources.length >
                    sourceDomains.length && (
                    <div style={{ fontSize: '0.8em', marginTop: '10px' }}>
                      <Field
                        name="sourceFields.whitelistDomains"
                        component="input"
                        type="checkbox"
                        normalize={(v) => {
                          return !!v ? true : false
                        }}
                      />{' '}
                      <strong>Only</strong> show content from the domains:{' '}
                      {humaniseList(
                        sourceDomains.map((source) => source.value),
                      )}
                    </div>
                  )}
              </div>

              {this.props.sourceFields.sources.length > 0 && (
                <div className="form-group">
                  <label>
                    <strong>Step 3: Further filter (optional)</strong>
                  </label>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Article titles must contain one of these main keywords:
                    </div>
                    <WordList
                      field="sourceFields.keywords"
                      newField="keywordsNew"
                      change={this.props.change}
                    />
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Article titles also must contain one or more of the
                      following words:
                    </div>
                    <WordList
                      field="sourceFields.includedWords"
                      newField="includedWordsNew"
                      change={this.props.change}
                    />
                    <IncludeWordSuggestions
                      field="sourceFields.includedWords"
                      onAdd={(word) => {
                        this.props.array.push(
                          'sourceFields.includedWords',
                          word,
                        )
                      }}
                    />
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Article titles must <strong>not</strong> contain any of
                      the following words:
                    </div>
                    <WordList
                      field="sourceFields.excludedWords"
                      newField="excludedWordsNew"
                      change={this.props.change}
                    />
                    <ExcludeWordSuggestions
                      field="sourceFields.excludedWords"
                      onAdd={(word) => {
                        this.props.array.push(
                          'sourceFields.excludedWords',
                          word,
                        )
                      }}
                    />
                  </div>

                  <div className={styles.filterWordSection}>
                    <div className={styles.filterText}>
                      Block articles that come from the following domains:
                    </div>
                    <WordList
                      field="sourceFields.blockedDomains"
                      newField="blockedDomainsNew"
                      change={this.props.change}
                    />
                  </div>
                </div>
              )}

              {this.props.viaApi && (
                <div className={styles.stepContainer}>
                  <div className={styles.stepTitle}>API-only settings</div>

                  <div>
                    <div className="form-group">
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="influencerFilter"
                            component="input"
                            type="checkbox"
                            normalize={(v) => {
                              return !!v ? true : false
                            }}
                            className="form-check-input"
                          />
                          <span>Filter influencer sources by topics</span>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="domainFilter"
                            component="input"
                            type="checkbox"
                            normalize={(v) => {
                              return !!v ? true : false
                            }}
                            className="form-check-input"
                          />
                          <span>Filter domains sources by topics</span>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <Field
                            name="rssFilter"
                            component="input"
                            type="checkbox"
                            normalize={(v) => {
                              return !!v ? true : false
                            }}
                            className="form-check-input"
                          />
                          <span>Filter RSS sources by topics</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <AddSourceModal
          isOpen={this.state.isAddSourceModalOpen}
          onRequestClose={() => this.setState({ isAddSourceModalOpen: false })}
          change={this.props.change}
          array={this.props.array}
          onAddSource={(source) => {
            this.props.array.push('sourceFields.sources', source)
          }}
        />
      </div>
    )
  }
}
