import React from 'react'
import { connect } from 'react-redux'

import EBookBanner from './components/EBookBanner/EBookBanner'
import Navbar from './components/Navbar/Navbar'
import Intro from './components/Intro/Intro'
import PartnersCarousel from './components/PartnersCarousel/OldPartnersCarousel'
import CTA from './components/CTA/CTA'
import Video from './components/Video/Video'
import PartnersList from './components/PartnersList/PartnersList'
import Quotes from './components/Quotes/Quotes'
import HowItWorks from './components/HowItWorks/HowItWorks'
import CaseStudies from './components/CaseStudies/CaseStudies'
import EBookForm from './components/EBookForm/EBookForm'
import Footer from './components/Footer/Footer'

import enterpriseLogos from './logos/enterpriseLogos'
import platformsLogos from './logos/platformsLogos'

import styles from './PlatformsHomepage.module.css'
import Testimonials from './components/Testimonials/Testimonials'

@connect((state) => ({
  app: state.app,
}))
export default class PlatformsHomepage extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: true,
      },
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_APP_LAYOUT',
      layout: {
        completePage: false,
      },
    })
  }

  render() {
    return (
      <div className={styles.outerBody}>
        <div className={styles.innerBody}>
          <main className={styles.home}>
            <EBookBanner bannerColor="white" externalLink={false} />
            <div className={styles.background1}>
              <Navbar
                backgroundColors="dark"
                pinkOrGreen="pink"
                logoColor="white"
              />
              <Intro
                headerText="Plug an AI-powered curation tool into your platform"
                bullet1="Accelerate your roadmap"
                bullet2="Add value for your clients"
                bullet3="Grow your revenue"
                headerColor="white"
                bulletPointColor="white"
                bulletTextColor="white"
                homepage="platforms"
              />
              <div className={styles.background1Inner}>
                <PartnersCarousel
                  headerText="Partnering with the world's leading learning platforms"
                  logos={platformsLogos}
                />
              </div>
            </div>
            <CTA
              headerText="We are the answer to the curation question your clients are asking"
              img={`${STATIC_DOMAIN}/pages/new-homepage/ctaPlatforms.png`}
              bullets={[
                {
                  text: 'Could you accelerate your development roadmap with an integrated curation tool?',
                  key: 1,
                },
                {
                  text: 'Do your clients struggle to keep learners engaged on your platform?',
                  key: 2,
                },
                {
                  text: "Would your platform be 'stickier' with integrated content curation from day one?",
                  key: 3,
                },
                {
                  text: 'Are your clients facing critical skills shortages that you are not providing a solution to?',
                  key: 4,
                },
              ]}
              p2Text="Anders Pink's AI-powered content curation tool delivers relevant and up-to-date content within your platform."
            />
            <Video bgColor="green" />
            <PartnersList
              headerText="Making our partners and clients heroes"
              logos={enterpriseLogos}
            />
            <Testimonials withOldHeader={true} />
            <HowItWorks
              stepContent={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw1.png`,
                  headerText: '1. Analyse',
                  bodyText:
                    "We'll work with you to identify how automated curation best fits with your platform and client needs.",
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw2.png`,
                  headerText: '2. Integrate',
                  bodyText:
                    "We'll plug our curation tool into your platform, branded and integrated the way you want.",
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/hiw3.png`,
                  headerText: '3. Launch',
                  bodyText:
                    "We'll partner with you to successfully deliver for your clients, so you can gain maximum impact with minimal effort.",
                },
              ]}
            />
            <CaseStudies
              headerText="Discover how some of our enterprise clients are automating content curation"
              studies={[
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-d2l-brightspace.png?1`,
                  caption: 'D2L Brightspace showcase',
                  title: 'Curation made easy, automated and integrated',
                  link: '/partners/d2l',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-degreed.png`,
                  caption: 'Degreed showcase',
                  title:
                    'Degreed connects learning and career growth to business opportunities',
                  link: '/partners/degreed',
                },
                {
                  img: `${STATIC_DOMAIN}/pages/new-homepage/transparent-totara.png`,
                  caption: 'Totara',
                  title:
                    'Curate fresh content from the web to power your Totara Talent Experience Platform',
                  link: '/partners/totara',
                },
              ]}
            />
            <EBookForm />
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}
