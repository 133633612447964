import React from 'react'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  reduxForm,
  change,
  formValueSelector,
  touch,
} from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { Component } from 'react'
import styles from './SourceFilters.css'

import FixedModal from '../../app/components/FixedModal'

import DomainList from './DomainList'
import DomainArrayList from './DomainArrayList'

const isUnique = (domainLists, selectedId) => (value) => {
  const domainListNames = domainLists
    .filter(({ id }) => !selectedId || +id !== +selectedId)
    .map(({ listName }) => listName)

  return (
    domainListNames.includes(value) && 'There is already a list with that name'
  )
}
const mustExist = (message) => (value) =>
  !value || value.length === 0 ? message : undefined

const mustHaveItems = (message) => (value, allValues) => {
  if (!value || value.length === 0) {
    return message
  }
}

const stateInitial = { listName: '', listType: 'allow' }
const FORM_NAME = 'sourcesFilters'
const selector = formValueSelector(FORM_NAME)
@graphql(
  gql`
    query domainListQuery($id: ID!) {
      domainList(id: $id) {
        listType
        listName
        items {
          id
          domain
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          id: props.selectedId,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
    skip: (props) => {
      return !props.selectedId
    },
  },
)
@reduxForm({
  form: FORM_NAME,
  touchOnChange: true,
  validate: (values) => {
    const errors = {}
    if (!values.items || values.items.length === 0) {
      errors.items = 'required'
    }
  },
})
@connect(
  (state) => ({
    items: selector(state, 'items'),
    listType: selector(state, 'listType'),
  }),
  { change, touch },
)
export default class SourceFiltersEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      isDeleting: false,
    }
  }

  componentDidMount() {
    if (this.props.initialize) {
      this.props.initialize({ ...stateInitial })
    }
  }
  componentDidUpdate(prevProps) {
    const { initialize, data: { loading, domainList } = {} } = this.props
    const hasLoaded = loading === false && prevProps.data.loading === true

    if (hasLoaded) {
      initialize({ ...domainList })
    }
  }

  listIsEmpty = (items) =>
    items.every(({ domain }) => !domain || domain.length === 0)

  submitChanges = ({ items, listName, listType }) => {
    const isNew = this.props.selectedId === null
    if (isNew) {
      return this.props.createList({
        listName,
        listType,
        items: items.map(({ id, domain }) => ({ id, domain })),
      })
    } else {
      return this.props.updateList({
        id: this.props.selectedId,
        listName,
        listType,
        items: items.map(({ id, domain }) => ({ id, domain })),
      })
    }
  }
  deleteList = (id) => {
    const self = this
    this.setState({ isDeleting: true })
    return this.props.deleteList(id)
  }

  onSubmit = ({ items, listName, listType }) => {
    // this.addItems()
    this.setState({ items, listName, listType })
    if (this.props.selectedId !== null && this.listIsEmpty(items)) {
      this.openModal({ items, listName, listType })
    } else {
      return this.submitChanges({ items, listName, listType })
    }
  }
  renderInput = (props) => (
    <>
      <input {...props.input} type="text" className="form-control" />
      {props.meta.touched && props.meta.error && (
        <span className={styles.validationError}>{props.meta.error}</span>
      )}
    </>
  )

  openModal = ({ items, listName, listType }) => {
    this.setState({ isModalOpen: true, items, listName, listType })
  }
  closeModal = () => {
    this.setState({ isModalOpen: false })
  }
  onItemsUpdate = (items) => {
    this.props.change(FORM_NAME, 'items', items)
    this.props.touch(FORM_NAME, ['items'])
  }
  clearItems = () => {
    const currentItems = this.props.items
    const updatedItems = currentItems
      .map(({ id }) => (!id ? undefined : { id, domain: '' }))
      .filter(Boolean)
    this.onItemsUpdate(updatedItems)
  }

  render() {
    const { data } = this.props
    if (data && data.loading) {
      return <div>Loading...</div>
    }
    const {
      error,
      handleSubmit,
      submitting,
      anyTouched,
      valid,
      listType,
    } = this.props
    const { items = [], domainLists, selectedId } = this.props
    const { isDeleting } = this.state
    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div>
            <div
              className={`form-group d-flex flex-column my-3 ${styles.formContainer}`}
            >
              <label>List name:</label>
              <Field
                name="listName"
                component={this.renderInput}
                type="text"
                validate={[
                  isUnique(domainLists, selectedId),
                  mustExist('Lists must have a name'),
                ]}
              />
            </div>
            <div className="form-group">
              List type:
              <label className="mx-2">
                <Field
                  name="listType"
                  component="input"
                  type="radio"
                  value="allow"
                />{' '}
                Allow
              </label>
              <label>
                <Field
                  name="listType"
                  component="input"
                  type="radio"
                  value="block"
                />{' '}
                Block
              </label>
            </div>
            <label className="m-0">
              {' '}
              Domains in this {listType === 'allow' ? 'Allowlist' : 'Blocklist'}
              :
            </label>
            <FieldArray
              name="items"
              component={DomainArrayList}
              validate={[mustHaveItems('List cannot be empty')]}
            />
            <div className="my-2">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={!anyTouched || submitting || isDeleting}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary mx-3"
                onClick={this.clearItems}
                disabled={submitting || isDeleting}
              >
                Clear
              </button>
              {selectedId !== null ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.openModal}
                  disabled={submitting || isDeleting}
                >
                  Delete
                </button>
              ) : null}
            </div>
          </div>
        </form>

        {error && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{ marginTop: '10px' }}
          >
            {error}
          </div>
        )}
        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          buttons={[
            <button
              className="btn btn-sm btn-primary"
              onClick={() => this.deleteList(this.props.selectedId)}
            >
              Delete this list
            </button>,
            <button
              className="btn btn-sm btn-secondary"
              onClick={this.closeModal}
            >
              Cancel
            </button>,
          ]}
        >
          <div style={{ padding: '20px', minHeight: '100px' }}>
            <div>This list will be deleted.</div>
          </div>
        </FixedModal>
      </div>
    )
  }
}
