import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import NewPasswordInput from 'authentication/containers/NewPasswordInput'

@reduxForm({
  form: 'signup',
  initialValues: {
    receiveNewsletters: false,
  },
})
export default class SignupForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
  }
  render() {
    const { onSubmit, error, handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Email address</label>
          <Field
            name="email"
            component="input"
            type="email"
            className="form-control"
            placeholder="Email address"
          />
        </div>

        <div className="fluid-container">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>First Name</label>
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Last Name</label>
                <Field
                  name="lastName"
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Password (8+ characters)</label>
          <NewPasswordInput />
        </div>
        <div className="form-check mb-2">
          <label className="form-check-label">
            <Field
              name="terms"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            I accept the
          </label>{' '}
          <a href="/terms-and-conditions" target="_blank" className="link">
            terms & conditions
          </a>
          .
        </div>

        <div className="form-check mb-2">
          <label className="form-check-label">
            <Field
              name="receiveNewsletters"
              component="input"
              type="checkbox"
              normalize={(v) => {
                return !!v ? true : false
              }}
              className="form-check-input"
            />{' '}
            Receive occasional updates from us including new features, articles
            and communications. You can opt out at any time in your profile
            settings.
          </label>
        </div>

        <p style={{ color: '#AAA', fontSize: '0.9em' }}>
          We will never sell your data to third parties. for more info see our{' '}
          <a target="_blank" href="/privacy-policy">
            Privacy Policy
          </a>
          .
        </p>

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <button type="submit" className="btn btn-primary" disabled={submitting}>
          Sign up
        </button>
      </form>
    )
  }
}
