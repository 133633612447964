import React from 'react'
import { Form, Container, Row, Col, Table } from 'react-bootstrap'

import Monthly from './stats/Monthly'
import PopularBriefings from './stats/PopularBriefings'
import PopularFolders from './stats/PopularFolders'
import PopularArticles from './stats/PopularArticles'
import PopularDomains from './stats/PopularDomains'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'
import moment from 'moment'
@compose(
  // withProps((props) => ({
  //   initialValues: {
  //     timeStart: String(new Date().getFullYear()),
  //   },
  // })),
  // reduxForm({
  //   form: 'monthlyStats',
  // }),
  // connect((state, props) => ({
  //   timeStart: selector(state, 'timeStart'),
  // })),
  graphql(
    gql`
      query ($id: ID) {
        team(id: $id) {
          id
          digests {
            id
            newsletterTitle
            sends {
              id
              createdAt
            }
          }
        }
      }
    `,
    {
      options: (props) => {
        return {
          variables: {
            id: props.teamId,
          },
          fetchPolicy: 'cache-and-network',
        }
      },
    },
  ),
)
class Filters extends React.Component {
  render() {
    console.log(this.props.data)
    if (
      !this.props.data ||
      !this.props.data.team ||
      !this.props.data.team.digests
    ) {
      return null
    }
    return (
      <div className="mb-2" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flexShrink: '0' }}>Show data from</div>
        <select
          className="ml-2 form-control"
          value={this.props.source}
          onChange={(e) => {
            this.props.onChange({ source: e.target.value })
          }}
        >
          <option value="all">Everywhere</option>
          <option value="api">App/API only</option>
          <option value="digests">Digests only</option>
        </select>
        {this.props.source === 'digests' && (
          <select
            className="ml-2  form-control"
            value={this.props.digest}
            onChange={(e) => {
              console.log(e.target.value)
              this.props.onChange({ digest: e.target.value })
            }}
          >
            <option value="all">All digests</option>
            {this.props.data.team.digests.map((d) => (
              <option key={d.id} value={d.id}>
                {d.newsletterTitle} (ID {d.id})
              </option>
            ))}
          </select>
        )}

        {this.props.source === 'digests' && this.props.digest !== 'all' && (
          <select
            className="ml-2 form-control"
            value={this.props.send}
            onChange={(e) => {
              this.props.onChange({ send: e.target.value })
            }}
          >
            <option value="all">All sends</option>
            {this.props.data.team.digests
              .find((d) => d.id === this.props.digest)
              .sends.map((s) => (
                <option key={s.id} value={s.id}>
                  Sent {s.createdAt} (ID {s.id})
                </option>
              ))}
          </select>
        )}
      </div>
    )
  }
}

@connect((state) => ({
  trackingEnabled: state.app.appQueryResult.team.trackingEnabled,
}))
export default class ApiStats2 extends React.Component {
  constructor(props) {
    super()
    this.state = {
      integration: null,

      source: props.location.query.source || 'all',
      digest: props.location.query.digest || 'all',
      send: props.location.query.send || 'all',
    }
  }
  render() {
    const { teamId } = this.props.params
    const { trackingEnabled } = this.props

    return (
      <div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 className="mb-3">Usage Analytics</h5>
            <Filters
              teamId={teamId}
              source={this.state.source}
              digest={this.state.digest}
              send={this.state.send}
              onChange={(data) => {
                const newState = {
                  ...this.state,
                  ...data,
                }
                this.setState(newState)

                browserHistory.push({
                  pathname: `/team-api-stats/${teamId}`,
                  query: {
                    ...this.props.location.query,
                    source: newState.source,
                    digest: newState.digest,
                    send: newState.send,
                  },
                })
              }}
            />
          </div>

          {!trackingEnabled && (
            <div className="alert alert-info">
              Your team currently doesn't have link tracking enabled so you
              won't be receiving any stats. Please contact us if you'd like it
              to be enabled.
            </div>
          )}

          {/* <Form className="mb-2">
            <Form.Row className="align-items-center">
              <Col xs="auto">
                <Form.Label>Integration</Form.Label>
              </Col>
              <Col xs="auto">
                <select className="form-control">
                  <option>All</option>
                </select>
              </Col>
            </Form.Row>
          </Form> */}

          <Row noGutters={false} className="mb-3">
            <Col>
              <div
                style={{
                  border: '1px solid #CCC',
                  // borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <Monthly
                  id={teamId}
                  source={this.state.source}
                  digest={this.state.digest}
                  send={this.state.send}
                />
              </div>
            </Col>
          </Row>
          <Row noGutters={false} className="mb-3">
            <Col>
              <div
                style={{
                  border: '1px solid #CCC',
                  // borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <PopularBriefings
                  id={teamId}
                  source={this.state.source}
                  digest={this.state.digest}
                  send={this.state.send}
                />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  border: '1px solid #CCC',
                  // borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <PopularFolders
                  id={teamId}
                  source={this.state.source}
                  digest={this.state.digest}
                  send={this.state.send}
                />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  border: '1px solid #CCC',
                  // borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <PopularDomains
                  id={teamId}
                  source={this.state.source}
                  digest={this.state.digest}
                  send={this.state.send}
                />
              </div>
            </Col>
          </Row>
          <Row noGutters={false} className="mb-3">
            <Col>
              <div
                style={{
                  border: '1px solid #CCC',
                  // borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <PopularArticles
                  id={teamId}
                  source={this.state.source}
                  digest={this.state.digest}
                  send={this.state.send}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
