import React from 'react'
import { connect } from 'react-redux'
import {
  reset,
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form'
import moment from 'moment'
import styles from './TeamSettingsPage.css'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router'
import { SubmissionError } from 'redux-form'

import FixedModal from 'app/components/FixedModal'
import TeamUserInviteForm from 'team/containers/TeamUserInviteForm'
import TeamSettingsForm from 'team/containers/TeamSettingsForm'

const selector = formValueSelector('teamMembers')

@reduxForm({
  form: 'teamMembers',
  initialValues: {
    newLevel: 'user',
  },
})
@connect((state, props) => ({
  newLevel: selector(state, 'newLevel'),
}))
@graphql(
  gql`
    query teamSettingsQuery {
      session {
        user {
          id
        }
        team {
          id
          name
          allowPublicSignup
          publicSignupLink
          areBriefingsLocked
          canUsersEditDomainLists
          areBoardsLocked
          isSavingLocked
          isBlockingLocked
          canCreateApiKey
          teamUsers {
            user {
              id
              firstName
              lastName
              email
              lastActiveDate
            }
            level
          }
          invites {
            id
            email
            level
            createdAt
            resentAt
          }
          apiUser {
            id
            key
          }
        }
        isTeamAdmin
      }
    }
  `,
  {
    options: (props) => ({
      fetchPolicy: 'cache-and-network',
    }),
  },
)
@graphql(
  gql`
    mutation ($team: TeamInput!) {
      updateTeam(team: $team) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateTeam: (team) =>
        mutate({
          variables: { team },
          refetchQueries: ['teamSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($teamInvite: TeamInviteInput!) {
      createTeamInvite(teamInvite: $teamInvite)
    }
  `,
  {
    props: ({ mutate }) => ({
      createTeamInvite: (teamInvite) =>
        mutate({
          variables: { teamInvite },
          refetchQueries: ['teamSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($teamUser: TeamUserInput!) {
      updateTeamUser(teamUser: $teamUser)
    }
  `,
  {
    props: ({ mutate }) => ({
      updateTeamUser: (teamUser) =>
        mutate({
          variables: { teamUser },
          refetchQueries: ['teamSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($teamUser: TeamUserInput!) {
      deleteTeamUser(teamUser: $teamUser)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteTeamUser: (teamUser) =>
        mutate({
          variables: { teamUser },
          refetchQueries: ['teamSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($id: ID!) {
      resendTeamInvite(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      resendTeamInvite: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['teamSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($id: ID!) {
      deleteTeamInvite(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteTeamInvite: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['teamSettingsQuery'],
        }),
    }),
  },
)
@graphql(
  gql`
    mutation ($id: ID!) {
      createTeamApiKey(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      createTeamApiKey: (id) =>
        mutate({
          variables: { id },
          refetchQueries: ['teamSettingsQuery', 'appQuery'],
        }),
    }),
  },
)
export default class TeamSettingsPage extends React.Component {
  constructor() {
    super()
    this.state = {
      teamSettingsSaved: false,
      isModalOpen: false,
      modalType: null,
      modalData: null,
    }
  }
  render() {
    if (this.props.data.loading) {
      return null
    }
    if (!this.props.data.session.isTeamAdmin) {
      return <div>Sorry, you don't have permission to manage this team.</div>
    }

    const team = this.props.data.session.team

    const pending = <span className={styles.pending}>(pending)</span>

    const hasApi = team.apiUser && team.apiUser.id

    return (
      <div>
        {(this.props.location.query.new ? true : false) && (
          <div className="alert alert-success" role="alert">
            <h5>You've successfully created a new team</h5>
            <div>Here's the next steps:</div>
            <ol>
              <li>Invite new team members</li>
              <li>
                <Link className="link" to="/create-briefing">
                  Create
                </Link>{' '}
                some briefings (we suggest you do it now - so your team don't
                see an empty home page!)
              </li>
              <li>Start collaborating, sharing and saving articles!</li>
            </ol>
            <p>
              You can return to this page any time by clicking on "Team
              settings" under your profile.
            </p>
          </div>
        )}

        <h5>Team Settings</h5>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>Team members</div>

          <div className={styles.rows}>
            <table className="table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Last active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {team.teamUsers.map((teamUser) => (
                  <tr key={teamUser.user.id}>
                    <td>{teamUser.user.firstName}</td>
                    <td>{teamUser.user.lastName}</td>
                    <td>{teamUser.user.email}</td>
                    <td>{teamUser.level}</td>
                    <td>{moment(teamUser.user.lastActiveDate).fromNow()}</td>
                    <td>
                      {teamUser.level !== 'owner' && (
                        <div>
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                              this.setState({
                                isModalOpen: true,
                                modalType: 'changeRole',
                                modalData: teamUser.user,
                              })
                            }}
                          >
                            Change role
                          </button>{' '}
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                              this.setState({
                                isModalOpen: true,
                                modalType: 'removeUser',
                                modalData: teamUser.user,
                              })
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
                {team.invites.map((invite) => (
                  <tr key={invite.email}>
                    <td>{pending}</td>
                    <td>{pending}</td>
                    <td>
                      {invite.email} {pending}
                    </td>
                    <td>
                      {invite.level} {pending}
                    </td>
                    <td></td>
                    <td>
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                          this.setState({
                            isModalOpen: true,
                            modalType: 'resendInvite',
                            modalData: invite,
                          })
                        }}
                      >
                        Resend invite
                      </button>{' '}
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                          this.setState({
                            isModalOpen: true,
                            modalType: 'cancelInvite',
                            modalData: invite,
                          })
                        }}
                      >
                        Cancel invite
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>Invite new user</div>
          <p>
            The user will receive an email inviting them to join your team. If
            the user already has an Anders Pink account, make sure to use the
            email address that they signed up with.
          </p>
          <TeamUserInviteForm
            onSubmit={(values) => {
              return this.props
                .createTeamInvite({
                  teamId: team.id,
                  ...values,
                })
                .then((response) => {
                  this.props.dispatch(reset('teamUserInvite'))
                })
                .catch((err) => {
                  throw new SubmissionError({
                    _error: err.graphQLErrors[0].message,
                  })
                })
            }}
          />
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>Public signup link</div>
          <p>
            You can activate and share a special public link that will allow
            anyone to signup to your team.
          </p>

          {team.allowPublicSignup && (
            <div>
              <div className={styles.signupLink}>{team.publicSignupLink}</div>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.props.updateTeam({
                    id: team.id,
                    allowPublicSignup: false,
                  })
                }}
              >
                Disable public signup
              </button>
            </div>
          )}
          {!team.allowPublicSignup && (
            <div>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.props.updateTeam({
                    id: team.id,
                    allowPublicSignup: true,
                  })
                }}
              >
                Enable public signup
              </button>
            </div>
          )}
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>Team settings</div>

          <div className={styles.permissions}>
            <TeamSettingsForm
              initialValues={{
                name: team.name,
                areBriefingsLocked: team.areBriefingsLocked,
                areBoardsLocked: team.areBoardsLocked,
                isSavingLocked: team.isSavingLocked,
                isBlockingLocked: team.isBlockingLocked,
                canUsersEditDomainLists: team.canUsersEditDomainLists,
              }}
              onSubmit={(values) => {
                this.props
                  .updateTeam({
                    id: team.id,
                    ...values,
                  })
                  .then(() => {
                    this.props.dispatch({
                      type: 'ADD_NOTIFICATION',
                      content: `Team settings successfully updated`,
                    })
                  })
              }}
            />
          </div>
        </div>

        {team.canCreateApiKey && !hasApi && (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>API key</div>
            <p>You can make an API key for this team.</p>

            <button
              className="btn btn-secondary"
              onClick={() => {
                this.props
                  .createTeamApiKey(team.id)
                  .then((response) => {
                    this.props.dispatch({
                      type: 'ADD_NOTIFICATION',
                      content: `Created API key, please see Export settings on the left hand menu`,
                    })
                  })
                  .catch((err) => {
                    let error = err.message
                    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
                      error = err.graphQLErrors[0].message
                    } else if (err.networkError) {
                      error = err.networkError.message
                    }
                    this.props.dispatch({
                      type: 'ADD_NOTIFICATION',
                      level: 'danger',
                      content: error,
                    })
                  })
              }}
            >
              Create API key
            </button>
          </div>
        )}
        {hasApi && (
          <div>
            <div className={styles.sectionTitle}>API key</div>
            <p>
              This team has an API key: <strong>{team.apiUser.key}</strong>
            </p>
            <p>
              You can manage which briefings and saved folders are exposed by
              going to the{' '}
              <Link to="/export-settings" className="link">
                Export Settings
              </Link>
              .
            </p>
          </div>
        )}

        <FixedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          buttons={[
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                // What action do we need to do...
                switch (this.state.modalType) {
                  case 'changeRole':
                    this.props.updateTeamUser({
                      teamId: team.id,
                      userId: this.state.modalData.id,
                      level: this.props.newLevel,
                    })
                    break

                  case 'removeUser':
                    this.props.deleteTeamUser({
                      teamId: team.id,
                      userId: this.state.modalData.id,
                    })
                    break

                  case 'resendInvite':
                    this.props.resendTeamInvite(this.state.modalData.id)
                    break

                  case 'cancelInvite':
                    this.props.deleteTeamInvite(this.state.modalData.id)
                    break
                }
                this.setState({
                  isModalOpen: false,
                })
              }}
            >
              Confirm
            </button>,
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => this.setState({ isModalOpen: false })}
            >
              Close
            </button>,
          ]}
        >
          <div style={{ padding: '20px', minHeight: '100px' }}>
            {this.state.modalType == 'changeRole' && (
              <div>
                Change role of{' '}
                <strong>
                  {this.state.modalData.firstName}{' '}
                  {this.state.modalData.lastName}
                </strong>{' '}
                to:
                <br />
                <br />
                <Field
                  name="newLevel"
                  component="select"
                  className="form-control"
                >
                  <option value="user">Normal user</option>
                  <option value="admin">Admin</option>
                </Field>
              </div>
            )}
            {this.state.modalType == 'removeUser' && (
              <div>
                Are you sure you want to remove{' '}
                <strong>
                  {this.state.modalData.firstName}{' '}
                  {this.state.modalData.lastName}
                </strong>{' '}
                from this team?
              </div>
            )}
            {this.state.modalType == 'resendInvite' && (
              <div>
                Are you sure you want to re-send the email invite to{' '}
                <strong>{this.state.modalData.email}</strong>?
              </div>
            )}
            {this.state.modalType == 'cancelInvite' && (
              <div>
                Are you sure you want to cancel the invite to{' '}
                <strong>{this.state.modalData.email}</strong>?
              </div>
            )}
          </div>
        </FixedModal>
      </div>
    )
  }
}
