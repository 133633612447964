import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import TwitterLoginButton from 'app/containers/TwitterLoginButton'
import { Link } from 'react-router'
import styles from './LandingPage.css'

export default class HomePage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.topBanner}>
          <h1>Content curation for learning</h1>
          <div className={styles.subtitle}>
            Easier, automated and plugged into your platforms
          </div>
        </div>

        <div className={styles.banner1}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div className={styles.imageText}>
              Curate the content you want - from any sources on any topic
              <br />
              <br />
              Get fresh content every few hours
              <br />
              <br />
              Embed it seamlessly into your Learning Platform, tools and apps
            </div>
            <div>
              <img src="/home-images/embed2.png" style={{ width: '600px' }} />
            </div>
            <div className={styles.imageText}>
              Save time, stay smart
              <br />
              <br />
              Keep your content and platform fresh
              <br />
              <br />
              Drive continuous learning in the tools you already use
              <br />
            </div>
          </div>
        </div>

        <div className={styles.demo}>
          <Link to="/contact" className="btn btn-primary btn-lg">
            Start a free trial
          </Link>
        </div>

        <div
          className="container"
          style={{ maxWidth: '800px', margin: '80px auto' }}
        >
          <h4 style={{ textAlign: 'center' }}>
            Helping our clients and partners become content curation heroes:
          </h4>
          <br />
          <br />

          <div className="row">
            <div className="col">
              <div className={styles.company}>
                <p>
                  "Using Anders Pink is a key component of our curation learning
                  and development strategy. It brings us the{' '}
                  <strong>latest and greatest content</strong>, seamlessly in
                  our learning portals."
                </p>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src="/home-images/scania.png" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className={styles.company}>
                <p>
                  "One of the big challenges for learning is keeping it{' '}
                  <strong>fresh and relevant</strong>. Anders Pink can do all of
                  that for us, embedded directly into our Learning Platform to
                  support daily learning habits."
                </p>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src="/home-images/hitachi.png" />
                </div>
              </div>
            </div>
            {/* <div className="col">
              <div className={styles.company}>
                <p>
                  "Anders Pink <strong>saves our team a lot of time</strong>,
                  and has allowed us to customize our briefings to include what
                  we want to see, each and every day. It has truly been a life
                  saver!"
                </p>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src="/home-images/cibc.png" />
                </div>
              </div>
            </div> */}
          </div>
          <br />
          <br />
          <br />

          <div className="row">
            <div className="col">
              <div className={styles.company}>
                <p>
                  "Finding great external content is vital for our clients.
                  Anders Pink is fully integrated into Fuse, bringing in the
                  most popular, new content that people are loving.{' '}
                  <strong>Amazing, automatic curation, saving time</strong>."
                </p>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src="/home-images/fuse.png" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className={styles.company}>
                <p>
                  "Anders Pink is really useful tool. We use it on the platforms
                  we design. Access to constantly updated, well targeted and
                  easy to find articles{' '}
                  <strong>
                    make the LMS a useful destination for the learner
                  </strong>
                  , encouraging repeat visits to the platform."
                </p>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src="/home-images/kineo.png" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className={styles.company}>
                <p>
                  "Anders Pink has{' '}
                  <strong>
                    saved us time and resources in sourcing content
                  </strong>{' '}
                  for subjects. Our clients tell us as time is precious for
                  them, it is very advantageous to access relevant content in
                  one location."
                </p>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src="/home-images/ei.png" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.demo}>
          <Link to="/contact" className="btn btn-primary btn-lg">
            Start a free trial
          </Link>
        </div>

        <div
          className="container"
          style={{ maxWidth: '1000px', margin: '80px auto' }}
        >
          <h4 style={{ textAlign: 'center' }}>
            Discover: get the content you want, updated every few hours
          </h4>
          <br />
          <br />
          <div className="row">
            <div className="col">
              <p>
                You want to keep learners up to date. But you don’t have time to
                check multiple sites and sources every day for the latest
                content to support your learning. Anders Pink dynamically
                aggregates content from millions of sources. Get exactly what
                you want with your choice of topics, keywords, sites, RSS feeds
                and Twitter influencers. Get exactly what you want from the web,
                for example:
              </p>
              <ul>
                <li>
                  Content on{' '}
                  <a
                    target="_blank"
                    className="link"
                    href="/briefing/2596/sales-latest-thinking-from-top-blogs/1-week"
                  >
                    Sales tips and best practices from your preferred 30 sites
                    and sources
                  </a>
                </li>
                <li>
                  Trending content on{' '}
                  <a
                    target="_blank"
                    className="link"
                    href="/briefing/8887/big-data-and-hr/1-month"
                  >
                    Big Data that also mentions HR, Talent or Recruitment
                  </a>
                </li>
              </ul>
              <p>
                Your content updates every few hours, so you don’t miss what
                matters to you.{' '}
              </p>
            </div>
            <div className="col">
              <img
                src="/home-images/briefing.png"
                style={{
                  width: '500px',
                  boxShadow: 'rgb(204, 204, 204) 0px 0px 3px 1px',
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: '1000px', margin: '80px auto' }}
        >
          <h4 style={{ textAlign: 'center' }}>
            Curate: share your insights as a team
          </h4>
          <br />
          <br />
          <div className="row">
            <div className="col">
              <img
                src="/home-images/briefing2.png"
                style={{
                  width: '500px',
                  boxShadow: 'rgb(204, 204, 204) 0px 0px 3px 1px',
                }}
              />
            </div>
            <div className="col">
              <p>Add value to the content you discover:</p>
              <ul>
                <li>Recommend content to colleagues in private teams</li>
                <li>Add comments and curate as a team </li>
                <li>
                  Build collections of the most relevant content for sharing
                </li>
              </ul>
              <p>
                Our AI-powered algorithm learns from your preferences and
                refines content to make it even more specific.
              </p>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{ maxWidth: '1000px', margin: '80px auto' }}
        >
          <h4 style={{ textAlign: 'center' }}>
            Integrate: bring curated content into your apps, tools and platforms
          </h4>
          <br />
          <br />
          <div className="row">
            <div className="col">
              <p>
                <a href="/api-info" className="link">
                  Our plugins and integrations
                </a>{' '}
                make it easy to display live briefings or saved folders in any
                Learning Platform, tool or app. You can integrate full curation
                functionality into your app for your clients, styled your way
                and completely white labelled.
              </p>
              <ul>
                <li>
                  Bring fresh content into your Learning Platform to support
                  your learners
                </li>
                <li>
                  Add fresh content automatically to Slack, WordPress and Social
                  Networks{' '}
                </li>
                <li>
                  Partners: Add a powerful curation tool to your LMS to enhance
                  your offer{' '}
                </li>
              </ul>
              <p>
                We’re plugged into over 25 platforms and tools, and adding new
                integrations all the time. Bring us your platform, we’ll make it
                happen...
              </p>
            </div>
            <div className="col">
              <img
                src="/home-images/briefing3.png"
                style={{
                  width: '400px',
                  boxShadow: 'rgb(204, 204, 204) 0px 0px 3px 1px',
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{ textAlign: 'center', padding: '3rem 0', fontSize: '1.2rem' }}
        >
          <p>
            We are here to make your team content curation heroes. <br />
            <br />
            <Link to="/contact" className="btn btn-primary btn-lg">
              Get in touch
            </Link>{' '}
            we’d love to help you.
          </p>
        </div>
      </div>
    )
  }
}
