const brightspace = `${STATIC_DOMAIN}/pages/new-homepage/Brightspace.png?1`
const degreed = `${STATIC_DOMAIN}/pages/new-homepage/Degreed.png`
const docebo = `${STATIC_DOMAIN}/pages/new-homepage/Docebo.png`
const fuse = `${STATIC_DOMAIN}/pages/new-homepage/Fuse.png`
const learnamp = `${STATIC_DOMAIN}/pages/new-homepage/Learnamp.png`
const blend = `${STATIC_DOMAIN}/pages/new-homepage/Blend.png`

const platformsLogos = [
  {
    id: 1,
    src: brightspace,
    name: 'brightspace',
    style: { height: '50px' },
  },
  {
    id: 2,
    src: degreed,
    name: 'degreed',
    style: { height: '40px' },
  },
  {
    id: 3,
    src: docebo,
    name: 'docebo',
    style: { height: '30px' },
  },
  { id: 4, src: fuse, name: 'fuse', style: { height: '50px' } },
  {
    id: 5,
    src: learnamp,
    name: 'learnamp',
    style: { height: '40px' },
  },
  {
    id: 6,
    src: blend,
    name: 'blend',
    style: { height: '80px' },
  },
]

export default platformsLogos
