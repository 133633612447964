import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import BoardNameBlock from 'boards/components/BoardNameBlock'
import BriefingNameBlockList from 'briefings/components/BriefingNameBlockList'
import styles from './AllBoardsPage.css'
import { Field, reduxForm } from 'redux-form'

@reduxForm({
  form: 'addBoard',
  initialValues: {
    name: '',
  },
})
@graphql(
  gql`
    mutation ($name: String!) {
      createBoard(name: $name) {
        id
        name
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createBoard: (name) =>
        mutate({
          variables: { name },
          refetchQueries: ['appQuery'],
        }),
    }),
  },
)
@connect((state) => ({
  session: state.app.appQueryResult,
}))
export default class AddBoardForm extends React.Component {
  static defaultProps = {
    onAdd: () => {},
  }
  constructor() {
    super()
    this.state = {
      open: false,
    }
  }
  onSubmit = (values) => {
    this.props.createBoard(values.name).then(() => {
      //this.props.onAdd();
    })
    this.props.reset()
    this.setState({
      open: false,
    })
  }
  render() {
    const { error, handleSubmit, submitting } = this.props

    if (this.state.open && this.props.session.numAvailableBoards <= 0) {
      return (
        <div style={{ margin: '10px 0' }}>
          Sorry, you can't make any more Folders.
        </div>
      )
    }

    return (
      <div style={{ margin: '10px 0' }}>
        {this.props.session.canEditBoards && !this.state.open && (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              this.setState({
                open: true,
              })
            }}
          >
            Add a new Folder
          </span>
        )}

        {this.state.open && (
          <form onSubmit={handleSubmit(this.onSubmit)} className="form-inline">
            <Field
              name="name"
              style={{ width: '160px' }}
              component="input"
              type="text"
              className="form-control form-control-sm mb-2 mr-sm-2 mb-sm-0"
              placeholder="New Folder name"
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  //this.doPreview();
                }
              }}
            />

            <button
              className="btn btn-secondary btn-sm mb-2 mr-sm-2 mb-sm-0"
              onClick={() => {}}
            >
              Add Folder
            </button>
          </form>
        )}
      </div>
    )
  }
}
