import React, { useEffect } from 'react'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import ListItem from '../components/ListItem'

const GET_DIGESTS_FOR_TEAM = gql`
  query NewslettersForTeam {
    NewslettersForTeam {
      id
      newsletterTitle
      createdAt
    }
  }
`

const DELETE_DIGEST = gql`
  mutation deleteNewsletter($id: ID) {
    deleteNewsletter(id: $id)
  }
`

const SEND_TEST_DIGEST = gql`
  mutation testNewsletter($id: ID) {
    testNewsletter(id: $id)
  }
`

let ListDigestPage = (props) => {
  const { NewslettersForTeam, error, loading, refetch } =
    props.NewslettersForTeam
  const { deleteNewsletter, location, testNewsletter } = props

  useEffect(() => {
    if (location.state && location.state.refetch) {
      refetch()
    }
  }, [location])

  const handleAlertDialog = (id) => {
    deleteNewsletter({
      variables: {
        id,
      },
    })
      .then(() => {
        browserHistory.push({
          pathname: '/newsletters',
          state: { refetch: true },
        })
        props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `Digest succesfully deleted`,
        })
      })
      .catch((err) => {
        let error = ''
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          error = err.graphQLErrors[0].message
        } else if (err.networkError) {
          error = err.networkError.message
        } else {
          error = err.message
        }
        props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: error,
        })
      })
  }

  const handleTestSend = (id) => {
    testNewsletter({
      variables: {
        id,
      },
    })
      .then(() => {
        props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: `Test Digest succesfully sent`,
        })
      })
      .catch((err) => {
        let error = ''
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          error = err.graphQLErrors[0].message
        } else if (err.networkError) {
          error = err.networkError.message
        } else {
          error = err.message
        }
        props.dispatch({
          type: 'ADD_NOTIFICATION',
          content: error,
        })
      })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div>
      <h5>Manage digests</h5>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Creation Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {NewslettersForTeam.map((item, index) => (
            <ListItem
              key={index}
              name={item.newsletterTitle}
              createdDate={item.createdAt}
              onEdit={() => browserHistory.push(`newsletters/${item.id}`)}
              onDelete={() => {
                if (confirm('Do you really want to delete this digest?')) {
                  handleAlertDialog(item.id)
                }
              }}
              onStats={() =>
                browserHistory.push(`newsletters/${item.id}/stats`)
              }
              onTestSend={() => {
                if (
                  confirm(
                    'This will send an email just to you, do you wish to continue?',
                  )
                ) {
                  handleTestSend(item.id)
                }
              }}
            />
          ))}
        </tbody>
      </table>
      <div>
        <Link to={`newsletters/create`} className="btn btn-sm btn-primary">
          Create new digest
        </Link>
      </div>
    </div>
  )
}

ListDigestPage = compose(
  withApollo,
  graphql(GET_DIGESTS_FOR_TEAM, {
    name: 'NewslettersForTeam',
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(DELETE_DIGEST, {
    name: 'deleteNewsletter',
  }),
  graphql(SEND_TEST_DIGEST, {
    name: 'testNewsletter',
  }),
  connect(),
)(ListDigestPage)

export default ListDigestPage
