import React from 'react'
import moment from 'moment'

const ListItem = ({
  name,
  createdDate,
  onEdit,
  onDelete,
  onStats,
  onTestSend,
}) => {
  return (
    <>
      <tr className="align-items-center">
        <th scope="row">{name}</th>
        <td>Created {moment(createdDate).fromNow()}</td>
        <td>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary ml-1"
              onClick={onDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary ml-1"
              onClick={onStats}
            >
              Stats
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary ml-1"
              onClick={onTestSend}
            >
              Send Test
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}
export default ListItem
