import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { browserHistory, Link } from 'react-router'

import AdminTable from 'admin/components/AdminTable'

const ITEMS_PER_PAGE = 100

@graphql(
  gql`
    query(
      $query: String
      $sort: String
      $sortOrder: String
      $limit: Int!
      $offset: Int!
    ) {
      accounts(
        query: $query
        sort: $sort
        sortOrder: $sortOrder
        limit: $limit
        offset: $offset
      ) {
        id
        name
        type
        activeSubscription {
          id
          plans {
            id
            planSet {
              id
              name
            }
          }
        }
        teams {
          id
          name
        }
      }
    }
  `,
  {
    options: (props) => {
      return {
        variables: {
          query: props.location.query.query || '',
          sort: props.location.query.sort || 'id',
          sortOrder: props.location.query.sortOrder || 'desc',
          limit: props.location.query.limit || ITEMS_PER_PAGE,
          offset: props.location.query.offset || 0,
        },
      }
    },
    props({ data: { accounts, fetchMore, ...rest } }) {
      return {
        data: {
          accounts,
          ...rest,
          loadMore() {
            return fetchMore({
              variables: {
                offset: accounts.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousResult
                }
                return Object.assign({}, previousResult, {
                  // Append the new feed results to the old one
                  accounts: [
                    ...previousResult.accounts,
                    ...fetchMoreResult.accounts,
                  ],
                })
              },
            })
          },
        },
      }
    },
  },
)
export default class AdminAccountsPage extends React.Component {
  render() {
    return (
      <div>
        <AdminTable
          fields={{
            id: {
              label: 'ID',
              render: (id) => (
                <Link to={`/admin/accounts/${id}`} className="link link-list">
                  {id}
                </Link>
              ),
            },
            type: {
              label: 'Type',
            },
            name: {
              label: 'Name',
            },
            activeSubscription: {
              label: 'Subscription',
              render: (activeSubscription) => (
                <div>
                  {activeSubscription !== null && (
                    <div>
                      Has {activeSubscription.isTrial ? 'trial' : ''}{' '}
                      subscription to:
                      <ul>
                        {activeSubscription.plans
                          .filter((plan) => (plan.planSet ? true : false))
                          .map((plan) => (
                            <li>
                              <Link
                                key={plan.id}
                                to={`/admin/plans/${plan.planSet.id}`}
                                className="link"
                              >
                                {plan.planSet.name}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              ),
            },
            teams: {
              label: 'Teams',
              render: (teams) => {
                return teams.map((team, index) => (
                  <Link
                    key={index}
                    to={`/admin/teams/${team.id}`}
                    className="link link-list"
                  >
                    {team.name}
                  </Link>
                ))
              },
            },
          }}
          data={this.props.data.accounts || []}
          loading={this.props.data.loading}
          onSubmit={(values) => {
            browserHistory.push({
              pathname: '/admin/accounts',
              query: {
                ...values,
              },
            })
          }}
          loadMore={() => {
            this.props.data.loadMore()
          }}
          initialValues={{
            ...this.props.location.query,
          }}
        />
      </div>
    )
  }
}
